<div *ngIf="showSection" [ngStyle]="{'display': isPopoutOpened ? 'flex':'none' }"
    class="sps-left-popout">
    <div class="lp-panel">
        <div class="lp-panel-top">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text">Hinge Type</h3>
                    <!-- Hinge Type -->
                    <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse"
                        alt="close icon" (click)="onClose()">
                </div>
            </div>
            <!-- Selected Info -->
            <div class="lp-container lp-container-custom">
                <div class="lp-container-displaybox">
                    <img *ngIf="listOfDisplayData[selectedIndex]" alt="article"
                        [attr.src]="baseImageSrc+listOfDisplayData[selectedIndex].imageSRC">  
                </div>
                <div class="lp-container-text">
                    <div class="sps-pill" *ngIf="listOfDisplayData[selectedIndex] && listOfDisplayData[selectedIndex].Color">
                        <p>{{ listOfDisplayData[selectedIndex].Color.substr(listOfDisplayData[selectedIndex].Color.length - 8) }}</p>
                    </div>
                </div>
            </div>
           <!-- Table Content -->
            <div class="bpsTableContent">
                <bps-table #bpsTable [data]="listOfDisplayData" [scroll]="{ y: 'calc(100vh - 406px)' }"
                    [showPagination]="false" [frontPagination]="false" (onclickRow)="onclickRow($event)"
                    (ondblclickRow)="ondblclickRow($event)" (selectionChange)="selectionChange($event)"
                    [tableType]="'glass_profile'" class="bps-table-section"
                    [filterPlaceholder]="('configure.mullion-depth-search-by-article' | translate)" (searchValueChange)="filter($event)"
                    (sortChange)="sort($event)" [(config)]="configurationCustomGrid">
                </bps-table>
            </div>
        </div>
         <!-- Confirm -->
        <div class="lp-confirm-btn-container">
            <button class="lp-confirm-btn" bps-button bpsType="variation-4" (click)="onConfirm()">{{'configure.confirm'
                | translate }}</button>
        </div>
    </div>
</div>