<div class="main-container">
    <div class="result-sustainability-container rectangle-border">
        <h3 class="title-header final-result-header"> {{'result.sustainability.final-result' | translate}} </h3>
        <div class="gwp-result rectangle-border">
            {{'result.sustainability.gwp-total' | translate}} = {{umService?.current_UnifiedModel?.AnalysisResult?.SustainabilityResult?.SustainabilityUIResult?.GWP?.TotalValue || '--'}} kg CO<sub>2</sub>-Eq
        </div>
        <div class="code-descrption-container rectangle-border">
            <img src="/assets/Images/sps/sps_round_icon_infoacoustic_blue.svg">
            <span class="code-description-text">{{'result.sustainability.code-description' | translate}}</span>
        </div>
    </div>
</div>