<div *appCheckPermissions="feature.GlassPanelTableNew">
    <div *ngIf="(selectedType == 'newGlassAndPanel' && !isEditCustom)|| (selectedType=='customLibrary' && isEditCustom)"> 
        <div class="lp-top-section-extanded">
            <div class="lp-header">
                <h3 class="lp-header-text">{{'configure.structural-library-custom-create-new' | translate | uppercase}}</h3>
                <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse"
                    style="cursor: pointer;" alt="close icon" (click)="onClose()">
            </div>
        </div>
        <div class="glass-viewer-container" id="sps-glass-viewer-container">
            <div class="glass-viewer-row">
                <div class="glass-viewer">
                    <app-viewer-infill [isSidePanel]="isSidePanel" *ngIf="isPopoutOpened"
                        [confirmedGlassPanelArticle]="selectedArticle">
                    </app-viewer-infill>
                </div>
                <div class="glass-viewer-dimensions-of-glass" id="sps-glass-viewer-dimensions-of-glass" *ngIf="selectedArticle!=undefined && selectedArticleIsConfigure">
                    <div class="glass-and-panel-dimensions-container" id="glass-and-panel-dimensions-container">
                        <div class="lp-header">
                            <h3 class="lp-header-text" style="margin: 10px 77px 11px 15px;">{{'configure.glass-thickness' | translate | uppercase}}</h3>
                        </div>
                        <div class="glass-and-panel-dimensions-row" *ngFor="let elementSize of selectedArticle.composition.split('-'); let i = index">
                            <h3 *ngIf="this.applicationType=='BPS' && elementSize !== 'null'" class="glass-and-panel-dimensions-number" style="margin: 0 0 0 15px !important;">{{ elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('(')) : elementSize | number }}</h3>
                            <h3 *ngIf="this.applicationType=='BPS' && elementSize === 'null'" class="glass-and-panel-dimensions-number" style="margin: 0 0 0 15px !important;">{{ elementSize}}</h3>
                            <h3 class="glass-and-panel-dimensions-unit" style="margin: 0 0 0 15px !important;">{{ selectedArticle.totalThicknessUnit }}</h3>
                            <!-- <h3 [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ selectedArticle.type.split('-')[i] }}</h3> -->
                            <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='glass'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.glass' | translate }}</h3>
                            <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='argon'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.argon' | translate }}</h3>
                            <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='air'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.air' | translate }}</h3>
                            <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='lamipvb'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.glass' | translate }}</h3>
                            <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='insulation'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.structural-library-custom-panel-layer-insulation-option' | translate }}</h3>
                            <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='aluminium'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.structural-library-custom-panel-layer-aluminium-option' | translate }}</h3>
                            <h3 *ngIf="elementSize.includes('(')  && this.applicationType=='BPS' else selectContactTemp" class="glass-and-panel-dimensions-text" style="margin: 0 -25px 0 15px; width:182px !important;"> {{elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('('))/2 : elementSize/2 | number}}{{'|'}}{{elementSize.substring(2,6)}}{{' PVB|'}}{{elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('('))/2 : elementSize/2 | number}}</h3>
                            <!-- <h3 *ngIf="pvbValueFull[selectedPicker][i] && applicationType=='SRS'"  style="width:115px;" class="glass-and-panel-dimensions-unit">({{ pvbValueFull[selectedPicker][i] }})</h3>
                            <h3 *ngIf="pvbValueFull[selectedPicker][i] && applicationType == 'BPS'" style="width:115px;" class="glass-and-panel-dimensions-unit">{{elementSize.includes('[')? elementSize.substring(0, elementSize.indexOf('['))/2 : elementSize/2 | number}}{{'|'}}{{pvbValueFull[selectedPicker][i].substring(0, pvbValueFull[selectedPicker][i].indexOf('m'))}}{{' PVB|'}}{{elementSize.includes('[')? elementSize.substring(0, elementSize.indexOf('['))/2 : elementSize/2 | number}}</h3> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sps-left-popout-container-level" *ngIf="libraryElements">
            <div class="sps-left-popout-container-level2">
                <div class="scrolling-container">
                    <div class="sps-left-popout-level2-top-section">
                        <div>
                            <div class="sps-popout-header sps-fr-outerfr-heading">
                                <h3 id="outer-popout-panel-heading" class="outer-popout-panel-heading">
                                    {{'configure.structural-library-custom-create-new-glass' | translate}}</h3>
                                <!-- CUSTOM PROFILE -->
                                <!-- <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" alt="close-btn"
                                    id="sps-left-popout-container-level2-close-btn" style="cursor: pointer;"
                                    (click)="onCloseButton()"> -->
                            </div>
                            <div class="sps-popout-header sps-fr-outerfr-heading">
                                <button style="width: 180px;" bps-button bpsType="variation-2"
                                    [ngClass]="{'selected': libraryElements.selectedType == 'custom-double'}"
                                    (click)="onSelectTypeChange('custom-double')">{{'configure.structural-library-custom-varation-double-option'
                                    | translate}}</button>
                                <button style="width: 180px;" bps-button bpsType="variation-2"
                                    [ngClass]="{'selected': libraryElements.selectedType == 'custom-triple'}"
                                    (click)="onSelectTypeChange('custom-triple')" [disabled]="isTripleDisabled">{{'configure.structural-library-custom-variation-triple-option'
                                    | translate}}</button>
                                <button style="width: 200px;" bps-button bpsType="variation-2"
                                    [ngClass]="{'selected': libraryElements.selectedType == 'custom-panel'}"
                                    (click)="onSelectTypeChange('custom-panel')" [disabled]="isPanelBtnDisabled">{{'configure.structural-library-custom-variation-panel-option'
                                    | translate}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="sps-break-line"></div>
                    <div id="sps-left-popout-custom-section-property" class="sps-left-popout-custom-section-property">
                        <form bps-form [formGroup]="validateForm">
                            <!-- <bps-form-item class="bps-form-item">
                                <bps-form-control>
                                    <h3 style="margin-bottom: 4px;">{{'NAME' | uppercase }}</h3>
                                    {{libraryElements.name}}
                                </bps-form-control>
                            </bps-form-item> -->
                            <h3>{{'configure.structural-library-custom-layers' | translate
                                | uppercase}}
                                <span *ngIf="libraryElements.selectedType === 'custom-panel'" style="margin-left: 445px;" bpsType="variation-1" bps-popover
                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                [bpsPopoverContent]="layerContent">
                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                            <ng-template #layerContent>
                                <div class="popover-variation3" style="width: 220px;">
                                   The user is responsible for the panel's feasibility. For more information check the Schüco Docu Center.
                                </div>
                            </ng-template>
                        </span>
                            </h3>
                            <!-- Section Properties -->
                            <div *ngIf="libraryElements.selectedType != 'custom-panel'">
                                <table style="width:100%">
                                    <tr style="height: 22px !important;">
                                        <th class="table-column-title width-column1" style="padding-left: 0px;">
                                            {{'configure.structural-library-custom-heat-treatment' | translate}}</th>
                                        <th class="table-column-title width-column2">
                                            {{'configure.structural-library-custom-type' | translate}}</th>
                                        <th class="table-column-title width-column3">
                                            {{'configure.structural-library-custom-size' | translate}}</th>
                                        <th class="table-column-title width-column4">
                                            {{'configure.structural-library-custom-interlayer' | translate}}</th>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 0px;" class="width-column1">
                                            <bps-form-item class="width-column1">
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_xx_1"
                                                        [ngModel]="libraryElements.element_xx_1"
                                                        (ngModelChange)="changeElement_xx_1($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-xx1-hs-option' | translate}}"
                                                            bpsValue="HS"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td class="width-column2">
                                            <bps-form-item class="width-column2 margin-bot-16">
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_type_1"
                                                        [ngModel]="libraryElements.element_type_1"
                                                        (ngModelChange)="changeElement_type_1($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type1-glass-option' | translate}}"
                                                            bpsValue="Glass"></bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type1-laminated-glass-option' | translate}}"
                                                            bpsValue="lamiPVB">
                                                        </bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type1-laminated-glass-sgp-option' | translate}}"
                                                            bpsValue="lamiSGP">
                                                        </bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type1-laminated-glass-ac-option' | translate}}"
                                                            bpsValue="lamiAcousticPVB">
                                                        </bps-option>
                                                        <bps-option
                                                        bpsLabel="{{'configure.structural-library-custom-element-type1-laminated-glass-sc-option' | translate}}"
                                                        bpsValue="lamiSC">
                                                    </bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                            <bps-form-item class="width-column2 margin-bot-16">
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_ins_type_1"
                                                        [ngModel]="libraryElements.element_ins_type_1"
                                                        (ngModelChange)="changeElement_ins_type_1($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type1-air-option' | translate}}"
                                                            bpsValue="Air"></bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type1-argon-option' | translate}}"
                                                            bpsValue="Argon"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td class="width-column3">
                                            <bps-form-item class="width-column3 margin-bot-16">
                                                <bps-form-control>
                                                    <bps-select class="has-success" formControlName="element_size_1" [ngModel]="libraryElements.element_size_1"
                                                        (ngModelChange)="changeElement_size_1($event)" *ngIf="applicationType === 'SRS'">
                                                        <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="14" bpsValue="14" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="16" bpsValue="16" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="18" bpsValue="18" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="20" bpsValue="20" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="24" bpsValue="24" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                    </bps-select>
                                                    <bps-select style="width:60px;" class="has-success" formControlName="element_size_1" [ngModel]="libraryElements.element_size_1"
                                                    (ngModelChange)="changeElement_size_1($event)" *ngIf="applicationType==='BPS'">
                                                    <bps-option bpsLabel="2" bpsValue="2" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="3" bpsValue="3" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="5" bpsValue="5" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="7" bpsValue="7" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="15" bpsValue="15" *ngIf="libraryElements.element_type_1 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="14" bpsValue="14" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="16" bpsValue="16" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="18" bpsValue="18" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="20" bpsValue="20" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="24" bpsValue="24" *ngIf="libraryElements.element_type_1 === 'lamiAcousticPVB' || libraryElements.element_type_1 === 'lamiPVB' || libraryElements.element_type_1 === 'lamiSGP' || libraryElements.element_type_1 === 'lamiSC'">
                                                    </bps-option>
                                                </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                            <bps-form-item class="width-column3 margin-bot-16">
                                                <bps-form-control>
                                                    <div class="sps-custom-section-property-input-container has-success">
                                                        <input style="width: 60px;" type="number" bps-input formControlName="element_ins_size_1"
                                                        [ngModel]="libraryElements.element_ins_size_1"
                                                        (ngModelChange)="changeElement_ins_size_1($event)">
                                                    </div>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td class="width-column4">
                                            <bps-form-item class="width-column4">
                                                <bps-form-control>
                                                    <bps-select style="width: 70px;" formControlName="element_interlayer_1"
                                                        [bpsDisabled]="libraryElements.element_type_1 === 'Glass'"
                                                        [ngModel]="libraryElements.element_interlayer_1"
                                                        bpsPlaceHolder="{{'configure.select' | translate }}"
                                                        (ngModelChange)="changeElement_interlayer_1($event)">
                                                        <bps-option bpsLabel="0.38" bpsValue="0.38"></bps-option>
                                                        <bps-option bpsLabel="0.76" bpsValue="0.76"></bps-option>
                                                        <bps-option bpsLabel="1.52" bpsValue="1.52"></bps-option>
                                                        <bps-option bpsLabel="2.28" bpsValue="2.28"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                    </tr>
                                    <tr style="height: 22px !important;">
                                        <td class="width-column1">
                                            <bps-form-item class="width-column1">
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_xx_2"
                                                        [ngModel]="libraryElements.element_xx_2"
                                                        (ngModelChange)="changeElement_xx_2($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-xx2-hs-option' | translate}}"
                                                            bpsValue="HS"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td class="width-column2">
                                            <bps-form-item class="width-column2 margin-bot-16">
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_type_2"
                                                        [ngModel]="libraryElements.element_type_2"
                                                        (ngModelChange)="changeElement_type_2($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type2-glass-option' | translate}}"
                                                            bpsValue="Glass"></bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type2-laminated-glass-option' | translate}}"
                                                            bpsValue="lamiPVB">
                                                        </bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type2-laminated-glass-sgp-option' | translate}}"
                                                            bpsValue="lamiSGP">
                                                        </bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type2-laminated-glass-ac-option' | translate}}"
                                                            bpsValue="lamiAcousticPVB">
                                                        </bps-option>
                                                        <bps-option
                                                        bpsLabel="{{'configure.structural-library-custom-element-type2-laminated-glass-sc-option' | translate}}"
                                                        bpsValue="lamiSC">
                                                    </bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                            <bps-form-item class="width-column2 margin-bot-16" *ngIf="libraryElements.selectedType === 'custom-triple'">
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_ins_type_2"
                                                        [ngModel]="libraryElements.element_ins_type_2"
                                                        (ngModelChange)="changeElement_ins_type_2($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-ins-type2-air-option' | translate}}"
                                                            bpsValue="Air"></bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-ins-type2-argon-option' | translate}}"
                                                            bpsValue="Argon"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td class="width-column3">
                                            <bps-form-item class="width-column3 margin-bot-16">
                                                <bps-form-control>
                                                    <bps-select class="has-success" *ngIf="applicationType === 'SRS'" formControlName="element_size_2"
                                                        [ngModel]="libraryElements.element_size_2"
                                                        (ngModelChange)="changeElement_size_2($event)">
                                                        <bps-option bpsLabel="4" bpsValue="4"
                                                            *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6"
                                                            *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8"
                                                            *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10"
                                                            *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12"
                                                            *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="14" bpsValue="14" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="16" bpsValue="16" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="18" bpsValue="18" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="20" bpsValue="20" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="24" bpsValue="24" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB'">
                                                        </bps-option>
                                                    </bps-select>
                                                    <bps-select style="width:60px" class="has-success" formControlName="element_size_2" *ngIf="applicationType === 'BPS'"
                                                    [ngModel]="libraryElements.element_size_2"
                                                    (ngModelChange)="changeElement_size_2($event)">
                                                    <bps-option bpsLabel="2" bpsValue="2"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="3" bpsValue="3"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="4" bpsValue="4"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="5" bpsValue="5"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="6" bpsValue="6"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="7" bpsValue="7"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="15" bpsValue="15"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="19" bpsValue="19"
                                                        *ngIf="libraryElements.element_type_2 === 'Glass'"></bps-option>
                                                    <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="14" bpsValue="14" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="16" bpsValue="16" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="18" bpsValue="18" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="20" bpsValue="20" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2=== 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                    <bps-option bpsLabel="24" bpsValue="24" *ngIf="libraryElements.element_type_2 === 'lamiAcousticPVB' || libraryElements.element_type_2 === 'lamiPVB' || libraryElements.element_type_2 === 'lamiSGP' || libraryElements.element_type_2 === 'lamiSC'">
                                                    </bps-option>
                                                </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                            <bps-form-item class="width-column3 margin-bot-16" *ngIf="libraryElements.selectedType === 'custom-triple'">
                                                <bps-form-control>
                                                    <div class="sps-custom-section-property-input-container has-success">
                                                        <input style="width: 60px;" type="number" bps-input formControlName="element_ins_size_2"
                                                            [ngModel]="libraryElements.element_ins_size_2"
                                                            (ngModelChange)="changeElement_ins_size_2($event)">
                                                    </div>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td class="width-column4">
                                            <bps-form-item class="width-column4">
                                                <bps-form-control>
                                                    <bps-select style="width: 70px;" formControlName="element_interlayer_2"
                                                        [bpsDisabled]="libraryElements.element_type_2 === 'Glass'"
                                                        [ngModel]="libraryElements.element_interlayer_2"
                                                        bpsPlaceHolder="{{'configure.select' | translate }}"
                                                        (ngModelChange)="changeElement_interlayer_2($event)">
                                                        <bps-option bpsLabel="0.38" bpsValue="0.38"></bps-option>
                                                        <bps-option bpsLabel="0.76" bpsValue="0.76"></bps-option>
                                                        <bps-option bpsLabel="1.52" bpsValue="1.52"></bps-option>
                                                        <bps-option bpsLabel="2.28" bpsValue="2.28"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                    </tr>
                                    <tr style="height: 22px !important;" *ngIf="libraryElements.selectedType === 'custom-triple'">
                                        <td>
                                            <bps-form-item>
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_xx_3"
                                                        [ngModel]="libraryElements.element_xx_3"
                                                        (ngModelChange)="changeElement_xx_3($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-xx3-hs-option' | translate}}"
                                                            bpsValue="HS"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td>
                                            <bps-form-item>
                                                <bps-form-control>
                                                    <bps-select style="width:170px;" class="has-success" formControlName="element_type_3"
                                                        [ngModel]="libraryElements.element_type_3"
                                                        (ngModelChange)="changeElement_type_3($event)">
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type3-glass-option' | translate}}"
                                                            bpsValue="Glass"></bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type3-luminated-glass-option' | translate}}"
                                                            bpsValue="lamiPVB">
                                                        </bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type3-laminated-glass-sgp-option' | translate}}"
                                                            bpsValue="lamiSGP">
                                                        </bps-option>
                                                        <bps-option
                                                            bpsLabel="{{'configure.structural-library-custom-element-type3-laminated-glass-ac-option' | translate}}"
                                                            bpsValue="lamiAcousticPVB">
                                                        </bps-option>
                                                        <bps-option
                                                        bpsLabel="{{'configure.structural-library-custom-element-type3-laminated-glass-sc-option' | translate}}"
                                                        bpsValue="lamiSC">
                                                    </bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td>
                                            <bps-form-item>
                                                <bps-form-control>
                                                    <bps-select style="width:60px;" class="has-success" formControlName="element_size_3"
                                                        [ngModel]="libraryElements.element_size_3"
                                                        (ngModelChange)="changeElement_size_3($event)" *ngIf="applicationType === 'SRS'">
                                                        <bps-option bpsLabel="4" bpsValue="4"
                                                            *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6"
                                                            *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8"
                                                            *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10"
                                                            *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12"
                                                            *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="14" bpsValue="14" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="16" bpsValue="16" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="18" bpsValue="18" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="20" bpsValue="20" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="24" bpsValue="24" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                    </bps-select>
                                                    <bps-select style="width:60px;" class="has-success" formControlName="element_size_3"
                                                        [ngModel]="libraryElements.element_size_3"
                                                        (ngModelChange)="changeElement_size_3($event)" *ngIf="this.applicationType === 'BPS'">
                                                        <bps-option bpsLabel="2" bpsValue="2" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="3" bpsValue="3" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="5" bpsValue="5" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="7" bpsValue="7" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="15" bpsValue="15" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="19" bpsValue="19" *ngIf="libraryElements.element_type_3 === 'Glass'"></bps-option>
                                                        <bps-option bpsLabel="4" bpsValue="4" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="6" bpsValue="6" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="8" bpsValue="8" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="10" bpsValue="10" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="12" bpsValue="12" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="14" bpsValue="14" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="16" bpsValue="16" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="18" bpsValue="18" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="20" bpsValue="20" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                        <bps-option bpsLabel="24" bpsValue="24" *ngIf="libraryElements.element_type_3 === 'lamiAcousticPVB' || libraryElements.element_type_3 === 'lamiPVB' || libraryElements.element_type_3 === 'lamiSGP' || libraryElements.element_type_3 === 'lamiSC'">
                                                        </bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                        <td>
                                            <bps-form-item>
                                                <bps-form-control>
                                                    <bps-select style="width:70px;" formControlName="element_interlayer_3"
                                                        [bpsDisabled]="libraryElements.element_type_3 === 'Glass'"
                                                        [ngModel]="libraryElements.element_interlayer_3"
                                                        bpsPlaceHolder="{{'configure.select' | translate }}"
                                                        (ngModelChange)="changeElement_interlayer_3($event)">
                                                        <bps-option bpsLabel="0.38" bpsValue="0.38"></bps-option>
                                                        <bps-option bpsLabel="0.76" bpsValue="0.76"></bps-option>
                                                        <bps-option bpsLabel="1.52" bpsValue="1.52"></bps-option>
                                                        <bps-option bpsLabel="2.28" bpsValue="2.28"></bps-option>
                                                    </bps-select>
                                                </bps-form-control>
                                            </bps-form-item>
                                        </td>
                                    </tr>
                                </table>
                                <div class="sps-break" style="margin-top: -9px;"></div>
                                <div style="width:100%">                            
                                    <div *ngIf="unified3DModel.ProblemSetting.EnableThermal" class="thermal-input-container">
                                        <div style="width: 280px;">
                                            <div class="canvas-rectangle-parameter">
                                                <div class="input-parameter-value">
                                                    <div class="row1-flex">
                                                        <h3>{{'configure.structural-library-custom-centre-of-glass-u-value' | translate}}</h3>
                                                            <div style="margin: 8px;" bpsType="variation-1" bps-popover
                                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                                [bpsPopoverContent]="windowUValueZoneContent">
                                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                            <ng-template #windowUValueZoneContent>
                                                                <div class="popover-variation3" style="width: 220px;">
                                                                    {{'configure.structural-library-custom-window-zone-content-glass' | translate}}
                                                                    <a>{{'configure.structural-library-custom-window-zone-content-u-value' |
                                                                        translate}}</a>
                                                                    {{'configure.structural-library-custom-window-zone-content-thermal-analysis' |
                                                                    translate}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container">
                                                                <input type="number" formControlName="uValue" style="width: 56px; background-color:  #474747 !important" bps-input
                                                                    placeholder="" [ngModel]="libraryElements.uValue"
                                                                    (ngModelChange)="changeElement_uValue($event)">
                                                                <span class="units" style="font-size: 11px;">(W/m²K)</span>
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
                                                <div class="input-parameter-value" *ngIf="isFrenchSystem">
                                                    <div class="row1-flex">
                                                        <h3>{{'configure.structural-library-custom-sg-winter-1/2' | translate}}</h3>
                                                            <div style="margin: 8px;" bpsType="variation-1" bps-popover
                                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                                [bpsPopoverContent]="sgWinterZoneContent">
                                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                            <ng-template #sgWinterZoneContent>
                                                                <div class="popover-variation3" style="width: 220px;">
                                                                    {{'configure.structural-library-custom-the' | translate}}
                                                                    <a>{{'configure.structural-library-custom-sg-winter-1/2' | translate}}</a>
                                                                    {{'configure.structural-library-custom-value-range-message' | translate }}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    
                                                    <div style="display: flex; flex-direction: row;">
                                                        <bps-form-item class="bps-form-item" style="width: 80px;">
                                                            <bps-form-control>
                                                                <div class="sps-custom-section-property-input-container">
                                                                    <input type="number" formControlName="sgWinter1" style="width: 56px; background-color: #474747 !important" bps-input
                                                                        placeholder="" [ngModel]="libraryElements.sgWinter1"
                                                                        (ngModelChange)="changeElement_sgWinter1($event)">
                                                                </div>
                                                            </bps-form-control>
                                                        </bps-form-item>
                                                        
    
                                                        <bps-form-item class="bps-form-item">
                                                            <bps-form-control>
                                                                <div class="sps-custom-section-property-input-container">
                                                                    <input type="number" formControlName="sgWinter2" style="width: 56px; background-color: #474747 !important" bps-input
                                                                        placeholder="" [ngModel]="libraryElements.sgWinter2"
                                                                        (ngModelChange)="changeElement_sgWinter2($event)">
                                                                </div>
                                                            </bps-form-control>
                                                        </bps-form-item>
                                                    </div>
                                                </div>
    
                                                <div class="input-parameter-value" *ngIf="isFrenchSystem">
                                                    <div class="row1-flex">
                                                        <h3>{{'configure.structural-library-custom-sg-summer-1/2' | translate}}</h3>
                                                            <div style="margin: 8px;" bpsType="variation-1" bps-popover
                                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                                [bpsPopoverContent]="sgSummerZoneContent">
                                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                            <ng-template #sgSummerZoneContent>
                                                                <div class="popover-variation3" style="width: 220px;">
                                                                    {{'configure.structural-library-custom-the' | translate}}
                                                                    <a>{{'configure.structural-library-custom-sg-summer-1/2' | translate}}</a>
                                                                    {{'configure.structural-library-custom-value-range-message' | translate }}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    
                                                    <div style="display: flex; flex-direction: row;">
                                                        <bps-form-item class="bps-form-item" style="width: 80px;">
                                                            <bps-form-control>
                                                                <div class="sps-custom-section-property-input-container">
                                                                    <input type="number" formControlName="sgSummer1" style="width: 56px; background-color: #474747 !important" bps-input
                                                                        placeholder="" [ngModel]="libraryElements.sgSummer1"
                                                                        (ngModelChange)="changeElement_sgSummer1($event)">
                                                                </div>
                                                            </bps-form-control>
                                                        </bps-form-item>
                                                        
    
                                                        <bps-form-item class="bps-form-item">
                                                            <bps-form-control>
                                                                <div class="sps-custom-section-property-input-container">
                                                                    <input type="number" formControlName="sgSummer2" style="width: 56px; background-color: #474747 !important" bps-input
                                                                        placeholder="" [ngModel]="libraryElements.sgSummer2"
                                                                        (ngModelChange)="changeElement_sgSummer2($event)">
                                                                </div>
                                                            </bps-form-control>
                                                        </bps-form-item>
                                                    </div>
                                                </div>
    
                                                <div class="input-parameter-value" *ngIf="!isFrenchSystem">
                                                    <div class="row1-flex">
                                                        <h3>{{'SHGC'}}</h3>
                                                            <div style="margin: 8px;" bpsType="variation-1" bps-popover
                                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                                [bpsPopoverContent]="shgcZoneContent">
                                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                            <ng-template #shgcZoneContent>
                                                                <div class="popover-variation3" style="width: 220px;">
                                                                    {{'configure.structural-library-custom-the' | translate}}
                                                                    <a>{{'SHGC'}}</a>
                                                                    {{'configure.structural-library-custom-value-range-message' | translate }}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container">
                                                                <input type="number" formControlName="shgc" style="width: 56px; background-color: #474747 !important" bps-input
                                                                    placeholder="" [ngModel]="libraryElements.shgc"
                                                                    (ngModelChange)="changeElement_shgc($event)">
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
    
                                                <div class="input-parameter-value">
                                                    <div class="row1-flex">
                                                        <h3>{{'configure.structural-library-custom-tlg' | translate}}</h3>
                                                            <div style="margin: 8px;" bpsType="variation-1" bps-popover
                                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                                [bpsPopoverContent]="tlgZoneContent">
                                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                            <ng-template #tlgZoneContent>
                                                                <div class="popover-variation3" style="width: 220px;">
                                                                    {{'configure.structural-library-custom-the' | translate}}
                                                                    <a>{{'TLG'}}</a>
                                                                    {{'configure.structural-library-custom-value-range-message' | translate }}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container">
                                                                <input type="number" formControlName="tlg" style="width: 56px; background-color: #474747 !important" bps-input
                                                                    placeholder="" [ngModel]="libraryElements.tlg"
                                                                    (ngModelChange)="changeElement_tlg($event)">
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div style="width: 280px;">
                                            <div class="canvas-rectangle-parameter" style="width: 278px;">
                                                <div class="row1-flex">
                                                    <h3>{{'configure.structural-library-custom-glass-rw' | translate}}</h3>
                                                    <div style="padding: 10px;" bpsType="variation-1" bps-popover
                                                        [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                        [bpsPopoverContent]="windowZoneContent">
                                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                    </div>
                                                    <ng-template #windowZoneContent>
                                                        <div class="popover-variation3" style="width: 220px;">
                                                            {{'configure.structural-library-custom-glass-window-zone-content-glass' |
                                                            translate}}
                                                            <a>{{'configure.structural-library-custom-window-zone-content-glass-rw' |
                                                                translate}}</a>
                                                            {{'configure.structural-library-custom-window-zone-content-glass-acoustic-analysis'
                                                            | translate}}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <bps-form-item class="bps-form-item" style="margin-bottom: 11px;">
                                                    <bps-form-control>
                                                        <div class="sps-custom-section-property-input-container has-success">
                                                            <input type="text" formControlName="glassrw" style="width: 56px; background-color: #262626;" bps-input
                                                                placeholder="" [ngModel]="libraryElements.glassrw"
                                                                (ngModelChange)="changeElement_glassrw($event)">
                                                            <span class="units" style="font-size: 11px;">(dB)</span>
                                                        </div>
                                                    </bps-form-control>
                                                </bps-form-item>
                                                <div class="input-parameter-value">
                                                    <h3>{{'configure.structural-library-custom-stc' | translate}}</h3>
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container has-success">
                                                                <input type="text" formControlName="stc" style="width: 56px; background-color: #262626;" bps-input
                                                                    placeholder="" [ngModel]="libraryElements.stc"
                                                                    (ngModelChange)="changeElement_stc($event)">
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
                                                <div class="input-parameter-value">
                                                    <h3>{{'configure.structural-library-custom-oitc' | translate}}</h3>
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container has-success">
                                                                <input type="text" formControlName="oitc" style="width: 56px; background-color: #262626;" bps-input
                                                                    placeholder="" [ngModel]="libraryElements.oitc"
                                                                    (ngModelChange)="changeElement_oitc($event)">
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
                                            </div>                                        
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="libraryElements.selectedType === 'custom-panel'">
                                <table style="width:100%">
                                    <tr>
                                        <th class="table-column-title" style="width:195px;">
                                            {{'configure.structural-library-custom-type' | translate}}</th>
                                        <th class="table-column-title" style="width:60px;">
                                            {{'configure.structural-library-custom-size' | translate}}</th>
                                        <th class="table-column-title" style="width:230px;"></th>
                                    </tr>
                                    <ng-container formArrayName="panelLayers">
                                        <tr *ngFor="let layerForm of panelLayers.controls; let i = index" style="margin-bottom: 16px;">
                                            <ng-container [formGroup]="layerForm">
                                                <td>
                                                            <!-- <div class="lesson-form-row" [formGroup]="lessonForm">
                                                                <mat-form-field appearance="fill">
                                                                    <input matInput
                                                                        formControlName="title"
                                                                        placeholder="Lesson title">
                                                                </mat-form-field>
                                                                <mat-form-field appearance="fill">
                                                                    <mat-select formControlName="level"
                                                                            placeholder="Lesson level">
                                                                        <mat-option value="beginner">Beginner</mat-option>
                                                                        <mat-option value="intermediate">Intermediate</mat-option>
                                                                        <mat-option value="advanced">Advanced</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                                <mat-icon class="delete-btn" (click)="deleteLesson(i)">
                                                                    delete_forever</mat-icon> -->
                                                    
                                                    <bps-form-item style="margin-bottom: 0px;">
                                                        <bps-form-control>
                                                            <bps-select class="has-success" formControlName="type"
                                                                *ngIf="libraryElements.selectedType === 'custom-panel'"
                                                                (ngModelChange)="changeElement_panelLayers($event)" style="width: 170px;">
                                                                <bps-option
                                                                    bpsLabel="{{'configure.structural-library-custom-panel-layer-aluminium-option' | translate}}"
                                                                    bpsValue="Aluminium"
                                                                    ></bps-option>
                                                                <bps-option
                                                                    bpsLabel="{{'configure.structural-library-custom-panel-layer-glass-option' | translate}}"
                                                                    bpsValue="Glass"
                                                                    ></bps-option>
                                                                <bps-option
                                                                    bpsLabel="{{'configure.structural-library-custom-panel-layer-air-option' | translate}}"
                                                                    bpsValue="Air"
                                                                    ></bps-option>
                                                                <bps-option
                                                                    bpsLabel="{{'configure.structural-library-custom-panel-layer-insulation-option' | translate}}"
                                                                    bpsValue="Insulation"
                                                                    ></bps-option>
                                                            </bps-select>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </td>
                                                <td>
                                                    <bps-form-item style="margin-bottom: 0px;">
                                                        <bps-form-control>
                                                            <div class="has-success">
                                                                <input style="width: 60px;" type="text" name="size" bps-input formControlName="size"
                                                                    *ngIf="libraryElements.selectedType === 'custom-panel'" pattern="^(0|[1-9]\d?|1\d{2}|200)$"
                                                                    (ngModelChange)="changeElement_panelLayers($event)">
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <button type="button" bps-button bpsType="variation-15" (click)="deleteLayer(i)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.061" height="16.333" viewBox="0 0 12.061 16.333">
                                                        <defs>
                                                            <style>
                                                            .prefix__cls-c1{fill:none;stroke:#fff;stroke-width:1.5px}
                                                            </style>
                                                        </defs>
                                                        <g id="prefix__sps_round_icon_deleteconfiguration_white" transform="translate(-1319 -66.541)">
                                                            <g id="prefix__Group_2699" data-name="Group 2699" transform="translate(250.901 4.126)">
                                                            <g id="prefix__Rectangle_1411" class="prefix__cls-c1" data-name="Rectangle 1411" transform="translate(1068.886 65.642)">
                                                                <rect width="10.485" height="13.106" rx="1" style="stroke:none"/>
                                                                <rect width="8.985" height="11.606" x=".75" y=".75" rx=".25" style="fill:none"/>
                                                            </g>
                                                            <path id="prefix__Path_989" d="M-9638-11019.622v-11.223" class="prefix__cls-c1" data-name="Path 989" transform="translate(10712.129 11097.496)"/>
                                                            <path id="prefix__Path_990" d="M-9640.47-11032.232l1.6-1.6h1.75l1.6 1.6" class="prefix__cls-c1" data-name="Path 990" transform="translate(10712.152 11096.999)"/>
                                                            <path id="prefix__Path_991" d="M-9643-11033h12.061" class="prefix__cls-c1" data-name="Path 991" transform="translate(10711.099 11097.769)"/>
                                                            </g>
                                                        </g>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </table>
                                <div style="width: 100%; align-items: center;">
                                    <button bps-button type="button"bpsType="variation-16" class="plus-btn" [disabled]="isAddDisable" style="display:block; margin-left: auto; margin-right: auto; margin-bottom: 0px;" (click)="addLayer()">
                                            <img src="/assets/bps-icons/sps_plus_icon_configuration_enabled.svg" />
                                    </button>
                                </div>
                                <table style="width:100%">
                                    <tr>
                                        <th colspan="2">
                                            <div class="sps-break"></div>
                                        </th>
                                    </tr>
                                    <tr *ngIf="unified3DModel.ProblemSetting.EnableThermal">
                                        <th style="width:50%">
                                            <div class="canvas-rectangle-parameter">
                                                <div class="input-parameter-value">
                                                    <div class="row1-flex">
                                                        <h3>{{'configure.structural-library-custom-up' | translate}}</h3>
                                                            <div style="padding: 10px;" bpsType="variation-1" bps-popover
                                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                                                [bpsPopoverContent]="windowUpZoneContent">
                                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                            <ng-template #windowUpZoneContent>
                                                                <div class="popover-variation3" style="width: 220px;">
                                                                    {{'configure.structural-library-custom-window-zone-content-panel' | translate}}
                                                                    <a>{{'configure.structural-library-custom-window-zone-content-u-value' |
                                                                        translate}}</a>
                                                                    {{'configure.structural-library-custom-window-zone-content-thermal-analysis' |
                                                                    translate}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container">
                                                                <input type="text" formControlName="up" style="width: 56px; background-color: #474747 !important;" bps-input
                                                                    placeholder="" [ngModel]="libraryElements.up"
                                                                    (ngModelChange)="changeElement_up($event)"
                                                                    *ngIf="libraryElements.selectedType === 'custom-panel'">
                                                                    <span class="units" style="font-size: 11px;" *ngIf="libraryElements.selectedType === 'custom-panel'">(W/m²K)</span>
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
                                                <div class="input-parameter-value">
                                                    <div class="row1-flex">
                                                        <h3>{{'configure.glass-panel-absorption-coefficient' | translate}}</h3>
                                                    </div>
                                                    <bps-form-item class="bps-form-item">
                                                        <bps-form-control>
                                                            <div class="sps-custom-section-property-input-container">
                                                                <input type="text" formControlName="absCoeffControl" style="width: 56px; background-color: #474747 !important;" bps-input
                                                                    [ngModel]="libraryElements.AbsorptionCoefficient"
                                                                    (ngModelChange)="changeElement_absCoeff($event)"
                                                                    *ngIf="libraryElements.selectedType === 'custom-panel'">
                                                            </div>
                                                        </bps-form-control>
                                                    </bps-form-item>
                                                </div>
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </table>
                            </div>
                        </form>
                    </div>            
                </div>
            </div>
            <!-- <div class="sps-break" style="margin-top: 12px"></div> -->
            <div class="sps-left-popout-level2-bottom-section">
                <!-- <button [disabled]="isAddButtonDisplay" [ngClass]="{'activeDeleteBtn': !isAddButtonDisplay}"
                    id="sps-custom-article-delete-btn" class="sps-custom-article-delete-btn" bps-button
                    bpsType="variation-4" style="min-width: 80px" (click)="onDelete()">{{'configure.delete' | translate
                    }}</button> -->
                <!-- Delete -->
                <button
                    id="sps-custom-article-reset-btn" class="sps-custom-article-reset-btn" bps-button
                    bpsType="variation-4" style="min-width: 80px" (click)="onReset()">{{'configure.structural-library-custom-reset' | translate
                    }}</button>
                <!-- Reset -->
                <!-- [ngClass]="{'active': isFormValid()}" -->
                <button [disabled]="!(isFormValid()) || hasDuplicateGlass" *ngIf="isAddButtonDisplay"
                    id="sps-custom-article-add-btn" class="sps-custom-article-add-btn" bps-button bpsType="variation-4"
                    style="min-width: 80px" (click)="onAdd()">{{'configure.add' |
                    translate }}</button>
                <!-- Add -->
                <button [disabled]="!(isFormValid())  || hasDuplicateGlass"
                    *ngIf="!isAddButtonDisplay" id="sps-custom-article-update-btn" class="sps-custom-article-add-btn"
                    bps-button bpsType="variation-4" (click)="onUpdate()">{{'configure.update' | translate }}</button>
                <!-- Update -->
            </div>
        </div>
        <!-- <div *ngIf="isDisplay" id="sps-left-popout-container-level2-overlay-left"
            class="sps-left-popout-container-level2-overlay-left"></div>
        <div *ngIf="isDisplay" id="sps-left-popout-container-level2-overlay-right"
            class="sps-left-popout-container-level2-overlay-right"></div> -->
    </div>
    
    <ng-template #selectContactTemp>
        <div class="emptyDiv" style="width: 182px !important;">
            
        </div>
    </ng-template>
    
    <app-notification-custom #notifCustomTemplate></app-notification-custom>
</div>


