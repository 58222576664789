import { Component, OnInit, Input, Output, ViewChild, TemplateRef, ChangeDetectorRef, OnDestroy, AfterViewInit, EventEmitter } from '@angular/core';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { BpsUnifiedProblem } from 'src/app/app-common/models/bps-unified-problem';
import { CeldType, BpsTableComponent } from 'bps-components-lib';
import { ReportService } from 'src/app/app-structural/services/report.service';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Subject, zip } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ResultService } from 'src/app/app-structural/services/result.service';
import { IframeService } from 'src/app/app-structural/services/iframe.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadService } from 'src/app/app-layout/services/download.service';
import { AnalysisResult } from 'src/app/app-common/models/analysis-result';
import { NzModalService } from 'ng-zorro-antd';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';

declare var tinymce: any;
@Component({
  selector: 'app-left-report',
  templateUrl: './left-report.component.html',
  styleUrls: ['./left-report.component.css']
})
export class LeftReportComponent implements OnInit, OnDestroy, AfterViewInit  {
  @ViewChild('summaryReportCellTemplate', { read: TemplateRef, static: true }) summaryReportCellTemplate: TemplateRef<{}>;
  @ViewChild('configColTemplate', { read: TemplateRef, static: true }) configColTemplate: TemplateRef<{}>;
  @ViewChild('noteColTemplate', { read: TemplateRef, static: true }) noteColTemplate: TemplateRef<{}>;
  @ViewChild('regReportColTemplate', { read: TemplateRef, static: true }) regReportColTemplate: TemplateRef<{}>;
  @ViewChild('summaryReportColTemplate', { read: TemplateRef, static: true }) summaryReportColTemplate: TemplateRef<{}>;
  @ViewChild('noteTemplate', { read: TemplateRef, static: true }) noteTemplate: TemplateRef<{}>;
  @ViewChild('cellTemplate', { read: TemplateRef, static: true }) cellTemplate: TemplateRef<{}>;
  @ViewChild('bpsExampleTable', { read: BpsTableComponent, static: false }) gridComponent: BpsTableComponent;

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder,
    private configureService: ConfigureService,
    private sanitizer: DomSanitizer,
    private downloadService: DownloadService,
    private cdr: ChangeDetectorRef,
    private resultService: ResultService,
    private iframeService: IframeService,
    private reportService: ReportService,
    private translate: TranslateService,
    private modalService: NzModalService) {
    this.validateForm = this.fb.group({
      comment: ['', [Validators.required]],
    });
    this.validateForm2 = this.fb.group({
      languageSelectionControl: ['', [Validators.required]]
    });
    }

  @Input() projectName: string;

  @Input() projectLocation: string;
  @Input() problemGuid: string;

  @Input() projectGuid: string; //ProjectGuid to get all the problems
  @Input() listOfProblems: BpsUnifiedProblem[] = [];

  @Input() currentProblem: BpsUnifiedProblem;

  @Output() ngNotificaionShow: EventEmitter<any> = new EventEmitter<any>();
  //#region new code

  configurationCustomGrid: any;
  listOfDisplayData = [];
  data = [];
  valueA = true;
  valueB = false;
  isOperating = false;
  isAllDisplayDataCheckedAcoustic = false;
  isAllDisplayDataCheckedStructural = false;
  isAllDisplayDataCheckedThermal = false;
  isAllDisplayDataCheckedFullReport = false;
  isAllDisplayDataDisabledAcoustic = false;
  isAllDisplayDataDisabledStructural = false;
  isAllDisplayDataDisabledThermal = false;

  mapOfCheckedIdAcoustic: { [key: string]: boolean } = {};
  mapOfCheckedIdStructural: { [key: string]: boolean } = {};
  mapOfCheckedIdThermal: { [key: string]: boolean } = {};
  mapOfCheckedIdFullStructural: { [key: string]: boolean } = {};
  numberOfCheckedAcoustic = 0;
  numberOfCheckedStructuralSummary = 0;
  numberOfCheckedStructuralFull = 0;
  numberOfCheckedThermal = 0;

  //popup change starts
  isNotesModalVisible: boolean = false;
  disabled: boolean = true;
  comment: string;
  // hideEditor : boolean = true;
  //editorIsValid : boolean = false;
  validateForm: FormGroup;
  validateForm2: FormGroup;
  //popup change ends

  currentProblemGuidMoreOption: string;
  selectedNotesProblemGuid: string;

  enableDownload: boolean = false;
  selectedProblem: any;

  areAllReportsReady: boolean = false;

  // reportNotReadyModal_title: string = this.translate.instant(_('report.report-is-not-ready-structral-full-report'));
  // reportNotReadyModal_text: string = this.translate.instant(_('report.shortly-available-for-download'));
  // reportDownloadLimit_title: string = this.translate.instant(_('report.download-limit-title'));
  // reportDownloadLimit_text: string = this.translate.instant(_('report.download-limit-message'));
  reportNotReadyModal_isVisible = false;

  reportsExportPopup_isVisible : boolean = false;
  requestedLanguageForReport: string;
  defaultUserLanguage: string;
  fromReportLang: string;
  toReportLang: string;

  reportNotReadyModal_handleOk(): void {
    this.reportNotReadyModal_isVisible = false;
  }

  reportNotReadyModal_handleCancel(): void {
    this.reportNotReadyModal_isVisible = false;
  }

 

  ngAfterViewInit() {
    let disabledAcousticColumn: boolean = true, disabledStructuralColumn: boolean = true, disabledThermalColumn: boolean = true;
    this.listOfProblems.forEach((problem) => {
      if (disabledAcousticColumn || disabledStructuralColumn || disabledThermalColumn) {
        let unifiedModel: UnifiedModel = JSON.parse(problem.UnifiedModel);
        if (unifiedModel && unifiedModel.AnalysisResult) {
          if (disabledAcousticColumn && unifiedModel.AnalysisResult.AcousticResult)
            disabledAcousticColumn = false;
          if (disabledStructuralColumn && (unifiedModel.AnalysisResult.StructuralResult || unifiedModel.AnalysisResult.FacadeStructuralResult || unifiedModel.AnalysisResult.UDCStructuralResult))
            disabledStructuralColumn = false;
          if (disabledThermalColumn && unifiedModel.AnalysisResult.ThermalResult)
            disabledThermalColumn = false;
        }
      }
    });
    this.configurationCustomGrid = {
      fields: [
        {
          celdType: CeldType.Default,
          property: 'configuration',
          width: '210px',
          template: {
            ref: this.configColTemplate,
            context: {}
          }
        },
        {
          celdType: CeldType.TemplateRef,
          property: 'note',
          width: '55px',
          template: {
            ref: this.noteColTemplate,
            context: {}
          },
          ngClass: {
            [`bps-centered`]: true,
            [`bps-no-padding`]: true
          }
        },
        {
          celdType: CeldType.TemplateRef,
          property: 'status',
          width: '110px',
          template: {
            ref: this.regReportColTemplate,
            context: {
              disabledAcoustic: disabledAcousticColumn,
              disabledStructural: disabledStructuralColumn,
              disabledThermal: disabledThermalColumn
            }
          },
          ngClass: {
            [`bps-no-padding`]: true,
          }
        },
        {
          celdType: CeldType.TemplateRef,
          property: 'options',
          width: '80px',
          template: {
            ref: this.summaryReportColTemplate,
            context: {}
          },
          ngClass: {
            [`bps-centered`]: true,
            [`bps-no-padding`]: true,
          }
        },
      ],
      fieldId: 'id'
    };
    this.getAllProblems();
  }

  ngOnInit(): void {
    this.reportService.selectTableRowSubject.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (this.gridComponent && data) {
        this.gridComponent.selectRow(data, true);
      }
    });
    this.defaultUserLanguage = this.configureService.getLanguage();
    this.requestedLanguageForReport = this.defaultUserLanguage;
    this.validateForm2.controls['languageSelectionControl'].markAsTouched();
    this.validateForm2.controls['languageSelectionControl'].updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  operateData(): void {
    this.isOperating = true;
    setTimeout(() => {
      this.refreshStatusAcoustic();
      this.refreshStatusStructural();
      this.refreshStatusThermal();
      this.refreshFullStatusReport();
      this.isOperating = false;
    }, 1000);
  }

  loadData(): void {
    this.data = [];
    for (let i = 0; i < this.listOfProblems.length; i++) {
      let problemCell: BpsUnifiedProblem = this.listOfProblems[i];
      let unifiedModelPerCell: UnifiedModel = JSON.parse(problemCell.UnifiedModel);
      let isComputed = unifiedModelPerCell && unifiedModelPerCell.AnalysisResult ? false : true;
      let item = {
        id: '' + i,
        configuration: problemCell.ProblemName,
        note: {
          ref: this.noteTemplate,
          context: {
            index: i,
            id: problemCell.ProblemGuid,
            disabled: isComputed,
            disabledNote: unifiedModelPerCell && unifiedModelPerCell.ProblemSetting && unifiedModelPerCell.ProblemSetting.UserNotes ? false : true

          }
        },
        status: {
          ref: this.cellTemplate,
          context: {
            id: '' + i,
            disabled: isComputed,
            disabledAcoustic: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult && unifiedModelPerCell.AnalysisResult.AcousticResult),
            disabledStructural: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult
              && (unifiedModelPerCell.AnalysisResult.StructuralResult && unifiedModelPerCell.AnalysisResult.StructuralResult.summaryFileUrl ||
                unifiedModelPerCell.AnalysisResult.FacadeStructuralResult && unifiedModelPerCell.AnalysisResult.FacadeStructuralResult.summaryFileUrl ||
                unifiedModelPerCell.AnalysisResult.UDCStructuralResult && unifiedModelPerCell.AnalysisResult.UDCStructuralResult.summaryFileUrl)),
            disabledThermal: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult && unifiedModelPerCell.AnalysisResult.ThermalResult)
          }
        },
        options: {
          ref: this.summaryReportCellTemplate,
          context: {
            index: i,
            id: '' + i,
            disabled: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult && (unifiedModelPerCell.AnalysisResult.StructuralResult || unifiedModelPerCell.AnalysisResult.FacadeStructuralResult || unifiedModelPerCell.AnalysisResult.UDCStructuralResult))
          }
        },
        disabled: isComputed,
        disabledAcoustic: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult && unifiedModelPerCell.AnalysisResult.AcousticResult),
        disabledStructural: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult
          && (unifiedModelPerCell.AnalysisResult.StructuralResult && unifiedModelPerCell.AnalysisResult.StructuralResult.summaryFileUrl ||
            unifiedModelPerCell.AnalysisResult.FacadeStructuralResult && unifiedModelPerCell.AnalysisResult.FacadeStructuralResult.summaryFileUrl ||
            unifiedModelPerCell.AnalysisResult.UDCStructuralResult && unifiedModelPerCell.AnalysisResult.UDCStructuralResult.summaryFileUrl)),
        disabledThermal: !(unifiedModelPerCell && unifiedModelPerCell.AnalysisResult && unifiedModelPerCell.AnalysisResult.ThermalResult),
        problemGuid: problemCell.ProblemGuid
      };

      this.data.push(item);
    }
    this.reportService.sendAllData(this.data);
  }

  refreshStatusAcoustic($event = false, id = null): void {
    if (id !== null && id !== undefined) {
      this.mapOfCheckedIdAcoustic[id] = $event;
    }
    this.isAllDisplayDataCheckedAcoustic = this.listOfDisplayData.filter(item => !item.disabledAcoustic).length > 0 && this.listOfDisplayData
      .filter(item => !item.disabledAcoustic)
      .every(item => this.mapOfCheckedIdAcoustic[item.id]);
    this.isAllDisplayDataDisabledAcoustic = this.listOfDisplayData.filter(item => !item.disabledAcoustic).length == 0;
    this.numberOfCheckedAcoustic = this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]).length;
    let totalEnabledChecks = this.numberOfCheckedAcoustic + this.numberOfCheckedStructuralSummary + this.numberOfCheckedThermal + this.numberOfCheckedStructuralFull;
    if (totalEnabledChecks > 0) {
      this.enableDownload = true;
    } else
      this.enableDownload = false;
  }

  refreshStatusStructural($event = false, id = null): void {
    if (id !== null && id !== undefined) {
      this.mapOfCheckedIdStructural[id] = $event;
    }
    this.isAllDisplayDataCheckedStructural = this.listOfDisplayData
    .filter(item => !item.disabledStructural).length > 0 && this.listOfDisplayData
      .filter(item => !item.disabledStructural)
      .every(item => this.mapOfCheckedIdStructural[item.id]);
    this.isAllDisplayDataDisabledStructural = this.listOfDisplayData.filter(item => !item.disabledStructural).length == 0;
    this.numberOfCheckedStructuralSummary = this.data.filter(item => this.mapOfCheckedIdStructural[item.id]).length;
    let totalEnabledChecks = this.numberOfCheckedAcoustic + this.numberOfCheckedStructuralSummary + this.numberOfCheckedThermal + this.numberOfCheckedStructuralFull;
    if (totalEnabledChecks > 0) {
      this.enableDownload = true;
    } else
      this.enableDownload = false;
  }

  refreshFullStatusReport($event = false, id = null): void {
    if (id !== null && id !== undefined) {
      this.mapOfCheckedIdFullStructural[id] = $event;
    }
    this.isAllDisplayDataCheckedFullReport = this.listOfDisplayData
      .filter(item => !item.disabledStructural).length > 0
      &&
      this.listOfDisplayData
        .filter(item => !item.disabledStructural)
        .every(item => this.mapOfCheckedIdFullStructural[item.id]);

    this.numberOfCheckedStructuralFull = this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]).length;
    let totalEnabledChecks = this.numberOfCheckedAcoustic + this.numberOfCheckedStructuralSummary + this.numberOfCheckedThermal + this.numberOfCheckedStructuralFull;
    if (totalEnabledChecks > 0) {
      this.enableDownload = true;
    } else
      this.enableDownload = false;
  }


  refreshStatusThermal($event = false, id = null): void {
    if (id !== null && id !== undefined) {
      this.mapOfCheckedIdThermal[id] = $event;
    }
    this.isAllDisplayDataCheckedThermal = this.listOfDisplayData
    .filter(item => !item.disabledThermal).length > 0 && this.listOfDisplayData
      .filter(item => !item.disabledThermal)
      .every(item => this.mapOfCheckedIdThermal[item.id]);
    this.isAllDisplayDataDisabledThermal = this.listOfDisplayData.filter(item => !item.disabledThermal).length == 0;
    this.numberOfCheckedThermal = this.data.filter(item => this.mapOfCheckedIdThermal[item.id]).length;
    let totalEnabledChecks = this.numberOfCheckedAcoustic + this.numberOfCheckedStructuralSummary + this.numberOfCheckedThermal + this.numberOfCheckedStructuralFull;
    if (totalEnabledChecks > 0) {
      this.enableDownload = true;
    } else
      this.enableDownload = false;
  }

  checkAllAcoustic(value: boolean): void {
    this.listOfDisplayData.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedIdAcoustic[item.id] = value));
    this.refreshStatusAcoustic();
  }

  checkAllStructural(value: boolean): void {
    this.listOfDisplayData.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedIdStructural[item.id] = value));
    this.refreshStatusStructural();
  }
  checkAllThermal(value: boolean): void {
    this.listOfDisplayData.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedIdThermal[item.id] = value));
    this.refreshStatusThermal();
  }

  stopPropagation($event: Event) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
  }

  log($event) {} 
  

  //#endregion new code ends
  //technical code below


  getFileName(resp: HttpResponse<Blob>): string {
    let fileName = "Report.pdf";
    if (resp.headers && resp.headers.get('content-disposition')) {
      if (resp.headers.get('content-disposition').split('filename=') && resp.headers.get('content-disposition').split('filename=').length > 0)
        if (resp.headers.get('content-disposition').split('filename=')[1].split(" ") && resp.headers.get('content-disposition').split('filename=')[1].split(".pdf").length > 0) {
          if (resp.headers.get('content-disposition').split('filename=')[1].split(".pdf")[0]) {
            // fileName = resp.headers.get('content-disposition').split('filename=')[1].split(" ")[1].replace(/"/g, '').replace(';', '').replace('.pdf', '');
            let str = resp.headers.get('content-disposition').split('filename=')[1].split(".pdf")[0];
            fileName = str.substring(str.lastIndexOf(" ") + 1, str.length);
          }

        }
    }
    return fileName;
  }
  getProblemName(resp: HttpResponse<Blob>): string {
    let problemName = "Report.pdf";
    if (resp.headers && resp.headers.get('content-disposition')) {
      if (resp.headers.get('content-disposition').split('; name=') && resp.headers.get('content-disposition').split('; name=').length > 0)
        if (resp.headers.get('content-disposition').split('; name=')[1].split(" ") && resp.headers.get('content-disposition').split('; name=')[1].split(" ").length > 0) {
          problemName = resp.headers.get('content-disposition').split('; name=')[1].replace(/"/g, '');
        }
    }
    return problemName;
  }

  getProblemGuid(resp: HttpResponse<Blob>): string {
    let configurationName = "Configuration";
    if (resp.headers && resp.headers.get('content-disposition')
    && resp.headers.get('content-disposition').split('filename=').length > 1
    && resp.headers.get('content-disposition').split('filename=')[1].split('.pdf').length > 0) {
      
      let filename = resp.headers.get('content-disposition').split('filename=')[1].replace(/"/g, '').split(';')[0];
      configurationName = filename.replace('_acoustic_report.pdf','')
                                  .replace('_structural_summary_report.pdf','')
                                  .replace('_structural_report.pdf','')
                                  .replace('_thermal_report.pdf','');
    }
    return configurationName;
  }
  getProblemGuidForRequestedLanguage(resp: HttpResponse<Blob>): string {
    let configurationName = "Configuration";
    if (resp.headers && resp.headers.get('content-disposition')
    && resp.headers.get('content-disposition').split('filename=').length > 1
    && resp.headers.get('content-disposition').split('filename=')[1].split('_').length > 0) {
      
      let filename = resp.headers.get('content-disposition').split('filename=')[1].split('_')[0].replace(/"/g, '').split(';')[0];
      configurationName = filename.replace('_acoustic_report.pdf','')
                                  .replace('_structural_summary_report.pdf','')
                                  .replace('_structural_report.pdf','')
                                  .replace('_thermal_report.pdf','');
    }
    return configurationName;
  }
  onMoreOptionClick(selectedRowProblemGuid) {
    this.currentProblemGuidMoreOption = selectedRowProblemGuid;
  }
  onSummaryReportClick() {
    if (this.currentProblemGuidMoreOption) {
      let problemGuid = this.currentProblemGuidMoreOption;
      this.currentProblemGuidMoreOption = null;
      let selectedProblemList = this.listOfProblems.filter(x => x.ProblemGuid == problemGuid);
      if (selectedProblemList && selectedProblemList.length == 1) {
        let selectedProblem = selectedProblemList[0];
        let unifiedModel: UnifiedModel = JSON.parse(selectedProblem.UnifiedModel);
        if (unifiedModel) {
          let reportFileUrl = null;
          if (unifiedModel.AnalysisResult.StructuralResult && unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl;
          else if (unifiedModel.AnalysisResult.FacadeStructuralResult && unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl;
          else if (unifiedModel.AnalysisResult.UDCStructuralResult && unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl;
          if (reportFileUrl) {
            var param = this.getBuildParam(reportFileUrl);
            this.configureService.GetPCReport(param[0], param[1], param[2].replace('.pdf', ''))
              .pipe(takeUntil(this.destroy$)).subscribe((response) => {
                let pipe = new DatePipe(unifiedModel.UserSetting.Language);
                let dateFormat = this.configureService.getDateFormat_byCulture();
                let fileName = this.getProblemName(response) + "_" + this.getFileName(response) + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                saveAs(response.body, fileName);
              });
          }
        }
      }
    }
  }
  getBuildParam(reportFileUrl: string) {
    return reportFileUrl.split('/');
  }
  
  getProblemNameFromGuid(problemGuid) {
    return this.listOfProblems.filter(problem => {return problem.ProblemGuid == problemGuid})[0].ProblemName
  }

  getLanguageText(lang: string){
    switch(lang){
      case "en-US":
        return this.translate.instant(_('report.english_v2'))+" (EN)";
      case "de-DE":
        return this.translate.instant(_('report.german_v2'))+" (DE)";
      case "fr-FR":
        return this.translate.instant(_('report.french_v2'))+" (FR)";
    }
  }

  showDownloadProgressBar: boolean = false;
  //to download report of language other than default one.
  isLoading: boolean = false;
  progressBarStatus = "active";
  resetProgressBar(){
    this.progressBarStatus = "active";
    this.commentProgress.progress = 0;
    this.addition = 100;
    this.loading = true;
  }

  requestedLanguageForReportShort:string="";
  onDownloadClick() {
    this.resetProgressBar();
    if (this.requestedLanguageForReport) {
      this.requestedLanguageForReportShort = this.requestedLanguageForReport.split('-') && this.requestedLanguageForReport.split('-').length>0?
                  this.requestedLanguageForReport.split('-')[0].toUpperCase():"";
      if (this.requestedLanguageForReport != this.defaultUserLanguage) {
        //
        this.fromReportLang = this.getLanguageText(this.defaultUserLanguage);
        this.toReportLang = this.getLanguageText(this.requestedLanguageForReport);
        let language;
        let selectedIconsAcoutic = [];
        let selectedIconsStructural = [];
        let selectedIconsStructuralFull = [];
        let selectedIconsThermal = [];
        if (this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]).length > 0)
          selectedIconsAcoutic = this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]);
        if (this.data.filter(item => this.mapOfCheckedIdStructural[item.id]).length > 0)
          selectedIconsStructural = this.data.filter(item => this.mapOfCheckedIdStructural[item.id]);
        if (this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]).length > 0)
          selectedIconsStructuralFull = this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]);
        if (this.data.filter(item => this.mapOfCheckedIdThermal[item.id]).length > 0)
          selectedIconsThermal = this.data.filter(item => this.mapOfCheckedIdThermal[item.id]);

        if (selectedIconsAcoutic.length > 0 || selectedIconsStructural.length > 0 || selectedIconsThermal.length > 0 || selectedIconsStructuralFull.length > 0) {
          let downloadReportCount = selectedIconsAcoutic.length + selectedIconsStructural.length + selectedIconsStructuralFull.length + selectedIconsThermal.length;
          if (downloadReportCount > 10) {
            this.ngNotificaionShow.next('WarningDownloadLimitReport');
          } else {
            this.reportsExportPopup_isVisible = true;
           

          }
        }
        //
      } else {
        //download reports for default language
        this.DownloadReportsForDefaultLanguage();
      }
    }
    //this.requestedLanguageForReport = null;
  }
  
  exportReports(): void {
    this.reportsExportPopup_isVisible = false;
    let selectedIconsAcoutic = [];
    let selectedIconsStructural = [];
    let selectedIconsStructuralFull = [];
    let selectedIconsThermal = []; 
    if (this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]).length > 0)
      selectedIconsAcoutic = this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]);
    if (this.data.filter(item => this.mapOfCheckedIdStructural[item.id]).length > 0)
      selectedIconsStructural = this.data.filter(item => this.mapOfCheckedIdStructural[item.id]);
    if (this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]).length > 0)
      selectedIconsStructuralFull = this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]);
    if (this.data.filter(item => this.mapOfCheckedIdThermal[item.id]).length > 0)
      selectedIconsThermal = this.data.filter(item => this.mapOfCheckedIdThermal[item.id]);

    if (selectedIconsAcoutic.length > 0 || selectedIconsStructural.length > 0 || selectedIconsThermal.length > 0 || selectedIconsStructuralFull.length > 0) {
      this.showDownloadProgressBar = true;
      this.DownloadEnable(selectedIconsAcoutic, selectedIconsStructuralFull, selectedIconsStructural, selectedIconsThermal);
    } else{ 
      this.showDownloadProgressBar = false;
    }

  }

  cancelExportReports(): void {
    this.reportsExportPopup_isVisible = false;
  }

  callsProgress : [];
  DownloadEnable(selectedIconsAcoutic, selectedIconsStructuralFull, selectedIconsStructural, selectedIconsThermal){
    // totalCount += selectedIconsAcoutic.length;
    let allProblemsSelected = this.listOfProblems;
    let problemsToBeDownloaded: BpsUnifiedProblem[] = [];

    let responseCount: number = 0;
    let requestCount = 0;
    allProblemsSelected.forEach((problem) => {
      let calls = [];
      let unifiedModel: UnifiedModel;
      unifiedModel = JSON.parse(problem.UnifiedModel); 
      unifiedModel.AnalysisResult = null;
      let reportFileUrl = null;
      if (unifiedModel) {
        if (selectedIconsAcoutic.some(x => x.problemGuid == problem.ProblemGuid)) {
          unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
          if (!(unifiedModel.ProblemSetting.ProductType === "Window" && unifiedModel.ModelInput.Geometry.PanelSystems.length > 0)){
            calls.push(this.resultService.runAcousticAnalysis(unifiedModel, this.configureService.applicationType));
            requestCount++;
          }
          console.log(unifiedModel.ProblemSetting.ConfigurationName);
        }
        if (selectedIconsStructuralFull.some(x => x.problemGuid == problem.ProblemGuid)) { 
          console.log(unifiedModel.ProblemSetting.ConfigurationName);
          unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
          calls.push(this.resultService.runStructuralAnalysis(unifiedModel, this.configureService.applicationType));
          requestCount++;
        }
        if (selectedIconsStructural.some(x => x.problemGuid == problem.ProblemGuid)) {
          
          if(!selectedIconsStructuralFull.some(x => x.problemGuid == problem.ProblemGuid)){
            console.log(unifiedModel.ProblemSetting.ConfigurationName);
            unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
            calls.push(this.resultService.runStructuralAnalysisShortReport(unifiedModel, this.configureService.applicationType));
            requestCount++;
          }
        }
        if (selectedIconsThermal.some(x => x.problemGuid == problem.ProblemGuid)) {
          console.log(unifiedModel.ProblemSetting.ConfigurationName);
          unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
          if (!(unifiedModel.ProblemSetting.ProductType === "Window" && unifiedModel.ModelInput.Geometry.PanelSystems.length > 0))
            calls.push(this.resultService.runThermalAnalysis(unifiedModel, this.configureService.applicationType));
            requestCount++;
        }

        //#region STEP 2
        if (calls.length > 0) {
          console.log("Calls length: "+ calls.length);
          //calls.forEach()
          let isUserDefineLoadSelected = unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural !== null ? unifiedModel.ModelInput.Structural.isUserDefineLoadSelected : false;
          zip(...calls).pipe(takeUntil(this.destroy$))
            .subscribe((responses) => {
              responses.forEach((response: any) => {
                if (response) {

                  switch (response.type) {
                    case "acoustic":
                      if (response.data && response.data.AnalysisResult && response.data.AnalysisResult.AcousticResult) {
                        // if (!unifiedModel.AnalysisResult) unifiedModel.AnalysisResult = new AnalysisResult();
                        unifiedModel.AnalysisResult.AcousticResult = response.data.AnalysisResult.AcousticResult;
                        let postCodeValue = unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.dinWindLoadInput ?
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue : "";
                        unifiedModel.ModelInput.Structural = response.data.ModelInput.Structural;
                        if (postCodeValue && postCodeValue !== "") {
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue = postCodeValue;
                        }
                        if (unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == undefined && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == null) {
                          unifiedModel.ModelInput.Structural.isUserDefineLoadSelected = isUserDefineLoadSelected;
                        }
                        //
                        let addProblemToList: boolean = true;
                        if (problemsToBeDownloaded && problemsToBeDownloaded.length > 0) {
                          let existedProblem = problemsToBeDownloaded.find(x => x.ProblemGuid == response.data.ProblemSetting.ProblemGuid);
                          if (existedProblem) {
                            addProblemToList = false;
                            //existedProblem = existedProblem[0];
                            let index = problemsToBeDownloaded.indexOf(existedProblem);
                            let existedUnifiedModel: UnifiedModel = JSON.parse(problemsToBeDownloaded[index].UnifiedModel);
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.StructuralResult)
                              unifiedModel.AnalysisResult.StructuralResult = existedUnifiedModel.AnalysisResult.StructuralResult;
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.FacadeStructuralResult)
                              unifiedModel.AnalysisResult.FacadeStructuralResult = existedUnifiedModel.AnalysisResult.FacadeStructuralResult;
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.UDCStructuralResult)
                              unifiedModel.AnalysisResult.UDCStructuralResult = existedUnifiedModel.AnalysisResult.UDCStructuralResult;
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.ThermalResult)
                              unifiedModel.AnalysisResult.ThermalResult = existedUnifiedModel.AnalysisResult.ThermalResult;
                            problemsToBeDownloaded[index].UnifiedModel = JSON.stringify(unifiedModel);
                          }
                        }
                        if (addProblemToList) {
                          problem.UnifiedModel = JSON.stringify(unifiedModel);
                          problemsToBeDownloaded.push(problem);
                        }
                        //
                      }
                      break;
                    case "structural": case "shortStructural":
                      if (response.data && response.data.AnalysisResult && response.data.AnalysisResult.StructuralResult) {
                        // if (!unifiedModel.AnalysisResult) unifiedModel.AnalysisResult = new AnalysisResult();
                        let postCodeValue = unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.dinWindLoadInput ?
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue : "";
                        unifiedModel.AnalysisResult.StructuralResult = response.data.AnalysisResult.StructuralResult;
                        unifiedModel.ModelInput.Structural = response.data.ModelInput.Structural;
                        if (postCodeValue && postCodeValue !== "") {
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue = postCodeValue;
                        }
                        if (unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == undefined && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == null) {
                          unifiedModel.ModelInput.Structural.isUserDefineLoadSelected = isUserDefineLoadSelected;
                        }
                      }
                      else if (response.data && response.data.AnalysisResult && response.data.AnalysisResult.FacadeStructuralResult) {
                        // if (!unifiedModel.AnalysisResult) unifiedModel.AnalysisResult = new AnalysisResult();
                        unifiedModel.AnalysisResult.FacadeStructuralResult = response.data.AnalysisResult.FacadeStructuralResult;
                        let postCodeValue = unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.dinWindLoadInput ?
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue : "";
                        unifiedModel.ModelInput.Structural = response.data.ModelInput.Structural;
                        if (postCodeValue && postCodeValue !== "") {
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue = postCodeValue;
                        }
                        if (unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == undefined && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == null) {
                          unifiedModel.ModelInput.Structural.isUserDefineLoadSelected = isUserDefineLoadSelected;
                        }
                      }
                      else if (response.data && response.data.AnalysisResult && response.data.AnalysisResult.UDCStructuralResult) {
                        // if (!unifiedModel.AnalysisResult) unifiedModel.AnalysisResult = new AnalysisResult();
                        unifiedModel.AnalysisResult.UDCStructuralResult = response.data.AnalysisResult.UDCStructuralResult;
                        unifiedModel.ModelInput.Structural = response.data.ModelInput.Structural;
                      }
                      //
                      let addProblemToList: boolean = true;
                      if (problemsToBeDownloaded && problemsToBeDownloaded.length > 0) {
                        let existedProblem = problemsToBeDownloaded.find(x => x.ProblemGuid == response.data.ProblemSetting.ProblemGuid);
                        if (existedProblem) {
                          addProblemToList = false;
                          //existedProblem = existedProblem[0];
                          let index = problemsToBeDownloaded.indexOf(existedProblem);
                          let existedUnifiedModel: UnifiedModel = JSON.parse(problemsToBeDownloaded[index].UnifiedModel);
                          if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.AcousticResult)
                            unifiedModel.AnalysisResult.AcousticResult = existedUnifiedModel.AnalysisResult.AcousticResult;
                          if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.ThermalResult)
                            unifiedModel.AnalysisResult.ThermalResult = existedUnifiedModel.AnalysisResult.ThermalResult;
                          problemsToBeDownloaded[index].UnifiedModel = JSON.stringify(unifiedModel);
                        }
                      }
                      if (addProblemToList) {
                        problem.UnifiedModel = JSON.stringify(unifiedModel);
                        problemsToBeDownloaded.push(problem);
                      }
                      //
                      break;
                    case "thermal":
                      if (response.data && response.data.AnalysisResult && response.data.AnalysisResult.ThermalResult) {
                        // if (!unifiedModel.AnalysisResult) unifiedModel.AnalysisResult = new AnalysisResult();
                        unifiedModel.AnalysisResult.ThermalResult = response.data.AnalysisResult.ThermalResult;
                        let postCodeValue = unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.dinWindLoadInput ?
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue : "";
                        unifiedModel.ModelInput.Structural = response.data.ModelInput.Structural;
                        if (postCodeValue && postCodeValue !== "") {
                          unifiedModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue = postCodeValue;
                        }
                        if (unifiedModel.ModelInput.Structural && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == undefined && unifiedModel.ModelInput.Structural.isUserDefineLoadSelected == null) {
                          unifiedModel.ModelInput.Structural.isUserDefineLoadSelected = isUserDefineLoadSelected;
                        }
                        //
                        let addProblemToList: boolean = true;
                        if (problemsToBeDownloaded && problemsToBeDownloaded.length > 0) {
                          let existedProblem = problemsToBeDownloaded.find(x => x.ProblemGuid == response.data.ProblemSetting.ProblemGuid);
                          if (existedProblem) {
                            addProblemToList = false;
                            //existedProblem = existedProblem[0];
                            let index = problemsToBeDownloaded.indexOf(existedProblem);
                            let existedUnifiedModel: UnifiedModel = JSON.parse(problemsToBeDownloaded[index].UnifiedModel);
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.StructuralResult)
                              unifiedModel.AnalysisResult.StructuralResult = existedUnifiedModel.AnalysisResult.StructuralResult;
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.FacadeStructuralResult)
                              unifiedModel.AnalysisResult.FacadeStructuralResult = existedUnifiedModel.AnalysisResult.FacadeStructuralResult;
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.UDCStructuralResult)
                              unifiedModel.AnalysisResult.UDCStructuralResult = existedUnifiedModel.AnalysisResult.UDCStructuralResult;
                            if (existedUnifiedModel.AnalysisResult && existedUnifiedModel.AnalysisResult.AcousticResult)
                              unifiedModel.AnalysisResult.AcousticResult = existedUnifiedModel.AnalysisResult.AcousticResult;
                            problemsToBeDownloaded[index].UnifiedModel = JSON.stringify(unifiedModel);
                          }
                        }
                        if (addProblemToList) {
                          problem.UnifiedModel = JSON.stringify(unifiedModel);
                          problemsToBeDownloaded.push(problem);
                        }
                        //
                      }
                      break;
                  }
                  responseCount++;
                  if (responseCount == requestCount) {
                    setTimeout(() => {
                      responseCount = 0;
                      this.showDownloadProgressBar = true;
                      this.addAddition();
                      if(problemsToBeDownloaded && problemsToBeDownloaded.length>0)
                        this.DownloadReportsForRequestedLanguage(problemsToBeDownloaded, selectedIconsStructuralFull, selectedIconsStructural);
                    }, 1);
                  }
                }
              });

            },
              () => { //error 
                
                this.progressBarStatus = "exception";
                this.commentProgress.progress = 70;
              }
              , () => { //complete
                //this.DownloadReportsForRequestedLanguage();
              });
        }

        //#endregion  STEP 2
      }

    }); //listOfProblems foreach

    //STEP 3 call new Download APIs
  }
  public commentProgress = {
    progress: 0
  };
  private addition = 100;
  private loading = true;
  
  private addAddition() {
    setTimeout(() => {
      if(this.showDownloadProgressBar){
        this.commentProgress.progress += Math.round(this.addition / 2);
        this.addition /= 2;
        if (this.loading) {
          this.addAddition();
        } else {
          this.commentProgress.progress = 0;
        }
      }

    }, 100);
  }

 // language selected by user from drop down
  DownloadReportsForRequestedLanguage(problemsToBeDownloaded : BpsUnifiedProblem[], selectedIconsStructuralFull: any, selectedIconsStructural:any) {
   // if(unifiedModel && physicsType){
      let calls = [];
      let reportFileUrl = null;
      let language;
      problemsToBeDownloaded.forEach((problem)=>{
        let unifiedModel: UnifiedModel;
        unifiedModel = JSON.parse(problem.UnifiedModel);
        //switch(physicsType){
          //case "acoustic":
            unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
            if (unifiedModel.AnalysisResult && unifiedModel.AnalysisResult.AcousticResult) {
              var param = this.getBuildParam(unifiedModel.AnalysisResult.AcousticResult.reportFileUrl);
              calls.push(this.configureService.GetPCReportForRequestedLanguage(param[0], param[1], param[2].replace('.pdf', ''), unifiedModel.ProblemSetting.ReportLanguage));
            }
            //break;
          //case "structural":
            unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
            
            if (unifiedModel.AnalysisResult && (unifiedModel.AnalysisResult.StructuralResult || unifiedModel.AnalysisResult.FacadeStructuralResult || unifiedModel.AnalysisResult.UDCStructuralResult)) {
              if (unifiedModel.AnalysisResult.StructuralResult && unifiedModel.AnalysisResult.StructuralResult.reportFileUrl)
                reportFileUrl = unifiedModel.AnalysisResult.StructuralResult.reportFileUrl;
              else if (unifiedModel.AnalysisResult.FacadeStructuralResult && unifiedModel.AnalysisResult.FacadeStructuralResult.reportFileUrl)
                reportFileUrl = unifiedModel.AnalysisResult.FacadeStructuralResult.reportFileUrl;
              else if (unifiedModel.AnalysisResult.UDCStructuralResult && unifiedModel.AnalysisResult.UDCStructuralResult.reportFileUrl)
                reportFileUrl = unifiedModel.AnalysisResult.UDCStructuralResult.reportFileUrl;
              if (reportFileUrl && selectedIconsStructuralFull && selectedIconsStructuralFull.some(x => x.problemGuid == problem.ProblemGuid)) {
                var param = this.getBuildParam(reportFileUrl);
                calls.push(this.configureService.GetPCReportForRequestedLanguage(param[0], param[1], param[2].replace('.pdf', ''), unifiedModel.ProblemSetting.ReportLanguage));
              }
            }
            if (unifiedModel && unifiedModel.AnalysisResult && (unifiedModel.AnalysisResult.StructuralResult || unifiedModel.AnalysisResult.FacadeStructuralResult || unifiedModel.AnalysisResult.UDCStructuralResult)) {
              if (unifiedModel.AnalysisResult.StructuralResult && unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl)
                reportFileUrl = unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl;
              else if (unifiedModel.AnalysisResult.FacadeStructuralResult && unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl)
                reportFileUrl = unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl;
              else if (unifiedModel.AnalysisResult.UDCStructuralResult && unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl)
                reportFileUrl = unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl;
              if (reportFileUrl && selectedIconsStructural && selectedIconsStructural.some(x => x.problemGuid == problem.ProblemGuid)) {
                var param = this.getBuildParam(reportFileUrl);
                calls.push(this.configureService.GetPCReportForRequestedLanguage(param[0], param[1], param[2].replace('.pdf', ''), unifiedModel.ProblemSetting.ReportLanguage));
              }
            }
            //break;
          //case "thermal":
            unifiedModel.ProblemSetting.ReportLanguage = this.requestedLanguageForReport;
            if (unifiedModel && unifiedModel.AnalysisResult && unifiedModel.AnalysisResult.ThermalResult) {
              var param = this.getBuildParam(unifiedModel.AnalysisResult.ThermalResult.reportFileUrl);
              calls.push(this.configureService.GetPCReportForRequestedLanguage(param[0], param[1], param[2].replace('.pdf', ''), unifiedModel.ProblemSetting.ReportLanguage));
            }
            //break; 
        //}
      });
      
      if(calls.length>0){
        language = this.requestedLanguageForReport;
        let dateFormat = this.configureService.getDateFormat_byCulture();
        let pipe = new DatePipe(language);
        zip(...calls).pipe(takeUntil(this.destroy$)).subscribe(
          responses => {
            if (responses) {
              this.areAllReportsReady = true;
              const zip = new JSZip();
              const name = this.projectName + '_' + this.projectLocation + '_' + pipe.transform(Date.now(), dateFormat) + '.zip';
              for (let index in responses) {
                var response: any = responses[index];
                if (response && response.body && response.body.size && response.body.size > 0 && this.areAllReportsReady) {
                  // let problemGuidOfResponse = this.getProblemGuid(response);
                  // let problemNameOfResponse = this.getProblemNameFromGuid(problemGuidOfResponse);
                  //let fileName = this.getFileName(response);
                  let problemGuidOfResponse = this.getProblemGuidForRequestedLanguage(response);
                  let problemNameOfResponse = this.getProblemNameFromGuid(problemGuidOfResponse);
                  //var param = this.getBuildParam(unifiedModel.AnalysisResult.AcousticResult.reportFileUrl);
                  let fileName = this.getFileName(response);
                  //let fileName = param && param.length > 1 ? param[2] : "Report.pdf";
                  let acoustic_folder, structural_folder, thermal_folder;
                  let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                  if (fileName.toLowerCase().includes('acoustic') || fileName.toLowerCase().includes('akustik')) {
                    let fullFileName = problemNameOfResponse + '_' + this.getReportTypeToLang(this.requestedLanguageForReport, "acoustic") + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                    //let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                    acoustic_folder = acoustic_folder ? acoustic_folder : zip.folder(folderName);
                    acoustic_folder.file(fullFileName, response.body);
                  } else if (fileName.toLowerCase().includes('structural') || fileName.toLowerCase().includes('statik')) {
                    let fullFileName = '';
                    if (fileName.toLowerCase().includes('summary') || fileName.toLowerCase().includes('zusammenfassung')) {
                      fullFileName = problemNameOfResponse + '_' + this.getReportTypeToLang(this.requestedLanguageForReport, "shortStructural") + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                    }
                    else {
                      fullFileName = problemNameOfResponse + '_' + this.getReportTypeToLang(this.requestedLanguageForReport, "structural") + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                    }
                    //let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                    structural_folder = structural_folder ? structural_folder : zip.folder(folderName);
                    structural_folder.file(fullFileName, response.body);
                  } else if (fileName.toLowerCase().includes('thermal') || fileName.toLowerCase().includes('thermisch')) {
                    let fullFileName = problemNameOfResponse + '_' + this.getReportTypeToLang(this.requestedLanguageForReport, "thermal") + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                    //let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                    thermal_folder = thermal_folder ? thermal_folder : zip.folder(folderName);
                    thermal_folder.file(fullFileName, response.body);
                  } else {
                    let fullFileName = fileName + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                    zip.file(fullFileName, response.body);
                  }
                }
                else {
                  this.areAllReportsReady = false;
                  //this.reportNotReadyModal_isVisible = true;
                  break;
                }
              };
              if (this.areAllReportsReady) {
                this.resetProgressBar();
                this.showDownloadProgressBar = false;
                zip.generateAsync({ type: 'blob' }).then((content) => {
                  if (content) {
                    saveAs(content, name);
                  }
                });
              }
              else {
                setTimeout(() => {
                  if (this.areAllReportsReady == false)
                    this.ngNotificaionShow.next('WarningStructuralFullReport');
                    this.progressBarStatus = "exception";
                    this.commentProgress.progress = 70;
                }, 1);
              }
            }
          }, err => {
            this.progressBarStatus = "exception";
            this.commentProgress.progress = 70;
          },
          () => console.log('Ok ')
        );
      }
    //}
  }

 // default language of user
  DownloadReportsForDefaultLanguage() {
  let totalCount = 0;
  let language;
  //let reportArr : any[] = [];
  let ids: number[] = [];
  let selectedIconsAcoutic = [];
  let selectedIconsStructural = [];
  let selectedIconsStructuralFull = [];
  let selectedIconsThermal = [];
  if (this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]).length > 0)
    selectedIconsAcoutic = this.data.filter(item => this.mapOfCheckedIdAcoustic[item.id]);
  if (this.data.filter(item => this.mapOfCheckedIdStructural[item.id]).length > 0)
    selectedIconsStructural = this.data.filter(item => this.mapOfCheckedIdStructural[item.id]);
  if (this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]).length > 0)
    selectedIconsStructuralFull = this.data.filter(item => this.mapOfCheckedIdFullStructural[item.id]);
  if (this.data.filter(item => this.mapOfCheckedIdThermal[item.id]).length > 0)
    selectedIconsThermal = this.data.filter(item => this.mapOfCheckedIdThermal[item.id]);
  let calls = [];
  if (selectedIconsAcoutic.length > 0 || selectedIconsStructural.length > 0 || selectedIconsThermal.length > 0 || selectedIconsStructuralFull.length > 0) {
    // totalCount += selectedIconsAcoutic.length;
    this.listOfProblems.forEach((problem) => {
      let unifiedModel: UnifiedModel;
      let reportFileUrl = null;
      if (selectedIconsAcoutic.some(x => x.problemGuid == problem.ProblemGuid)) {
        unifiedModel = JSON.parse(problem.UnifiedModel);
        if (!language)
          language = unifiedModel.UserSetting.Language;
        if (unifiedModel && unifiedModel.AnalysisResult && unifiedModel.AnalysisResult.AcousticResult) {
          var param = this.getBuildParam(unifiedModel.AnalysisResult.AcousticResult.reportFileUrl);
          calls.push(this.configureService.GetPCReport(param[0], param[1], param[2].replace('.pdf', '')));
        }
      }
      if (selectedIconsStructuralFull.some(x => x.problemGuid == problem.ProblemGuid)) {
        unifiedModel = unifiedModel ? unifiedModel : JSON.parse(problem.UnifiedModel);
        if (!language)
          language = unifiedModel.UserSetting.Language;
        if (unifiedModel && unifiedModel.AnalysisResult && (unifiedModel.AnalysisResult.StructuralResult || unifiedModel.AnalysisResult.FacadeStructuralResult || unifiedModel.AnalysisResult.UDCStructuralResult)) {
          if (unifiedModel.AnalysisResult.StructuralResult && unifiedModel.AnalysisResult.StructuralResult.reportFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.StructuralResult.reportFileUrl;
          else if (unifiedModel.AnalysisResult.FacadeStructuralResult && unifiedModel.AnalysisResult.FacadeStructuralResult.reportFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.FacadeStructuralResult.reportFileUrl;
          else if (unifiedModel.AnalysisResult.UDCStructuralResult && unifiedModel.AnalysisResult.UDCStructuralResult.reportFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.UDCStructuralResult.reportFileUrl;
          if (reportFileUrl) {
            var param = this.getBuildParam(reportFileUrl);
            calls.push(this.configureService.GetPCReport(param[0], param[1], param[2].replace('.pdf', '')));
          }
        }
      }
      if (selectedIconsStructural.some(x => x.problemGuid == problem.ProblemGuid)) {
        unifiedModel = unifiedModel ? unifiedModel : JSON.parse(problem.UnifiedModel);
        if (!language)
          language = unifiedModel.UserSetting.Language;
        if (unifiedModel && unifiedModel.AnalysisResult && (unifiedModel.AnalysisResult.StructuralResult || unifiedModel.AnalysisResult.FacadeStructuralResult || unifiedModel.AnalysisResult.UDCStructuralResult)) {
          if(unifiedModel.AnalysisResult.StructuralResult && unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl  )
            reportFileUrl = unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl;
          else if(unifiedModel.AnalysisResult.FacadeStructuralResult && unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl;
          else if(unifiedModel.AnalysisResult.UDCStructuralResult && unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl)
            reportFileUrl = unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl;
          if (reportFileUrl) {
            var param = this.getBuildParam(reportFileUrl);
            calls.push(this.configureService.GetPCReport(param[0], param[1], param[2].replace('.pdf', '')));
          }
        }
      }
      if (selectedIconsThermal.some(x => x.problemGuid == problem.ProblemGuid)) {
        unifiedModel = unifiedModel ? unifiedModel : JSON.parse(problem.UnifiedModel);
        if (!language) language = unifiedModel.UserSetting.Language;
        if (unifiedModel && unifiedModel.AnalysisResult && unifiedModel.AnalysisResult.ThermalResult) {
          var param = this.getBuildParam(unifiedModel.AnalysisResult.ThermalResult.reportFileUrl);
          calls.push(this.configureService.GetPCReport(param[0], param[1], param[2].replace('.pdf', '')));
        }
      }
    });
    language = language && language == 'de-DE' ? 'de-DE' : 'en-US';
    let dateFormat = this.configureService.getDateFormat_byCulture();
    let pipe = new DatePipe(language);
    zip(...calls).pipe(takeUntil(this.destroy$)).subscribe(
      responses => {
        if (responses) {
          this.areAllReportsReady = true;
          const zip = new JSZip();
          const name = this.projectName + '_' + this.projectLocation + '_' + pipe.transform(Date.now(), dateFormat) + '.zip';
          for(let index in responses) {
            var response: any = responses[index];
            if (response && response.body && response.body.size && response.body.size > 0 && this.areAllReportsReady) {
              let problemGuidOfResponse = this.getProblemGuid(response);
              let problemNameOfResponse = this.getProblemNameFromGuid(problemGuidOfResponse);
              let fileName = this.getFileName(response);
              let acoustic_folder, structural_folder, thermal_folder;
              if (fileName.toLowerCase().includes('acoustic') || fileName.toLowerCase().includes('akustik')) {
                let fullFileName = problemNameOfResponse + '_' + this.translate.instant(_('report.acoustic-report')) + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                acoustic_folder = acoustic_folder ? acoustic_folder : zip.folder(folderName);
                acoustic_folder.file(fullFileName, response.body);
              } else if (fileName.toLowerCase().includes('structural') || fileName.toLowerCase().includes('statik')) {
                let fullFileName = '';
                if (fileName.toLowerCase().includes('summary') || fileName.toLowerCase().includes('zusammenfassung')) {
                  fullFileName = problemNameOfResponse + '_' + this.translate.instant(_('report.structural-short-report')) + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                }
                else {
                  fullFileName = problemNameOfResponse + '_' + this.translate.instant(_('report.structural-report')) + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                }
                let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                structural_folder = structural_folder ? structural_folder : zip.folder(folderName);
                structural_folder.file(fullFileName, response.body);
              } else if (fileName.toLowerCase().includes('thermal') || fileName.toLowerCase().includes('thermisch')) {
                let fullFileName = problemNameOfResponse + '_' + this.translate.instant(_('report.thermal-report')) + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                let folderName = this.getProblemNameFromGuid(fileName.split('_')[0]);
                thermal_folder = thermal_folder ? thermal_folder : zip.folder(folderName);
                thermal_folder.file(fullFileName, response.body);
              } else {
                let fullFileName = fileName + '_' + pipe.transform(Date.now(), dateFormat) + '.pdf';
                zip.file(fullFileName, response.body);
              }
            }
            else {
              this.areAllReportsReady = false;
              //this.reportNotReadyModal_isVisible = true;
              break;
            }
          };
          if (this.areAllReportsReady) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              if (content) {
                saveAs(content, name);
              }
            });  
          }
          else {
            setTimeout(() => {
            if (this.areAllReportsReady == false)
                this.ngNotificaionShow.next('WarningStructuralFullReport');
            }, 1);
          }
        }
      }, err => console.log('error ' + err),
      () => console.log('Ok ')
    );

    // if (calls.length == 1) {
    //   let reportRequest = {
    //     "FolderPath": "/Content/acoustic-result/3fb4e6aa-e27a-42c6-b8d4-3541b64f29b8/7b76407a-5876-47a7-b29a-c472a89ece20/7e0dab4f-8059-4680-acc4-704181101a78/",
    //     "ReportFileName": "u Acoustic_Report.pdf"
    //   };
    //   this.configureService.GetReport(reportRequest).pipe(takeUntil(this.destroy$)).subscribe(
    //     (resp: HttpResponse<Blob>) => {
    //       if (resp) {
    //         let fileName = "Report.pdf";
    //         if (resp.headers && resp.headers.get('content-disposition')) {
    //           if (resp.headers.get('content-disposition').split('=') && resp.headers.get('content-disposition').split('=').length > 0)
    //             if (resp.headers.get('content-disposition').split('=')[1].split(" ") && resp.headers.get('content-disposition').split('=')[1].split(" ").length > 0) {
    //               if (resp.headers.get('content-disposition').split('=')[1].split(" ")[1])
    //                 fileName = resp.headers.get('content-disposition').split('=')[1].split(" ")[1].replace('"', '');
    //             }
    //         }

    //         saveAs(resp.body, fileName);
    //       }
    //     });
    // } else if (calls.length > 1)
    //   zip (...calls).pipe(takeUntil(this.destroy$)).subscribe(
    //     fileDataList => {
    //       const zip = new JSZip();
    //       const name = 'BPS Reports' + '.zip';
    //       for (let counter = 0; counter < fileDataList.length; counter++) {
    //         zip.file('Acoustic' + counter + '.pdf', fileDataList[counter]);
    //       }
    //       zip.generateAsync({ type: 'blob' }).then((content) => {
    //         if (content) {
    //           saveAs(content, name);
    //         }
    //       });
    //     }, err => console.log('error ' + err),
    //     () => console.log('Ok ')
    //   );
  }
  }

  getReportTypeToLang(lang: string, type: string){
    switch(lang){
      case "en-US":
        switch(type){
          case "acoustic":
            return "Acoustic Report";
          case "structural":
            return "Structural Report";
          case "shortStructural":
            return "Structural Short Report";
          case "thermal":
            return "Thermal Report";
        }
      break;
      case "de-DE":
        switch(type){
          case "acoustic":
            return "Akustikbericht";
          case "structural":
            return "Statik Bericht";
          case "shortStructural":
            return "Struktureller Kurzbericht";
          case "thermal":
            return "Thermalbericht";
        }
      break;
      case "fr-FR":
        switch(type){
          case "acoustic":
            return "Rapport acoustique";
          case "structural":
            return "Rapport structurel";
          case "shortStructural":
            return "Rapport statique simplifié";
          case "thermal":
            return "Rapport de synthèse";
        }
      break;
    }
  }

  onRowSelected(event: any) {
    if (event && event.data) {
      if (event.selected) {
        let selectedProblem = this.listOfDisplayData.filter(x => event.data.problemGuid && x.problemGuid == event.data.problemGuid);
        if (selectedProblem && selectedProblem.length > 0) {
          this.selectedProblem = selectedProblem[0];
          this.reportService.selectedProblemSubject.next(selectedProblem[0]);
        }
      } else {
        this.reportService.selectedProblemSubject.next(null);
      }
    }

  }

  checkAllFullReport(value: boolean): void {
    this.listOfDisplayData.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedIdFullStructural[item.id] = value));
    this.refreshFullStatusReport();
  }


  onProblemNameEdit(event: any) {
    if (event && event.length > 0) {
      if(event[0].problemGuid){
        let selectedProblemList = this.listOfProblems.filter(x => x.ProblemGuid == event[0].problemGuid);
        if (selectedProblemList && selectedProblemList[0]) {
          if(event[0].configuration && event[0].configuration.trim()){
            event[0].configuration = event[0].configuration.trim();
            let newProblemName = event[0].configuration;
            let listOfAllOtherProblemNames = this.listOfProblems.map(problem => problem.ProblemGuid != event[0].problemGuid ? problem.ProblemName:'').filter(String);
            let does_newProblemName_alreadyExist: Boolean = listOfAllOtherProblemNames.includes(newProblemName);
            if (does_newProblemName_alreadyExist) {
              this.ngNotificaionShow.next('sameNameWarning');
              if(selectedProblemList[0].ProblemName && this.selectedProblem){
                this.selectedProblem.configuration = selectedProblemList[0].ProblemName
              }  
            }
            else {
              selectedProblemList[0].ProblemName = event[0].configuration;
              let selectedProblem = selectedProblemList[0];
              let unifiedModel: UnifiedModel = JSON.parse(selectedProblem.UnifiedModel);
              if (unifiedModel && unifiedModel.ProblemSetting) {
                unifiedModel.ProblemSetting.ConfigurationName = event[0].configuration;
                this.iframeService.setShowLoader(true);
                let calls = [];
      
                let reportURLs = [];
                let newProblemName = event[0].configuration;
                if (unifiedModel.AnalysisResult.AcousticResult !== null) {
                  reportURLs.push(unifiedModel.AnalysisResult.AcousticResult.reportFileUrl);
                }
                if (unifiedModel.AnalysisResult.FacadeStructuralResult !== null) {
                  reportURLs.push(unifiedModel.AnalysisResult.FacadeStructuralResult.reportFileUrl);
                  reportURLs.push(unifiedModel.AnalysisResult.FacadeStructuralResult.summaryFileUrl);
                }
                if (unifiedModel.AnalysisResult.StructuralResult !== null) {
                  reportURLs.push(unifiedModel.AnalysisResult.StructuralResult.reportFileUrl);
                  reportURLs.push(unifiedModel.AnalysisResult.StructuralResult.summaryFileUrl);
                }
                if (unifiedModel.AnalysisResult.ThermalResult !== null) {
                  reportURLs.push(unifiedModel.AnalysisResult.ThermalResult.reportFileUrl);
                }
                if (unifiedModel.AnalysisResult.UDCStructuralResult !== null) {
                  reportURLs.push(unifiedModel.AnalysisResult.UDCStructuralResult.reportFileUrl);
                  reportURLs.push(unifiedModel.AnalysisResult.UDCStructuralResult.summaryFileUrl);
                }
                calls.push(this.resultService.RenameProblem({ reportURLs, newProblemName }));
                calls.push(this.configureService.RenameProblem(unifiedModel.ProblemSetting.ProblemGuid, newProblemName));
                zip(...calls).pipe(takeUntil(this.destroy$)).subscribe(
                  response => {
                    if (response) {
                      this.cdr.detectChanges();
                      this.handleCancel();
                      this.selectedRowForNotes = null;
                      if (this.selectedProblem.problemGuid == event[0].problemGuid)
                        this.reportService.refreshCurrentreport.next(true);
                      this.iframeService.setShowLoader(false);
                    }
                  }, err => console.log('error ' + err),
                  () => console.log('Ok ')
                );
              }
            }
          }
          else{
            if(selectedProblemList[0].ProblemName && this.selectedProblem){
              this.selectedProblem.configuration = selectedProblemList[0].ProblemName
            }
          }
        }
      }      
    }
  }
  getAllProblems() {

    this.loadData();
    this.listOfDisplayData = this.data.slice();
    this.operateData();
    this.cdr.detectChanges();
  }

  //popup change starts
  unifiedModelForNotes: UnifiedModel;
  selectedRowForNotes: any;
  currentUserNotes: string = "";
  showNotesModal($event: any, selectedRowProblemGuid: string) {
    this.currentUserNotes = null;
    this.unifiedModelForNotes = null;
    this.selectedRowForNotes = null;
    this.selectedNotesProblemGuid = selectedRowProblemGuid;
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    this.isNotesModalVisible = true;
    if (this.selectedNotesProblemGuid) {
      let selectedProblemList = this.listOfProblems.filter(x => x.ProblemGuid == this.selectedNotesProblemGuid);
      this.selectedRowForNotes = this.listOfDisplayData.filter(x => x.problemGuid == this.selectedNotesProblemGuid);
      if (selectedProblemList && selectedProblemList.length == 1) {
        let selectedProblem = selectedProblemList[0]
        let unifiedModel: UnifiedModel = JSON.parse(selectedProblem.UnifiedModel);
        this.unifiedModelForNotes = unifiedModel;
        if (unifiedModel && unifiedModel.ProblemSetting && unifiedModel.ProblemSetting.UserNotes) {
          this.comment = unifiedModel.ProblemSetting.UserNotes;
          this.currentUserNotes = this.comment;
        }
      }
    }
    //this.hideEditor = false;
  }
  handleCancel() {
    this.isNotesModalVisible = false;
    this.resetForm();
  }

  submitForm(value: any): void {
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    if (this.unifiedModelForNotes) {
      this.unifiedModelForNotes.ProblemSetting.UserNotes = this.comment;
      this.iframeService.setShowLoader(true);
      let calls = [];

      let reportURLs = [];
      let userNotes = this.comment;
      if (this.unifiedModelForNotes.AnalysisResult.AcousticResult !== null) {
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.AcousticResult.reportFileUrl);
      }
      if (this.unifiedModelForNotes.AnalysisResult.FacadeStructuralResult !== null) {
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.FacadeStructuralResult.reportFileUrl);
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.FacadeStructuralResult.summaryFileUrl);
      }
      if (this.unifiedModelForNotes.AnalysisResult.StructuralResult !== null) {
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.StructuralResult.reportFileUrl);
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.StructuralResult.summaryFileUrl);
      }
      if (this.unifiedModelForNotes.AnalysisResult.ThermalResult !== null) {
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.ThermalResult.reportFileUrl);
      }
      if (this.unifiedModelForNotes.AnalysisResult.UDCStructuralResult !== null) {
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.UDCStructuralResult.reportFileUrl);
        reportURLs.push(this.unifiedModelForNotes.AnalysisResult.UDCStructuralResult.summaryFileUrl);
      }
      calls.push(this.resultService.UpdateUserNotes({ reportURLs, userNotes }));
      calls.push(this.reportService.updateUserNotes(this.unifiedModelForNotes));

      zip(...calls).pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          if (response) {
            let selectedProblemList = this.listOfProblems.filter(x => x.ProblemGuid == this.selectedNotesProblemGuid);
            if (selectedProblemList && selectedProblemList[0]) {
              let unifiedModelString = JSON.stringify(this.unifiedModelForNotes);
              selectedProblemList[0].UnifiedModel = unifiedModelString;
            }
            this.unifiedModelForNotes = null;
            if (this.selectedRowForNotes && this.selectedRowForNotes.length == 1 && this.selectedRowForNotes[0].note && this.selectedRowForNotes[0].note.context) {
              this.selectedRowForNotes[0].note.context.disabledNote = this.comment ? false : true;
              this.listOfDisplayData = this.listOfDisplayData.slice();
              let data = this.listOfDisplayData;
              this.listOfDisplayData = [];
              this.listOfDisplayData = data.slice();
              this.cdr.detectChanges();
            }

            this.handleCancel();
            this.selectedRowForNotes = null;
            if (this.selectedProblem.problemGuid == this.selectedNotesProblemGuid)
              this.reportService.refreshCurrentreport.next(true);
            this.iframeService.setShowLoader(false);
          }
        }, err => console.log('error ' + err),
        () => console.log('Ok ')
      );
    }
  }

  resetForm(): void {
    this.validateForm.reset();
    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  isValid() {
    if (this.comment)
      return true;
    else if (this.currentUserNotes && !this.comment) {
      return true;
    }
  }
  //popup change ends

}
