import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Transport } from 'src/app/app-common/models/unified-model/Transport';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';

@Component({
  selector: 'app-transportation-table',
  templateUrl: './transportation-table.component.html',
  styleUrls: ['./transportation-table.component.css']
})
export class TransportationTableComponent implements OnInit, OnDestroy {

  isPopoutOpened: boolean = false;
  PopoutOpeningSubscription: Subscription;
  validateForm: FormGroup;

  constructor(private cpService: ConfigPanelsService, private fb: FormBuilder, private umService: UnifiedModelService) {
    this.validateForm = this.fb.group({
      lorry12Control: [0, [Validators.required, Validators.min(0), Validators.max(100000)]],
      lorry28Control: [0, [Validators.required, Validators.min(0), Validators.max(100000)]],
      lorry40Control: [0, [Validators.required, Validators.min(0), Validators.max(100000)]],
      aeroplaneControl: [0, [Validators.required, Validators.min(0), Validators.max(100000)]],
      shipControl: [0, [Validators.required, Validators.min(0), Validators.max(100000)]],
      trainControl: [0, [Validators.required, Validators.min(0), Validators.max(100000)]],
    });
  }

  ngOnInit(): void {
    this.PopoutOpeningSubscription = this.cpService.obsPopout.subscribe(
      response => {
        if (response.panelsModule === PanelsModule.Transportation ) {
          if (response.isOpened) this.onOpen();
          this.isPopoutOpened = response.isOpened;
        }
    });
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].markAsTouched();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.PopoutOpeningSubscription.unsubscribe();
  }

  onOpen() {
    this.validateForm.controls['lorry12Control'].setValue(this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport.Lorry12tDistance);
    this.validateForm.controls['lorry28Control'].setValue(this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport.Lorry28tDistance);
    this.validateForm.controls['lorry40Control'].setValue(this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport.Lorry40tDistance);
    this.validateForm.controls['trainControl'].setValue(this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport.TrainDistance);
    this.validateForm.controls['aeroplaneControl'].setValue(this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport.AeroplaneDistance);
    this.validateForm.controls['shipControl'].setValue(this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport.ShipDistance);
  }

  onClose() {
    this.cpService.setPopout(false, PanelsModule.Transportation);
  }

  onConfirm() {
    let transportModel: Transport = {
      Lorry12tDistance: this.validateForm.controls['lorry12Control'].value,
      Lorry28tDistance: this.validateForm.controls['lorry28Control'].value,
      Lorry40tDistance: this.validateForm.controls['lorry40Control'].value,
      TrainDistance: this.validateForm.controls['trainControl'].value,
      AeroplaneDistance: this.validateForm.controls['aeroplaneControl'].value,
      ShipDistance: this.validateForm.controls['shipControl'].value
    };
    this.umService.current_UnifiedModel.ModelInput.Sustainability.Transport = transportModel;
  }
}
