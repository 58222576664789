<div class="accordion-section-container" *ngIf="unified3DModel">
    <div>
        <div class="sps-accordion-section" id="config-thermal">
            <div id="sps-thermal">
                <h3 *ngIf="language != 'fr-FR'" class="heading" style="margin-bottom: 7px;">{{umService.current_UnifiedModel.ProblemSetting.ProductType == 'Window' ? ('configure.thermal-u-value-calculation-window' | translate):('configure.thermal-u-value-calculation-facade' | translate) }}</h3>
                <h3 *ngIf="language == 'fr-FR' && umService.current_UnifiedModel.ProblemSetting.ProductType == 'Window'" class="heading" style="margin-bottom: 7px;">{{'configure.thermal-u-value-calculation-window' | translate}}<sub>w</sub></h3>
                <h3 *ngIf="language == 'fr-FR' && umService.current_UnifiedModel.ProblemSetting.ProductType == 'Facade'" class="heading" style="margin-bottom: 7px;">{{'configure.thermal-u-value-calculation-facade' | translate}}<sub>cw</sub></h3>
                <!-- <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content" style="margin: 7px 0px 0px 0px;"> -->
                    <div class="sps-thermal-u-value-calculation-button-container">
                        <input style="width: 170px; " centered disabled bpsDisabled bps-input [(ngModel)]="calculationInputValue">
                        <button bps-button bpsType="variation-13" [ngClass]="{'active': isCalculationOpened}"
                        style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
                        (click)="onOpenCalculationPopout()">
                        <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                            src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                        </button>
                    </div>
                <div class="sps-break" style="margin-top: 26px; margin-bottom: 12.5px;"></div>
                <div class="wall-perimeter">
                    <h3 class="boldWhiteFont" [ngStyle]="{'color': dewPointChecked ? '#00a2d1':'#ffffff'}">{{'configure.dew-point-calculator' | translate }}</h3>
                    <!-- Dew Point Calculator -->
                    <bps-switch [(ngModel)]="dewPointChecked" (ngModelChange)="onDewPointSelected()"></bps-switch>
                </div>
                <!--line-->
                <div class="sps-break" style="margin-top: 12.5px; margin-bottom: 12.5px;"></div>
                <div id="sps-str-buildnglen" [ngClass]="{'hidden': !dewPointChecked}">
                    <h3 id="sps-thermal-relative-humidity-heading" [style.color]="dewPointChecked?'#ffffff':'#666666'"
                        class="boldWhiteFont">{{'configure.relative-humidity' | translate }}</h3>
                    <!-- Relative humidity -->
                </div>
                <form bps-form [formGroup]="validateForm" [ngClass]="{'hidden': !dewPointChecked}">
                    <bps-form-item class="bps-form-item" style="margin: 0px">
                        <bps-form-control>
                            <div class="sps-height-num-info sps-str-blen">
                                <div id="thermal-relative-humidity-inputfield">
                                    <div id="sps-thermal-relative-humidity-input-field">
                                        <!-- <input type="number" formControlName="realtiveHumidity" style="width: 60px; margin-left: -0.1px" required bps-input autocomplete="off" min="0" max="100" [(ngModel)]="realtiveHumidity" [disabled]="!dewPointChecked" name="realtiveHumidity" id="sps-thermal-relative-humidity-input-box" (ngModelChange)="onRealativeHumidityChange()"> -->
                                        <input type="text" formControlName="realtiveHumidity"
                                            style="width: 60px; margin-left: -0.1px" required bps-input
                                            autocomplete="off" [(ngModel)]="realtiveHumidityString"
                                            [disabled]="!dewPointChecked" name="realtiveHumidity"
                                            id="sps-thermal-relative-humidity-input-box"
                                            (ngModelChange)="onRealativeHumidityChange()">
                                    </div>
                                </div>
                                <div id="sps-thermal-relative-humidity-percentage"
                                    style="font-size: 11px; margin-left:10px;"
                                    [ngStyle]="{'color': dewPointChecked ? 'white':'#666666'}"
                                    class="sps-thermal-enabled-percentage">
                                    %
                                </div>
                            </div>
                        </bps-form-control>
                    </bps-form-item>
                </form>
            </div>
        </div>
    </div>
</div>