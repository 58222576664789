import { Injectable } from '@angular/core';
import { User } from 'src/app/app-core/models/user.model';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  renderer: any;

  constructor(private localStorageService: LocalStorageService) {
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
  }

  /* ------------Subject------------- */
  // loading = false;
  // private sendLoadingData = new Subject<boolean>();
  // sendLoadingObs = this.sendLoadingData.asObservable();
  // setLoadingData(loading: boolean): void { this.loading = loading; this.sendLoadingData.next(this.loading); }

  // private problemGuid_Subject = new Subject<boolean>();
  // problemGuid_Observable = this.problemGuid_Subject.asObservable();
  // setProblemGuid(value: boolean): void { this.problemGuid_Subject.next(value); }

  // private unifiedProblem_Subject: Subject<BpsUnifiedProblem> = new Subject<BpsUnifiedProblem>();
  // unifiedProblem_Observable = this.unifiedProblem_Subject.asObservable();
  // public setUnifiedProblem(value: BpsUnifiedProblem): void { this.unifiedProblem_Subject.next(value); }

  // private unifiedModel_Subject: Subject<BpsUnifiedModel> = new Subject<BpsUnifiedModel>();
  // unifiedModel_Observable = this.unifiedModel_Subject.asObservable();
  // public setUnifiedModel(value: BpsUnifiedModel): void { this.unifiedModel_Subject.next(value); }

  // /* ------------BehaviorSubject------------- */
  // private problemSaved_BehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // public getIsProblemSaved(): BehaviorSubject<boolean> { return this.problemSaved_BehaviorSubject; }
  // public setIsProblemSaved(value: boolean): void { this.problemSaved_BehaviorSubject.next(value); }

  // private showLoader_BehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // public getIsLoader(): BehaviorSubject<boolean> { return this.showLoader_BehaviorSubject; }
  // public setIsLoader(value: boolean): void { this.showLoader_BehaviorSubject.next(value); }

  articlesMappingData: any[] = [
    // Window
    { key: 'Window', viewValue: 'aws__75_si_plus', value: 'aws__75_si_plus', description: 'AWS 75.SI+', type: null },
    { key: 'Window', viewValue: 'aws__75__bs_si_plus', value: 'aws__75__bs_si_plus', description: 'AWS 75 BS.SI+', type: null },
    { key: 'Window', viewValue: 'aws__75__bs_hi_plus', value: 'aws__75__bs_hi_plus', description: 'AWS 75 BS.HI+', type: null },
    { key: 'Window', viewValue: 'aws__90_si_plus', value: 'aws__90_si_plus', description: 'AWS 90.SI+', type: null },
    { key: 'Window', viewValue: 'aws__90__bs_si_plus', value: 'aws__90__bs_si_plus', description: 'AWS 90 BS.SI+', type: null },
    { key: 'Window', viewValue: 'aws__75_ii', value: 'aws__75_ii', description: 'AWS 75.II', type: null },
    { key: 'Window', viewValue: 'aws__75_bd', value: 'aws__75_bd', description: 'AWS 75 BD', type: null },
    { key: 'Window', viewValue: 'aws__75__bs_hi_plus_fr', value: 'aws__75__bs_hi_plus_fr', description: 'AWS 75 BS.HI+ (FR)', type: null },
    // Door
    { key: 'Door', value: 'ads__75', description: 'ADS 75', type: null },
    // Facade
    // { key: 'Facade', viewValue: 'FWS35PD', value: 'FWS35PD', description: 'FWS 35 PD.SI', type: null },
    // { key: 'Facade', viewValue: 'fws_35_pd_hi', value: 'fws_35_pd_hi', description: 'FWS 35 PD.HI', type: null },
    { key: 'Facade', viewValue: 'fws_50', value: 'FWS50', description: 'FWS 50.SI', type: null },
    // { key: 'Facade', viewValue: 'fws_50_hi', value: 'fws_50_hi', description: 'FWS 50.HI', type: null },
    // { key: 'Facade', viewValue: 'fws_50_si', value: 'fws_50_si', description: 'FWS 50.SI', type: null },
    // { key: 'Facade', viewValue: 'fws_50_si_green', value: 'fws_50_si_green', description: 'FWS 50.SI GREEN', type: null },
    { key: 'Facade', viewValue: 'fws_60', value: 'FWS60', description: 'FWS 60', type: null },
    // { key: 'Facade', viewValue: 'fws_60_hi', value: 'fws_60_hi', description: 'FWS 60.HI', type: null },
    // { key: 'Facade', viewValue: 'fws_60_si', value: 'fws_60_si', description: 'FWS 60.SI', type: null },
    // { key: 'Facade', viewValue: 'fws_60_si_green', value: 'fws_60_si_green', description: 'FWS 60.SI GREEN', type: null },
    // UDC
    { key: 'Facade', viewValue: 'udc_80', value: 'udc_80', description: 'UDC 80', type: 'UDC' },
    { key: 'Facade', viewValue: 'udc_80_hi', value: 'udc_80_hi', description: 'UDC 80.HI', type: 'UDC' },
    { key: 'Facade', viewValue: 'udc_80_si', value: 'udc_80_si', description: 'UDC 80.SI', type: 'UDC' },
    { key: 'Facade', viewValue: 'udc_80_si_xps', value: 'udc_80_si_xps', description: 'UDC 80.SI with XPS Filling', type: 'UDC' },
    // Sliding Door
    { key: 'SlidingDoor', viewValue: 'ase__60', value: 'ase__60', description: 'ASE 60', type: null },
    { key: 'SlidingDoor', viewValue: 'ase__80_hi', value: 'ase__80_hi', description: 'ASE 80.HI', type: null },
  ];

  setUser(value: string) { this.localStorageService.setValue<any>("current_User", value); }
  getUser(): User { return JSON.parse(this.localStorageService.getValue("current_User")); }
  getUserValue(): string { return this.localStorageService.getValue("current_User"); }
  resetUser(value: string) { if (this.getUserValue()) { this.deleteUser(); } this.setUser(value); }
  deleteUser() { this.localStorageService.removeValue('current_User'); }
  getUserEmail() { return this.getUserValue() ? this.getUser().Email : ''; }
  getUserName() { return this.getUserValue() ? this.getUser().UserName : ''; }
  getRenderer() {return this.renderer}
  setUserGuid(value: any) { this.localStorageService.setValue<any>("current_UserGuid", value); }
  getUserGuid(): any { return this.localStorageService.getValue("current_UserGuid"); }
  setUserId(value: any) { this.localStorageService.setValue<any>("current_UserId", value); }
  getUserId(): any { return this.localStorageService.getValue("current_UserId"); }
  getApplicationType() { return (this.getUserValue() && (this.getUser().AccessRoles[0] === "Dealer-Full" || this.getUser().AccessRoles[0] === "Dealer-Restricted")) ? "SRS" : 'BPS'; }
  getUserRole() { return this.getUser().AccessRoles[0]; }

  setProject(value: string) { this.localStorageService.setValue<any>("current_Project", value); }
  getProject(): any { return JSON.parse(this.localStorageService.getValue("current_Project")); }

  getDealerCreditBalance(dealer: any): any { return ((dealer.CreditUsed / dealer.CreditLine) * 100); }
  getUserAccessibleFeatureList() { return this.getUser().Features; }
}
