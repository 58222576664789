import { AfterViewInit, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BpsTableComponent, CeldType } from 'bps-components-lib';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SystemTableService } from 'src/app/app-structural/services/system-table.service';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { NotificationCustomComponent } from '../../../../app-common/components/notification-custom/notification-custom.component';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';



@Component({
  selector: 'app-system-table',
  templateUrl: './system-table.component.html',
  styleUrls: ['./system-table.component.css']
})
export class SystemTableComponent implements OnInit, AfterViewInit {

  private destroy$ = new Subject<void>();
  isPopoutOpened: boolean = false;
  listOfFramingSystems: any = [];

  //Left panel
  selectedSystemRowData: any = {description: '', status:'', market:'', moreInfo:''};
  listOfCountries: any;

  //Right panel
  configurationCustomGrid: any;
  listOfDisplayData1 = [];
  listOfDisplayData2 = [];
  listOfDisplayData3 = [];
  data1: any = [];
  data2: any = [];
  data3: any = [];
  systemDataInUseToAdd1: any = [];
  systemDataInUseToAdd2: any = [];
  systemDataInUseToAdd3: any = [];
  sortName1: string | null = null;
  sortValue1: string | null = null;
  sortName2: string | null = null;
  sortValue2: string | null = null;
  sortName3: string | null = null;
  sortValue3: string | null = null;
  arrow_hovered = false;
  searchSystemInputText: string = '';
  rowSelectionData: any = {};
  configurationNameInUse: string[];
  @ViewChild('rightColumnTemplate', { read: TemplateRef, static: true }) rightColumnTemplate: TemplateRef<{}>;
  @ViewChild('table1', { read: BpsTableComponent, static: true }) table1Component: BpsTableComponent;
  @ViewChild('table2', { read: BpsTableComponent, static: true }) table2Component: BpsTableComponent;
  @ViewChild('table3', { read: BpsTableComponent, static: true }) table3Component: BpsTableComponent;
  @ViewChild(NotificationCustomComponent) notifCustomTemplate: NotificationCustomComponent;

  constructor(private cpService: ConfigPanelsService, private stService: SystemTableService, private umService: UnifiedModelService, private configureService: ConfigureService, private navLayoutService: NavLayoutService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.configurationCustomGrid = { 
      fields: [ 
        { celdType: CeldType.Default, display: 'Name', property: 'description', width: '245px', showSort: true },
        { celdType: CeldType.TemplateRef, property: 'outside', width: '80px', showSort: false }
      ],
      fieldId: 'id'
    };
    /**
     * The translate service doesn't work on init (bug ?)
    */
    setTimeout(() => {
      this.setTranslations();
    },100);
  }
  
  ngAfterViewInit() {
    this.stService.sujUnselectSystem.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.selectedSystemRowData = {description: '', status:'', market:'', moreInfo:''};
      }
    );    
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.SystemTable) {
          if (response.isOpened) {
            this.onPopoutOpened();
          }
          this.isPopoutOpened = response.isOpened;
        }
      }
    );
    this.configureService.subSettings.pipe(takeUntil(this.destroy$)).subscribe(
      _boolean => {
        this.stService.GetFramingSystems(this.configureService.problemSetting.ProjectGuid).pipe(takeUntil(this.destroy$)).subscribe(
          response => {

            this.listOfFramingSystems = response;
            this.cpService.systemList = response;
            // if(this.cpService.systemList){

            //   this.cpService.setSystemList(this.cpService.systemList);
            //   this.cpService.systemListAdded = false;
            // }
            this.cpService.systemListAdded = false;
            this.cpService.sujSystemList.next({ data: response, systemFor: this.cpService.currentSystem })
            this.loadFramingSystemsData();
          }
        );    
      }
    );
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setTranslations();
      this.organizeDisplayData();
    });

     /**
     * Lock and add system in the 'in use' statement
     * If the system data is not fetched yet from the back-end
     */
     this.stService.sujAddSystem.pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        switch (data.table) {
          case 'tableA':
            if (this.data1.length > 0) this.addSystem1InUse(data);
            else this.systemDataInUseToAdd1.push(data);
            break;
          case 'tableB':
            if (this.data2.length > 0) this.addSystem2InUse(data);
            else this.systemDataInUseToAdd2.push(data);
            break;
          case 'tableC':
            if (this.data3.length > 0) this.addSystem3InUse(data);
            else this.systemDataInUseToAdd3.push(data);
            break;
        }
        this.organizeDisplayData();
      }
    );
    
    // save only confirmed systems
    this.stService.saveSystemsSubject.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.saveConfirmedSystems();
      }
    );

    this.stService.sujUnselectSystem.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.unselectArticle();
      }
    );
    // close table
    this.stService.sujCloseTableFromNavBar.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.onClose();
      }
    );
  }

  setTranslations() {
    this.translateListOfCountries();
    this.translateCustomGridName();
    this.translateStatusOfData();
  }

  loadFramingSystemsData() {
    this.loadData();
    this.organizeDisplayData();
    //Save the selected system's names to the stService for the display in the framing component
    setTimeout(() => {
      this.stService.saveSystemSelectionInService([].concat(
        this.listOfDisplayData1.filter(system => system.outside.context.activated).map(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase()),
        this.listOfDisplayData2.filter(system => system.outside.context.activated).map(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase()),
        this.listOfDisplayData3.filter(system => system.outside.context.activated).map(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase()),
      ).slice());  
    });
  }

  onPopoutOpened() {
    this.organizeDisplayData();
    this.clearNotifications();
    this.selectDefaultArticle();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }















//# LEFT PANEL
  onFramingSystemSelection(data: any = {description: '', status: '', market: '', moreInfo: '', systemNameSmall: ''}): void {
    this.selectedSystemRowData = data;
  }

  translateListOfCountries() {
    this.listOfCountries = { FR: this.translate.instant(_('sys-table.france')), US: this.translate.instant(_('sys-table.us')), DE: this.translate.instant(_('sys-table.germany')) };
  }

  /**
   * Returns the string to display in the market field
   */
  getMarketCountries(): string {
    if (this.selectedSystemRowData?.market && this.listOfCountries) {
      let countriesAvailable = [];
      Object.keys(this.selectedSystemRowData.market).forEach(countryCode => {
        if (this.selectedSystemRowData.market[countryCode] == 1) {
          countriesAvailable.push(this.listOfCountries[countryCode]);
        }
      });
      return countriesAvailable.join(', ');
    }
    else {
      return '';
    }
  }

  getImgSRC(): string {
    if (this.selectedSystemRowData.systemNameSmall)
      if (this.selectedSystemRowData.systemNameSmall.includes('aws'))
        return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/' + this.selectedSystemRowData.systemNameSmall + '.jpg';
      else if (this.selectedSystemRowData.systemNameSmall.includes('fws')) {
        switch (this.selectedSystemRowData.systemNameSmall) {
          case 'fws_50':
            return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/fws_50_si.png';
          case 'fws_60':
          case 'fws_60_si':
          case 'fws_60_si_green':
            return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/fws_60_hi.png';
          default:
            return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/' + this.selectedSystemRowData.systemNameSmall + '.png';
        }
      }
      else if (this.selectedSystemRowData.systemNameSmall.includes('udc'))
        return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/' + this.selectedSystemRowData.systemNameSmall + '.png';
      else
        return '';
    else
      return '';
  }

  getOrderManual_SRC() {
    switch (this.selectedSystemRowData.description) {
      case "AWS 75 SI+":
      case "AWS 75 BS.SI+":
      case "AWS 75 BS.HI+":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?LID=en&fwd=true#1510090";
      case "AWS 90 SI+":
      case "AWS 90 BS.SI+":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?LID=en&fwd=true#1549502";
      case "FWS 35 PD":
      case "FWS 35 PD.SI":
      case "FWS 35 PD.HI":
      case "FWS 50":
      case "FWS 50.HI":
      case "FWS 50.SI":
      case "FWS 50.SI GREEN":
      case "FWS 60":
      case "FWS 60.HI":
      case "FWS 60.SI":
      case "FWS 60.SI GREEN":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1549581";
      case "UDC 80":
      case "UDC 80.HI":
      case "UDC 80.SI":
      case "UDC 80.SI WITH XPS FILLING":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1575106";
      case "AWS 75.II":
      case 'AWS 75 BD':
      case 'AWS 75 BS.HI+ (FR)':
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1314808";
      case "":
        return "https://docucenter.schueco.com/";
    }
  }

  getFabManual_SRC() {
    switch (this.selectedSystemRowData.description) {
      case "AWS 75 SI+":
      case "AWS 75 BS.SI+":
      case "AWS 75 BS.HI+":
      case "AWS 90 SI+":
      case "AWS 90 BS.SI+":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?LID=en&fwd=true#1567371";
      case "FWS 35 PD":
      case "FWS 35 PD.SI":
      case "FWS 35 PD.HI":
      case "FWS 50":
      case "FWS 50.HI":
      case "FWS 50.SI":
      case "FWS 50.SI GREEN":
      case "FWS 60":
      case "FWS 60.HI":
      case "FWS 60.SI":
      case "FWS 60.SI GREEN":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1568358";
      case "UDC 80":
      case "UDC 80.HI":
      case "UDC 80.SI":
      case "UDC 80.SI WITH XPS FILLING":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1523091";
      case "AWS 75.II":
      case 'AWS 75 BD':
      case 'AWS 75 BS.HI+ (FR)':
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1472658";
      case "":
        return "https://docucenter.schueco.com/";
    }
  }

  getArchInfo_SRC() {
    switch (this.selectedSystemRowData.description) {
      case "AWS 75 SI+":
      case "AWS 75 BS.SI+":
      case "AWS 75 BS.HI+":
      case "AWS 90 SI+":
      case "AWS 90 BS.SI+":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?LID=en&fwd=true#1264127";
      case "FWS 35 PD":
      case "FWS 35 PD.SI":
      case "FWS 35 PD.HI":
      case "FWS 50":
      case "FWS 50.HI":
      case "FWS 50.SI":
      case "FWS 50.SI GREEN":
      case "FWS 60":
      case "FWS 60.HI":
      case "FWS 60.SI":
      case "FWS 60.SI GREEN":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1020673";
      case "UDC 80":
      case "UDC 80.HI":
      case "UDC 80.SI":
      case "UDC 80.SI WITH XPS FILLING":
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1192855";
      case "AWS 75.II":
      case 'AWS 75 BD':
      case 'AWS 75 BS.HI+ (FR)':     
        return "https://docucenter.schueco.com/web/main/SinglePageApp.php?fwd=true#1142590";
      case "":
        return "https://docucenter.schueco.com/";
    }
  }

  //# End region











  


//#Region Right Panel
  /**
   * Table settings before translate service is ready
   * Wait for the translate service to be ready
   * New table settings
   */
  setConfigurationCustomGrid() {
    this.configurationCustomGrid = { 
      fields: [ 
        { celdType: CeldType.Default, display: this.translate.instant(_('sys-table.name')), property: 'description', width: '245px', showSort: true },
        { celdType: CeldType.TemplateRef, property: 'outside', width: '80px', showSort: false }
      ],
      fieldId: 'id'
    };
  }

  translateCustomGridName(): void {
    this.configurationCustomGrid.fields[0].display = this.translate.instant(_('sys-table.name'));
  }

  preventDefault($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  /**
   * Load the framing system data in the three tables depending on the system type
   */
  loadData(): void {
    this.data1 = [];
    this.data2 = [];
    this.data3 = [];
    this.listOfFramingSystems.forEach( framingSystem => {
      framingSystem.Selected = Boolean(framingSystem.Project);
      switch (framingSystem.ProductType.Name) {
        case "Window":
          this.data1.push({
            id: framingSystem.FramingSystemId,
            description: framingSystem.SystemName,
            status: this.translate.instant(_('sys-table.active')),
            market: {FR: framingSystem.FR, DE: framingSystem.DE, US: framingSystem.US},
            moreInfo: framingSystem.MoreInfo,
            systemNameSmall: framingSystem.Name,
            outside: {
              ref: this.rightColumnTemplate,
              context: {
                id: framingSystem.FramingSystemId,
                table: 'tableA',
                activated: framingSystem.Selected,
                disabled: false,
                description: framingSystem.SystemName,
              }
            },
            disabled:  false,
            bpsTable4CustomRow: framingSystem.Selected,
            showInNotification: false
          });
          break;
        case "Mullion-Transom":
          this.data3.push({
            id: framingSystem.FramingSystemId,
            description: framingSystem.SystemName,
            status: this.translate.instant(_('sys-table.active')),
            market: {FR: framingSystem.FR, DE: framingSystem.DE, US: framingSystem.US},
            moreInfo: framingSystem.MoreInfo,
            systemNameSmall: framingSystem.Name,
            outside: {
              ref: this.rightColumnTemplate,
              context: {
                id: framingSystem.FramingSystemId,
                table: 'tableC',
                activated: framingSystem.Selected,
                disabled: false,
                description: framingSystem.SystemName,
              }
            },
            disabled: false,
            bpsTable4CustomRow: framingSystem.Selected,
            showInNotification: false
          });
          break;
        case "UDC":
          this.data3.push({
            id: framingSystem.FramingSystemId,
            description: framingSystem.SystemName,
            status: this.translate.instant(_('sys-table.active')),
            market: {FR: framingSystem.FR, DE: framingSystem.DE, US: framingSystem.US},
            moreInfo: framingSystem.MoreInfo,
            systemNameSmall: framingSystem.Name,
            outside: {
              ref: this.rightColumnTemplate,
              context: {
                id: framingSystem.FramingSystemId,
                table: 'tableC',
                activated: framingSystem.Selected,
                disabled: false,
                description: framingSystem.SystemName,
              }
            },
            disabled: false,
            bpsTable4CustomRow: framingSystem.Selected,
            showInNotification: false
          });
          break;
      }
    });
    this.stService.isNewSystemAddedToTheActivatedSystems = false;
    this.systemDataInUseToAdd1.forEach(data => {
      this.addSystem1InUse(data);
    });
    this.systemDataInUseToAdd2.forEach(data => {
      this.addSystem2InUse(data);
    });
    this.systemDataInUseToAdd3.forEach(data => {
      this.addSystem3InUse(data);
    });
    if (this.stService.isNewSystemAddedToTheActivatedSystems) this.stService.saveSystemsSubject.next(true);
    this.systemDataInUseToAdd1 = [];
    this.systemDataInUseToAdd2 = [];
    this.systemDataInUseToAdd3 = [];
  }

/**
 * Lock and mark as 'in use' a system in the respective table
 * @param data 
 */
  addSystem1InUse(data) {
    let system = this.data1.find(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase() == data.systemDescription.substring().split(' ').join('').split('.').join('').toUpperCase());
    if (!system.outside.context.activated) this.stService.isNewSystemAddedToTheActivatedSystems = true;
    system.bpsTable4CustomRow = true;
    system.outside.context.activated = true;
    system.showInNotification = false;
    this.stService.addSavedSystemSelectionInService(data.systemDescription);
    if (data.select) this.unselectArticle();
  }

  addSystem2InUse(data) {
    let system = this.data2.find(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase() == data.systemDescription.substring().split(' ').join('').split('.').join('').toUpperCase());
    if (!system.outside.context.activated) this.stService.isNewSystemAddedToTheActivatedSystems = true;
    system.bpsTable4CustomRow = true;
    system.outside.context.activated = true;
    system.showInNotification = false;
    this.stService.addSavedSystemSelectionInService(data.systemDescription);
    if (data.select) this.unselectArticle();
  }

  addSystem3InUse(data) {
    let system = this.data3.find(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase() == data.systemDescription.substring().split(' ').join('').split('.').join('').toUpperCase());
    if (!system.outside.context.activated) this.stService.isNewSystemAddedToTheActivatedSystems = true;
    system.bpsTable4CustomRow = true;
    system.outside.context.activated = true;
    system.showInNotification = false;
    this.stService.addSavedSystemSelectionInService(data.systemDescription);
    if (data.select) this.unselectArticle();
  }

  log($event) {
    console.log($event);
  }

  sort(sort: { sortName: string; sortValue: string }, table: string): void {
    switch(table) {
      case 'tableA':
        this.sortName1 = sort.sortName;
        this.sortValue1 = sort.sortValue;
        break;
      case 'tableB':
        this.sortName2 = sort.sortName;
        this.sortValue2 = sort.sortValue;
        break;
      case 'tableC':
        this.sortName3 = sort.sortName;
        this.sortValue3 = sort.sortValue;
        break;
    }
    this.organizeDisplayData();
  }

  translateStatusOfData(): void {
    this.data1.forEach(article => { article.status = this.translate.instant(_('sys-table.active')); });
    this.data2.forEach(article => { article.status = this.translate.instant(_('sys-table.active')); });
    this.data3.forEach(article => { article.status = this.translate.instant(_('sys-table.active')); });
  }

  /**
   * Filter and sort the data of the tables for the search functionality,
   * Then place the in-use systems on top of the tables if no sorting is applied,
   * Select the row of the selected article if displayed again,
   * And send the data to display to the left system table (if any row is selected or not)
   */
organizeDisplayData(): void {
  const filterAndSortData = (data: any[], sortName: string | null, sortValue: string | null) => {
    return data
      .filter((item) => item.description.includes(this.searchSystemInputText.toUpperCase()))
      .sort((a, b) => {
        if (!sortName || !sortValue) return 0;
        return sortValue === 'ascend' ? (a[sortName] > b[sortName] ? 1 : -1) : (b[sortName] > a[sortName] ? 1 : -1);
      });
  };

  const selectRowInTable = (tableComponent: any) => {
    setTimeout(() => {
      tableComponent.selectRow(this.rowSelectionData);
      this.onFramingSystemSelection(this.rowSelectionData);
    });
  };

  const moveInUseSystemsToTop = (data: any[]) => {
    const inUseSystems = data.filter((system) => this.isSystemCurrentlyUsed(system.description));
    const otherSystems = data.filter((system) => !this.isSystemCurrentlyUsed(system.description));
    return inUseSystems.concat(otherSystems);
  };

  this.listOfDisplayData1 = filterAndSortData(this.data1, this.sortName1, this.sortValue1);
  this.listOfDisplayData2 = filterAndSortData(this.data2, this.sortName2, this.sortValue2);
  this.listOfDisplayData3 = filterAndSortData(this.data3, this.sortName3, this.sortValue3);

  if (!this.sortValue1) this.listOfDisplayData1 = moveInUseSystemsToTop(this.listOfDisplayData1);
  if (!this.sortValue2) this.listOfDisplayData2 = moveInUseSystemsToTop(this.listOfDisplayData2);
  if (!this.sortValue3) this.listOfDisplayData3 = moveInUseSystemsToTop(this.listOfDisplayData3);

  if (this.listOfDisplayData1.some((system) => system.id === this.rowSelectionData.id)) {
    selectRowInTable(this.table1Component);
  } else if (this.listOfDisplayData2.some((system) => system.id === this.rowSelectionData.id)) {
    selectRowInTable(this.table2Component);
  } else if (this.listOfDisplayData3.some((system) => system.id === this.rowSelectionData.id)) {
    selectRowInTable(this.table3Component);
  } else {
    this.onFramingSystemSelection();
  }
}

  /**
   * Create an array of all the id of the selected systems and save the information to the back-end with the ProjectGuid
   * Create an array of all the description of the selected systems and save the information to the system-table service for the framing UI
   * Get the strings of all the name of the added systems and display them in the notifications
   * Get the strings of all the name of the removed systems and display them in the notifications
   * Mark these names to hide in the notifications in the future
   * Mark the names of the non-selected systems to show in the notifications in the future if they are selected
   * Send an error message if no system is selected in a category (aws, fws, udc)
   */
  onConfirm() {
    // Send SelectedSystemsIds to system-table component
    let arrayOfSelectedSystemsId = [].concat(
      this.listOfDisplayData1.filter(system => system.outside.context.activated).map(system => system.id),
      this.listOfDisplayData2.filter(system => system.outside.context.activated).map(system => system.id),
      this.listOfDisplayData3.filter(system => system.outside.context.activated).map(system => system.id),
    );
    let systemSelectionInfo = { SelectedSystemIds: arrayOfSelectedSystemsId.slice(), ProjectGuid: this.configureService.problemSetting.ProjectGuid };

    let WindowSystemAdded_string: string = this.listOfDisplayData1.slice().filter(system => system.outside.context.activated && system.showInNotification).map(system => system.description).join(', ');
    let SlidingSystemAdded_string: string = this.listOfDisplayData2.slice().filter(system => system.outside.context.activated && system.showInNotification).map(system => system.description).join(', ');
    let FacadeSystemAdded_string: string = this.listOfDisplayData3.slice().filter(system => system.outside.context.activated && system.showInNotification).map(system => system.description).join(', ');
    let WindowSystemRemoved_string: string = this.listOfDisplayData1.slice().filter(system => !system.outside.context.activated && system.showInNotification).map(system => system.description).join(', ');
    let SlidingSystemRemoved_string: string = this.listOfDisplayData2.slice().filter(system => !system.outside.context.activated && system.showInNotification).map(system => system.description).join(', ');
    let FacadeSystemRemoved_string: string = this.listOfDisplayData3.slice().filter(system => !system.outside.context.activated && system.showInNotification).map(system => system.description).join(', ');

    this.stService.EditSelectedSystems(systemSelectionInfo).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      // Add system notifications
      if (WindowSystemAdded_string) this.notifCustomTemplate.notificationShow("WindowSystemAdded", WindowSystemAdded_string);
      if (SlidingSystemAdded_string) this.notifCustomTemplate.notificationShow("SlidingSystemAdded", SlidingSystemAdded_string);
      if (FacadeSystemAdded_string) this.notifCustomTemplate.notificationShow("FacadeSystemAdded", FacadeSystemAdded_string);
      // Remove system notifications
      if (WindowSystemRemoved_string) this.notifCustomTemplate.notificationShow("WindowSystemRemoved", WindowSystemRemoved_string);
      if (SlidingSystemRemoved_string) this.notifCustomTemplate.notificationShow("SlidingSystemRemoved", SlidingSystemRemoved_string);
      if (FacadeSystemRemoved_string) this.notifCustomTemplate.notificationShow("FacadeSystemRemoved", FacadeSystemRemoved_string);
      
      // Hide or Add system in notification for next time
      this.listOfDisplayData1.forEach(system => system.showInNotification = true);
      this.listOfDisplayData2.forEach(system => system.showInNotification = true);
      this.listOfDisplayData3.forEach(system => system.showInNotification = true);  

      this.listOfFramingSystems = response;
      this.loadFramingSystemsData();
    });
  }

  saveConfirmedSystems() {
    let arrayOfSelectedSystemsId = [].concat(
      this.listOfDisplayData1.filter(system => system.outside.context.activated && !system.showInNotification).map(system => system.id),
      this.listOfDisplayData2.filter(system => system.outside.context.activated && !system.showInNotification).map(system => system.id),
      this.listOfDisplayData3.filter(system => system.outside.context.activated && !system.showInNotification).map(system => system.id),
    );
    let systemSelectionInfo = { SelectedSystemIds: arrayOfSelectedSystemsId.slice(), ProjectGuid: this.configureService.problemSetting.ProjectGuid };
    this.stService.EditSelectedSystems(systemSelectionInfo).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.listOfFramingSystems = response;
      this.loadFramingSystemsData();
    });
  }

  onClose(): void {
    this.searchSystemInputText = '';
    this.cpService.setPopout(false, PanelsModule.SystemTable);
    this.onDeactivateNonConfirmedArticles();
    this.onReactivateConfirmedArticles();
    this.unselectArticle();
  }

  onDeactivateNonConfirmedArticles() {
    [this.listOfDisplayData1, this.listOfDisplayData2, this.listOfDisplayData3].forEach(listOfDisplayData => {
      listOfDisplayData.forEach(system => {
        if (!this.isSystemCurrentlyUsed(system.description) && system.outside.context.activated && system.showInNotification) {
          system.bpsTable4CustomRow = !system.bpsTable4CustomRow;
          system.outside.context.activated = !system.outside.context.activated;
        }
      });
    });
  }

  onReactivateConfirmedArticles() {
    [this.listOfDisplayData1, this.listOfDisplayData2, this.listOfDisplayData3].forEach(listOfDisplayData => {
      listOfDisplayData.forEach(system => {
        if (!this.isSystemCurrentlyUsed(system.description) && !system.outside.context.activated && this.isSystemCurrentlyDisplayedInFraming(system.description)) {
          system.bpsTable4CustomRow = !system.bpsTable4CustomRow;
          system.outside.context.activated = !system.outside.context.activated;
        }
      });
    });
  }

  /**
   * Unselect the row of the other tables
   * Send the row data to the stService for the system table left component
   * @param table table to keep the row selected
   * @param data 
   */
  onChangeRowSelection(table: string, data: any) {
    if (this.table1Component && this.table2Component && this.table3Component){
      this.rowSelectionData = data;
      switch (table) {
        case 'tableA':
          this.table2Component.selectRow({disabled: false, id: -1}, true);
          this.table3Component.selectRow({disabled: false, id: -1}, true);
          break;
        case 'tableB':
          this.table1Component.selectRow({disabled: false, id: -1}, true);
          this.table3Component.selectRow({disabled: false, id: -1}, true);
          break;
        case 'tableC':
          this.table2Component.selectRow({disabled: false, id: -1}, true);
          this.table1Component.selectRow({disabled: false, id: -1}, true);
          break;
      }
      this.onFramingSystemSelection(data);
    }
  }

  /**
   * The user checks or unchecks a box on the right column of the table.
   * The row UI changes are made and the rows of the other tables are unselected
   * @param table 
   * @param id id of the article to edit
   */
  onActivateDeactivateArticle(table: string, id: number) {
    let system = table == 'tableA' ? this.listOfDisplayData1.find(system => system.id == id): table == 'tableB' ? this.listOfDisplayData2.find(system => system.id == id): table == 'tableC' ? this.listOfDisplayData3.find(system => system.id == id):null;
    if (system) {
      if (!this.isSystemCurrentlyUsed(system.description)) {
        system.bpsTable4CustomRow = !system.bpsTable4CustomRow;
        system.outside.context.activated = !system.outside.context.activated;
        system.showInNotification = !system.showInNotification;
      }
    }
  }

  isSystemCurrentlyUsed(systemDescription: String): Boolean {
    return Object.values(this.stService.systemOfProblemsUsed).includes(systemDescription.substring(0).split(' ').join('').split('.').join('').toUpperCase());
  }

  isSystemCurrentlyDisplayedInFraming(systemDescription: String): Boolean {
    return this.stService.arrayOfSelectedSystemsDescription.includes(systemDescription.substring(0).split(' ').join('').split('.').join('').toUpperCase());
  }

  onChangeConfigurationNameInUse(description) {
    let listOfProblemGuidUsed = Object.keys(this.stService.systemOfProblemsUsed).filter(problemGuid => this.stService.systemOfProblemsUsed[problemGuid].substring().split(' ').join('').split('.').join('').toUpperCase() == description.substring().split(' ').join('').split('.').join('').toUpperCase());
    this.configurationNameInUse = listOfProblemGuidUsed.map(problemGuid => {return this.configureService.problemList.find(problem => problem.ProblemGuid == problemGuid).ProblemName;});
  }

  clearNotifications() {
    this.notifCustomTemplate.notificationRemove();
  }

  /**
   * At the opening of the panel
   * The system of the current configuration is selected if no article is already selected
   */
  selectDefaultArticle() {
    if (!Boolean(this.rowSelectionData.id)) {
      this.selectArticleOfTheCurrentConfiguration();
    }
  }

  selectArticleOfTheCurrentConfiguration() {
    switch(this.umService.current_UnifiedModel.ProblemSetting.ProductType) {
      case 'Window':
        this.rowSelectionData = this.data1.find(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase() == this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType.substring(0).split(' ').join('').split('.').join('').toUpperCase());
        if (this.rowSelectionData) {
          this.table1Component.selectRow({disabled: false, id: this.rowSelectionData.id}, true);
          this.table2Component.selectRow({disabled: false, id: -1}, true);
          this.table3Component.selectRow({disabled: false, id: -1}, true);
          this.onFramingSystemSelection(this.rowSelectionData);
        }
        break;
      case 'Facade':
        this.rowSelectionData = this.data3.find(system => system.description.substring().split(' ').join('').split('.').join('').toUpperCase() == (this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType.substring(0) + (this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone ? this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone:"")).split(' ').join('').split('.').join('').toUpperCase());
        if (this.rowSelectionData) {
          this.table1Component.selectRow({disabled: false, id: -1}, true);
          this.table2Component.selectRow({disabled: false, id: -1}, true);
          this.table3Component.selectRow({disabled: false, id: this.rowSelectionData.id}, true);
          this.onFramingSystemSelection(this.rowSelectionData);
        }
        break;
    }
  }

  unselectArticle() {
    this.rowSelectionData = {};
    this.table1Component.selectRow({disabled: false, id: -1}, true);
    this.table2Component.selectRow({disabled: false, id: -1}, true);
    this.table3Component.selectRow({disabled: false, id: -1}, true);
    this.onFramingSystemSelection();
  }
  //# End region
}
