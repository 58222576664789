<div class="sps-left-popout" [ngStyle]="{'display': isPopoutOpened ? 'flex':'none' }">
    <div class="top-container">
        <div class="heading-container">
            <h3 class="title">{{ 'configure.code-selection' | translate }}</h3>
            <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse" alt="close icon" style="cursor: pointer;" (click)="onClose()">
        </div>
        <form bps-form [formGroup]="validateForm">
            <bps-form-item class="no-margin-bottom">
                <bps-form-control>
                    <bps-select style="width: 195px" [(ngModel)]="classificationSelection" formControlName="classificationSelectionControl">
                        <bps-option bpsValue="1" bpsLabel="{{'configure.calculation-method-US-Standard' | translate}}"></bps-option>
                        <bps-option bpsValue="2" bpsLabel="{{'configure.calculation-method-European-Standard' | translate}}"></bps-option>
                      </bps-select>
                </bps-form-control>
            </bps-form-item>
        </form>
        <div class="sps-break"></div>
    </div>
    <div class="bottom-container shadow-line-up">
        <button class="confirm-btn" bps-button bpsType="variation-4" (click)="onConfirm();onClose();">{{'configure.confirm' | translate }}</button>
    </div>
</div>
