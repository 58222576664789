import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { takeUntil } from 'rxjs/operators';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { Subject } from 'rxjs';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-classification-table',
  templateUrl: './classification-table.component.html',
  styleUrls: ['./classification-table.component.css']
})
export class ClassificationTableComponent implements OnInit, AfterViewInit {
  isPopoutOpened: boolean = false;
  private destroy$ = new Subject<void>();
  classificationSelection: string;
  validateForm: FormGroup;


  constructor(private cpService: ConfigPanelsService, private umService: UnifiedModelService, private cService: ConfigureService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      classificationSelectionControl: [this.classificationSelection, [Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.ClassificationTable) {
          if (response.isOpened) {
            this.classificationSelection = this.umService.getAcousticClassificationValue()?.toString();
          }
          this.isPopoutOpened = response.isOpened;
        }
      });
  }

  onClose() {
    this.cpService.setPopout(false, PanelsModule.ClassificationTable);
  }

  onConfirm() {
    if (parseInt(this.classificationSelection) != this.umService.getAcousticClassificationValue()) this.cService.computeClickedSubject.next(false);
    this.umService.set_ClassificationCalculation(parseInt(this.classificationSelection));
  }

}
