<div [ngStyle]="{'display': isPopoutOpened ? 'flex':'none' }" class="sps-left-popout">
    <div class="lp-panel">
        <div class="lp-panel-top">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text">{{'configure.sustainability.methods-and-distances' | translate }}</h3>
                    <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse" alt="close icon" (click)="onClose()" style="cursor: pointer;">
                </div>
            </div>
            <form bps-form class="form" [formGroup]="validateForm">
                <div class="field-container">
                    <h3 class="input-header">{{'configure.sustainability.lorry-12' | translate}}</h3>
                    <div class="input-row">
                        <bps-form-item class="bps-form-item">
                            <bps-form-control>
                                <input type="number" min="0" max="100000" class="input-elmt" bps-input formControlName="lorry12Control">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="input-unit">Km</span>
                    </div>
                </div>
                <div class="field-container">
                    <h3 class="input-header">{{'configure.sustainability.lorry-28' | translate}}</h3>
                    <div class="input-row">
                        <bps-form-item class="bps-form-item">
                            <bps-form-control>
                                <input type="number" min="0" max="100000" class="input-elmt" bps-input formControlName="lorry28Control">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="input-unit">Km</span>
                    </div>
                </div>
                <div class="field-container">
                    <h3 class="input-header">{{'configure.sustainability.lorry-40' | translate}}</h3>
                    <div class="input-row">
                        <bps-form-item class="bps-form-item">
                            <bps-form-control>
                                <input type="number" min="0" max="100000" class="input-elmt" bps-input formControlName="lorry40Control">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="input-unit">Km</span>
                    </div>
                </div>
                <div class="field-container">
                    <h3 class="input-header">{{'configure.sustainability.train' | translate}}</h3>
                    <div class="input-row">
                        <bps-form-item class="bps-form-item">
                            <bps-form-control>
                                <input type="number" min="0" max="100000" class="input-elmt" bps-input formControlName="trainControl">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="input-unit">Km</span>
                    </div>
                </div>
                <div class="field-container">
                    <h3 class="input-header">{{'configure.sustainability.aeroplane' | translate}}</h3>
                    <div class="input-row">
                        <bps-form-item class="bps-form-item">
                            <bps-form-control>
                                <input type="number" min="0" max="100000" class="input-elmt" bps-input formControlName="aeroplaneControl">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="input-unit">Km</span>
                    </div>
                </div>
                <div class="field-container">
                    <h3 class="input-header">{{'configure.sustainability.ship' | translate}}</h3>
                    <div class="input-row">
                        <bps-form-item class="bps-form-item">
                            <bps-form-control>
                                <input type="number" min="0" max="100000" class="input-elmt" bps-input formControlName="shipControl">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="input-unit">Km</span>
                    </div>
                </div>
            </form>
        </div>
        <!-- Confirm -->
        <div class="lp-confirm-btn-container shadow-line-up">
            <button class="lp-confirm-btn" [disabled]="!validateForm.valid" bps-button bpsType="variation-4" (click)="onConfirm(); onClose();">{{'configure.confirm' | translate }}</button>
        </div>
    </div>
</div>