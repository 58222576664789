<div *ngIf="unified3DModel" nzType="flex" nzjustify="center" class="sps-accordion-section-container">
  <div class="sps-accordion-section" id="config-framing">
    <div class="column-container"
      *ngIf="unified3DModel && unified3DModel.ProblemSetting && unified3DModel.ProblemSetting.ProductType=='Window'">
      <div class="sps-accordion-ui-section-content-container">
        <div id="system-cross-section-img-container" class="sps-accordion-ui-section-content">
          <img id="system-cross-section-img" *ngIf="awsSystemValue[systemSelected] !== undefined"
            [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/'+awsSystemValue[systemSelected]+'.jpg'" alt="aws_picture">
        </div>
      </div>
      <div id="system-pill-container" class="sps-accordion-ui-section-content" style="margin-bottom: 0;">
        <div id="sps-system-names" style="display: block;" class="sps-pill-container" *ngIf="showDoorArticles">
          <ng-continer>
            <div class="sps-pill" [ngClass]="{'selected': systemSelected === 0, 'deselected': orderPlaced==true}"
              (click)="onSelectSystemWindow(0)" *appCheckPermissions="feature.ADS_75">
              <p>ADS 75</p>
            </div>
          </ng-continer>
        </div>
        
        <div id="sps-system-names" class="sps-pill-container" *ngIf="!showDoorArticles">
          <div class="sps-pill" [ngClass]="{'selected': systemSelected === 0, 'deselected': orderPlaced==true, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS75SI+')}"
            (click)="onSelectSystemWindow(0)" *appCheckPermissions="feature.AWS_75_SI_plus">
            <p>AWS 75.SI+</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_75_BS_SI_plus" [ngClass]="{'selected': systemSelected === 1, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS75BSSI+')}"
            (click)="onSelectSystemWindow(1)">
            <p>AWS 75 BS.SI+</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_75_BS_HI_plus" [ngClass]="{'selected': systemSelected === 2, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS75BSHI+')}"
            (click)="onSelectSystemWindow(2)">
            <p>AWS 75 BS.HI+</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_75_II" [ngClass]="{'selected': systemSelected === 5, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS75II')}"
            (click)="onSelectSystemWindow(5)">
            <p>AWS 75.II</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_75_BD" [ngClass]="{'selected': systemSelected === 6, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS75BD')}"
            (click)="onSelectSystemWindow(6)">
            <p>AWS 75 BD</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_75_BS_HI_FR" [ngClass]="{'selected': systemSelected === 7, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS75BSHI+(FR)')}"
            (click)="onSelectSystemWindow(7)">
            <p>AWS 75 BS.HI+ (FR)</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_90_SI_plus" [ngClass]="{'selected': systemSelected === 3, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS90SI+')}"
            (click)="onSelectSystemWindow(3)">
            <p>AWS 90.SI+</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.AWS_90_BS_SI_plus" style="margin-bottom: 0;"
            [ngClass]="{'selected': systemSelected === 4, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('AWS90BSSI+')}" (click)="onSelectSystemWindow(4)">
            <p>AWS 90 BS.SI+</p>
          </div>
        </div>
      </div>
      <div class="sps-break"></div>
      <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container" style="margin-top: 0;">
        <h3 class="outer-frame-heading" [ngClass]="{'blue-heading': isOuterOpened, 'disabled': orderPlaced===true}">
          {{'configure.outer-frame' | translate }}</h3>
        <!-- Outer Frame -->
        <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="outerFrameInputValue">
            </div>
            <button bps-button bpsType="variation-13"
              [ngClass]="{'active': isOuterOpened, 'arrowdeactive': orderPlaced===true}"
              style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
              (click)="onOpenIFramePopout('OuterFrame')" [disabled]="orderPlaced==true">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">

            </button>
          </div>
        </div>
      </div>
      <div id="sps-operability-vent-frame-container" class="sps-operability-vent-frame-container"
        style="margin-top: 22px;" *ngIf="showVent">
        <div style="margin-top: 7px; display: flex; justify-content: space-between; width: 214px;">
          <h3 id="operablility-vent-frame-heading-width" class="vent-frame-heading"
            [ngClass]="{'blue-heading': isVentOpened, 'disabled': orderPlaced===true}">{{'configure.vent-frame' |
            translate }}</h3>
          <!-- Vent Frame -->
          <div>
            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg" bps-popover
              [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
              [bpsPopoverContent]="ventInfo">
          </div>
        </div>
        <div id="sps-operablility-vent-frame-dropdown-container" class="accordion-ui-section-content"
            style="margin: 7px 0px 0px 0px;">
            <div class="sps-operability-vent-frame-button-container">
              <div>
                <input style="width: 170px;"
                  [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                  centered disabled bpsDisabled bps-input [(ngModel)]="ventFrameInputValue">
              </div>
              <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('VentFrame')"
                [ngClass]="{'active': isVentOpened}" [disabled]="orderPlaced">
                <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                  *ngIf="!orderPlaced">
                <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                  *ngIf="orderPlaced">
              </button>
            </div>
          </div>
      </div>
      <ng-template #ventInfo>
        <div style="text-align: left; padding-left: 15px;">
          <div>
            <span>{{'configure.vent-Info-part1' | translate }}</span><br/>
            <span>{{'configure.vent-Info-part2' | translate }}</span><br/>
            <span>{{'configure.vent-Info-part3' | translate }}</span><br/>
            <span>{{'configure.vent-Info-part4' | translate }}</span><br/>
            <span>{{'configure.vent-Info-part5' | translate }}</span>
            <!-- with glazing rebate insulation, assembly insulation first -->
          </div>
        </div>
      </ng-template>
      <div id="sps-mullion-transom-input-container-mullion" class="sps-accordion-ui-section-content-container"
        style="margin-top: 22px;" *ngIf="showMullion">
        <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isMullionOpened, 'disabled': orderPlaced===true}">
          {{'configure.mullion' | translate }}</h3>
        <!-- Mullion -->
        <div id="sps-mullion-transom-dropdown-container" class="accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="mullionInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('Mullion')"
              [ngClass]="{'active': isMullionOpened}" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>
      <div id="sps-mullion-transom-input-container-transom" class="sps-accordion-ui-section-content-container"
        style="margin-top: 22px;" *ngIf="showTransom">
        <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isTransomOpened, 'disabled': orderPlaced===true}">
          {{'configure.transom' | translate }}</h3>
        <!-- Transom -->
        <div id="sps-mullion-transom-dropdown-container-transom" class="sps-accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="transomInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('Transom')"
              [ngClass]="{'active': isTransomOpened}" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>

      <!-- Sill Profile(Fixed Panel) -->
      <div id="sps-mullion-transom-input-container-transom" class="sps-accordion-ui-section-content-container"
      style="margin-top: 22px;" *ngIf="showDoorArticles && showMullion">
      <h3 class="mullion-frame-heading" style="text-transform: none;" [ngClass]="{'blue-heading': isSillProfileFixedOpened, 'disabled': orderPlaced===true}">
        SILL PROFILE - (Fixed panel)</h3>
      <!-- Sill Profile(Fixed Panel) -->
      <div id="sps-mullion-transom-dropdown-container-transom" class="sps-accordion-ui-section-content"
        style="margin: 7px 0px 0px 0px;">
        <div class="sps-operability-vent-frame-button-container">
          <div>
            <input style="width: 170px;"
              [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
              centered disabled bpsDisabled bps-input [(ngModel)]="sillProfileFixedValue">
          </div>
          <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('SillProfileFixed')"
            [ngClass]="{'active': isSillProfileFixedOpened}" [disabled]="orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
          </button>
        </div>
      </div>
    </div>
      <!-- End of  Sill Profile(Fixed Panel) -->

      <!-- Sill Profile(Bottom) -->
      <div id="sps-mullion-transom-input-container-transom" class="sps-accordion-ui-section-content-container"
      style="margin-top: 22px;" *ngIf="showDoorArticles">
      <h3 class="mullion-frame-heading"  style="text-transform: none;" [ngClass]="{'blue-heading': isSillProfileBottomOpened, 'disabled': orderPlaced===true}">
        SILL PROFILE (Bottom)</h3>
      <!-- Sill Profile(Bottom) -->
      <div id="sps-mullion-transom-dropdown-container-transom" class="sps-accordion-ui-section-content"
        style="margin: 7px 0px 0px 0px;">
        <div class="sps-operability-vent-frame-button-container">
          <div>
            <input style="width: 170px;"
              [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
              centered disabled bpsDisabled bps-input [(ngModel)]="sillProfileBottomValue">
          </div>
          <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('SillProfileBottom')"
            [ngClass]="{'active': isSillProfileBottomOpened}" [disabled]="orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
          </button>
        </div>
      </div>
    </div>
      <!-- End of  Sill Profile(Bottom) -->

      <!-- Door Leaf(Active Vent) -->
      <div id="sps-mullion-transom-input-container-transom" class="sps-accordion-ui-section-content-container"
      style="margin-top: 22px;" *ngIf="showDoorArticles">
      <h3 class="mullion-frame-heading"  style="text-transform: none;" [ngClass]="{'blue-heading': isDoorLeafActiveOpened, 'disabled': orderPlaced===true}">
        DOOR LEAF (Active Vent)</h3>
      <!-- Door Leaf(Active Vent) -->
      <div id="sps-mullion-transom-dropdown-container-transom" class="sps-accordion-ui-section-content"
        style="margin: 7px 0px 0px 0px;">
        <div class="sps-operability-vent-frame-button-container">
          <div>
            <input style="width: 170px;"
              [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
              centered disabled bpsDisabled bps-input [(ngModel)]="doorLeafActiveValue">
          </div>
          <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('DoorLeafActive')"
            [ngClass]="{'active': isDoorLeafActiveOpened}" [disabled]="orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
          </button>
        </div>
      </div>
    </div>
      <!-- End of  Door Leaf(Active Vent) -->

      <!-- Door Leaf(Passive Vent) -->
      <div id="sps-mullion-transom-input-container-transom" class="sps-accordion-ui-section-content-container"
      style="margin-top: 22px;" *ngIf="showDoorArticles && isDoubleDoor">
      <h3 class="mullion-frame-heading"  style="text-transform: none;" [ngClass]="{'blue-heading': isDoorLeafPassiveOpened, 'disabled': orderPlaced===true}">
        DOOR LEAF (Passive Vent Jamb)</h3>
      <!-- Door Leaf(Passive Vent) -->
      <div id="sps-mullion-transom-dropdown-container-transom" class="sps-accordion-ui-section-content"
        style="margin: 7px 0px 0px 0px;">
        <div class="sps-operability-vent-frame-button-container">
          <div>
            <input style="width: 170px;"
              [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
              centered disabled bpsDisabled bps-input [(ngModel)]="doorLeafPassiveValue">
          </div>
          <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('DoorLeafPassive')"
            [ngClass]="{'active': isDoorLeafPassiveOpened}" [disabled]="orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
              src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
          </button>
        </div>
      </div>
    </div>
      <!-- End of  Door Leaf(Passive Vent) -->

      <div *appCheckPermissions="feature.ProfileColor" class="sps-accordion-ui-section-content-container"
        style="margin-top: 0px;">
        <div class="sps-break"></div>
        <h3 class="outer-frame-heading"
          [ngClass]="{'blue-heading': isProfileColorOpened, 'disabled': orderPlaced===true}">
          {{'configure.profile-color' | translate }}</h3>
        <!-- profile color -->
        <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
              <input style="width: 170px; " centered disabled bpsDisabled bps-input [(ngModel)]="profileColorInputValue"
                (ngModelChange)="onProfileColorChange($event)"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true,  'ipactive': orderPlaced===false}">
            </div>
            <button bps-button bpsType="variation-13" [ngClass]="{'active': isProfileColorOpened}"
              style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
              (click)="onOpenIFramePopout('ProfileColor')" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>
      <div class="sps-break"
        *ngIf="unified3DModel.ProblemSetting.EnableStructural || unified3DModel.ProblemSetting.EnableThermal"></div>

      <form bps-form [formGroup]="validateForm">
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div class="insulating-bar-container"
              *ngIf="unified3DModel.ProblemSetting.EnableStructural || unified3DModel.ProblemSetting.EnableThermal">
              <h3 class="vent-frame-heading">{{'configure.insulating-bar-material' | translate }}</h3>
              <!-- Insulating Bar -->
              <div style="margin-top: 10px;">
                <!-- <bps-select style="width: 222px" formControlName="Insulating" [(ngModel)]="insulatingSelection"
                  name="insulatingSelection" (ngModelChange)="onSelectInsulating($event)"
                  bpsPlaceHolder="{{'configure.select' | translate }}"> -->
                  <!-- Select -->
                  <!-- <bps-option bpsValue="Polythermid Coated Before" *ngIf="!systemType || this.systemType=='75'"
                    bpsLabel="{{'configure.polythermid-coated-before' | translate }}"></bps-option> -->
                  <!-- (PT) Coated Before -->
                  <!-- <bps-option bpsValue="Polythermid Anodized After" *ngIf="!systemType || this.systemType=='75'"
                    bpsLabel="{{'configure.polythermid-anodized-before' | translate }}"></bps-option> -->
                  <!-- (PT) Anodized Before -->
                  <!-- <bps-option bpsValue="Polyamide Coated Before" *ngIf="!systemType || this.systemType=='75'"
                    bpsLabel="{{'configure.polyamide-coated-before' | translate }}"></bps-option> -->
                  <!-- (PA) Coated Before -->
                  <!-- <bps-option bpsValue="Polyamide Coated After"
                    *ngIf="!systemType || this.systemType=='75'|| this.systemType=='90'"
                    bpsLabel="{{'configure.polyamide-coated-after' | translate }}"></bps-option> -->
                  <!-- (PA) Coated After -->
                  <!-- <bps-option bpsValue="Polyamide Anodized Before" *ngIf="!systemType || this.systemType=='75'"
                    bpsLabel="{{'configure.polyamide-anodized-before' | translate }}"></bps-option> -->
                  <!-- (PA) Anodized Before -->
                  <!-- <bps-option bpsValue="Polyamide Anodized After"
                    *ngIf="!systemType || this.systemType=='75'|| this.systemType=='90'"
                    bpsLabel="{{'configure.polyamide-anodized-after' | translate }}"></bps-option> -->
                  <!-- (PA) Anodized After -->
                  <bps-select style="width: 222px" formControlName="Material" [(ngModel)]="materialSelection"
                  name="materialSelection" (ngModelChange)="onSelectMaterial($event)"
                  bpsPlaceHolder="{{'configure.select' | translate }}">
                    <bps-option bpsValue="Polyamide" bpsLabel="{{'configure.polyamide' | translate}}"></bps-option>
                    <bps-option *ngIf="umService.isPolythermideEnabled()" bpsValue="Polythermide" bpsLabel="{{'configure.polythermide' | translate}}"></bps-option>
                </bps-select>
              </div>
            </div>
          </bps-form-control>
        </bps-form-item>
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div *ngIf="unified3DModel.ProblemSetting.EnableThermal && unified3DModel.ModelInput.FrameSystem.SystemType === 'AWS 75.SI+'">
              <h3 class="vent-frame-heading">{{'configure.glazing-gasket-combination' | translate }}</h3>
              <!-- Glazing gasket combination -->
              <div style="margin-top: 7px; display: flex; justify-content: space-between; width: 214px;">
                <bps-select style="width: 170px;" formControlName="Glazing" [(ngModel)]="gasketSelection"
                  name="gasketSelection" bpsPlaceHolder="{{'configure.select' | translate }}"
                  (ngModelChange)="onSelectGasket($event)">
                  <!-- Select -->
                  <bps-option *ngFor="let gasket of framingService.gasketSelections" bpsValue="{{gasket.Value}}" bpsLabel="{{'configure.option' | translate }} {{gasket.Option}}"></bps-option>
                </bps-select>
                <div>
                  <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg" bps-popover
                    [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                    [bpsPopoverContent]="contentTemplate_AWS_75_SI">
                </div>
              </div>
            </div>
            <ng-template #contentTemplate_AWS_75_SI>
              <div style="text-align: left; padding-left: 15px;">
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 1</h3>
                  <!-- Option -->
                  <p>{{'configure.glazing-combination-option-1-content' | translate }}.</p>
                  <!-- with glazing rebate insulation, assembly insulation first -->
                </div>
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 2</h3>
                  <p>{{'configure.glazing-combination-option-2-content' | translate }}.</p>
                  <!-- with glazing rebate insulation, assembly glazing first, 288138, 288149 -->
                </div>
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 3</h3>
                  <p>{{'configure.glazing-combination-option-3-content' | translate }}.</p>
                  <!-- with gasket fin -->
                </div>
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 4</h3>
                  <p>{{'configure.glazing-combination-option-4-content' | translate }}.</p>
                  <!-- without glazing rebate insulation -->
                </div>
              </div>
            </ng-template>
            <div *ngIf="unified3DModel.ProblemSetting.EnableThermal && isFrenchSystem()">
              <h3 class="vent-frame-heading">{{'configure.insulation-level' | translate }}</h3>
              <!-- Insulation Level -->
              <div style="margin-top: 7px; display: flex; justify-content: space-between; width: 214px;">
                <bps-select style="width: 170px;" formControlName="InsulationControl" [(ngModel)]="insulationLevelSelection"
                  name="insulationLevelSelection" bpsPlaceHolder="{{'configure.select' | translate }}"
                  (ngModelChange)="onSelectInsulationLevel($event)">
                  <!-- Select -->
                  <bps-option *ngFor="let insulation of framingService.insulationLevels" bpsValue="{{insulation.Value}}" bpsLabel="{{'configure.level' | translate }} {{insulation.Level}}"></bps-option>
                </bps-select>
                <div>
                  <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg" bps-popover
                    [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                    [bpsPopoverContent]="contentTemplate_AWS_75_II">
                </div>
              </div>
            </div>
            <ng-template #contentTemplate_AWS_75_II>
              <div style="text-align: left; padding-left: 15px;">
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 1</h3>
                  <!-- Option -->
                  <p>{{'configure.insulation-level-1-content' | translate }}.</p>
                </div>
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 2</h3>
                  <p>{{'configure.insulation-level-2-content' | translate }}.</p>
                </div>
                <div>
                  <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 3</h3>
                  <p>{{'configure.insulation-level-3-content' | translate }}.</p>
                </div>
                <!-- <div>
                  <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 4</h3>
                  <p>{{'configure.insulation-level-4-content' | translate }}.</p>
                </div> -->
              </div>
            </ng-template>
          </bps-form-control>
        </bps-form-item>
      </form>
      <div class="sps-last-break" style="margin-bottom: 0;"></div>
    </div>

    <!-- FACADE mullion-transom-->
    <div
      *ngIf="unified3DModel && unified3DModel.ProblemSetting && unified3DModel.ProblemSetting.ProductType=='Facade' && unified3DModel.ProblemSetting.FacadeType=='mullion-transom'">
      <!--Image-->
      <div class="sps-accordion-ui-section-content-container">
        <div id="system-cross-section-img-container" class="sps-accordion-ui-section-content">
          <img id="system-cross-section-img" *ngIf="fwsSystemValue[systemSelected] !== undefined"
            [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/'+fwsSystemValue_img[systemSelected]+'.png'"
            alt="aws_picture">
        </div>
      </div>
      <!--System Type-->
      <div id="system-pill-container" class="sps-accordion-ui-section-content"
        style="margin-top: 15px; margin-bottom: 0;">
        <div id="sps-system-names" class="sps-pill-container">
          <div class="sps-pill" *appCheckPermissions="feature.FWS_35_PD" [ngClass]="{'selected': systemSelected === 10, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS35PD')}" (click)="onSelectSystemFacade(10)" style="margin-bottom: 0;">
            <p>FWS 35 PD</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_35_PD_SI" [ngClass]="{'selected': systemSelected === 0, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS35PDSI')}" (click)="onSelectSystemFacade(0)" style="margin-bottom: 0;">
            <p>FWS 35 PD.SI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_35_PD_HI" [ngClass]="{'selected': systemSelected === 1, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS35PDHI')}" (click)="onSelectSystemFacade(1)">
            <p>FWS 35 PD.HI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_50" [ngClass]="{'selected': systemSelected === 2, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS50')}" (click)="onSelectSystemFacade(2)">
            <p>FWS 50</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_50_HI" [ngClass]="{'selected': systemSelected === 3, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS50HI')}" (click)="onSelectSystemFacade(3)">
            <p>FWS 50.HI</p>
          </div>
         <div class="sps-pill" *appCheckPermissions="feature.FWS_50_SI" [ngClass]="{'selected': systemSelected === 4, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS50SI')}" (click)="onSelectSystemFacade(4)">
            <p>FWS 50.SI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_50_SI_Green" [ngClass]="{'selected': systemSelected === 5, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS50SIGREEN')}" (click)="onSelectSystemFacade(5)">
            <p>FWS 50.SI Green</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_60" [ngClass]="{'selected': systemSelected === 6, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS60')}" (click)="onSelectSystemFacade(6)">
            <p>FWS 60</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_60_HI" [ngClass]="{'selected': systemSelected === 7, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS60HI')}" (click)="onSelectSystemFacade(7)">
            <p>FWS 60.HI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_60_SI" [ngClass]="{'selected': systemSelected === 8, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS60SI')}" (click)="onSelectSystemFacade(8)">
            <p>FWS 60.SI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.FWS_60_SI_Green" [ngClass]="{'selected': systemSelected === 9, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('FWS60SIGREEN')}" (click)="onSelectSystemFacade(9)">
            <p>FWS 60.SI Green</p>
          </div>
        </div>
      </div>
      <!--line-->
      <div class="sps-break"></div>
      <!--Insulating Zone-->
      <form bps-form [formGroup]="validateFormForFacade">
        <bps-form-item style="margin: 0px !important;" *ngIf="1 == 2">
          <bps-form-control>
            <div class="insulating-bar-container" *ngIf="unified3DModel.ProblemSetting.EnableThermal">
              <h3 class="vent-frame-heading">{{'configure.left-configure-framing-insulation-zone' | translate}}</h3>
              <!--Insulating Zone-->
              <div style="margin-top: 7px;">
                <bps-select style="width: 170px" formControlName="InsulationZone" [(ngModel)]="insulationZone"
                  name="insulationZone" (ngModelChange)="onSelectInsulationZone($event)"
                  bpsPlaceHolder="{{'configure.select' | translate }}">
                  <!-- Select -->
                  <bps-option bpsValue="FWS 50.SI Green" bpsLabel="FWS 50.SI Green"></bps-option>
                  <!-- (PT) Coated Before -->
                </bps-select>
              </div>
              <div class="sps-break"></div>
            </div>
          </bps-form-control>
        </bps-form-item>
        <!--Mullion Depth-->
        <div id="sps-mullion-transom-input-container-mullion" class="sps-accordion-ui-section-content-container">
          <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isMullionFacadeOpened}">
            {{'configure.left-configure-framing-mullion-depth' | translate}}</h3>
          <!-- Mullion -->
          <div id="sps-mullion-transom-dropdown-container" class="accordion-ui-section-content"
            style="margin: 7px 0px 15px 0px;">
            <div class="sps-operability-vent-frame-button-container">
              <div>
                <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                  disabled bpsDisabled bps-input formControlName="mullionDepthInputValue"
                  [(ngModel)]="mullionDepthInputValue">
              </div>
              <button bps-button bpsType="variation-13" style="margin-left: 22px"
                [ngClass]="{'active': isMullionFacadeOpened}" (click)="onOpenIFramePopout('MullionFacade')">
                <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
              </button>
            </div>
          </div>
        </div>
         <!--Transom Depth-->
         <div class="sps-accordion-ui-section-content-container">
          <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isTransomFacadeOpened}">
            {{'configure.left-configure-framing-transom-depth' | translate}}</h3>
          <!-- Transom -->
          <div class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
            <div class="sps-operability-vent-frame-button-container">
              <div>
                <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                  disabled bpsDisabled bps-input formControlName="transomDepthInputValue"
                  [(ngModel)]="transomDepthInputValue">
              </div>
              <button bps-button bpsType="variation-13" style="margin-left: 22px"
                [ngClass]="{'active': isTransomFacadeOpened}" (click)="onOpenIFramePopout('TransomFacade')">
                <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
              </button>
            </div>
          </div>
        </div>
        <!--line-->
        <div class="sps-break"></div>
        <!--Add Reinforcement-->
        <div class="switch-heading">
          <h3 style="text-transform:uppercase;" [ngStyle]="{'color':switchReinforcement?'#00a2d1':'#ffffff'}"
            [ngClass]="{'reInforcementDisabled': mullionReinforcementTypeHeading === null || reinforcementDisabled  }">
            <!-- {{'configure.left-configure-framing-add-reinforcement' | translate}}</h3> -->
            {{'configure.left-configure-framing-mullion-reinforcement' | translate}} </h3>
            <bps-switch *ngIf="mullionReinforcementTypeHeading != null && !reinforcementDisabled" formControlName="SwitchReinforcementControl" [(ngModel)]="switchReinforcement" (click)="onSwitchMullionReinforcement()">
            </bps-switch>
            <bps-switch *ngIf="mullionReinforcementTypeHeading === null || reinforcementDisabled" formControlName="SwitchReinforcementControl" [(ngModel)]="switchReinforcement"
              bpsDisabled>
          </bps-switch>
        </div>

        <!--Select Mullion Reinforcement-->
        <div *ngIf="switchReinforcement">
                  <div id="sps-mullion-transom-input-container-mullion" class="sps-accordion-ui-section-content-container">
                    <!--line-->
                    <div class="sps-break" style="border-bottom: 1px solid #313131 !important"></div>
                    <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isReinforcementFacadeOpened}">
                      {{'configure.left-configure-framing-left-reinforcement' | translate}}</h3>
                    <div id="sps-mullion-transom-dropdown-container" class="accordion-ui-section-content"
                      style="margin: 7px 0px 15px 0px;">
                      <div class="sps-operability-vent-frame-button-container">
                        <div>
                          <input bps-input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" disabled
                            bpsDisabled [(ngModel)]="mullionReinforcementTypeHeading" formControlName="mullionReinforcementTypeControl"
                            placeholder="{{'configure.select' | translate }}">
                          <!-- <ng-template #selectTag>
                                            <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                                              disabled bpsDisabled bps-input value="{{'configure.select' | translate }}">
                                          </ng-template> -->
                        </div>
                        <button bps-button bpsType="variation-13" style="margin-left: 22px"
                          [ngClass]="{'active': isReinforcementFacadeOpened}" (click)="onOpenIFramePopout('ReinforcementFacade')">
                          <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--line-->
                  <div class="sps-break" style="border-bottom: 1px solid #313131 !important"></div>
                  <div class="apply-btn-container" id="sps-glass-spandrel-apply-btn-container">
                    <button [ngStyle]="language ==='de-DE' ? {'transform': 'translate(74%, 0%)'} : {'margin-left':'60px','width':'80px'}" bps-button
                      bpsType="variation-4" [disabled]="selectedMajorMullionIDs.length == 0"
                      [ngClass]="{'active': !(selectedMajorMullionIDs.length == 0)}"
                      (click)="onApplyReinforcement()">{{'configure.add' | translate }}</button>
                    <!-- Apply -->
                    <span style="margin-top: 4px; float: right;" bpsType="variation-1" bps-popover [bpsPopoverPlacement]="'right'"
                      [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="applyContent9">
                      <img *ngIf="selectedPicker !== -1 else greyImage2" class="iconI"
                        src="/assets/Images/icons/sps_information_icon_blue.svg">
                      <ng-template #greyImage2>
                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_grey.svg">
                      </ng-template>
                    </span>
                    <ng-template #applyContent9>
                      <div class="popover-variation3" style="width: 400px; height: 200px;">
                        <div style="float: left; width: 45%; margin:10px;border-right: #383838 2px solid;">
                          <div class="roundCount"> 1 </div> {{'configure.left-configure-framing-select-mullion-text' |
                          translate}}
                          <br> {{'configure.left-configure-framing-3d-model' | translate}}
                          <br>
                          <img src="/assets/Images/tooltips/APPLY_REINFORCEMENT.png" height="115"
                            style="margin:10px; align-items: center;" />
                        </div>
                        <div style="float: right; width: 45%; margin:10px;" *ngIf="language === 'en-US'">
                          <div class="roundCount"> 2 </div>{{'configure.left-configure-framing-click-text' | translate}} "
                          <a>{{'configure.add' | translate}}</a>"<br>
                          {{'configure.left-configure-framing-button-text' | translate}}
                          <br>
                          <!-- <button bps-button bpsType="variation-4" style="margin: 50px">{{'configure.add' | translate
                            }}</button> -->
                            <img style="margin: 50px; align-items:center;" src="/assets/Images/tooltips/button_add_img.png">
                        </div>
                        <div style="float: right; width: 45%; margin:10px;" *ngIf="language ==='de-DE'">
                          <div class="roundCount"> 2 </div>{{'configure.left-configure-framing-click-text' | translate}} <br>
                          "<a>{{'configure.add' | translate}}</a>"
                          <!-- <button bps-button bpsType="variation-4" style="margin: 50px">{{'configure.add' | translate
                            }}</button> -->
                            <img style="margin: 50px; align-items:center;" src="/assets/Images/tooltips/button_add_img.png">
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <bps-list bpsBordered bpsListType="{{ bpsListTypeVariation }}" [bpsDisabled]="selectedPicker === -1"
                    style="margin-top: 15px;">
                    <bps-list-item *ngFor="let memberID of appliedMullionReinforcement" bpsDelete
                      (ondelete)="onDeleteMullionReinforcement(memberID)">
                      {{'configure.mullion-depth-reinforcement' | translate}}_{{ memberID }} {{'configure.added' | translate | lowercase}}
                    </bps-list-item>
                  </bps-list>
                  <!-- <div class="sps-break"></div> -->
        </div>

        <div class="sps-break"></div>
      <!--Add Transom Reinforcement-->
      <div class="switch-heading">
        <h3 style="text-transform:uppercase;" [ngStyle]="{'color':switchTransomReinforcement?'#00a2d1':'#ffffff'}"
          [ngClass]="{'transomreinforcementDisabled': transomReinforcementTypeHeading === null || transomreinforcementDisabled}">
          <!-- {{'configure.left-configure-framing-add-reinforcement' | translate}}</h3> -->
          {{'configure.left-configure-framing-transom-reinforcement' | translate}}</h3>
          <bps-switch *ngIf="transomReinforcementTypeHeading !== null && !transomreinforcementDisabled"
            formControlName="SwitchTransomReinforcement" [(ngModel)]="switchTransomReinforcement"
            (click)="onSwitchTransomReinforcement()">
          </bps-switch>
          <bps-switch *ngIf="transomReinforcementTypeHeading === null || transomreinforcementDisabled"
            formControlName="SwitchTransomReinforcement" [(ngModel)]="switchTransomReinforcement" bpsDisabled
            (click)="onSwitchTransomReinforcement()">
          </bps-switch>
      </div>

         <!--Select Transom Reinforcement-->
         <div *ngIf="switchTransomReinforcement">
          <div id="sps-mullion-transom-input-container-mullion" class="sps-accordion-ui-section-content-container">
             <!--line-->
        <div class="sps-break" style="border-bottom: 1px solid #313131 !important"></div>
            <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isTransomReinforcementFacadeOpened}">
              {{'configure.left-configure-framing-left-reinforcement' | translate}}</h3>
            <div id="sps-mullion-transom-dropdown-container" class="accordion-ui-section-content"
              style="margin: 7px 0px 15px 0px;">
              <div class="sps-operability-vent-frame-button-container">
                <div>
                  <input bps-input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;"
                    disabled bpsDisabled [(ngModel)]="transomReinforcementTypeHeading"
                    formControlName="transomReinforcementTypeControl" placeholder="{{'configure.select' | translate }}">
                  <!-- <ng-template #selectTag>
                    <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                      disabled bpsDisabled bps-input value="{{'configure.select' | translate }}">
                  </ng-template> -->
                </div>
                <button bps-button bpsType="variation-13" style="margin-left: 22px"
                  [ngClass]="{'active': isTransomReinforcementFacadeOpened}" (click)="onOpenIFramePopout('TransomReinforcementFacade')">
                  <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                </button>
              </div>
            </div>
          </div>
          <!--line-->
          <div class="sps-break" style="border-bottom: 1px solid #313131 !important"></div>
          <div class="apply-btn-container" id="sps-glass-spandrel-apply-btn-container">
            <button [ngStyle]="language ==='de-DE' ? {'transform': 'translate(74%, 0%)'} : {'margin-left':'60px','width':'80px'}"
              bps-button bpsType="variation-4" [disabled]="selectedMajorTransomIDs.length == 0"
              [ngClass]="{'active': !(selectedMajorTransomIDs.length == 0)}"
              (click)="onApplyTransomReinforcement()">{{'configure.add' | translate }}</button>
            <!-- Apply -->
            <span style="margin-top: 4px; float: right;" bpsType="variation-1" bps-popover
              [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="applyContent9">
              <img *ngIf="selectedPicker !== -1 else greyImage2" class="iconI"
                src="/assets/Images/icons/sps_information_icon_blue.svg">
              <ng-template #greyImage2>
                <img class="iconI" src="/assets/Images/icons/sps_information_icon_grey.svg">
              </ng-template>
            </span>
            <ng-template #applyContent9>
              <div class="popover-variation3" style="width: 400px; height: 200px;">
                <div style="float: left; width: 45%; margin:10px;border-right: #383838 2px solid;">
                  <div class="roundCount"> 1 </div> {{'configure.left-configure-framing-select-transom-text' | translate}}
                  <br> {{'configure.left-configure-framing-3d-model' | translate}}
                  <br>
                  <img src="/assets/Images/tooltips/APPLY_TRANSOMREINFORCEMENT.png" height="115"
                    style="margin:10px; align-items: center;" />
                </div>
                <div style="float: right; width: 45%; margin:10px;" *ngIf="language === 'en-US'">
                  <div class="roundCount"> 2 </div>{{'configure.left-configure-framing-click-text' | translate}} "
                  <a>{{'configure.add' | translate}}</a>"<br>
                  {{'configure.left-configure-framing-button-text' | translate}}
                  <br>
                  <!-- <button bps-button bpsType="variation-4" style="margin: 50px">{{'configure.add' | translate
                    }}</button> -->
                    <img style="margin: 50px; align-items:center;" src="/assets/Images/tooltips/button_add_img.png">
                </div>
                <div style="float: right; width: 45%; margin:10px;" *ngIf="language ==='de-DE'">
                  <div class="roundCount"> 2 </div>{{'configure.left-configure-framing-click-text' | translate}} <br>
                  "<a>{{'configure.add' | translate}}</a>"
                  <!-- <button bps-button bpsType="variation-4" style="margin: 50px">{{'configure.add' | translate
                    }}</button> -->
                    <img style="margin: 50px; align-items:center;" src="/assets/Images/tooltips/button_add_img.png">
                </div>
              </div>
            </ng-template>
          </div>
          <bps-list bpsBordered bpsListType="{{ bpsListTypeVariation }}" [bpsDisabled]="selectedPicker === -1"
            style="margin-top: 15px;">
            <bps-list-item *ngFor="let memberID of appliedTransomReinforcement" bpsDelete
              (ondelete)="onDeleteTransomReinforcement(memberID)">
              {{'configure.mullion-depth-reinforcement' | translate}}_{{ memberID }} {{'configure.added' | translate | lowercase}}
              <!-- glass | added -->
            </bps-list-item>
          </bps-list>
          <!-- <div class="sps-break"></div> -->
        </div>
       
        <!-- Intermediate Mullion Depth -->
        <div class="sps-accordion-ui-section-content-container" *ngIf="showIntermediateMullionDepth">
          <div class="sps-break"></div>
          <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isIntermediateMullionFacadeOpened}">
            {{'configure.left-configure-framing-intermediate-mullion-depth' | translate}}</h3>
          <!-- Intermediate Mullion -->
          <div class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
            <div class="sps-operability-vent-frame-button-container">
              <div>
                <input *ngIf="intermediateMullionDepthInputValue === undefined"
                  style="width: 170px; color: #ffffff !important; border-color: #e94c0a !important;" centered disabled
                  bpsDisabled bps-input formControlName="intermediateMullionDepthInputValueControl"
                  [(ngModel)]="intermediateMullionDepthInputValue">
                <input *ngIf="intermediateMullionDepthInputValue !== undefined"
                  style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered disabled
                  bpsDisabled bps-input formControlName="intermediateMullionDepthInputValueControl"
                  [(ngModel)]="intermediateMullionDepthInputValue">
                <!-- <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                  disabled bpsDisabled bps-input formControlName="intermediateMullionDepthInputValueControl"
                  [(ngModel)]="intermediateMullionDepthInputValue"> -->
              </div>
              <button bps-button bpsType="variation-13" style="margin-left: 22px"
                [ngClass]="{'active': isIntermediateMullionFacadeOpened}"
                (click)="onOpenIFramePopout('IntermediateMullionFacade')">
                <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
              </button>
            </div>
          </div>
        </div>
        <!--line-->
        <div class="sps-last-break"></div>
      </form>
    </div>

    <!-- FACADE unitized-->
    <div
      *ngIf="unified3DModel && unified3DModel.ProblemSetting && unified3DModel.ProblemSetting.ProductType=='Facade' && unified3DModel.ProblemSetting.FacadeType=='UDC'">
      <!--Image-->
      <div class="sps-accordion-ui-section-content-container">
        <div id="system-cross-section-img-container" class="sps-accordion-ui-section-content">
          <img id="system-cross-section-img" *ngIf="udcSystemValue[systemSelected] !== undefined"
            [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/'+udcSystemValue_temp[systemSelected]+'.png'"
            alt="aws_picture">
        </div>
      </div>
      <!--System Type-->
      <div id="system-pill-container" class="sps-accordion-ui-section-content"
        style="margin-top: 15px; margin-bottom: 0;">
        <div id="sps-system-names" class="sps-pill-container">
          <div class="sps-pill" style="margin-bottom: 0;" *appCheckPermissions="feature.UDC_80" [ngClass]="{'selected': systemSelected === 0, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('UDC80')}"
            (click)="onSelectSystemFacadeUDC(0)">
            <p>UDC 80</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.UDC_80_HI" [ngClass]="{'selected': systemSelected === 1, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('UDC80HI')}" (click)="onSelectSystemFacadeUDC(1)">
            <p>UDC 80.HI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.UDC_80_SI" [ngClass]="{'selected': systemSelected === 2, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('UDC80SI')}" (click)="onSelectSystemFacadeUDC(2)">
            <p>UDC 80.SI</p>
          </div>
          <div class="sps-pill" *appCheckPermissions="feature.UDC_80_SI_with_XPS_Filling" [ngClass]="{'selected': systemSelected === 3, 'hidden': !stService.arrayOfSelectedSystemsDescription.includes('UDC80SIWITHXPSFILLING')}" (click)="onSelectSystemFacadeUDC(3)">
            <p>UDC 80.SI with XPS Filling</p>
          </div>
        </div>
      </div>
      <!--line-->
      <div class="sps-break"></div>
      <form bps-form [formGroup]="validateFormForFacadeUDC">
        <!--Framing-->
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div class="sps-accordion-ui-section-content-container" style="margin: 0px !important;">
              <h3 class="mullion-frame-heading" [ngClass]="{'blue-heading': isUDCFramingOpened}">
                {{'configure.framing' | translate}}</h3>
              <!-- Framing -->
              <div class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
                <div class="sps-operability-vent-frame-button-container">
                  <div>
                    <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                      disabled bpsDisabled bps-input formControlName="UDCFramingInputValueControl"
                      [(ngModel)]="UDCFramingInputValue">
                  </div>
                  <button bps-button bpsType="variation-13" style="margin-left: 22px"
                    [ngClass]="{'active': isUDCFramingOpened}" (click)="onOpenIFramePopout('UDCFraming')">
                    <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                  </button>
                </div>
              </div>
            </div>
          </bps-form-control>
        </bps-form-item>

        <!--Bottom Framing-->
        <bps-form-item style="margin: 0px !important;" *ngIf="UDCBottomFramingInputValue != 'Art. 505220 - 180 mm' && UDCBottomFramingInputValue !== '' && horizontalJointWidth === '20'">
          <bps-form-control>
            <div class="sps-accordion-ui-section-content-container"
            >
            <h3 class="mullion-frame-heading" style="text-transform: uppercase;"
              [ngClass]="{'blue-heading': isUDCBottomFramingOpened}">
              {{'configure.bottom-framing' | translate}}</h3>
            <!-- [TRANS_TODO] Bottom Framing-->
            <div class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
              <div class="sps-operability-vent-frame-button-container">
                <div>
                  <input style="width: 170px; color: #666666 !important; border-color: #666666 !important;" centered
                    disabled bpsDisabled bps-input formControlName="UDCBottomFramingInputValue"
                    [(ngModel)]="UDCBottomFramingInputValue">
                </div>
                <button bps-button bpsType="variation-13" style="margin-left: 22px" disabled bpsDisabled
                  [ngClass]="{'active': isUDCBottomFramingOpened}" (click)="onOpenIFramePopout('UDCBottomFraming')">
                  <img alt="" style="vertical-align: unset; " src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg">
                </button>
              </div>
            </div>
          </div>
          </bps-form-control>
        </bps-form-item>

        <!-- Intermediate Mullion Depth  *ngIf="showIntermediateMullionDepth"-->
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div class="sps-accordion-ui-section-content-container" *ngIf="isMullionExistUDC()">
              <h3 class="mullion-frame-heading" style="text-transform: uppercase;"
                [ngClass]="{'blue-heading': isIntermediateMullionFacadeOpened}">
                {{'configure.vertical-glazing-bar' | translate}}</h3>
              <!-- [TRANS_TODO] VERTICAL GLAZING BAR-->
              <div class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
                <div class="sps-operability-vent-frame-button-container">
                  <div>
                    <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                      disabled bpsDisabled bps-input formControlName="intermediateMullionDepthInputValueControl"
                      [(ngModel)]="intermediateMullionDepthInputValue">
                  </div>
                  <button bps-button bpsType="variation-13" style="margin-left: 22px"
                    [ngClass]="{'active': isIntermediateMullionFacadeOpened}"
                    (click)="onOpenIFramePopout('IntermediateMullionFacade')">
                    <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                  </button>
                </div>
              </div>
            </div>
          </bps-form-control>
        </bps-form-item>


        <!--Intermediate Transom Depth  *ngIf="showIntermediateTransomDepth"-->
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div class="sps-accordion-ui-section-content-container" *ngIf="isTransomExistUDC()">
              <h3 class="mullion-frame-heading" style="text-transform: uppercase;"
                [ngClass]="{'blue-heading': isIntermediateTransomFacadeOpened}">
                {{'configure.horizontal-glazing-bar' | translate}}</h3>
              <!-- [TRANS_TODO] HORIZONTAL GLAZING BAR-->
              <div class="accordion-ui-section-content" style="margin: 7px 0px 0px 0px;">
                <div class="sps-operability-vent-frame-button-container">
                  <div>
                    <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;" centered
                      disabled bpsDisabled bps-input formControlName="intermediateTransomDepthInputValue"
                      [(ngModel)]="intermediateTransomDepthInputValue">
                  </div>
                  <button bps-button bpsType="variation-13" style="margin-left: 22px"
                    [ngClass]="{'active': isIntermediateTransomFacadeOpened}"
                    (click)="onOpenIFramePopout('IntermediateTransomFacade')">
                    <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                  </button>
                </div>
              </div>
            </div>
          </bps-form-control>
        </bps-form-item>


        <div class="sps-break"></div>
        <!-- Vertical Joint -->
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div class="sps-accordion-ui-section-content-container" style="margin: 0px !important;">
              <h3 class="mullion-frame-heading" style="text-transform: uppercase;">
                {{'configure.Vertical-Joint' | translate}}</h3>
              <!-- [TRANS_TODO] VERTICAL JOINT-->
              <div class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
                <div class="sps-operability-vent-frame-button-container">
                  <div>
                    <bps-select formControlName="verticalJointWidth" style="width: 100px" [(ngModel)]="verticalJointWidth"
                      name="verticalJointWidth" (ngModelChange)="onVerticalJointChange($event)">
                      <bps-option bpsValue='10' bpsLabel="10 mm"></bps-option>
                      <bps-option bpsValue='15' bpsLabel="15 mm"></bps-option>
                    </bps-select>
                  </div>
                </div>
              </div>
            </div>
          </bps-form-control>
        </bps-form-item>


        <!-- Horizontal Joint -->
        <bps-form-item style="margin: 0px !important;">
          <bps-form-control>
            <div class="sps-accordion-ui-section-content-container">
              <h3 class="mullion-frame-heading" style="text-transform: uppercase;">
                {{'configure.Horizontal-Joint' | translate}} </h3>
              <!-- [TRANS_TODO] HORIZONTAL JOINT-->
              <div class="accordion-ui-section-content" style="margin: 7px 0px 0px 0px;">
                <div class="sps-operability-vent-frame-button-container">
                  <div>
                    <bps-select formControlName="horizontalJointWidthControl" style="width: 100px"
                      [(ngModel)]="horizontalJointWidth" name="horizontalJointWidth"
                      (ngModelChange)="onHorizontalJointChange($event)">
                      <bps-option bpsValue='10' bpsLabel="10 mm"></bps-option>
                      <bps-option bpsValue='15' bpsLabel="15 mm"></bps-option>
                      <bps-option *ngIf="UDCBottomFramingInputValue != 'Art. 505220 - 180 mm'" bpsValue='20' bpsLabel="20 mm"></bps-option>
                      <bps-option *ngIf="UDCBottomFramingInputValue == 'Art. 505220 - 180 mm'" bpsValue='20' bpsLabel="20 mm" bpsDisabled></bps-option>
                    </bps-select>
                  </div>
                </div>
              </div>
            </div>
          </bps-form-control>
        </bps-form-item>


        <!--line-->
        <div class="sps-last-break"></div>
      </form>
    </div>


    <!-- SLIDING DOOR -->
    <div class="column-container"
      *ngIf="unified3DModel && unified3DModel.ProblemSetting && unified3DModel.ProblemSetting.ProductType=='SlidingDoor'">
      <div class="sps-accordion-ui-section-content-container">
        <div id="system-cross-section-img-container" class="sps-accordion-ui-section-content">
          <img id="system-cross-section-img" *ngIf="aseSystemValue[systemSelected] !== undefined"
            [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/'+aseSystemValue[systemSelected]+'.jpg'" alt="aws_picture">
        </div>
      </div>
      <div id="system-pill-container" class="sps-accordion-ui-section-content" style="margin-bottom: 0;">        
        <div id="sps-system-names" class="sps-pill-container">
          <div class="sps-pill" [ngClass]="{'selected': systemSelected === 0, 'deselected': orderPlaced==true}"
            (click)="onSelectSystemSliding(0)" style="width: 60px !important; height: 22px !important; flex-grow: 0;" *appCheckPermissions="feature.ASE_60">
            <p>ASE 60</p>
          </div>
          <div class="sps-pill" [ngClass]="{'selected': systemSelected === 1}"
            (click)="onSelectSystemSliding(1)" style="width: 70px !important; height: 22px !important; flex-grow: 0;" *appCheckPermissions="feature.ASE_80_HI">
            <p>ASE 80.HI</p>
          </div>
        </div>
      </div>
      <div class="sps-break"></div>
      <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container" style="margin-top: 0;">
        <h3 class="outer-frame-heading" [ngClass]="{'blue-heading': isOuterOpened, 'disabled': orderPlaced===true}">
          {{'configure.outer-frame' | translate }}</h3>
        <!-- Outer Frame -->
        <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="outerFrameInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px"
              [ngClass]="{'active': isOuterOpened}"
              (click)="onOpenIFramePopout('OuterFrame')" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_white.svg" *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;width:inherit; height: inherit;"
                src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg" *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>


      <div id="sps-operability-bottom-outer-frame-container" class="sps-operability-vent-frame-container"
        style="margin-top: 22px;" *ngIf="isBottomOuterFrameDisplay">
        <h3 id="operablility-bottom-outer-frame-heading-width" class="vent-frame-heading"
          [ngClass]="{'blue-heading': isBottomOuterOpened, 'disabled': orderPlaced===true}">bottom outer frame</h3>
        <!-- Bottom Outer Frame -->
        <div id="sps-operablility-bottom-outer-frame-dropdown-container" class="accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="bottomOuterFrameInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('BottomOuterFrameSliding')"
              [ngClass]="{'active': isBottomOuterOpened}" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>
      <div class="sps-break" style="margin-bottom: 0px"></div>
      <div id="sps-operability-vent-frame-container" class="sps-operability-vent-frame-container"
        style="margin-top: 22px;">
        <h3 id="operablility-vent-frame-heading-width" class="vent-frame-heading"
          [ngClass]="{'blue-heading': isVentOpened, 'disabled': orderPlaced===true}">{{'configure.vent-frame' |
          translate }}
          <span style="height:16px; float: right;" bpsType="variation-1" bps-popover
          [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
          [bpsPopoverContent]="windowZoneContent1">
            <img *ngIf="selectedPicker !== -1 && !orderPlaced else greyImage2" class="iconI"
                src="/assets/Images/icons/sps_information_icon_blue.svg">
            <ng-template #greyImage2>
                <img class="iconI" src="/assets/Images/icons/sps_information_icon_grey.svg">
            </ng-template>
          </span>
          <ng-template #windowZoneContent1>
            <div class="popover-variation3" style="width: 205px;">
              Vent frame and Interlock Profile have the same profile list, selecting a different profile on Vent Frame will also affect the Interlock Profile selection.
            </div>
          </ng-template>
        </h3>
        <!-- Vent Frame -->
        <div id="sps-operablility-vent-frame-dropdown-container" class="accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="ventFrameInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('VentFrame')"
              [ngClass]="{'active': isVentOpened}" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>

      <div id="sps-operability-interlock-profile-container" class="sps-operability-vent-frame-container"
        style="margin-top: 22px;">
        <h3 id="operablility-interlock-profile-heading-width" class="vent-frame-heading"
          [ngClass]="{'blue-heading': isInterlockOpened, 'disabled': orderPlaced===true}">INTERLOCK PROFILE</h3>
        <!-- Interlock Profile -->
        <div id="sps-operablility-interlock-profile-dropdown-container" class="accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px; border-color: #666666 !important;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': false}"
                centered disabled bps-input [(ngModel)]="interlockInputValue">  <!--orderPlaced===false for ipactive, add bpsDisabled attribute and remove the border-color style-->
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('InterlockSliding')"
              [ngClass]="{'active': isInterlockOpened}" [disabled]="true">
              <!-- <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                *ngIf="orderPlaced"> -->
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg">
            </button>
          </div>
        </div>
      </div>
      <div class="sps-break"></div>

      <div *ngIf="unified3DModel.ModelInput.Geometry.OperabilitySystems">
        <div id="sps-operability-double-vent-profile-container" style="margin-bottom: 22px;" class="sps-operability-vent-frame-container" *ngIf="unified3DModel.ModelInput.Geometry.OperabilitySystems.length > 0 && unified3DModel.ModelInput.Geometry.OperabilitySystems[0].VentOperableType.includes('2D') || unified3DModel.ModelInput.Geometry.OperabilitySystems[0].VentOperableType.includes('3F')">
      
      <h3 id="operablility-double-vent-profile-heading-width" class="vent-frame-heading"
        [ngClass]="{'blue-heading': isDoubleVentOpened, 'disabled': orderPlaced===true}">Double Vent Profile</h3>
      <!-- Double Vent Profile -->
      <div id="sps-operablility-double-vent-profile-dropdown-container" class="accordion-ui-section-content"
        style="margin: 7px 0px 0px 0px;">
        <div class="sps-operability-vent-frame-button-container">
          <div>
            <input style="width: 170px;"
              [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
              centered disabled bpsDisabled bps-input [(ngModel)]="doubleVentInputValue">
          </div>
          <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('DoubleVentSliding')"
            [ngClass]="{'active': isDoubleVentOpened}" [disabled]="orderPlaced">
            <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
              *ngIf="!orderPlaced">
            <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
              *ngIf="orderPlaced">
          </button>
        </div>
      </div>
    </div>
    </div>
    <div id="sps-operability-strucutral-profile-container" class="sps-operability-vent-frame-container">
    <h3 id="operablility-strucutral-profile-heading-width" class="vent-frame-heading"
      [ngClass]="{'blue-heading': isStructuralOpened, 'disabled': orderPlaced===true}">Structural Profile</h3>
    <!-- Structural Profile -->
    <div id="sps-operablility-strucutral-profile-dropdown-container" class="accordion-ui-section-content"
      style="margin: 7px 0px 0px 0px;">
      <div class="sps-operability-vent-frame-button-container">
        <div>
          <input style="width: 170px;"
            [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
            centered disabled bpsDisabled bps-input [(ngModel)]="structuralProfileInputValue">
        </div>
        <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('StrucutralProfileSliding')"
          [ngClass]="{'active': isStructuralOpened}" [disabled]="orderPlaced">
          <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
            *ngIf="!orderPlaced">
          <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
            *ngIf="orderPlaced">
        </button>
      </div>
    </div>
  </div>



      <div class="sps-break"></div>
      <div class="reinforcement-container">
        <h3 class="vent-frame-heading" [ngClass]="{'disabled': orderPlaced===true}">Add Reinforcement</h3>
        <bps-switch [(ngModel)]="addReinforcementBool" (ngModelChange)="onSwitchReinforcementBool()" [bpsDisabled]="orderPlaced===true"></bps-switch>
      </div>
      <div id="sps-operability-reinforcement-profile-container" class="sps-operability-vent-frame-container"
        style="margin-top: 22px;" *ngIf="addReinforcementBool">
        <h3 id="operablility-reinforcement-profile-heading-width" class="vent-frame-heading"
          [ngClass]="{'blue-heading': isReinforcementOpened, 'disabled': orderPlaced===true}">Reinforcement PROFILE</h3>
        <!-- Reinforcement Profile -->
        <div id="sps-operablility-reinforcement-profile-dropdown-container" class="accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="reinforcementInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('ReinforcementSliding')"
              [ngClass]="{'active': isReinforcementOpened}" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>
      <div class="sps-break"></div>
      <div id="sps-operability-profile-color-container" class="sps-operability-vent-frame-container">
        <!-- <h3 id="operablility-vent-frame-heading-width" class="vent-frame-heading"
          [ngClass]="{'blue-heading': isProfileColorSlidingOpened, 'disabled': orderPlaced===true}">{{'configure.vent-frame' |
          translate }}</h3> -->
        <h3 id="operablility-profile-color-heading-width" class="vent-frame-heading"
          [ngClass]="{'blue-heading': isProfileColorSlidingOpened, 'disabled': orderPlaced===true}">{{'configure.profile-color' |
          translate }}</h3>
        <!-- Profile Color -->
        <div id="sps-operablility-profile-color-dropdown-container" class="accordion-ui-section-content"
          style="margin: 7px 0px 0px 0px;">
          <div class="sps-operability-vent-frame-button-container">
            <div>
              <input style="width: 170px;"
                [ngClass]="{'disabled': orderPlaced===true, 'deactive': orderPlaced===true, 'ipactive': orderPlaced===false}"
                centered disabled bpsDisabled bps-input [(ngModel)]="profileColorInputValue">
            </div>
            <button bps-button bpsType="variation-13" style="margin-left: 22px" (click)="onOpenIFramePopout('ProfileColor')"
              [ngClass]="{'active': isProfileColorSlidingOpened}" [disabled]="orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg"
                *ngIf="!orderPlaced">
              <img alt="" style="vertical-align: unset;" src="/assets/Images/sps_round_icon_rightpanelopened_gray.svg"
                *ngIf="orderPlaced">
            </button>
          </div>
        </div>
      </div>
      <div class="sps-break" style="margin-bottom: 0px;"></div>

    </div>

  </div>
</div>

<app-notification-custom #notifCustomTemplate></app-notification-custom>