<nz-header>
    <div id="nav-left">
        <div id="logo-container" (click)="onNavigateToHome()">
            <img alt="" src="/assets/Images/sps/sps_spsolver_white_logo.svg" *appCheckPermissions="feature.BPSLogo">
            <img alt="" src="/assets/Images/sps_logo_icon_SRS_white.png" *appCheckPermissions="feature.SRSLogo">
        </div>
        <!-- <div (click)="logOut()">
            <img alt="" src="/assets/Images/logout-200px.png" width="20">
        </div> -->
        <div *ngIf="isNavBarButtonAndTitleVisible" (click)="onNavigateToConfigure()" class="nav-button nav-button-blue-hover"
        [ngClass]="{'active':navLayoutService.isConfigureClicked}">
        <div class="nav-button-text">
            <a>{{'nav-layout.configure' | translate | uppercase}}</a>
            <!-- CONFIGURE -->
        </div>
    </div>
</div>
<div id="nav-right">
    <ng-container *appCheckPermissions="feature.Result && feature.Report">
        <div id="nav-right-a" *ngIf="isNavBarButtonAndTitleVisible && isComputeClicked else disabledButtons">
            <div *appCheckPermissions="feature.Result" class="nav-button nav-button-blue-hover" (click)="onNavigateToResult()" [ngClass]="{'active':navLayoutService.isResultClicked}">
                <div class="nav-button-text">
                        <a>{{'nav-layout.result' | translate | uppercase}}</a>
                        <!-- RESULT -->
                    </div>
                </div>
                <div *appCheckPermissions="feature.Report" class="nav-button nav-button-blue-hover" (click)="onNavigateToReport()" [ngClass]="{'active':navLayoutService.isReportClicked}">
                    <div class="nav-button-text">
                        <a>{{'nav-layout.report' | translate | uppercase}}</a>
                        <!-- REPORT -->
                    </div>
                </div>
                <div *appCheckPermissions="feature.FramingSystem" class="nav-button system-table-button" (click)="navLayoutService.isConfigureClicked ? onOpenCloseSystemTable():null" [ngClass]="{'activeSystemTable': isSystemTableOpened, 'enabled': navLayoutService.isConfigureClicked}">
                    <div class="nav-button-text">
                        <img [attr.src]="!navLayoutService.isConfigureClicked ? '/assets/Images/icons/sps_tab_icon_system_disabled.svg':'/assets/Images/icons/sps_tab_icon_system_white.svg'" alt="system icon">
                    </div>
                </div>        
            </div>
        </ng-container>
        <ng-container *appCheckPermissions="feature.MyOrder">
            <div id="nav-right-a" *ngIf="isNavBarButtonAndTitleVisible else disabledButtons">
                <div class="nav-button nav-button-blue-hover" (click)="onNavigateToOrders()" [ngClass]="{'active': MyOrderSelected}">
                    <div class="nav-button-text">
                        <a>MY ORDERS</a>
                        <!-- TODO: Translate - my order -->
                    </div>
                </div>
                <!-- <img src="/assets/Images/sps/srs/sps_icon_lock.svg" alt="" /> -->
            </div>
        </ng-container>
        <ng-template #disabledButtons>
            <div id="nav-right-a">
                <div class="nav-button-disabled">
                    <div class="nav-button-text disabled" *ngIf="isNavBarButtonAndTitleVisible">
                        <p>{{'nav-layout.result' | translate | uppercase}}</p>
                        <!-- RESULT -->
                    </div>
                </div>
                <div class="nav-button-disabled">
                    <div class="nav-button-text disabled" *ngIf="isNavBarButtonAndTitleVisible">
                        <p>{{'nav-layout.report' | translate | uppercase}}</p>
                        <!-- REPORT -->
                    </div>
                </div>
                <div *appCheckPermissions="feature.FramingSystem" class="nav-button system-table-button" (click)="navLayoutService.isConfigureClicked ? onOpenCloseSystemTable():null" [ngClass]="{'activeSystemTable':isSystemTableOpened, 'enabled': navLayoutService.isConfigureClicked}">
                    <div *ngIf="isNavBarButtonAndTitleVisible" class="nav-button-text">
                        <img [attr.src]="!navLayoutService.isConfigureClicked ? '/assets/Images/icons/sps_tab_icon_system_disabled.svg':'/assets/Images/icons/sps_tab_icon_system_white.svg'" alt="system icon">                      
                    </div>
                </div>        
            </div>
        </ng-template>
        <div id="nav-right-b">
            <div>
                <ng-container>
                    <p *ngIf="isNavBarButtonAndTitleVisible && configureService.problemSetting">
                        <span id="project-name">{{ configureService.problemSetting.ProjectName }}</span> - 
                        <span id="project-location">{{ configureService.problemSetting.Location }}</span>
                    </p>
                </ng-container>
                <!-- <ng-container *ngIf="applicationType === 'SRS'">
                    <p *ngIf="isNavBarButtonAndTitleVisible">
                        <span id="project-name">{{ configureService.projectName }}</span>,
                        <span id="project-location">{{ configureService.projectLocation }}</span>
                    </p>
                </ng-container> -->
            </div>
        </div>
        <div id="nav-right-c">
            <!-- added image for feedback  -->
            <div id="bps-download-button" class="bps-download-button" (click)="onDownloadButtonClick()" (contextmenu)="copyToClipboard()"
                *ngIf="showDownload && navLayoutService.isConfigureClicked"></div>
                
            <img alt="" src="/assets/Images/sps/sps_square_icon_addnotes_white.svg" bps-tooltip *ngIf="applicationType==='BPS'"
            [bpsTooltipType]="'variation_11'" bpsTooltipTitle="{{'nav-layout.feedback' | translate }}" 
            bpsTooltipPlacement="bottom" style="cursor: pointer; visibility: visible; margin-right: 19px;"  class="bps-save-button" (click)="onOpenFeedback()"/>
            

            <!-- <div id="bps-save-button" class="bps-save-button"
                [ngStyle]="{'background-image':showBlueSaveButton?'url(/assets/Images/sps/sps_square_icon_save_blue.svg)':'url(/assets/Images/sps/sps_square_icon_save_white.svg)'}"
                (click)="onSaveButtonClick()" style="cursor: pointer; visibility: visible;" *ngIf="isConfigureClicked">
            </div> -->
            <img id="bps-save-button" alt="" [src]='navLayoutService.showBlueSaveButton? "/assets/Images/sps/sps_square_icon_save_blue.svg":"/assets/Images/sps/sps_square_icon_save_white.svg"' bps-tooltip *ngIf="navLayoutService.isConfigureClicked"
            [bpsTooltipType]="'variation_11'" bpsTooltipTitle="{{'nav-layout.save-project' | translate }}" 
            bpsTooltipPlacement="bottom" style="cursor: pointer; visibility: visible; margin-right: 19px;"  class="bps-save-button" (click)="onSaveButtonClick()"/>
            
            <div class=" user-name welcome">
                {{'nav-layout.welcome-back' | translate }}<span> {{ userFullName }}</span>
                <!-- Welcome back -->
            </div>
            <button bps-button bpsType="variation-16" class="login-arrow-down-container" bps-dropdown
            [bpsDropdownMenu]="menu2" bpsTrigger="click" [bpsPlacement]="'bottomRight'">
            <img alt="" class="intro-page-heading-spacing-1" src="/assets/Images/sps/sps-arrowdown-icon-white.svg">
        </button>
        <bps-dropdown-menu #menu2="bpsDropdownMenu">
            <ul nz-menu>
                <div class="dropdown-title">{{'nav-layout.account' | translate }}</div>
                <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled>
                    <i nz-icon nzType="user" nzTheme="outline" style="padding-left: 12.13px; margin-right:27px; color: #999;"></i>
                    <span>{{'nav-layout.profile-page' | translate }}</span>
                </li>
                <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled><span class="first-span-settings login-settings"></span><span>{{'nav-layout.settings' | translate }}</span></li>
                <a href="https://www.schueco.com/com/" target="_blank"><li nz-menu-item class="bps-menu-item bps-menu-item-login"><span class="first-span-schuco login-shuco"></span><span class="login-titles-shuco">Schüco</span></li></a>
                <div class="dropdown-title">{{'nav-layout.language' | translate }}</div>
                <li nz-menu-item class="bps-menu-item bps-menu-item-login" (click)="onChangeLanguage('de-DE')">
                    <span class="fi fi-de languageImg"></span>
                    <span class="login-titles-shuco language-title">Deutsch</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'de-DE'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" (click)="onChangeLanguage('en-US')">
                        <span class="fi fi-us languageImg"></span>
                        <span class="login-titles-shuco language-title">English (US)</span>    
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'en-US'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled>
                        <span class="fi fi-gb languageImg"></span>
                        <span class="login-titles-shuco language-title">English (UK)</span>    
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'en-UK'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled>
                        <span class="fi fi-es languageImg"></span>
                        <span class="login-titles-shuco language-title">Español</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'es-ES'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" (click)="onChangeLanguage('fr-FR')">
                        <span class="fi fi-fr languageImg"></span>
                        <span class="login-titles-shuco language-title">Français</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'fr-FR'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled>
                        <span class="fi fi-it languageImg"></span>
                        <span class="login-titles-shuco language-title">Italiano</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'it-IT'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled>
                        <span class="fi fi-pt languageImg"></span>
                        <span class="login-titles-shuco language-title">Português</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'pt-PT'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled>
                        <span class="fi fi-cn languageImg"></span>
                        <span class="login-titles-shuco language-title">中文</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121" *ngIf="navLayoutService.language == 'cn-CN'">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <!-- PLAN -->
                    <div class="dropdown-title">{{'nav-layout.plan' | translate }}</div>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" (click)="onChangePlan(1)" [nzDisabled]="navLayoutService.plan != 1">
                        <span class="advanced"></span>
                        <span class="login-titles-shuco">{{'nav-layout.advanced' | translate }}</span>
                        <svg *ngIf="navLayoutService.plan == 1" xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" (click)="onChangePlan(2)" [nzDisabled]="navLayoutService.plan != 2">
                        <span class="professional"></span>
                        <span class="login-titles-shuco">{{'nav-layout.professional' | translate }}</span>
                        <svg *ngIf="navLayoutService.plan == 2" xmlns="http://www.w3.org/2000/svg" width="16.414" height="12.121" viewBox="0 0 16.414 12.121">
                            <g id="prefix__sps_done_icon_blue" transform="translate(-1494.293 -103.293)">
                                <path id="prefix__Path_1273" d="M-7453-21963l5 5 10-10" data-name="Path 1273" transform="translate(8948 22072)" style="fill:none;stroke:#00a2d1;stroke-width:2px" />
                            </g>
                        </svg>
                    </li>
                    <!-- LOG OUT -->
                    <li nz-menu-item (click)="logOut()" class="bps-menu-item bps-menu-item-logout bps-menu-item-login"><span class="first-span-logout login-logout"></span><span>{{'nav-layout.log-out' | translate }}</span></li>
                </ul>
            </bps-dropdown-menu>

            <!-- <a *ngIf="1 == 2" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="login-arrow-down-container">
                <img alt="" class="intro-page-heading-spacing-1" src="/assets/Images/sps/sps_arrowdown_icon_expand_darkgrey.svg">
            </a> -->
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu class="login-menu" role="menu">
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.settings' | translate }}</li>
                    <!-- Settings -->
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>Schüco</li>
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.help-and-support' | translate }}</li>
                    <!-- Help & Support -->
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.contact-us' | translate }}</li>
                    <!-- Contact Us --> <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.legal' | translate }}</li>
                    <!-- Legal -->
                    <li nz-menu-item (click)="logOut()">{{'nav-layout.log-out' | translate }}</li>
                    <!-- Log out -->
                </ul>
            </nz-dropdown-menu>
        </div>
    </div>
</nz-header>

<button bps-button bpsType="variation-18" *ngIf="showCrossButton && previousRoute" (click)="onComePreviousPage()"
    class="cross-button" bps-tooltip
    [bpsTooltipType]="'variation_10'"
    bpsTooltipTitle="{{'home.configure-tab' | translate }}"
    bpsTooltipPlacement="top">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.392" height="14.054" viewBox="0 0 15.392 14.054">
        <defs>
            <style>
                .prefix__cls-1 {
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-width: 2px
                }
            </style>
        </defs>
        <g id="prefix__sps_round_icon_back_white" transform="translate(-2533.021 -328.325)">
            <g id="prefix__sps_round_icon_rightpanelopened_white" transform="translate(1349.387 261.965)">
                <path id="prefix__Path_287" d="M1878.866 408.4l-5.613-5.613 5.613-5.613" class="prefix__cls-1"
                    data-name="Path 287" transform="rotate(180 1535.433 238.09)" />
            </g>
            <path id="prefix__Path_1295" d="M-9320.857-21989.648H-9333" class="prefix__cls-1" data-name="Path 1295"
                transform="translate(11867.02 22325)" />
        </g>
    </svg>
</button>

