<div class="main-container">
    <div class="transport-distance-container">
        <h3 class="header">{{'configure.sustainability.transport-distance' | translate}}</h3>
        <div class="transportation-information-container">
            <div class="transportation-info-row">
                <span class="transportation-info-title">{{'configure.sustainability.lorry-12' | translate}}:</span>
                <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability?.Transport?.Lorry12tDistance || '--'}} km</span>
            </div>
            <div class="transportation-info-row">
                <span class="transportation-info-title">{{'configure.sustainability.lorry-28' | translate}}:</span>
                <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability?.Transport?.Lorry28tDistance || '--'}} km</span>
            </div>
            <div class="transportation-info-row">
                <span class="transportation-info-title">{{'configure.sustainability.lorry-40' | translate}}:</span>
                <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability?.Transport?.Lorry40tDistance || '--'}} km</span>
            </div>
            <div class="transportation-info-row">
                <span class="transportation-info-title">{{'configure.sustainability.train' | translate}}:</span>
                <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability?.Transport?.TrainDistance || '--'}} km</span>
            </div>
            <div class="transportation-info-row">
                <span class="transportation-info-title">{{'configure.sustainability.aeroplane' | translate}}:</span>
                <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability?.Transport?.AeroplaneDistance || '--'}} km</span>
            </div>
            <div class="transportation-info-row">
                <span class="transportation-info-title">{{'configure.sustainability.ship' | translate}}:</span>
                <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability?.Transport?.ShipDistance || '--'}} km</span>
            </div>
        </div>
    </div>
</div>