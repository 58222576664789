import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { HomeService } from '../../services/home.service';
import { BpsProject } from '../../models/bps-project';
import { CommonService } from '../../services/common.service';
import { PermissionService } from 'src/app/app-core/services/permission.service';
import { Feature } from 'src/app/app-core/models/feature';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  language: string = '';
  country: string = '';
  constructor(private navLayoutService: NavLayoutService, private homeService: HomeService, private commonService: CommonService, private permissionService: PermissionService, private localStorageService: LocalStorageService, private activatedRoute: ActivatedRoute, private cookieService: CookieService, private configureService: ConfigureService) { }
  updateProjectList: boolean = false;
  projects: BpsProject[];
  isOperating = false;
  isFeaturePopupVisible:boolean = false;
  ngOnInit(): void {
    // this.navLayoutService.isEmptyPage.next(false);
    // this.navLayoutService.changeNavBarVisibility(true);
    // this.navLayoutService.changeNavBarButtonAndTitleVisibility(false);
    ////isEmpty: boolean, navBarVisibility: boolean, navBarAndTitleVisibility: boolean
    //
    this.activatedRoute.queryParams.subscribe(params => {
      if(params){
        const sToken = params['token'];
        this.country = params['country']?params['country']:'US';
        const language = params['language']?params['language']:'en';
        if(params['language']){
          var languageSelectedTo: string;
          switch(language){
            case "de":
              languageSelectedTo = language +"-DE";
              break;
            case "fr":
              languageSelectedTo = language +"-FR";
              break;
            case "en":
              languageSelectedTo = language +"-US";
              break;
            default:
              if(this.country) languageSelectedTo = language +"-"+this.country;
              break;
          }
          if(languageSelectedTo && languageSelectedTo != this.language){
            
            this.localStorageService.setValue<string>('culture', languageSelectedTo);
            this.language = languageSelectedTo;
            this.homeService.setCurrentUserLanguage(languageSelectedTo).subscribe((lan) => {
              this.localStorageService.setValue<string>('culture', lan);
              setTimeout(() => {
                this.navLayoutService.languageIsChangedSubject.next();
                this.setHomeData();
              }, 200);
            });
          } 
        } else{
          this.setHomeData();
        }
      }
      else{
        this.setHomeData();
      }

    });
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      console.log('country ' +this.country);
      this.language = this.configureService.getLanguage();
    });
  }

  setHomeData(){
    this.language = (this.localStorageService.getValue('culture')) ? this.localStorageService.getValue('culture') : 'en-US';
   
    var is_modal_show = this.cookieService.get('featurePopShownForUser');
    if(localStorage.getItem('currentVersion') == null){
      this.navLayoutService.GetVersionInformation().pipe(takeUntil(this.destroy$)).subscribe((versionInformation: any) => {
        if (versionInformation) {
          if(versionInformation.VersionNumber)
            localStorage.setItem('currentVersion', versionInformation.VersionNumber);
          if(is_modal_show != 'featurePopShownForUser_'+this.commonService.getUserId()+ '_'+ localStorage.getItem('currentVersion')){
            const warningMsg = "";
            this.isFeaturePopupVisible = true;
            this.cookieService.set('featurePopShownForUser','featurePopShownForUser_'+this.commonService.getUserId()+ '_'+ localStorage.getItem('currentVersion'));
          }
        }
      }, error => {
      });
    }
    else{
      if(is_modal_show != 'featurePopShownForUser_'+this.commonService.getUserId()+ '_'+ localStorage.getItem('currentVersion')){
        const warningMsg = "";
        this.isFeaturePopupVisible = true;
        this.cookieService.set('featurePopShownForUser','featurePopShownForUser_'+this.commonService.getUserId()+ '_'+ localStorage.getItem('currentVersion'));
      }
    }
    this.navLayoutService.changeNavBarSettings(false,true,false)
    this.isOperating = false;
    if (this.permissionService.checkPermission(Feature.GetProjects)) {
      this.homeService.GetProjects().pipe(takeUntil(this.destroy$)).subscribe(bpsProject => {
        this.projects = bpsProject.sort((a, b) => (a.ProjectId > b.ProjectId ? -1 : 1));
        this.isOperating = true;
      });
    }
    else if(this.permissionService.checkPermission(Feature.GetSRSProjects)){
      this.homeService.GetSRSProjects(null, null).pipe(takeUntil(this.destroy$)).subscribe(bpsProject => {
        this.projects = bpsProject.sort((a, b) => (a.ProjectId > b.ProjectId ? -1 : 1));
        this.isOperating = true;
      });
    }
  }
  

  showFeaturePopup(){
    this.isFeaturePopupVisible = true;
  }

  hideFeaturePopup(){
    
    this.isFeaturePopupVisible = false;
  }

  refreshHome() {
    this.updateProjectList = true;
  }
}
