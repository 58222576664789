import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SystemSelectionInfo } from 'src/app/app-common/models/bps-project';
import { BpsSimplifiedProblem } from 'src/app/app-common/models/bps-simplified-problem';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { AppconstantsService } from 'src/app/app-common/services/appconstants.service';

@Injectable({
  providedIn: 'root'
})
export class SystemTableService {
  /**
   *                                  WORKFLOW OF THE SYSTEM TABLE :
   * 
   * 
   * On GetProblemByGuid call : We then call GetFramingSystems and reload the tables.
   * 
   * On close the panel by the x button or the navbar button :  We deactivate the non confirmed article.
   *                                                            We unselect the article in the panel.
   * 
   * 
   * On Compute configuration,
   * On Copy & Create configuration from the right panel : We save the confirmed + in use systems (saveSystemsSubject)
   * 
   * On select any system from the framing component,
   * On any action from the right panel : We unselect the article in the panel.
   *                                      Next time the user opens the system panel, the current system will be selected by default.
   */

  arrayOfSelectedSystemsDescription: any = [];
  sujAddSystem: Subject<any> = new Subject<any>();
  sujUnselectSystem: Subject<any> = new Subject<any>();
  sujCloseTableFromNavBar: Subject<any> = new Subject<any>();
  saveSystemsSubject: Subject<any> = new Subject<any>();
  systemOfProblemsUsed = {};
  isNewSystemAddedToTheActivatedSystems: boolean = false;

  constructor(private appConstantService: AppconstantsService, private http: HttpClient) { }

  EditSelectedSystems(systemSelectionInfo: SystemSelectionInfo): Observable<any> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/EditFramingSystemSelection/";
    return this.http.post<BpsSimplifiedProblem>(url, systemSelectionInfo);
  }

  GetFramingSystems(ProjectGuid): Observable<any> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/GetFramingSystems/" + ProjectGuid;
    return this.http.get<any>(url);
  }

  saveSystemSelectionInService(arrayOfSystemDescriptions) {
    setTimeout(() => {
      this.arrayOfSelectedSystemsDescription = arrayOfSystemDescriptions;
    });
  }

  addSavedSystemSelectionInService(systemDescription) {
    setTimeout(() => {
      this.arrayOfSelectedSystemsDescription.push(systemDescription.split(' ').join('').split('.').join('').toUpperCase());
    });
  }


  /**
   * When all the configurations of the project are loaded
   * We lock all the systems used in these problems
   * @param problems
   */
  saveSystemOfProblems(problems) {
    this.systemOfProblemsUsed = {};
    this.isNewSystemAddedToTheActivatedSystems = false;
      problems.forEach(problem => {
        let um = JSON.parse(problem.UnifiedModel);
        if (um.ProblemSetting.ProductType) {
          let table = um.ProblemSetting.ProductType == 'Window' ? 'tableA':um.ProblemSetting.ProductType == 'SlidingDoor' ? 'tableB':um.ProblemSetting.ProductType == 'Facade' ? 'tableC':null;
          let system_description = (um.ModelInput.FrameSystem.SystemType + (um.ModelInput.FrameSystem.InsulationZone != null ? um.ModelInput.FrameSystem.InsulationZone:'')).split(' ').join('').split('.').join('').toUpperCase()
          this.systemOfProblemsUsed[problem.ProblemGuid] = system_description;
          this.sujAddSystem.next({systemDescription: system_description, table: table, select: true});
        }
      });
    if (this.isNewSystemAddedToTheActivatedSystems) this.saveSystemsSubject.next(true);
  }

  /**
   * When the user creates a new configuration
   * We lock the system of the problem
   * This system has now the selected state in the system table
   * @param problem  
   * @param table 
   */
  addSystemOfProblem(problem, table) {
    let um = JSON.parse(problem.UnifiedModel);
    if (um.ProblemSetting.ProductType) {
      this.systemOfProblemsUsed[problem.ProblemGuid] = (um.ModelInput.FrameSystem.SystemType + (um.ModelInput.FrameSystem.InsulationZone != null ? um.ModelInput.FrameSystem.InsulationZone:'')).split(' ').join('').split('.').join('').toUpperCase();
      this.addSavedSystemSelectionInService(this.systemOfProblemsUsed[problem.ProblemGuid]);
      this.sujAddSystem.next({systemDescription: (um.ModelInput.FrameSystem.SystemType + (um.ModelInput.FrameSystem.InsulationZone != null ? um.ModelInput.FrameSystem.InsulationZone:'')).split(' ').join('').split('.').join('').toUpperCase(), table: table, select: true});
    }
  }


  /**
   * When the user selects a system from the framing section in left configure
   * We lock the system from the unified model
   * @param um 
   * @param table 
   */
  addSystemOfProblemWithUnifiedModel(um: UnifiedModel, table) {
    if (um.ProblemSetting.ProductType) {      
      this.systemOfProblemsUsed[um.ProblemSetting.ProblemGuid] = (um.ModelInput.FrameSystem.SystemType + (um.ModelInput.FrameSystem.InsulationZone != null ? um.ModelInput.FrameSystem.InsulationZone:'')).split(' ').join('').split('.').join('').toUpperCase();
      this.addSavedSystemSelectionInService(this.systemOfProblemsUsed[um.ProblemSetting.ProblemGuid]);
      this.sujAddSystem.next({systemDescription: (um.ModelInput.FrameSystem.SystemType + (um.ModelInput.FrameSystem.InsulationZone != null ? um.ModelInput.FrameSystem.InsulationZone:'')).split(' ').join('').split('.').join('').toUpperCase(), table: table, select: false});
    }
  }

  /**
   * Unselect the article of the system panel
   */
  unselectArticle() {
    this.sujUnselectSystem.next();
  }

  /**
   * Close the table from the nav bar
   */
  onCloseTableFromNavBar() {
    this.sujCloseTableFromNavBar.next();
  }

}