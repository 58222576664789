import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { FacadeStructuralResult, StructuralResult, UDCStructuralResult } from 'src/app/app-common/models/bps-structural-result';
import { ResultService } from 'src/app/app-structural/services/result.service';
import { Subject } from 'rxjs';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { takeUntil } from 'rxjs/operators';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';

@Component({
  selector: 'app-left-structural-panel',
  templateUrl: './left-structural-panel.component.html',
  styleUrls: ['./left-structural-panel.component.css']
})
export class LeftStructuralPanelComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private navLayoutService: NavLayoutService,
    private configureService: ConfigureService, private resultService: ResultService) {
  }
  @Input() unified3DModel: UnifiedModel;
  @Input() selectedStructuralIntermediate: number[];
  @Input() selectedStructuralIntermediateFacade: number[];
  @Output() sendSelectedIntermediate: EventEmitter<number> = new EventEmitter();
  @Output() sendIntermediateRedColor: EventEmitter<any[]> = new EventEmitter();
  bpsStructuralResult: StructuralResult;
  bpsFacadeStructuralResult: FacadeStructuralResult;
  bpsUDCStructuralResult: UDCStructuralResult;
  memberName_stringId: number;
  isTransom: boolean = true;
  articleId: string;
  articleImgUrl: string = "";

  stressRation: number;
  shearRatio: number;
  deflectionRatio: number;
  verticalDeflectionRatio: number;

  outofplaneBendingCapacityRatio: number;
  outofplaneReinfBendingCapacityRatio: number;
  inplaneReinfBendingCapacityRatio: number;
  inplaneBendingCapacityRatio: number;
  outofplaneDeflectionCapacityRatio: number;
  inplaneDeflectionCapacityRatio: number;
  requiredIx: number;
  requiredIy: number;

  language: string = '';


  ngOnInit(): void {
    this.language = this.configureService.getLanguage();
    this.GetStructuralResults();
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.language = this.configureService.getLanguage();
    });  
  }
  ngOnChanges(Changes: SimpleChanges) {
    if (this.bpsStructuralResult || this.bpsFacadeStructuralResult || this.bpsUDCStructuralResult) {
      this.SetStructuralResultLabels();
    }
    if (Changes.unified3DModel && !Changes.unified3DModel.firstChange) {
      this.selectedStructuralIntermediate = [];
      this.selectedStructuralIntermediateFacade = [];
      this.resultService.selectedIntermediatesStructural = null;
      this.GetStructuralResults();
    }
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  SetStructuralResultLabels() {
    if (this.selectedStructuralIntermediate && this.selectedStructuralIntermediate.length > 0) {
      this.resultService.selectedIntermediatesStructural = this.selectedStructuralIntermediate[0];
      this.PopulateStructuralResultForMember();
    }
    else if (this.selectedStructuralIntermediateFacade && this.selectedStructuralIntermediateFacade.length > 0) {
      this.resultService.selectedIntermediatesStructural = this.selectedStructuralIntermediateFacade[0];
      this.PopulateStructuralResultForMember();
    }
    else if (!this.selectedStructuralIntermediate ||
            (this.selectedStructuralIntermediate && this.selectedStructuralIntermediate.length == 0) ||
            (!this.selectedStructuralIntermediateFacade || (this.selectedStructuralIntermediateFacade && this.selectedStructuralIntermediateFacade.length == 0))
            ) {
      this.isTransom = true;
      this.articleId = null;
      this.articleImgUrl = '';
      this.memberName_stringId = null;
      this.stressRation = null;
      this.shearRatio = null;
      this.deflectionRatio = null;
      this.verticalDeflectionRatio = null;
      this.outofplaneBendingCapacityRatio = null;
      this.outofplaneReinfBendingCapacityRatio = null;
      this.inplaneReinfBendingCapacityRatio = null;
      this.inplaneBendingCapacityRatio = null;
      this.outofplaneDeflectionCapacityRatio = null;
      this.inplaneDeflectionCapacityRatio = null;
      this.requiredIx = null;
      this.requiredIy = null;
      let memberList = [];

      if (this.unified3DModel.ProblemSetting.ProductType === "Window") {
        if (this.bpsStructuralResult && this.bpsStructuralResult.MemberResults)
          this.bpsStructuralResult.MemberResults.forEach(element => {
            let color = "0xA2D892";
            if (element.shearRatio > 1 || element.stressRatio > 1 || element.deflectionRatio > 1 || element.verticalDeflectionRatio > 1
              || element.IyRequired >1 || element.IzRequired)
              color = "0xbc0000";
            memberList.push({ id: element.memberID, color: color });
          });
      }
      else {
        if (this.bpsFacadeStructuralResult && this.bpsFacadeStructuralResult.MemberResults) {
          this.bpsFacadeStructuralResult.MemberResults.forEach(element => {
            let color = "0xA2D892";
            if (element.outofplaneBendingCapacityRatio > 1 || element.outofplaneReinfBendingCapacityRatio > 1 || element.inplaneBendingCapacityRatio > 1
              || element.inplaneReinfBendingCapacityRatio >1 || element.outofplaneDeflectionCapacityRatio > 1 || element.inplaneDeflectionCapacityRatio > 1
              || element.IxRequired >1 || element.IyRequired)
              color = "0xbc0000";
            memberList.push({ id: element.memberID, color: color });
          });
        }
        else if (this.bpsUDCStructuralResult && this.bpsUDCStructuralResult.MemberResults) {
          this.bpsUDCStructuralResult.MemberResults.forEach(element => {
            let color = "0xA2D892";
            if (element.outofplaneBendingCapacityRatio > 1 || element.inplaneBendingCapacityRatio > 1
              || element.outofplaneDeflectionCapacityRatio > 1 || element.inplaneDeflectionCapacityRatio > 1
              || element.IxRequired >1 || element.IyRequired)
              color = "0xbc0000";
            memberList.push({ id: element.memberID, color: color });
          });
        }
      }
    }
  }
  
  GetStructuralResults() {
    if (this.unified3DModel.ProblemSetting.ProductType === "Window") {
      if (this.unified3DModel && this.unified3DModel.AnalysisResult && this.unified3DModel.AnalysisResult.StructuralResult) {
        this.bpsStructuralResult = this.unified3DModel.AnalysisResult.StructuralResult;
        this.SetStructuralResultLabels();
        if (this.resultService.selectedIntermediatesStructural) {
          this.sendSelectedIntermediate.emit(this.resultService.selectedIntermediatesStructural);
        }
      }
    } else {
      if (this.unified3DModel && this.unified3DModel.AnalysisResult && this.unified3DModel.AnalysisResult.FacadeStructuralResult) {
        this.bpsFacadeStructuralResult = this.unified3DModel.AnalysisResult.FacadeStructuralResult;
        this.SetStructuralResultLabels();
        if (this.resultService.selectedIntermediatesStructural) {
          this.sendSelectedIntermediate.emit(this.resultService.selectedIntermediatesStructural);
        }
      }
      else if (this.unified3DModel && this.unified3DModel.AnalysisResult && this.unified3DModel.AnalysisResult.UDCStructuralResult) {
        this.bpsUDCStructuralResult = this.unified3DModel.AnalysisResult.UDCStructuralResult;
        this.SetStructuralResultLabels();
        if (this.resultService.selectedIntermediatesStructural) {
          this.sendSelectedIntermediate.emit(this.resultService.selectedIntermediatesStructural);
        }
      }
    }
  }

  PopulateStructuralResultForMember() {
    if (this.unified3DModel.ProblemSetting.ProductType === "Window") {
      if (this.selectedStructuralIntermediate && this.selectedStructuralIntermediate.length > 0) {
        let selectedMembers = this.bpsStructuralResult.MemberResults.filter(x => this.selectedStructuralIntermediate[0] == (x.memberID));
        if (selectedMembers && selectedMembers.length > 0) {
          let selectedMember = selectedMembers[0];
          this.shearRatio = selectedMember.shearRatio * 100;
          this.stressRation = selectedMember.stressRatio * 100;
          this.deflectionRatio = selectedMember.deflectionRatio * 100;
          this.verticalDeflectionRatio = selectedMember.verticalDeflectionRatio * 100;
          this.requiredIx = selectedMember.IyRequired;
          this.requiredIy = selectedMember.IzRequired;
          let members = this.unified3DModel.ModelInput.Geometry.Members.filter(x => x.MemberID == selectedMember.memberID);
          if (members && members.length == 1) {
            let member = members[0];
            this.isTransom = false;
            switch (member.MemberType) {
              case 1:
                this.memberName_stringId = 1;  // outer frame
                break;
              case 2:
                this.memberName_stringId = 2;  // mullion
                break;
              case 3:
                this.memberName_stringId = 3;  // transom
                this.isTransom = true;
                break;
            }
            if (this.unified3DModel.ModelInput.Geometry.Sections !== null) {
              let sections = this.unified3DModel.ModelInput.Geometry.Sections.filter(x => x.SectionID == member.SectionID);
              if (sections && sections.length == 1) {
                this.articleId = sections[0].ArticleName;
                this.articleImgUrl = sections[0].isCustomProfile ? "https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/blankArticle.png" : 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/cross-section/' + this.articleId + '.jpg';
              }
            }
          }
        }
      }
    }
    else {
      if (this.bpsFacadeStructuralResult && this.bpsFacadeStructuralResult.MemberResults) {
        let selectedMembers = this.bpsFacadeStructuralResult.MemberResults.filter(x => this.selectedStructuralIntermediateFacade[0] == (x.memberID));
        if (selectedMembers && selectedMembers.length > 0) {
          let selectedMember = selectedMembers[0];
          this.outofplaneBendingCapacityRatio = selectedMember.outofplaneBendingCapacityRatio * 100;
          this.outofplaneReinfBendingCapacityRatio = selectedMember.outofplaneReinfBendingCapacityRatio * 100;
          this.inplaneReinfBendingCapacityRatio = selectedMember.inplaneReinfBendingCapacityRatio * 100;
          this.inplaneBendingCapacityRatio = selectedMember.inplaneBendingCapacityRatio * 100;
          this.outofplaneDeflectionCapacityRatio = selectedMember.outofplaneDeflectionCapacityRatio * 100;
          this.inplaneDeflectionCapacityRatio = selectedMember.inplaneDeflectionCapacityRatio * 100;
          this.requiredIx = selectedMember.IyRequired;
          this.requiredIy = selectedMember.IzRequired;
          let members = this.unified3DModel.ModelInput.Geometry.Members.filter(x => x.MemberID == selectedMember.memberID);
          if (members && members.length == 1) {
            let member = members[0];
            this.isTransom = false;
            //1: Outer Frame, 2: Mullion, 3: transom,  4: Facade Major Mullion, 5: Facade Transom, 6 Facade Minor Mullion
            switch (member.MemberType) {
              case 1:
                this.memberName_stringId = 1;  // outer frame
                break;
              case 2:
                this.memberName_stringId = 2;  // mullion
                break;
              case 3:
                this.memberName_stringId = 3;  // transom
                this.isTransom = true;
                break;
              case 4:
                this.memberName_stringId = 4;  // facade major mullion
                break;
              case 5:
                this.memberName_stringId = 5;  // facade transom
                this.isTransom = true;
                break;
              case 6:
                this.memberName_stringId = 6;  // facade minor mullion
                break;
            }
            if (this.unified3DModel.ModelInput.Geometry.FacadeSections !== null){
              let mainsections = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(x => x.SectionID == member.SectionID);
              if(this.unified3DModel.ModelInput.Geometry.Reinforcements != undefined && this.unified3DModel.ModelInput.Geometry.Reinforcements != null && this.unified3DModel.ModelInput.Geometry.Reinforcements.length > 0 && this.unified3DModel.ModelInput.Geometry.Reinforcements.filter(r => r.MemberID === member.MemberID).length > 0) {
                
                  let reinforcement = this.unified3DModel.ModelInput.Geometry.Reinforcements.filter(r => r.MemberID === member.MemberID)[0];
                  
                  if(reinforcement) {
                    let section = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(x => x.SectionID == reinforcement.SectionID);
                    if(section && section.length ==1) {
                      this.articleId = section[0].MullionReinforcementSteelBarThickness !==0 ?  mainsections[0].ArticleName + '_' + section[0].ArticleName + '_' + section[0].MullionReinforcementSteelBarThickness  : mainsections[0].ArticleName + '_' + section[0].ArticleName;
                      this.articleImgUrl =  section[0].isCustomProfile ? "https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/blankArticle.png" : 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg-new/' + this.articleId + '.jpg';
                    }
                  }
               
              } else {
                if (mainsections && mainsections.length == 1) {
                  this.articleId = mainsections[0].ArticleName;
                  this.articleImgUrl = mainsections[0].isCustomProfile ? "https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/blankArticle.png": 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg-new/' + this.articleId + '.jpg';
                }
              }
            }
          }
        }
      }
      else if (this.bpsUDCStructuralResult && this.bpsUDCStructuralResult.MemberResults) {
          let selectedMembers = this.bpsUDCStructuralResult.MemberResults.filter(x => this.selectedStructuralIntermediateFacade[0] == (x.memberID));
          if (selectedMembers && selectedMembers.length > 0) {
            let selectedMember = selectedMembers[0];
            this.outofplaneBendingCapacityRatio = selectedMember.outofplaneBendingCapacityRatio * 100;
            this.inplaneBendingCapacityRatio = selectedMember.inplaneBendingCapacityRatio * 100;
            this.outofplaneDeflectionCapacityRatio = selectedMember.outofplaneDeflectionCapacityRatio * 100;
            this.inplaneDeflectionCapacityRatio = selectedMember.inplaneDeflectionCapacityRatio * 100;
            this.requiredIx = selectedMember.IyRequired;
            this.requiredIy = selectedMember.IzRequired;
            let members = this.unified3DModel.ModelInput.Geometry.Members.filter(x => x.MemberID == selectedMember.memberID);
            if (members && members.length == 1) {
              let member = members[0];
              this.isTransom = false;
              //1: Outer Frame, 2: Mullion, 3: transom,  4: Facade Major Mullion, 5: Facade Transom, 6 Facade Minor Mullion
              // 21:UDC Top Frame; 22: UDC Vertical Frame;  23: UDC Bottom Frame; 24: UDC Vertical Glazing Bar; 25: UDC Horizontal Glazing Bar;
              switch (member.MemberType) {
                case 21:
                  this.memberName_stringId = 7; // 'Top Frame';
                  // [TRANS_TODO] Top Frame
                  this.isTransom = true;
                  break;
                case 22:
                  this.memberName_stringId = 8; // 'Vertical Frame';
                  this.isTransom = true;
                  // [TRANS_TODO] Vertical Frame
                  break;
                case 23:
                  this.memberName_stringId = 9; // 'Bottom Framing';
                  this.isTransom = true;
                  // [TRANS_TODO] Bottom Framing
                  break;
                case 24:
                  this.memberName_stringId = 10; // 'Vertical Glazing Bar';
                  this.isTransom = true;
                  // [TRANS_TODO] Vertical Glazing Bar
                  break;
                case 25:
                  this.memberName_stringId = 11; // 'Horizontal Glazing Bar';
                  // [TRANS_TODO] Horizontal Glazing Bar
                  break;
              }
              if (this.unified3DModel.ModelInput.Geometry.FacadeSections !== null) {
                let sections = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(x => x.SectionID == member.SectionID);
                if (sections && sections.length == 1) {
                  this.articleId = sections[0].ArticleName;
                  this.articleImgUrl = 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/' + this.articleId + '.jpg';
                }
              }
            }
          }
        }
    }
  }
}
