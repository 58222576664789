<div *ngIf="umService?.current_UnifiedModel?.ModelInput?.Sustainability" class="accordion-section-container">
    <div class="sps-break first-break"></div>
    <div class="surface-treatment-row" [ngClass]="{'row-active': umService.current_UnifiedModel.ModelInput.Sustainability.SurfaceTreatment}">
        <h3 class="header no-margin-bottom" [ngClass]="{'active': umService.current_UnifiedModel.ModelInput.Sustainability.SurfaceTreatment}">{{'configure.sustainability.surface-treatments' | translate}}</h3>
        <bps-switch [(ngModel)]="umService.current_UnifiedModel.ModelInput.Sustainability.SurfaceTreatment" (ngModelChange)="onSwitchSurfaceTreatment($event)"></bps-switch>
    </div>
    <form bps-form [formGroup]="validateForm">
        <div *ngIf="umService.current_UnifiedModel.ModelInput.Sustainability.SurfaceTreatment" class="type-container">
            <h3 class="header">{{'configure.sustainability.type' | translate}}</h3>
            <bps-form-item class="bps-form-item">
                <bps-form-control>
                    <bps-select class="type-select" formControlName="surfaceTreatmentsControl" [(ngModel)]="umService.current_UnifiedModel.ModelInput.Sustainability.SurfaceTreatment" bpsPlaceHolder="Choose">
                        <bps-option bpsValue="Powder Coating" bpsLabel="{{'configure.sustainability.powder-coating' | translate}}"></bps-option>
                        <bps-option bpsValue="Anodized" bpsLabel="{{'configure.sustainability.anodized' | translate}}"></bps-option>
                        <bps-option bpsValue="PVDF" bpsLabel="{{'configure.sustainability.pvdf' | translate}}"></bps-option>
                    </bps-select>
                </bps-form-control>
            </bps-form-item>
        </div>
        <div class="sps-break second-break"></div>
        <div class="transport-distance-container">
            <h3 class="header">{{'configure.sustainability.transport-distance' | translate}}</h3>
            <button class="transportation-btn" bps-button bpsType="variation-2" (click)="onOpenTransportation()">{{'configure.sustainability.transportation' | translate}}</button>
            <div class="transportation-information-container">
                <div class="transportation-info-row">
                    <span class="transportation-info-title">{{'configure.sustainability.lorry-12' | translate}}:</span>
                    <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability.Transport.Lorry12tDistance || '--'}} km</span>
                </div>
                <div class="transportation-info-row">
                    <span class="transportation-info-title">{{'configure.sustainability.lorry-28' | translate}}:</span>
                    <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability.Transport.Lorry28tDistance || '--'}} km</span>
                </div>
                <div class="transportation-info-row">
                    <span class="transportation-info-title">{{'configure.sustainability.lorry-40' | translate}}:</span>
                    <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability.Transport.Lorry40tDistance || '--'}} km</span>
                </div>
                <div class="transportation-info-row">
                    <span class="transportation-info-title">{{'configure.sustainability.train' | translate}}:</span>
                    <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability.Transport.TrainDistance || '--'}} km</span>
                </div>
                <div class="transportation-info-row">
                    <span class="transportation-info-title">{{'configure.sustainability.aeroplane' | translate}}:</span>
                    <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability.Transport.AeroplaneDistance || '--'}} km</span>
                </div>
                <div class="transportation-info-row">
                    <span class="transportation-info-title">{{'configure.sustainability.ship' | translate}}:</span>
                    <span class="transportation-info-value">{{umService.current_UnifiedModel.ModelInput.Sustainability.Transport.ShipDistance || '--'}} km</span>
                </div>
            </div>
        </div>
        <h3 class="header">{{'configure.sustainability.aluminum-grade' | translate}}</h3>
        <bps-form-item class="bps-form-item">
            <bps-form-control>
                <bps-select [(ngModel)]="umService.current_UnifiedModel.ModelInput.Sustainability.AluminumGrade" bpsPlaceHolder="Choose" formControlName="aluminumGradeControl" class="aluminum-select">
                    <bps-option bpsValue="Standard Aluminum" bpsLabel="{{'configure.sustainability.standard-aluminum' | translate}}"></bps-option>
                    <bps-option bpsValue="Low Carbon Aluminum" bpsLabel="{{'configure.sustainability.low-carbon-aluminum' | translate}}"></bps-option>
                    <bps-option bpsValue="Ultra Low Carbon Aluminum" bpsLabel="{{'configure.sustainability.ultra-low-carbon-aluminum' | translate}}"></bps-option>
                </bps-select>
            </bps-form-control>
        </bps-form-item>
        <h3 class="header">{{'configure.sustainability.electricity' | translate}}</h3>
        <div class="electricity-input-row" >
            <bps-form-item class="bps-form-item">
                <bps-form-control>
                    <input class="electricity-input" type="number" bps-input [(ngModel)]="umService.current_UnifiedModel.ModelInput.Sustainability.Electricity" formControlName="electricityControl">
                </bps-form-control>
            </bps-form-item>
            <span class="electricity-unit">{{'configure.sustainability.electricity-unit' | translate }}</span>
        </div>
    </form>
</div>