import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AppconstantsService } from 'src/app/app-common/services/appconstants.service';
import { UnifiedModelService } from './unified-model.service';
import { ConfigureService } from './configure.service';

@Injectable({
  providedIn: 'root'
})
export class ThermalService {

  constructor(private appConstantService: AppconstantsService, private http: HttpClient, private umService: UnifiedModelService, private configureService: ConfigureService) { }

  readonly AbsorptionCoefficient_defaultValue = 0.6;

  sunShadingArticleList: any = [];
  loadDataCallSubject: Subject<void> = new Subject<void>();

  getSunShadingList(): Observable<any> {
    // let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetSunShadingList";
    // this.http.get<any>(url);

    return of([
      {id: 1, descriptionKey: "configure.thermal-accordion-blinds", delta: 0.08},
      {id: 2, descriptionKey: "configure.thermal-adjustable-slats", delta: 0.08},
      {id: 3, descriptionKey: "configure.thermal-swing-shutters", delta: 0.08},
      {id: 4, descriptionKey: "configure.thermal-louvers", delta: 0.08},
      {id: 5, descriptionKey: "configure.thermal-blind-fasterners", delta: 0.15},
      {id: 6, descriptionKey: "configure.thermal-alu-shutter", delta: 0.15},
      {id: 7, descriptionKey: "configure.thermal-roller-shutters", delta: 0.19},
      {id: 8, descriptionKey: "configure.thermal-sliding-shutter", delta: 0.19},
      {id: 9, descriptionKey: "configure.thermal-pvc-swing-shutter", delta: 0.19},
      {id: 10, descriptionKey: "configure.thermal-wooden-swing-shutter", delta: 0.19},
      {id: 11, descriptionKey: "configure.thermal-sliding-shutter", delta: 0.25},
      {id: 12, descriptionKey: "configure.thermal-wooden-swing-shutter", delta: 0.25},
      {id: 13, descriptionKey: "configure.thermal-roller-shutters", delta: 0.25}
    ]);
  }

  getSunShadingArticleStringById(id: number): string {
    if (this.sunShadingArticleList.length == 0) {
      this.loadDataCallSubject.next();
    }
    let article = this.sunShadingArticleList.find(article => article.id == id);
    return article ? article.description : null;
  }

  isThermalCodeAllowed(country: string): boolean {
    if (!this.umService.isAnyInsertedUnit() || this.umService.isWindow()) {
      let isGerman: boolean = this.umService.getCurrentSystemCompatibleForInsertUnit()?.DE;
      let isFrench: boolean = this.umService.getCurrentSystemCompatibleForInsertUnit()?.FR;
      if (isGerman && isFrench) return country == 'France' ? this.configureService.isProjectInFrance():!this.configureService.isProjectInFrance();
      else return country == 'France' ? isFrench:isGerman;
    }
    else return country == 'France' ? this.configureService.isProjectInFrance():!this.configureService.isProjectInFrance();
  }
}
