<div class="main-container" [ngClass]="{'opening-animation': isPopoutOpened, 'closing-animation': !isPopoutOpened}">










    <div class="left-panel">
        <div class="main-container-left">
            <img *ngIf="selectedSystemRowData && selectedSystemRowData.systemNameSmall" alt="aws_picture" class="img" [attr.src]="getImgSRC()">
            <div *ngIf="!selectedSystemRowData || !selectedSystemRowData.systemNameSmall" class="img-empty"></div>
            <div class="system-title" [ngStyle]="{'background-color': selectedSystemRowData.description ? '#363636':'#262626'}">
                {{selectedSystemRowData.description}}
            </div>
            <div class="title-left">{{'sys-table.status' | translate }}</div>
            <div class="info-container">
                <span class="text-info-container">
                    {{selectedSystemRowData.status || '-'}}
                </span>&nbsp;&nbsp;
                <span *ngIf="selectedSystemRowData.status" class="active-dot"></span>
            </div>
            <div class="title-left">{{'sys-table.market' | translate }}</div>
            <div class="info-container">
                <span class="text-info-container">
                    {{getMarketCountries() || '-'}}
                </span>        
            </div>
            <div class="title-left">{{'sys-table.more-info' | translate }}:</div>
            <div class="info-container">
                <span class="text-info-container">
                    {{selectedSystemRowData.moreInfo || '-'}}
                </span>        
            </div>
            <div class="title-left">{{'sys-table.documentation' | translate }}</div>
            <a bps-button bpsType="variation-2" *ngIf="selectedSystemRowData.description" style="width: 215px; display: flex; align-items: center; justify-content: center; margin-bottom: 5px;" href="{{getOrderManual_SRC()}}" target="_blank">Order Manual</a>
            <a bps-button bpsType="variation-2" *ngIf="selectedSystemRowData.description" style="width: 215px; display: flex; align-items: center; justify-content: center; margin-bottom: 5px;" href="{{getFabManual_SRC()}}" target="_blank">Fabrication Manual</a>
            <a bps-button bpsType="variation-2" *ngIf="selectedSystemRowData.description" style="width: 215px; display: flex; align-items: center; justify-content: center; margin-bottom: 5px;" href="{{getArchInfo_SRC()}}" target="_blank">Architect Info</a>
            <button bps-button bpsType="variation-2" *ngIf="!selectedSystemRowData.description" disabled style="width: 215px; display: flex; align-items: center; justify-content: center; margin-bottom: 5px;">Order Manual</button>
            <button bps-button bpsType="variation-2" *ngIf="!selectedSystemRowData.description" disabled style="width: 215px; display: flex; align-items: center; justify-content: center; margin-bottom: 5px;">Fabrication Manual</button>
            <button bps-button bpsType="variation-2" *ngIf="!selectedSystemRowData.description" disabled style="width: 215px; display: flex; align-items: center; justify-content: center; margin-bottom: 5px;">Architect Info</button>
        </div>
    </div>












    <div class="right-panel">
        <div class="main-container-right">
            <div class="sub-main-container">
                <div class="top-container">
                    <div style="width: 365px; margin-right: 40px;">
                        <div class="title-right">
                            {{'sys-table.available-systems' | translate }}
                        </div>
                        <input type="text" class="search-system-input" bps-input [(ngModel)]="searchSystemInputText" (ngModelChange)="organizeDisplayData()" [placeholder]="('sys-table.search-by-system-name' | translate)">        
                    </div>
                    <div style="width: 365px; margin-right: 40px;"></div>
                    <div style="width: 365px; margin-right: 40px;"></div>
                </div>
                <div class="system-tables-container">
                    <div style="width: 365px; margin-right: 40px;" class="hide-input-search">
                        <div class="header-table">
                            {{'sys-table.add-window-door-systems' | translate }}
                        </div>
                        <bps-table #table1 [data]="listOfDisplayData1"
                                   [scroll]="{y: 'calc(100vh - 334px)', x: '330px' }"
                                   [showPagination]="false"
                                   [frontPagination]="false"
                                   (onclickRow)="onChangeRowSelection('tableA', $event)"
                                   (selectionChange)="log($event)"
                                   [tableType]="'glass_profile'"
                                   class="bps-system-table"
                                   (sortChange)="sort($event, 'tableA')"
                                   [(config)]="configurationCustomGrid">
                        </bps-table>
                    </div>
                    <div style="width: 365px; margin-right: 40px;" class="hide-input-search">
                        <div class="header-table">
                            {{'sys-table.add-sliding-door-systems' | translate }}
                        </div>
                        <bps-table #table2 [data]="listOfDisplayData2"
                                   [scroll]="{y: 'calc(100vh - 334px)', x: '330px' }"
                                   [showPagination]="false"
                                   [frontPagination]="false"
                                   (onclickRow)="onChangeRowSelection('tableB', $event)"
                                   (selectionChange)="log($event)"
                                   [tableType]="'glass_profile'"
                                   class="bps-system-table"
                                   (sortChange)="sort($event, 'tableB')"
                                   [(config)]="configurationCustomGrid">
                        </bps-table>
                    </div>
                    <div style="width: 365px; margin-right: 40px;" class="hide-input-search">
                        <div class="header-table">
                            {{'sys-table.add-facade-systems' | translate }}
                        </div>
                        <bps-table #table3 [data]="listOfDisplayData3"
                                   [scroll]="{y: 'calc(100vh - 334px)', x: '330px' }"
                                   [showPagination]="false"
                                   [frontPagination]="false"
                                   (onclickRow)="onChangeRowSelection('tableC', $event)"
                                   (selectionChange)="log($event)"
                                   [tableType]="'glass_profile'"
                                   class="bps-system-table"
                                   (sortChange)="sort($event, 'tableC')"
                                   [(config)]="configurationCustomGrid">
                        </bps-table>
                    </div>
                </div>
            </div>
            <button class="confirm-button" (click)="onConfirm();onClose();">
                {{'sys-table.done' | translate }}
            </button>
        </div>
        <img alt="close icon" class="close-icon" src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" (click)="onClose();">
        
        
        <app-notification-custom #notifCustomTemplate></app-notification-custom>
    </div>









</div>

<ng-template #rightColumnTemplate let-disabled="disabled" let-activated="activated" let-id="id" let-table="table" let-description="description">
    <div class="right-icon-container">
        <span *ngIf="!disabled && activated && isSystemCurrentlyUsed(description)">{{'sys-table.in-use' | translate}}</span>
        <span *ngIf="disabled || !activated || !isSystemCurrentlyUsed(description)"></span>
        <img *ngIf="disabled" src="/assets/Images/bps-icons/sps_round_icon_lock_disabled.svg" style="margin-left: -2px;"/>
        <img *ngIf="!disabled && !activated" src="/assets/Images/bps-icons/sps_round_icon_lock_enabled.svg" (click)="onActivateDeactivateArticle(table, id)"  bps-tooltip bpsTooltipPlacement="left" [bpsTooltipType]="'variation_10'" [bpsTooltipTitle]="('configure.add' | translate)"/>
        <div *ngIf="!disabled && activated && isSystemCurrentlyUsed(description)" class="activelock-crop"><img src="/assets/Images/bps-icons/sps_round_icon_lock_activelocked.svg" (mouseenter)="onChangeConfigurationNameInUse(description)" bps-popover [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_6'" [bpsPopoverContent]="inUseTooltipTemplate"/></div>
        <img *ngIf="!disabled && activated && !isSystemCurrentlyUsed(description)" src="/assets/Images/bps-icons/sps_round_icon_lock_active.svg" (click)="onActivateDeactivateArticle(table, id)" bps-tooltip bpsTooltipPlacement="left" [bpsTooltipType]="'variation_10'" [bpsTooltipTitle]="('sys-table.tooltip-message-remove' | translate)"/>
    </div>
</ng-template>

<ng-template #inUseTooltipTemplate>
    <div *ngIf="configurationNameInUse.length == 1" class="tooltip-text">
        {{'sys-table.tooltip-message-single-in-use' | translate}} <span style="color: #00a2d1;">{{configurationNameInUse[0]}}.</span>
    </div>
    <div *ngIf="configurationNameInUse.length > 1" style="width:190px;" class="tooltip-text">
        {{'sys-table.tooltip-message-multiple-in-use-top' | translate}}:
        <ul class="configList">
            <li *ngFor="let configurationName of configurationNameInUse; index as i">{{i < 5 ? configurationName: i == 5 ? 'etc.':''}}</li>
        </ul>
        {{'sys-table.tooltip-message-multiple-in-use-bottom' | translate}}.
    </div>
</ng-template>