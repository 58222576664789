import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';

@Component({
  selector: 'app-notification-custom',
  templateUrl: './notification-custom.component.html',
  styleUrls: ['./notification-custom.component.css'],
})

export class NotificationCustomComponent implements OnInit {
  @ViewChild('notificationTemplate', { read: TemplateRef, static: false }) notificationTemplate: TemplateRef<{}>;
  @ViewChild('notificationTemplate2', { read: TemplateRef, static: false }) notificationTemplate2: TemplateRef<{}>;
  iconCustom = '';
  placement = 'topLeft';
  duration = 10 * 1000;
  ngStyleSettings: any = {
    nzStyle: {
      width: '320px',
      marginLeft: '200px',
      marginTop: ''
    },
    nzBottom: '24px',
    nzTop: '55px',
    nzClass: 'ant-notification-cust-level1',
    nzDuration: 6000,
    nzData: null
  };

  constructor(private notification: NzNotificationService, private navLayoutService: NavLayoutService ) { }
  
  ngOnInit(): void {
  }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.config({ nzPlacement: this.placement });
    //this.ngStyleSettings.nzDuration = this.duration;
    this.notification.template(template, this.ngStyleSettings);
  }
  
  notificationShow(notificationKeyId: string, optionalMessage1?: string, optionalMessage2?: string): void {
    this.ngStyleSettings.nzStyle.marginLeft = this.navLayoutService.isReportClicked ? '200px':'';
    this.ngStyleSettings.nzKey = notificationKeyId;           // Only one notification by key
    this.ngStyleSettings.nzData = {key: notificationKeyId};   // Needed for HTML notification template
    if (optionalMessage1) this.ngStyleSettings.nzData.message1 = optionalMessage1;
    if (optionalMessage2) this.ngStyleSettings.nzData.message2 = optionalMessage2;
    if (['Trash_ItemsDeleted_Redirection', 'Trash_ItemsDeleted', 'Trash_CannotDelete', 'chromeWarning'].indexOf(notificationKeyId) > -1) this.placement = 'topRight';
    this.notification.config({ nzPlacement: this.placement });
    this.notification.template(this.notificationTemplate, this.ngStyleSettings);
  }
  
  notificationRemove(): void {
    setTimeout(() => {
      this.notification.remove();
    }, 100);
  }
}
