import { Component, OnInit, TemplateRef, Output, EventEmitter, Input, ViewChild, AfterViewInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { CeldType, BpsTableComponent } from 'bps-components-lib';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { FramingService } from 'src/app/app-structural/services/framing.service';
import { NzFormatEmitEvent, NzTreeNodeOptions } from 'ng-zorro-antd';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { Subject } from 'rxjs';
import { Feature } from 'src/app/app-core/models/feature';
import { PermissionService } from 'src/app/app-core/services/permission.service';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { LibraryCustomComponent } from '../library-custom/library-custom.component';
import { GlassBPS } from 'src/app/app-structural/models/glass-bps.model';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';


@Component({
  selector: 'app-glass-panel-table',
  templateUrl: './glass-panel-table.component.html',
  styleUrls: ['./glass-panel-table.component.css']
})
export class GlassPanelTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  isPopoutOpened: boolean;

  // selectedPicker: number = 1;
  selectedType: string = "double";
  selectedLayerType: string = "double";
  isDoubleBtnDisabled: boolean = false;
  isTripleBtnDisabled: boolean = false;
  isPanelBtnDisabled: boolean = false;
  isNewBtnDisabled: boolean = false;
  // searchValue: string = '';
  ArticlesForSystem = [];
  selectedArticle: any;
  isSidePanel = false;
  maxAllowedThickness: number;
  unified3DModel: UnifiedModel;
  @Output() openNewCustomEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editCustomArticleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngNotificaionShow: EventEmitter<any> = new EventEmitter<any>();

  configurationCustomGrid: any;
  listOfDisplayData: any = [];
  data = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  searchValue = '';
  language: string = '';
  doubleData: any;
  tripleData: any;
  panelLibraryData: any;
  customLibraryData: any;
  isConfirmBtnDisabled: boolean = true;
  isEditCustomDisabled: boolean = true;
  numberOfClicks: number = 0;
  @ViewChild('outsideTemplate', { read: TemplateRef, static: true }) outsideTemplate: TemplateRef<{}>;
  @ViewChild('uValueTemplate', { read: TemplateRef, static: true }) uValueTemplate: TemplateRef<{}>;
  @ViewChild('idTemplate', { read: TemplateRef, static: true }) idTemplate: TemplateRef<{}>;
  @ViewChild('rwTemplate', { read: TemplateRef, static: true }) rwTemplate: TemplateRef<{}>;
  @ViewChild('thicknessTemplate', { read: TemplateRef, static: true }) thicknessTemplate: TemplateRef<{}>;
  @ViewChild('shgcTemplate', { read: TemplateRef, static: true }) shgcTemplate: TemplateRef<{}>;
  @ViewChild('oitcTemplate', { read: TemplateRef, static: true }) oitcTemplate: TemplateRef<{}>;
  @ViewChild('vtTemplate', { read: TemplateRef, static: true }) vtTemplate: TemplateRef<{}>;
  @ViewChild('stcTemplate', { read: TemplateRef, static: true }) stcTemplate: TemplateRef<{}>;
  @ViewChild('bpsTable', { read: BpsTableComponent, static: false }) tableComponent: BpsTableComponent<{}>;
  @ViewChild('customlibrary') customLibrary: LibraryCustomComponent;
  currentArticle: any;
  nodes: NzTreeNodeOptions[];
  defaultSelectedKeys = ['1'];
  disableConfirmBtn: boolean = false;
  defaultExpandedKeys = ['100'];
  applicationType: string;
  selectedChildNodeKey: number = 1;
  selectedHeader: boolean = true;
  headerSelected: boolean = false;
  feature = Feature;
  isFrenchSystem: boolean = false;
  totalGlassList: any = [];
  frGlassList: any = [];
  enGlassList: any = [];
  sortedGlassList: any = [];
  mappedGlassList: any = [];
  isEditCustom: boolean = false;
  @Input() closeNdisableRightPanelEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isDisplay: boolean = false;

  
  listOfDisplayData2: any = [
    {id: '0', dataId: '1', description: '24 mm    (4-16-4)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '1', dataId: '2', description: '28 mm    (6-16-6)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '2', dataId: '3', description: '28 mm    (6-16-4)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '3', dataId: '4', description: '28 mm    (8-16-4)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '4', dataId: '5', description: '32 mm    (10-16-6)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '5', dataId: '6', description: '34 mm    (6-20-8[0.38])', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '6', dataId: '7', description: '36 mm    (8[0.38]-16-12)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '7', dataId: '8', description: '40 mm    (12[0.76]-20-8[0.76])', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '8', dataId: '9', description: '42 mm    (8[0.38]-24-10)', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '9', dataId: '10', description: '44 mm    (12[0.76]-24-8[0.76])', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '10', dataId: '11', description: '44 mm    (12[0.76]-24-8[0.76])', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '11', dataId: '12', description: '44 mm    (12[0.76]-24-8[0.76])', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54},
    {id: '12', dataId: '13', description: '44 mm    (12[0.76]-24-8[0.76])', uValue: '1.1', disabled: false, rw: {value: 50, disabled: false, arrowHovered: false, index: 9, validData: true}, article: {id: 7, composition: '8(0.38mm Acoustic PVB)-16-12', type: 'glass-argon-glass', totalThickness: 36, totalThicknessUnit: 'mm'}, thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54}
    
    
  ]

  constructor(
    private cpService: ConfigPanelsService,
    private umService: UnifiedModelService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private configureService: ConfigureService,
    private framingService: FramingService,
    private permissionService: PermissionService,
    private fb: FormBuilder, private navLayoutService: NavLayoutService
  ) {
    this.applicationType = this.configureService.applicationType;
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.language = this.configureService.getLanguage();
    this.unified3DModel = this.umService.current_UnifiedModel;
    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
          if(this.unified3DModel.ModelInput.Geometry.SlidingDoorSystems && this.unified3DModel.ModelInput.Geometry.SlidingDoorSystems.length > 0) {
            this.isTripleBtnDisabled = true;
          } else {
            this.isTripleBtnDisabled = false;
          }
        }
      });
    
   this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
     this.getConfigGrid();
   });

    this.configureService.getGlassInfo(this.applicationType).subscribe(result => {
        this.totalGlassList = JSON.parse(result);
    });

    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.data) {
        this.isFrenchSystem = this.umService.isFrenchSystem(response.data.Description);
      }
      else {
        this.isFrenchSystem = false;
      }
      
      this.enGlassList = this.totalGlassList.filter(g => g.Glass_SystemType === 'EN');
      this.frGlassList = this.totalGlassList.filter(g => g.Glass_SystemType === 'FR');
      
      if(this.isFrenchSystem) {
         this.sortedGlassList = this.frGlassList.concat(this.enGlassList);
      } else {
         this.sortedGlassList = this.enGlassList.concat(this.frGlassList);
      }
      this.mappedGlassList = [];
      this.sortedGlassList.forEach(glass => {
        let mappedGlass = new GlassBPS();
        mappedGlass.id = glass.GlassTypeID;
        mappedGlass.category = glass.GlassType;
        mappedGlass.composition = glass.Composition;
        mappedGlass.totalThickness = glass.TotalThickness;
        mappedGlass.totalThicknessUnit = glass.TotalThicknessUnit;
        mappedGlass.uvalue = glass.U_value;
        mappedGlass.rw = glass.Rw;
        mappedGlass.spacer = glass.Spacer;
        mappedGlass.shgc = glass.Sg;
        mappedGlass.vt = glass.TLg;
        mappedGlass.stc = glass.STC;
        mappedGlass.oitc = glass.OITC;
        mappedGlass.sg1h = glass.Sg1h;
        mappedGlass.sg2h = glass.Sg2h;
        mappedGlass.sg1e = glass.Sg1e;
        mappedGlass.sg2e = glass.Sg2e;
        mappedGlass.element_xx_1 = glass.element_xx_1;
        mappedGlass.element_type_1 = glass.element_type_1;
        mappedGlass.element_size_1 = glass.element_size_1;
        mappedGlass.element_interlayer_1 = glass.element_interlayer_1;
        mappedGlass.element_ins_size_1 = glass.element_ins_size_1;
        mappedGlass.element_xx_2 = glass.element_xx_2;
        mappedGlass.element_type_2 = glass.element_type_2;
        mappedGlass.element_size_2 = glass.element_size_2;
        mappedGlass.element_interlayer_2 = glass.element_interlayer_2;
        mappedGlass.element_ins_size_2 = glass.element_ins_size_2;
        mappedGlass.element_xx_3 = glass.element_xx_3;
        mappedGlass.element_type_3 = glass.element_type_3;
        mappedGlass.element_size_3 = glass.element_size_3;
        mappedGlass.element_interlayer_3 = glass.element_interlayer_3;
        mappedGlass.Glass_SystemType = glass.Glass_SystemType;
        mappedGlass.type = glass.Type;
        mappedGlass.element_ins_type_1 = glass.element_ins_type_1;
        mappedGlass.element_ins_type_2 = glass.element_ins_type_2;
        this.mappedGlassList.push(mappedGlass);
      });
      this.doubleData = this.mappedGlassList.filter(mg => mg.category === 'double');
      this.tripleData = this.mappedGlassList.filter(mg => mg.category === 'triple');
      this.panelLibraryData = this.mappedGlassList.filter(mg => mg.category === 'panel');
    });

    if (this.permissionService.checkPermission(this.feature.GlassPanelSideTableShort)) {
      this.doubleData = this.configureService.getArticleListByType("double_SRS", this.isFrenchSystem);
      this.tripleData = this.configureService.getArticleListByType("triple_SRS", this.isFrenchSystem);
    } else if (this.permissionService.checkPermission(this.feature.GlassPanelSideTableFull)) {
     // this.doubleData = this.configureService.getArticleListByType("double_BPS", this.isFrenchSystem);
     // this.tripleData = this.configureService.getArticleListByType("triple_BPS", this.isFrenchSystem);
     // this.panelLibraryData = this.configureService.getArticleListByType("panel_BPS", this.isFrenchSystem);
    }
    this.customLibraryData = this.umService.ConvertCustomGlassData();
    this.getConfigGrid();

    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.GlassPanel) {
          this.isPopoutOpened = response.isOpened;
        }
        if (this.unified3DModel.ProblemSetting.ProductType === 'Window')
          this.isPanelBtnDisabled = true;
        else if (this.unified3DModel.ProblemSetting.ProductType === 'Facade')
          this.isPanelBtnDisabled = false;

        if (!this.isPopoutOpened) {
          this.numberOfClicks = 0;
        }
        else {
          if (this.tableComponent) {
            this.tableComponent.inputElement.nativeElement.value = '';
          }
          this.searchValue = '';
          this.search();
          //   this.onUnifiedModelUpdated();
        }
      });
  }

  getConfigGrid(action: string = "") {
    if (action == "") {
      var descriptionText = this.isPanelSelected ? this.translate.instant(_('configure.panel-description')): this.translate.instant(_('configure.glass-description'));
      this.configurationCustomGrid = {
        fields: [
          {
            celdType: CeldType.Default,
            display: descriptionText,
            property: 'description',
            width: '195px',
            showSort: true
          },
          {
            celdType: CeldType.Default,
            property: 'totalThickness',
            width: '60px',
            template: {
              ref: this.thicknessTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'uValue',
            template: {
              ref: this.uValueTemplate,
              context: {}
            },
            width: '52px',
            showSort: true
          },
          {
            celdType: CeldType.Default,
            property: 'shgc',
            width: '52px',
            template: {
              ref: this.shgcTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'vt',
            width: '52px',
            template: {
              ref: this.vtTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.TemplateRef,
            property: 'rw',
            width: '52px',
            template: {
              ref: this.rwTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'stc',
            width: '52px',
            template: {
              ref: this.stcTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'oitc',
            width: '52px',
            template: {
              ref: this.oitcTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          }
        ],
        fieldId: 'id'
      };
    }
    if (action == "NEW") {

      this.configurationCustomGrid = {
        fields: [
          {
            celdType: CeldType.Default,
            display: this.translate.instant(_('configure.glass-description')),
            property: 'description',
            width: '195px',
            showSort: true
          },
          {
            celdType: CeldType.Default,
            property: 'totalThickness',
            width: '60px',
            template: {
              ref: this.thicknessTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'uValue',
            template: {
              ref: this.uValueTemplate,
              context: {}
            },
            width: '52px',
            showSort: true
          },
          {
            celdType: CeldType.Default,
            property: 'shgc',
            width: '52px',
            template: {
              ref: this.shgcTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'vt',
            width: '52px',
            template: {
              ref: this.vtTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.TemplateRef,
            property: 'rw',
            width: '52px',
            template: {
              ref: this.rwTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'stc',
            width: '52px',
            template: {
              ref: this.stcTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          },
          {
            celdType: CeldType.Default,
            property: 'oitc',
            width: '52px',
            template: {
              ref: this.oitcTemplate,
              context: {}
            },
            showSort: true,
            disabled: false
          }
        ],
        fieldId: 'id'
      };
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  preventDefault($event: Event, index) {
    this.isEditCustom = true;
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.unified3DModel.ModelInput.Geometry.CustomGlass !== undefined && this.unified3DModel.ModelInput.Geometry.CustomGlass !== null) {
      this.SelectedCustomRow = this.unified3DModel.ModelInput.Geometry.CustomGlass[index];
      this.openNewLibraryCustom(this.SelectedCustomRow);
      setTimeout(() => {
        this.onClickNew();
      }, 200);
      
    }
  }

  onHover(index, value) {
    this.listOfDisplayData[index].rw.context.arrowHovered = value;
  }

  log($event) {
  }

  sort(sort: { sortName: string; sortValue: string }): void {
    this.sortName = sort.sortName;
    this.sortValue = sort.sortValue;
    this.search();
  }

  filter(value: string): void {
    this.searchValue = value;
    this.search();
  }

  search(): void {
    const filterFunc = (item: any) => {
      return item.description.indexOf(this.searchValue) !== -1;
    };
    const data = this.data.filter((item: { description: string; inside: string; outside: string }) =>
        filterFunc(item)
    );
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) => this.sortValue === "ascend" ? a[this.sortName!] > b[this.sortName!] ? 1 : -1 : b[this.sortName!] > a[this.sortName!] ? 1 : -1 );
      this.listOfDisplayData = this.listOfDisplayData.slice();
    } else {
      this.listOfDisplayData = data;
    }
    // this.getSelectedRow();
  }

  //#region events
  selectionChange(event) {
    if (event !== undefined && event.data !== undefined && this.unified3DModel.ModelInput.Geometry.CustomGlass !== undefined && this.unified3DModel.ModelInput.Geometry.CustomGlass !== null) {
      this.SelectedCustomRow = this.unified3DModel.ModelInput.Geometry.CustomGlass[event.data.id]; this.isEditCustomDisabled = false;
    }
  }

  onClose(): void {
    if (this.tableComponent) {
      this.tableComponent.inputElement.nativeElement.value = "";
    }
    this.searchValue = "";
    this.search();
    if (this.isPopoutOpened)
      this.cpService.setPopout(false, PanelsModule.GlassPanel);
    this.isDisplay = false;
    this.closeNdisableRightPanelEvent.emit(this.isDisplay);
  }

  onclickRow(event: any): void {
    this.selectedArticle = event.article;
    switch (this.selectedArticle.composition) {
      
      case '2-26-120-2':
        this.selectedArticle.type='aluminium-air-insulation-aluminium';
        break;
      case '6-22-120-2':
        this.selectedArticle.type='glass-air-insulation-aluminium';
        break;
      

    }
    this.selectedArticle.composition.split('-');
    let selectedCompositionArray = [];
   let  selectedCompositionList = this.selectedArticle.composition.split('-');
   if(this.selectedArticle && this.selectedArticle.Glass_SystemType === "FR") {
    let size1: any;
      let size1_first: any;
      let size1_second: any;
      let intSize1: any;
      let finalelement1: any;
      let size2: any;
      let size2_first: any;
      let size2_second: any;
      let intSize2: any;
      let finalelement2: any;
      let finalCavityElement: any;
      let size3: any;
      let size3_first: any;
      let size3_second: any;
      let intSize3: any;
      let finalelement3: any;
      let finalCavityElement2: any;
      if(this.selectedArticle.category === 'double'){
        if(this.selectedArticle.element_size_1.includes('/'))  {
          size1 = this.selectedArticle.element_size_1.split('/');
          size1_first = size1[0];
          size1_second = size1[1];
          intSize1 = parseInt(size1_first) + parseInt(size1_second);
          finalelement1 = intSize1.toString() + '(' + this.selectedArticle.element_interlayer_1.toString() + ')';
       } else {
        size1 = this.selectedArticle.element_size_1;
        finalelement1 = size1.toString();
       }
       if(this.selectedArticle.element_size_2.includes('/'))  {
        size2 = this.selectedArticle.element_size_2.split('/');
        size2_first = size2[0];
        size2_second = size2[1];
        intSize2 = parseInt(size2_first) + parseInt(size2_second);
        finalelement2 = intSize2.toString() + '(' + this.selectedArticle.element_interlayer_2.toString() + ')';
     } else {
      size2 = this.selectedArticle.element_size_2;
      finalelement2 = size2.toString();
     }
     finalCavityElement = this.selectedArticle.element_ins_size_1.toString();
     this.selectedArticle.actualComposition =  finalelement1 + '-' + finalCavityElement +  '-' + finalelement2;
     this.selectedArticle.description = finalelement1 + '-' + finalCavityElement +  '-' + finalelement2;
      }

      if(this.selectedArticle.category === 'triple'){
        if(this.selectedArticle.element_size_1.includes('/'))  {
          size1 = this.selectedArticle.element_size_1.split('/');
          size1_first = size1[0];
          size1_second = size1[1];
          intSize1 = parseInt(size1_first) + parseInt(size1_second);
          if(this.selectedArticle.element_interlayer_1 !== null && this.selectedArticle.element_interlayer_1 !== '')
          finalelement1 = intSize1.toString() + '(' + this.selectedArticle.element_interlayer_1.toString() + ')';
          else 
          finalelement1 = intSize1.toString();
       } else {
        size1 = this.selectedArticle.element_size_1;
        finalelement1 = size1.toString();
       }
       if(this.selectedArticle.element_size_2.includes('/'))  {
        size2 = this.selectedArticle.element_size_2.split('/');
        size2_first = size2[0];
        size2_second = size2[1];
        intSize2 = parseInt(size2_first) + parseInt(size2_second);
        if(this.selectedArticle.element_interlayer_2 !== null && this.selectedArticle.element_interlayer_2 !== '')
        finalelement2 = intSize2.toString() + '(' + this.selectedArticle.element_interlayer_2.toString() + ')';
        else
        finalelement2 = intSize2.toString();
     } else {
      size2 = this.selectedArticle.element_size_2;
      finalelement2 = size2.toString();
     }
     if(this.selectedArticle.element_size_3.includes('/')) {
      size3 = this.selectedArticle.element_size_3.split('/');
      size3_first = size3[0];
      size3_second = size3[1];
      intSize3 = parseInt(size3_first) + parseInt(size3_second);
      if(this.selectedArticle.element_interlayer_3 !== null && this.selectedArticle.element_interlayer_3 !== '')
        finalelement3 = intSize3.toString() + '(' + this.selectedArticle.element_interlayer_3.toString() + ')';
        else
        finalelement3 = intSize3.toString();
     }else {
      size3 = this.selectedArticle.element_size_3;
      finalelement3 = size3.toString();
     }
     finalCavityElement = this.selectedArticle.element_ins_size_1.toString();
     finalCavityElement2 = this.selectedArticle.element_ins_size_2.toString();
     this.selectedArticle.actualComposition =  finalelement1 + '-' + finalCavityElement +  '-' + finalelement2 + '-' + finalCavityElement2 + '-' + finalelement3;
     this.selectedArticle.description = finalelement1 + '-' + finalCavityElement +  '-' + finalelement2 + '-' + finalCavityElement2 + '-' + finalelement3;
      }
      
  } else {
    selectedCompositionList.forEach((ele, index) => {
      let val = ele.match(/[\d\.]+/g)[0].trim();
     // let val = ele.replace(/\D/g, '').trim();
      selectedCompositionArray.push(val);
      });
      this.selectedArticle.description = selectedCompositionArray.join('-');
      this.selectedArticle.actualComposition = selectedCompositionArray.join('-');
  }
   
    this.selectedArticle.idArticle = this.glazingSystemsId;
    if (this.selectedArticle && this.currentArticle && this.currentArticle.id && this.currentArticle.id == this.selectedArticle.id && this.currentArticle.category == this.selectedArticle.category ) {
      this.isConfirmBtnDisabled = true;
    }
    else {
      this.isConfirmBtnDisabled = false;
    }

    // if(this.unified3DModel.ProblemSetting.ProductType=== 'Facade' && this.unified3DModel.ProblemSetting.FacadeType==='mullion-transom') {
    //   if(this.selectedArticle && this.selectedArticle.category === 'panel' && this.maxAllowedThickness < this.selectedArticle.totalThickness && !this.displayednotification) {
    //     this.ngNotificaionShow.next({ title: this.translate.instant(_('notification.panel-depth')), message: this.translate.instant(_('notification.panel-depth-message')), logoToShow: 'incorrectPanelThicknessWarning' });
    //     this.displayednotification = true;
    //     //this.isConfirmDisabled = true;
    //   } 
    // } 
    
  }

  ondblclickRow(event) {
    this.tableComponent.selectRow(event);
    this.onConfirm();
  }

  onConfirm() {
    this.umService.set_GlassPanel(this.selectedArticle);
    this.fromOldTable = null;
    this.cpService.setConfirm(this.selectedArticle, PanelsModule.GlassPanel);
    this.cpService.setPopout(false, PanelsModule.GlassPanel);
    this.customLibraryData = this.umService.ConvertCustomGlassData();
    this.onClose();
    this.headerSelected = false;
  }
  //#endregion

  onUnifiedModelUpdated() {
    if (this.unified3DModel && this.unified3DModel.ModelInput.Geometry.FacadeSections && this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(f => f.SectionID === 5 && f.SectionType === 5)) {
      const mullion = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(f => f.SectionID === 1 && f.SectionType === 4)[0];
      const transom = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(f => f.SectionID === 2 && f.SectionType === 5)[0];
      if (mullion) {
        this.framingService.getFacade_TransomList(this.unified3DModel.ModelInput.FrameSystem.SystemType, mullion.ArticleName, this.umService.IsIntermediateMullionApplied(), mullion.Depth, this.configureService.applicationType).pipe(takeUntil(this.destroy$)).subscribe((data: any[]) => {
          if (transom) {
            var aSelected = data.filter(f => f.transomArticleId.toString() === transom.ArticleName)[0];
            if (aSelected)
              this.maxAllowedThickness = data.filter(f => f.transomArticleId.toString() === transom.ArticleName)[0].transomDepth;
            else
              this.maxAllowedThickness = data[data.length - 1].transomDepth;
            if (this.selectedArticle)
              switch (this.selectedType) {
                case "double":
                  this.onSetDoubleGlassTypesForSystem();
                  break;
                case "triple":
                  this.onSetTripleGlassTypesForSystem();
                  break;
                case "panel":
                  this.onSetPanelLibraryGlassTypesForSystem();
                  break;
                case "new":
                  this.onClickNew();
                  break;
              }
          }
        });
      }
    }
  }
  
  isCustomRowValid(uvalue: string, rw: string, index: number, selectedRow: any): boolean {
    if (selectedRow && index.toString() === selectedRow.id && (uvalue === "--" || rw === "--" || uvalue === "" || rw === ""))
      return false;
    else
      return true;
  }

  loadData(): void {
    this.data = [];
    if (this.ArticlesForSystem !== undefined) {
      //let selectedRow = this.getSelectedRow();
      if (this.permissionService.checkPermission(this.feature.GlassPanelSideTableShort)) {
        let nodeRow = {
          title: '/assets/Images/spacer-type/vitroarchitecturalglass_enabled.svg',
          key: '100',
          children: [
          ]
        };

        this.ArticlesForSystem.forEach((article, index) => {
          article.rw = article.rw ? article.rw : "--";
          let compositions = article.composition.split('-');         
          compositions = compositions.join('-');
          let desc: string;
          desc = compositions;
          // if (article.thicknessFraction) {
          //   desc = article.totalThickness.toString() + ' ' + article.thicknessFraction + ' ' + article.totalThicknessUnit + '    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;(' + compositions + ')';
          // } else
          //   desc = article.totalThickness.toString() + ' ' + article.totalThicknessUnit + '    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;(' + compositions + ')';
          this.data.push({
            id: '' + index,
            dataId: '' + (index + 1),
            description: desc,
            uValue: article.uvalue ? article.uvalue : "--",
            //disabled: (this.unified3DModel.ProblemSetting.ProductType == 'Facade' && this.unified3DModel.ProblemSetting.FacadeType == 'mullion-transom') ? (this.maxAllowedThickness > article.totalThickness) ? false : true : false,
            disabled: false,
            article: article,
            rw: {
              ref: this.outsideTemplate,
              context: {
                value: article.rw,
                disabled: false,
                arrowHovered: false,
                index: index,
                validData: true
              }
            },
            bpsTable4CustomRow: this.selectedType === "new" ? true : false,
            shgc: article.shgc ? article.shgc : "--",
            vt: article.vt ? article.vt : "--",
            stc: article.stc ? article.stc : "--",
            oitc: article.oitc ? article.oitc : "--",
            thickness: article.thickness ? article.thickness : "--"
          });
          nodeRow.children.push({ 'title': desc, 'key': article.id, 'isLeaf': true });

        });
        this.nodes = [
          nodeRow
        ];
        this.defaultExpandedKeys = [];
        let selectedChild;
        this.defaultSelectedKeys.forEach(key => {
          selectedChild = this.nodes.filter(node => node.children.map(child => parseInt(child.key)).includes(parseInt(key)));
          if (selectedChild && selectedChild.length == 1)
            this.defaultExpandedKeys.push(this.nodes.filter(node => node.children.map(child => parseInt(child.key)).includes(parseInt(key)))[0].key);
        });
        if (!selectedChild || selectedChild == 0) {
          this.defaultSelectedKeys = ['1'];
          this.defaultSelectedKeys.forEach(key => {
            selectedChild = this.nodes.filter(node => node.children.map(child => parseInt(child.key)).includes(parseInt(key)));
            if (selectedChild && selectedChild.length == 1)
              this.defaultExpandedKeys.push(this.nodes.filter(node => node.children.map(child => parseInt(child.key)).includes(parseInt(key)))[0].key);
          });
        }
      } else if (this.permissionService.checkPermission(this.feature.GlassPanelSideTableFull)) {
        this.ArticlesForSystem.forEach((article, index) => {
          article.rw = article.rw ? article.rw : "--";
          let compositions = article.composition.split('-');
          if(article.Glass_SystemType === "EN"){
            for (let i = 0; i < compositions.length; i++) {
              if (compositions[i].includes('PVB')) {
                let compositionParts = compositions[i].split('(');
                if (compositionParts && compositionParts.length > 0 && compositionParts[1]) {
                  compositions[i] = compositionParts[0] + '[' + compositionParts[1].match(/\d.\d+/g) + ']';
                }
              }
            }
            compositions = compositions.join('-');
          } else {
            let comArr = [];
            compositions.forEach(com => {
              if(com.includes('(')) {
                comArr.push(com.split('(')[0])
              } else{
                comArr.push(com);
              }
            });
            compositions = comArr.join('-');
          }
          // for (let i = 0; i < compositions.length; i++) {
          //   if (compositions[i].includes('PVB')) {
          //     let compositionParts = compositions[i].split('(');
          //     if (compositionParts && compositionParts.length > 0 && compositionParts[1]) {
          //       compositions[i] = compositionParts[0] + '[' + compositionParts[1].match(/\d.\d+/g) + ']';
          //     }
          //   }
            
          // }

          // compositions = compositions.join('-');
          
          this.data.push({
            id: '' + index,
            dataId: '' + (index + 1),
           // description: article.totalThickness.toString() + ' ' + article.totalThicknessUnit + '    (' + compositions + ')',
            description: compositions,
            uValue: article.uvalue ? article.uvalue : "--",
            //rw: article.rw,
            //disabled: (this.unified3DModel.ProblemSetting.ProductType == 'Facade' && this.unified3DModel.ProblemSetting.FacadeType == 'mullion-transom') ? (this.maxAllowedThickness > article.totalThickness) ? false : true : false,
            disabled: false,
            article: article,
            rw: {
              ref: this.outsideTemplate,
              context: {
                value: article.rw,
                disabled: false,
                arrowHovered: false,
                index: index,
                validData: true //this.isCustomRowValid(article.uvalue, article.rw, index, selectedRow)
              }
            },
            bpsTable4CustomRow: this.selectedType === "new" ? true : false,
            shgc: article.shgc ? article.shgc : "--",
            AbsorptionCoefficient: article.AbsorptionCoefficient,
            vt: article.vt ? article.vt : "--",
            stc: article.stc ? article.stc : "--",
            oitc: article.oitc ? article.oitc : "--",
            totalThickness: article.totalThickness ? article.totalThickness : "--"
          });
        });
      }
    }
  }

  getSelectedRow(): any {   
    let selectedRow;
    if (this.fromOldTable) {
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.fromOldTable.composition)[0];
    } else{
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition
        && row.article.rw == this.selectedArticle.rw && row.article.uValue == this.selectedArticle.uValue)[0];
      }

    if (!selectedRow && this.isCustomArticleUpdated && this.SelectedCustomRow) {
      selectedRow = this.listOfDisplayData[0];
      this.isCustomArticleUpdated = false;
    }
    this.fromOldTable = null;
    if (selectedRow) {
      setTimeout(() => {
        this.selectedChildNodeKey = selectedRow.article.id;
        if(this.nodes !== undefined && this.nodes !== null) {
          this.Click(this.nodes[this.selectedChildNodeKey-1]);
        }
        
      });
      //  this.selectRow(selectedRow);
    } else {
      setTimeout(() => { this.selectedChildNodeKey = -1; });      
    }
  }

  glazingSystemsId = undefined;
  ngOnChanges(Changes: SimpleChanges) {
    //this.onUnifiedModelUpdated();
  }

  fromOldTable: any;
  //Double Glass
  onSetDoubleGlassTypesForSystem(): void { 
    this.numberOfClicks = 0;
    this.isConfirmBtnDisabled = true;
    this.ArticlesForSystem = this.doubleData;
    this.loadData();
    this.listOfDisplayData = this.data.slice();
    let selectedRow;
    if (this.fromOldTable) {
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.fromOldTable.composition)[0];
    } else{
      if(this.selectedArticle.stc !== -1 && this.selectedArticle.oitc !== -1 && this.selectedArticle.stc !== 0 && this.selectedArticle.oitc !== 0) {
        this.listOfDisplayData.forEach((row, index) => {
          if(row.article.Glass_SystemType === 'FR'){
            let size1: any;
            let size1_first: any;
            let size1_second: any;
            let intSize1: any;
            let finalelement1: any;
            let size2: any;
            let size2_first: any;
            let size2_second: any;
            let intSize2: any;
            let finalelement2: any;
            let finalCavityElement: any;
            let acomp: any;
            if(row.article.element_size_1.includes('/'))  {
              size1 = row.article.element_size_1.split('/');
              size1_first = size1[0];
              size1_second = size1[1];
              intSize1 = parseInt(size1_first) + parseInt(size1_second);
              if(intSize1)
                finalelement1 = intSize1.toString() + '(' + row.article.element_interlayer_1.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
           } else {
            size1 = row.article.element_size_1;
            if(size1)
              finalelement1 = size1.toString();
           }
           if(row.article.element_size_2.includes('/'))  {
            size2 = row.article.element_size_2.split('/');
            size2_first = size2[0];
            size2_second = size2[1];
            intSize2 = parseInt(size2_first) + parseInt(size2_second);
            if(intSize2)
              finalelement2 = intSize2.toString() + '(' + row.article.element_interlayer_2.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
         } else {
          size2 = row.article.element_size_2;
          if(size2)
            finalelement2 = size2.toString();
         }
         if(row.article.element_ins_size_1)
          finalCavityElement = row.article.element_ins_size_1.toString();
          acomp =  finalelement1 + '-' + finalCavityElement +  '-' + finalelement2;
          if(acomp == this.selectedArticle.composition) {
            selectedRow = this.listOfDisplayData[index];
            return;
          }
          
          } else {
            selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition)[0];
          }
        });
      } else {
        selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition)[0];
      }
     
    }
      
    this.fromOldTable = null;
    setTimeout(() => {
      if (this.configureService.applicationType == "SRS") {
        if (!selectedRow)
          selectedRow = this.listOfDisplayData[0];
        if (selectedRow && selectedRow.article)
          this.defaultSelectedKeys = [selectedRow.article.id];
          this.selectedChildNodeKey = selectedRow.article.id;
      } else {
        if (selectedRow && this.tableComponent) {
          this.tableComponent.selectRow(selectedRow);
        }
        else if (this.tableComponent) {
          this.tableComponent.checkboxCache.forEach(article => {
            article.selected = false;
          });
          this.listOfDisplayData = [];
          setTimeout(() => {
            this.listOfDisplayData = this.data.slice();
            if (!selectedRow) {
              this.fromOldTable = this.selectedArticle;
              setTimeout(() => {
                this.tableComponent.selectRow(this.listOfDisplayData[0]);
              });
            }
          });
        }
      }
    });
  }

  //Triple Glass
  onSetTripleGlassTypesForSystem(): void {
    this.numberOfClicks = 0;
    this.isConfirmBtnDisabled = true;
    this.ArticlesForSystem = this.tripleData;
    this.loadData();
    this.listOfDisplayData = this.data.slice();
    let selectedRow;
    if (this.fromOldTable) {
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.fromOldTable.composition)[0];
    } else{
      if(this.selectedArticle.stc !== -1 && this.selectedArticle.oitc !== -1) {
        this.listOfDisplayData.forEach((row, index) => {
          if(row.article.Glass_SystemType === 'FR'){
            let size1: any;
            let size1_first: any;
            let size1_second: any;
            let intSize1: any;
            let finalelement1: any;
            let size2: any;
            let size2_first: any;
            let size2_second: any;
            let intSize2: any;
            let finalelement2: any;
            let finalCavityElement: any;
            let acomp: any;
            let size3: any;
            let size3_first: any;
            let size3_second: any;
            let intSize3: any;
            let finalelement3: any;
            let finalCavityElement2: any;
            if(row.article.element_size_1.includes('/'))  {
              size1 = row.article.element_size_1.split('/');
              size1_first = size1[0];
              size1_second = size1[1];
              intSize1 = parseInt(size1_first) + parseInt(size1_second);
              if(intSize1)
                finalelement1 = intSize1.toString() + '(' + row.article.element_interlayer_1.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
           } else {
            size1 = row.article.element_size_1;
            if(size1)
              finalelement1 = size1.toString();
           }
           if(row.article.element_size_2.includes('/'))  {
            size2 = row.article.element_size_2.split('/');
            size2_first = size2[0];
            size2_second = size2[1];
            intSize2 = parseInt(size2_first) + parseInt(size2_second);
            if(intSize2)
              finalelement2 = intSize2.toString() + '(' + row.article.element_interlayer_2.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
         } else {
          size2 = row.article.element_size_2;
          if(size2)
            finalelement2 = size2.toString();
         }
         if(row.article.element_size_3.includes('/'))  {
          size3 = row.article.element_size_3.split('/');
          size3_first = size3[0];
          size3_second = size3[1];
          intSize3 = parseInt(size3_first) + parseInt(size3_second);
          if(intSize3)
            finalelement3 = intSize3.toString() + '(' + row.article.element_interlayer_3.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
       } else {
        size3 = row.article.element_size_3;
        if(size3)
          finalelement3 = size3.toString();
       }
        if(row.article.element_ins_size_1)
          finalCavityElement = row.article.element_ins_size_1.toString();
        if(row.article.element_ins_size_2)
         finalCavityElement2 = row.article.element_ins_size_2.toString();
         acomp =  finalelement1 + '-' + finalCavityElement +  '-' + finalelement2 + '-' + finalCavityElement2 + '-' + finalelement3;
         if(acomp == this.selectedArticle.composition && row.article.totalThickness === this.selectedArticle.totalThickness && row.article.uvalue === this.selectedArticle.uvalue) {
          selectedRow = this.listOfDisplayData[index];
          return;
        }
          
          } 
        });
      } else {
        selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition)[0];
      }
    }
      
    this.fromOldTable = null;
    setTimeout(() => {
      if (this.configureService.applicationType == "SRS") {
        if (!selectedRow)
          selectedRow = this.listOfDisplayData[0];
        if (selectedRow && selectedRow.article)
          this.defaultSelectedKeys = [selectedRow.article.id];
        this.selectedChildNodeKey = selectedRow.article.id;
      } else {
        if (selectedRow && this.tableComponent) {
          this.tableComponent.selectRow(selectedRow);
        }
        else if (this.tableComponent) {
          this.tableComponent.checkboxCache.forEach(article => {
            article.selected = false;
          });
          this.listOfDisplayData = [];
          setTimeout(() => {
            this.listOfDisplayData = this.data.slice();
            if (!selectedRow) {
              this.fromOldTable = this.selectedArticle;
              setTimeout(() => {
                this.tableComponent.selectRow(this.listOfDisplayData[0]);
              });
            }
          });
        }
      }

    });
  }

  //Panel Library Glass
  onSetPanelLibraryGlassTypesForSystem(): void {
    this.numberOfClicks = 0;
    this.isConfirmBtnDisabled = true;
    this.ArticlesForSystem = this.panelLibraryData;
    this.loadData();
    this.listOfDisplayData = this.data.slice();

    let selectedRow;
    if (this.fromOldTable) {
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.fromOldTable.composition)[0];
    } else
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition)[0];
    this.fromOldTable = null;

    setTimeout(() => {
      if (selectedRow && this.tableComponent) {
        this.tableComponent.selectRow(selectedRow);
      }
      else if (this.tableComponent) {
        this.tableComponent.checkboxCache.forEach(article => {
          article.selected = false;
        });
        this.listOfDisplayData = [];
        setTimeout(() => {
          this.listOfDisplayData = this.data.slice();
          if (!selectedRow) {
            this.fromOldTable = this.selectedArticle;
            setTimeout(() => {
              this.tableComponent.selectRow(this.listOfDisplayData[0]);
            });
          }
        });
      }
    });
  }

  //Custom Library
  onSetCustomLibraryTypesForSystem(): void {
    this.numberOfClicks = 0;
    this.isConfirmBtnDisabled = true;
    this.ArticlesForSystem = this.customLibraryData;
    this.loadData();
    this.listOfDisplayData = this.data.slice();
    let selectedRow;
    if (this.fromOldTable) {
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.fromOldTable.composition)[0];
    } else
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition)[0];
    this.fromOldTable = null;
    setTimeout(() => {
      //SRS
      if (this.configureService.applicationType == "SRS") {
        if (!selectedRow)
          selectedRow = this.listOfDisplayData[0];
        if (selectedRow && selectedRow.article)
          this.defaultSelectedKeys = [selectedRow.article.id];
        this.selectedChildNodeKey = selectedRow.article.id;
      }
      // BPS
      else {
        if (selectedRow && this.tableComponent) {
          this.tableComponent.selectRow(selectedRow);
        }
        else if (this.tableComponent) {
          this.tableComponent.checkboxCache.forEach(article => {
            article.selected = false;
          });
          this.listOfDisplayData = [];
          setTimeout(() => {
            this.listOfDisplayData = this.data.slice();
            if (!selectedRow && this.listOfDisplayData.length > 0) {
              this.fromOldTable = this.selectedArticle;
              setTimeout(() => {
                this.tableComponent.selectRow(this.listOfDisplayData[0]);
              });
            }
          });
        }
      }

    });
  }

  onOpenCustom(): void {
    this.openNewCustomEvent.emit(this.selectedArticle);
  }
  onOpenEditCustom(): void {
    this.openNewCustomEvent.emit(this.SelectedCustomRow);
  }

  onEditCustom(index: number): void {
    this.editCustomArticleEvent.emit({ article: this.listOfDisplayData[index], index: index });
  }
  SelectedCustomRow: any;
  onClickNew() {
    this.getConfigGrid("NEW");
    this.numberOfClicks = 0;
    this.isConfirmBtnDisabled = true;
    this.ArticlesForSystem = this.customLibraryData;
    this.loadData();
    this.listOfDisplayData = this.data.slice();

    let selectedRow;
    if (this.fromOldTable) {
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.fromOldTable.composition)[0];
    } else
      selectedRow = this.listOfDisplayData.filter(row => row.article.composition == this.selectedArticle.composition
        && row.article.rw == this.selectedArticle.rw && row.article.uValue == this.selectedArticle.uValue)[0];
    if (!selectedRow && this.isCustomArticleUpdated && this.SelectedCustomRow) {
      selectedRow = this.listOfDisplayData[this.SelectedCustomRow.customGlassID];
      this.isCustomArticleUpdated = false;
    }

    this.fromOldTable = null;
    if (selectedRow) {
      if (this.unified3DModel.ModelInput.Geometry.CustomGlass !== undefined && this.unified3DModel.ModelInput.Geometry.CustomGlass !== null) {
        this.isEditCustomDisabled = false;
        this.SelectedCustomRow = this.unified3DModel.ModelInput.Geometry.CustomGlass[selectedRow.id];
      }
    }
    setTimeout(() => {
      if (selectedRow && this.tableComponent && this.listOfDisplayData.length > 0) {
        this.tableComponent.selectRow(selectedRow);
      }
      else if (this.tableComponent && this.listOfDisplayData.length > 0) {
        this.tableComponent.checkboxCache.forEach(article => {
          article.selected = false;
        });
        this.listOfDisplayData = [];
        setTimeout(() => {
          this.listOfDisplayData = this.data.slice();
          if (!selectedRow) {
            this.fromOldTable = this.selectedArticle;
            setTimeout(() => {
              this.tableComponent.selectRow(this.listOfDisplayData[0]);
            });
          }
        });
      }
    });
  }
  
  isPanelSelected : boolean = false;
  onNewTypeSelection(type: string) {
    this.isPanelSelected = type == 'panel'? true : false;
    this.getConfigGrid();
    if (this.selectedType !== type) {
      this.selectedType = type;
      switch (this.selectedType) {
        case "double":
          this.cpService.setcustomLibraryrow({article: null, selectedType: this.selectedType, isEditCustom: this.isEditCustom});
          this.onSetDoubleGlassTypesForSystem();
          break;
        case "triple":
          this.cpService.setcustomLibraryrow({article: null, selectedType: this.selectedType, isEditCustom: this.isEditCustom});
          this.onSetTripleGlassTypesForSystem();
          break;
        case "panel":
          this.cpService.setcustomLibraryrow({article: null, selectedType: this.selectedType, isEditCustom: this.isEditCustom});
          this.onSetPanelLibraryGlassTypesForSystem();
          break;
        case "new":
          this.onClickNew();
          this.onOpenCustom();
          break;
        case "newGlassAndPanel":
          this.isEditCustom = false;
          this.cpService.setcustomLibraryrow({article: null, selectedType: this.selectedType, isEditCustom: this.isEditCustom});
          this.onOpenCustom();
          this.openNewLibraryCustom(null)
          this.onClickNew();
          break;
        case "customLibrary":
          this.cpService.setcustomLibraryrow({article: null, selectedType: undefined, isEditCustom: undefined});
          setTimeout(() => {
            this.onSetCustomLibraryTypesForSystem();  
          }, 100);
          break;
      }
      this.getSelectedRow();
      this.search();
    } else {
      switch (this.selectedType) {
        case "customLibrary":
          if(this.isEditCustom)
          this.isEditCustom = false;
          this.cpService.setcustomLibraryrow({article: null, selectedType: this.selectedType, isEditCustom: this.isEditCustom});
      }
    }
  }

  onNewLayerTypeSelection(type: string) {
    this.getConfigGrid();
    if (this.selectedLayerType !== type) {
      this.selectedLayerType = type;
      switch (this.selectedLayerType) {
        case "double":
          //this.onSetDoubleGlassTypesForSystem();
          break;
        case "triple":
          //this.onSetTripleGlassTypesForSystem();
          break;
        case "panel":
          //this.onSetPanelLibraryGlassTypesForSystem();
          break;
        case "new":
          //this.onClickNew();
          break;
      }
      this.getSelectedRow();
      this.search();
    }
  }

  selectRow(article: any) {
    this.isDisplay = true;
    this.closeNdisableRightPanelEvent.emit(this.isDisplay);
    // SRS
    if (this.configureService.applicationType == "SRS") {
      let articleData = this.doubleData.filter(f => f.composition == article.article.composition);
      if (articleData.length === 0)
        articleData = this.tripleData.filter(f => f.composition == article.article.composition);
      this.selectedArticle = articleData[0];
    }
    // BPS
    else { 
      this.selectedArticle = article.article;
      this.selectedArticle.description = this.selectedArticle.composition;
    }
    this.glazingSystemsId = this.selectedArticle.idArticle;
    if ((article.article.category == undefined || article.article.category === 'double')) {
      if(article.article.stc !== -1 && article.article.oitc !== -1) {
        let acomp: any;
        this.doubleData.forEach((dd)=> {
          if(dd.Glass_SystemType === 'FR'){
            let size1: any;
            let size1_first: any;
            let size1_second: any;
            let intSize1: any;
            let finalelement1: any;
            let size2: any;
            let size2_first: any;
            let size2_second: any;
            let intSize2: any;
            let finalelement2: any;
            let finalCavityElement: any;
            let acomp: any;
            if(dd.element_size_1.includes('/'))  {
              size1 = dd.element_size_1.split('/');
              size1_first = size1[0];
              size1_second = size1[1];
              intSize1 = parseInt(size1_first) + parseInt(size1_second);
              if(intSize1)
                finalelement1 = intSize1.toString() + '(' + dd.element_interlayer_1.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
           } else {
            size1 = dd.element_size_1;
            if(size1)
              finalelement1 = size1.toString();
           }
           if(dd.element_size_2.includes('/'))  {
            size2 = dd.element_size_2.split('/');
            size2_first = size2[0];
            size2_second = size2[1];
            intSize2 = parseInt(size2_first) + parseInt(size2_second);
            if(intSize2)
              finalelement2 = intSize2.toString() + '(' + dd.element_interlayer_2.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
         } else {
          size2 = dd.element_size_2;
          if(size2)
            finalelement2 = size2.toString();
         }
         if(dd.element_ins_size_1) 
          finalCavityElement = dd.element_ins_size_1.toString();

          acomp =  finalelement1 + '-' + finalCavityElement +  '-' + finalelement2;
          if(acomp === article.article.composition) {
            this.selectedType = "double";
            this.onSetDoubleGlassTypesForSystem();
            return;
          }
        
          } else {
            if(this.doubleData.map(article => article.composition).includes(article.article.composition)) {
              this.selectedType = "double";
              this.onSetDoubleGlassTypesForSystem();
            }
          }
        });
       
      } else {
        if(this.doubleData.map(article => article.composition).includes(article.article.composition)) {
          this.selectedType = "double";
          this.onSetDoubleGlassTypesForSystem();
        }
      }
      
      // this.selectedType = "double";
      // this.onSetDoubleGlassTypesForSystem();
    }
    else if ((article.article.category == undefined || article.article.category === 'triple')) {
      if(article.article.stc !== -1 && article.article.oitc !== -1) {
        let acomp: any;
        this.tripleData.forEach((td) => {
          if(td.Glass_SystemType === 'FR') {
            let size1: any;
            let size1_first: any;
            let size1_second: any;
            let intSize1: any;
            let finalelement1: any;
            let size2: any;
            let size2_first: any;
            let size2_second: any;
            let intSize2: any;
            let finalelement2: any;
            let finalCavityElement: any;
            let acomp: any;
            let size3: any;
            let size3_first: any;
            let size3_second: any;
            let intSize3: any;
            let finalelement3: any;
            let finalCavityElement2: any;
            if(td.element_size_1.includes('/'))  {
              size1 = td.element_size_1.split('/');
              size1_first = size1[0];
              size1_second = size1[1];
              intSize1 = parseInt(size1_first) + parseInt(size1_second);
              if(intSize1)
                finalelement1 = intSize1.toString() + '(' + td.element_interlayer_1.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
           } else {
            size1 = td.element_size_1;
            if(size1)
              finalelement1 = size1.toString();
           }
           if(td.element_size_2.includes('/'))  {
            size2 = td.element_size_2.split('/');
            size2_first = size2[0];
            size2_second = size2[1];
            intSize2 = parseInt(size2_first) + parseInt(size2_second);
            if(intSize2)
              finalelement2 = intSize2.toString() + '(' + td.element_interlayer_2.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
         } else {
          size2 = td.element_size_2;
          if(size2)
            finalelement2 = size2.toString();
         }
         if(td.element_size_3.includes('/'))  {
          size3 = td.element_size_3.split('/');
          size3_first = size3[0];
          size3_second = size3[1];
          intSize3 = parseInt(size3_first) + parseInt(size3_second);
          if(intSize3)
            finalelement3 = intSize3.toString() + '(' + td.element_interlayer_3.toString() + 'mm ' +  this.translate.instant(_('configure.acoustic')) +  ' PVB)';
       } else {
        size3 = td.element_size_3;
        if(size3)
          finalelement3 = size3.toString();
       }
        if(td.element_ins_size_1)
          finalCavityElement = td.element_ins_size_1.toString();
        if(td.element_ins_size_2)
         finalCavityElement2 = td.element_ins_size_2.toString();
         acomp =  finalelement1 + '-' + finalCavityElement +  '-' + finalelement2 + '-' + finalCavityElement2 + '-' + finalelement3;
         if(acomp === article.article.composition  && td.totalThickness === article.article.totalThickness && td.uvalue === article.article.uvalue) {
          this.selectedType = "triple";
          this.onSetTripleGlassTypesForSystem();
          return;
        }
          }
        });
      } else {
        if(this.tripleData.map(article => article.composition).includes(article.article.composition)) {
          this.selectedType = "triple";
          this.onSetTripleGlassTypesForSystem();
        }
      }
      // this.selectedType = "triple";
      // this.onSetTripleGlassTypesForSystem();
    }
    else if ((article.article.category == undefined || article.article.category === 'panel') && this.panelLibraryData.map(article => article.composition).includes(article.article.composition)) {
      this.selectedType = "panel";
      this.configurationCustomGrid.fields[0].display = this.translate.instant(_('configure.panel-description'));
      this.onSetPanelLibraryGlassTypesForSystem();
    }
    else {
      this.customLibraryData = this.umService.ConvertCustomGlassData();
      this.selectedType = "customLibrary";
      if (this.customLibraryData === undefined) this.customLibraryData = [];
      this.customLibraryData = this.umService.ConvertCustomGlassData();
      //this.customLibraryData.push(article.article);
      this.onNewTypeSelection('customLibrary');
      this.onSetCustomLibraryTypesForSystem();
    }
  }

  bpsClick(event: NzFormatEmitEvent): void {
    if (event && event.node && event.node.isLeaf) {
      let articles = this.selectedType == 'double' ? this.doubleData.filter(x => x.id == event.node.key) :
        (this.selectedType == 'triple' ? this.tripleData.filter(x => x.id == event.node.key) : null);
      if (articles && articles.length == 1) {
        this.selectedArticle = articles[0];
        this.defaultSelectedKeys = event.keys;
        // this.disableConfirmBtn = this.selectedArticle[0].level == 0;
        this.isConfirmBtnDisabled = false;
      }
    } else this.isConfirmBtnDisabled = true;
  }

  onDblClickSRS(node: any, level: number) {
    if (level > 0) {
      this.bpsClick(node);
      this.onConfirm();
    }
  }

  Click(node: any): void {
    this.headerSelected = false;
    if (node && node.isLeaf) {
      let articles = this.selectedType == 'double' ? this.doubleData.filter(x => x.id == node.key) :
        (this.selectedType == 'triple' ? this.tripleData.filter(x => x.id == node.key) : null);
      this.selectedChildNodeKey = node.key;
      if (articles && articles.length == 1) {
        this.selectedArticle = articles[0];
        // this.defaultSelectedKeys = node.keys;
        if (this.defaultSelectedKeys === undefined || this.defaultSelectedKeys === null || this.defaultSelectedKeys.length == 0) {
          this.defaultSelectedKeys = [];
          this.defaultSelectedKeys.push(node.key)
        }
        this.isConfirmBtnDisabled = false;
      }
    } else this.isConfirmBtnDisabled = true;
  }

  activeChange(event) {
    if (event === false) {
      this.selectedHeader = true;
    } else {
      this.selectedHeader = false;
    }
    this.headerSelected = true;
    if (event) {
      this.getSelectedRow();
    }
  }

  onDelete() {
    this.deleteLibraryCustomArticle("article");
  }

  deleteLibraryCustomArticle(articleIndex) {
    this.unified3DModel.ModelInput.Geometry.CustomGlass.splice(this.SelectedCustomRow.customGlassID, 1);
    if(this.unified3DModel.ModelInput.Geometry.CustomGlass.length === 0) {
      let value = "1.1"; 
      let uvalueByCulture = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(value);
      let articleselected = { composition: "4-16-4", type: "glass-argon-glass", totalThickness: 24, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 31, spacer: "0", category: "double", Glass_SystemType:"EN", article: {composition: "4-16-4", type: "glass-argon-glass", totalThickness: 24, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 31, spacer: "0", category: "double", Glass_SystemType:"EN"} };
      
      this.selectRow(articleselected)
      this.umService.set_GlassPanel(articleselected);
      this.cpService.setConfirm(articleselected, PanelsModule.GlassPanel);
      this.isDisplay = false;
      this.closeNdisableRightPanelEvent.emit(this.isDisplay);
    }
    //this.unified3DModel.ModelInput.Geometry.CustomGlass.splice(articleIndex, 1);
    this.customLibraryData = this.umService.ConvertCustomGlassData();
    this.isEditCustomDisabled = false;
    this.onClickNew();
  }

  addLibraryCustomArticle(sectionElement) {
    if (this.customLibraryData === undefined) this.customLibraryData = [];
    if(sectionElement.category === 'custom-panel') {
      this.customLibraryData = this.umService.ConvertCustomPanelData(sectionElement);
    }
    this.customLibraryData = this.umService.ConvertCustomGlassData();
    this.onClickNew();
    this.onNewTypeSelection('customLibrary');
  }

  isCustomArticleUpdated = false;
  updateLibraryCustomArticle(data) {
    this.customLibraryData = this.umService.ConvertCustomGlassData();
    this.isEditCustomDisabled = false;
    this.isCustomArticleUpdated = true;
    this.onClickNew();
    this.onNewTypeSelection('customLibrary');
  }

  openNewLibraryCustom(article: any) {
    let sArticle = article;
    this.cpService.setcustomLibraryrow({article: sArticle, selectedType: this.selectedType, isEditCustom: this.isEditCustom});
  }

}
