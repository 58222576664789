import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { ValidatePanelsService } from 'src/app/app-structural/services/validate-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { Structural } from 'src/app/app-common/models/unified-model/Structural';
import { DinWindLoadInput } from 'src/app/app-common/models/unified-model/DinWindLoadInput';
import { NFWindLoadInput } from 'src/app/app-common/models/unified-model/NFWindLoadInput';
import { NFSimplifiedWindLoadInput } from 'src/app/app-common/models/unified-model/NFSimplifiedWindLoadInput';

@Component({
  selector: 'app-structural-table',
  templateUrl: './structural-table.component.html',
  styleUrls: ['./structural-table.component.css']
})
export class StructuralTableComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject<void>();
  //Rama: V3
  isStructuralTableOpened: boolean;

  validateForm: FormGroup;
  @Input() unified3DModel: UnifiedModel = null;
  @Input() sendFormDataEvent: EventEmitter<any>;
  @Input() windLoad_tableModel: any;
  @Output() validStructuralEvent: EventEmitter<any> = new EventEmitter();
  @Output() sendPressureValueEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openLeftTableEvent: EventEmitter<void> = new EventEmitter<void>();

  isValid: boolean = false;
  windLoadOption: string;
  windowZone: string;
  pemissableDeflectionInput: string = "1";  // keep
  terrainCategory: string;
  windLoad: number = 0.96;
  // horizontalIndex: number = 175;
  // verticalIndex: number = 360;
  postcodeValue: string;
  buildingWidth: number;
  buildingDepth: number;
  buildingHeight: number;
  windowElevation: number;
  windLoadSelectedText: string = 'User Define';
  packVelocityPressureString: string = "0.96";
  suctionString: string = "-1";
  pressureString: string = "1";

  //Need to remove this approach in next release
  dispIndexType_obs: number;
  windLoadSelectedText_obs: string = 'User Define';
  packVelocityPressureString_obs: string = "0.96";
  suctionString_obs: string = "-1";
  pressureString_obs: string = "1";
  siteInformation_obs: string;
  windZone_obs: string;
  terrainCategory_obs: string;
  buildingWidthString_obs: string;
  buildingDepthString_obs: string;
  buildingHeightString_obs: string;
  windowElevationString_obs: string;
  windowZone_obs: string;
  postcodeValue_obs: number;


  buildingWidthString: string;
  buildingDepthString: string;
  buildingHeightString: string;
  windowElevationString: string;
  positiveWindPressure: string;
  negativeWindPressure: string;
  maxAbsoluteValue: string;
  isUserDefinedSelected: boolean = false;
  structuralModel: Structural;
  isWEValid: boolean = true;
  isWLValid: boolean = true;
  isBWValid: boolean = true;
  isBHValid: boolean = true;
  isBDValid: boolean = true;
  isHValid: boolean = true;
  isVerIValid: boolean = true;

  calculateWindLoadSelection: string;
  maxWindPressure: number;
  switchConcentrated: boolean;
  switchSeismic: boolean;
  switchBoundary: boolean;
  switchSlab: boolean;

  windZoneBWSMperS: number;
  windZoneBWSMpH: number;

  place: string;
  district: string;
  state: string;
  locationDescription: string;
  siteInformation: string;
  speedMperS: number;
  speedMperH: number;
  language: string;

  windZone: string;
  dualValues: any;
  // 'Zip Code' | 'Wind Zone'
  patternLanguage: string;
  patternLanguage_negative: string;
  dinWinLoad: DinWindLoadInput;
  nfWinLoad: NFWindLoadInput;
  nfSimplifiedWinLoad: NFSimplifiedWindLoadInput;

  edgeZoneLength: number;
  internalPressure: boolean = true;
  positiveInternalPressure: string;
  negativeInternalPressure: string;
  horizontalLiveLoad: number;
  horizontalLiveLoadHeight: number;
  germanVb0Table = {1:22.5, 2:25.0, 3:27.5, 4:30.0};
  frenchVb0Table = {1:22.0, 2:24.0, 3:26.0, 4:28.0, 5:36.0, 6:17.0, 7:32.0, 8:30.0, 9:34.0}; // 5 for 'Guadeloupe', 6 for 'Guyane', 7 for 'Martinique', 8 for 'Mayotte', 9 for 'Reunion'
  constructor(private navLayoutService: NavLayoutService, public umService: UnifiedModelService, private cpService: ConfigPanelsService, private vpService: ValidatePanelsService, private localStorageService: LocalStorageService, private fb: FormBuilder,
    public configureService: ConfigureService, private translate: TranslateService) {
    this.language = this.configureService.getLanguage();
    this.patternLanguage = this.configureService.getNumberPattern();
    this.patternLanguage_negative = this.configureService.getNegNumberPattern();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    if (this.translate) this.dualValues = [{ title: this.translate.instant(_('configure.zip-code')), checked: true }, { title: this.translate.instant(_('configure.wind-zone')), checked: false }];
    this.validateForm = this.fb.group({
      codeSelection: [this.windLoadSelectedText, []],
      postCode: ['', [Validators.pattern("[0-9]{5}")]],
      windZoneControl: ['', []],
      terrainCategoryControl: ['', []],
      buildingWidth: ['', [Validators.pattern(this.patternLanguage)]],
      buildingDepth: ['', [Validators.pattern(this.patternLanguage)]],
      buildingHeight: ['', [Validators.pattern(this.patternLanguage)]],
      windowElevation: ['', [Validators.pattern(this.patternLanguage)]],
      windowZone: ['', []],
      pressureControl: ['1', []],
      packVelocityPressureControl: ['0.96', []],
      suctionControl: ['-1', []],
      internalPressureControl: [true],
      positiveInternalPressureControl: [0, [Validators.min(0)]],
      negativeInternalPressureControl: [0, [Validators.max(0)]],
    });
    /*this.unified3DModel = this.umService.current_UnifiedModel;*/
   // this.loadStructuralTable();

    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
          this.loadStructuralTable();
        }
      });
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {        
        if (response.panelsModule === PanelsModule.WindLoad) {
          this.isStructuralTableOpened = response.isOpened;
          setTimeout(() => {
            this.loadStructuralTable();
          }, 1000);
        }
      });

      //Change the titles of the dual values due to the language change
      this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.language = this.configureService.getLanguage();
        this.dualValues[0].title = this.translate.instant(_('configure.zip-code'));
        this.dualValues[1].title = this.translate.instant(_('configure.wind-zone'));
        this.patternLanguage = this.configureService.getNumberPattern();
        this.patternLanguage_negative = this.configureService.getNegNumberPattern();
        this.buildingWidthString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.buildingWidthString);
        this.buildingDepthString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.buildingDepthString);
        this.buildingHeightString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.buildingHeightString);
        this.windowElevationString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.windowElevationString);
        this.positiveWindPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.positiveWindPressure);
        this.negativeWindPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.negativeWindPressure);      
        this.positiveInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.positiveInternalPressure);
        this.negativeInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.negativeInternalPressure);      
      });
  }

  loadStructuralTable() {
    if (this.translate) this.dualValues = [{ title: this.translate.instant(_('configure.zip-code')), checked: true }, { title: this.translate.instant(_('configure.wind-zone')), checked: false }];
    this.structuralModel = this.umService.getNewStructuralModel();
    if (this.unified3DModel?.ModelInput?.Structural?.WindLoadInputType) {
      this.horizontalLiveLoad = this.structuralModel.HorizontalLiveLoad = this.unified3DModel.ModelInput.Structural.HorizontalLiveLoad;
      this.structuralModel.isUserDefineLoadSelected = this.unified3DModel.ModelInput.Structural.isUserDefineLoadSelected;
      this.horizontalLiveLoadHeight = this.structuralModel.HorizontalLiveLoadHeight = this.unified3DModel.ModelInput.Structural.HorizontalLiveLoadHeight;
      this.structuralModel.DispHorizontalIndex = this.unified3DModel.ModelInput.Structural.DispHorizontalIndex;
      this.structuralModel.DispVerticalIndex = this.unified3DModel.ModelInput.Structural.DispVerticalIndex;
      this.structuralModel.DispHorizontalIndex2 = this.unified3DModel.ModelInput.Structural.DispHorizontalIndex2;
      this.structuralModel.DispVerticalIndex2 = this.unified3DModel.ModelInput.Structural.DispVerticalIndex2;
      this.positiveWindPressure = this.unified3DModel.ModelInput.Structural.PositiveWindPressure;
      this.negativeWindPressure = this.unified3DModel.ModelInput.Structural.NegativeWindPressure;
      this.structuralModel.PositiveWindPressure = this.positiveWindPressure;
      this.structuralModel.NegativeWindPressure = this.negativeWindPressure;
      switch (this.unified3DModel.ModelInput.Structural.WindLoadInputType) {
        case 1: // User-Define
          if (this.unified3DModel.ModelInput.Structural.Cpn && this.unified3DModel.ModelInput.Structural.Cpp && this.unified3DModel.ModelInput.Structural.WindLoad) {     //add velocity in condition
            this.suctionString = this.unified3DModel.ModelInput.Structural.Cpn.toString();
            this.pressureString = this.unified3DModel.ModelInput.Structural.Cpp.toString();
            this.packVelocityPressureString = this.unified3DModel.ModelInput.Structural.WindLoad.toString();
            this.windLoadSelectedText = 'User Define';
            this.dispIndexType_obs = this.unified3DModel.ModelInput.Structural.DispIndexType;
            this.windLoadSelectedText_obs = this.windLoadSelectedText;
            this.suctionString_obs = this.suctionString;
            this.pressureString_obs = this.pressureString;
            this.packVelocityPressureString_obs = this.packVelocityPressureString;
            this.positiveWindPressure = (parseInt(this.pressureString) * parseInt(this.packVelocityPressureString)).toString();
            this.negativeWindPressure = (parseInt(this.suctionString) * parseInt(this.packVelocityPressureString)).toString();
          }
          break;
        case 2: // Din
          if (this.unified3DModel.ModelInput.Structural.dinWindLoadInput) {
            this.internalPressure = this.unified3DModel.ModelInput.Structural.dinWindLoadInput.IncludeCpi;
            this.validateForm.controls['positiveInternalPressureControl'].setValue(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.pCpi);
            this.validateForm.controls['negativeInternalPressureControl'].setValue(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.nCpi);
            this.windLoadSelectedText = 'DIN EN 1991-1-4';
            this.windLoadSelectedText_obs = this.windLoadSelectedText;
            this.dispIndexType_obs = this.unified3DModel.ModelInput.Structural.DispIndexType;
            this.terrainCategory = this.unified3DModel.ModelInput.Structural.dinWindLoadInput.TerrainCategory.toString();
            this.positiveInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.pCpi.toString());
            this.negativeInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.nCpi.toString());
            this.buildingWidthString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.L0.toString());
            this.buildingDepthString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.B0.toString());
            this.buildingHeightString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.h.toString());
            this.windowElevationString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.dinWindLoadInput.ElvW.toString());
            this.windowZone = this.unified3DModel.ModelInput.Structural.dinWindLoadInput.WindowZone.toString();
            if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Structural && this.unified3DModel.ModelInput.Structural.dinWindLoadInput) {
              if (this.unified3DModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue) {
                this.siteInformation = 'zipeCode';
                this.siteInformation_obs = this.siteInformation;
                this.dualValues[0].checked = true;
                this.dualValues[1].checked = false;
                this.postcodeValue = this.unified3DModel.ModelInput.Structural.dinWindLoadInput.PostCodeValue;
              }
              else {
                this.siteInformation = 'windZone';
                this.siteInformation_obs = this.siteInformation;
                this.dualValues[0].checked = false;
                this.dualValues[1].checked = true;
                this.windZone = this.unified3DModel.ModelInput.Structural.dinWindLoadInput.WindZone ? this.unified3DModel.ModelInput.Structural.dinWindLoadInput.WindZone.toString() : null;
                this.onWindZoneChange(this.windZone);
              }
            }
          }
          break;
        case 3: // NF
          if (this.unified3DModel.ModelInput.Structural.nfWindLoadInput) {
            this.internalPressure = this.unified3DModel.ModelInput.Structural.nfWindLoadInput.IncludeCpi;
            this.validateForm.controls['positiveInternalPressureControl'].setValue(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.pCpi);
            this.validateForm.controls['negativeInternalPressureControl'].setValue(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.nCpi);
            this.windLoadSelectedText = 'NF EN 1991-1-4';
            this.windLoadSelectedText_obs = this.windLoadSelectedText;
            this.dispIndexType_obs = this.unified3DModel.ModelInput.Structural.DispIndexType;
            this.terrainCategory = this.unified3DModel.ModelInput.Structural.nfWindLoadInput.TerrainCategory.toString();
            this.positiveInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.pCpi.toString());
            this.negativeInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.nCpi.toString());
            this.buildingWidthString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.L0.toString());
            this.buildingDepthString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.B0.toString());
            this.buildingHeightString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.h.toString());
            this.windowElevationString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfWindLoadInput.ElvW.toString());
            this.windowZone = this.unified3DModel.ModelInput.Structural.nfWindLoadInput.WindowZone.toString();
            if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Structural && this.unified3DModel.ModelInput.Structural.nfWindLoadInput) {
              if (this.unified3DModel.ModelInput.Structural.nfWindLoadInput.PostCodeValue) {
                this.siteInformation = 'zipeCode';
                this.siteInformation_obs = this.siteInformation;
                this.dualValues[0].checked = true;
                this.dualValues[1].checked = false;
                this.postcodeValue = this.unified3DModel.ModelInput.Structural.nfWindLoadInput.PostCodeValue;
              }
              else {
                this.siteInformation = 'windZone';
                this.siteInformation_obs = this.siteInformation;
                this.dualValues[0].checked = false;
                this.dualValues[1].checked = true;
                this.windZone = this.unified3DModel.ModelInput.Structural.nfWindLoadInput.WindZone ? this.unified3DModel.ModelInput.Structural.nfWindLoadInput.WindZone.toString() : null;
                this.onWindZoneChange(this.windZone);
              }
            }
          }
          break;
        case 4: // Simplified Method (SM)
          if (this.unified3DModel.ModelInput.Structural.nfsWindLoadInput) {
            this.internalPressure = false;
            this.validateForm.controls['positiveInternalPressureControl'].setValue(this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.pCpi);
            this.validateForm.controls['negativeInternalPressureControl'].setValue(this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.nCpi);
            this.windLoadSelectedText = 'SM';
            this.windLoadSelectedText_obs = this.windLoadSelectedText;
            this.dispIndexType_obs = this.unified3DModel.ModelInput.Structural.DispIndexType;
            this.terrainCategory = this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.TerrainCategory.toString();
            this.buildingWidthString = null;
            this.buildingDepthString = null;
            this.positiveInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.pCpi.toString());
            this.negativeInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.nCpi.toString());
            this.buildingHeightString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.h.toString());
            this.windowElevationString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.ElvW.toString());
            this.windowZone = null;
            if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Structural && this.unified3DModel.ModelInput.Structural.nfsWindLoadInput) {
              if (this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.PostCodeValue) {
                this.siteInformation = 'zipeCode';
                this.siteInformation_obs = this.siteInformation;
                this.dualValues[0].checked = true;
                this.dualValues[1].checked = false;
                this.postcodeValue = this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.PostCodeValue;
              } 
              else {
                this.siteInformation = 'windZone';
                this.siteInformation_obs = this.siteInformation;
                this.dualValues[0].checked = false;
                this.dualValues[1].checked = true;
                this.windZone = this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.WindZone ? this.unified3DModel.ModelInput.Structural.nfsWindLoadInput.WindZone.toString() : null;
                this.onWindZoneChange(this.windZone);
              }
            }
          }
          break;
      }
    }
    this.validateForm.controls['codeSelection'].setValue(this.windLoadSelectedText);
    this.validateForm.controls['postCode'].setValue(this.postcodeValue);
    this.validateForm.controls['windZoneControl'].setValue(this.windZone);
    this.validateForm.controls['terrainCategoryControl'].setValue(this.terrainCategory);
    this.validateForm.controls['buildingWidth'].setValue(this.buildingWidthString);
    this.validateForm.controls['buildingDepth'].setValue(this.buildingDepthString);
    this.validateForm.controls['buildingHeight'].setValue(this.buildingHeightString);
    this.validateForm.controls['windowElevation'].setValue(this.windowElevationString);
    this.validateForm.controls['windowZone'].setValue(this.windowZone);
    this.validateForm.controls['pressureControl'].setValue(this.pressureString);
    this.validateForm.controls['packVelocityPressureControl'].setValue(this.packVelocityPressureString);
    this.validateForm.controls['suctionControl'].setValue(this.suctionString);
    this.validateForm.controls['internalPressureControl'].setValue(this.internalPressure);
    this.validateForm.controls['positiveInternalPressureControl'].setValue(this.positiveInternalPressure);
    this.validateForm.controls['negativeInternalPressureControl'].setValue(this.negativeInternalPressure);
    // this.validateForm.controls['positiveInternalPressure'].markAsDirty();
    // this.validateForm.controls['negativeInternalPressure'].markAsDirty();
    // this.validateForm.controls['positiveInternalPressure'].updateValueAndValidity();
    // this.validateForm.controls['negativeInternalPressure'].updateValueAndValidity();
    for (const key in this.validateForm.controls) {
      if(key !== 'positiveInternalPressureControl' && key !== 'negativeInternalPressureControl') {
        this.validateForm.controls[key].setValidators([Validators.required]);
        if (this.validateForm.controls[key].value) {
          this.validateForm.controls[key].markAsDirty();
          this.validateForm.controls[key].updateValueAndValidity();
        }
      }     
    }
    //this.onChangeSiteInformation();
    if (this.dualValues[0].checked) {
      this.siteInformation = "zipeCode";
    }
    else {
      this.siteInformation = "windZone";
    }
  }
  ngOnChanges(Changes: SimpleChanges) {
    setTimeout(() => {
      if (Changes.windLoad_tableModel && Boolean(Changes.windLoad_tableModel.currentValue) && Boolean(Changes.windLoad_tableModel.currentValue.WindLoadModelInput)) {
        switch(this.unified3DModel.ModelInput.Structural.WindLoadInputType) {
          case 1:
            this.windLoadSelectedText = 'User Define';
            break;
          case 2:
            this.windLoadSelectedText = 'DIN EN 1991-1-4';
            break;
          case 3:
            this.windLoadSelectedText = 'NF EN 1991-1-4';
            break;
          case 4:
            this.windLoadSelectedText = 'SM';
            break;
          case 5:
            this.windLoadSelectedText = 'ASCE 7-16';
            break;
        }
        let windLoad_tableModel = Changes.windLoad_tableModel.currentValue.WindLoadModelInput;
        this.windLoadSelectedText_obs = this.windLoadSelectedText;
        this.onWindLoadCalculationTypeSelected(this.windLoadSelectedText);
        this.windZone = windLoad_tableModel.WindZone.toString();
        this.windowZone = windLoad_tableModel.WindowZone ? windLoad_tableModel.WindowZone.toString():null;
        this.buildingHeight = windLoad_tableModel.h;
        this.buildingWidth = windLoad_tableModel.L0;
        this.buildingDepth = windLoad_tableModel.B0;
        this.windowElevation = windLoad_tableModel.ElvW;
        this.windowElevationString_obs = windLoad_tableModel.ElvW;
        this.internalPressure = windLoad_tableModel.IncludeCpi;
        this.positiveInternalPressure = this.internalPressure ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(windLoad_tableModel.pCpi.toString()):null;
        this.negativeInternalPressure = this.internalPressure ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(windLoad_tableModel.nCpi.toString()):null;
        this.buildingWidthString = this.buildingWidth ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.buildingWidth.toString()):null;
        this.buildingDepthString = this.buildingDepth ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.buildingDepth.toString()):null;
        this.buildingHeightString = this.buildingHeight ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.buildingHeight.toString()):null;
        this.windowElevationString = this.windowElevation ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.windowElevation.toString()):null;
        this.terrainCategory = windLoad_tableModel.TerrainCategory.toString();
        if (windLoad_tableModel && windLoad_tableModel.PostCodeValue) {
          this.siteInformation = 'zipeCode';
          this.postcodeValue = windLoad_tableModel.PostCodeValue;
          this.postcodeValue_obs = windLoad_tableModel.PostCodeValue;
          this.siteInformation_obs = this.siteInformation;
          this.dualValues[0].checked = true;
          this.dualValues[1].checked = false;
        } else {
          this.siteInformation = 'windZone';
          this.siteInformation_obs = this.siteInformation;
          this.dualValues[0].checked = false;
          this.dualValues[1].checked = true;
        }
        this.windowElevationString_obs = this.windowElevationString;
        this.buildingHeightString_obs = this.buildingHeightString;
        this.buildingDepthString_obs = this.buildingDepthString;
        this.buildingWidthString_obs = this.buildingWidthString;
        this.terrainCategory_obs = this.terrainCategory;
        this.windZone_obs = this.windZone;
        this.windowZone_obs = this.windowZone;
        this.windLoadSelectedText_obs = this.windLoadSelectedText;
        if (this.dualValues[0].checked) {
          this.siteInformation = "zipeCode";
        }
        else {
          this.siteInformation = "windZone";
        }
        switch(this.unified3DModel.ModelInput.Structural.WindLoadInputType) {
          case 1:     // 'User Define'
          case 2:     // 'DIN EN 1991-1-4'
            this.calculatedWindLoadDIN(Changes.windLoad_tableModel.firstChange);
            break;
          case 3:     // 'NF EN 1991-1-4'
            this.calculatedWindLoadNF(Changes.windLoad_tableModel.firstChange);
            break;
          case 4:     // 'SM'
            this.calculateWindLoad_SM(Changes.windLoad_tableModel.firstChange);
            break;
          case 5:     // 'ASCE 7-16'
            break;
        }
      }
    }, 1000);
   
  }

  clearTable() {
    this.windZone = null;
    this.windowZone = null;
    this.buildingHeight = null;
    this.buildingWidth = null;
    this.buildingDepth = null;
    this.windowElevation = null;
    this.terrainCategory = null;
    this.buildingWidthString = null;
    this.buildingDepthString = null;
    this.buildingHeightString = null;
    this.windowElevationString = null;
    this.positiveInternalPressure = null;
    this.negativeInternalPressure = null;
    this.clearPostCode();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].markAsUntouched();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    this.internalPressure = this.configureService.isProjectInFrance();
    this.validateForm.setErrors(null);
  }

  onChangeSiteInformation(dualValues = this.dualValues) {
    if (dualValues[0].checked) {
      this.siteInformation = "zipeCode";
    }
    else {
      this.siteInformation = "windZone";
    }
  }

  onCloseTable(): void {
    this.showHidePopUp(false);
  }

  onOpenTable(): void {
    this.showHidePopUp(true);
    this.loadStructuralTable();
    // the below lines are commented as to restrict the validation when the structural table is opened by default
    // and it should happen when the control is touched and not entered any value then it should get validate
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls[key].value) {
        this.validateForm.controls[key].markAsDirty();
        this.validateForm.controls[key].updateValueAndValidity();
      }
      //  else {
      //   if(this.validateForm.controls[key].value !== null && this.validateForm.controls[key].value !== '') {
      //     this.validateForm.controls[key].markAsDirty();
      //   this.validateForm.controls[key].updateValueAndValidity();
      //   }
      // }

    }
  }

  onWindLoadCalculationTypeSelected(optionSelected: string) {
    let isNewSelection = this.windLoadSelectedText != optionSelected;
    this.windLoadSelectedText = optionSelected;
    if (this.windLoadSelectedText) {
      if (this.windLoadSelectedText == 'User Define') {
        this.windLoadOption = 'USER';
        this.isUserDefinedSelected = true;
        this.createUnified3DModelForUserDefine();
        this.isValid = this.windLoad ? true : false;
        if (this.packVelocityPressureString) {
          this.validateForm.controls['packVelocityPressureControl'].markAsDirty();
          this.validateForm.controls['packVelocityPressureControl'].updateValueAndValidity();  
        }
        if (this.pressureString) {
          this.validateForm.controls['pressureControl'].markAsDirty();
          this.validateForm.controls['pressureControl'].updateValueAndValidity();  
        }
        if (this.suctionString) {
          this.validateForm.controls['suctionControl'].markAsDirty();
          this.validateForm.controls['suctionControl'].updateValueAndValidity();  
        }
        if (this.isValid)
          this.validStructuralEvent.emit(this.structuralModel); //send to parent 1st time.  
      }
      else {
        if (isNewSelection) {
          this.clearTable();
          this.setPostCodeFromLocation();
        }
        this.isUserDefinedSelected = false;
        this.windLoadOption = 'NO USER';
        if (this.isDinWindLoadValid()) {
          this.isValid = true;
          this.validStructuralEvent.emit(this.structuralModel);
        } else {
          this.windLoad = null;
        }
      }
    }
  }

  setPostCodeFromLocation(): void {
    if (this.unified3DModel.ProblemSetting.Location.match(/\d{5}/) != null) {
      this.postcodeValue = this.unified3DModel.ProblemSetting.Location.match(/\d{5}/)[0];
      this.onChangePostCode();
    }
  }

  clearPostCode() {
    this.postcodeValue = null;
    this.validateForm.controls['postCode'].setErrors({ 'incorrect': true });
    this.place = "";
    this.district = "";
    this.state = "";
    this.locationDescription = null;
    this.speedMperS = null;
    this.speedMperH = null;
    this.windZoneBWSMperS = null;
    this.windZoneBWSMpH = null;
  }

  onChangePostCode() {
    if (this.postcodeValue && this.postcodeValue.toString().length == 5) {
      this.validateForm.controls['postCode'].markAsTouched();
      this.validateForm.controls['postCode'].markAsDirty();
      this.validateForm.controls['postCode'].updateValueAndValidity();  
      if (this.windLoadSelectedText == 'DIN EN 1991-1-4') {
        this.configureService.GetWindZoneDE(this.postcodeValue.toString()).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.windZone = data.WindZone ? data.WindZone.toString() : null;
          this.place = data.Place;
          this.district = data.District;
          this.state = data.State;
          this.locationDescription = this.place + ", " + this.state;
          this.speedMperS = data.vb0;
          this.speedMperH = Math.round(this.speedMperS * 2.237);
          if (this.place == "") {
            this.validateForm.controls['postCode'].setErrors({ 'incorrect': true });
            this.locationDescription = null;
            this.windZone = null;
          }
        });
      }
      else {
        this.configureService.GetWindZoneFR(this.postcodeValue.toString()).pipe(takeUntil(this.destroy$)).subscribe(dataArray => {
          if (dataArray.length > 0) {
            if (this.windLoadSelectedText == 'SM' && dataArray[0].Zone_de_vent == 8) {  // Mayotte not supported in the simplified method
              this.validateForm.controls['postCode'].setErrors({ 'incorrect': true });
              this.locationDescription = null;
              this.windZone = null;
            }
            else {
              let data = dataArray[0];
              this.windZone = data.Zone_de_vent ? data.Zone_de_vent.toString() : null;
              this.place = data.Commune;
              this.district = '';
              this.state = data.Département;
              this.locationDescription = this.place + ", " + this.state;
              this.speedMperS = this.frenchVb0Table[data.Zone_de_vent];
              this.speedMperH = Math.round(this.speedMperS * 2.237);
            }
          }
          if (dataArray.length == 0 || this.place == "") {
            this.validateForm.controls['postCode'].setErrors({ 'incorrect': true });
            this.locationDescription = null;
            this.windZone = null;
          }
        });
      }
    }
    else if (this.postcodeValue && this.postcodeValue.toString().length !== 5) {
      this.clearPostCode();
      this.windZone = null;
    }
  }

  calculateWindLoad() {
    switch(this.windLoadSelectedText) {
      case 'DIN EN 1991-1-4':
        if (this.isDinWindLoadValid()) {
          this.calculatedWindLoadDIN();
        }
        break;
      case 'NF EN 1991-1-4':
        if (this.isNFWindLoadValid()) {
          this.calculatedWindLoadNF();
        }
        break;
      case 'SM':
        this.calculateWindLoad_SM();
        break;
    }
  }

  calculatedWindLoadDIN(andThenConfirm: boolean = false) {
    this.dinWinLoad = {
      WindZone: parseInt(this.windZone),
      TerrainCategory: parseFloat(this.terrainCategory),
      L0: this.buildingWidth,
      B0: this.buildingDepth,
      h: this.buildingHeight,
      ElvW: this.windowElevation? this.windowElevation : -1,
      WindowZone: parseFloat(this.windowZone),
      IncludeCpi: null,
      pCpi: null,
      nCpi: null,
      RequestDescription: false,
      PostCodeValue: null
    };
    if (andThenConfirm) {
      this.onConfirm();
    }
  }
  
  isDinWindLoadValid(): boolean {
    if(this.umService.isWindow()){
      if (this.terrainCategory && this.buildingWidth && this.buildingDepth && this.buildingHeight && this.windowElevation && this.windowZone) {
        return true;
      }
    } else{
      if (this.terrainCategory && this.buildingWidth && this.buildingDepth && this.buildingHeight && this.windowZone) {
        return true;
      }
    }
    return false;
  }

  calculatedWindLoadNF(andThenConfirm: boolean = false) {
    this.nfWinLoad = {
      WindZone: parseInt(this.windZone),
      TerrainCategory: parseFloat(this.terrainCategory),
      L0: this.buildingWidth,
      B0: this.buildingDepth,
      h: this.buildingHeight,
      ElvW: this.windowElevation? this.windowElevation : -1,
      WindowZone: parseFloat(this.windowZone),
      IncludeCpi: null,
      pCpi: null,
      nCpi: null,
      RequestDescription: false,
      PostCodeValue: null
    };
    if (andThenConfirm) {
      this.onConfirm();
    }
  }
  
  isNFWindLoadValid(): boolean {
    if (this.umService.isWindow()){
      if (this.terrainCategory && this.buildingWidth && this.buildingDepth && this.buildingHeight && this.windowElevation && this.windowZone) {
        return true;
      }
    }
    else {
      if (this.terrainCategory && this.buildingWidth && this.buildingDepth && this.buildingHeight && this.windowZone) {
        return true;
      }
    }
    return false;
  }

  calculateWindLoad_SM(andThenConfirm: boolean = false) {
    this.nfSimplifiedWinLoad = {
      WindZone: parseInt(this.windZone),
      TerrainCategory: parseFloat(this.terrainCategory),
      L0: 1.0,
      B0: 1.0,
      h: this.buildingHeight,
      ElvW: -1,
      WindowZone: 1.0,
      IncludeCpi: null,
      pCpi: null,
      nCpi: null,
      RequestDescription: false,
      PostCodeValue: null
    };
    if (andThenConfirm) {
      this.onConfirm();
    }
  }

  onSuctionChange() {
    if (!this.vpService.isValidNumber_Negative(this.suctionString))
      this.validateForm.controls['suctionControl'].setErrors({ 'incorrect': false });
  }

  onPositiveInternalPressureChange() {
    if ((new RegExp(this.patternLanguage)).test(this.positiveInternalPressure)) {
      if (!this.vpService.isValidNumber_Positive(typeof this.positiveInternalPressure == 'string' ? parseFloat(this.positiveInternalPressure.replace(',','.')):this.positiveInternalPressure)) {
        this.validateForm.controls['positiveInternalPressureControl'].setErrors({ 'incorrect': false });
      }
      else {
        if (this.validateForm.controls['positiveInternalPressureControl'].errors != null) {
          delete this.validateForm.controls['positiveInternalPressureControl'].errors['incorrect'];
          this.validateForm.controls['positiveInternalPressureControl'].updateValueAndValidity();
        }
        // this.validateForm.controls['positiveInternalPressure'].setErrors({ 'incorrect': null });
      }
    }
    else {
      this.validateForm.controls['positiveInternalPressureControl'].setErrors({ 'incorrect': false });
    }
  }

  onNegativeInternalPressureChange() {
    if ((new RegExp(this.patternLanguage_negative)).test(this.negativeInternalPressure)) {
      if (!this.vpService.isValidNumber_Negative(typeof this.negativeInternalPressure == 'string' ? parseFloat(this.negativeInternalPressure.replace(',','.')):this.negativeInternalPressure)) {
        this.validateForm.controls['negativeInternalPressureControl'].setErrors({ 'incorrect': false });
      }
      else {
        if (this.validateForm.controls['negativeInternalPressureControl'].errors != null) {
          delete this.validateForm.controls['negativeInternalPressureControl'].errors['incorrect'];
          this.validateForm.controls['negativeInternalPressureControl'].updateValueAndValidity();
        }
      }
    }
    else {
      this.validateForm.controls['negativeInternalPressureControl'].setErrors({ 'incorrect': false });
    }
  }

  onPressureChange() {
    if (!this.vpService.isValidNumber_Positive(this.pressureString)) {
      this.validateForm.controls['pressureControl'].setErrors({ 'incorrect': false });
    }
    else {
      if (this.validateForm.controls['pressureControl'].errors != null) {
        delete this.validateForm.controls['pressureControl'].errors['incorrect'];
        this.validateForm.controls['pressureControl'].updateValueAndValidity();
      }
    }
  }

  onVelocityPressureChange() {
    if (!this.vpService.isValidNumber_Positive(this.packVelocityPressureString)) {
      this.validateForm.controls['packVelocityPressureControl'].setErrors({ 'incorrect': false });
    }
    else {
      if (this.validateForm.controls['packVelocityPressureControl'].errors != null) {
        delete this.validateForm.controls['packVelocityPressureControl'].errors['incorrect'];
        this.validateForm.controls['packVelocityPressureControl'].updateValueAndValidity();
      }
    }
  }

  calculateEdgeZoneLength() {
    //Min(building width /5 , 2 * building height /5, building depth)
    if (this.buildingHeight && this.buildingWidth && this.buildingDepth) {
      return Math.min(this.buildingWidth / 5, 2 * this.buildingHeight / 5, this.buildingDepth);
    } else
      return null;
  }

  onBuildingWidthChange() {
    if ((new RegExp(this.patternLanguage)).test(this.buildingWidthString)) {
      if (this.buildingWidthString) {
        this.buildingWidth = parseFloat(this.buildingWidthString.replace(",", "."));
        if (isNaN(this.buildingWidth) || this.buildingWidth < 1 || this.buildingWidth > 1000) {
          this.validateForm.controls['buildingWidth'].setErrors({ 'incorrect': true });
        }
        else {
          if (this.validateForm.controls['buildingWidth'].errors != null) {
            delete this.validateForm.controls['buildingWidth'].errors['incorrect'];
            this.validateForm.controls['buildingWidth'].updateValueAndValidity();
          }
          this.edgeZoneLength = this.calculateEdgeZoneLength();
          this.isBWValid = this.vpService.isValidNumber_Positive(this.buildingWidth);
          if (this.isBWValid) this.calculateWindLoad();
        }
      }
      else {
        this.buildingWidth = null;
      }
    }
    else {
      if (this.buildingWidthString) {
        this.validateForm.controls['buildingWidth'].setErrors({ 'incorrect': true });
      }
    }
  }

  onBuildingHeightChange() {
    if ((new RegExp(this.patternLanguage)).test(this.buildingHeightString)) {
      if (this.buildingHeightString) {
        this.buildingHeight = parseFloat(this.buildingHeightString.replace(",", "."));
        switch(this.windLoadSelectedText) {
          case 'DIN EN 1991-1-4':
            if (isNaN(this.buildingHeight) || this.buildingHeight < 1 || this.buildingHeight > 1000) {
              this.validateForm.controls['buildingHeight'].setErrors({ 'incorrect': true });
            }
            else {
              if (this.validateForm.controls['buildingHeight'].errors != null) {
                delete this.validateForm.controls['buildingHeight'].errors['incorrect'];
                this.validateForm.controls['buildingHeight'].updateValueAndValidity();
              }
              this.edgeZoneLength = this.calculateEdgeZoneLength();
              this.isBHValid = this.vpService.isValidNumber_Positive(this.buildingHeight);
              if (this.isBHValid) this.calculateWindLoad();
            }
            break;
          case 'NF EN 1991-1-4':
            if (isNaN(this.buildingHeight) || this.buildingHeight < 1 || this.buildingHeight > 200) {
              this.validateForm.controls['buildingHeight'].setErrors({ 'incorrect': true });
            }
            else {
              if (this.validateForm.controls['buildingHeight'].errors != null) {
                delete this.validateForm.controls['buildingHeight'].errors['incorrect'];
                this.validateForm.controls['buildingHeight'].updateValueAndValidity();
              }
              this.edgeZoneLength = this.calculateEdgeZoneLength();
              this.isBHValid = this.vpService.isValidNumber_Positive(this.buildingHeight);
              if (this.isBHValid) this.calculateWindLoad();
            }
            break;
          case 'SM':
            if (isNaN(this.buildingHeight) || this.buildingHeight < 1 || this.buildingHeight > 200) {
              this.validateForm.controls['buildingHeight'].setErrors({ 'incorrect': true });
            }
            else {
              if (this.validateForm.controls['buildingHeight'].errors != null) {
                delete this.validateForm.controls['buildingHeight'].errors['incorrect'];
                this.validateForm.controls['buildingHeight'].updateValueAndValidity();
              }
              this.edgeZoneLength = this.calculateEdgeZoneLength();
              this.isBHValid = this.vpService.isValidNumber_Positive(this.buildingHeight);
              if (this.isBHValid) this.calculateWindLoad();
            }
            break;
        }
      }
      else {
        this.buildingHeight = null;
      }
    }
    else if (this.buildingHeightString) {
      this.validateForm.controls['buildingHeight'].setErrors({ 'incorrect': true });
    }
  }

  onBuildingDepthChange() {
    if ((new RegExp(this.patternLanguage)).test(this.buildingDepthString)) {
      if (this.buildingDepthString) {
        this.buildingDepth = parseFloat(this.buildingDepthString.replace(",", "."));
        if (isNaN(this.buildingDepth) || this.buildingDepth < 1 || this.buildingDepth > 1000) {
          this.validateForm.controls['buildingDepth'].setErrors({ 'incorrect': true });
        }
        else {
          if (this.validateForm.controls['buildingDepth'].errors != null) {
            delete this.validateForm.controls['buildingDepth'].errors['incorrect'];
            this.validateForm.controls['buildingDepth'].updateValueAndValidity();
          }
          this.edgeZoneLength = this.calculateEdgeZoneLength();
          this.isBDValid = this.vpService.isValidNumber_Positive(this.buildingDepth);
          if (this.isBDValid) this.calculateWindLoad();
        }
      }
      else {
        this.buildingDepth = null;
      }
      // if(this.isValid) this.updateParentForInvalid();
    }
    else {
      if (this.buildingDepthString) {
        this.validateForm.controls['buildingDepth'].setErrors({ 'incorrect': true });
      }
    }
  }

  onWindowElevationChange() {  // keep
    if (this.windowElevationString) {
      this.windowElevation = parseFloat(this.windowElevationString.replace(",", "."));
      // if (this.windowElevation < 0.5 || this.windowElevation > 1000) {
      //   this.validateForm.controls['windowElevation'].setErrors({ 'incorrect': true });
      // }
    }
    else {
      this.windowElevation = null;
    }
    this.isWEValid = this.vpService.isValidNumber_Positive(this.windowElevation);
    if (this.isWEValid)
      this.calculateWindLoad();
    // if(this.isValid) this.updateParentForInvalid();
  }

  onWindZoneChange(event: string) { 
    if (event) {
      this.windZone = event;
      this.windZoneBWSMperS = this.windLoadSelectedText === 'DIN EN 1991-1-4' ? this.germanVb0Table[parseInt(this.windZone)]:this.frenchVb0Table[parseInt(this.windZone)];
      this.windZoneBWSMpH = this.windZoneBWSMperS * 2.237;
      this.windZoneBWSMpH = parseFloat(this.windZoneBWSMpH.toFixed(0));
    }
  }

  createUnified3DModelForUserDefine() {
    if (this.windLoadOption == 'USER' && this.windLoad) {
      this.structuralModel.WindLoad = this.windLoad;
      this.structuralModel.WindLoadInputType = 1;
      this.structuralModel.DispIndexType = this.dispIndexType_obs;
    }
  }

  onConfirm() {
    if (this.windLoadSelectedText == 'DIN EN 1991-1-4' || this.windLoadSelectedText == 'NF EN 1991-1-4' || this.windLoadSelectedText == 'SM') {      
      switch(this.windLoadSelectedText) {
        case 'DIN EN 1991-1-4':
          if (this.structuralModel !== undefined) {
            if (this.structuralModel.dinWindLoadInput !== undefined && this.structuralModel.dinWindLoadInput !== null) {
              this.structuralModel.dinWindLoadInput.PostCodeValue = this.postcodeValue;
            }
          }
          if (this.siteInformation == "zipeCode") {
            this.dinWinLoad.WindZone = parseInt(this.windZone);
            this.dinWinLoad.PostCodeValue = this.postcodeValue;
          } else {
            this.clearPostCode();
            this.dinWinLoad.WindZone = parseInt(this.windZone);
          }    
          this.dinWinLoad.IncludeCpi = this.internalPressure;
          if (this.internalPressure) {
            this.dinWinLoad.pCpi = parseFloat(this.positiveInternalPressure.replace(',','.'));
            this.dinWinLoad.nCpi = parseFloat(this.negativeInternalPressure.replace(',','.'));
          }
          else {
            this.dinWinLoad.pCpi = 0;
            this.dinWinLoad.nCpi = 0;
          }
          this.configureService.calculatedWindLoadDIN(this.dinWinLoad).pipe(takeUntil(this.destroy$)).subscribe((windLoadResult) => {
            let maxAbsWP = windLoadResult.MaxAbsWindPressure.toFixed(2);
            let maxPositiveWP = windLoadResult.MaxPositiveWindPressure.toFixed(2);
            let minNegativeWP = windLoadResult.MinNegtiveWindPressure < 0 ? windLoadResult.MinNegtiveWindPressure.toFixed(2): (-1*windLoadResult.MinNegtiveWindPressure).toFixed(2);
            this.maxAbsoluteValue = maxAbsWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(maxAbsWP.toString()) : maxAbsWP;
            this.positiveWindPressure = maxPositiveWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(maxPositiveWP.toString()): maxPositiveWP;
            this.negativeWindPressure = minNegativeWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(minNegativeWP.toString()): minNegativeWP;
            
            //1.c
            if (this.maxAbsoluteValue) {
              this.windLoad = parseFloat(this.maxAbsoluteValue);
              this.isValid = true;
            }
            //2 send event to parent to enable Compute button which will enable Result, Report after click of Compute
            if (this.isValid) {
              this.createUnified3DModel_DIN();//2
            }
            this.structuralModel.WindLoadInputType = 2; //2 for DIN
            this.structuralModel.WindLoad = parseFloat(this.packVelocityPressureString);
            this.structuralModel.Cpp = parseFloat(this.pressureString);
            this.structuralModel.Cpn = parseFloat(this.suctionString);
            this.structuralModel.PositiveWindPressure = this.positiveWindPressure;
            this.structuralModel.NegativeWindPressure = this.negativeWindPressure;
            this.sendPressureValueEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, maxAbsoluteValue: this.maxAbsoluteValue, structuralModel: this.structuralModel });
            if (this.siteInformation == 'zipeCode') {
              setTimeout(() => {
                this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, siteInformation: this.siteInformation, postcodeValue: this.postcodeValue, terrainCategory: this.terrainCategory, buildingWidthString: this.buildingWidthString, buildingDepthString: this.buildingDepthString, buildingHeightString: this.buildingHeightString, windowElevationString: this.windowElevationString, windowZone: this.windowZone, windZone: parseInt(this.windZone), 
                  internalPressure: this.internalPressure, positiveInternalPressure: parseFloat(this.positiveInternalPressure?.replace(',','.')), negativeInternalPressure: parseFloat(this.negativeInternalPressure?.replace(',','.')) });
                },0);
            }
            else if (this.siteInformation == 'windZone') {
              setTimeout(() => {
                this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, siteInformation: this.siteInformation, windZone: this.windZone, terrainCategory: this.terrainCategory, buildingWidthString: this.buildingWidthString, buildingDepthString: this.buildingDepthString, buildingHeightString: this.buildingHeightString, windowElevationString: this.windowElevationString, windowZone: this.windowZone,
                  internalPressure: this.internalPressure, positiveInternalPressure: parseFloat(this.positiveInternalPressure?.replace(',','.')), negativeInternalPressure: parseFloat(this.negativeInternalPressure?.replace(',','.')) });
                },0);
              }
            });     
          break;
        case 'NF EN 1991-1-4':
          if (this.structuralModel !== undefined) {
            if (this.structuralModel.nfWindLoadInput !== undefined && this.structuralModel.nfWindLoadInput !== null) {
              this.structuralModel.nfWindLoadInput.PostCodeValue = this.postcodeValue;
            }
          }
          if (this.siteInformation == "zipeCode") {
            this.nfWinLoad.WindZone = parseInt(this.windZone);
            this.nfWinLoad.PostCodeValue = this.postcodeValue;
          } else {
            this.clearPostCode();
            this.nfWinLoad.WindZone = parseInt(this.windZone);
          }
    
          this.nfWinLoad.IncludeCpi = this.internalPressure;
          if(this.internalPressure){
            this.nfWinLoad.pCpi = parseFloat(this.positiveInternalPressure.replace(',','.'));
            this.nfWinLoad.nCpi = parseFloat(this.negativeInternalPressure.replace(',','.'));
          } else {
            this.nfWinLoad.pCpi = 0;
            this.nfWinLoad.nCpi = 0;
          }
          this.configureService.calculatedWindLoadNF(this.nfWinLoad).pipe(takeUntil(this.destroy$)).subscribe((windLoadResult) => {
            let maxAbsWP = windLoadResult.MaxAbsWindPressure.toFixed(2);
            let maxPositiveWP = windLoadResult.MaxPositiveWindPressure.toFixed(2);
            let minNegativeWP = windLoadResult.MinNegtiveWindPressure < 0 ? windLoadResult.MinNegtiveWindPressure.toFixed(2): (-1*windLoadResult.MinNegtiveWindPressure).toFixed(2);
            this.maxAbsoluteValue = maxAbsWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(maxAbsWP.toString()) : maxAbsWP;
            this.positiveWindPressure = maxPositiveWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(maxPositiveWP.toString()): maxPositiveWP;
            this.negativeWindPressure = minNegativeWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(minNegativeWP.toString()): minNegativeWP;
            if (this.maxAbsoluteValue) {
              this.windLoad = parseFloat(this.maxAbsoluteValue);
              this.isValid = true;
            }
            if (this.isValid) {
              this.createUnified3DModel_NF();
            }
            this.structuralModel.WindLoadInputType = 3; //3 for NF
            this.structuralModel.WindLoad = parseFloat(this.packVelocityPressureString);
            this.structuralModel.Cpp = parseFloat(this.pressureString);
            this.structuralModel.Cpn = parseFloat(this.suctionString);
            this.structuralModel.PositiveWindPressure = this.positiveWindPressure;
            this.structuralModel.NegativeWindPressure = this.negativeWindPressure;
            this.sendPressureValueEvent.emit({ positiveWindPressure: this.positiveWindPressure,
                                                negativeWindPressure: this.negativeWindPressure,
                                                maxAbsoluteValue: this.maxAbsoluteValue,
                                                structuralModel: this.structuralModel
            });
            if (this.siteInformation == 'zipeCode') { setTimeout(() => { this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, siteInformation: this.siteInformation, postcodeValue: this.postcodeValue, terrainCategory: this.terrainCategory, buildingWidthString: this.buildingWidthString, buildingDepthString: this.buildingDepthString, buildingHeightString: this.buildingHeightString, windowElevationString: this.windowElevationString, windowZone: this.windowZone, windZone: parseInt(this.windZone), internalPressure: this.internalPressure, positiveInternalPressure: parseFloat(this.positiveInternalPressure?.replace(',','.')), negativeInternalPressure: parseFloat(this.negativeInternalPressure?.replace(',','.')) }); },0); }
            else if (this.siteInformation == 'windZone') { setTimeout(() => { this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, siteInformation: this.siteInformation, windZone: this.windZone, terrainCategory: this.terrainCategory, buildingWidthString: this.buildingWidthString, buildingDepthString: this.buildingDepthString, buildingHeightString: this.buildingHeightString, windowElevationString: this.windowElevationString, windowZone: this.windowZone, internalPressure: this.internalPressure, positiveInternalPressure: parseFloat(this.positiveInternalPressure?.replace(',','.')), negativeInternalPressure: parseFloat(this.negativeInternalPressure?.replace(',','.')) }); },0); }
          });
          break;
        case 'SM':
          if (this.structuralModel !== undefined) {
            if (this.structuralModel.nfsWindLoadInput !== undefined && this.structuralModel.nfsWindLoadInput !== null) {
              this.structuralModel.nfsWindLoadInput.PostCodeValue = this.postcodeValue;
            }
          }
          if (this.siteInformation == "zipeCode") {
            this.nfSimplifiedWinLoad.WindZone = parseInt(this.windZone);
            this.nfSimplifiedWinLoad.PostCodeValue = this.postcodeValue;
          } else {
            this.clearPostCode();
            this.nfSimplifiedWinLoad.WindZone = parseInt(this.windZone);
          }    
          this.nfSimplifiedWinLoad.IncludeCpi = false;
          this.nfSimplifiedWinLoad.pCpi = 0;
          this.nfSimplifiedWinLoad.nCpi = 0;
          this.configureService.calculatedWindLoadSM(this.nfSimplifiedWinLoad).pipe(takeUntil(this.destroy$)).subscribe((windLoadResult) => {
            let maxAbsWP = windLoadResult.MaxAbsWindPressure.toFixed(2);
            let maxPositiveWP = windLoadResult.MaxPositiveWindPressure.toFixed(2);
            let minNegativeWP = windLoadResult.MinNegtiveWindPressure < 0 ? windLoadResult.MinNegtiveWindPressure.toFixed(2): (-1*windLoadResult.MinNegtiveWindPressure).toFixed(2);
            this.maxAbsoluteValue = maxAbsWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(maxAbsWP.toString()) : maxAbsWP;
            this.positiveWindPressure = maxPositiveWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(maxPositiveWP.toString()): maxPositiveWP;
            this.negativeWindPressure = minNegativeWP ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(minNegativeWP.toString()): minNegativeWP;
            if (this.maxAbsoluteValue) {
              this.windLoad = parseFloat(this.maxAbsoluteValue);
              this.isValid = true;
            }
            if (this.isValid) {
              this.createUnified3DModel_SM();
            }
            this.structuralModel.WindLoadInputType = 4; //4 for SM
            this.structuralModel.WindLoad = parseFloat(this.packVelocityPressureString);
            this.structuralModel.Cpp = parseFloat(this.pressureString);
            this.structuralModel.Cpn = parseFloat(this.suctionString);
            this.structuralModel.PositiveWindPressure = this.positiveWindPressure;
            this.structuralModel.NegativeWindPressure = this.negativeWindPressure;
            this.sendPressureValueEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, maxAbsoluteValue: this.maxAbsoluteValue, structuralModel: this.structuralModel });
            if (this.siteInformation == 'zipeCode') {
              setTimeout(() => { this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, siteInformation: this.siteInformation, postcodeValue: this.postcodeValue, windZone: this.windZone, terrainCategory: this.terrainCategory, buildingHeightString: this.buildingHeightString, internalPressure: this.internalPressure, positiveInternalPressure: parseFloat(this.positiveInternalPressure?.replace(',','.')), negativeInternalPressure: parseFloat(this.negativeInternalPressure?.replace(',','.')) }); },0);
            }
            else if (this.siteInformation == 'windZone') {
              setTimeout(() => { this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, siteInformation: this.siteInformation, windZone: this.windZone, terrainCategory: this.terrainCategory, buildingHeightString: this.buildingHeightString, internalPressure: this.internalPressure, positiveInternalPressure: parseFloat(this.positiveInternalPressure?.replace(',','.')), negativeInternalPressure: parseFloat(this.negativeInternalPressure?.replace(',','.')) }); },0);
            }
          });
          break;
      }
    }
    else if (this.windLoadSelectedText == 'User Define') {
      setTimeout(() => {
        this.structuralModel.WindLoadInputType = 1;
        this.structuralModel.WindLoad = parseFloat(this.packVelocityPressureString);
        this.positiveWindPressure = (parseFloat(this.pressureString) * parseFloat(this.packVelocityPressureString)).toString();
        this.negativeWindPressure = (parseFloat(this.suctionString) * parseFloat(this.packVelocityPressureString)).toString();
        this.structuralModel.Cpp = parseFloat(this.pressureString);
        this.structuralModel.Cpn = parseFloat(this.suctionString);
        this.sendPressureValueEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, maxAbsoluteValue: this.maxAbsoluteValue, structuralModel: this.structuralModel });
        // this.isStructuralTableOpened = false;
        this.sendFormDataEvent.emit({ positiveWindPressure: this.positiveWindPressure, negativeWindPressure: this.negativeWindPressure, windLoadSelectedText: this.windLoadSelectedText, packVelocityPressureString: this.packVelocityPressureString, pressureString: this.pressureString, suctionString: this.suctionString });
      }, 0);
    }
    if (this.isStructuralTableOpened) {
      this.showHidePopUp(false);
    }

  }

  createUnified3DModel_DIN() {
    if (this.windLoadOption == 'NO USER') {
      if (!this.structuralModel) this.umService.getNewStructuralModel();
      this.structuralModel.WindLoad = this.windLoad;
      if (!this.structuralModel.dinWindLoadInput) this.structuralModel.dinWindLoadInput = {
        WindZone: null,
        TerrainCategory: null,
        L0: null,
        B0: null,
        h: null,
        ElvW: null,
        WindowZone: null,
        IncludeCpi: null,
        pCpi: null,
        nCpi: null,
        RequestDescription: false,
        PostCodeValue: null
      };
      this.structuralModel.dinWindLoadInput.WindZone = this.dinWinLoad.WindZone; //Notes: needs to be replaced by zipcode -> Basic Wind Speed
      this.structuralModel.dinWindLoadInput.TerrainCategory = parseFloat(this.terrainCategory);
      this.structuralModel.dinWindLoadInput.L0 = this.buildingWidth;
      this.structuralModel.dinWindLoadInput.B0 = this.buildingDepth;
      this.structuralModel.dinWindLoadInput.h = this.buildingHeight;
      this.structuralModel.dinWindLoadInput.ElvW = this.windowElevation? this.windowElevation : -1;
      this.structuralModel.dinWindLoadInput.WindowZone = parseFloat(this.windowZone);
      this.structuralModel.dinWindLoadInput.IncludeCpi = this.internalPressure;
      if(this.internalPressure){
        this.structuralModel.dinWindLoadInput.pCpi = parseFloat(this.positiveInternalPressure.replace(',','.'));
        this.structuralModel.dinWindLoadInput.nCpi = parseFloat(this.negativeInternalPressure.replace(',','.'));
      } else {
        this.structuralModel.dinWindLoadInput.pCpi = 0;
        this.structuralModel.dinWindLoadInput.nCpi = 0;
      }
      this.structuralModel.WindLoadInputType = 2;
      this.structuralModel.DispIndexType = this.dispIndexType_obs;
      this.structuralModel.dinWindLoadInput.PostCodeValue = this.postcodeValue;
    }
  }

  createUnified3DModel_NF() {
    if (this.windLoadOption == 'NO USER') {
      if (!this.structuralModel) this.umService.getNewStructuralModel();
      if (!this.structuralModel.nfWindLoadInput) this.structuralModel.nfWindLoadInput = { WindZone: null, TerrainCategory: null, L0: null, B0: null, h: null, ElvW: null, WindowZone: null, IncludeCpi: null, pCpi: null, nCpi: null, RequestDescription: false, PostCodeValue: null };
      this.structuralModel.WindLoad = this.windLoad;
      this.structuralModel.nfWindLoadInput.WindZone = this.nfWinLoad.WindZone;
      this.structuralModel.nfWindLoadInput.TerrainCategory = parseFloat(this.terrainCategory);
      this.structuralModel.nfWindLoadInput.L0 = this.buildingWidth;
      this.structuralModel.nfWindLoadInput.B0 = this.buildingDepth;
      this.structuralModel.nfWindLoadInput.h = this.buildingHeight;
      this.structuralModel.nfWindLoadInput.ElvW = this.windowElevation? this.windowElevation : -1;
      this.structuralModel.nfWindLoadInput.WindowZone = parseFloat(this.windowZone);
      this.structuralModel.nfWindLoadInput.IncludeCpi = this.internalPressure;
      if(this.internalPressure){
        this.structuralModel.nfWindLoadInput.pCpi = parseFloat(this.positiveInternalPressure.replace(',','.'));
        this.structuralModel.nfWindLoadInput.nCpi = parseFloat(this.negativeInternalPressure.replace(',','.'));
      } else {
        this.structuralModel.nfWindLoadInput.pCpi = 0;
        this.structuralModel.nfWindLoadInput.nCpi = 0;
      }
      this.structuralModel.WindLoadInputType = 3;
      this.structuralModel.DispIndexType = this.dispIndexType_obs;
      this.structuralModel.nfWindLoadInput.PostCodeValue = this.postcodeValue;
    }
  }

  createUnified3DModel_SM() {
    if (this.windLoadOption == 'NO USER') {
      if (!this.structuralModel) this.umService.getNewStructuralModel();
      if (!this.structuralModel.nfsWindLoadInput) this.structuralModel.nfsWindLoadInput = { WindZone: null, TerrainCategory: null, L0: null, B0: null, h: null, ElvW: null, WindowZone: null, IncludeCpi: null, pCpi: null, nCpi: null, RequestDescription: false, PostCodeValue: null };
      this.structuralModel.WindLoad = this.windLoad;
      this.structuralModel.nfsWindLoadInput.WindZone = this.nfSimplifiedWinLoad.WindZone;
      this.structuralModel.nfsWindLoadInput.TerrainCategory = parseFloat(this.terrainCategory);
      this.structuralModel.nfsWindLoadInput.h = this.buildingHeight;
      this.structuralModel.nfsWindLoadInput.ElvW = this.windowElevation? this.windowElevation : -1;
      this.structuralModel.nfsWindLoadInput.IncludeCpi = false;
      this.structuralModel.nfsWindLoadInput.pCpi = 0;
      this.structuralModel.nfsWindLoadInput.nCpi = 0;
      this.structuralModel.WindLoadInputType = 4;
      this.structuralModel.DispIndexType = this.dispIndexType_obs;
      this.structuralModel.nfsWindLoadInput.PostCodeValue = this.postcodeValue;
    }
  }

  isConfirmBtnDisable(): boolean {
    switch (this.windLoadSelectedText) {
      case 'DIN EN 1991-1-4':
      case 'NF EN 1991-1-4':
        let disableBool: boolean;
        if (this.umService.isWindow()) {
          disableBool = !(this.validateForm.controls['terrainCategoryControl'].valid && this.validateForm.controls['buildingWidth'].valid && this.validateForm.controls['buildingDepth'].valid && this.validateForm.controls['buildingHeight'].valid && this.validateForm.controls['windowElevation'].valid && this.validateForm.controls['windowZone'].valid && ((this.siteInformation == 'zipeCode' && this.validateForm.controls['postCode'].valid) || (this.siteInformation == 'windZone' && this.validateForm.controls['windZoneControl'].valid)));
        }
        else {
          disableBool = !(this.validateForm.controls['terrainCategoryControl'].valid && this.validateForm.controls['buildingWidth'].valid && this.validateForm.controls['buildingDepth'].valid && this.validateForm.controls['buildingHeight'].valid && this.validateForm.controls['windowZone'].valid && ((this.siteInformation == 'zipeCode' && this.validateForm.controls['postCode'].valid) || (this.siteInformation == 'windZone' && this.validateForm.controls['windZoneControl'].valid)));
        }
        if(this.internalPressure) {
          disableBool = !(this.validateForm.controls['terrainCategoryControl'].valid && this.validateForm.controls['buildingWidth'].valid && this.validateForm.controls['buildingDepth'].valid && this.validateForm.controls['buildingHeight'].valid && this.validateForm.controls['windowZone'].valid && ((this.siteInformation == 'zipeCode' && this.validateForm.controls['postCode'].valid) || (this.siteInformation == 'windZone' && this.validateForm.controls['windZoneControl'].valid)) && this.validateForm.controls['positiveInternalPressureControl'].valid && this.validateForm.controls['negativeInternalPressureControl'].valid);
        }
        return disableBool;
      case 'SM':
        let disableBool2: boolean;
        if (this.umService.isWindow()) {
          disableBool2 = !(this.validateForm.controls['terrainCategoryControl'].valid
                        && this.validateForm.controls['buildingHeight'].valid
                        && ((this.siteInformation == 'zipeCode'
                        && this.validateForm.controls['postCode'].valid)
                        || (this.siteInformation == 'windZone' && this.validateForm.controls['windZoneControl'].valid)));
        }
        else {
          disableBool2 = !(this.validateForm.controls['terrainCategoryControl'].valid
                        && this.validateForm.controls['buildingHeight'].valid
                        && ((this.siteInformation == 'zipeCode'
                        && this.validateForm.controls['postCode'].valid)
                        || (this.siteInformation == 'windZone' && this.validateForm.controls['windZoneControl'].valid)));
        }
        if(this.internalPressure) {
          disableBool2 = disableBool2 || !this.validateForm.controls['positiveInternalPressureControl'].valid || !this.validateForm.controls['negativeInternalPressureControl'].valid;
        }
        return disableBool2;
      case 'User Define':
        return !(this.validateForm.controls['pressureControl'].valid && this.validateForm.controls['suctionControl'].valid && this.validateForm.controls['packVelocityPressureControl'].valid);
      default:
        return false;
    }
  }

  onInternalPressureChange() {
    if(this.internalPressure) {
      if(!this.positiveInternalPressure || !this.negativeInternalPressure || this.positiveInternalPressure == "0" || this.negativeInternalPressure == "0") {
        this.positiveInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture("0.2");
        this.negativeInternalPressure = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture("-0.3");
      }
      this.validateForm.controls['negativeInternalPressureControl'].markAsTouched();
      this.validateForm.controls['positiveInternalPressureControl'].markAsTouched();
      if (this.vpService.isValidNumber_Negative(parseFloat(this.negativeInternalPressure.replace(',','.')))) {
        this.validateForm.controls['negativeInternalPressureControl'].setErrors({ 'correct': true });
      }
      if (this.vpService.isValidNumber_Positive(parseFloat(this.positiveInternalPressure.replace(',','.')))) {
        this.validateForm.controls['positiveInternalPressureControl'].setErrors({ 'correct': true });
      }
    }
    else {
      this.positiveInternalPressure = "0";
      this.negativeInternalPressure = "0";
    }
  }

  //Rama: V3
  showHidePopUp(isOpened: boolean): void {
    this.isStructuralTableOpened = isOpened;
    this.cpService.setPopout(this.isStructuralTableOpened, PanelsModule.WindLoad);
  }

}
