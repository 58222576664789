import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';

@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.css']
})
export class SustainabilityComponent implements OnInit, OnDestroy {

  validateForm: FormGroup;
  formStatusChangesSubscription: Subscription;

  @Output() sustainabilityChildEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private fb: FormBuilder,public umService: UnifiedModelService, private cpService: ConfigPanelsService) {
    this.validateForm = this.fb.group({
      surfaceTreatmentsControl: ['', []],
      aluminumGradeControl: ['', [Validators.required]],
      electricityControl: [0, [Validators.required, Validators.max(10000), Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    this.formStatusChangesSubscription = this.validateForm.statusChanges.subscribe((status) => {
      this.sustainabilityChildEvent.emit(status === 'VALID');
    });
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].markAsTouched();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.formStatusChangesSubscription.unsubscribe();
  }

  onOpenTransportation() {
    this.cpService.setPopout(true, PanelsModule.Transportation);
  }

  onSwitchSurfaceTreatment(isChecked: boolean) {
    this.umService.current_UnifiedModel.ModelInput.Sustainability.SurfaceTreatment = isChecked ? 'Powder Coating':'';
  }

}
