<div [ngClass]="{'hidden': !displayTable}">
    <div class="overlay-left"></div>
    <div class="overlay-right"></div>
    <div class="table">
        <div class="border-left"></div>
        <div class="table-center">
            <div class="top-container">
                <div class="heading-container">
                    <h3 class="heading">
                        {{'configure.thermal-sun-shading-library' | translate }}
                    </h3>
                    <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" class="cross-box" (click)="onCloseTable()">
                </div>
                <div class="hide-input-search table-container">
                    <bps-table #table
                        [data]="listOfDisplayData"
                        [scroll]="{ y: 'calc(100vh - 240px)'}"
                        [showPagination]="false"
                        [frontPagination]="false"
                        (onclickRow)="onClickRow($event)"
                        [tableType]="'glass_profile'"
                        class="bps-table-example-glass"
                        (sortChange)="sort($event)"
                        (ondblclickRow)="ondblclickRow($event)"
                        [(config)]="configurationCustomGrid">
                    </bps-table>
                </div>
            </div>
            <div class="bottom-container shadow-line-up">
                <button style="width: 80px" bps-button bpsType="variation-4" (click)="onConfirm();onCloseTable();">{{'configure.confirm' | translate }}</button>
            </div>
        </div>
        <div class="border-right"></div>
    </div>
</div>

<ng-template #col2Heading>
    <div>{{'configure.thermal-delta-r' | translate}}</div>
    <div style="color: #999999">(m².K/W)</div>
</ng-template>