import { Injectable } from '@angular/core';
import { AppconstantsService } from 'src/app/app-common/services/appconstants.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { WindLoadOutput } from '../models/wind-load-output';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { BpsUnifiedProblem } from 'src/app/app-common/models/bps-unified-problem';
import { BpsSimplifiedProblem } from 'src/app/app-common/models/bps-simplified-problem';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { OrderApiModel } from '../models/order-model';
import { CommonService } from 'src/app/app-common/services/common.service';
import { ConfigPanelsService } from './config-panels.service';
import { UnifiedModelService } from './unified-model.service';
import { GlassSRS } from '../models/glass-srs.model';
import { GlassBPS } from '../models/glass-bps.model';
import { FormControl } from '@angular/forms';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { DinWindLoadInput } from 'src/app/app-common/models/unified-model/DinWindLoadInput';
import { NFWindLoadInput } from 'src/app/app-common/models/unified-model/NFWindLoadInput';
import { NFSimplifiedWindLoadInput } from 'src/app/app-common/models/unified-model/NFSimplifiedWindLoadInput';
import { PermissionService } from 'src/app/app-core/services/permission.service';
import { Feature } from 'src/app/app-core/models/feature';

@Injectable({
  providedIn: 'root'
})
export class ConfigureService {

  problemSubject: Subject<BpsUnifiedProblem> = new Subject<BpsUnifiedProblem>();
  throwUnableToSaveNotificationSubject: Subject<void> = new Subject<void>();
  problemGuid: string = null;
  problem: BpsUnifiedProblem = new BpsUnifiedProblem();
  problemSetting: any;
  currentUnifiedModel: any;
  problemList: BpsUnifiedProblem[];

  //static physicsTypeSelected: Array<string> = ["Acoustic", "Structural", "Thermal"];
  // isNavigatedFromRightresult:boolean = false;
  computeClickedSubject: Subject<boolean> = new Subject<boolean>();
  checkoutButtonEnabled: Subject<boolean> = new Subject<boolean>();

  isComputeEnabled: boolean = false;
  saveProblemSubject: Subject<boolean> = new Subject<boolean>();
  isProblemSavedFromNavBar: Subject<boolean> = new Subject<boolean>();

  saveProblemFromRightPanelSubject: Subject<boolean> = new Subject<boolean>();
  runLoadJson: Subject<boolean> = new Subject<boolean>();
  runLoadJsonSelect: Subject<boolean> = new Subject<boolean>();
  runLoadJsonSelectForResult: Subject<boolean> = new Subject<boolean>();
  isProblemSavedForRightPanel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  editConfigurationName: Subject<string> = new Subject<string>();

  isSaved: boolean = true;
  lastModifiedDateForUM: Date = null;
  isCopyCall: boolean = false;
  isSelectedFromRightPanel: boolean = false;
  isNewProblemCreated: boolean = false;
  isSelectedFromRightResultPanel: boolean = false;
  isDefaultProblemCreatedFromRightPanel: boolean = false;
  configureCall: boolean = false;
  public newProblemBool: boolean = false;
  public previousProblem_ProjectGuid: string;
  showLoader: boolean = false;

  applicationType: string = '';
  userAccessRole: string = '';
  subTotalFromIframeSubject: Subject<number> = new Subject<number>();
  quickCheckPassed: Subject<boolean> = new Subject<boolean>();

  orderModel_shipping_Address: any;
  orderModel_show_ShipToAddress: string;

  isAccordianDisabledSubject: Subject<boolean> = new Subject<boolean>();
  computeBoolSubject: Subject<boolean> = new Subject<boolean>();
  onComputeSubject: Subject<any> = new Subject<any>();

  subSettings: Subject<boolean> = new Subject<boolean>();
  feature = Feature;
  

  constructor(private cpService: ConfigPanelsService, private appConstantService: AppconstantsService, private permissionService: PermissionService,
    private umService: UnifiedModelService, private http: HttpClient, private cookieService: CookieService, private commonService: CommonService,
    private navLayoutService: NavLayoutService, private localStorageService: LocalStorageService, private translate: TranslateService) {
    //this.problemGuid = this.cookieService.get('problemGuid') ? this.cookieService.get('problemGuid') : this.navLayoutService.getRouteParam('problemGuid') ? this.navLayoutService.getRouteParam('problemGuid') : '';
    this.problemGuid = this.getCurrentProblemGuid();
    this.applicationType = this.commonService.getApplicationType();
    this.glassBPSInformation = [];
    this.glassSRSInformation = [];
    if (this.problemGuid) {
      //this.GetProblemByGuid(this.problemGuid);
      setTimeout(() => {
        this.getGlassInfo(this.applicationType).subscribe(glassList => {
          if (this.applicationType === 'BPS')
            this.glassBPSInformation = JSON.parse(glassList);
          else
            this.glassSRSInformation = JSON.parse(glassList);
        });
      }, 1000);
    };
  }
  GetProblemByGuid(problemGuid: string): void {
    this.umService.setProblemGuid(problemGuid);
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/GetProblemByGuid/" + problemGuid;
    this.http.get<BpsUnifiedProblem>(url).subscribe(problem => {
      if (problem) {
        this.problem = problem;
        this.umService.setUnifiedProblem(problem);
        let unifiedModel: UnifiedModel = JSON.parse(this.problem.UnifiedModel);
        if (unifiedModel.ProblemSetting.ProductType === 'SlidingDoor') {
          this.setMullionBarDisplay(false);
        } else {
          this.setMullionBarDisplay(true);
        }
        this.cpService.closeAllPopouts();
        this.problem.ModifiedOn = this.convertUTCDateToLocalDate(new Date(problem.ModifiedOn));
        if (unifiedModel && unifiedModel.ProblemSetting) {
          this.problemSetting = unifiedModel.ProblemSetting;
          this.subSettings.next(true);
        }
        this.problemSubject.next(this.problem);
        this.previousProblem_ProjectGuid = unifiedModel.ProblemSetting ? unifiedModel.ProblemSetting.ProjectGuid : null;
      }
    });
  }

  getCurrentProblemGuid() {
    if (!this.problemGuid) {
      this.problemGuid = this.cookieService.get('problemGuid') ? this.cookieService.get('problemGuid') : this.navLayoutService.getRouteParam('problemGuid') ? this.navLayoutService.getRouteParam('problemGuid') : '';
    } return this.problemGuid;
  }

  // GetProblemScreenShot(problemGuid: string): Observable<string> {
  //   let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/GetProblemScreenShot/" + problemGuid;
  //   return this.http.get<string>(url);
  // }

  emitIsAccordian(isEnable: boolean): void {
    this.isAccordianDisabledSubject.next(isEnable);
  }
  
  emitComputeBool(isEnable: boolean): void {
    this.computeBoolSubject.next(isEnable);
  }

  emitOnCompute(): void {
    this.onComputeSubject.next();
  }

  emitProblemByGuid(): void {
    this.problemSubject.next(this.problem);
  }

  setProblemShow(problemGuid: string): void {
    if (this.cookieService.get('problemGuid')) {
      this.cookieService.delete('problemGuid');
    }
    this.cookieService.set('problemGuid', problemGuid, 0, '/');
    // if (this.cookieService.check('projectGuid')) {
    //   this.cookieService.delete('projectGuid');    }
    //this.cookieService.set('projectGuid', projectGuid,0,'/');
    if (this.configureCall) {
      this.configureCall = false;
      this.GetProblemByGuid(problemGuid);
    }
  }

  //#region rightPanelOpened
  rightPanelOpened: boolean = false;
  rightPanelOpenedSubject: Subject<boolean> = new Subject<boolean>();
  changeRightPanelDisplay(): void {
    this.rightPanelOpened = !this.rightPanelOpened;
    this.rightPanelOpenedSubject.next(this.rightPanelOpened);
  } 
  //#endregion

  //#region overlay
  rightPanelOverlay: boolean = false;
  rightPanelOverlaySubject: Subject<boolean> = new Subject<boolean>();
  obsRightPanelOverlay = this.rightPanelOverlaySubject.asObservable();
  //#endregion

  //#region ASE Type Change
  aseTypeChange: boolean = false;
  aseTypeChangeSubject: Subject<UnifiedModel> = new Subject<UnifiedModel>();
  obsASETypeChange = this.aseTypeChangeSubject.asObservable();
  // CallASETypeChange(aseType: string): void {
  //   this.GetProblemForSlidingDoorProject(this.umService.current_UnifiedModel.ProblemSetting.ProjectGuid, this.umService.current_UnifiedModel.ProblemSetting.ProblemGuid, aseType).subscribe((problem) => {
  //     this.umService.setUnifiedModel(JSON.parse(problem.UnifiedModel));
  //   //  this.aseTypeChangeSubject.next(JSON.parse(problem.UnifiedModel));
  //   });
  // }
  //#endregion

   //#region Mullion, Transom, Delete Bar display
   isMullionBarDisplay: boolean = true;
   sujMullionButtonsBar: Subject<boolean> = new Subject<boolean>();
   obsMullionButtonsBar = this.sujMullionButtonsBar.asObservable();
   setMullionBarDisplay(isDisplay: boolean): void {
     this.isMullionBarDisplay = isDisplay;
     this.sujMullionButtonsBar.next(this.isMullionBarDisplay);
   }
   //#endregion

   //#region info bar selection display
   isInfoBtnSelected: boolean = false;
   sujInfoBtn: Subject<boolean> = new Subject<boolean>();
   obsInfoBtn = this.sujInfoBtn.asObservable();
   setInfoButtonDisplay(isDisplay: boolean): void {
     this.isInfoBtnSelected = isDisplay;
     this.sujInfoBtn.next(this.isInfoBtnSelected);
   }
   //#endregion

  GetProblemsForProject(projectGuid: string): Observable<BpsUnifiedProblem[]> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/GetProblemsForProject/" + projectGuid;
    return this.http.get<BpsUnifiedProblem[]>(url);
  }

  GetProblemsForProjectLite(projectGuid: string): Observable<BpsUnifiedProblem[]> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/GetProblemsForProjectLite/" + projectGuid;
    return this.http.get<BpsUnifiedProblem[]>(url);
  }
  CreateProblemForProject(projectGuid: string): Observable<BpsUnifiedProblem> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/CreateDefaultProblemForProject/" + projectGuid + "/" + this.applicationType;
    return this.http.get<BpsUnifiedProblem>(url);
  }

  CreateProblemFacadeForProject(projectGuid: string, navLayout: any): Observable<BpsUnifiedProblem> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/CreateDefaultProblemForFacadeProject/" + projectGuid;
    return this.http.post<BpsUnifiedProblem>(url, navLayout);
  }

  GetWindZoneDE(PostCode: string): Observable<any> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/GetWindZoneDE/" + PostCode;
    return this.http.get<any>(url);
  }
  GetWindZoneFR(PostCode: string): Observable<any> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/GetWindZoneFR/" + PostCode;
    return this.http.get<any>(url);
  }

  generatePDF(fileName: string) {
    let url = this.appConstantService.APP_DOMAIN + fileName;
    const headers = new HttpHeaders()
      .set('Accept', 'text/html')
      .set('Access-Control-Allow-Origin', '*')
    const options = {
      'headers': headers,
    };
    return this.http.get(url);
  }
  //   GetReport(reportRequest: any): Observable<any>  {
  //     let url = this.appConstantService.APP_DOMAIN + 'api/BpsProject/GetReport';
  //     return this.http.post<any>(url, reportRequest, {observe: 'response'
  // ,responseType: 'blob' as 'json'
  //     });
  //   }
  GetReport(reportRequest: any): Observable<HttpResponse<Blob>> {
    let url = this.appConstantService.APP_DOMAIN + 'api/BpsProject/GetReport';
    return this.http.post<Blob>(url, reportRequest, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  GetPCReport(ProjectGuid: string, ProblemGuid: string, reportName: string): Observable<HttpResponse<Blob>> {
    let url = this.appConstantService.PHYSICS_CORE_DOMAIN + 'api/Report/DownloadReport/' + ProjectGuid + '/' + ProblemGuid + '/' + reportName;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
  
  GetPCReportForRequestedLanguage(ProjectGuid: string, ProblemGuid: string, reportName: string, requestedLanguage: string): Observable<HttpResponse<Blob>> {
    let url = this.appConstantService.PHYSICS_CORE_DOMAIN + 'api/Report/DownloadLanguageOptionReport/' + ProjectGuid + '/' + ProblemGuid + '/' + reportName+ '/' + requestedLanguage;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  GetReportFromPhysicsCore(reportURL: string): Observable<HttpResponse<Blob>> {
    let url = this.appConstantService.PHYSICS_CORE_DOMAIN + 'api/Report/GetReport?reportURL=' + reportURL;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  calculatedWindLoadDIN(input: DinWindLoadInput): Observable<WindLoadOutput> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/CalculateWindLoadDIN/";
    return this.http.post<WindLoadOutput>(url, input);
  }

  calculatedWindLoadNF(input: NFWindLoadInput): Observable<WindLoadOutput> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/CalculateWindLoadNF/";
    return this.http.post<WindLoadOutput>(url, input);
  }

  calculatedWindLoadSM(input: NFSimplifiedWindLoadInput): Observable<WindLoadOutput> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/CalculateWindLoadNFSimplified/";
    return this.http.post<WindLoadOutput>(url, input);
  }

  ReadSectionProperties(unified3DModel: UnifiedModel): Observable<UnifiedModel> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/ReadSectionProperties/";
    return this.http.post<UnifiedModel>(url, unified3DModel);
  }
  ReadFacadeSectionProperties(unified3DModel: UnifiedModel): Observable<UnifiedModel> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Structural/ReadFacadeSectionProperties/";
    return this.http.post<UnifiedModel>(url, unified3DModel);
  }

  // placeOrder(projectGuid: string) {
  //   let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/PlaceOrder/" + projectGuid;
  //   return this.http.get(url);
  // }

  GetProjectOrders(projectGuid: string): Observable<OrderApiModel> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Order/GetProjectOrders/" + projectGuid;
    return this.http.get<OrderApiModel>(url);
  }

  CreateOrders(newOrders: OrderApiModel) {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Order/PostOrders/";
    return this.http.post<UnifiedModel>(url, newOrders);
  }
  updateProblem(unified3DModel: UnifiedModel) {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/UpdateProblem/";
    return this.http.post<number>(url, unified3DModel);
  }
  updateOperabilitySystemsIds(unified3DModel: UnifiedModel): UnifiedModel {
    if (unified3DModel && unified3DModel.ModelInput.Geometry.OperabilitySystems) {
      let id = 1;
      unified3DModel.ModelInput.Geometry.OperabilitySystems.forEach(element => {
        let os = unified3DModel.ModelInput.Geometry.OperabilitySystems;
        let inf = unified3DModel.ModelInput.Geometry.Infills;
        let currentId = element.OperabilitySystemID;
        os = os.filter(f => f.OperabilitySystemID == currentId).map(x => { x.OperabilitySystemID = id; return x });
        inf = inf.filter(f => f.OperabilitySystemID == currentId).map(x => { x.OperabilitySystemID = id; return x });
        id++;
      });
    }
    return unified3DModel;
  }
  uploadResults(formData: FormData) {
    // let headers = new Headers();
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/UploadResults/";
    return this.http.post<any>(url, formData);
  }
  saveProblemScreenShot(saveScreenShotRequest: any): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/SaveProblemScreenShotS3/";
    return this.http.post<string>(url, saveScreenShotRequest);
  }
  GetScreenshotURL(id: string): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Order/GetPresignedScreenshotURL/" + id;
    return this.http.get<string>(url);
  }

  glassSRSInformation: GlassSRS[];
  glassBPSInformation: GlassBPS[];
  getGlassInfo(applicationName: string): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Glass/GetGlassInfo/" + applicationName;
    return this.http.get<string>(url);
  }

  getArticleListByType(selectedType: string, isFrenchSystem: boolean): any[] {
    let value = "1.1";
    let uvalueByCulture = this.getStringFormat_withCorrectDecimalSeparator_byCulture(value);
    let pvb_1_byCulture = this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.38");
    let pvb_2_byCulture = this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.76");
    var doubleData = [];
    var tripleData = [];
    switch (selectedType) {
      case 'double_BPS':
        if(isFrenchSystem) {
           doubleData = [
            { id: 1, composition: "4-20A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:34, oitc: 27, sg1h: 0.547, sg2h: 0.079, sg1e: 0.547, sg2e: 0.088, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 2, composition: "4 G200-20A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: -1, vt: -5, stc:34, oitc: 27, sg1h: 0.524, sg2h: 0.089, sg1e: 0.524, sg2e: 0.089, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 3, composition: "4-16A-4FE ONE", type: "glass-Argon-glass", totalThickness: 24, totalThicknessUnit: "mm", uvalue: 1, rw: 33, spacer: 0, category: 'double', thickness: 24, shgc: 0.5, vt: 0.71, stc:30, oitc: 30, sg1h: 0.441, sg2h: 0.059, sg1e: 0.441, sg2e: 0.068, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 4, composition: "6-18A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 36, spacer: 0, category: 'double', thickness: 28, shgc: 0.61, vt: 0.79, stc:30, oitc: 30, sg1h: 0.532, sg2h: 0.077, sg1e: 0.532, sg2e: 0.089, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 5, composition: "8-16A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.59, vt: 0.78, stc:38, oitc: 31, sg1h: 0.518, sg2h: 0.076, sg1e: 0.518, sg2e: 0.091, element_xx_1: null, element_type_1: "glass", element_size_1: "8", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 6, composition: "10-14A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.58, vt: 0.78, stc:37, oitc: 31, sg1h: 0.501, sg2h: 0.074, sg1e: 0.501, sg2e: 0.094, element_xx_1: null, element_type_1: "glass", element_size_1: "10", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 7, composition: "44.2 sil FE-10A-10", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.4, rw: 39, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.52, vt: 0.76, stc:39, oitc: 32, sg1h: 0.452, sg2h: 0.072, sg1e: 0.452, sg2e: 0.103, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 10, element_xx_2: null, element_type_2: "glass", element_size_2: "10", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 8, composition: "44.2-16A-4FE", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.56, vt: 0.78, stc: 40, oitc: 33, sg1h: 0.49, sg2h: 0.07, sg1e: 0.49, sg2e: 0.091, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 9, composition: "55.2-14A-4FE", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.55, vt: 0.77, stc: 40, oitc: 33, sg1h: 0.478, sg2h: 0.069, sg1e: 0.478, sg2e: 0.094, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "5/5", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 10, composition: "33.2-12A-44.2FE", type: "lamiPVB-Argon-lamiPVB", totalThickness: 27.5, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 27.5, shgc: 0.57, vt: 0.77, stc: 40, oitc: 33, sg1h: 0.467, sg2h: 0.102, sg1e: 0.467, sg2e: 0.124, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "3/3", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 12, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 11, composition: "44.2-10A-44.2FE", type: "lamiPVB-Argon-lamiPVB", totalThickness: 27.5, totalThicknessUnit: "mm", uvalue: 1.4, rw: 40, spacer: 0, category: 'double', thickness: 27.5, shgc: 0.56, vt: 0.76, stc: 40, oitc: 34, sg1h: 0.456, sg2h: 0.099, sg1e: 0.456, sg2e: 0.127, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 10, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 12, composition: "SP10-14A-4FE", type: "glass-Argon-glass", totalThickness: 28.3, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28.3, shgc: 0.54, vt: 0.77, stc: 37, oitc: 31, sg1h: 0.47, sg2h: 0.069, sg1e: 0.47, sg2e: 0.096, element_xx_1: null, element_type_1: "glass", element_size_1: "10", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 13, composition: "5 ANTELIO CLAIR-18A-4FE", type: "glass-Argon-glass", totalThickness: 27, totalThicknessUnit: "mm", uvalue: 1.1, rw: 35, spacer: 0, category: 'double', thickness: 27, shgc: 0.37, vt: 0.41, stc: 35, oitc: 28, sg1h: 0.314, sg2h: 0.057, sg1e: 0.314, sg2e: 0.076, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3:null , element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 14, composition: "6 ANTELIO ARGENT-18A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 36, spacer: 0, category: 'double', thickness: 28, shgc: 0.48, vt: 0.59, stc: 37, oitc: 29, sg1h: 0.414, sg2h: 0.063, sg1e: 0.414, sg2e: 0.074, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 15, composition: "5 ANTELIO HAVANE-18A-4FE", type: "glass-Argon-glass", totalThickness: 27, totalThicknessUnit: "mm", uvalue: 1.1, rw: 35, spacer: 0, category: 'double', thickness: 27, shgc: 0.24, vt: 0.24, stc: 35, oitc: 28, sg1h: 0.187, sg2h: 0.055, sg1e: 0.187, sg2e: 0.098, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 16, composition: "5 ANTELIO CLAIR-14A-44.2FE", type: "glass-Argon-lamiPVB", totalThickness: 27.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 27.8, shgc: 0.37, vt: 0.4, stc: 40, oitc: 33, sg1h: 0.282, sg2h: 0.086, sg1e: 0.282, sg2e: 0.109, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 17, composition: "6 ANTELIO ARGENT-14A-44.2FE", type: "glass-Argon-lamiPVB", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.47, vt: 0.58, stc: 40, oitc: 34, sg1h: 0.372, sg2h: 0.1, sg1e: 0.372, sg2e: 0.113, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 18, composition: "5 ANTELIO HAVANE-14A-44.2FE", type: "glass-Argon-lamiPVB", totalThickness: 27.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 27.8, shgc: 0.23, vt: 0.23, stc: 40, oitc: 33, sg1h: 0.164, sg2h: 0.064, sg1e: 0.164, sg2e: 0.105, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 19, composition: "4 PLANISTAR-20A-4", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: 0.38, vt: 0.71, stc: 34, oitc: 27, sg1h: 0.355, sg2h: 0.023, sg1e: 0.355, sg2e: 0.039, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 20, composition: "4 PLANISTAR-16A-44.2", type: "glass-Argon-lamiPVB", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.38, vt: 0.7, stc: 40, oitc: 33, sg1h: 0.328, sg2h: 0.048, sg1e: 0.328, sg2e: 0.068, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1:"Argon" , element_ins_size_1: 16, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 21, composition: "44.2 G200-16A-4FE", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.54, vt: 0.75, stc: 40, oitc: 33, sg1h: 0.47, sg2h: 0.07, sg1e: 0.47, sg2e: 0.093, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 22, composition: "4 G200-14A-SP10FE", type: "glass-Argon-glass", totalThickness: 28.3, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28.3, shgc: 0.59, vt: 0.74, stc: 37, oitc: 31, sg1h: 0.45, sg2h: 0.143, sg1e: 0.45, sg2e: 0.157, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "14", element_xx_2: null, element_type_2: "glass", element_size_2: "10", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 23, composition: "4 Dépoli acide-20A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: 0.62, vt: 0.79, stc: 34, oitc: 27, sg1h: 0.54, sg2h: 0.079, sg1e: 0.54, sg2e: 0.09, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true"},
            { id: 24, composition: "6 G200-18A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 36, spacer: 0, category: 'double', thickness: 28, shgc: 0.57, vt: 0.74, stc: 37, oitc: 29, sg1h: 0.499, sg2h: 0.075, sg1e: 0.499, sg2e: 0.091, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "18", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 25, composition: "8 G200-16A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.55, vt: 0.72, stc: 38, oitc: 31, sg1h: 0.475, sg2h: 0.073, sg1e: 0.475, sg2e: 0.094, element_xx_1: null, element_type_1: "glass", element_size_1: "8", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "16", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 26, composition: "10 G200-14A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.53, vt: 0.69, stc: 37, oitc: 31, sg1h: 0.453, sg2h: 0.072, sg1e: 0.453, sg2e: 0.098 , element_xx_1: null, element_type_1: "glass", element_size_1: "10", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1:"14" , element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 27, composition: "4-16-4", type: "glass-argon-glass", totalThickness: 24, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 31, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 28, composition: "6-16-6", type: "glass-argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 33, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 29, composition: "6-16-4", type: "glass-argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 35, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 30, composition: "8-16-4", type: "glass-argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0., element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 31, composition: "10-16-6", type: "glass-argon-glass", totalThickness: 32, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 39, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 32, composition: "6-20-8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass", totalThickness: 34, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 43, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 33, composition: "8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-16-12", type: "glass-argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 46, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 34, composition: "12(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-20-8(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass", totalThickness: 40, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 49, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 35, composition: "8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-24-10", type: "glass-argon-glass", totalThickness: 42, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 47, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 36, composition: "12(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-24-8(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass", totalThickness: 44, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 50, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  }
          ];
        } else {
           doubleData = [
            { id: 1, composition: "4-16-4", type: "glass-argon-glass", totalThickness: 24, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 31, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 2, composition: "6-16-6", type: "glass-argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 33, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 3, composition: "6-16-4", type: "glass-argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 35, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 4, composition: "8-16-4", type: "glass-argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0., element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 5, composition: "10-16-6", type: "glass-argon-glass", totalThickness: 32, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 39, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 6, composition: "6-20-8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass", totalThickness: 34, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 43, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 7, composition: "8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-16-12", type: "glass-argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 46, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 8, composition: "12(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-20-8(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass", totalThickness: 40, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 49, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 9, composition: "8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-24-10", type: "glass-argon-glass", totalThickness: 42, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 47, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 10, composition: "12(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)-24-8(" + pvb_2_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass", totalThickness: 44, totalThicknessUnit: "mm", uvalue: uvalueByCulture, rw: 50, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false"  },
            { id: 11, composition: "4-20A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: 0.63, vt: 0.8, stc:34, oitc: 27, sg1h: 0.547, sg2h: 0.079, sg1e: 0.547, sg2e: 0.088, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 12, composition: "4 G200-20A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: -1, vt: -5, stc:34, oitc: 27, sg1h: 0.524, sg2h: 0.089, sg1e: 0.524, sg2e: 0.089, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 13, composition: "4-16A-4FE ONE", type: "glass-Argon-glass", totalThickness: 24, totalThicknessUnit: "mm", uvalue: 1, rw: 33, spacer: 0, category: 'double', thickness: 24, shgc: 0.5, vt: 0.71, stc:30, oitc: 30, sg1h: 0.441, sg2h: 0.059, sg1e: 0.441, sg2e: 0.068, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 14, composition: "6-18A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 36, spacer: 0, category: 'double', thickness: 28, shgc: 0.61, vt: 0.79, stc:30, oitc: 30, sg1h: 0.532, sg2h: 0.077, sg1e: 0.532, sg2e: 0.089, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 15, composition: "8-16A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.59, vt: 0.78, stc:38, oitc: 31, sg1h: 0.518, sg2h: 0.076, sg1e: 0.518, sg2e: 0.091, element_xx_1: null, element_type_1: "glass", element_size_1: "8", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 16, composition: "10-14A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.58, vt: 0.78, stc:37, oitc: 31, sg1h: 0.501, sg2h: 0.074, sg1e: 0.501, sg2e: 0.094, element_xx_1: null, element_type_1: "glass", element_size_1: "10", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null,  element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 17, composition: "44.2 sil FE-10A-10", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.4, rw: 39, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.52, vt: 0.76, stc:39, oitc: 32, sg1h: 0.452, sg2h: 0.072, sg1e: 0.452, sg2e: 0.103, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 10, element_xx_2: null, element_type_2: "glass", element_size_2: "10", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 18, composition: "44.2-16A-4FE", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.56, vt: 0.78, stc: 40, oitc: 33, sg1h: 0.49, sg2h: 0.07, sg1e: 0.49, sg2e: 0.091, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 19, composition: "55.2-14A-4FE", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.55, vt: 0.77, stc: 40, oitc: 33, sg1h: 0.478, sg2h: 0.069, sg1e: 0.478, sg2e: 0.094, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "5/5", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 20, composition: "33.2-12A-44.2FE", type: "lamiPVB-Argon-lamiPVB", totalThickness: 27.5, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 27.5, shgc: 0.57, vt: 0.77, stc: 40, oitc: 33, sg1h: 0.467, sg2h: 0.102, sg1e: 0.467, sg2e: 0.124, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "3/3", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 12, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 21, composition: "44.2-10A-44.2FE", type: "lamiPVB-Argon-lamiPVB", totalThickness: 27.5, totalThicknessUnit: "mm", uvalue: 1.4, rw: 40, spacer: 0, category: 'double', thickness: 27.5, shgc: 0.56, vt: 0.76, stc: 40, oitc: 34, sg1h: 0.456, sg2h: 0.099, sg1e: 0.456, sg2e: 0.127, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 10, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 22, composition: "SP10-14A-4FE", type: "glass-Argon-glass", totalThickness: 28.3, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28.3, shgc: 0.54, vt: 0.77, stc: 37, oitc: 31, sg1h: 0.47, sg2h: 0.069, sg1e: 0.47, sg2e: 0.096, element_xx_1: null, element_type_1: "glass", element_size_1: "10", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 23, composition: "5 ANTELIO CLAIR-18A-4FE", type: "glass-Argon-glass", totalThickness: 27, totalThicknessUnit: "mm", uvalue: 1.1, rw: 35, spacer: 0, category: 'double', thickness: 27, shgc: 0.37, vt: 0.41, stc: 35, oitc: 28, sg1h: 0.314, sg2h: 0.057, sg1e: 0.314, sg2e: 0.076, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3:null , element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 24, composition: "6 ANTELIO ARGENT-18A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 36, spacer: 0, category: 'double', thickness: 28, shgc: 0.48, vt: 0.59, stc: 37, oitc: 29, sg1h: 0.414, sg2h: 0.063, sg1e: 0.414, sg2e: 0.074, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 25, composition: "5 ANTELIO HAVANE-18A-4FE", type: "glass-Argon-glass", totalThickness: 27, totalThicknessUnit: "mm", uvalue: 1.1, rw: 35, spacer: 0, category: 'double', thickness: 27, shgc: 0.24, vt: 0.24, stc: 35, oitc: 28, sg1h: 0.187, sg2h: 0.055, sg1e: 0.187, sg2e: 0.098, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 18, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 26, composition: "5 ANTELIO CLAIR-14A-44.2FE", type: "glass-Argon-lamiPVB", totalThickness: 27.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 27.8, shgc: 0.37, vt: 0.4, stc: 40, oitc: 33, sg1h: 0.282, sg2h: 0.086, sg1e: 0.282, sg2e: 0.109, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 27, composition: "6 ANTELIO ARGENT-14A-44.2FE", type: "glass-Argon-lamiPVB", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.47, vt: 0.58, stc: 40, oitc: 34, sg1h: 0.372, sg2h: 0.1, sg1e: 0.372, sg2e: 0.113, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 28, composition: "5 ANTELIO HAVANE-14A-44.2FE", type: "glass-Argon-lamiPVB", totalThickness: 27.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 27.8, shgc: 0.23, vt: 0.23, stc: 40, oitc: 33, sg1h: 0.164, sg2h: 0.064, sg1e: 0.164, sg2e: 0.105, element_xx_1: null, element_type_1: "glass", element_size_1: "5", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 14, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 29, composition: "4 PLANISTAR-20A-4", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: 0.38, vt: 0.71, stc: 34, oitc: 27, sg1h: 0.355, sg2h: 0.023, sg1e: 0.355, sg2e: 0.039, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 30, composition: "4 PLANISTAR-16A-44.2", type: "glass-Argon-lamiPVB", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.38, vt: 0.7, stc: 40, oitc: 33, sg1h: 0.328, sg2h: 0.048, sg1e: 0.328, sg2e: 0.068, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1:"Argon" , element_ins_size_1: 16, element_xx_2: null, element_type_2: "lamiPVB", element_size_2: "4/4", element_interlayer_2: 0.76, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 31, composition: "44.2 G200-16A-4FE", type: "lamiPVB-Argon-glass", totalThickness: 28.8, totalThicknessUnit: "mm", uvalue: 1.1, rw: 40, spacer: 0, category: 'double', thickness: 28.8, shgc: 0.54, vt: 0.75, stc: 40, oitc: 33, sg1h: 0.47, sg2h: 0.07, sg1e: 0.47, sg2e: 0.093, element_xx_1: null, element_type_1: "lamiPVB", element_size_1: "4/4", element_interlayer_1: 0.76, element_ins_type_1: "Argon", element_ins_size_1: 16, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 32, composition: "4 G200-14A-SP10FE", type: "glass-Argon-glass", totalThickness: 28.3, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28.3, shgc: 0.59, vt: 0.74, stc: 37, oitc: 31, sg1h: 0.45, sg2h: 0.143, sg1e: 0.45, sg2e: 0.157, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "14", element_xx_2: null, element_type_2: "glass", element_size_2: "10", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 33, composition: "4 Dépoli acide-20A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 34, spacer: 0, category: 'double', thickness: 28, shgc: 0.62, vt: 0.79, stc: 34, oitc: 27, sg1h: 0.54, sg2h: 0.079, sg1e: 0.54, sg2e: 0.09, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: 20, element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 34, composition: "6 G200-18A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 36, spacer: 0, category: 'double', thickness: 28, shgc: 0.57, vt: 0.74, stc: 37, oitc: 29, sg1h: 0.499, sg2h: 0.075, sg1e: 0.499, sg2e: 0.091, element_xx_1: null, element_type_1: "glass", element_size_1: "6", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "18", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 35, composition: "8 G200-16A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.55, vt: 0.72, stc: 38, oitc: 31, sg1h: 0.475, sg2h: 0.073, sg1e: 0.475, sg2e: 0.094, element_xx_1: null, element_type_1: "glass", element_size_1: "8", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "16", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 36, composition: "10 G200-14A-4FE", type: "glass-Argon-glass", totalThickness: 28, totalThicknessUnit: "mm", uvalue: 1.1, rw: 37, spacer: 0, category: 'double', thickness: 28, shgc: 0.53, vt: 0.69, stc: 37, oitc: 31, sg1h: 0.453, sg2h: 0.072, sg1e: 0.453, sg2e: 0.098 , element_xx_1: null, element_type_1: "glass", element_size_1: "10", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1:"14" , element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "true" },
            
          ];
        }
        
        return doubleData;
      case 'triple_BPS':
        let value1 = "0.7";
        let uvalueByCulture1 = this.getStringFormat_withCorrectDecimalSeparator_byCulture(value1);
        if(isFrenchSystem) {
          tripleData = [
            { id: 1, composition: "4 PLTH LUX-10A-4 PLLUX-10A-4 PLTH LUX ", type: "glass-Argon-glass-Argon-glass", totalThickness: 32, totalThicknessUnit: "mm", uvalue: 0.9, rw: 34, spacer: 0, category: 'triple', shgc: 0.62, vt: 0.73, stc:32, oitc: 28, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "10", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "10", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 2, composition: "4 PLTH LUX-12A-4 PLLUX-12A-4 PLTH LUX ", type: "glass-Argon-glass-Argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: 0.8, rw: 32, spacer: 0, category: 'triple', shgc: 0.62, vt: 0.73, stc:32, oitc: 25, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "12", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "12", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 3, composition: "4 PLTH LUX-14A-4 PLLUX-14A-4 PLTH LUX ", type: "glass-Argon-glass-Argon-glass", totalThickness: 40, totalThicknessUnit: "mm", uvalue: 0.7, rw: 32, spacer: 0, category: 'triple', shgc: 0.62, vt: 0.73, stc:32, oitc: 24, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1:null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "14", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "14", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true"  },
            { id: 4, composition: "4 PLTH MAX-10A-4 DIAM-10A-4 PLTH MAX", type: "glass-Argon-glass-Argon-glass", totalThickness: 32, totalThicknessUnit: "mm", uvalue: 0.9, rw: 34, spacer: 0, category: 'triple', shgc: 0.6, vt: 0.74, stc:32, oitc: 28, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1:null , element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "10", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "10", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 5, composition: "4 PLTH MAX-12A-4 DIAM-12A-4 PLTH MAX", type: "glass-Argon-glass-Argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: 0.7, rw: 32, spacer: 0, category: 'triple', shgc: 0.6, vt: 0.74, stc:32, oitc: 25, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "12", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "12", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true"  },
            { id: 6, composition: "4 PLTH MAX-16A-4 DIAM-16A-4 PLTH MAX", type: "glass-Argon-glass-Argon-glass", totalThickness: 44, totalThicknessUnit: "mm", uvalue: 0.6, rw: 35, spacer: 0, category: 'triple', shgc: 0.6, vt: 0.74, stc:32, oitc: 37, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "16", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "16", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true"  },
            { id: 7, composition: "10-12-6-12-8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass-argon-glass", totalThickness: 48, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 49, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0 , element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 8, composition: "4-12-4-12-4", type: "glass-argon-glass-argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 32, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 9, composition: "6-12-4-12-4", type: "glass-argon-glass-argon-glass", totalThickness: 38, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 36, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 10, composition: "8-12-4-12-6", type: "glass-argon-glass-argon-glass", totalThickness: 42, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 39, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" }
          ];
        } else {
          tripleData = [
            { id: 1, composition: "10-12-6-12-8(" + pvb_1_byCulture + "mm " + this.translate.instant(_('configure.acoustic')) + " PVB)", type: "glass-argon-glass-argon-glass", totalThickness: 48, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 49, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0 , element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 2, composition: "4-12-4-12-4", type: "glass-argon-glass-argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 32, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 3, composition: "6-12-4-12-4", type: "glass-argon-glass-argon-glass", totalThickness: 38, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 36, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 4, composition: "8-12-4-12-6", type: "glass-argon-glass-argon-glass", totalThickness: 42, totalThicknessUnit: "mm", uvalue: uvalueByCulture1, rw: 39, spacer: 0, category: 'triple', shgc: 0.63, vt: 0.8, stc:0.079, oitc: 0.54, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: null, element_size_1: null, element_interlayer_1: null, element_ins_type_1: null, element_ins_size_1: null, element_xx_2: null, element_type_2: null, element_size_2: null, element_interlayer_2: null, element_ins_type_2: null,  element_ins_size_2: null, element_xx_3: null, element_type_3: null, element_size_3: null, element_interlayer_3: null, isFrenchSystemGlass: "false" },
            { id: 5, composition: "4 PLTH LUX-10A-4 PLLUX-10A-4 PLTH LUX ", type: "glass-Argon-glass-Argon-glass", totalThickness: 32, totalThicknessUnit: "mm", uvalue: 0.9, rw: 34, spacer: 0, category: 'triple', shgc: 0.62, vt: 0.73, stc:32, oitc: 28, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "10", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "10", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 6, composition: "4 PLTH LUX-12A-4 PLLUX-12A-4 PLTH LUX ", type: "glass-Argon-glass-Argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: 0.8, rw: 32, spacer: 0, category: 'triple', shgc: 0.62, vt: 0.73, stc:32, oitc: 25, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "12", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "12", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 7, composition: "4 PLTH LUX-14A-4 PLLUX-14A-4 PLTH LUX ", type: "glass-Argon-glass-Argon-glass", totalThickness: 40, totalThicknessUnit: "mm", uvalue: 0.7, rw: 32, spacer: 0, category: 'triple', shgc: 0.62, vt: 0.73, stc:32, oitc: 24, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1:null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "14", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "14", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true"  },
            { id: 8, composition: "4 PLTH MAX-10A-4 DIAM-10A-4 PLTH MAX", type: "glass-Argon-glass-Argon-glass", totalThickness: 32, totalThicknessUnit: "mm", uvalue: 0.9, rw: 34, spacer: 0, category: 'triple', shgc: 0.6, vt: 0.74, stc:32, oitc: 28, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1:null , element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "10", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "10", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true" },
            { id: 9, composition: "4 PLTH MAX-12A-4 DIAM-12A-4 PLTH MAX", type: "glass-Argon-glass-Argon-glass", totalThickness: 36, totalThicknessUnit: "mm", uvalue: 0.7, rw: 32, spacer: 0, category: 'triple', shgc: 0.6, vt: 0.74, stc:32, oitc: 25, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "12", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "12", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true"  },
            { id: 10, composition: "4 PLTH MAX-16A-4 DIAM-16A-4 PLTH MAX", type: "glass-Argon-glass-Argon-glass", totalThickness: 44, totalThicknessUnit: "mm", uvalue: 0.6, rw: 35, spacer: 0, category: 'triple', shgc: 0.6, vt: 0.74, stc:32, oitc: 37, sg1h: 0, sg2h: 0, sg1e: 0, sg2e: 0, element_xx_1: null, element_type_1: "glass", element_size_1: "4", element_interlayer_1: 0, element_ins_type_1: "Argon", element_ins_size_1: "16", element_xx_2: null, element_type_2: "glass", element_size_2: "4", element_interlayer_2: 0, element_ins_type_2: "Argon",  element_ins_size_2: "16", element_xx_3: null, element_type_3: "glass", element_size_3: "4", element_interlayer_3: null, isFrenchSystemGlass: "true"  }
          ];
        }
        return tripleData;
      case 'panel_BPS':
        var panelLibraryData = [
          { id: 1, composition: "2-20-2", type: "aluminium-insulation-aluminium", totalThickness: 24, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("1.17"), rw: 33, spacer: [""], category: 'panel'},
          { id: 2, composition: "6-20-2", type: "glass-insulation-aluminium", totalThickness: 28, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("1.34"), rw: 40, spacer: [""], category: 'panel'},
          { id: 3, composition: "2-40-2", type: "aluminium-insulation-aluminium", totalThickness: 44, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.76"), rw: 35, spacer: [""], category: 'panel'},
          { id: 4, composition: "6-40-2", type: "glass-insulation-aluminium", totalThickness: 48, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.76"), rw: 42, spacer: [""], category: 'panel'},
          { id: 5, composition: "2-100-2", type: "aluminium-insulation-aluminium", totalThickness: 104, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.33"), rw: 40, spacer: [""], category: 'panel'},
          { id: 6, composition: "6-100-2", type: "glass-insulation-aluminium", totalThickness: 108, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.33"), rw: 49, spacer: [""], category: 'panel'},
          { id: 7, composition: "2-26-120-2", type: "aluminium-air-insulation-aluminium", totalThickness: 150, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.26"), rw: 44, spacer: [""], category: 'panel'},
          { id: 8, composition: "6-22-120-2", type: "glass-air-insulation-aluminium", totalThickness: 150, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.27"), rw: 53, spacer: [""], category: 'panel'},
          { id: 9, composition: "2-220-2", type: "aluminium-insulation-aluminium", totalThickness: 224, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.15"), rw: 53, spacer: [""], category: 'panel'},
          { id: 10, composition: "6-220-2", type: "aluminium-insulation-aluminium", totalThickness: 228, totalThicknessUnit: "mm", uvalue: this.getStringFormat_withCorrectDecimalSeparator_byCulture("0.15"), rw: 62, spacer: [""], category: 'panel'}
        ];
        return panelLibraryData;
      case 'double_SRS':
        var doubleDataSRS = [
          { id: 1, composition: "1/4 Clear SB 60-1/2 ARGON-1/4 Clear", compositionShort: "1/4-1/2-1/4", type: "clear glass SB 60-argon-clear glass", totalThickness: 1, totalThicknessUnit: "in", uvalue: "1.358", shgc: 0.39, vt: 0.7023, rw: 35, rwc: -1, rwctr: -4, spacer: 0, category: 'double', stc: 35, oitc: 30 },
          { id: 2, composition: "1/4 Grey-1/2 AIR-1/4 Clear", compositionShort: "1/4-1/2-1/4", type: "grey glass-air-clear glass", totalThickness: 1, totalThicknessUnit: "in", uvalue: "2.688", shgc: 0.466, vt: 0.383, rw: 35, rwc: -1, rwctr: -4, spacer: 0, category: 'double', stc: 35, oitc: 30 },
          { id: 3, composition: "1/4 Clear SB 70-1/2 AIR-1/4 Clear", compositionShort: "1/4-1/2-1/4", type: "clear glass SB 70-air-clear glass", totalThickness: 1, totalThicknessUnit: "in", uvalue: "1.618", shgc: 0.273, vt: 0.637, rw: 35, rwc: -1, rwctr: -4, spacer: 0, category: 'double', stc: 35, oitc: 30 },
          { id: 4, composition: "1/4 Grey SB 70-1/2 AIR-1/4 Clear", compositionShort: "1/4-1/2-1/4", type: "grey glass SB 70-air-clear glass", totalThickness: 1, totalThicknessUnit: "in", uvalue: "1.618", shgc: 0.225, vt: 0.45, rw: 35, rwc: -1, rwctr: -4, spacer: 0, category: 'double', stc: 35, oitc: 30 },
          { id: 5, composition: "1/4 Bronze SB 70-1/2 AIR-1/4 Clear", compositionShort: "1/4-1/2-1/4", type: "bronze glass SB 70-air-clear glass", totalThickness: 1, totalThicknessUnit: "in", uvalue: "1.618", shgc: 0.202, vt: 0.38, rw: 35, rwc: -1, rwctr: -4, spacer: 0, category: 'double', stc: 35, oitc: 30 }
        ];
        return doubleDataSRS;
      case 'triple_SRS':
        var tripleDataSRS = [
          { id: 1, composition: "1/4-1/2 ARGON-1/4-1/2 ARGON-1/4", compositionShort: "1/4-1/2-1/4-1/2-1/4", type: "Optigray Glass-argon-Starphire Glass-argon-Clear glass", totalThickness: 1, thicknessFraction: "3/4", totalThicknessUnit: "in", uvalue: "0.648", shgc: 0.162, vt: 0.34, rw: 39, rwc: -2, rwctr: -5, spacer: 0, category: 'triple', stc: 39, oitc: 31 },
          //{ id: 1, composition: "1/4-1/2 ARGON-1/4-1/2 ARGON-1/4", compositionShort: "1/4-1/2-1/4-1/2-1/4", type: "Glass-argon-Glass-argon-Glass", totalThickness: 1, thicknessFraction: "3/4", totalThicknessUnit: "in", uvalue: "0.648", shgc: 0.114, vt: 0.162, rw: 39, rwc: -2, rwctr: -5, spacer: 0, category: 'triple', stc: 39, oitc: 31 },
        ];
        return tripleDataSRS;
    }
  }

  sendMessage(isClicked: boolean) {
    this.computeClickedSubject.next(isClicked);
  }

  //v3
  public getLanguage(): string {
    return this.localStorageService.getValue("culture") ? this.localStorageService.getValue("culture") : "en-US";
  }
  
  public getNumberPattern(): string {
    var language = this.getLanguage();
    var numberPattern = language == "en-US" ? "^[0-9]+[.]?[0-9]*$":"^[0-9]+[,]?[0-9]*$";
    return numberPattern;
  }
  public getNegNumberPattern(): string {
    var language = this.getLanguage();
    var numberPattern = language == "en-US" ? "^(?:([-][0-9]+[.]*[0-9]*)|([0]?[.]?[0]*))$":"^(?:([-][0-9]+[,]*[0-9]*)|([0]?[,]?[0]*))$";
    return numberPattern;
  }

  isProjectInFrance(): boolean {
    return this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('France') || 
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Frankreich') || 
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Guadeloupe') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('French Guiana') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Französisch-Guyana') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Martinique') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Mayotte') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Réunion');
  }

  isProjectInGermany(): boolean {
    return this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Germany') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Allemagne') ||
           this.umService.current_UnifiedModel.ProblemSetting.Location.endsWith('Deutschland');
  }


  getStringFormat_withCorrectDecimalSeparator_byCulture(myString: string, language: string = this.getLanguage()): string {
    if (myString == undefined || myString == null || typeof myString != 'string') return myString;
    switch(language) {
      case 'fr-FR':
      case 'de-DE':
      case 'es-ES':
      case 'it-IT':
      case 'pt-PT':
        return myString.replace(".", ",");
      case 'en-US':
      case 'en-UK':
      case 'cn-CN':
        return myString.replace(",", ".");
      default:
        return myString;
    }
  }

  getNumberFormat_withCorrectDecimalSeparator_byCulture(myString: string, language: string = this.getLanguage()): string {
    if (myString == undefined || myString == null || typeof myString != 'string') return myString;
    switch(language) {
      case 'fr-FR':
      case 'de-DE':
      case 'es-ES':
      case 'it-IT':
      case 'pt-PT':
        return myString.replace(",", ".");
      case 'en-US':
      case 'en-UK':
      case 'cn-CN':
      default:
        return myString;
    }
  }

  getDateFormat_byCulture(language: string = this.getLanguage()): string {
    switch(language) {
      case 'fr-FR':
      case 'de-DE':
      // case 'es-ES':
      // case 'it-IT':
      // case 'pt-PT':
        return 'dd_MMM_yyyy';
      case 'en-US':
      // case 'en-UK':
      // case 'cn-CN':
      default:
        return 'MMM_dd_yyyy';
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  // RenameProblem(unifiedModel: UnifiedModel): Observable<BpsSimplifiedProblem> {
  //   let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/RenameProblem/";
  //   return this.http.post<BpsSimplifiedProblem>(url, unifiedModel);
  // }
  RenameProblem(problemGuid: string, newName: string): Observable<BpsSimplifiedProblem> {
    this.computeClickedSubject.next(false); //added to force user to compute after renaming - to cover flattening issue
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/RenameProblem/"+problemGuid+"/"+newName;
    return this.http.post<BpsSimplifiedProblem>(url, '');
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  } 

  //Create default Problem for sliding Doors based on the TrackType Selection
  CreateProblemForSlidingDoorProject(projectGuid: string, aseType: string): Observable<BpsUnifiedProblem> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/CreateDefaultProblemForASEProject/" + projectGuid;
    return this.http.post<BpsUnifiedProblem>(url, aseType);
  }

  //Get Default Problem for Sliding Doors based on the TrackType Selection
  GetProblemForSlidingDoorProject(projectGuid: string, problemGuid: string, aseType: string): Observable<BpsUnifiedProblem> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/BpsProject/GetDefaultASEProblem/" + projectGuid + "/" + problemGuid + "/" + aseType;
    return this.http.post<any>(url, '');
  }

  getIsFramingCompatibleWithInsetUnitByLocation(system?: string) {
    if (!this.umService.isWindow()) {
      let systemCompatibility = this.umService.getCurrentSystemCompatibleForInsertUnit(system);
      return (this.isProjectInFrance() && systemCompatibility.FR) ||
          (this.isProjectInGermany() && systemCompatibility.DE) ||
          (!this.isProjectInFrance() && !this.isProjectInGermany() && systemCompatibility.US);
    }
    return true;
  }

  hasFrenchInsertedUnit(): boolean {
    return this.umService.isAnyInsertedUnit() && this.isProjectInFrance();
  }

  getPhysicsTypeNumber(): number {
    let numberOfPhysicsType: number = [ this.permissionService.checkPermission(this.feature.Acoustic),
      this.permissionService.checkPermission(this.feature.Structural),
      this.permissionService.checkPermission(this.feature.Thermal),
      this.permissionService.checkPermission(this.feature.Sustainability) ]
        .reduce(function (acc, currentValue) { return acc + (currentValue ? 1 : 0); }, 0);
    return numberOfPhysicsType;
  }

}
 