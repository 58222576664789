import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { PermissionService } from 'src/app/app-core/services/permission.service';
import { Feature } from 'src/app/app-core/models/feature';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';

@Component({
  selector: 'app-left-result',
  templateUrl: './left-result.component.html',
  styleUrls: ['./left-result.component.css']
})
export class LeftResultComponent implements OnInit, OnChanges {

  constructor(public configureService: ConfigureService, private umService: UnifiedModelService, private translate: TranslateService, private permissionService:PermissionService) { }
  @Input() selectedThermalResultLabel: number;
  @Input() unified3DModel: UnifiedModel;
  @Input() selectedStructuralIntermediate: number[];
  @Input() selectedStructuralIntermediateFacade: number[];
  @Output() sendPhysicsTypeEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendSelectedIntermediateGeneral: EventEmitter<number> = new EventEmitter();
  @Output() sendIntermediateRedColorGeneral: EventEmitter<any[]> = new EventEmitter();

  @Output() sendParentUnifiedModel: EventEmitter<UnifiedModel> = new EventEmitter();
  isAcousticSelected: boolean = false;
  isStructuralSelected: boolean = false;
  isThermalSelected: boolean = false;
  isSustainabilitySelected: boolean = false;

  isAcousticDisabled: boolean = false;
  isStructuralDisabled: boolean = false;
  isThermalDisabled: boolean = false;
  isSustainabilityDisabled: boolean = false;

  problemGuid: string;
  @Input() physicsType: string;

  isProductInfoVisible: boolean = false;
  path: string;
  productType: string;
  systemType: string;

  dimensions: string;

  feature = Feature;

  ngOnInit(): void {
    this.refreshPanel();
  }
  GeometryGlassList = [];
  refreshPanel() {
    this.GeometryGlassList = [];
    // let physicsTypes: string[] = this.configureService.getPhysicsTypesSelected();
    this.problemGuid = this.unified3DModel?.ProblemSetting ? this.unified3DModel.ProblemSetting.ProblemGuid : null;
    if (this.unified3DModel) {
      if (this.unified3DModel.ModelInput) {
        if (this.unified3DModel.ModelInput.FrameSystem)
          this.systemType = this.unified3DModel.ModelInput.FrameSystem.SystemType;
        if (this.unified3DModel.ModelInput.Geometry && this.unified3DModel.ModelInput.Geometry.Points) {
          let max_X_Dimension: number = 0, max_Y_Dimension: number = 0;
          let xDimensions = this.unified3DModel.ModelInput.Geometry.Points.map(x => x.X);
          let yDimensions = this.unified3DModel.ModelInput.Geometry.Points.map(x => x.Y);
          if (xDimensions && xDimensions.length > 0)
            max_X_Dimension = xDimensions.reduce((a, b) => Math.max(a, b));
          if (yDimensions && yDimensions.length > 0)
            max_Y_Dimension = yDimensions.reduce((a, b) => Math.max(a, b));
            this.dimensions = (max_X_Dimension).toFixed(0) + ' mm x ' + (max_Y_Dimension).toFixed(0) + ' mm';
        }

        var arr = [];
        if(this.unified3DModel.ModelInput.Geometry.OperabilitySystems)
          this.unified3DModel.ModelInput.Geometry.OperabilitySystems.filter(f => f.VentOperableType !== null && f.InsertedWindowType !== null && f.VentArticleName !== '-1');
        this.GeometryGlassList = arr.filter((arr, index, self) =>
          index === self.findIndex((t) => (t.VentOperableType === arr.VentOperableType && t.InsertedWindowType === arr.InsertedWindowType && t.VentOpeningDirection === arr.VentOpeningDirection)))
        this.GeometryGlassList.forEach(element => {
          switch (element.VentOperableType) {
            case 'Side-Hung-Left':
              element.VentOperableType = this.translate.instant(_('result.side-hung-left'));
              break;
            case 'Side-Hung-Right':
              element.VentOperableType = this.translate.instant(_('result.side-hung-right'));
              break;
            case 'Bottom-Hung':
              element.VentOperableType = this.translate.instant(_('result.bottom-hung'));
              break;
            case 'Top-Hung':
              element.VentOperableType = this.translate.instant(_('result.top-hung'));
              break;
            case 'Parallel-Opening':
              element.VentOperableType = this.translate.instant(_('result.parallel-opening'));
              break;
            case 'Tilt-Turn-Left':
              element.VentOperableType = this.translate.instant(_('result.tilt-turn-left'));
              break;
            case 'Tilt-Turn-Right':
              element.VentOperableType = this.translate.instant(_('result.tilt-turn-right'));
              break;
          }
          switch (element.VentOpeningDirection) {
            case 'Inward':
              element.VentOpeningDirection = this.translate.instant(_('result.inward'));
              break;
            case 'Outward':
              element.VentOpeningDirection = this.translate.instant(_('result.outward'));
              break;
          }
        });
      }

      if (this.unified3DModel.ProblemSetting) {
        let problemSetting = this.unified3DModel.ProblemSetting;

        if (this.unified3DModel.AnalysisResult) {
          if ((this.physicsType == 'A' && !this.unified3DModel.AnalysisResult.AcousticResult) ||
              (this.physicsType == 'B' && !(this.unified3DModel.AnalysisResult.StructuralResult || this.unified3DModel.AnalysisResult.FacadeStructuralResult)) ||
              (this.physicsType == 'C' && !this.unified3DModel.AnalysisResult.ThermalResult) ||
              (this.physicsType == 'D' && !this.unified3DModel.AnalysisResult.SustainabilityResult))
            this.physicsType = null;
        } else
          this.physicsType = null;
        if (problemSetting) {
          if (problemSetting.ProductType) {
            if (problemSetting.ProductType == 'Window')
              this.productType = this.translate.instant(_('result.window'));
            else if (problemSetting.ProductType == 'Facade')
              this.productType = this.translate.instant(_('result.facade'));
          }
          if (!this.physicsType) {
            if (problemSetting.EnableAcoustic && !this.umService.isAcousticDisabled()) {
              this.physicsType = 'A';
            } else if (problemSetting.EnableStructural) {
              this.physicsType = 'B';
            } else if (problemSetting.EnableThermal) {
              this.physicsType = 'C';
            } else if (problemSetting.EnableSustainability) {
              this.physicsType = 'D';
            }
          }
        }

        // this.selectPhysicsType();
        // let problemSetting = this.unified3DModel.ProblemSetting;
        this.isAcousticDisabled = !problemSetting.EnableAcoustic || this.umService.isAcousticDisabled();
        this.isStructuralDisabled = !problemSetting.EnableStructural;
        this.isThermalDisabled = !problemSetting.EnableThermal;
        this.isSustainabilityDisabled = !problemSetting.EnableSustainability;


        //this.getResults(physicsTypes);
        this.sendPhysicsTypeToParent();
      }
    }
  }

  ngOnChanges(Changes: SimpleChanges) {
    if (Changes.unified3DModel && !Changes.unified3DModel.firstChange) {
      this.refreshPanel();
    }
  }



  selectPhysicsType() {
    switch (this.physicsType) {
      case 'A':
        this.isAcousticSelected = true;
        this.isStructuralSelected = false;
        this.isThermalSelected = false;
        this.isSustainabilitySelected = false;
        break;
      case 'B':
        this.isAcousticSelected = false;
        this.isStructuralSelected = true;
        this.isThermalSelected = false;
        this.isSustainabilitySelected = false;
        break;
      case 'C':
        this.isAcousticSelected = false;
        this.isStructuralSelected = false;
        this.isThermalSelected = true;
        this.isSustainabilitySelected = false;
        break;
      case 'D':
        this.isAcousticSelected = false;
        this.isStructuralSelected = false;
        this.isThermalSelected = false;
        this.isSustainabilitySelected = true;
        break;
    }
  }

  sendPhysicsTypeToParent(): void {
    this.selectPhysicsType();
    this.sendPhysicsTypeEvent.emit(this.physicsType);
  }
  onReceiveSelectedIntermediateGeneral(val: number) {
    this.sendSelectedIntermediateGeneral.emit(val);
  }

  onReceiveIntermediateRedColor(val: any[]) {
    this.sendIntermediateRedColorGeneral.emit(val);
  }

  showProductInfoModal() {
    this.configureService.GetScreenshotURL(this.problemGuid).subscribe(imageURL => {
      if (imageURL) {
        this.path = imageURL;
        this.isProductInfoVisible = true;
      }
    }, (error) => {
      this.path = this.permissionService.checkPermission(Feature.WindowDefaultImageV2) ? '/assets/Images/window_default_SRS.png' : '/assets/Images/window__default.png';
    });
  }
  setDefaultImage() {
    this.path = this.permissionService.checkPermission(Feature.WindowDefaultImageV2)?'/assets/Images/window_default_SRS.png':'/assets/Images/window__default.png';
    //this.path = '/assets/Images/window__default.png';
  }
  handleCancel() {
    this.isProductInfoVisible = false;
  }
}
