export class GlassBPS {
    id: number;
    category: string;
    composition: string;
    type: string;
    totalThickness: number;
    uvalue: number;
    rw: number;
    spacer: number;
    shgc: number;
    vt: number;
    sg1h: number;
    sg2h: number;
    sg1e: number;
    sg2e: number;
    stc: number;
    oitc: number;
    totalThicknessUnit: string;
    element_xx_1: string;
    element_type_1: string;
    element_size_1: string; 
    element_interlayer_1: number; 
    element_ins_type_1: string;  
    element_ins_size_1: number; 
    element_xx_2: string;
    element_type_2: string;
    element_size_2: string; 
    element_interlayer_2: number; 
    element_ins_type_2: string;  
    element_ins_size_2: number; 
    element_xx_3: string; 
    element_type_3: string; 
    element_size_3: string;  
    element_interlayer_3: string; 
    Glass_SystemType: string;

}


