import { Component, OnInit, Renderer2, ViewChild, TemplateRef, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FramingService } from 'src/app/app-structural/services/framing.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { BpsTableComponent, CeldType } from 'bps-components-lib';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Subject, Subscription } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { takeUntil } from 'rxjs/operators';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';

@Component({
  selector: 'app-mullion-depth-table',
  templateUrl: './mullion-depth-table.component.html',
  styleUrls: ['./mullion-depth-table.component.css']
})
export class MullionDepthTableComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  isMullionPopoutOpened: boolean = false;
  isTransomPopoutOpened: boolean = false;
  isIntermediateMullionPopoutOpened: boolean = false;
  isIntermediateTransomPopoutOpened: boolean = false;
  isUDCFramingPopoutOpened: boolean = false;
  isReinforcementPopoutOpened: boolean = false;
  isTransomReinforcementPopoutOpened: boolean = false;

  initialLengthMullion: number;
  initialLengthTransom: number;
  initialLengthIntermediateMullion: number;
  initialLengthIntermediateTransom: number;
  initialLengthUDCFraming: number;

  mullionData = [];
  reinforcementData = [];
  transomData = [];
  intermediateMullionData = [];
  intermediateTransomData = [];
  UDCFramingData = [];
  transomreinforcementData = [];

  selectedMullionArticleId: number;
  lastSelectedMullionArticleId: number;
  selectedReinforcementMullionArticleId: number;
  selectedReinforcementMullionSteelBarThickness: number;
  selectedTransomArticleId: number;
  selectedReinforcementTransomArticleId: number;
  selectedIntermediateMullionArticleId: number;
  is_selectedIntermediateMullionArticle_default: boolean = false;
  selectedIntermediateTransomArticleId: number;
  selectedUDCFramingArticleId: number;
  current_selectedSystem: string;

  selectedMullionArticle: number = 0;
  selectedReinforcementMullionArticle: number = 0;
  selectedTransomArticle: number = 0;
  selectedIntermediateMullionArticle: number = 0;
  selectedIntermediateTransomArticle: number = 0;
  selectedUDCFramingArticle: number = 0;
  selectedReinforcementTransomArticle: number = 0;

  articleTitle: string;
  articleSteelbarThickness: string;
  marginTopTable: string = '2px';
  currentArticleToMullionDepthTable: any;
  currentArticleToTransomDepthTable: any;
  currentArticleToIntermediateMullionDepthTable: any;
  currentArticleToIntermediateTransomDepthTable: any;
  currentArticleToUDCFramingDepthTable: any;
  currentArticleToReinforcementMullionTable: any;
  currentArticleToReinforcementTransomTable: any;
  
  @Input() unified3DModel: UnifiedModel;
  @Input() event3D: any;
  @Input() systemFacadeSelectedFromFraming: string;
  

  @Output() openNewCustomEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() editCustomArticleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngNotificaionShow: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendDeleteAllIntermediate:EventEmitter<boolean> = new EventEmitter<boolean>();

  configurationCustomGrid: any;
  configurationReinforcementGrid: any;

  listOfDisplayDataInTable = [];
  listOfDisplayDataInTable_mullionDepth = [];
  listOfDisplayDataInTable_transomDepth = [];
  listOfDisplayDataInTable_intermediateMullionDepth = [];
  listOfDisplayDataInTable_intermediateTransomDepth = [];
  listOfDisplayDataInTable_UDCFramingDepth = [];
  listOfDisplayDataInTable_reinforcement = [];
  listOfDisplayDataInTable_TransomReinforcement = [];

  dataToShowInTable = [];
  sortDataToShowInTable = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  searchValue = '';
  arrow_hovered = false;
  @ViewChild('column2Template', { read: TemplateRef, static: true }) column2Template: TemplateRef<{}>;
  @ViewChild('uValueTemplate', { read: TemplateRef, static: true }) uValueTemplate: TemplateRef<{}>;
  @ViewChild('column2ArrowTemplate', { read: TemplateRef, static: true }) column2ArrowTemplate: TemplateRef<{}>;
  @ViewChild('divtable') divOftable: ElementRef;
  tableComponent: BpsTableComponent;
  language: string;
  tableComponent1: BpsTableComponent;
  @ViewChild('idTemplate', { read: TemplateRef, static: true }) idTemplate: TemplateRef<{}>;


  @ViewChild('bpsMullionTransomTable', { read: BpsTableComponent, static: false }) set content(content: BpsTableComponent) {
    if (content) {
      this.tableComponent = content;
      if (this.searchValue == ''){
        this.buildTableComponentDataList();
      }
    }
  }
  constructor(private umService: UnifiedModelService, private navLayoutService: NavLayoutService, private configureService: ConfigureService,
    private cpService: ConfigPanelsService, private render: Renderer2, public framingService: FramingService, private translate: TranslateService, private localStorageService: LocalStorageService, private modalService: NzModalService) { }

  preventDefault($event: Event, index) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.onEditCustom(index);
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  
  }

  isIntermediateMullionDepthApplied: boolean = false;
  ngOnInit(): void {
    this.language = this.configureService.getLanguage();
    /*this.unified3DModel = this.umService.current_UnifiedModel;*/
    /*this.onUnifiedModelUpdated();*/
    
    /*this.umService.obsUnifiedModel.subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
          this.onUnifiedModelUpdated();
        }
      });*/
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.MullionFacade) {
          this.isMullionPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseMullionDepthPopout();
          else if (!this.isMullionPopoutOpened) this.onCloseLeftPopout();
        }
        else if (response.panelsModule === PanelsModule.TransomFacade) {
          this.isTransomPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseTransomDepthPopout();
          else if (!this.isTransomPopoutOpened)  this.onCloseLeftPopout();
        }
        else if (response.panelsModule === PanelsModule.ReinforcementFacade) {
          this.isReinforcementPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseReinforcementFacadesPopout();
          else if (!this.isReinforcementPopoutOpened)  this.onCloseLeftPopout();
        }
        else if (response.panelsModule === PanelsModule.TransomReinforcementFacade) {
          this.isTransomReinforcementPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseTransomReinforcementFacadesPopout();
          else if (!this.isTransomReinforcementPopoutOpened)  this.onCloseLeftPopout();
        }
        else if (response.panelsModule === PanelsModule.IntermediateMullionFacade) {
          this.isIntermediateMullionPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseIntermediateMullionDepthPopout();
          else if (!this.isIntermediateMullionPopoutOpened)  this.onCloseLeftPopout();
        }
        else if (response.panelsModule === PanelsModule.IntermediateTransomFacade) {
          this.isIntermediateTransomPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseIntermediateTransomDepthPopout();
          else if (!this.isIntermediateTransomPopoutOpened)  this.onCloseLeftPopout();
        }
        else if (response.panelsModule === PanelsModule.UDCFraming) {
          this.isUDCFramingPopoutOpened = response.isOpened;
          if (response.isOpened) this.onOpenCloseUDCFramingPopout();
          else if (!this.isUDCFramingPopoutOpened)  this.onCloseLeftPopout();
        }
      });
    this.cpService.obsCurrent.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.MullionFacade) { this.updateCurrentValue_MullionFacade(response.data); }
        if (response.panelsModule === PanelsModule.TransomFacade) { this.updateCurrentValue_TransomFacade(response.data); }
        if (response.panelsModule === PanelsModule.IntermediateMullionFacade) { this.updateCurrentValue_IntermediateMullionFacade(response.data); }
        if (response.panelsModule === PanelsModule.IntermediateTransomFacade) { this.updateCurrentValue_IntermediateTransomFacade(response.data); }
        if (response.panelsModule === PanelsModule.UDCFramingFacade) { this.updateCurrentValue_UDCFramingFacade(response.data); }
        if (response.panelsModule === PanelsModule.ReinforcementFacade) { this.updateCurrentValue_ReinforcementFacade(response.data); }
        if (response.panelsModule === PanelsModule.TransomReinforcementFacade) { this.updateCurrentValue_TransomReinforcementFacade(response.data); }

      });
    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (this.unified3DModel && this.unified3DModel.ProblemSetting.FacadeType == 'mullion-transom') {
          if (response.data && this.current_selectedSystem != response.data.Value && response.systemFor !== 'OPERABILITY') {
            this.current_selectedSystem = response.data.Value;
            setTimeout(()=>{
              this.collectMullionDepthData(response.data.Value);
            },200);
          }
        }
        else if (this.unified3DModel && this.unified3DModel.ProblemSetting.FacadeType == 'UDC') {
          if (response && response.data && (this.current_selectedSystem != response.data.Value)) {
            this.current_selectedSystem = response.data.Value;
            if(response.data) this.collectUDCFramingFacadeData(response.data.Value, null);
          }
        }
      });
      //
      this.cpService.obsIntermediateMullionDepth.pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          if (this.unified3DModel && this.unified3DModel.ProblemSetting.FacadeType == 'mullion-transom') {
            if (response != undefined && this.isIntermediateMullionDepthApplied != response) {
              this.isIntermediateMullionDepthApplied = response;
              setTimeout(()=>{
                if(this.isTransomPopoutOpened) this.isTransomPopoutOpened = false;
                this.collectTransomDepthData(this.current_selectedSystem);
              },200);
            }
          }
        });
      //
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataTranslation();
    });
      
    if (this.unified3DModel && this.unified3DModel.ProblemSetting.FacadeType === 'UDC')
      this.setConfigurationCustomGrid(true);
    else
      this.setConfigurationCustomGrid(false);
  }

  updateCurrentValue_MullionFacade(data: any) { this.currentArticleToMullionDepthTable = data;}
  updateCurrentValue_TransomFacade(data: any) { this.currentArticleToTransomDepthTable = data;}
  updateCurrentValue_IntermediateMullionFacade(data: any) { this.currentArticleToIntermediateMullionDepthTable = data;}
  updateCurrentValue_IntermediateTransomFacade(data: any) { this.currentArticleToIntermediateTransomDepthTable = data;}
  updateCurrentValue_UDCFramingFacade(data: any) {
    if (data && (!this.currentArticleToUDCFramingDepthTable || this.currentArticleToUDCFramingDepthTable.ArticleID != data.ArticleID)) {
      this.currentArticleToUDCFramingDepthTable = data;
      this.collectUDCFramingFacadeData(this.current_selectedSystem, null);
    }
  }
  updateCurrentValue_ReinforcementFacade(data: any) {
     this.currentArticleToReinforcementMullionTable = data; 
    }
  updateCurrentValue_TransomReinforcementFacade(data: any) { 
    this.currentArticleToReinforcementTransomTable = data;
  }
  
  // onUnifiedModelUpdated() { }
  onHover(index, value) {
    if (this.listOfDisplayDataInTable[index].outside)
      this.listOfDisplayDataInTable[index].outside.context.arrowHovered = value;
    else
      this.listOfDisplayDataInTable[index].column2.context.arrowHovered = value;
  }

  log(event) {
  }

  sort(sort: { sortName: string; sortValue: string }): void {
    this.sortName = sort.sortName;
    this.sortValue = sort.sortValue;
    this.search();
  }

  filter(value: string): void {
    this.searchValue = value;
    this.search();
  }

  search(): void {
    const filterFunc = (item: any) => {
      return item.description.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1;
    };
    this.sortDataToShowInTable = [];
    if (this.isMullionPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_mullionDepth.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    if (this.isTransomPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_transomDepth.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    if (this.isIntermediateMullionPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_intermediateMullionDepth.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    if (this.isIntermediateTransomPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_intermediateTransomDepth.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    if (this.isUDCFramingPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_UDCFramingDepth.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    if (this.isReinforcementPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_reinforcement.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    if (this.isTransomReinforcementPopoutOpened) {
      this.sortDataToShowInTable = this.listOfDisplayDataInTable_TransomReinforcement.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    }
    
    // if (this.sortDataToShowInTable && this.sortDataToShowInTable.length == 1 && this.tableComponent1) {
    //   this.tableComponent1.selectRow(this.sortDataToShowInTable[0]);
    // }
    if (this.sortName && this.sortValue) {
      this.listOfDisplayDataInTable = this.sortDataToShowInTable.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
      this.listOfDisplayDataInTable = this.listOfDisplayDataInTable.slice();
    } else {
      this.listOfDisplayDataInTable = this.sortDataToShowInTable;
    }
    this.selectRowIdAfterSearch();
  }

  selectRowIdAfterSearch() {
    if (this.isMullionPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedMullionArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedMullionArticleId));
    }
    if (this.isTransomPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedTransomArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedTransomArticleId));
    }
    if (this.isIntermediateMullionPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedIntermediateMullionArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedIntermediateMullionArticleId));
    }
    if (this.isIntermediateTransomPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedIntermediateTransomArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedIntermediateTransomArticleId));
    }
    if (this.isUDCFramingPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedUDCFramingArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedUDCFramingArticleId));
    }
    if (this.isReinforcementPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedReinforcementMullionArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedReinforcementMullionArticleId));
    }
    if (this.isTransomReinforcementPopoutOpened && this.tableComponent && this.listOfDisplayDataInTable.find(article => article.id == this.selectedReinforcementTransomArticleId)) {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedReinforcementTransomArticleId));
    }
  }

  onCloseLeftPopout(): void {
    if (this.tableComponent) {
      this.tableComponent.inputElement.nativeElement.value = '';
    }
    this.searchValue = '';
    // this.search();
    if (this.isMullionPopoutOpened) this.cpService.setPopout(false, PanelsModule.MullionFacade);
    if (this.isTransomPopoutOpened) this.cpService.setPopout(false, PanelsModule.TransomFacade);
    if (this.isIntermediateMullionPopoutOpened) this.cpService.setPopout(false, PanelsModule.IntermediateMullionFacade);
    if (this.isIntermediateTransomPopoutOpened) this.cpService.setPopout(false, PanelsModule.IntermediateTransomFacade);
    if (this.isUDCFramingPopoutOpened) this.cpService.setPopout(false, PanelsModule.UDCFraming);
    if (this.isReinforcementPopoutOpened) this.cpService.setPopout(false, PanelsModule.ReinforcementFacade);
    if (this.isTransomReinforcementPopoutOpened) this.cpService.setPopout(false, PanelsModule.TransomReinforcementFacade);
  }

  SetMullionDepthToLastSelection(index: number){
    if (this.isMullionPopoutOpened) {
      this.selectedMullionArticleId = index;
      if (this.mullionData.find(article => article.id == this.selectedMullionArticleId) && this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId) {
        this.articleTitle = this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId
      }
      else if (this.mullionData.find(article => article.id == this.selectedMullionArticleId) && this.mullionData.find(article => article.id == this.selectedMullionArticleId).CustomArticleName) {
        this.articleTitle = this.mullionData.find(article => article.id == this.selectedMullionArticleId).CustomArticleName;
      }
      else {
        this.articleTitle = '';
      }      
      this.onConfirmMullionArticle(false);
    }
  }

  onSelectArticle(event: any): void {
    if (this.isMullionPopoutOpened) {
      this.lastSelectedMullionArticleId = this.selectedMullionArticleId;
      this.selectedMullionArticleId = parseInt(event.id);
      if (this.mullionData.find(article => article.id == this.selectedMullionArticleId) && this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId) {
        this.articleTitle = this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId
      }
      else if (this.mullionData.find(article => article.id == this.selectedMullionArticleId) && this.mullionData.find(article => article.id == this.selectedMullionArticleId).CustomArticleName) {
        this.articleTitle = this.mullionData.find(article => article.id == this.selectedMullionArticleId).CustomArticleName;
      }
      else {
        this.articleTitle = '';
      }      
    }
    else if (this.isTransomPopoutOpened) {
      this.selectedTransomArticleId = parseInt(event.id);
      if (this.transomData.find(article => article.id == this.selectedTransomArticleId) && this.transomData.find(article => article.id == this.selectedTransomArticleId).transomArticleId) {
        this.articleTitle = this.transomData.find(article => article.id == this.selectedTransomArticleId).transomArticleId;
      }
      else if (this.transomData.find(article => article.id == this.selectedTransomArticleId) && this.transomData.find(article => article.id == this.selectedTransomArticleId).CustomArticleName) {
        this.articleTitle = this.transomData.find(article => article.id == this.selectedTransomArticleId).CustomArticleName;
      }
      else {
        this.articleTitle = '';
      }      
    }
    else if (this.isIntermediateMullionPopoutOpened) {
      this.is_selectedIntermediateMullionArticle_default = false;
      this.selectedIntermediateMullionArticleId = parseInt(event.id);
      if (this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId) && this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId) {
        this.articleTitle = this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId;
      }
      else if (this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId) && this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).CustomArticleName) {
        this.articleTitle = this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).CustomArticleName;
      }
      else {
        this.articleTitle = '';
      }
    }
    else if (this.isIntermediateTransomPopoutOpened) {
      this.selectedIntermediateTransomArticleId = parseInt(event.id);
      if (this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId) && this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomArticleId) {
        this.articleTitle = this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomArticleId;
      }
      else if (this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId) && this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).CustomArticleName) {
        this.articleTitle = this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).CustomArticleName;
      }
      else {
        this.articleTitle = '';
      }
    }
    else if (this.isUDCFramingPopoutOpened) {
      this.selectedUDCFramingArticleId = parseInt(event.id);
      if (this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId) && this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId) {
      this.articleTitle = this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId;
      }
      else if (this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId) && this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).CustomArticleName) {
        this.articleTitle = this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).CustomArticleName;
      }
      else {
        this.articleTitle = '';
      }
    }
    else if (this.isReinforcementPopoutOpened) {
      this.selectedReinforcementMullionArticleId = parseInt(event.id);
      this.articleTitle = this.selectedReinforcementMullionArticleId >= 0 ? this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).value : this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).value;
      this.articleSteelbarThickness = this.selectedReinforcementMullionArticleId >= 0 && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== '0' && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== null && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== '10' ? '20000'+ this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo :this.selectedReinforcementMullionArticleId >= 0 && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== '0' && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== null && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo === '10' ?'2000'+ this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo :'';
    }
    else if (this.isTransomReinforcementPopoutOpened) {
      // this.selectedReinforcementTransomArticle = parseInt(event.id);
      // if (this.selectedReinforcementTransomArticle < 0) {
      //   this.selectedReinforcementTransomArticle = 0;
      // }
      // this.articleTitle = this.selectedReinforcementTransomArticle >= 0 ? this.transomreinforcementData[this.selectedReinforcementTransomArticle].value : this.transomreinforcementData[this.selectedReinforcementTransomArticle].value;

      this.selectedReinforcementTransomArticleId = parseInt(event.id);
      this.articleTitle = this.selectedReinforcementTransomArticleId >= 0 ? this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId).value : this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId).value;
    }
  }

  CheckDepth() {
    if (this.intermediateMullionData.length > 0 && this.intermediateTransomData.length > 0) {
      if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Geometry && this.unified3DModel.ModelInput.Geometry.Members) {
        let transomUDC = this.unified3DModel.ModelInput.Geometry.Members.filter(x => x.MemberType == 25);
        let mullionUDC = this.unified3DModel.ModelInput.Geometry.Members.filter(x => x.MemberType == 24);
        if(transomUDC && transomUDC.length > 0 && mullionUDC && mullionUDC.length>0){
          let mullionDepth = this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomDepth;
          let transomDepth = this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomDepth;
          if (transomDepth != mullionDepth) this.ngNotificaionShow.next('DifferentDepth');
        }
      }
    }
  }

  onConfirmMullionArticle(checkIncompatibility: boolean = true): void {
    if (this.isMullionPopoutOpened) {
      var selectedMullion = this.mullionData.find(article => article.id == this.selectedMullionArticleId);
      if(checkIncompatibility && selectedMullion.mullionDepth < 85 && this.umService.IsIntermediateMullionApplied() && this.umService.isFacadeMullionTransom()){
        //
        this.notifyUserAboutIncompatibleMullionDepth().then(e=>{
          if(e){
            setTimeout(()=>{   
              this.confirmMullion();
              this.onCloseLeftPopout();
            },500);
          }
        });
      } else{
        this.confirmMullion();
        this.onCloseLeftPopout();
      }
    }
    else if (this.isTransomPopoutOpened) {
      this.confirmTransom();
      this.onCloseLeftPopout();
    }
    else if (this.isIntermediateMullionPopoutOpened) {
      this.confirmIntermediateMullion();
      this.onCloseLeftPopout();
    }
    else if (this.isIntermediateTransomPopoutOpened) {
      this.confirmIntermediateTransom();
      this.onCloseLeftPopout();
    }
    else if (this.isUDCFramingPopoutOpened) {
      if (!this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).isCustomProfile) {
        this.umService.set_UDCFraming({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: false });
        // this.cpService.setConfirm({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: false }, PanelsModule.UDCFraming);
      }
      else {
        this.umService.set_UDCFraming({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).isCustomProfile });
        // this.cpService.setConfirm({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).isCustomProfile }, PanelsModule.UDCFraming);
      }
      //if (this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomDepth < this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomDepth)
      //if (this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomDepth < this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomDepth)
      this.collectIntermediateMullionDepthData(this.systemFacadeSelectedFromFraming, this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId.toString());
      setTimeout(() => {
        this.collectIntermediateTransomDepthData(this.systemFacadeSelectedFromFraming, this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId.toString());
      }, 100);
      this.onCloseLeftPopout();
    }
    else if (this.isReinforcementPopoutOpened) {
      this.confirmReinforcement();
      this.onCloseLeftPopout();
    }
    else if (this.isTransomReinforcementPopoutOpened) {
      // this.cpService.setConfirm({ article: this.transomreinforcementData[this.selectedReinforcementTransomArticle] }, PanelsModule.TransomReinforcementFacade);
      // this.umService.set_TransomReinforcementFacade({ article: this.transomreinforcementData[this.selectedReinforcementTransomArticle] })
      // this.onCloseLeftPopout();
      this.confirmTransomReinforcement();
      this.onCloseLeftPopout();
    }
  }

  confirmMullion() {
    this.collectTransomDepthData(this.systemFacadeSelectedFromFraming);
    this.collectMullionReinforcementData(this.systemFacadeSelectedFromFraming, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
    setTimeout(() => {
      if (this.transomData.find(article => article.id == this.selectedTransomArticleId) != undefined) this.collectIntermediateMullionDepthData(this.systemFacadeSelectedFromFraming, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId, this.transomData.find(article => article.id == this.selectedTransomArticleId).transomDepth.toString());
    }, 10);
    this.umService.set_MullionDepth({ article: this.mullionData.find(article => article.id == this.selectedMullionArticleId), isCustomed: false });
  }

  confirmTransom() {
    this.collectTransomReinforcementData(this.systemFacadeSelectedFromFraming, this.transomData.find(article => article.id == this.selectedTransomArticleId).transomArticleId.toString());
    this.collectIntermediateMullionDepthData(this.systemFacadeSelectedFromFraming, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString(),this.transomData.find(article => article.id == this.selectedTransomArticleId).transomDepth);
    this.umService.set_TransomDepth({ article: this.transomData.find(article => article.id == this.selectedTransomArticleId), isCustomed: false });
  }

  confirmIntermediateMullion() {
    if (this.unified3DModel.ProblemSetting.FacadeType == 'UDC') {
      this.CheckDepth();
      this.currentArticleToIntermediateMullionDepthTable = { ArticleID: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId.toString() };
      if (!this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).isCustomProfile) {
        this.umService.set_IntermediateMullionDepth({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false });
        this.cpService.setConfirm({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false }, PanelsModule.IntermediateMullionFacade);
        //if (this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomDepth < this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomDepth)
        setTimeout(() => {
          this.collectIntermediateTransomDepthData(this.systemFacadeSelectedFromFraming, this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId.toString());
        }, 100);
      }
      else {
        this.umService.set_IntermediateMullionDepth({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).isCustomProfile });
      }
    }
    else {
      this.collectTransomDepthData(this.systemFacadeSelectedFromFraming);
      this.currentArticleToIntermediateMullionDepthTable = { ArticleID: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId.toString() };
      this.umService.set_IntermediateMullionDepth({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false });
    }
  }

  confirmIntermediateTransom() {
    if (this.unified3DModel.ProblemSetting.FacadeType == 'UDC') this.CheckDepth();
    this.currentArticleToIntermediateTransomDepthTable = { ArticleID: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomArticleId.toString() };
    if (!this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).isCustomProfile) {
      this.umService.set_IntermediateTransomDepth({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: false });
      this.cpService.setConfirm({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: false }, PanelsModule.IntermediateTransomFacade);
    }
    else {
      this.umService.set_IntermediateTransomDepth({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).isCustomProfile });
      this.cpService.setConfirm({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).isCustomProfile }, PanelsModule.IntermediateTransomFacade);
    }
  }

  confirmReinforcement() {
    this.umService.set_ArticleReinforcementFacade({ article: this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId) });
  }

  confirmTransomReinforcement() {
    this.umService.set_ArticleTransomReinforcementFacade({ article: this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId) });
  }

  onOpenCustom(): void {
    this.openNewCustomEvent.emit();
  }

  onEditCustom(index: number): void {
    if (this.isIntermediateMullionPopoutOpened)
      this.editCustomArticleEvent.emit({ article: this.intermediateMullionData[index], index: index });
    if (this.isIntermediateTransomPopoutOpened)
      this.editCustomArticleEvent.emit({ article: this.intermediateTransomData[index], index: index });
    if (this.isUDCFramingPopoutOpened)
      this.editCustomArticleEvent.emit({ article: this.UDCFramingData[index], index: index });
    else
      this.editCustomArticleEvent.emit({ article: this.mullionData[index], index: index });
  }

  buildTableComponentDataList() {    
    if (this.tableComponent) { // && this.searchValue == ''
      if (this.isMullionPopoutOpened) {
        this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_mullionDepth;
        this.tableComponent.selectRow(this.listOfDisplayDataInTable_mullionDepth.find(article => article.id == this.selectedMullionArticleId), true);
        this.articleTitle = this.selectedMullionArticleId < this.initialLengthMullion ? this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId : this.mullionData.find(article => article.id == this.selectedMullionArticleId).CustomArticleName;
      }
      if (this.isTransomPopoutOpened) {
        this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_transomDepth;
        this.tableComponent.selectRow(this.listOfDisplayDataInTable_transomDepth.find(article => article.id == this.selectedTransomArticleId), true);
        this.articleTitle = this.selectedTransomArticleId < this.initialLengthTransom ? this.transomData.find(article => article.id == this.selectedTransomArticleId).transomArticleId : this.transomData.find(article => article.id == this.selectedTransomArticleId).CustomArticleName;
      }
      if (this.isIntermediateMullionPopoutOpened) {
        this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_intermediateMullionDepth;
        if (this.selectedIntermediateMullionArticleId == undefined) this.selectedIntermediateMullionArticleId = 0;
        this.tableComponent.selectRow(this.listOfDisplayDataInTable_intermediateMullionDepth.find(article => article.id == this.selectedIntermediateMullionArticleId), true);
        this.articleTitle = this.selectedIntermediateMullionArticleId < this.initialLengthIntermediateMullion ? this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId : this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).CustomArticleName;
      }
      if (this.isIntermediateTransomPopoutOpened) {
        this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_intermediateTransomDepth;
        this.tableComponent.selectRow(this.listOfDisplayDataInTable_intermediateTransomDepth.find(article => article.id == this.selectedIntermediateTransomArticleId), true);
        this.articleTitle = this.selectedIntermediateTransomArticleId < this.initialLengthIntermediateTransom ? this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomArticleId : this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).CustomArticleName;
      }
      if (this.isUDCFramingPopoutOpened) {
        this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_UDCFramingDepth;
        this.tableComponent.selectRow(this.listOfDisplayDataInTable_UDCFramingDepth.find(article => article.id == this.selectedUDCFramingArticleId), true);
        this.articleTitle = this.selectedUDCFramingArticleId < this.initialLengthUDCFraming ? this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId : this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).CustomArticleName;
      }
      if (this.isReinforcementPopoutOpened) {
        if (this.framingService.reinforcementData.length > 0) {
          this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_reinforcement;
          this.tableComponent.selectRow(this.listOfDisplayDataInTable_reinforcement.find(article => article.id == this.selectedReinforcementMullionArticleId), true);
          this.articleTitle = this.selectedReinforcementMullionArticleId >= 0 ? this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).value : this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).value;
        }
      }
      if (this.isTransomReinforcementPopoutOpened) {
        // if (this.transomreinforcementData.length > 0) {
        //   this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_TransomReinforcement;
        //   this.tableComponent.selectRow(this.listOfDisplayDataInTable_TransomReinforcement[this.selectedReinforcementTransomArticle], true);
        //   this.articleTitle = this.selectedReinforcementTransomArticle >= 0 ? this.transomreinforcementData[this.selectedReinforcementTransomArticle].value : this.transomreinforcementData[this.selectedReinforcementTransomArticle].value;
        //   this.umService.set_TransomReinforcementFacade({ article: this.transomreinforcementData[this.selectedReinforcementTransomArticle]});
        // }
        if (this.framingService.transomreinforcementData.length > 0) {
          this.listOfDisplayDataInTable = this.listOfDisplayDataInTable_TransomReinforcement;
          this.tableComponent.selectRow(this.listOfDisplayDataInTable_TransomReinforcement.find(article => article.id == this.selectedReinforcementTransomArticleId), true);
          this.articleTitle = this.selectedReinforcementTransomArticleId >= 0 ? this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId).value : this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId).value;
        }
      }
    }   
  }

  onOpenCloseMullionDepthPopout() {
    if (this.unified3DModel && this.umService.isFacadeUDC()) this.setConfigurationCustomGrid(true);
    else this.setConfigurationCustomGrid(false);
    this.checkMarginTopOfMullionTransomTable();
    if (this.tableComponent) {
      if (this.isMullionPopoutOpened) {
        this.buildTableComponentDataList();
        this.listOfDisplayDataInTable = this.listOfDisplayDataInTable.slice();
        setTimeout(() => {
          let article_to_select = this.listOfDisplayDataInTable.find(article => article.mullionArticleId.toString() == (this.umService.obj_MullionDepth()).ArticleName);
          if (article_to_select) this.tableComponent.selectRow(article_to_select, true);
        });
      }
      this.tableComponent.inputElement.nativeElement.value = '';
      this.searchValue = '';
      this.search();
    }
    this.articleTitle = this.selectedMullionArticleId < this.initialLengthMullion ? this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId : this.mullionData.find(article => article.id == this.selectedMullionArticleId).CustomArticleName;
  }

  onOpenCloseIntermediateMullionDepthPopout() {
    if (this.unified3DModel && this.umService.isFacadeUDC()) this.setConfigurationCustomGrid(true);
    else this.setConfigurationCustomGrid(false);
    this.checkMarginTopOfMullionTransomTable();
    if (this.selectedIntermediateMullionArticleId > -1) {
      if (this.tableComponent) {
        if (this.isIntermediateMullionPopoutOpened) {
          this.buildTableComponentDataList();
          this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedIntermediateMullionArticleId), true);
        }
        this.tableComponent.inputElement.nativeElement.value = '';
        this.searchValue = '';
        this.search();
      }
      this.articleTitle = this.selectedIntermediateMullionArticleId < this.initialLengthIntermediateMullion ? this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId : this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).CustomArticleName;
    }
  }

  onOpenCloseIntermediateTransomDepthPopout() {
    if (this.unified3DModel && this.umService.isFacadeUDC()) this.setConfigurationCustomGrid(true);
    else this.setConfigurationCustomGrid(false);
    this.checkMarginTopOfMullionTransomTable();
    if (this.tableComponent) {
      if (this.isIntermediateTransomPopoutOpened) {
        this.buildTableComponentDataList();
        this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedIntermediateTransomArticleId), true);
      }
      this.tableComponent.inputElement.nativeElement.value = '';
      this.searchValue = '';
      this.search();
    }
    this.articleTitle = this.selectedIntermediateTransomArticleId < this.initialLengthIntermediateTransom ? this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomArticleId : this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).CustomArticleName;
  }

  onOpenCloseUDCFramingPopout() {
    if (this.unified3DModel && this.umService.isFacadeUDC()) this.setConfigurationCustomGrid(true);
    else this.setConfigurationCustomGrid(false);
    this.checkMarginTopOfMullionTransomTable();
    this.tableComponent.selectRow(this.listOfDisplayDataInTable_UDCFramingDepth.find(article => article.id == this.selectedUDCFramingArticleId), true);
    if (this.tableComponent) {
      if (this.isUDCFramingPopoutOpened) {
        this.buildTableComponentDataList();
        this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedUDCFramingArticleId), true);
      }
      this.tableComponent.inputElement.nativeElement.value = '';
      this.searchValue = '';
      this.search();
    }
    this.articleTitle = this.selectedUDCFramingArticleId < this.initialLengthUDCFraming ? this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId : this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).CustomArticleName;
  }

  onOpenCloseTransomDepthPopout() {
    if (this.unified3DModel && this.umService.isFacadeUDC()) this.setConfigurationCustomGrid(true);
    else this.setConfigurationCustomGrid(false);
    this.checkMarginTopOfMullionTransomTable();
    if (this.tableComponent) {
      if (this.isTransomPopoutOpened) {
        this.buildTableComponentDataList();
        this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.transomArticleId.toString() == (this.umService.obj_TransomDepth()).ArticleName), true);
      }
      this.tableComponent.inputElement.nativeElement.value = '';
      this.searchValue = '';
      this.search();
    }
    this.articleTitle = this.selectedTransomArticleId < this.initialLengthTransom ? this.transomData.find(article => article.id == this.selectedTransomArticleId).transomArticleId : this.transomData.find(article => article.id == this.selectedTransomArticleId).CustomArticleName;
  }

  onOpenCloseReinforcementFacadesPopout() {
    this.configurationCustomGrid = {
      fields: [
        {
          celdType: CeldType.Default,
          display: this.translate.instant(_('configure.description')),
          property: 'description',
          width: '180px',
          showSort: true
        },
        {
          celdType: CeldType.Default,
          property: 'dataId',
          template: {
            ref: this.idTemplate,
            context: {}
          },
          width: '0px',
          showSort: false,
        }
        
      ],
      fieldId: 'id'
    };
    if (this.listOfDisplayDataInTable_reinforcement.find(article => article.id == this.selectedReinforcementMullionArticleId) !== undefined) {
      if (this.tableComponent) {
        if (this.isReinforcementPopoutOpened) {
          this.buildTableComponentDataList();
          this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedReinforcementMullionArticleId), true);
        }
      }
    }
    this.tableComponent.inputElement.nativeElement.value = '';
    this.searchValue = '';
    this.search();
    this.articleTitle = this.selectedReinforcementMullionArticleId >= 0 ? this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).value : this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).value;
    this.articleSteelbarThickness = this.selectedReinforcementMullionArticleId >= 0 && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== '0' && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== null && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== '10' ? '20000'+ this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo :this.selectedReinforcementMullionArticleId >= 0 && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== '0' && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo !== null && this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo === '10' ?'2000'+ this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId).moreInfo :'';
  }

  onOpenCloseTransomReinforcementFacadesPopout() {
  this.configurationCustomGrid = {
    fields: [
      {
        celdType: CeldType.Default,
        display: this.translate.instant(_('configure.description')),
        property: 'description',
        width: '180px',
        showSort: true
      },
      {
        celdType: CeldType.Default,
        property: 'dataId',
        template: {
          ref: this.idTemplate,
          context: {}
        },
        width: '0px',
        showSort: false,
      } 
    ],
    fieldId: 'id'
  };
   // if (this.listOfDisplayDataInTable_TransomReinforcement[this.selectedReinforcementTransomArticle] !== undefined) {
    if (this.listOfDisplayDataInTable_TransomReinforcement.find(article => article.id == this.selectedReinforcementTransomArticleId) !== undefined) {
      if (this.tableComponent) {
        if (this.isTransomReinforcementPopoutOpened) {
          this.buildTableComponentDataList();
         // this.tableComponent.selectRow(this.listOfDisplayDataInTable[this.selectedReinforcementTransomArticle], true);
         this.tableComponent.selectRow(this.listOfDisplayDataInTable.find(article => article.id == this.selectedReinforcementTransomArticleId), true);
        }
      }
    }
    this.tableComponent.inputElement.nativeElement.value = '';
    this.searchValue = '';
    this.search();
    // this.articleTitle = this.selectedReinforcementTransomArticle >= 0 ? this.transomreinforcementData[this.selectedReinforcementTransomArticle].value : this.transomreinforcementData[this.selectedReinforcementTransomArticle].value;
    this.articleTitle = this.selectedReinforcementTransomArticleId >= 0 ? this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId).value : this.framingService.transomreinforcementData.find(article => article.id == this.selectedReinforcementTransomArticleId).value;
  }

  getMaxPanelThickness(): number {
    if (this.unified3DModel.ModelInput.Geometry.PanelSystems) {
      return undefined;
    } else {
      var arr = this.unified3DModel.ModelInput.Geometry.PanelSystems;
      if (arr && arr.length > 0) {
        return arr.sort(
          function (a: any, b: any) {
            return parseFloat(b.Thickness) - parseFloat(a.Thickness);
          }
        )[0].Thickness;
      }
      else
        return undefined;
    }
  }

  setConfigurationCustomGrid(showWidth: boolean) {
    if (showWidth) {
      this.configurationCustomGrid = {
        fields: [
          {
            celdType: CeldType.Default,
            display: this.translate.instant(_('configure.description')),
            property: 'description',
            width: '180px',
            showSort: true
          },
          {
            celdType: CeldType.Default,
            property: 'inside',
            template: {
              ref: this.uValueTemplate,
              context: {}
            },
            width: '65px',
            showSort: true
          },
          {
            celdType: CeldType.TemplateRef,
            property: 'column2',
            width: '65px',
            template: {
              ref: this.column2Template,
              context: {}
            },
            showSort: true
          }
        ],
        fieldId: 'id'
      };
    } else {
      this.configurationCustomGrid = {
        fields: [
          {
            celdType: CeldType.Default,
            display: this.translate.instant(_('configure.description')),
            property: 'description',
            width: '245px',
            showSort: true
          },
          {
            celdType: CeldType.TemplateRef,
            property: 'column2',
            width: '65px',
            template: {
              ref: this.column2Template,
              context: {}
            },
            showSort: true
          }
        ],
        fieldId: 'id'
      };
    }
  }

  collectMullionDepthData(system: string) {
    this.framingService.getFacade_MullionList(system).pipe(takeUntil(this.destroy$)).subscribe(data => {
      setTimeout(() => {
        const panelThickness = this.getMaxPanelThickness();
        this.mullionData = data;
        let defaultMullionArticle = this.mullionData.length - 1;
        this.initialLengthMullion = this.mullionData.length;
        let dataToShowInMullionDepthTable = [];
        this.mullionData.forEach((article, index) => {
          article.id = index;
          dataToShowInMullionDepthTable.push({
            id: '' + index,
            description: this.translate.instant(_('configure.mullion-depth-mullion-description')) + " - " + article.mullionArticleId,
            mullionArticleId: article.mullionArticleId,
            column2: {
              ref: this.column2ArrowTemplate,
              context: {
                value: article.mullionDepth + article.moreInfo,
                disabled: true,
                arrowHovered: true,
                index: index
              }
            },
            propertyId: 0, //0 for mullionDepth
            disabled: panelThickness === undefined || panelThickness < article.mullionDepth ? false : true,
            bpsTable4CustomRow: false,
            article: article,
          });
        });
        this.listOfDisplayDataInTable_mullionDepth = dataToShowInMullionDepthTable.slice();
        if (this.currentArticleToMullionDepthTable && this.currentArticleToMullionDepthTable.ArticleID) {
          this.currentArticleToMullionDepthTable.ArticleID = this.framingService.oldToNewFraming(this.currentArticleToMullionDepthTable.ArticleID);
          if (this.mullionData.find(article => article.mullionArticleId.toString() == this.currentArticleToMullionDepthTable.ArticleID.toString())) {
            this.selectedMullionArticleId = this.mullionData.find(article => article.mullionArticleId.toString() == this.currentArticleToMullionDepthTable.ArticleID.toString()).id;
          }
          else {
            this.selectedMullionArticleId = this.mullionData[defaultMullionArticle].id;
          }
        }
        else {
          this.selectedMullionArticleId = this.mullionData[defaultMullionArticle].id;
        }
        this.tableComponent.selectRow(this.listOfDisplayDataInTable_mullionDepth.find(article => article.id == this.selectedMullionArticleId), true);
        // this.cpService.setConfirm({ article: this.mullionData.find(article => article.id == this.selectedMullionArticleId), isCustomed: false }, PanelsModule.MullionFacade);
        this.confirmMullion();
        if (this.umService.isFacade()) {
          if (this.umService.isFacadeMullionTransom()) {
            this.collectTransomDepthData(system);
            if (this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Geometry && this.unified3DModel.ModelInput.Geometry.Members) {
              if (this.unified3DModel.ModelInput.Geometry.Members.findIndex(x => x.MemberType == 5) != -1)
                setTimeout(() => {
                  this.collectIntermediateMullionDepthData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString(), this.transomData.find(article => article.id == this.selectedTransomArticleId)?.transomDepth?.toString());

                }, 300);
              if (this.unified3DModel.ModelInput.Geometry.Members.findIndex(x => x.MemberType == 6) != -1)
                this.collectIntermediateTransomDepthData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
            }
            this.collectMullionReinforcementData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
          }
          else if (this.umService.isFacadeUDC()) {
            this.collectUDCFramingFacadeData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
            this.collectIntermediateMullionDepthData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
            setTimeout(() => {
              this.collectIntermediateTransomDepthData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
            }, 100);
            //this.collectIntermediateTransomDepthData(system, this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId.toString());
          }
        }
      }, 100);
    });
  }

  collectMullionReinforcementData(system: string, mullionId: string) {
    this.framingService.getFacade_Mullion_ReinforcementList(system, mullionId).pipe(takeUntil(this.destroy$)).subscribe(data => {
      setTimeout(() => {
        this.framingService.setReinforcementData(data);
        let defaultReinforcementArticle = 0;
        let defaultReinforcementSteelbarThickness = 0;
        let dataToShowInMullionReinforcementTable = [];
        this.framingService.reinforcementData.forEach((article, index) => {
          article.id = index;
          article.moreInfo = this.framingService.reinforcementData.filter(ar => ar.value === article.value).length > 1 ? index === 2 ? '5' : index === 3 ? '6': index === 4 ? '8': index === 5 ? '10': '0': '0'
          let translatedMaterial = '';
          if (article.material === 'Aluminium') {
            translatedMaterial = this.translate.instant(_('configure.mullion-depth-reinforcement-aluminium'));
          }
          if (article.material === 'Steel') {
            translatedMaterial = this.translate.instant(_('configure.mullion-depth-reinforcement-steel'));
          }
          dataToShowInMullionReinforcementTable.push({
            id: '' + index,
            description: index === 2 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 5 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) : index === 3 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 6 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) : index === 4 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 8 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) : index === 5 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 10 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) : translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value,
            column2: {
              ref: this.column2ArrowTemplate,
              context: {
                value: translatedMaterial,
                disabled: false,
                arrowHovered: false,
                index: index
              }
            },
            propertyId: 5, // 5 for reinforcement
            value: article.value,
            isMaterialAluminium: article.material === 'Aluminium',
            isMaterialSteel: article.material === 'Steel',
            disabled: false,
            bpsTable4CustomRow: false,
            article: article
          });
        });
        this.listOfDisplayDataInTable_reinforcement = dataToShowInMullionReinforcementTable.slice();
        if (this.currentArticleToReinforcementMullionTable && this.currentArticleToReinforcementMullionTable.ArticleID) {
          this.currentArticleToReinforcementMullionTable.ArticleID = this.currentArticleToReinforcementMullionTable.ArticleID;
          if (this.framingService.reinforcementData.find(article => article.value.toString() == this.currentArticleToReinforcementMullionTable.ArticleID.toString())) {
            this.selectedReinforcementMullionArticleId = this.framingService.reinforcementData.find(article => article.value.toString() == this.currentArticleToReinforcementMullionTable.ArticleID.toString() && article.moreInfo.toString() == this.currentArticleToReinforcementMullionTable.MullionSteelBarThickness.toString()).id;
           this.selectedReinforcementMullionSteelBarThickness = this.framingService.reinforcementData.find(article => article.value.toString() == this.currentArticleToReinforcementMullionTable.ArticleID.toString() && article.moreInfo.toString() == this.currentArticleToReinforcementMullionTable.MullionSteelBarThickness.toString()).moreInfo;
          }
          else {
            this.selectedReinforcementMullionArticleId = defaultReinforcementArticle;
            this.selectedReinforcementMullionSteelBarThickness = defaultReinforcementSteelbarThickness;
          }
        }
        else {
          this.selectedReinforcementMullionArticleId = defaultReinforcementArticle;
          this.selectedReinforcementMullionSteelBarThickness = defaultReinforcementSteelbarThickness;
        }
        if (this.listOfDisplayDataInTable_reinforcement.find(article => article.id == this.selectedReinforcementMullionArticleId)){
          this.tableComponent.selectRow(this.listOfDisplayDataInTable_reinforcement.find(article => article.id == this.selectedReinforcementMullionArticleId), true);
          this.confirmReinforcement();
        }
        //this.selectedReinforcementMullionArticle = defaultReinforcementArticle;
       // this.tableComponent.selectRow(this.listOfDisplayDataInTable_reinforcement.find(article => article.id == this.selectedReinforcementMullionArticleId), true);
        //this.changeReinforcementMullionInputValueEvent.emit({ article: this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId), isCustomed: false });
        // if (this.framingService.reinforcementData.length > 0) {
        //   this.cpService.setConfirm({ article: this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId) }, PanelsModule.ReinforcementFacade);
        //   this.umService.set_ReinforcementFacade(true, { article: this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId) });
        // }
        // else {
        //   this.cpService.setConfirm({ article: null }, PanelsModule.ReinforcementFacade);
        //   this.umService.set_ReinforcementFacade(true, { article: null });
        // }
        // if (this.framingService.reinforcementData.length > 0) {
        //   if (this.currentReinforcementToMullionDepthTable && this.currentReinforcementToMullionDepthTable.ArticleID) {
        //     this.selectedReinforcementMullionArticle = this.framingService.reinforcementData.map(article => article.mullionArticleId.toString()).indexOf(this.currentArticleToMullionDepthTable.ArticleID);
        //     if(this.selectedReinforcementMullionArticle < 0)
        //       this.selectedReinforcementMullionArticle = defaultReinforcementArticle;
        //       this.cpService.setConfirm({article: this.framingService.reinforcementData.find(article => article.id == this.selectedReinforcementMullionArticleId)}, PanelsModule.ReinforcementFacade);
        //   }
        //   else {
        //     this.cpService.setConfirm({article: null}, PanelsModule.ReinforcementFacade);
        //   }
        // }
        // else {
        //   this.cpService.setConfirm({article: null}, PanelsModule.ReinforcementFacade);
        // }
      }, 0);
    });
  }

  //get the transom reinforcement data
  collectTransomReinforcementData(system, transomId: string) {
    
    this.framingService.getFacade_Transom_ReinforcementList(system, transomId).pipe(takeUntil(this.destroy$)).subscribe(data => {
      // setTimeout(() => {
        // 
        this.framingService.setTransomReinforcementData(data);
       // this.transomreinforcementData = data;
        let defaultTransomReinforcementArticle = 0;
        let dataToShowInTransomReinforcementTable = [];
       // this.dataToShowInTable = [];
       this.framingService.transomreinforcementData.forEach((article, index) => {
        article.id = index;
          let translatedMaterial = '';
          if (article.material === 'Aluminium') {
            translatedMaterial = this.translate.instant(_('configure.mullion-depth-reinforcement-aluminium'));
          }
          if (article.material === 'Steel') {
            translatedMaterial = this.translate.instant(_('configure.mullion-depth-reinforcement-steel'));
          }
          dataToShowInTransomReinforcementTable.push({
            id: '' + index,
            description: translatedMaterial + ' ' + this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value,
            column2: {
              ref: this.column2ArrowTemplate,
              context: {
                value: translatedMaterial,
                disabled: false,
                arrowHovered: false,
                index: index
              }
            },
            propertyId: 6,
            value: article.value,
            disabled: false,
            bpsTable4CustomRow: false,
            article: article,
            isMaterialAluminium: article.material === 'Aluminium',
            isMaterialSteel: article.material === 'Steel'
          });
        });

        // this.listOfDisplayDataInTable_TransomReinforcement = [...this.listOfDisplayDataInTable];
        // if (this.currentArticleToReinforcementTransomTable && this.currentArticleToReinforcementTransomTable.ArticleID) {
        //   this.currentArticleToReinforcementTransomTable.ArticleID = this.currentArticleToReinforcementTransomTable.ArticleID;
        //   this.selectedReinforcementTransomArticle = this.transomreinforcementData.map(article => article.value.toString()).indexOf(this.currentArticleToReinforcementTransomTable.ArticleID.toString());
        //   if (this.selectedReinforcementTransomArticle < 0)
        //     this.selectedReinforcementTransomArticle = defaultTransomReinforcementArticle;
        // }
        // else {
        //   this.selectedReinforcementTransomArticle = defaultTransomReinforcementArticle;
        // }
        // if (this.transomreinforcementData.length > 0) {
        //   this.cpService.setConfirm({ article: this.transomreinforcementData[this.selectedReinforcementTransomArticle] }, PanelsModule.TransomReinforcementFacade);
        // }
        // else {
        //   this.cpService.setConfirm({ article: null }, PanelsModule.TransomReinforcementFacade);
        // }

        this.listOfDisplayDataInTable_TransomReinforcement = dataToShowInTransomReinforcementTable.slice();
        if (this.currentArticleToReinforcementTransomTable && this.currentArticleToReinforcementTransomTable.ArticleID) {
          this.currentArticleToReinforcementTransomTable.ArticleID = this.currentArticleToReinforcementTransomTable.ArticleID;
          if (this.framingService.transomreinforcementData.find(article => article.value.toString() == this.currentArticleToReinforcementTransomTable.ArticleID.toString())) {
            this.selectedReinforcementTransomArticleId = this.framingService.transomreinforcementData.find(article => article.value.toString() == this.currentArticleToReinforcementTransomTable.ArticleID.toString()).id;
          }
          else {
            this.selectedReinforcementTransomArticleId = defaultTransomReinforcementArticle;
          }
        }
        else {
          this.selectedReinforcementTransomArticleId = defaultTransomReinforcementArticle;
        }
        if (this.listOfDisplayDataInTable_TransomReinforcement.find(article => article.id == this.selectedReinforcementTransomArticleId)){
          this.tableComponent.selectRow(this.listOfDisplayDataInTable_TransomReinforcement.find(article => article.id == this.selectedReinforcementTransomArticleId), true);
          this.confirmTransomReinforcement();
        }
       
    });
  }

  collectTransomDepthData(system: string) {
    
    var selectedMullion = this.mullionData.find(article => article.id == this.selectedMullionArticleId);
    var mullionId = selectedMullion.mullionArticleId.toString();
    var mullionDepth = selectedMullion.mullionDepth;
    var hasIntermediateMullion : boolean = this.umService.current_UnifiedModel.ModelInput.Geometry.Members.some(member => member.MemberType == 6 || member.SectionID == 3);
    this.framingService.getFacade_TransomList(system, mullionId, hasIntermediateMullion, mullionDepth).pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data && data.length > 0) {
        setTimeout(() => {
          const panelThickness = this.getMaxPanelThickness();
          if(mullionDepth && mullionDepth > 0){
            this.transomData = data.filter(x=> x.transomDepth )
          } else{
            this.transomData = data;
          }
          let defaultTransomArticle = this.transomData.length - 1;
          this.initialLengthTransom = this.transomData.length;
          let dataToShowInTransomDepthTable = [];
          this.transomData.forEach((article, index) => {
            article.id = index;
            dataToShowInTransomDepthTable.push({
              id: '' + index,
              description: this.translate.instant(_('configure.transom')) + " - " + article.transomArticleId,
              column2: {
                ref: this.column2ArrowTemplate,
                context: {
                  value: article.transomDepth,
                  disabled: true,
                  arrowHovered: true,
                  index: index
                }
              },
              propertyId: 1,//1 for transomDepth
              transomArticleId: article.transomArticleId,
              disabled: panelThickness === undefined || panelThickness < article.transomDepth ? false : true,
              bpsTable4CustomRow: false,
              article: article
            });
          });
          this.listOfDisplayDataInTable_transomDepth = dataToShowInTransomDepthTable.slice();
          if (this.currentArticleToTransomDepthTable && this.currentArticleToTransomDepthTable.ArticleID) {
            this.currentArticleToTransomDepthTable.ArticleID = this.framingService.oldToNewFraming(this.currentArticleToTransomDepthTable.ArticleID);
            if (this.transomData.find(article => article.transomArticleId.toString() == this.currentArticleToTransomDepthTable.ArticleID.toString())) {
              this.selectedTransomArticleId = this.transomData.find(article => article.transomArticleId.toString() == this.currentArticleToTransomDepthTable.ArticleID.toString()).id;
            }
            else {
              this.selectedTransomArticleId = this.transomData[defaultTransomArticle].id;
            }
          }
          else {
            this.selectedTransomArticleId = this.transomData[defaultTransomArticle].id;
          }
          
          this.tableComponent.selectRow(this.listOfDisplayDataInTable_transomDepth.find(article => article.id == this.selectedTransomArticleId), true);
          // this.cpService.setConfirm({ article: this.transomData.find(article => article.id == this.selectedTransomArticleId), isCustomed: false }, PanelsModule.TransomFacade);
          this.confirmTransom();
          if (this.umService.isFacadeMullionTransom()) {
            this.collectTransomReinforcementData(system, this.transomData.find(article => article.id == this.selectedTransomArticleId).transomArticleId.toString());
          }
        }, 0);
      }
    });
  }

  collectIntermediateMullionDepthData(system: string, mullionId: string, transomDepth: number = undefined) {
    if (this.umService.isFacadeMullionTransom()) {
      this.framingService.getFacade_Intermediate_MullionList(system, mullionId,transomDepth).pipe(takeUntil(this.destroy$)).subscribe(data => {
        setTimeout(() => {
          if (transomDepth == 0 || !transomDepth)
            this.intermediateMullionData = data;
          else
            this.intermediateMullionData = data.filter(f => f.transomDepth <= transomDepth-6);

          let defaultIntermediateMullionArticle = this.intermediateMullionData.length - 1;
          this.initialLengthIntermediateMullion = this.intermediateMullionData.length;
          let dataToShowInIntermediateMullionTable = [];
          this.intermediateMullionData.forEach((article, index) => {
            article.id = index;
            dataToShowInIntermediateMullionTable.push({
              id: '' + index,
              description: this.translate.instant(_('configure.structural-mullion-depth-table-intermediate-mullion')) + " - " + article.transomArticleId,
              column2: {
                ref: this.column2ArrowTemplate,
                context: {
                  value: article.transomDepth,
                  disabled: true,
                  arrowHovered: true,
                  index: index
                }
              },
              propertyId: 2, // 2 for intermediate mullion depth
              isVerticaleGlazingBar: false,
              descriptionPart2: article.transomArticleId,
              disabled: false,
              bpsTable4CustomRow: false,
              article: article
            });
          });
          this.listOfDisplayDataInTable_intermediateMullionDepth = dataToShowInIntermediateMullionTable.slice();
          if (!this.currentArticleToIntermediateMullionDepthTable) this.currentArticleToIntermediateMullionDepthTable = {};
          this.currentArticleToIntermediateMullionDepthTable.ArticleID = this.umService.obj_IntermediateMullionDepth().ArticleName;
          if (this.currentArticleToIntermediateMullionDepthTable && this.currentArticleToIntermediateMullionDepthTable.ArticleID) {
            let bool = this.intermediateMullionData.map(article => article.transomArticleId.toString()).indexOf(this.currentArticleToIntermediateMullionDepthTable.ArticleID) > -1;
            if (bool && this.intermediateMullionData.find(article => article.transomArticleId.toString() == this.currentArticleToIntermediateMullionDepthTable.ArticleID)) {
              this.selectedIntermediateMullionArticleId = this.intermediateMullionData.find(article => article.transomArticleId.toString() == this.currentArticleToIntermediateMullionDepthTable.ArticleID).id;
            }
            if (!this.intermediateMullionData.find(article => article.transomArticleId.toString() == this.currentArticleToIntermediateMullionDepthTable.ArticleID)) {
              this.selectedIntermediateMullionArticleId = defaultIntermediateMullionArticle;
              this.is_selectedIntermediateMullionArticle_default = true;
            }
          }
          else {
            this.selectedIntermediateMullionArticleId = defaultIntermediateMullionArticle;
            this.is_selectedIntermediateMullionArticle_default = true;
          }
          if (this.listOfDisplayDataInTable_intermediateMullionDepth.length > 0 && this.tableComponent) {
            this.tableComponent.selectRow(this.listOfDisplayDataInTable_intermediateMullionDepth.find(article => article.id == this.selectedIntermediateMullionArticleId), true);
            this.umService.set_IntermediateMullionDepth({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false });            // this.cpService.setConfirm({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false }, PanelsModule.IntermediateMullionFacade);
          }
        });
      });
    }
    else if (this.umService.isFacadeUDC()) {
      let descriptionPrefix = this.translate.instant(_('configure.vertical-glazing-bar'));
      this.framingService.getUDCFramingList(system, mullionId, 'IntermediateMullion').pipe(takeUntil(this.destroy$)).subscribe(data => {
        setTimeout(() => {
          this.intermediateMullionData = data;
          this.intermediateMullionData.forEach((article, index) => {
            article.id = index;
          });
          var secOne = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(section => section.SectionID == 4)[0];
          if (secOne && secOne.isCustomProfile) {
            secOne.transomArticleId = secOne.ArticleName;
            secOne.Name = "" + secOne.ArticleName;
            secOne.Description = secOne.ArticleName;
            this.intermediateMullionData.push(secOne);
          }

          this.initialLengthIntermediateMullion = this.intermediateMullionData.length;
          let dataToShowInIntermediateMullionDepthTable = [];
          this.intermediateMullionData.forEach((article, index) => {
            dataToShowInIntermediateMullionDepthTable.push({
              id: '' + index,
              description: article.isCustomProfile ? descriptionPrefix + " - " + article.Description : descriptionPrefix + " - " + article.transomArticleId,
              inside: article.isCustomProfile ? '- -' : (article.transomWidth ? article.transomWidth : "N/D"),
              column2: {
                ref: this.column2ArrowTemplate,
                context: {
                  value: article.isCustomProfile ? '- -' : (article.transomDepth ? article.transomDepth : "N/D"),
                  disabled: false,
                  arrowHovered: true,
                  index: index
                }
              },
              propertyId: 2, //2 for intermediateMullionDepth,
              isVerticaleGlazingBar: true,
              descriptionPart2: article.isCustomProfile ? article.Description:article.transomArticleId,
              disabled: article.disabled,
              bpsTable4CustomRow: Boolean(article.isCustomProfile),
              article: article
            });
          });
          this.listOfDisplayDataInTable_intermediateMullionDepth = dataToShowInIntermediateMullionDepthTable.slice();
          if (!this.currentArticleToIntermediateMullionDepthTable) this.currentArticleToIntermediateMullionDepthTable = {};
          this.currentArticleToIntermediateMullionDepthTable.ArticleID = this.umService.obj_IntermediateMullionDepth().ArticleName;
          if (this.currentArticleToIntermediateMullionDepthTable && this.currentArticleToIntermediateMullionDepthTable.ArticleID) {
            let defined = false;
            if (this.intermediateMullionData.find(article => article.transomArticleId + "" == this.currentArticleToIntermediateMullionDepthTable.ArticleID)) {
              this.selectedIntermediateMullionArticleId = this.intermediateMullionData.find(article => article.transomArticleId + "" == this.currentArticleToIntermediateMullionDepthTable.ArticleID).id;
              defined = true;
            }
            else if (this.intermediateMullionData.find(article => article.Description == this.currentArticleToIntermediateMullionDepthTable.ArticleID)) {
              this.selectedIntermediateMullionArticleId = this.intermediateMullionData.find(article => article.Description == this.currentArticleToIntermediateMullionDepthTable.ArticleID).id;
              defined = true;
            }
            else { 
              this.selectedIntermediateMullionArticleId = 0;
            }            
            if (defined) {
              if (this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId) && this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId) &&
                this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomDepth < this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomDepth) {
                // if framing Depth 180 and Mullion Depth = 204, change Mullion Depth to 179
                this.selectedIntermediateMullionArticleId = this.intermediateMullionData.find(article => article.transomDepth == this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomDepth - 1).id;;
                this.currentArticleToIntermediateMullionDepthTable.ArticleID = this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).transomArticleId.toString();
              }
            }
          }
          else {
            this.selectedIntermediateMullionArticleId = 0;
          }
          if (!this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).isCustomProfile) {
            this.umService.set_IntermediateMullionDepth({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false });
            // this.cpService.setConfirm({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: false }, PanelsModule.IntermediateMullionFacade);
          } else {
            this.umService.set_IntermediateMullionDepth({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).isCustomProfile });
            // this.cpService.setConfirm({ article: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId), isCustomed: this.intermediateMullionData.find(article => article.id == this.selectedIntermediateMullionArticleId).isCustomProfile }, PanelsModule.IntermediateMullionFacade);
          }
        });
      });
    }
  }

  collectIntermediateTransomDepthData(system: string, mullionId: string) {
    this.framingService.getUDCFramingList(system, mullionId, 'IntermediateTransom').pipe(takeUntil(this.destroy$)).subscribe(data => {
      let descriptionPrefix = this.translate.instant(_('configure.horizontal-glazing-bar'));
      setTimeout(() => {
        this.intermediateTransomData = data;
        var secOne = this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(section => section.SectionID == 5)[0];
        if (secOne && secOne.isCustomProfile) {
          secOne.transomArticleId = secOne.ArticleName;
          secOne.Name = "" + secOne.ArticleName;
          secOne.Description = secOne.ArticleName;
          this.intermediateTransomData.push(secOne);
        }

        this.initialLengthIntermediateTransom = this.intermediateTransomData.length;
        let dataToShowInIntermediateTransomTable = [];
        this.intermediateTransomData.forEach((article, index) => {
          article.id = index;
          dataToShowInIntermediateTransomTable.push({
            id: '' + index,
            description: article.isCustomProfile ? descriptionPrefix + " - " + article.Description : descriptionPrefix + " - " + article.transomArticleId,
            inside: article.isCustomProfile ? '- -' : (article.transomWidth ? article.transomWidth : "N/D"),
            column2: {
              ref: this.column2ArrowTemplate,
              context: {
                value: article.isCustomProfile ? '- -' : (article.transomDepth ? article.transomDepth : "N/D"),
                disabled: false,
                arrowHovered: true,
                index: index
              }
            },
            propertyId: 3, // 3 for intermediate transom depth
            descriptionPart2: article.isCustomProfile ? article.Description : article.transomArticleId,
            isHorizontalGlazingBar: true,
            disabled: article.disabled,
            bpsTable4CustomRow: article.isCustomProfile ? article.isCustomProfile : false,
            article: article
          });
        });
        this.listOfDisplayDataInTable_intermediateTransomDepth = dataToShowInIntermediateTransomTable.slice();
        if (!this.currentArticleToIntermediateTransomDepthTable) this.currentArticleToIntermediateTransomDepthTable = {};
        this.currentArticleToIntermediateTransomDepthTable.ArticleID = this.umService.obj_IntermediateTransomDepth().ArticleName;
        if (this.currentArticleToIntermediateTransomDepthTable && this.currentArticleToIntermediateTransomDepthTable.ArticleID) {
          ////if (this.intermediateTransomData.map(article => article.transomArticleId + "").indexOf(this.selectedIntermediateTransomArticle.toString()))
          if (this.intermediateTransomData.find(article => article.transomArticleId + "" == this.currentArticleToIntermediateTransomDepthTable.ArticleID)) {
            this.selectedIntermediateTransomArticleId = this.intermediateTransomData.find(article => article.transomArticleId + "" == this.currentArticleToIntermediateTransomDepthTable.ArticleID).id;
          }
          if (!this.intermediateTransomData.find(article => article.transomArticleId + "" == this.currentArticleToIntermediateTransomDepthTable.ArticleID)) {
            this.selectedIntermediateTransomArticleId = 0;
          }
          else if (this.unified3DModel.ProblemSetting.FacadeType == 'UDC') {
            if (this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId) && this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId) &&
              this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomDepth < this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomDepth) {
              // if framing Depth 180 and Mullion Depth = 204, change Mullion Depth to 179
              this.selectedIntermediateTransomArticleId = this.intermediateTransomData.find(article => article.transomDepth == this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomDepth - 1).id;
              this.currentArticleToIntermediateTransomDepthTable.ArticleID = this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).transomArticleId.toString();
            }
          }
        }
        else {
          this.selectedIntermediateTransomArticleId = 0;
        }
        if (this.intermediateTransomData.length > 0 && this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId) && !this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).isCustomProfile) {
          this.umService.set_IntermediateTransomDepth({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: false });
          // this.cpService.setConfirm({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: false }, PanelsModule.IntermediateTransomFacade);
        } else if (this.intermediateTransomData.length > 0 && this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId)) {
          this.umService.set_IntermediateTransomDepth({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).isCustomProfile });
          // this.cpService.setConfirm({ article: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId), isCustomed: this.intermediateTransomData.find(article => article.id == this.selectedIntermediateTransomArticleId).isCustomProfile }, PanelsModule.IntermediateTransomFacade);
        }
      });
    });
  }

  collectUDCFramingFacadeData(system: string, mullionId: string) {
    this.setConfigurationCustomGrid(true);
    this.framingService.getUDCFramingList(system, null, 'Framing').pipe(takeUntil(this.destroy$)).subscribe(data => {
      setTimeout(() => {
        this.UDCFramingData = data;
        var secOne = this.unified3DModel.ModelInput.Geometry.FacadeSections ? this.unified3DModel.ModelInput.Geometry.FacadeSections.filter(section => section.SectionID == 1)[0]: null;
        if (secOne && secOne.isCustomProfile) {
          secOne.transomArticleId = secOne.ArticleName;
          secOne.Name = "" + secOne.ArticleName;
          secOne.Description = secOne.ArticleName;
          this.UDCFramingData.push(secOne);
        }
        this.initialLengthUDCFraming = this.UDCFramingData.length;
        let dataToShowInUDCFramingFacadeTable = [];
        this.UDCFramingData.forEach((article, index) => {
          article.id = index;
          dataToShowInUDCFramingFacadeTable.push({
            id: '' + index,
            description: article.isCustomProfile ? "" + article.Description : "" + article.transomArticleId,
            inside: article.isCustomProfile ? '- -' : (article.transomWidth ? article.transomWidth : "N/D"),
            column2: {
              ref: this.column2ArrowTemplate,
              context: {
                value: article.isCustomProfile ? '- -' : (article.transomDepth ? article.transomDepth : "N/D"),
                disabled: false,
                arrowHovered: true,
                index: index
              }
            },
            propertyId: 4, // 4 for UDCFramingDepth
            disabled: false,
            bpsTable4CustomRow: article.isCustomProfile ? article.isCustomProfile : false,
            article: article
          });
        });
        this.listOfDisplayDataInTable_UDCFramingDepth = dataToShowInUDCFramingFacadeTable.slice();
        if (this.currentArticleToUDCFramingDepthTable && this.currentArticleToUDCFramingDepthTable.ArticleID) {
          if (this.selectedUDCFramingArticleId = this.UDCFramingData.find(article => article.transomArticleId + "" == this.currentArticleToUDCFramingDepthTable.ArticleID)) {
            this.selectedUDCFramingArticleId = this.UDCFramingData.find(article => article.transomArticleId + "" == this.currentArticleToUDCFramingDepthTable.ArticleID).id;
          }
          else if (this.UDCFramingData.find(article => article.Description == this.currentArticleToUDCFramingDepthTable.ArticleID)) {
            this.selectedUDCFramingArticleId = this.UDCFramingData.find(article => article.Description == this.currentArticleToUDCFramingDepthTable.ArticleID).id;
          }
          else { 
            this.selectedUDCFramingArticleId = 0;
          }
        }
        else {
          this.selectedUDCFramingArticleId = 0;
        }
        if (this.UDCFramingData.length > 0) // && !this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).isCustomProfile
        {
          this.cpService.setConfirm({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: false }, PanelsModule.UDCFraming);
          //// this.collectUDCFramingFacadeData(system, this.mullionData.find(article => article.id == this.selectedMullionArticleId).mullionArticleId.toString());
          this.collectIntermediateMullionDepthData(system, this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId.toString());
          this.collectIntermediateTransomDepthData(system, this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId.toString());
          // setTimeout(() => {
          //   this.collectIntermediateTransomDepthData(system, this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).transomArticleId.toString());
          // }, 100);
        }
        // else if (this.UDCFramingData.length > 0) {
        //   this.cpService.setConfirm({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId).isCustomProfile }, PanelsModule.UDCFraming);
        // }
        // if (this.selectedUDCFramingArticle > -1 && this.listOfDisplayDataInTable_UDCFramingDepth.length > 0) {
        //   this.tableComponent.selectRow(this.listOfDisplayDataInTable_UDCFramingDepth.find(article => article.id == this.selectedUDCFramingArticleId), true);
        //   this.cpService.setConfirm({ article: this.UDCFramingData.find(article => article.id == this.selectedUDCFramingArticleId), isCustomed: false }, PanelsModule.UDCFraming);
        // }
      }, 0);
    });
  }

  deleteArticle(articleIndex) {
    this.mullionData.splice(articleIndex, 1);
    this.selectedMullionArticleId = Math.min(this.selectedMullionArticleId, this.mullionData.length - 1);
    if (this.selectedMullionArticleId < 0) {
      this.selectedMullionArticleId = 0;
      this.mullionData.splice(articleIndex, 1);
    }
    this.selectedTransomArticleId = Math.min(this.selectedTransomArticleId, this.transomData.length - 1);
    if (this.selectedTransomArticleId < 0) {
      this.selectedTransomArticleId = 0;
    }
    this.transomData.splice(articleIndex, 1);
    this.selectedIntermediateMullionArticleId = Math.min(this.selectedIntermediateMullionArticleId, this.intermediateMullionData.length - 1);
    if (this.selectedIntermediateMullionArticleId < 0) {
      this.selectedIntermediateMullionArticleId = 0;
      this.intermediateMullionData.splice(articleIndex, 1);
    }
    this.dataToShowInTable = this.listOfDisplayDataInTable.slice();
    this.dataToShowInTable.splice(articleIndex, 1);
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
  }

  deleteUDCFramingArticle(articleIndex) {
    this.dataToShowInTable = this.listOfDisplayDataInTable_UDCFramingDepth;
    this.UDCFramingData.splice(articleIndex, 1);
    this.selectedUDCFramingArticleId = Math.min(this.selectedUDCFramingArticleId, this.UDCFramingData.length - 1);
    if (this.selectedUDCFramingArticleId < 0) {
      this.selectedUDCFramingArticleId = 0;
      this.UDCFramingData.splice(articleIndex, 1);
    }
    this.dataToShowInTable.splice(articleIndex, 1);
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
  }

  deleteIntermediateTransomArticle(articleIndex) {
    this.dataToShowInTable = this.listOfDisplayDataInTable_intermediateTransomDepth;
    this.intermediateTransomData.splice(articleIndex, 1);
    this.selectedIntermediateTransomArticleId = Math.min(this.selectedIntermediateTransomArticleId, this.intermediateTransomData.length - 1);
    if (this.selectedIntermediateTransomArticleId < 0) {
      this.selectedIntermediateTransomArticleId = 0;
      this.intermediateTransomData.splice(articleIndex, 1);
    }
    this.dataToShowInTable.splice(articleIndex, 1);
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
  }

  deleteIntermediateMullionArticle(articleIndex) {
    this.dataToShowInTable = this.listOfDisplayDataInTable_intermediateMullionDepth;
    this.intermediateMullionData.splice(articleIndex, 1);
    this.selectedIntermediateMullionArticleId = Math.min(this.selectedIntermediateMullionArticleId, this.intermediateMullionData.length - 1);
    if (this.selectedIntermediateMullionArticleId < 0) {
      this.selectedIntermediateMullionArticleId = 0;
      this.intermediateMullionData.splice(articleIndex, 1);
    }
    this.dataToShowInTable.splice(articleIndex, 1);
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
  }

  addMullionTransomArticle(sectionElement) {
    this.mullionData.push(sectionElement);
    let index = this.listOfDisplayDataInTable.length;
    this.listOfDisplayDataInTable.push({
      id: '' + index,
      description: sectionElement.ArticleName,
      inside: sectionElement.InsideW,
      outside: {
        ref: this.column2Template,
        context: {
          value: sectionElement.OutsideW,
          disabled: false,
          arrowHovered: true,
          index: index
        }
      },
      disabled: false,
      bpsTable4CustomRow: sectionElement.isCustomProfile
    });
    setTimeout(() => {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable[index]);
    });
  }

  updateMullionTransomArticle(data) {
    let index = data.index;
    let property = data.sectionElement;
    this.mullionData[index] = property;
    this.transomData[index] = property;
  }

  onDblClickRow(event) {
    this.tableComponent.selectRow(event);
    //this.tableComponent1.selectRow(event);
    this.onConfirmMullionArticle();
  }

  checkMarginTopOfMullionTransomTable() {
    this.marginTopTable = this.unified3DModel.ProblemSetting.EnableStructural ? '-10px' : '2px';
    if (this.divOftable) {
      this.render.setStyle(this.divOftable.nativeElement, 'margin-top', this.marginTopTable);
    }
  }

  addUDCFramingArticle(sectionElement) {
    this.dataToShowInTable = this.listOfDisplayDataInTable_UDCFramingDepth;
    this.UDCFramingData.push(sectionElement);
    let index = this.dataToShowInTable.length;
    this.dataToShowInTable.push({
      id: '' + index,
      description: sectionElement.ArticleName,
      inside: sectionElement.InsideW,
      outside: {
        ref: this.column2Template,
        context: {
          value: sectionElement.OutsideW,
          disabled: false,
          arrowHovered: true,
          index: index
        }
      },
      propertyId: 4, // 4 for UDCFramingDepth
      disabled: false,
      bpsTable4CustomRow: sectionElement.isCustomProfile
    });
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
    this.listOfDisplayDataInTable_UDCFramingDepth = this.dataToShowInTable.slice();

    setTimeout(() => {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable[index]);
    });
  }

  updateUDCFramingArticle(data) {
    let index = data.index;
    let property = data.sectionElement;
    this.UDCFramingData[index] = property;
  }

  addIntermediateTransomArticle(sectionElement) {
    this.dataToShowInTable = this.listOfDisplayDataInTable_intermediateTransomDepth;
    this.intermediateTransomData.push(sectionElement);
    let index = this.dataToShowInTable.length;
    this.dataToShowInTable.push({
      id: '' + index,
      description: this.translate.instant(_('configure.structural-mullion-depth-table-intermediate-transom')) + " - " + sectionElement.ArticleName,
      inside: sectionElement.InsideW,
      outside: {
        ref: this.column2Template,
        context: {
          value: sectionElement.OutsideW,
          disabled: false,
          arrowHovered: true,
          index: index
        }
      },
      propertyId: 3, // 3 for intermediateTransomDepth
      descriptionPart2: sectionElement.ArticleName,
      isHorizontalGlazingBar: false,
      disabled: false,
      bpsTable4CustomRow: sectionElement.isCustomProfile,
      sectionElement: sectionElement
    });
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
    this.listOfDisplayDataInTable_intermediateTransomDepth = this.dataToShowInTable.slice();

    setTimeout(() => {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable[index]);
    });
  }

  updateIntermediateTransomArticle(data) {
    let index = data.index;
    let property = data.sectionElement;
    this.intermediateTransomData[index] = property;
  }

  addIntermediateMullionArticle(sectionElement) {
    this.dataToShowInTable = this.listOfDisplayDataInTable_intermediateMullionDepth;
    this.intermediateMullionData.push(sectionElement);
    let index = this.dataToShowInTable.length;
    this.dataToShowInTable.push({
      id: '' + index,
      description: this.translate.instant(_('configure.structural-mullion-depth-table-intermediate-mullion')) + " - " + sectionElement.ArticleName,
      inside: sectionElement.InsideW,
      outside: {
        ref: this.column2Template,
        context: {
          value: sectionElement.OutsideW,
          disabled: false,
          arrowHovered: true,
          index: index
        }
      },
      propertyId: 2, // 2 for intermediate mullion depth
      isVerticaleGlazingBar: false,
      descriptionPart2: sectionElement.ArticleName,
      disabled: false,
      bpsTable4CustomRow: sectionElement.isCustomProfile
    });
    this.listOfDisplayDataInTable = this.dataToShowInTable.slice();
    this.listOfDisplayDataInTable_intermediateMullionDepth = this.dataToShowInTable.slice();

    setTimeout(() => {
      this.tableComponent.selectRow(this.listOfDisplayDataInTable[index]);
    });
  }

  updateIntermediateMullionArticle(data) {
    let index = data.index;
    let property = data.sectionElement;
    this.intermediateMullionData[index] = property;
  }

  setDataTranslation() {
    this.configurationCustomGrid.fields[0].display = this.translate.instant(_('configure.description'));
    [this.listOfDisplayDataInTable,
    this.listOfDisplayDataInTable_mullionDepth,
    this.listOfDisplayDataInTable_transomDepth,
    this.listOfDisplayDataInTable_intermediateMullionDepth,
    this.listOfDisplayDataInTable_intermediateTransomDepth,
    this.listOfDisplayDataInTable_UDCFramingDepth,
    this.listOfDisplayDataInTable_reinforcement,
    this.listOfDisplayDataInTable_TransomReinforcement
    ].forEach(list => {
      list.forEach(article => {
        switch (article.propertyId) {
          case 0: // mullionDepth
            article.description = this.translate.instant(_('configure.mullion-depth-mullion-description')) + " - " + article.mullionArticleId;
            break;
          case 1: // transomDepth
            article.description = this.translate.instant(_('configure.transom')) + " - " + article.transomArticleId;
            break;
          case 2: // intermediateMullionDepth
            if (article.isVerticaleGlazingBar) {
              article.description = this.translate.instant(_('configure.vertical-glazing-bar')) + " - " + article.descriptionPart2;
            }
            else {
              article.description = this.translate.instant(_('configure.structural-mullion-depth-table-intermediate-mullion')) + " - " + article.descriptionPart2;
            }
            break;
          case 3: // intermediateTransomDepth
            if (article.isHorizontalGlazingBar) {
              article.description = this.translate.instant(_('configure.horizontal-glazing-bar')) + " - " + article.descriptionPart2;
            }
            else {
              article.description = this.translate.instant(_('configure.structural-mullion-depth-table-intermediate-transom')) + " - " + article.descriptionPart2;
            }
            break;
          case 4: // UDCFramingDepth :  nothing to translate
            break;
          case 5: // reinforcement
            let translatedMaterial = article.isMaterialAluminium ? this.translate.instant(_('configure.mullion-depth-reinforcement-aluminium')): article.isMaterialSteel ? this.translate.instant(_('configure.mullion-depth-reinforcement-steel')):'';
            let index = article.column2.context.index;
            article.description = index === 2 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 5 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) :
                                  index === 3 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 6 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) :
                                  index === 4 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 8 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) :
                                  index === 5 ? translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value + ' + 2 x 10 mm ' + this.translate.instant(_('configure.mullion-depth-reinforcement-steelbar')) :
                                    translatedMaterial + ' ' +  this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value;
            break;
          case 6: // Transomreinforcement
            article.description = (article.isMaterialAluminium ? this.translate.instant(_('configure.mullion-depth-reinforcement-aluminium')):article.isMaterialSteel ? this.translate.instant(_('configure.mullion-depth-reinforcement-steel')):'') + ' ' + this.translate.instant(_('configure.mullion-depth-reinforcement')) + " - " + article.value;
            break;        
        }
      });
    });
    this.listOfDisplayDataInTable = this.listOfDisplayDataInTable.slice();
  }

  notifyUserAboutIncompatibleMullionDepth() {
    const that = this;
    return new Promise(function (resolve, reject) {
      var isIncompatible: boolean = false;
      var selectedMullion = that.mullionData.find(article => article.id == that.selectedMullionArticleId);
      if(selectedMullion.mullionDepth < 85) {
        isIncompatible = true;
        if (isIncompatible) {
          that.modalService.error({
            nzWrapClassName: "vertical-center-modal",
            nzWidth: '410px',
            nzTitle: '',
            nzContent: that.translate.instant(_('notification.incompatible-mullion-depth-with-current-intermediate-mullionDepth-message')),
            nzOkText: that.translate.instant(_('notification.yes')),
            nzOnOk: () => { 
              that.sendDeleteAllIntermediate.emit(true);
              resolve(true);
            },
            nzCancelText: that.translate.instant(_('notification.no')),
            nzOnCancel:() => {
              
              //return true;
              that.SetMullionDepthToLastSelection(that.lastSelectedMullionArticleId);
              
              resolve(false);
            }
          });
          //return isIncompatible;
        }
      } else{
        resolve(true);
      }
    });
  }
}
