<ng-template #notificationTemplate let-notifData="data">
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'chromeWarning'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">Browser Compatibility</div>
              <div class="ant-notification-notice-description">BPS is optimized on Chrome.</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Structural'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_structural_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message"></div>
              <div class="ant-notification-notice-description"></div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Thermal'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_thermal_active.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message"></div>
            <div class="ant-notification-notice-description"></div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'ThermalMissingInformationU'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_thermal_active.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">{{'notification.missing-information' | translate}}</div>
            <div class="ant-notification-notice-description">{{('notification.missing-UValue-information' | translate).replace("{0}", notifData.message1) }}</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Trash_CannotDelete'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/trash.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">Can't delete</div>
            <div class="ant-notification-notice-description">Can't delete this order because some of the orders are already delivered!</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Trash_ItemsDeleted'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/trash.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">Items deleted</div>
            <div class="ant-notification-notice-description">All the items are deleted now. But all your products remain in Configure.</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Trash_ItemsDeleted_Redirection'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/trash.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">Items deleted</div>
            <div class="ant-notification-notice-description">There are no items left and you will be redirected to Home page.</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WarningOrder_InvalidPostCode'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">Invalid Postal Code</div>
            <div class="ant-notification-notice-description">Please add valid address under 'Shipping Address & Shipping Method'.</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WindowSystemAdded'">
        <div class="flex-start">
          <span class="flex-center">
            <img width="28" src="../../../../../../assets/Images/icons/sps_round_icon_window_green.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">{{'notification.window-systems-added' | translate}}</div>
            <div class="ant-notification-notice-description">{{ notifData.message1 }}</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'SlidingSystemAdded'">
        <div class="flex-start">
          <span class="flex-center">
            <img width="28" src="../../../../../../assets/Images//icons/sps_round_icon_slidingdoor_green.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.sliding-door-systems-added' | translate}}</div>
              <div class="ant-notification-notice-description">{{ notifData.message1 }}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'FacadeSystemAdded'">
        <div class="flex-start">
          <span class="flex-center">
            <img width="28" src="../../../../../../assets/Images/icons/sps_round_icon_facade_green.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.facade-systems-added' | translate}}</div>
              <div class="ant-notification-notice-description">{{ notifData.message1 }}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WindowSystemRemoved'">
        <div class="flex-start">
          <span class="flex-center">
            <img width="28" src="../../../../../../assets/Images/icons/sps_round_icon_window_green.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">{{'notification.window-systems-removed' | translate}}</div>
            <div class="ant-notification-notice-description">{{ notifData.message1 }}</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'SlidingSystemRemoved'">
        <div class="flex-start">
          <span class="flex-center">
            <img width="28" src="../../../../../../assets/Images//icons/sps_round_icon_slidingdoor_green.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.sliding-door-systems-removed' | translate}}</div>
              <div class="ant-notification-notice-description">{{ notifData.message1 }}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'FacadeSystemRemoved'">
        <div class="flex-start">
          <span class="flex-center">
            <img width="28" src="../../../../../../assets/Images/icons/sps_round_icon_facade_green.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.facade-systems-removed' | translate}}</div>
              <div class="ant-notification-notice-description">{{ notifData.message1 }}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'EmptyList'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/warningSmall.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.empty-list' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.empty-list-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'UnableToSave'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/warningSmall.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'nav-layout.notification-unable-to-save-title' | translate}}</div>
              <div class="ant-notification-notice-description">{{'nav-layout.notification-unable-to-save-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WarningReport'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/warningSmall.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message"></div>
              <div class="ant-notification-notice-description"></div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'ProjectSavedSuccessfully'">
        <div class="flex-start">
          <span>
            <img width="15.4" src="../../../../../../assets/Images/sps/sps_square_icon_save_blue.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.project-saved-title' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.project-successfully-saved' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticSystemNotSupported'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/warningSmall.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-disabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{('configure.acoustic-system-not-supported' | translate).replace('XXX', notifData.message1)}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticNotSupportedByUDC'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-disabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.acoustic-solver-does-not-support-unitized-facade' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticNoSupportTripleLayer'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-disabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.acoustic-solver-does-not-support-custom-triple-layer-glass' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'topHungAcoustic'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-disabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.acoustic-solver-does-not-support-Top-Hung-openings' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticNoSupportParallelOpenings'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-disabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.acoustic-solver-does-not-support-Parallel-openings' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticNoSupportCustomPanel'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-disabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.acoustic-solver-does-not-support-custom-panel' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticEnabled'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.acoustic-enabled' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.acoustic-is-now-enabled' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'AcousticMissingInformationRw'">
        <div class="flex-start">
          <span>
            <img src="../../../../../../assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.missing-information' | translate}}</div>
              <div class="ant-notification-notice-description">{{('notification.missing-Rw-information' | translate).replace("{0}", notifData.message1)}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'sameNameWarning'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.product-name-already-exists' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.product-name-already-exists-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'DifferentDepth'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.different-depth-title' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.different-depth' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Warning_layerLimit'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.panel-layer-limit' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.panel-layer-limit-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Warning_incorrectLayer'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.incorrect-panel-layer' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.incorrect-panel-layer-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WarningOrderLeft'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.warning' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.hinge-rigid-joints-warning' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WarningDownloadLimitReport'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'report.warning' | translate}}</div>
              <div class="ant-notification-notice-description">{{'report.download-limit-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'WarningStructuralFullReport'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'report.report-is-not-ready-structral-full-report' | translate}}</div>
              <div class="ant-notification-notice-description">{{'report.shortly-available-for-download' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'dimensionMinWarning'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
          <div class="message-container">
            <div class="ant-notification-notice-message">{{'notification.dimensions-limit' | translate}}</div>
            <div class="ant-notification-notice-description">{{'notification.dimensions-limit-min-message' | translate}} W {{ notifData.message1 }} mm x H {{notifData.message2}} mm.</div>
          </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'dimensionMaxWarning'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.dimensions-limit' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.dimensions-limit-max-message' | translate}} W {{ notifData.message1 }} mm x H {{notifData.message2}} mm.</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'incorrectPanelThicknessWarning'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.panel-depth' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.panel-depth-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'transomLimitWarning'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.transom-limit' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.transom-limit-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Warning_DoorAboveTransom'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.configuration-not-supported' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.door-above-transom' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Warning_IncomptabibleSystemWithProjectLocation'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.incompatible-system-with-insert-unit-and-project-location-title' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.incompatible-system-with-insert-unit-and-project-location-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Warning_IntermediateNotificationNotice'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.intermediate-mullion-notice-title' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.intermediate-mullion-notice-message' | translate}}</div>
            </div>
        </div>
      </div>

      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'Warning_LowerMullionAndTransomDepths'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.lower-depths-title' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.lower-depths-message' | translate}}</div>
            </div>
        </div>
      </div>
      <div class="ant-notification-notice-icon" *ngIf="notifData.key == 'ComputeError'">
        <div class="flex-start">
          <span>
            <img width="17" src="../../../../../../assets/Images/sps_notificationexclamation_icon.svg" />
          </span>
            <div class="message-container">
              <div class="ant-notification-notice-message">{{'notification.compute-error' | translate}}</div>
              <div class="ant-notification-notice-description">{{'notification.compute-error-message' | translate}}</div>
            </div>
        </div>
      </div>
      
        <!-- <span *ngIf="notifData.iconCustom !== ''">
          <i nz-icon [nzType]="notifData.iconCustom" style="color: rgb(16, 142, 233);"></i>
        </span> -->
    </div>
  </div>
</ng-template>
<!-- <button nz-button (click)="createBasicNotification(createTemplate)">*</button> -->