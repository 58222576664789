import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { BpsUnifiedProblem } from 'src/app/app-common/models/bps-unified-problem';
import { HomeService } from 'src/app/app-common/services/home.service';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { NotificationService } from 'src/app/app-common/services/notification.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { PermissionService } from 'src/app/app-core/services/permission.service';
import { Feature } from 'src/app/app-core/models/feature';
import { takeUntil } from 'rxjs/operators';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { ResultService } from 'src/app/app-structural/services/result.service';
import { NotificationCustomComponent } from '../../../../app-common/components/notification-custom/notification-custom.component';
import { SystemTableService } from 'src/app/app-structural/services/system-table.service';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { ProblemSetting } from 'src/app/app-common/models/unified-model/ProblemSetting';

@Component({
  selector: 'app-right-configure',
  templateUrl: './right-configure.component.html',
  styleUrls: ['./right-configure.component.css']
})
export class RightConfigureComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private destroy$ = new Subject<void>();
  @Input() unified3DModel: UnifiedModel;
  @Input() currentProblemSetting: ProblemSetting;
  @Input() quickCheckPassed: boolean;
  @Input() orderPlaced: boolean = false;

  @Output() sendParentEvent: EventEmitter<UnifiedModel> = new EventEmitter();
  @Output() eventEmitter: EventEmitter<BpsUnifiedProblem> = new EventEmitter();
  @Output() closeSolverButtonEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() Loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isCheckoutClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() problemListEvent: EventEmitter<BpsUnifiedProblem[]> = new EventEmitter<BpsUnifiedProblem[]>();
  @Output() updateUnified3DModelEvent: EventEmitter<UnifiedModel> = new EventEmitter<UnifiedModel>();
  @Output() closeAllTablesEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() invalidProjectEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NotificationCustomComponent) notifCustomTemplate: NotificationCustomComponent;


  selectedFrameCombinationArticle: string = '';
  problem: BpsUnifiedProblem;
  projectGuid: string;
  problemList: BpsUnifiedProblem[];
  bpsAnalysisResult: boolean[] = [];
  currentIndex: number;
  selectedIndex: number;
  currentProblemGuid: string;
  timer: any;
  preventSimpleClick: boolean = false;
  isUpdatePreviousProblemFinishedSubsciption: Subscription;
  rightPanelOpenSubscription: Subscription;
  callType: string;
  newProblemGuid: string;
  problemSubscription: Subscription;
  computeSubjectSubscription: Subscription;
  subTotalSubjectSubscription: Subscription;
  quickCheckPassedSubscription: Subscription

  enableAllButtons: boolean = true;

  isInvalidProduct: boolean = false;
  isCopyCall: boolean = false;
  showCheckout: boolean = false;
  calculatedSubTotal: number;

  addToolTip: string;
  copyToolTip: string;
  selectedGlassIDs: number[] = [];
  feature= Feature;
  isCheckoutDisableInvalLargeDim: boolean = false;
  isCheckoutDisableInvalSmallDim: boolean = false;
  
  computeBool: boolean = false;
  computeBoolSubscription: Subscription;
  isAccordianDisabled: boolean;
  isAccordianDisabledSubscription: Subscription;
  isDisplay: boolean = false;

  
  ngNotificaionShow(event: string) {
    this.notifCustomTemplate.notificationShow(event);
  }

  @HostListener('document:click')
  clickout() {
    // this.notifCustomTemplate.notificationRemove();
  }

  constructor(
    public umService: UnifiedModelService, private router: Router,
    public configureService: ConfigureService, private navLayoutService: NavLayoutService,
    private homeService: HomeService, private translate: TranslateService,
    private notification: NotificationService, private resultService: ResultService,
    private permissionService: PermissionService,
    private cpService: ConfigPanelsService,
    private stService: SystemTableService) {
  }
  ngAfterViewInit(): void {
    this.cpService.obsCheckoutDisable_InvalidLargeDimension.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.isCheckoutDisableInvalLargeDim = response;
      });
    this.cpService.obsCheckoutDisable_InvalidSmallDimension.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.isCheckoutDisableInvalSmallDim = response;
      });
      
    this.umService.obsUnifiedProblem.subscribe(response => {
      if (response) {
        this.isCheckoutDisableInvalLargeDim = false;
        this.isCheckoutDisableInvalSmallDim = false;
      }
    });
    this.umService.obsUnifiedModel.subscribe(
      response => {
        if (response) {
          // this.unified3DModel = response;
          if(this.problemList && this.problemList[this.currentIndex])
          this.problemList[this.currentIndex].UnifiedModel = JSON.stringify(response);
        }
      });
    this.configureService.obsRightPanelOverlay.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.isDisplay = response;      
    });
    this.configureService.computeClickedSubject.pipe(takeUntil(this.destroy$)).subscribe((isClicked) => {
      setTimeout(() => {
        if (!isClicked) this.bpsAnalysisResult[this.currentIndex] = false;
      });
    });
    this.umService.computeClickedSubject.pipe(takeUntil(this.destroy$)).subscribe((isClicked) => {
      setTimeout(() => {
        if (!isClicked) this.bpsAnalysisResult[this.currentIndex] = false;
      });
    });

  }

  ngOnInit(): void {
    this.setTooltipText();
    this.configureService.isProblemSavedFromNavBar.subscribe((isProblemSaved) => {
      if (isProblemSaved) {
        //if (this.actionType === 'Copy') { }
        this.actionType = '';
      }
    });
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setTooltipText();
    });

    this.problemSubscription = this.configureService.problemSubject.subscribe(problem => {
      if (this.configureService.isSelectedFromRightPanel) {
        this.configureService.isSelectedFromRightPanel = false;
        if (this.configureService.isNewProblemCreated) {
          this.configureService.isNewProblemCreated = false;
          this.configureService.computeClickedSubject.next(false);
        }

        if (this.callType && this.callType == "Select") {
          let unifiedModel: UnifiedModel = JSON.parse(this.problem.UnifiedModel);
          if (unifiedModel) {
            if (unifiedModel.AnalysisResult) {
              //this.umService.setUnifiedModel(unifiedModel);
            } else {
              let lastModified = this.configureService.lastModifiedDateForUM;
              this.configureService.sendMessage(false);
              if (!lastModified) this.configureService.lastModifiedDateForUM = null;
            }
          }
          this.configureService.runLoadJsonSelect.next(true);
          this.callType = null;
        }

        else
          this.configureService.runLoadJson.next(true);
        this.router.navigate(['/problemconfigure/', this.newProblemGuid]);
      }
      //#region 
      /* if(this.configureService.isSelectedFromRightPanel){
        this.configureService.isSelectedFromRightPanel = false;
        if(this.configureService.isCopyCall){
          this.configureService.isCopyCall = false;
          this.homeService.CopyProblemByGuid(this.problem.ProblemGuid).subscribe((newProblemGuid) => {
            this.configureService.setProblemShow(newProblemGuid);
            this.configureService.computeClickedSubject.next(false);
            this.router.navigate(['/problemconfigure/', newProblemGuid]);
            this.configureService.runLoadJson.next(true);
          });
        } else{
          this.router.navigate(['/problemconfigure/', this.newProblemGuid]);
          if(this.callType && this.callType == "Select"){
            this.configureService.runLoadJsonSelect.next(true);
            this.callType = null;
          }
          else
            this.configureService.runLoadJson.next(true);
        }
      }
      */
      //#endregion

      this.problem = problem;
      this.currentProblemGuid = problem.ProblemGuid;
      let unifiedModel: UnifiedModel = JSON.parse(this.problem.UnifiedModel);
      this.calculatedSubTotal = unifiedModel && unifiedModel.SRSProblemSetting && unifiedModel.SRSProblemSetting.SubTotal ?
        unifiedModel.SRSProblemSetting.SubTotal : 0;
      this.projectGuid = unifiedModel && unifiedModel.ProblemSetting
        ? unifiedModel.ProblemSetting.ProjectGuid : null;
      
      let infills = unifiedModel.ModelInput.Geometry.Infills.filter(g => g.OperabilitySystemID > 0);
      if (this.permissionService.checkPermission(this.feature.Max4VentsAllowed) && (infills.length > 4)) {
        this.bpsAnalysisResult[this.currentIndex] = false;
        setTimeout(() => {
          this.notification.WarningNotification('Maximum 4 Vents per Product', 'The model support only 4 vents, please adjust the model accordingly before \"go to checkout\".', 0, 'topLeft', '0px');
        }, 200);
      }
      this.updateList();
    });
    this.computeSubjectSubscription = this.configureService.computeClickedSubject.subscribe((isComputeEnabled) => {
      if (this.permissionService.checkPermission(this.feature.Compute) && (isComputeEnabled == false || isComputeEnabled == true)) {
        this.bpsAnalysisResult[this.currentIndex] = this.configureService.isComputeEnabled;
      }
    });
    this.subTotalSubjectSubscription = this.configureService.subTotalFromIframeSubject.subscribe((subTotal) => {
      if(subTotal)
        this.calculatedSubTotal = subTotal? subTotal:0;
    });
    this.quickCheckPassedSubscription = this.configureService.quickCheckPassed.subscribe((isQuickCheckPassed) => {
      let infills = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(g => g.OperabilitySystemID > 0);
      if ((infills.length > 4))
        isQuickCheckPassed = false;
      this.umService.current_UnifiedModel.SRSProblemSetting.QuickCheckPassed = this.bpsAnalysisResult[this.currentIndex] = isQuickCheckPassed;      
      this.CheckProductValidity();
    });
    this.isAccordianDisabledSubscription = this.configureService.isAccordianDisabledSubject.subscribe((isAccordianDisabled) => {
      this.isAccordianDisabled = isAccordianDisabled;
    });
    this.computeBoolSubscription = this.configureService.computeBoolSubject.subscribe((computeBool) => {
      this.computeBool = computeBool;
    });
  }

  setTooltipText() {
    if (this.permissionService.checkPermission(this.feature.OrderingApp)) {
      this.addToolTip = 'New Product';
      this.copyToolTip = 'Copy Product';
    } else if(this.permissionService.checkPermission(this.feature.AnalysisApp)){
      this.addToolTip = this.translate.instant(_('configure.new-configuration'));
      this.copyToolTip = this.translate.instant(_('configure.copy'));
    }
  }


  ngOnDestroy(): void {
    this.notifCustomTemplate.notificationRemove();
    this.destroy$.next();
    this.destroy$.complete();
  
    if (this.isUpdatePreviousProblemFinishedSubsciption)
      this.isUpdatePreviousProblemFinishedSubsciption.unsubscribe();
    if (this.problemSubscription)
      this.problemSubscription.unsubscribe();
    if (this.computeSubjectSubscription)
      this.computeSubjectSubscription.unsubscribe();
    if (this.rightPanelOpenSubscription)
      this.rightPanelOpenSubscription.unsubscribe();
    if (this.subTotalSubjectSubscription)
      this.subTotalSubjectSubscription.unsubscribe();
    if (this.quickCheckPassedSubscription)
      this.quickCheckPassedSubscription.unsubscribe();
  }

  ngOnChanges(Changes: SimpleChanges) {
    this.setTooltipText();
    if (Changes) {
      if (Changes.unified3DModel && Changes.unified3DModel.currentValue) {
        setTimeout(() => {
          // this.unified3DModel = Changes.unified3DModel.currentValue;
          if (this.umService.current_UnifiedModel && this.umService.current_UnifiedModel.ProblemSetting)
            this.showCheckout = this.umService.current_UnifiedModel.ProblemSetting.ProductType ? true : false;
        });
      }
      //  setTimeout(()=>{
      //  if(Changes.quickCheckPassed && !Changes.quickCheckPassed.firstChange){
      //   this.unified3DModel.SRSProblemSetting.QuickCheckPassed = Changes.quickCheckPassed.currentValue;
      //   this.bpsAnalysisResult[this.currentIndex]  = Changes.quickCheckPassed.currentValue;
      //  } 
      // },100);
      if (Changes.event3D && Changes.event3D.currentValue && Changes.event3D.currentValue.eventType === 'selectGlass') {  // when user selects glass from 3D viewer
        var panels = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(f => f.GlazingSystemID != -1).map(f => f.InfillID);
        this.selectedGlassIDs = Changes.event3D.currentValue.value.selectedGlassIDs.filter(f => panels.includes(f))
      }
    }
  }
  updateList() {
    this.configureService.GetProblemsForProject(this.projectGuid).subscribe(problems => {
      this.stService.saveSystemOfProblems(problems);
      this.problemList = problems;
      this.problemListEvent.emit(this.problemList);
      this.enableAllButtons = true;
      for (let i = 0; i < this.problemList.length; i++) {
        let unifiedModel: UnifiedModel = JSON.parse(this.problemList[i].UnifiedModel);
        if(!this.isConfirRenamed)
          this.bpsAnalysisResult[i] = false;
        if (unifiedModel) {
          if (this.permissionService.checkPermission(this.feature.QuickCheck)) {
            if (unifiedModel.SRSProblemSetting) {
              if (unifiedModel.SRSProblemSetting.QuickCheckPassed == undefined) {
                unifiedModel.SRSProblemSetting.QuickCheckPassed = false;
                this.bpsAnalysisResult[i] = unifiedModel.ProblemSetting.ProductType ? true : false;
              } else {
                if (!this.isConfirRenamed)
                  this.bpsAnalysisResult[i] = unifiedModel.SRSProblemSetting.QuickCheckPassed ? true : false;
              }
            }
          } else {
            if (unifiedModel.AnalysisResult)
              this.bpsAnalysisResult[i] = true;
          }
          let infills = unifiedModel.ModelInput.Geometry.Infills.filter(g => g.OperabilitySystemID > 0);
          if (this.permissionService.checkPermission(this.feature.Max4VentsAllowed) && (infills.length > 4)) {
            this.bpsAnalysisResult[i] = false;
          }
        }
        if (this.problem.ProblemGuid === this.problemList[i].ProblemGuid) {
          this.currentIndex = i;
        }
      }
      this.CheckProductValidity();
    }, (error) => this.enableAllButtons = true);
  }

  CheckProductValidity() {
    if (this.bpsAnalysisResult && this.bpsAnalysisResult.length > 0) {
      if (this.permissionService.checkPermission(this.feature.Checkout) && this.bpsAnalysisResult && this.bpsAnalysisResult.length > 0) {
        let invalidConfigurations = this.bpsAnalysisResult.filter(x => x == false); 
        this.isInvalidProduct = invalidConfigurations && invalidConfigurations.length > 0 ? true : false;
        // if(this.unified3DModel.ProblemSetting.ProductType !== 'SlidingDoor') {
        //   this.isInvalidProduct = invalidConfigurations && invalidConfigurations.length > 0 ? true : false;
        // } else {
        //   this.isInvalidProduct = false;
        // }
        
        this.invalidProjectEvent.emit(this.isInvalidProduct);
      }
    } 
  }

  updateListAndRedirect() {
    this.configureService.GetProblemsForProject(this.projectGuid).subscribe(problems => {
      this.stService.saveSystemOfProblems(problems);
      this.problemList = problems;
      this.enableAllButtons = true;
      this.populate_bpsAnalysisResult();
      setTimeout(() => {
        this.onMove(this.problemList.length - 1);
      }, 200);
    }, (error) => this.enableAllButtons = true);
  }

  populate_bpsAnalysisResult() {
    for (let i = 0; i < this.problemList.length; i++) {

      let unifiedModel: UnifiedModel = JSON.parse(this.problemList[i].UnifiedModel);

      this.bpsAnalysisResult[i] = false;
      if (unifiedModel) {
        if (this.permissionService.checkPermission(this.feature.QuickCheck)) {
          if (unifiedModel.SRSProblemSetting) {
            if (unifiedModel.SRSProblemSetting.QuickCheckPassed == undefined) {
              unifiedModel.SRSProblemSetting.QuickCheckPassed = false;
              this.bpsAnalysisResult[i] = unifiedModel.ProblemSetting.ProductType ? true : false;
            } else {
              this.bpsAnalysisResult[i] = unifiedModel.SRSProblemSetting.QuickCheckPassed ? true : false;
            }
          }
        } else {
          if (unifiedModel.AnalysisResult)
            this.bpsAnalysisResult[i] = true;
        }
      }
      if (this.problem.ProblemGuid === this.problemList[i].ProblemGuid) {
        this.currentIndex = i;
      }
    }
  }

  // onClickOnCreateBtn() {
  //   this.enableAllButtons = false;
  //   if(this.isUpdatePreviousProblemFinishedSubsciption) {this.isUpdatePreviousProblemFinishedSubsciption.unsubscribe();}
  //   this.saveProblemFromRightPanel();
  //   this.homeService.CreateDefaultProblemForProject(this.projectGuid).subscribe((newProblem) => {
  //     this.problem = newProblem;
  //     this.configureService.isNewProblemCreated = true;
  //     this.redirectToNewProblem(newProblem.ProblemGuid);
  //   }, (error)=> this.enableAllButtons = true);
  // }

  actionType = '';
  onClickOnCreateBtn() {
    this.stService.saveSystemsSubject.next(true);
    this.actionType = 'Create';
    const that = this;
    if(this.umService.current_UnifiedModel.SRSProblemSetting !== null){
      this.umService.current_UnifiedModel.SRSProblemSetting.QuickCheckPassed = this.bpsAnalysisResult[this.currentIndex];
    }
    this.Loaded.emit(true);
    this.enableAllButtons = false;
    const currentProblem: UnifiedModel = that.umService.current_UnifiedModel;
    if (this.isUpdatePreviousProblemFinishedSubsciption) { this.isUpdatePreviousProblemFinishedSubsciption.unsubscribe(); }
    this.configureService.lastModifiedDateForUM = new Date();
    this.saveProblemFromRightPanel();
    this.configureService.updateProblem(that.umService.current_UnifiedModel).subscribe(data => {
      var createAfterSave = function () {
        if (that.configureService.isSaved === true) {
          that.homeService.CreateDefaultProblemForProject(that.projectGuid, that.configureService.applicationType).subscribe(
            (newProblem) => {
              // only creae deafult problem and redirect. exclude the physics and product type assignment from last problem
              if (newProblem) {
                that.stService.unselectArticle();
                that.configureService.isNewProblemCreated = true;
                that.configureService.isDefaultProblemCreatedFromRightPanel = true;
                setTimeout(() => {
                  that.updateListAndRedirect();
                }, 200);
              } else {
                that.configureService.sendMessage(false);
              }
            },
            (error) => {
              that.Loaded.emit(false);
              that.enableAllButtons = true;
            }
          );
        }
        else setTimeout(createAfterSave, 50);
      };
      createAfterSave();
    });
  }

  onClickOnCopyBtn() {
    this.stService.saveSystemsSubject.next(true);
    this.actionType = 'Copy';
    if(this.umService.current_UnifiedModel.SRSProblemSetting !== null){
      this.umService.current_UnifiedModel.SRSProblemSetting.QuickCheckPassed = this.bpsAnalysisResult[this.currentIndex];
    }
    this.Loaded.emit(true);
    this.enableAllButtons = false;
    const that = this;
    if (this.isUpdatePreviousProblemFinishedSubsciption) { this.isUpdatePreviousProblemFinishedSubsciption.unsubscribe(); }
    this.configureService.lastModifiedDateForUM = new Date();
    this.saveProblemFromRightPanel();
    this.configureService.updateProblem(that.umService.current_UnifiedModel).subscribe(data => {
      setTimeout(() => {
        var createAfterSave = function () {
          if (that.configureService.isSaved === true) {
            that.homeService.CopyProblemByGuid(that.problem.ProblemGuid).subscribe(
              (newProblemGuid) => {
                that.stService.unselectArticle();
                that.configureService.isNewProblemCreated = true;
                setTimeout(() => {
                  that.updateListAndRedirect();
                  setTimeout(() => {
                    that.umService.callTakeScreenShot(true);
                  }, 1000);
                }, 200);
              },
              (error) => {
                that.Loaded.emit(false);
                that.enableAllButtons = true;  
              }
            );
          }
          else setTimeout(createAfterSave, 50);
        };
        createAfterSave();
      }, 10);
    });
  }

  DeleteProblemReport(ProjectGuid: string, ProblemGuid: string) {
    this.resultService.DeleteProblemReport(ProjectGuid, ProblemGuid).subscribe((val) => {});
  }

  onClickOnDeleteBtn() {
    this.Loaded.emit(true);
    this.bpsAnalysisResult.splice(this.currentIndex, 1);
    this.homeService.DeleteProblemByGuid(this.problem.ProblemGuid).subscribe(
      (deletedProblemGuid) => {
        this.DeleteProblemReport(this.projectGuid, this.problem.ProblemGuid);
        this.configureService.GetProblemsForProject(this.projectGuid).subscribe(
          problems => {
            this.stService.unselectArticle();
            this.stService.saveSystemOfProblems(problems);
            this.problemList = problems;
            let currentProblem = this.problemList[this.problemList.length - 1];
            if (currentProblem) {
              let unifiedModel: UnifiedModel = JSON.parse(currentProblem.UnifiedModel);
              if (unifiedModel && !unifiedModel.AnalysisResult) {
                let lastModified = this.configureService.lastModifiedDateForUM;
                this.configureService.sendMessage(false);
                if (!lastModified) this.configureService.lastModifiedDateForUM = null;
                else this.configureService.lastModifiedDateForUM = new Date(currentProblem.ModifiedOn);
              }
              this.configureService.configureCall = true;
              this.configureService.setProblemShow(currentProblem.ProblemGuid);
              this.router.navigate(['/problemconfigure/', currentProblem.ProblemGuid]);
              this.Loaded.emit(false);
            } else {
              this.Loaded.emit(false);
            }
          },
          error => {
            this.Loaded.emit(false);
          }
        );
        this.CheckProductValidity();
      },
      (error) => {
        this.Loaded.emit(false);
      }
    );
  }

  isConfirRenamed = false;
  onRename(index: any): void {
    let currentProblem = this.problemList[index];
    if (currentProblem) {
      let unifiedModel = JSON.parse(currentProblem.UnifiedModel);
      if (unifiedModel) {
        if (currentProblem.ProblemName){
          currentProblem.ProblemName = currentProblem.ProblemName.trim();
          let oldProblemName = unifiedModel.ProblemSetting.ConfigurationName;
          let newProblemName = currentProblem.ProblemName;
          let listOfAllOtherProblemNames = this.problemList.map((problem, i) => i !=index ? problem.ProblemName:'').filter(String);
          let does_newProblemName_alreadyExist: Boolean = listOfAllOtherProblemNames.includes(newProblemName);
          let is_newProbleName_blank: Boolean = newProblemName == '';
          if (does_newProblemName_alreadyExist) {
            this.ngNotificaionShow('sameNameWarning');
            this.problemList[index].ProblemName = oldProblemName;
            this.orderPlaced = !this.orderPlaced;
            setTimeout(() => {
              this.orderPlaced = !this.orderPlaced;
            },100);
          }
          else if (is_newProbleName_blank) {
            this.problemList[index].ProblemName = oldProblemName;
            this.orderPlaced = !this.orderPlaced;
            setTimeout(() => {
              this.orderPlaced = !this.orderPlaced;
            },100);
          }
          else {
            unifiedModel.ProblemSetting.ConfigurationName = newProblemName;
            this.configureService.editConfigurationName.next(currentProblem.ProblemName);
            if (newProblemName)
              this.configureService.RenameProblem(currentProblem.ProblemGuid, newProblemName).subscribe(response => {
                if (unifiedModel.SRSProblemSetting !== null){
                  unifiedModel.SRSProblemSetting.QuickCheckPassed = this.bpsAnalysisResult[this.currentIndex];
                }
                unifiedModel.ProblemSetting.ConfigurationName = response.ProblemName;
                currentProblem.ProblemName = response.ProblemName;
                this.umService.setUnifiedModel(unifiedModel);
                this.isConfirRenamed = true;
                this.updateList();
                this.populate_bpsAnalysisResult();
              });          
          }         
        }
        else if (this.problemList[index] && unifiedModel.ProblemSetting && unifiedModel.ProblemSetting.ConfigurationName){
          let oldProblemName = unifiedModel.ProblemSetting.ConfigurationName;
          currentProblem.ProblemName  = oldProblemName;
          this.problemList[index].ProblemName = oldProblemName;
          this.orderPlaced = !this.orderPlaced;
          setTimeout(() => {
            this.orderPlaced = !this.orderPlaced;
          },100);
        }
      }
    } 
  }

  rightPanelDisable = false;
  onClick(i: number) {
    this.actionType = 'Click';
    this.rightPanelDisable = true;
    if(this.umService.current_UnifiedModel.SRSProblemSetting !== null){
      this.umService.current_UnifiedModel.SRSProblemSetting.QuickCheckPassed = this.bpsAnalysisResult[this.currentIndex];
    }
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        this.problem = this.problemList[i];
        let previousIndex = this.currentIndex;
        this.currentIndex = i;
        if (this.problem) {
          this.newProblemGuid = this.problem.ProblemGuid;
          this.callType = "Select";
          this.Loaded.emit(true);
          this.configureService.lastModifiedDateForUM = new Date();
          this.saveProblemFromRightPanel();
          if (!this.bpsAnalysisResult[previousIndex]) this.umService.current_UnifiedModel.AnalysisResult = null;
          this.configureService.updateProblem(this.umService.current_UnifiedModel).subscribe(
            data => {
              this.stService.unselectArticle();
              this.stService.saveSystemsSubject.next(true);
              this.redirectToNewProblem(this.problem.ProblemGuid);
            },
            error => {
              this.Loaded.emit(false);
              this.rightPanelDisable = false;
            }
          );
        }
      }
    }, delay);
  }
  onMove(i: number) {
    this.actionType = 'Move';
    this.rightPanelDisable = true;
    if(this.umService.current_UnifiedModel.SRSProblemSetting !== null){
      this.umService.current_UnifiedModel.SRSProblemSetting.QuickCheckPassed = this.bpsAnalysisResult[this.currentIndex];
    }
    this.problem = this.problemList[i];
    this.currentIndex = i;
    if (this.problem) {
      this.newProblemGuid = this.problem.ProblemGuid;
      this.callType = "Select";
      setTimeout(() => {
        this.redirectToNewProblem(this.problem.ProblemGuid);
      }, 100);
    }
  }

  redirectToNewProblem(newProblemGuid) {
    this.newProblemGuid = newProblemGuid;
    if (this.isUpdatePreviousProblemFinishedSubsciption) { this.isUpdatePreviousProblemFinishedSubsciption.unsubscribe(); }
    this.isUpdatePreviousProblemFinishedSubsciption = this.configureService.isProblemSavedForRightPanel.subscribe((val) => {
      if (val) {
        this.configureService.isSelectedFromRightPanel = true;
        this.configureService.configureCall = true;
        if (newProblemGuid) this.configureService.setProblemShow(this.newProblemGuid);
        this.configureService.isProblemSavedForRightPanel.next(false);
        setTimeout(() => {
          this.rightPanelDisable = false;
          this.Loaded.emit(false);
        }, 1000);
      }
    });
  }

  saveProblemFromRightPanel() {
    this.configureService.isSaved = false;
    this.configureService.saveProblemFromRightPanelSubject.next(true);
  }
  log(event: any) {
  }
  getCurrentProblemClass(problem: any, isLast: any) {
    return { 'bps-button-editable-selected': this.currentProblemGuid === problem.ProblemGuid, 'last': isLast }
  }
  getCurrentProblemDisabled(problem: any) {
    return this.currentProblemGuid === problem.ProblemGuid;
  }
  onCheckout() {
    let infills = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(g => g.OperabilitySystemID > 0);
    if (this.permissionService.checkPermission(this.feature.Max4VentsAllowed) && (this.selectedGlassIDs.length > 4 || infills.length > 4)) {
      setTimeout(() => {
        this.notification.WarningNotification('Maximum 4 Vents per Product', 'The model support only 4 vents, please adjust the model accordingly before \"go to checkout\".', 0, 'topLeft', '0px');
        this.bpsAnalysisResult[this.currentIndex] = false;
      }, 200);

    } else {
      this.isCheckoutClicked.emit(true);
    }

  }

  enableCreateButton(limit:number){
    return (this.permissionService.checkPermission(Feature.CreateNewProject) ||
       (this.permissionService.checkPermission(Feature.CreateNewOrder) && this.problemList && this.problemList.length < limit && this.orderPlaced == false));
  }

  onCompute(): void {
    this.configureService.emitOnCompute();
  }
}

