<div *ngIf="unified3DModel" class="accordion-section-container">
  <div>
    <div class="sps-accordion-section" id="config-structural">
      <div id="sps-structural">
        <form bps-form [formGroup]="validateForm">
          <bps-form-item class="bps-form-item">
            <bps-form-control>
              <div class="sps-str-windload-heading ">
                  <h3 [ngClass]="{'blue-heading': isWindLoadOpened}" class="permissibleTitle"  style="margin-bottom: 7px;">
                    {{'configure.left-configure-structural-wind-load-calculation' | translate}}
                  </h3>
                <div class="wind-load-calculation-container">
                  <input style="width: 170px; color: #ffffff !important; border-color: #00a2d1 !important;text-align-last: left !important;" 
                    disabled bpsDisabled bps-input formControlName="windLoadSelectedText"
                    (ngModelChange)="onWindLoadChange()" [(ngModel)]="calculateWindLoadSelection">
                  <button type="button" bps-button bpsType="variation-13" [ngClass]="{'active': isWindLoadOpened}" (click)="onOpenLeftTable()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956" viewBox="0 0 12.967 11.956">
                      <defs>
                        <style>
                          .cls-1 {
                            fill: none;
                            stroke: #fff;
                            stroke-linecap: round;
                            stroke-width: 2px
                          }
                        </style>
                      </defs>
                      <g id="sps_square_icon_changeprofile_enabled_new" transform="translate(-2533.021 -328.325)">
                        <g id="sps_round_icon_rightpanelopened_white" transform="translate(2540.011 329.74)">
                          <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0" class="cls-1" data-name="Path 287"
                            transform="rotate(180 2.282 4.564)" />
                        </g>
                        <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1" data-name="Path 1295"
                          transform="translate(11867.02 22323.951)" />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </bps-form-control>
          </bps-form-item>
          <div class="sps-break" style="margin-top: 26px; margin-bottom: 12.5px;"></div>
          <div *ngIf="!isNotANumber(positiveWindPressure) && !isNotANumber(negativeWindPressure) && language">
            <div class="sps-str-permissabledeflection-heading"
            style="display: flex; align-items: center; justify-content: space-between;">
            <h3 *ngIf="language == 'de-DE'" class="calculated-input-heading">{{'configure.calculated-results' | translate }} (W<sub style="text-transform: lowercase!important;">e</sub>)</h3>
            <h3 *ngIf="language != 'de-DE'" class="calculated-input-heading">{{'configure.calculated-results' | translate }} (W<sub style="text-transform: lowercase!important;">p</sub>)</h3>
            <div value="terrain-category" style="height:16px;" bps-popover [bpsPopoverPlacement]="'right'"
            [bpsPopoverType]="'variation_4'" [bpsPopoverContent]="tooltip_terrainCategory">
            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
          </div>
        </div>
            <!-- Calculated Results -->
            <div class="calculated-results-rectangle" style="margin-top: 7px;">
              <div class="calculated-results-ligne">
                <div class="pressure-title">{{'configure.positive-wind-pressure' | translate }}
                  <!-- W<sub>p</sub> -->
                </div>
                <!-- Positive Wind Pressure -->
                <div class="pressure-value" style="margin-left: 15.2px;">
                  {{ positiveWindPressure.replace(",",".") | number: '':language }}</div>
                <div class="max-pressure-unit" style="margin-right: 5px;">kN/m²</div>
              </div>
              <div class="calculated-results-ligne">
                <div class="pressure-title">{{'configure.negative-wind-pressure' | translate }}
                  <!-- W<sub>p</sub> -->
                </div>
                <!-- Negative Wind Pressure -->
                <div class="pressure-value" style="margin-left: 8.2px;">{{ negativeWindPressure.replace(",",".") | number: '':language }}
                </div>
                <div class="max-pressure-unit" style="margin-right: 5px;">kN/m²</div>
              </div>
            </div>
          </div>
          <div class="sps-break" style="margin-top: 15px; margin-bottom: 12.5px"></div>
          <div class="switch-heading">
            <h3 [ngStyle]="{'color':switchLiveLoad?'#00a2d1':'#ffffff'}">
              {{'configure.horizontal-live-load' | translate | uppercase}}</h3>
            <!-- HORIZONTAL LIVE LOAD -->
            <bps-switch formControlName="switchLiveLoadControl" [(ngModel)]="switchLiveLoad"
              (ngModelChange)="onSwitchLiveLoad()"></bps-switch>
          </div>
          <div *ngIf="switchLiveLoad" style="margin-top:24.5px;">
            <div style="display:flex; align-items: center; justify-content: space-between;margin-bottom:7px;">
              <h3 class="permissibleTitle">{{'configure.load' | translate | uppercase }}</h3>
              <!-- LOAD -->
              <div value="terrain-category" style="height:16px;" bps-popover [bpsPopoverPlacement]="'right'"
                [bpsPopoverType]="'variation_4'" [bpsPopoverContent]="unified3DModel.ModelInput.Structural.WindLoadInputType == 1 ? load_tooltip_userDefined: unified3DModel.ModelInput.Structural.WindLoadInputType == 2 ? load_tooltip_DIN:load_tooltip_NF">
                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
              </div>
            </div>
            <div>
              <div>
                <bps-form-item class="bps-form-item">
                  <bps-form-control>
                    <bps-select style="width: 215px; margin-left:0.4px;" formControlName="horizontalLiveLoadControl" [(ngModel)]="horizontalLiveLoad" (ngModelChange)="onHorizontalLiveLoadChange()" bpsPlaceHolder="{{'configure.select' | translate }}">
                      <!-- Select -->
                      <bps-option *ngIf="unified3DModel.ModelInput.Structural.WindLoadInputType == 2 || configureService.isProjectInGermany()" bpsValue="0.5" bpsLabel="0.5 kN/m ({{'configure.category' | translate }} A, B {{'configure.and' | translate }} C1)"></bps-option>
                      <bps-option *ngIf="unified3DModel.ModelInput.Structural.WindLoadInputType == 2 || configureService.isProjectInGermany()" bpsValue="1" bpsLabel="1.0 kN/m ({{'configure.category' | translate }} C2 - C4 {{'configure.and' | translate }} D)"></bps-option>
                      <bps-option *ngIf="unified3DModel.ModelInput.Structural.WindLoadInputType == 2 || configureService.isProjectInGermany()" bpsValue="2" bpsLabel="2.0 kN/m ({{'configure.category' | translate }} E)"></bps-option>
                      <bps-option *ngIf="unified3DModel.ModelInput.Structural.WindLoadInputType > 2 || configureService.isProjectInFrance()" bpsValue="0.6" bpsLabel="0.6 kN/m ({{'configure.category' | translate }} A {{'configure.and' | translate }} B)"></bps-option>
                      <bps-option *ngIf="unified3DModel.ModelInput.Structural.WindLoadInputType > 2 || configureService.isProjectInFrance()" bpsValue="0.8" bpsLabel="0.8 kN/m ({{'configure.category' | translate }} E1)"></bps-option>
                      <bps-option *ngIf="unified3DModel.ModelInput.Structural.WindLoadInputType > 2 || configureService.isProjectInFrance()" bpsValue="1" bpsLabel="1.0 kN/m ({{'configure.category' | translate }} C1-C4 {{'configure.and' | translate }} D)"></bps-option>
                      <bps-option bpsValue="UD" bpsLabel="{{'configure.user-define' | translate}}"></bps-option>
                    </bps-select>
                  </bps-form-control>
                </bps-form-item>
                <bps-form-item class="bps-form-item" *ngIf="isUserDefineLoadSelected">
                  <bps-form-control>
                    <div>
                      <div style="display:flex; align-items: center; justify-content: space-between;margin-top: 15px;margin-bottom: 7px;">
                        <h3 style="color:#ffffff;">{{'configure.left-configure-structural-userdefine-load-input' | translate}}</h3>
                        <div style="height:16px;" bps-popover [bpsPopoverPlacement]="'right'"
                          [bpsPopoverType]="'variation_4'" [bpsPopoverContent]="height_tooltip">
                          <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                        </div>
                        <!-- User Define Load Input -->
                      </div>
                      <div id="sps-height-num-info" class="sps-height-num-info">
                        <div id="sps-acoustics-height-input-container">
                          <div class="input-container">
                            <input bps-input formControlName="userdefineLoadControl" type="number" min="0" max ="5" style="width: 62px; margin-left: -0.1px"
                               [(ngModel)]="userdefineLoad" name="userdefineLoad" (ngModelChange)="onUserDefineLoadChange()"
                              autocomplete="off" id="sps-acoustics-height-input-box">
                            <div>(kN/m)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </bps-form-control>
                </bps-form-item>
                <bps-form-item class="bps-form-item">
                  <bps-form-control>
                    <div>
                      <div style="margin-top: 22px;margin-bottom: 7px;">
                        <h3 style="color:#ffffff;">{{'configure.height' | translate | uppercase}}</h3>
                        <!-- HEIGHT -->
                      </div>
                      <div id="sps-height-num-info" class="sps-height-num-info">
                        <div id="sps-acoustics-height-input-container">
                          <div class="input-container">
                            <input formControlName="heightControl" type="number" style="width: 62px; margin-left: -0.1px"
                              bps-input [(ngModel)]="wind_horizontalLiveLoadHeight" name="height" (ngModelChange)="onHeightChange()"
                              autocomplete="off" id="sps-acoustics-height-input-box">
                            <div>(mm)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </bps-form-control>
                </bps-form-item>
              </div>
            </div>
          </div>
          <div class="sps-break" style="margin-top: 12.5px; margin-bottom: 12px;"></div>
          <bps-form-item style="margin: 0px !important;">
            <bps-form-control>
              <div class="alloys-container" *ngIf="unified3DModel.ProblemSetting.EnableStructural">
                <h3 class="vent-frame-heading">{{'configure.alloys' | translate }}</h3>
                <!-- Alloys -->
                <div style="margin-top: 7px;">
                  <bps-select formControlName="Alloys" [(ngModel)]="alloysSelection"
                    (ngModelChange)="onAlloyChange($event)" bpsPlaceHolder="{{'configure.select' | translate }}">
                    <!-- Select -->
                    <bps-option bpsValue="6060-T66 (150MPa)" bpsLabel="6060-T66 (150MPa)"></bps-option>
                    <bps-option bpsValue="6063-T6 (160MPa) -DIN" bpsLabel="6063-T6 (160MPa) -DIN"></bps-option>
                    <bps-option bpsValue="6063-T6 (170MPa) -US" bpsLabel="6063-T6 (170MPa) -US"></bps-option>
                    <bps-option bpsValue="6061-T6 (240MPa)" bpsLabel="6061-T6 (240MPa)"></bps-option>
                    <bps-option bpsValue="6065A-T6 (240MPa)" bpsLabel="6065A-T6 (240MPa)"></bps-option>
                  </bps-select>
                </div>
              </div>
            </bps-form-control>
          </bps-form-item>
          <bps-form-item style="margin-top: 15px !important;" *ngIf="umService.isWindow()">
            <bps-form-control>
              <div class="alloys-container" *ngIf="unified3DModel.ProblemSetting.EnableStructural">
                <h3 class="vent-frame-heading">{{'configure.surface-treatment' | translate}}</h3>
                <!-- Surface Treatment -->
                <div style="margin-top: 7px;">
                  <bps-select formControlName="SurfaceTreatment" [(ngModel)]="surfaceTreatmentSelection"
                    (ngModelChange)="onSurfaceTreatmentChange($event)" bpsPlaceHolder="{{'configure.select' | translate }}">
                    <!-- Select -->
                    <bps-option bpsValue="Coated Before Rolling" bpsLabel="{{'configure.coated-before-rolling' | translate}}"></bps-option>
                    <bps-option bpsValue="Anodized Before Rolling" bpsLabel="{{'configure.anodized-before-rolling' | translate}}"></bps-option>
                  </bps-select>
                </div>
              </div>
            </bps-form-control>
          </bps-form-item>
          <div class="sps-break" style="margin-bottom: 12.5px;"></div>
          <div class="sps-str-permissabledeflection-heading"
            style="display: flex; align-items: center; justify-content: flex-start;">
            <h3 class="permissibleTitle">{{'configure.permissible-deflection' | translate | uppercase}}</h3>
            <!-- PERMISSIBLE DEFLECTION -->
          </div>
          <div id="permissabledeflection-dropdown-container">
            <div>
              <bps-form-item class="bps-form-item">
                <bps-form-control>
                  <bps-select style="width: 215px" formControlName="pemissableDeflectionControl"
                    [(ngModel)]="pemissableDeflectionInput" (ngModelChange)="onPermissableChange()"
                    bpsPlaceHolder="{{'configure.select' | translate }}">
                    <!-- Select -->
                    <bps-option *ngIf="umService.isWindow()" bpsValue="2" bpsLabel="DIN EN 14351-1-2016 CL. B L/200"></bps-option>
                    <bps-option *ngIf="umService.isWindow()" bpsValue="3" bpsLabel="DIN EN 14351-1-2016 CL. C L/300"></bps-option>
                    <bps-option *ngIf="umService.isFacade()" bpsValue="4" bpsLabel="DIN EN 13830:2003"></bps-option>
                    <bps-option *ngIf="umService.isFacade()" bpsValue="5" bpsLabel="DIN EN 13830:2015/2020"></bps-option>
                    <bps-option *ngIf="umService.isFacade()" bpsValue="7" bpsLabel="NF EN 13830 ({{'configure.facade' | translate}})"></bps-option>
                    <bps-option *ngIf="umService.isWindow()" bpsValue="8" bpsLabel="ND DTU 36.5 ({{'configure.window' | translate}})"></bps-option>
                    <!-- Standard -->
                    <!--@ViewResources.Resource.Standard -->
                    <bps-option bpsValue="6" bpsLabel="US-{{'configure.standard' | translate }}"></bps-option>
                    <!-- Standard -->
                    <!--@ViewResources.Resource.Standard -->
                    <bps-option bpsValue="1" bpsLabel="{{'configure.user-define' | translate }}"></bps-option>
                    <!-- User-Define' -->
                    <!--@ViewResources.Resource.UserDefine -->
                  </bps-select>
                </bps-form-control>
              </bps-form-item>
              <div *ngIf="pemissableDeflectionInput=='1'" id="sps-structural-user-dispindices-container"
                class="sps-structural-user-dispindices-container">
                <bps-form-item class="bps-form-item">
                  <bps-form-control>
                    <div id="sps-structural-user-dispindex-container"
                      style="margin-top: 15px; display: flex !important; align-items: baseline; justify-content: space-between;"
                      class="sps-structural-user-dispindex-container fontUniversforSchueco-430">
                      <div> {{'configure.horizontal' | translate }} </div>
                      <!-- Horizontal -->
                      <div style="display: flex; align-items: baseline;">
                        L &nbsp; / &nbsp;
                        <div id="sps-structural-user-dispindex-input-field">
                          <input style="width: 60px" type="number" bps-input [(ngModel)]="horizontalIndex_1"
                            formControlName="horizontalIndexControl_1" name="horizontalIndex" [ngClass]="{'no-border': !horizontalIndex_1}"
                            (ngModelChange)="onHorizontalIndexChange()" (keypress)="onDisableNavButtons()" id="sps-structural-user-dispindex-input-box"> &nbsp; <span class="hide-and-no-selection">mm</span>
                        </div>
                      </div>
                    </div>
                  </bps-form-control>
                </bps-form-item>
                <bps-form-item class="bps-form-item">
                  <bps-form-control>
                    <div id="sps-structural-user-dispindex-container"
                      style="margin-top: 10px; display: flex !important; align-items: baseline; justify-content: space-between;"
                      class="sps-structural-user-dispindex-container fontUniversforSchueco-430">
                      <div></div>
                      <!-- Horizontal -->
                      <div style="display: flex; align-items: baseline;">
                        {{'configure.or' | translate}} &nbsp;
                        <div id="sps-structural-user-dispindex-input-field">
                          <input style="width: 60px" type="number" bps-input [(ngModel)]="horizontalIndex_2"
                            formControlName="horizontalIndexControl_2" name="horizontalIndex" [ngClass]="{'no-border': !horizontalIndex_2}"
                            (ngModelChange)="onHorizontalIndexChange()" (keypress)="onDisableNavButtons()" id="sps-structural-user-dispindex-input-box"> &nbsp; mm
                        </div>
                      </div>
                    </div>
                  </bps-form-control>
                </bps-form-item>
                <bps-form-item class="bps-form-item">
                  <bps-form-control>
                    <div style="display:flex !important; align-items: baseline; justify-content: space-between;"
                      id="sps-structural-user-verticaldispindex-container"
                      class="sps-structural-user-dispindex-container fontUniversforSchueco-430">
                      <div> {{'configure.vertical' | translate }}</div>
                      <!-- Vertical -->
                      <div style="display: flex; align-items: baseline;">
                        L &nbsp; / &nbsp;
                        <div id="sps-structural-user-verticaldispindex-input-field">
                          <input style="width: 60px" type="number" bps-input [(ngModel)]="verticalIndex_1"
                            name="verticalIndex" formControlName="verticalIndexControl_1" [ngClass]="{'no-border': !verticalIndex_1}"
                            (ngModelChange)="onVerticalIndexChange()" (keypress)="onDisableNavButtons()"
                            id="sps-structural-user-verticaldispindex-input-box"> &nbsp; <span class="hide-and-no-selection">mm</span>
                        </div>
                      </div>
                    </div>
                  </bps-form-control>
                </bps-form-item>
                <bps-form-item class="bps-form-item">
                  <bps-form-control>
                    <div style="display:flex !important; align-items: baseline; justify-content: space-between;"
                      id="sps-structural-user-verticaldispindex-container"
                      class="sps-structural-user-dispindex-container fontUniversforSchueco-430">
                      <div></div>
                      <!-- Vertical -->
                      <div style="display: flex; align-items: baseline;">
                        {{'configure.or' | translate}} &nbsp;
                        <div id="sps-structural-user-verticaldispindex-input-field">
                          <input style="width: 60px" type="number" bps-input [(ngModel)]="verticalIndex_2"
                            name="verticalIndex" formControlName="verticalIndexControl_2"  [ngClass]="{'no-border': !verticalIndex_2}"
                            (ngModelChange)="onVerticalIndexChange()" (keypress)="onDisableNavButtons()"
                            id="sps-structural-user-verticaldispindex-input-box"> &nbsp; mm
                        </div>
                      </div>
                    </div>
                  </bps-form-control>
                </bps-form-item>
              </div>
            </div>
          </div>
          <div *ngIf="umService.isFacadeMullionTransom()" class="sps-break"></div>
          <div class="switch-heading"
            *ngIf="umService.isFacadeMullionTransom()">
            <h3 style="color:#00a2d1">
              {{'configure.boundary-condition' | translate | uppercase}}</h3>
            <!-- BOUNDARY CONDITION -->
            <!-- <bps-switch formControlName="switchBoundary" [(ngModel)]="switchBoundary"
              (ngModelChange)="onSwitchBoundaryCondition()"></bps-switch> -->
            </div>
            <div style="display:block;margin-top:20px;" *ngIf="umService.isFacadeMullionTransom()">
              <bps-form-item>
                <bps-form-control>  
                  <h3 class="calculated-input-heading">{{'configure.top-recess' | translate | uppercase}}</h3>
                  <div class="input-container">
                    <input style="width: 60px" bps-input type="number" min="0" formControlName="topRecess"
                          [(ngModel)]="topRecess" (blur)="onTopRecessChange(topRecess);">
                          <div>mm</div>
                          <div  style="height:12px;margin-left:99px;" bps-popover [bpsPopoverPlacement]="'right'"
                          bpsType="variation-1" [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="topRecessInfo">
                            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                        </div>
                        <ng-template #topRecessInfo>
                          <div style="width: 250px;">
                              <div> {{'configure.top-recess-info' | translate }}</div>
                          </div>
                      </ng-template>
                  </div>
                </bps-form-control>
              </bps-form-item>
              <bps-form-item>
                <bps-form-control>  
                  <h3 class="calculated-input-heading">{{'configure.bottom-recess' | translate | uppercase}}</h3>
                  <div class="input-container">
                    <input style="width: 60px" bps-input type="number" min="0" formControlName="bottomRecess"
                          [(ngModel)]="bottomRecess"
                          (blur)="onBottomRecessChange(bottomRecess);">
                          <div>mm</div>
                          <div value="terrain-category" style="height:12px;margin-left:99px;" bps-popover [bpsPopoverPlacement]="'right'"
                          bpsType="variation-1" [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="bottomRecessInfo">
                            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                           </div>
                           <ng-template #bottomRecessInfo>
                            <div style="width: 250px;">
                                <div>
                                    {{'configure.bottom-recess-info' | translate }}</div>
                            </div>
                        </ng-template>
                  </div>
                </bps-form-control>
              </bps-form-item>
              <div class="sps-break"></div>
            </div>
        </form>

        <div *ngIf="umService.isFacadeMullionTransom()">
          <div class="sps-break" style=" margin-top: 15px; margin-bottom: 15px;border-bottom:1px solid #262626;"></div>
          <div class="sps-str-windload-heading">
            <h3 class="manual-input-heading">{{'configure.left-configure-structural-intermediate-attachment' | translate
              }}</h3>
            <div style="margin-left:-0.1px; margin-top: 7px;">
              <bps-radio-group [(ngModel)]="floorAttachment">
                <label style="width: 220px" bps-radio-button bpsValue="Sliding" bpsRadioButtonType="variation5">
                  <span>{{'configure.left-configure-structural-sliding-bearing-point' | translate}}</span>
                  <img style="margin-top:10px;"
                    src="/assets/Images/icons/sps_boundary_icon_slidingbearingpoint_orange.svg" />
                </label>
                <label style="width: 220px" bps-radio-button bpsValue="Fixed" bpsRadioButtonType="variation5">
                  <span>{{'configure.left-configure-structural-fixed-bearing-point' | translate}}</span>
                  <img style="margin-top:10px;"
                    src="/assets/Images/icons/sps_boundary_icon_fixedbearingpoint_orange.svg" />
                </label>
              </bps-radio-group>
            </div>
            <div class="apply-btn-container">
              <button bps-button bpsType="variation-4"
                [ngStyle]="{'padding-left': configureService.getLanguage() === 'de-DE' ? '9px' : '15px'}" [disabled]="!floorAttachment"
                style="width:80px;" (click)="onAddBoudaryCondition_floorAttachment()">{{'configure.add' | translate
                }}</button>
            </div>
          </div>
          <!-- Mullion Joints -->
          <div class="sps-break"></div>
          <div class="sps-str-windload-heading">
            <h3 class="manual-input-heading">{{'configure.left-configure-structural-mullion-joints' | translate}}
              <span style="float: right;" bpsType="variation-1" bps-popover [bpsPopoverPlacement]="'right'"
              [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="mullionJointTooltip">
              <img  class="iconI"
                src="/assets/Images/icons/sps_information_icon_blue.svg"></span>
            </h3>
            <ng-template #mullionJointTooltip>
              <div style="width: 445px;">
                {{'configure.structural-tooltip-mullion-joints-line1' | translate}}<br /><br />
                {{'configure.structural-tooltip-mullion-joints-line2' | translate}}<br />
                {{'configure.structural-tooltip-mullion-joints-line3' | translate}}
              </div>
            </ng-template>

            <div style="margin-left:-0.1px; margin-top: 7px;">
              <bps-radio-group [(ngModel)]="mullionJoints">
                <label style="width: 220px" bps-radio-button bpsValue="SemiRigid" bpsRadioButtonType="variation5">
                  <span>{{'configure.left-configure-structural-semi-rigid' | translate}}</span>
                  <img style="margin-top: 12.5px;" src="/assets/Images/icons/sps_3d_icon_semirigid_enabled.svg" />
                </label>
                <label style="width: 220px" bps-radio-button bpsValue="Hinged" bpsRadioButtonType="variation5">
                  <span>{{'configure.left-configure-structural-hinged' | translate}}</span>
                  <img style="margin-top: 12.5px;" src="/assets/Images/icons/sps_3d_icon_hinge_enabled.svg" />
                </label>
                <label style="width: 220px" bps-radio-button bpsValue="Rigid" bpsRadioButtonType="variation5">
                  <span>{{'configure.left-configure-structural-rigid' | translate}}</span>
                  <img style="margin-top: 12px;"
                    src="/assets/Images/icons/sps_3d_icon_rigid_enabled.svg" />
                </label>
              </bps-radio-group>
            </div>
            <div class="apply-btn-container">
              <button bps-button bpsType="variation-4"
                [ngStyle]="{'padding-left': configureService.getLanguage() === 'de-DE' ? '9px' : '15px'}" [disabled]="!mullionJoints"
                style="width:80px;" (click)="onAddBoudaryCondition_mullionJoints()">{{'configure.add' | translate
                }}</button>
            </div>
            <!-- Reinforcement Cut -->
            <div class="sps-break" *ngIf="showReinforcementJoint"></div>
            <div class="sps-str-windload-heading" *ngIf="showReinforcementJoint">
              <h3 class="manual-input-heading">{{'configure.left-configure-structural-reinforcement-cut' | translate}}
                <span style="float: right;" bpsType="variation-1" bps-popover [bpsPopoverPlacement]="'right'"
              [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="ReinforcementTooltip">
              <img  class="iconI"
                src="/assets/Images/icons/sps_information_icon_blue.svg"></span>
              </h3>
              <ng-template #ReinforcementTooltip>
                <div>
                  {{'configure.structural-tooltip-reinforcement-joints-line1' | translate}}<br/>{{'configure.structural-tooltip-reinforcement-joints-line2' | translate}}<br/>{{'configure.structural-tooltip-reinforcement-joints-line3' | translate}}
                </div>
              </ng-template>
              <div style="margin-left:-0.1px; margin-top: 7px;">
                <bps-radio-group [(ngModel)]="reinforcementCutValue">
                  <label style="width: 220px" bps-radio-button bpsValue="ReinforcementCut" bpsRadioButtonType="variation5">
                    <span>{{'configure.left-configure-structural-reinforcement-cut' | translate}}</span>
                    <img style="margin-top:14px;" src="/assets/Images/icons/sps_3d_icon_cut_enabled.svg" />
                  </label>
                </bps-radio-group>
              </div>
              <div class="apply-btn-container">
                <button bps-button bpsType="variation-4"
                  [ngStyle]="{'padding-left': configureService.getLanguage() === 'de-DE' ? '9px' : '15px'}" [disabled]="!reinforcementCutValue"
                  style="width:80px;" (click)="onAddReinforcementCut()">{{'configure.add' | translate
                  }}</button>
              </div>
          </div>
        </div>
        <form bps-form [formGroup]="validateForm">
          <div class="temporarily-hidden" style="display: none">
            <div class="sps-break"></div>
            <div class="switch-heading">
              <h3 style="color: #666666;">{{'configure.seismic-load' | translate | uppercase}}</h3>
              <!-- SEISMIC LOAD -->
              <bps-switch formControlName="switchSeismic" [(ngModel)]="switchSeismic" bpsDisabled></bps-switch>
            </div>

            <div class="sps-break"></div>
            <div class="switch-heading">
              <h3 style="color: #666666;">{{'configure.slab-deflection' | translate | uppercase}}</h3>
              <!-- SLAB DEFLECTION -->
              <bps-switch formControlName="switchSlab" [(ngModel)]="switchSlab" bpsDisabled></bps-switch>
            </div>
          </div>
        </form>
        <div class="sps-last-break"></div>
      </div>
    </div>
  </div>
</div>
<app-notification-custom #notifCustomTemplate></app-notification-custom>

<ng-template #tooltip_terrainCategory>
  <div>
    {{'configure.structural-tooltip-terrain-category' | translate}} ≤ 1 m²
  </div>
</ng-template>

<ng-template #height_tooltip>
  <div>    
    {{'configure.structural-tooltip-load-user-define-recommended' | translate}}
  </div>
</ng-template>

<ng-template #load_tooltip_DIN>
  <div style="border: 1.25px solid #00A2D1;">
    <div nz-row class="row first-row">
      <div nz-col nzSpan="24" class="tooltip-cell">
        {{'configure.left-configure-structural-categories-of-use' | translate}}</div>
    </div>
    <div nz-row #2 class="row second-row">
      <div nz-col nzSpan="3" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-category' | translate}}</div>
      <div nz-col nzSpan="7" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-specific-use' | translate}}</div>
      <div nz-col nzSpan="14" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-example' | translate}}</div>
    </div>
    <div nz-row #3 class="row third-row">
      <div nz-col nzSpan="3" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-a' | translate}}</div>
      <div nz-col nzSpan="7" class="tooltip-cell text-cell">
        {{'configure.left-configure-structural-domestic-residential-areas' | translate}}
      </div>
      <div nz-col nzSpan="14" class="tooltip-cell text-cell">
        {{'configure.left-configure-structural-resedential-rooms' | translate}}</div>
    </div>
    <div nz-row #4 class="row fourth-row">
      <div nz-col nzSpan="3" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-b' | translate}}</div>
      <div nz-col nzSpan="7" class="tooltip-cell text-cell">
        {{'configure.left-configure-structural-office-areas' | translate}}</div>
      <div nz-col nzSpan="14" class="tooltip-cell text-cell"></div>
    </div>
    <div nz-row #5 class="row fifth-row">
      <div nz-col nzSpan="3" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-c' | translate}}</div>
      <div nz-col nzSpan="7" class="tooltip-cell text-cell">
        {{'configure.left-configure-structural-congregate-areas' | translate}}</div>
      <div nz-col nzSpan="14" class="tooltip-cell text-cell">
        <p>{{'configure.left-configure-structural-c1-text' | translate}}</p>
        <p>{{'configure.left-configure-structural-c2-text' | translate}}</p>
        <p>{{'configure.left-configure-structural-c3-text' | translate}}</p>
        <p>{{'configure.left-configure-structural-c4-text' | translate}}</p>
        <p>{{'configure.left-configure-structural-c5-text' | translate}}</p>
      </div>
    </div>
    <div nz-row #6 class="sixth-row">
      <div nz-col nzSpan="3" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-d-text' | translate}}</div>
      <div nz-col nzSpan="7" class="tooltip-cell text-cell">
        {{'configure.left-configure-structural-shopping-areas' | translate}}</div>
      <div nz-col nzSpan="14" class="tooltip-cell text-cell"></div>
    </div>
    <div nz-row #7 class="seventh-row">
      <div nz-col nzSpan="3" class="bps-popover-content-title tooltip-cell">
        {{'configure.left-configure-structural-e' | translate}}</div>
      <div nz-col nzSpan="7" class="tooltip-cell text-cell">
        {{'configure.left-configure-structural-storage-areas' | translate}}</div>
      <div nz-col nzSpan="14" class="tooltip-cell text-cell"></div>
    </div>
  </div>
</ng-template>

<ng-template #load_tooltip_NF>
  <div class="main-tootltip-container">
    <div class="tooltip-blue-line margin-bot-16">
        {{'configure.structural-tooltip-table-old' | translate }} :
    </div>
    <div class="tooltip-table margin-bot-16">
      <table>
        <tr>
          <th class="tooltip-table-heading tooltip-table-column-1">
            {{'configure.structural-tooltip-aires-chargees' | translate }}
          </th>
          <th class="tooltip-table-heading tooltip-table-column-2 text-center">
            {{'configure.structural-tooltip-qk' | translate }}
          </th>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} A
          </td>
          <td class="tooltip-white-line blue-side-border tooltip-table-column-2">
            0,6
          </td>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} B
          </td>
          <td class="tooltip-white-line blue-side-border tooltip-table-column-2">
            0,6
          </td>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} C1 & C4
          </td>
          <td class="tooltip-white-line blue-side-border tooltip-table-column-2">
            1,0
          </td>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} C5
          </td>
          <td class="tooltip-white-line blue-side-border tooltip-table-column-2">
            {{'configure.structural-tooltip-load-category-C5' | translate }}
          </td>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} D
          </td>
          <td class="tooltip-white-line blue-side-border tooltip-table-column-2">
            1,0
          </td>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} E1
          </td>
          <td class="tooltip-white-line blue-side-border tooltip-table-column-2">
            0,8
          </td>
        </tr>
        <tr class="category-row-height">
          <td class="tooltip-blue-line blue-side-border blue-border-bottom tooltip-table-column-1">
            {{'configure.structural-tooltip-category' | translate }} E2
          </td>
          <td class="tooltip-white-line blue-side-border blue-border-bottom tooltip-table-column-2">
            {{'configure.structural-tooltip-load-category-E2' | translate }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="tooltip-white-line blue-side-border note1">
            {{'configure.structural-tooltip-load-note1' | translate}}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="tooltip-white-line blue-side-border blue-border-bottom note2">
            {{'configure.structural-tooltip-load-note2' | translate}}
          </td>
        </tr>
      </table>
    </div>
    <div class="tooltip-blue-line">
      <span>
        {{'configure.structural-tooltip-table-new' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #load_tooltip_userDefined>
  <div style="width: 240px;">
    <p>{{'configure.structural-tooltip-load-user-define' | translate }}</p>
  </div>
</ng-template>