import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Input, OnDestroy, TemplateRef } from '@angular/core';
import { NzTreeNodeOptions, NzFormatEmitEvent } from 'ng-zorro-antd';
import { BpsTreeComponent } from 'bps-components-lib';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';

@Component({
  selector: 'app-spacer-type',
  templateUrl: './spacer-type.component.html',
  styleUrls: ['./spacer-type.component.css']
})
export class SpacerTypeComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();

  isPopoutOpened: boolean = false;
  articleSelected: any;
  disableConfirmBtn: boolean = false;
  @ViewChild('bpsTreeComponent', { static: false }) bpsTreeComponent: BpsTreeComponent;
  @Input() confirmSpacerTypeEvent: EventEmitter<any>;
  defaultSelectedKeys = ['1'];
  defaultExpandedKeys = ['100'];

  nodes: NzTreeNodeOptions[];
  language: string = 'en-US';
  headerSelected: boolean = false;
  selectedHeader: boolean = true;
  selectedChildNodeKey: number = 1;

  // french_window_thermal_data = [ // OpeningType : 1 for Fixed, 2 for InwardOpening
  //   {OpeningType: 1, IntercalaireKey: '1', THi1: 0.11, THi2: 0.081, THi3: 0.081, THi4: 0.083},    // Aluminium 
  //   {OpeningType: 1, IntercalaireKey: '102', THi1: 0.08, THi2: 0.08, THi3: 0.08, THi4: 0.08},     // Warm Edge normatif
  //   {OpeningType: 1, IntercalaireKey: '101', THi1: 0.048, THi2: 0.042, THi3: 0.042, THi4: 0.043}, // TGI Spacer M
  //   {OpeningType: 1, IntercalaireKey: '31', THi1: 0.049, THi2: 0.043, THi3: 0.043, THi4: 0.044},  // Thermix TX.N+
  //   {OpeningType: 1, IntercalaireKey: '93', THi1: 0.046, THi2: 0.04, THi3: 0.04, THi4: 0.041},    // Swisspacer Advance
  //   {OpeningType: 1, IntercalaireKey: '85', THi1: 0.047, THi2: 0.041, THi3: 0.041, THi4: 0.042},  // Chromatech Ultra F
  //   {OpeningType: 1, IntercalaireKey: '92', THi1: 0.036, THi2: 0.032, THi3: 0.032, THi4: 0.033},  // Swisspacer Ultimate
  //   {OpeningType: 2, IntercalaireKey: '1', THi1: 0.12, THi2: 0.084, THi3: 0.086, THi4: 0.088},    // Aluminium 
  //   {OpeningType: 2, IntercalaireKey: '102', THi1: 0.08, THi2: 0.08, THi3: 0.08, THi4: 0.08},     // Warm Edge normatif
  //   {OpeningType: 2, IntercalaireKey: '101', THi1: 0.051, THi2: 0.044, THi3: 0.045, THi4: 0.046}, // TGI Spacer M
  //   {OpeningType: 2, IntercalaireKey: '31', THi1: 0.052, THi2: 0.044, THi3: 0.046, THi4: 0.047},  // Thermix TX.N+
  //   {OpeningType: 2, IntercalaireKey: '93', THi1: 0.049, THi2: 0.042, THi3: 0.043, THi4: 0.042},  // Swisspacer Advance
  //   {OpeningType: 2, IntercalaireKey: '85', THi1: 0.049, THi2: 0.042, THi3: 0.044, THi4: 0.044},  // Chromatech Ultra F
  //   {OpeningType: 2, IntercalaireKey: '92', THi1: 0.038, THi2: 0.033, THi3: 0.034, THi4: 0.035},  // Swisspacer Ultimate
  // ];

  @ViewChild('bpsChildNodeTemplate', { read: TemplateRef, static: true }) bpsChildNodeTemplate: TemplateRef<{}>;

  constructor(
    public cpService: ConfigPanelsService,
    private translate: TranslateService,
    private navLayoutService: NavLayoutService,
    private configureService: ConfigureService,
    public umService: UnifiedModelService) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.language = this.configureService.getLanguage();
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.SpacerType) {
          this.isPopoutOpened = response.isOpened;
          if (response.isOpened)
            this.onOpenSpacerTypePopout();
          else if (!this.isPopoutOpened) 
            this.onClose(); }
      });
    this.setNodes();
  }

  onOpenSpacerTypePopout() {
    this.setNodes();
    this.headerSelected = false;
    this.defaultExpandedKeys = [];
    this.defaultSelectedKeys.forEach(key => {
      if (this.nodes.filter(node => node.children.map(child => child.key).includes(key)).length > 0) {
        this.nodes.find(node => node.children.map(child => child.key).includes(key)).active = true;
        this.nodes.filter(node => !node.children.map(child => child.key).includes(key)).forEach(node => {node.active = false;});
        this.defaultExpandedKeys.push(this.nodes.find(node => node.children.map(child => child.key).includes(key)).key);
        this.selectedChildNodeKey = parseInt(this.nodes.find(node => node.children.map(child => child.key).includes(key)).children.find(child => child.key == key).key);
      }    
    });     
  }

  ngAfterViewInit(): void {
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
     let um = this.umService.current_UnifiedModel;
     if(um.ProblemSetting.ProductType !== '') {
      this.setDataByLanguageChange();
     } 
    });
    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.systemFor == 'FRAMING') {
          this.setNodes();
          if (!this.nodes.find(node => node.children.map(child => child.key).includes(this.selectedChildNodeKey.toString()))) {
            this.defaultSelectedKeys = ['1'];
            this.defaultExpandedKeys = ['100'];
            this.onOpenSpacerTypePopout();
            this.onConfirmSpacerType();
          }
        }
      });    
  }

  setNodes(): void {
    //DE & US systems (all except AWS 75.II).
    let insulationZone = this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone && this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone != null ? '.'+this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone: "";
    let isFrenchSystem: boolean = this.umService.isFrenchSystem(this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType + insulationZone);
    let isGermanSystem: boolean = this.umService.isGermanSystem(this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType + insulationZone);
    if (!isFrenchSystem || (isGermanSystem && !this.configureService.isProjectInFrance())) {
      this.nodes = [
        {
          title: '/assets/Images/spacer-type/generalspacers_enabled.svg',
          key: '100',
          active: false,
          children: [
            { title: this.translate.instant(_('configure.spacer-general-aluminium-spacer')), key: '1', isLeaf: true, dPSIValue: '0.11', tPSIValue:'0.11' },  // no translation required
            { title: this.translate.instant(_('configure.spacer-general-steel-spacer')), key: '2', isLeaf: true, dPSIValue: '0.11', tPSIValue:'0.11' },
            { title: this.translate.instant(_('configure.spacer-general-thermally-spacer')), key: '3', isLeaf: true, disabled: false, dPSIValue: '0.08', tPSIValue:'0.08' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/allmetal_enabled.svg',
          key: '200',
          active: false,
          children: [
            { title: "GTS", key: '11', isLeaf: true, dPSIValue: '0.061', tPSIValue:'0.057' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/edgetech_enabled.svg',
          key: '300',
          active: false,
          children: [
            { title: "Super Spacer TriSeal / T-Spacer Premium", key: '21', isLeaf: true, dPSIValue: '0.036', tPSIValue:'0.031' },
            { title: "Super Spacer TriSeal / T-Spacer Premium Plus", key: '22', isLeaf: true, dPSIValue: '0.039', tPSIValue:'0.033' },
            { title: "Super Spacer TruPlas", key: '23', isLeaf: true, dPSIValue: '0.038', tPSIValue:'0.033' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/ensinger_enabled.svg',
          key: '400',
          active: false,
          children: [
            { title: 'Thermix TX.N plus', key: '31', isLeaf: true, dPSIValue: '0.05', tPSIValue:'0.045' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/fenzi_enabled.svg',
          key: '500',
          active: false,
          children: [
            { title: 'Butylver TPS', key: '41', isLeaf: true, dPSIValue: '0.043', tPSIValue:'0.038' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/helima_enabled.svg',
          key: '600',
          active: false,
          children: [
            { title: 'Nirotec 017', key: '51', isLeaf: true, dPSIValue: '0.065', tPSIValue:'0.061' },
            { title: 'Nirotec EVO', key: '52', isLeaf: true, dPSIValue: '0.047', tPSIValue:'0.042' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/igk_enabled.svg',
          key: '700',
          active: false,
          children: [
            { title: 'IGK 611', key: '61', isLeaf: true, dPSIValue: '0.05', tPSIValue:'0.045' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/kommerling_enabled.svg',
          key: '800',
          active: false,
          children: [
            { title: 'Ködispace', key: '71', isLeaf: true, dPSIValue: '0.043', tPSIValue:'0.038' },
            { title: 'Ködispace 4SG', key: '72', isLeaf: true, dPSIValue: '0.045', tPSIValue:'0.040' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/rolltech_enabled.svg',
          key: '900',
          active: false,
          children: [
            { title: 'Multitech', key: '81', isLeaf: true, dPSIValue: '0.035', tPSIValue:'0.030' },
            { title: 'Multitech G', key: '82', isLeaf: true, dPSIValue: '0.035', tPSIValue:'0.030' },
            { title: 'Chromatech', key: '83', isLeaf: true, dPSIValue: '0.068', tPSIValue:'0.066' },
            { title: 'Chromatech Plus', key: '84', isLeaf: true, dPSIValue: '0.064', tPSIValue:'0.060' },
            { title: 'Chromatech ultra F', key: '85', isLeaf: true, dPSIValue: '0.048', tPSIValue:'0.043' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/swisspacer_enabled.svg',
          key: '10000',
          active: false,
          children: [
            { title: 'Swisspacer', key: '91', isLeaf: true, dPSIValue: '0.063', tPSIValue:'0.058' },
            { title: 'Swisspacer Ultimate', key: '92', isLeaf: true, dPSIValue: '0.036', tPSIValue:'0.031' },
            { title: 'Swisspacer Advance', key: '93', isLeaf: true, dPSIValue: '0.047', tPSIValue:'0.042' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/technoform_enabled.svg',
          key: '11000',
          active: false,
          children: [
            { title: 'TGI-Spacer M', key: '101', isLeaf: true, dPSIValue: '0.049', tPSIValue:'0.044' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/thermoseal_enabled.svg',
          key: '12000',
          active: false,
          children: [
            { title: 'Thermobar', key: '111', isLeaf: true, dPSIValue: '0.036', tPSIValue:'0.031' }
          ]
        }
      ];
    }
    //FR systems
    else {
      this.nodes = [
        {
          title: '/assets/Images/spacer-type/generalspacers_enabled.svg',
          key: '100',
          active: false,
          children: [
            { title: this.translate.instant(_('configure.spacer-general-aluminium-spacer')), key: '1', isLeaf: true, dPSIValue: '0.11', tPSIValue:'0.11' },  // no translation required
            { title: this.translate.instant(_('configure.spacer-general-warm-edge-spacer')), key: '102', isLeaf: true, dPSIValue: '0.11', tPSIValue:'0.11' },
          ]
        },
        {
          title: '/assets/Images/spacer-type/ensinger_enabled.svg',
          key: '400',
          active: false,
          children: [
            { title: 'Thermix TX.N plus', key: '31', isLeaf: true, dPSIValue: '0.05', tPSIValue:'0.045' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/swisspacer_enabled.svg',
          key: '10000',
          active: false,
          children: [
            { title: 'Swisspacer Ultimate', key: '92', isLeaf: true, dPSIValue: '0.036', tPSIValue:'0.031' },
            { title: 'Swisspacer Advance', key: '93', isLeaf: true, dPSIValue: '0.047', tPSIValue:'0.042' }
          ]
        },
        {
          title: '/assets/Images/spacer-type/technoform_enabled.svg',
          key: '11000',
          active: false,
          children: [
            { title: 'TGI-Spacer M', key: '101', isLeaf: true, dPSIValue: '0.049', tPSIValue:'0.044' },
            { title: 'TGI-SP16', key: '103', isLeaf: true, dPSIValue: '0.008', tPSIValue:'0.008' }
          ]
        }
      ];
    }
    this.setGeneralSpacer_byCulture();
  }

  onClose() {
    if (this.isPopoutOpened) this.cpService.setPopout(false, PanelsModule.SpacerType);
  }

  bpsCheckBoxChange(event: NzFormatEmitEvent): void {

  }

  // // getPSIValue_Fixed(childnode) {
  // //   switch(this.umService.current_UnifiedModel.ModelInput.FrameSystem.UvalueType) {
  // //     case 'THi1':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 1).THi1;
  // //     case 'THi2':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 1).THi2;
  // //     case 'THi3':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 1).THi3;
  // //     case 'THi4':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 1).THi4;
  // //     default:
  // //       return '';
  // //   }
  // // }

  // // getPSIValue_Inward(childnode) {
  // //   switch(this.umService.current_UnifiedModel.ModelInput.FrameSystem.UvalueType) {
  // //     case 'THi1':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 2).THi1;
  // //     case 'THi2':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 2).THi2;
  // //     case 'THi3':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 2).THi3;
  // //     case 'THi4':
  // //       return this.french_window_thermal_data.find(article => article.IntercalaireKey == childnode.key && article.OpeningType == 2).THi4;
  // //     default:
  // //       return '';  
  // //   }
  // // }

  bpsExpandChange(event: NzFormatEmitEvent): void {
    if (event.keys.length == 2) {
      this.defaultExpandedKeys = [event.node.key];
    }

  }

  bpsSelect(keys: string[]): void {

  }

  setDataByLanguageChange() {
    this.language = this.configureService.getLanguage();
    this.setNodes();
    this.headerSelected = false;
    this.defaultExpandedKeys = [];
    this.defaultSelectedKeys.forEach(key => {
      if (this.nodes.filter(node => node.children.map(child => child.key).includes(key)).length > 0) {
        this.nodes.filter(node => node.children.map(child => child.key).includes(key))[0].active = true;
        this.nodes.filter(node => !node.children.map(child => child.key).includes(key)).forEach(node => {node.active = false;});
        this.defaultExpandedKeys.push(this.nodes.filter(node => node.children.map(child => child.key).includes(key))[0].key);
        this.selectedChildNodeKey = parseInt(this.nodes.filter(node => node.children.map(child => child.key).includes(key))[0].children.filter(child => child.key == key)[0].key);
      }
    });
    this.onConfirm();
  }


  setGeneralSpacer_byCulture() {
    switch (this.language) {
      case 'fr-FR':
        this.nodes[0].title = '/assets/Images/spacer-type/generalspacers_fr_enabled.svg';
        break;
      case 'de-DE':
        this.nodes[0].title = '/assets/Images/spacer-type/generalspacers_de_enabled.svg';
        break;
      case 'en-US':
      default:
        this.nodes[0].title = '/assets/Images/spacer-type/generalspacers_enabled.svg';
        break;
    } 
  }

  onConfirmSpacerType() {
   this.confirmSpacerTypeEvent.emit(this.articleSelected);
   this.nodes.forEach(node => {
    node.active = false; 
  });
    this.onClose();
  }

  getSpacerTypeByKey(key: string) {
    this.defaultSelectedKeys = [key];
    let allNodes_array = [];
    this.nodes.map(node => node.children).forEach(node => allNodes_array = allNodes_array.concat(node));
    if(this.nodes.filter(node => node.children.map(child => child.key).includes(key))[0]) {
      this.defaultExpandedKeys = [this.nodes.filter(node => node.children.map(child => child.key).includes(key))[0].key];
      let currentNode = allNodes_array.find(node => node.key == key);
      if (currentNode) this.articleSelected = [{ key: key, origin: { title: currentNode.title, dPSIValue: currentNode.dPSIValue, tPSIValue: currentNode.tPSIValue} }];
    }
    else {
      this.defaultSelectedKeys = ['1'];
      this.defaultExpandedKeys = ['100'];
      this.onOpenSpacerTypePopout();
      let currentNode = allNodes_array.find(node => node.key == this.selectedChildNodeKey.toString());
      if (currentNode) this.articleSelected = [{ key: key, origin: { title: currentNode.title, dPSIValue: currentNode.dPSIValue, tPSIValue: currentNode.tPSIValue} }];
    }
    this.onConfirmSpacerType();
  }

  onDblClickRow(event) {
    if(event.node.level > 0){
      this.bpsClick(event);
      this.onConfirmSpacerType();
    }
  }

  bpsClick(event: NzFormatEmitEvent): void {
    this.articleSelected = event.selectedKeys;
    this.defaultSelectedKeys = event.keys;
    this.disableConfirmBtn = this.articleSelected[0].level == 0;

  }

  onDblClickSRS(node: any, level: number) {
    if (level > 0) {
      this.Click(node);
      this.onConfirm();
    }
  }

  Click(node: any): void {
    this.headerSelected = false;
    if (node && node.isLeaf) {
      this.selectedChildNodeKey = node.key;
        if (this.defaultSelectedKeys === undefined || this.defaultSelectedKeys === null || !this.defaultSelectedKeys.length) {
          this.defaultSelectedKeys = [];
          this.defaultSelectedKeys.push(node.key)
        }
        this.disableConfirmBtn = false;
    } else {
      this.disableConfirmBtn = true;
    } 
  }

  activeChange(event) {
    if (event === false) {
      this.selectedHeader = true;
    } else {
      this.selectedHeader = false;
    }
    this.headerSelected = true;  
    this.disableConfirmBtn = true;  
  }



  onConfirm() {
    this.getSpacerTypeByKey(this.selectedChildNodeKey.toString());
  }

  isFrenchSystem(): boolean {
    let insulationZone = this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone && this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone != null ? '.'+this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone: "";
    return this.umService.isFrenchSystem(this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType+insulationZone);
  }

}
