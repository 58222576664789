import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { IFrameEvent } from 'src/app/app-structural/models/iframe-exchange-data';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeftConfigureComponent } from '../left-configure/left-configure.component';
import { NzModalService } from 'ng-zorro-antd';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { IframeService } from 'src/app/app-structural/services/iframe.service';
import { Feature } from 'src/app/app-core/models/feature';
import { PermissionService } from 'src/app/app-core/services/permission.service';
import { takeUntil } from 'rxjs/operators';
import { FramingService } from 'src/app/app-structural/services/framing.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { OperabilitySystem } from 'src/app/app-common/models/unified-model/OperabilitySystem';
import { DoorSystem } from 'src/app/app-common/models/unified-model/DoorSystem';

@Component({
  selector: 'app-operability',
  templateUrl: './operability.component.html',
  styleUrls: ['./operability.component.css']
})
export class OperabilityComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * This component links the operability systems to the glass infills.
   * 
   * Each operability system has a unique OperabilitySystemId (1,2,3 or 4) which corresponds to the picker selected (this.cpService.selectedOperabilityPicker = 0,1,2 or 3).
   * 
   * Some tools are used by both Operability and Framing (like the selection of a system, outer frame, vent frame, ...) and may be confusing : 
   *      - On Window, they are only used on the framing section.
   *      - On Facade & UDC, they are only used on the operability section and they are linked to the properties of the unifiedModel.ModelInput.Geometry.OperabilitySystems[]. For instance :
   *          if a system is selected on the framing section, it is related to the unifiedModel.ModelInput.FrameSystem.SystemType.
   *          if a system is selected on the operability (insert unit) section, it is related to the unifiedModel.ModelInput.Geometry.OperabilitySystems[].InsertedWindowType.
   */


  // @ViewChild(NotificationCustomComponent) notifCustomTemplate: NotificationCustomComponent;

  private destroy$ = new Subject<void>();
  @Output() unified3DModelFromOperabilityEvent: EventEmitter<UnifiedModel> = new EventEmitter<UnifiedModel>();
  @Input() iframeEvent: Subject<IFrameEvent>;
  @Input() unified3DModel: UnifiedModel;
  @Input() onOpenCloseFrameCombinationPopoutEvent: EventEmitter<any>;
  @Input() unified3DModelEvent: EventEmitter<UnifiedModel>;
  @Input() event3D: any;
  @Input() canBeDrawnBool: boolean;
  @Input() systemFacadeSelectedEvent: EventEmitter<any>;
  @Input() onCloseFramingPopoutsEvent: EventEmitter<any>;
  @Output() ngNotificaionShow: EventEmitter<any> = new EventEmitter<any>();
  //isSystemFacadeChanged: number = 0;   // skip two calls of Changes.unifiedModel when system changes (no call when equals to 1 or 2)
  isOuterOpened: boolean = false;
  isVentOpened: boolean = false;
  isDoorLeafActiveOpened: boolean = false;
  isDoorLeafPassiveOpened: boolean = false;
  isSillProfileFixedOpened: boolean = false;
  isSillProfileBottomOpened: boolean = false;
  isFrameCombinationOpened: boolean = false;
  isHandleColorOpened: boolean = false;
  isHingeTypeOpened: boolean = false;
  isInsideHandleOpened: boolean = false;
  isOutsideHandleOpened: boolean = false;
  isApplyBtnDisable: boolean = false;
  isTransomGlassExists: boolean = false;
  //@Input() handlePositionEvent: EventEmitter<any>;
  @Input() handleColorEvent: EventEmitter<any>;
  isSystemSelectionChanged: boolean = false;
  systemFacadeSelected: number;
  systemAWS114FacadeSelected: number;
  validateForm: FormGroup;
  validateFormForFacade: FormGroup;
  validateFormSRS: FormGroup;

  aws114SystemValue: string[];

  adsSystemValue = ['ads__75'];
  adsSystemDesc = ['ADS 75'];

  outwardSystemsToInsert: any;
  inwardSystemsToInsert: any;

  outerFrameInputValue: string;
  ventFrameInputValue: string;
  doorLeafActiveInputValue: string;
  doorLeafPassiveInputValue: string;
  sillProfileFixedInputValue: string;
  sillProfileBottomInputValue: string;
  frameCombinationInputValue: string = 'Combination 1';

  mullionInputValue: string;
  transomInputValue: string;
  pickers: any[];
  disabledApplyBtn: boolean = true;
  glassConfig: any;
  fixedOpening_index = { 'Inward': 'Inward', 'Outward': 'Outward' };
  operationType_index = { 'Manual': 'Manual', 'TipTronic': 'TipTronic' };
  operableType_index = { 'tiltTurn-right': "Turn-Tilt-Right", 'tiltTurn-left': "Turn-Tilt-Left", 'sideHung-right': "Side-Hung-Right", 'sideHung-left': "Side-Hung-Left", 'bottomHung': "Bottom-Hung", 'topHung': "Top-Hung", 'parallelOpening': "Parallel-Opening", 'doubleVentSH': 4, 'doubleVentST': 5, 'singleDoor-left': "Single-Door-Left", 'singleDoor-right': "Single-Door-Right", 'doubleDoor-left': "Double-Door-Active-Left", 'doubleDoor-right': "Double-Door-Active-Right" };
  selectedPickerString: string = '-1';
  selectedGlassIDs: number[] = [];
  glassIDsAlreadyApplied: object = {};
  glassAppliedArray = [[], [], [], []];
  language: string = '';
  // inwardText: string = this.translate.instant(_('configure.inward'));
  // outwardText: string = this.translate.instant(_('configure.outward'));
  // doubleVentShShText: string = this.translate.instant(_('configure.double-vent-sh-sh'));
  // doubleVentShTtText: string = this.translate.instant(_('configure.double-vent-sh-tt'));

  // tiltOrTurnRightText: string = this.translate.instant(_('configure.tilt-or-turn-right-handed')); //tiltTurn-right
  // tiltOrTurnLeftText: string = this.translate.instant(_('configure.tilt-or-turn-left-handed')); //tiltTurn-left
  // sideHungRightText: string = this.translate.instant(_('configure.side-hung-right-handed')); //sideHung-right
  // sideHungLeftText: string = this.translate.instant(_('configure.side-hung-left-handed')); //sideHung-left
  // bottomHungText: string = this.translate.instant(_('configure.bottom-hung')); //bottomHung
  // topHungText: string = this.translate.instant(_('configure.top-hung')); //topHung
  // parallelOpeningText: string = this.translate.instant(_('configure.parallel-opening')); //parallelOpening
  // singleDoorLeftText: string = 'Single Door - Left';
  // singleDoorRightText: string = 'Single Door - Right';
  // doubleDoorLeftText: string = 'Double Door - Left Active';
  // doubleDoorRightText: string = 'Double Door - Right Active';
  singleDoorLeftSystemTypeText: string = "Single-Door-Left";
  singleDoorRightSystemTypeText: string = "Single-Door-Right";
  doubleDoorLeftSystemTypeText: string = "Double-Door-Active-Left";
  doubleDoorRightSystemTypeText: string = "Double-Door-Active-Right";
  isTurnTiltLeftSelected: boolean = false;
  isTurnTiltRightSelected: boolean = false;
  isSingleDoorLeftSelected: boolean = false;
  isSingleDoorRightSelected: boolean = false;
  isDoubleDoorLeftSelected: boolean = false;
  isDoubleDoorRightSelected: boolean = false;

  handleColor: string = '';
  hingeType: string = '';
  insideHandle: string = '';
  outsideHandle: string = '';
  insideHandleArticleName: string;
  outsideHandleArticleName: string;
  hingeTypeArticleName: string;
  // handlePositioning: number ;

  windowHeight: number;
  applicationType: string;
  @Input() orderPlaced: boolean;
  //@Input() handlePosition:number;
  // addPickerDisabled: boolean = false;
  isDoorOperableTypeSelected: boolean = false;
  isWindowOperableTypeSelected: boolean = false;
  isDoorType: boolean = false;
  isDoorArticlesDisplay: boolean = false;
  isWindowArticlesDisplay: boolean = false;
  feature = Feature;
  isDoorOperableDisable: boolean = false;
  isWindowOperableDisable: boolean = false;
  isAddBtnDisable: boolean = false;
  isOperablilityPanelActive: boolean = false;
  isTurnTiltDirectionChanged: boolean = false;
  isOpDirectionChangeForCameraReset: boolean = false;
  gasketSelection: string = "AIF";
  insulatingSelection: string = "PA";
  insertInsulationTypeName: string = "PA";
  constructor(public umService: UnifiedModelService, private cpService: ConfigPanelsService,
    public configureService: ConfigureService, private modalService: NzModalService,
    private iframeService: IframeService,
    private translate: TranslateService, private fb: FormBuilder, private lc: LeftConfigureComponent,
    public framingService: FramingService,
    private permissionService: PermissionService) {
    this.applicationType = this.configureService.applicationType;
    this.validateForm = this.fb.group({
      Insulating: ['', [Validators.required]]
    });
    this.validateFormForFacade = this.fb.group({
      Insulating: ['', [Validators.required]],
      Glazing: ['', [Validators.required]],
      InsulationControl: ['', [Validators.required]],
    });
  }
  EnableAcoustic: boolean;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          // this.unified3DModel = response;
          this.addPickerDisable();
        }
      });
    this.umService.obsUnifiedProblem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          // this.unified3DModel = this.umService.current_UnifiedModel;
          this.loadOperability();
          this.UpdateUnfiedModel();
          if(this.umService.isFacadeMullionTransom() && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
            this.getOuterFrameInputValue();
            this.getVentFrameInputValue();
          }
          setTimeout(() => {
            this.reloadInputValues();
            this.loadInputValues();
            this.addPickerDisable();
            this.cpService.selectedOperabilityPicker = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems ? (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.sort(function (a, b) { return a.OperabilitySystemID - b.OperabilitySystemID })[0].OperabilitySystemID - 1): -1;
            this.selectedPickerString = this.cpService.selectedOperabilityPicker.toString();
            this.onSelectPicker();
          }, 100);
        }
      });
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.FrameCombination) this.isFrameCombinationOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.OuterFrame) this.isOuterOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.VentFrame) this.isVentOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.DoorLeafActive) this.isDoorLeafActiveOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.DoorLeafPassive) this.isDoorLeafPassiveOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.SillProfileFixed) this.isSillProfileFixedOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.SillProfileBottom) this.isSillProfileBottomOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.HandleColor) this.isHandleColorOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.HingeType) this.isHingeTypeOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.InsideHandle) this.isInsideHandleOpened = response.isOpened;
        else if (response.panelsModule === PanelsModule.OutsideHandle) this.isOutsideHandleOpened = response.isOpened;
      });
    this.cpService.obsOperabilityPanelActive.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.isOperablilityPanelActive = response;
      });
    this.umService.obsLoadSidePanel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response && response.panelsModule > -1 && response.finishedLoading) {
          if (response.panelsModule === PanelsModule.InsideHandle) this.insideHandle = this.umService.get_InsideHandle(this.cpService.selectedOperabilityPicker);
          else if (response.panelsModule === PanelsModule.HingeType) this.hingeType = this.umService.get_HingeType(this.cpService.selectedOperabilityPicker);
          else if (response.panelsModule === PanelsModule.OutsideHandle) this.outsideHandle = this.umService.get_OutsideHandle(this.cpService.selectedOperabilityPicker);
          else if (response.panelsModule === PanelsModule.OuterFrame) this.outerFrameInputValue =  this.umService.get_OuterFrame(this.cpService.selectedOperabilityPicker);
          else if (response.panelsModule === PanelsModule.VentFrame)  this.ventFrameInputValue = this.umService.get_VentFrame(this.cpService.selectedOperabilityPicker);
        }
      }
    );
    this.cpService.obsSystemList.pipe(takeUntil(this.destroy$)).subscribe(_data => {
      if (this.umService.isFacade()) this.setInwardSystems();
    });
    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response?.systemFor == 'FRAMING' && this.umService.isFacade()) this.getUvalueType();
      }
    );
  }

  getOuterFrameInputValue() {
    this.outerFrameInputValue = this.umService.get_OuterFrame(this.cpService.selectedOperabilityPicker);
  }

  getVentFrameInputValue() {
    this.ventFrameInputValue = this.umService.get_VentFrame(this.cpService.selectedOperabilityPicker);
  }
  
  private addPickerDisable() {
    if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[0] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[0].DoorSystemID > 0) {
      this.isDoorOperableTypeSelected = true;
      this.isWindowOperableTypeSelected = false;
      this.isWindowArticlesDisplay = false;
      this.isDoorArticlesDisplay = true;
      this.isAddBtnDisable = true;
    } else {
      this.isWindowOperableTypeSelected = true;
      this.isWindowArticlesDisplay = true;
      this.isDoorOperableTypeSelected = false;
      this.isDoorArticlesDisplay = false;
      this.isAddBtnDisable = false;
    }
  }

  private loadInputValues() {
    setTimeout(() => {
      this.insideHandle = this.umService.get_InsideHandle(this.cpService.selectedOperabilityPicker);
      this.hingeType = this.umService.get_HingeType(this.cpService.selectedOperabilityPicker);
      this.outsideHandle = this.umService.get_OutsideHandle(this.cpService.selectedOperabilityPicker);
    }, 100);
  }

  reloadInputValues() {
    this.umService.doLoadJSON = false;
    let ds = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems;
    if (ds) {
      if (ds.filter(glass => glass.OutsideHandleArticleName === null || glass.OutsideHandleArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.OutsideHandle);
        this.cpService.setPopout(false, PanelsModule.OutsideHandle);
      }
      if (ds.filter(glass => glass.HingeArticleName === null || glass.HingeArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.HingeType);
        this.cpService.setPopout(false, PanelsModule.HingeType);
      }
      if (ds.filter(glass => glass.InsideHandleArticleName === null || glass.InsideHandleArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.InsideHandle);
        this.cpService.setPopout(false, PanelsModule.InsideHandle);
      }
      if (ds.filter(glass => glass.DoorLeafArticleName === null || glass.DoorLeafArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.DoorLeafActive);
        this.cpService.setPopout(false, PanelsModule.DoorLeafActive);
      }
      if (ds.filter(glass => glass.DoorPassiveJambArticleName === null || glass.DoorPassiveJambArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.DoorLeafPassive);
        this.cpService.setPopout(false, PanelsModule.DoorLeafPassive);
      }
      if (ds.filter(glass => glass.DoorSillArticleName === null || glass.DoorSillArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.SillProfileBottom);
        this.cpService.setPopout(false, PanelsModule.SillProfileBottom);
      }
      if (ds.filter(glass => glass.DoorSidelightSillArticleName === null || glass.DoorSidelightSillArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.SillProfileFixed);
        this.cpService.setPopout(false, PanelsModule.SillProfileFixed);
      }
    }
    let os = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems;
    if (os) {
      if (os.filter(glass => (glass.VentArticleName == null || glass.VentArticleName == undefined) && glass.VentArticleName !== '-1').length > 0) {
        this.cpService.setPopout(true, PanelsModule.VentFrame);
        this.cpService.setPopout(false, PanelsModule.VentFrame);
      }
      if (os.filter(glass => glass.InsertOuterFrameArticleName === null || glass.InsertOuterFrameArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.OuterFrame);
        this.cpService.setPopout(false, PanelsModule.OuterFrame);
      }
      if (os.filter(glass => glass.InsideHandleArticleName === null || glass.InsideHandleArticleName === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.InsideHandle);
        this.cpService.setPopout(false, PanelsModule.InsideHandle);
      }
      if (os.filter(glass => glass.InsideHandleColor === null || glass.InsideHandleColor === undefined).length > 0) {
        this.cpService.setPopout(true, PanelsModule.HandleColor);
        this.cpService.setPopout(false, PanelsModule.HandleColor);
      }
    }
    let sec = this.umService.current_UnifiedModel.ModelInput.Geometry.Sections;
    if (sec) {
      if (sec.filter(glass => glass.SectionID == 2 && glass.Depth == 0).length > 0) {
        this.cpService.setPopout(true, PanelsModule.Mullion);
        this.cpService.setPopout(false, PanelsModule.Mullion);
      }
      if (sec.filter(glass => glass.SectionID == 3 && glass.Depth == 0).length > 0) {
        this.cpService.setPopout(true, PanelsModule.Transom);
        this.cpService.setPopout(false, PanelsModule.Transom);
      }
    }
    setTimeout(() => {
      this.umService.doLoadJSON = true;
      this.umService.callLoadJSON(this.canBeDrawnBool);
    }, 500);
  }

  ngOnInit(): void {
    this.language = this.configureService.getLanguage();
    this.applicationType = this.configureService.applicationType;
    if (this.umService.isFacade()) {
      this.setOutwardSystems();
      this.setInwardSystems();
      if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
        this.getOuterFrameInputValue();
        this.getVentFrameInputValue();
      }
    }
    setTimeout(() => {
      this.reloadInputValues();
      this.loadInputValues();
      this.onSelectPicker();
    }, 500);
  }

  loadOperability() {
    this.cpService.selectedOperabilityPicker = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.map(i => i.OperabilitySystemID).filter(id => id > -1).length > 0 ? Math.min.apply(null, this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.map(i => i.OperabilitySystemID).filter(id => id > -1)) - 1 : -1;
    this.selectedPickerString = this.cpService.selectedOperabilityPicker.toString();
    this.pickers = [
      { status: 'unpopulated', fixedOpening: '', operableType: '', operationType: '', windowSystem: '', handleColor: '', insideHandle: '', outsideHandle: '', hingeType: '' },
      { status: 'unpopulated', fixedOpening: '', operableType: '', operationType: '', windowSystem: '', handleColor: '', insideHandle: '', outsideHandle: '', hingeType: '' },
      { status: 'unpopulated', fixedOpening: '', operableType: '', operationType: '', windowSystem: '', handleColor: '', insideHandle: '', outsideHandle: '', hingeType: '' },
      { status: 'unpopulated', fixedOpening: '', operableType: '', operationType: '', windowSystem: '', handleColor: '', insideHandle: '', outsideHandle: '', hingeType: '' },
    ];            //  for dots
    this.glassIDsAlreadyApplied = {};   // list of glass added. Ex: {1:3} : glassId 1 applied in picker 3
    this.glassAppliedArray = [[], [], [], []];
    if (this.umService.isWindow()) {
      this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.forEach(glass => {
        let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(g => g.InfillID == glass.InfillID)[0].OperabilitySystemID;
        if (operableId > 0 && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameArticleName = null;
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameDistBetweenIsoBars = 0;
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameInsideW = 0;
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameOutsideW = 0;
          this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glass.InfillID)[0].InsertOuterFrameDepth = 0;
          this.handleColor = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsideHandleColor;
          if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].DoorSystemID > 0) {
            this.isDoorOperableTypeSelected = true;
            this.isWindowOperableTypeSelected = false;
            this.isWindowArticlesDisplay = false;
            this.isDoorArticlesDisplay = true;
          } else {
            this.isWindowOperableTypeSelected = true;
            this.isWindowArticlesDisplay = true;
            this.isDoorOperableTypeSelected = false;
            this.isDoorArticlesDisplay = false;
          }
          //this.cpService.setPopout(this.isDoorOperableTypeSelected, PanelsModule.DoorOperableType);
          //this.handlePositioning = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].HandlePosition === null ? null : this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].HandlePosition;
          //this.validateFormSRS.controls['handlePosition'].setValue(this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].HandlePosition === null ? '1/2' : this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].HandlePosition.toString());
        }

      });
    }
    // if (this.permissionService.checkPermission(this.feature.HandleColor)) {
    //   this.cpService.setCurrent(this.handleColor, PanelsModule.HandleColor);
    //   // this.GetHeight();
    // }

    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    for (const key in this.validateFormForFacade.controls) {
      this.validateFormForFacade.controls[key].markAsDirty();
      this.validateFormForFacade.controls[key].updateValueAndValidity();
    }

  }

  setOutwardSystems() {
    this.outwardSystemsToInsert = this.cpService.SystemData('AWS114').map((s, index) => ({Name: s.Description, Id: index, Value: s.Images}));
    this.aws114SystemValue = this.outwardSystemsToInsert.map(system => system.Value);
    if (this.outwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker]?.windowSystem)) {
      this.systemAWS114FacadeSelected = this.outwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id;
      if (this.umService.isAnyInsertedUnit()) {
        let sys = this.outwardSystemsToInsert.find(sys => sys.Id == this.systemAWS114FacadeSelected);
        if (sys != undefined) this.cpService.setSystem(this.cpService.SystemData("AWS114").find(aws => aws.Description == sys.Name), "OPERABILITY");
        this.getUvalueType();
      }
    }
  }

  setInwardSystems() {
    this.inwardSystemsToInsert = [];
    if (this.cpService.systemList.length > 0) {
      this.cpService.systemList.filter(s => s.SystemName.includes('AWS')).forEach((system, index) => {
        if ((this.configureService.isProjectInFrance() && system.FR) || (this.configureService.isProjectInGermany() && system.DE) || (!this.configureService.isProjectInFrance() && !this.configureService.isProjectInGermany() && system.US)) {
          this.inwardSystemsToInsert.push({
            Name: system.SystemName == 'AWS 75 SI+' ? 'AWS 75.SI+':system.SystemName == 'AWS 90 SI+' ? 'AWS 90.SI+':system.SystemName,
            Id: index,
            Value: system.Name,
          });
        }
      });
      if (this.inwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker]?.windowSystem)) {
        this.systemFacadeSelected = this.inwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id;
      }
      if (this.umService.isAnyInsertedUnit()) {
        let sys = this.inwardSystemsToInsert.find(sys => sys.Id == this.systemFacadeSelected);
        if (sys != undefined) this.cpService.setSystem(this.cpService.SystemData("AWS").find(aws => aws.Description == sys.Name), "OPERABILITY");
        this.getUvalueType();
      }
    }
  }

  isInwardSystemSelected(): boolean {
    return this.inwardSystemsToInsert.find(s => s.Id == this.systemFacadeSelected) !== undefined;
  }

  getSystemCrossSectionSRC(): string {
    return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/'+this.inwardSystemsToInsert.find(s => s.Id == this.systemFacadeSelected).Value+'.jpg';
  }

  // GetHeight(){
  //   let yDimensions = this.umService.current_UnifiedModel.ModelInput.Geometry.Points.map(x => x.Y);
  //   this.windowHeight = yDimensions && yDimensions.length > 0? yDimensions.reduce((a, b) => Math.max(a, b)): 0;
  //   this.windowHeight = this.windowHeight-200 >=0 ? this.windowHeight-200:this.windowHeight;
  //   if (this.handlePositioning >= 200 && this.handlePositioning <= this.windowHeight) {
  //     this.validateFormSRS.controls['handlePosition'].markAsDirty();
  //     this.validateFormSRS.controls['handlePosition'].updateValueAndValidity();
  //   } else{
  //     this.validateFormSRS.controls['handlePosition'].setErrors({ 'incorrect': true });
  //   }
  // }

  ngOnChanges(Changes: SimpleChanges): void {
    // if(this.orderPlaced) {
    //   this.validateFormSRS.controls['handlePosition'].disable();
    // } else if(this.validateFormSRS.controls['handlePosition'].disabled){
    //   this.validateFormSRS.controls['handlePosition'].enable();
    // }
    this.applicationType = this.configureService.applicationType;
    if (Changes && this.unified3DModel) {

      if (this.permissionService.checkPermission(this.feature.ADS_75) && Changes.HingeTypeFromChild && !Changes.HingeTypeFromChild.firstChange && Changes.HingeTypeFromChild.currentValue && Changes.HingeTypeFromChild.currentValue.Description) {
        if (this.hingeTypeArticleName !== Changes.HingeTypeFromChild.currentValue.ArticleName)
          this.configureService.computeClickedSubject.next(false);
      }


      if (Changes.unified3DModel) {
        if (Changes.unified3DModel.currentValue &&
          (Changes.unified3DModel.previousValue
            && (Changes.unified3DModel.currentValue.ProblemSetting.ProblemGuid != Changes.unified3DModel.previousValue.ProblemSetting.ProblemGuid))
          || (!Changes.unified3DModel.previousValue && Changes.unified3DModel.firstChange)) { // called when reload the page and change problem from right panel
          // this.unified3DModel = Changes.unified3DModel.currentValue;
          this.loadOperability();
          this.UpdateUnfiedModel();
        }
      }
      if (Changes.event3D && Changes.event3D.currentValue && Changes.event3D.currentValue.eventType === 'selectGlass') {     
        // when user selects glass from 3D viewer
        var panels = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(f => f.GlazingSystemID != -1).map(f => f.InfillID);
        this.selectedGlassIDs = Changes.event3D.currentValue.value.selectedGlassIDs.filter(f => panels.includes(f))
        // if (this.lc.isOperabilityActive && this.selectedGlassIDs.length !== Changes.event3D.currentValue.value.selectedGlassIDs.length) {
        //   this.ngNotificaionShow.next({ title: this.translate.instant(_('notification.acoustic-disabled')), message: this.translate.instant(_('notification.acoustic-solver-does-not-support-Top-Hung-openings')), logoToShow: 'Acoustic' });
        //   this.ngNotificaionShow.next({ title: this.translate.instant(_('notification.acoustic-disabled')), message: this.translate.instant(_('notification.acoustic-solver-does-not-support-Parallel-openings')), logoToShow: 'Acoustic' });
        // }  
      }

      if (Changes.event3D && Changes.event3D.currentValue && Changes.event3D.currentValue.eventType === 'SelectedMultipleGlass') {
        if (Changes.event3D.currentValue.value && this.isDoorOperableTypeSelected && Changes.event3D.currentValue.eventType === 'SelectedMultipleGlass') {
          if(this.selectedGlassIDs.length > 1) {
            setTimeout(() => {
              this.isApplyBtnDisable = true;
            },10);
          } else {
            this.isApplyBtnDisable = false;
            if (this.pickers.filter(f => f.operableType == 'tiltTurn-left' || f.operableType == 'tiltTurn-right').length > 0){
              this.isApplyBtnDisable = true;}
          }
        } else {
          this.isApplyBtnDisable = false;
        // Check whether the door operable is already applied to any infill
        // Get the infillIds to which Door OperableType is already applied
          this.disableApplyButton();
        }
        this.setDoorOperableTypeDisabled();
      }

      if(Changes.event3D && Changes.event3D.currentValue && Changes.event3D.currentValue.eventType === 'SelectedTransomGlass') {
        if (this.isOperablilityPanelActive) {
          if (Changes.event3D.currentValue.value) {
            this.isTransomGlassExists = true;
          }
          else {
            this.isTransomGlassExists = false;
          }
         // this.isTransomGlassExists = true;
          if((this.isDoorOperableTypeSelected || ( this.cpService.selectedOperabilityPicker > -1 && this.pickers[this.cpService.selectedOperabilityPicker].operableType.includes('Door'))) && Changes.event3D.currentValue.value){
            this.isApplyBtnDisable = true;
            this.ngNotificaionShow.next('Warning_DoorAboveTransom');
          }
          else {
            this.isApplyBtnDisable = false;
            this.disableApplyButton();
          }
        }
        
      }

      // if(Changes.event3D && Changes.event3D.currentValue && Changes.event3D.currentValue.eventType === 'SelectedTransomGlass') {
      //   this.isTransomGlassExists = true;
      //   if(this.isDoorOperableTypeSelected){
      //     this.isApplyBtnDisable = true;
      //   }else{
      //     this.isApplyBtnDisable = false;
      //   }        
      // }
      if (Changes.event3D && Changes.event3D.currentValue && Changes.event3D.currentValue.eventType === 'changeUnifiedModel') {  // when user adds mullion, tramsom, removes mullion/transom
        //disableresult

        this.configureService.computeClickedSubject.next(false);
        this.glassAppliedArray = [[], [], [], []];
        this.glassIDsAlreadyApplied = {};
        if (this.cpService.selectedOperabilityPicker > -1 && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.forEach(os => {

            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.forEach(glass => {
              let opType = '';
              switch (os.VentOperableType) {
                case 'Turn-Tilt-Right':
                  opType = 'Turn-Tilt-Right';
                  break
                case 'Turn-Tilt-Left':
                  opType = 'Turn-Tilt-Left';
                  break
                case 'Side-Hung-Right':
                  opType = 'Side-Hung-Right';
                  break
                case 'Side-Hung-Left':
                  opType = 'Side-Hung-Left';
                  break
                case 'Bottom-Hung':
                  opType = 'Bottom-Hung';
                  break
                case 'Top-Hung':
                  opType = 'Top-Hung';
                  break
                case 'Parallel-Opening':
                  opType = 'Parallel-Opening';
                  break
                case 'Single-Door-Left':
                  opType = 'Single-Door-Left';
                  break
                case 'Single-Door-Right':
                  opType = 'Single-Door-Right';
                  break
                case 'Double-Door-Active-Left':
                  opType = 'Double-Door-Active-Left';
                  break
                case 'Double-Door-Active-Right':
                  opType = 'Double-Door-Active-Right';
                  break
                default:
                  opType = '';
                  break
              }

              let fixopenText = '';
              switch (os.VentOpeningDirection) {
                case 'Inward':
                  fixopenText = 'Inward';
                  break
                case 'Outward':
                  fixopenText = 'Outward';
                  break
                default:
                  fixopenText = 'Inward';
                  break
              }
              if (os.OperabilitySystemID == glass.OperabilitySystemID && os.VentOpeningDirection !== null) {
                this.glassAppliedArray[os.OperabilitySystemID - 1].push({ glassID: glass.InfillID, fixedOpening: fixopenText, operableType: opType });
                this.glassIDsAlreadyApplied[glass.InfillID] = this.cpService.selectedOperabilityPicker + 1;
                this.selectedGlassIDs = [];
              }
            });
          });
        }
        this.setDoorOperableTypeDisabled();
      }
      // if(Changes.handlePosition && Changes.handlePosition.currentValue){
      //   this.handlePositioning = Changes.handlePosition.currentValue;
      //   this.updateHandlePosition(Changes.handlePosition.currentValue)
      // }
    }
    if (this.cpService.selectedOperabilityPicker > -1 && this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening == 'Outward' && this.insertInsulationTypeName === "") {
      this.insertInsulationTypeName = "PA";
      this.frameCombinationInputValue = 'Combination 1';
    }
    else if (this.cpService.selectedOperabilityPicker > -1 && this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening == 'Inward' && this.insertInsulationTypeName === "") {
      // this.insertInsulationTypeName = "Polyamide Anodized After";
      this.insertInsulationTypeName = "PA";
    }
  }

  private UpdateUnfiedModel() {
    this.glassAppliedArray = [[], [], [], []];
    this.glassIDsAlreadyApplied = {};
    if (this.umService.isFacade()) {
      if (!this.outwardSystemsToInsert) {
        this.setOutwardSystems();
      }
      if (!this.inwardSystemsToInsert) {
        this.setInwardSystems();
      }
    }
    // if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(f => f.VentOpeningDirection === null).length !== this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.length) {
    if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
      this.cpService.selectedOperabilityPicker = this.cpService.selectedOperabilityPicker == -1 ? Math.min.apply(null, this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.map(os => os.OperabilitySystemID)) - 1 : this.cpService.selectedOperabilityPicker;
      this.selectedPickerString = this.cpService.selectedOperabilityPicker.toString();
    }
    if (this.cpService.selectedOperabilityPicker !== -1) {
      if (this.umService.isWindow()) {
        if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems)
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.forEach(os => {
            let pickerIndex = os.OperabilitySystemID - 1;
            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.OperabilitySystemID > -1 && glass.OperabilitySystemID === os.OperabilitySystemID).forEach(glass => {
              if ((os.VentArticleName && os.VentArticleName !== '-1') || (os.DoorSystemID !== -1)) {
                glass.OperabilitySystemID = glass.OperabilitySystemID === undefined ? 0 : glass.OperabilitySystemID;
                if (!( glass.InfillID in this.glassIDsAlreadyApplied)) {
                  if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(section => section.VentOperableType !== null)[0]) {
                    this.cpService.selectedOperabilityPicker = this.cpService.selectedOperabilityPicker === -1 ? 0 : this.cpService.selectedOperabilityPicker;
                    this.selectedPickerString = this.cpService.selectedOperabilityPicker.toString();
                    this.pickers[pickerIndex].status = 'populated';
                   //this.pickers[this.cpService.selectedOperabilityPicker].status = 'populated';
                    this.glassIDsAlreadyApplied[glass.InfillID] = pickerIndex + 1;
                    if (this.isDoorOperableTypeSelected) {
                      this.systemFacadeSelected = this.adsSystemValue.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
                    } else {
                      if(this.outwardSystemsToInsert && this.outwardSystemsToInsert.length > 0)
                        this.systemFacadeSelected = this.outwardSystemsToInsert.find(system => system.Name == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem) ? this.outwardSystemsToInsert.find(system => system.Name == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id:-1;
                    }
                  }
                  if (os.VentOpeningDirection !== null && os.VentOperableType !== null) {
                    let opType = '';
                    switch (os.VentOperableType) {
                      case 'Turn-Tilt-Right':
                        opType = 'Turn-Tilt-Right';
                        this.isTurnTiltRightSelected = true;
                        break;
                      case 'Turn-Tilt-Left':
                        opType = 'Turn-Tilt-Left';
                        this.isTurnTiltLeftSelected = true;
                        break;
                      case 'Side-Hung-Right':
                        opType = 'Side-Hung-Right';
                        break;
                      case 'Side-Hung-Left':
                        opType = 'Side-Hung-Left';
                        break;
                      case 'Bottom-Hung':
                        opType = 'Bottom-Hung';
                        break;
                      case 'Top-Hung':
                        opType = 'Top-Hung';
                        break;
                      case 'Parallel-Opening':
                        opType = 'Parallel-Opening';
                        break;
                      case 'Single-Door-Left':
                        opType = 'Single-Door-Left';
                        this.isSingleDoorLeftSelected = true;
                        break;
                      case 'Single-Door-Right':
                        opType = 'Single-Door-Right';
                        this.isSingleDoorRightSelected = true;
                        break;
                      case 'Double-Door-Active-Left':
                        opType = 'Double-Door-Active-Left';
                        this.isDoubleDoorLeftSelected = true;
                        break;
                      case 'Double-Door-Active-Right':
                        opType = 'Double-Door-Active-Right';
                        this.isDoubleDoorRightSelected = true;
                        break;
                      default:
                        opType = '';
                        break;
                    }

                    let fixopenText = '';
                    switch (os.VentOpeningDirection) {
                      case 'Inward':
                        fixopenText = 'Inward';
                        break;
                      case 'Outward':
                        fixopenText = 'Outward';
                        break;
                      default:
                        fixopenText = 'Inward';
                        break;
                    }
                    this.glassAppliedArray[pickerIndex].push({ glassID: glass.InfillID, fixedOpening: fixopenText, operableType: opType });
                    //this.glassAppliedArray[glass.OperabilitySystemID - 1].push({ glassID: glass.InfillID, fixedOpening: fixopenText, operableType: opType });
                  // this.glassAppliedArray[this.cpService.selectedOperabilityPicker].push({ glassID: glass.InfillID, fixedOpening: fixopenText, operableType: opType });
                  }
                  if (glass.InsertWindowSystemType !== null && glass.InsertWindowSystemType !== undefined && glass.InsertWindowSystemType.indexOf('TipTronic') > -1) {
                    this.pickers[pickerIndex].operationType = Object.keys(this.operationType_index).filter(key => this.operationType_index[key] == 'TipTronic')[0];
                  } else {
                    this.pickers[pickerIndex].operationType = Object.keys(this.operationType_index).filter(key => this.operationType_index[key] == 'Manual')[0];
                  }
                   this.pickers[pickerIndex].windowSystem = glass.InsertWindowSystem;
                   this.pickers[pickerIndex].fixedOpening = Object.keys(this.fixedOpening_index).filter(key => this.fixedOpening_index[key] == os.VentOpeningDirection)[0];
                   this.pickers[pickerIndex].operableType = Object.keys(this.operableType_index).filter(key => this.operableType_index[key] == os.VentOperableType)[0];
                }
              }
            });
          });
      }
      else {  // ProductType == 'Facade'
        if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems)
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.forEach(os => {
            let pickerIndex = os.OperabilitySystemID - 1;
            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.forEach(glass => {
              glass.OperabilitySystemID = glass.OperabilitySystemID === undefined ? 0 : glass.OperabilitySystemID;
              if (glass.OperabilitySystemID != -1 && glass.OperabilitySystemID == os.OperabilitySystemID && !(this.glassIDsAlreadyApplied.hasOwnProperty(glass.InfillID))) {
                if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(section => section.VentOperableType !== null)[0]) {
                  this.cpService.selectedOperabilityPicker = this.cpService.selectedOperabilityPicker === -1 ? 0 : this.cpService.selectedOperabilityPicker;
                  this.selectedPickerString = this.cpService.selectedOperabilityPicker.toString();
                  this.pickers[pickerIndex].status = 'populated';
                  this.glassIDsAlreadyApplied[glass.InfillID] = pickerIndex + 1;
                }
                if (os.VentOpeningDirection !== null && os.VentOperableType !== null) {
                  this.pickers[pickerIndex].windowSystem = glass.InsertWindowSystem != null ? glass.InsertWindowSystem: os.VentOpeningDirection == 'Inward' ? this.inwardSystemsToInsert[0].Value:this.outwardSystemsToInsert[0].Value;
                  glass.InsertWindowSystem = this.pickers[pickerIndex].windowSystem;
                  this.pickers[pickerIndex].fixedOpening = Object.keys(this.fixedOpening_index).filter(key => this.fixedOpening_index[key] == os.VentOpeningDirection)[0];
                  this.pickers[pickerIndex].operableType = Object.keys(this.operableType_index).filter(key => this.operableType_index[key] == os.VentOperableType)[0];

                  let opType = '';
                  switch (os.VentOperableType) {
                    case 'Turn-Tilt-Right':
                      opType = 'Turn-Tilt-Right';
                      break;
                    case 'Turn-Tilt-Left':
                      opType = 'Turn-Tilt-Left';
                      break;
                    case 'Side-Hung-Right':
                      opType = 'Side-Hung-Right';
                      break;
                    case 'Side-Hung-Left':
                      opType = 'Side-Hung-Left';
                      break;
                    case 'Bottom-Hung':
                      opType = 'Bottom-Hung';
                      break;
                    case 'Top-Hung':
                      opType = 'Top-Hung';
                      break;
                    case 'Parallel-Opening':
                      opType = 'Parallel-Opening';
                      break;
                    case 'Single-Door-Left':
                      opType = 'Single-Door-Left';
                      break;
                    case 'Single-Door-Right':
                      opType = 'Single-Door-Right';
                      break;
                    case 'Double-Door-Active-Left':
                      opType = 'Double-Door-Active-Left';
                      break;
                    case 'Double-Door-Active-Right':
                      opType = 'Double-Door-Active-Right';
                      break;
                    default:
                      opType = '';
                      break;
                  }

                  let fixopenText = '';
                  switch (os.VentOpeningDirection) {
                    case 'Inward':
                      fixopenText = 'Inward';
                      break;
                    case 'Outward':
                      fixopenText = 'Outward';
                      break;
                    default:
                      fixopenText = 'Inward';
                      break;
                  }
                  this.glassAppliedArray[pickerIndex].push({ glassID: glass.InfillID, fixedOpening: fixopenText, operableType: opType });
                  //this.glassAppliedArray[pickerIndex].push({ glassID: glass.InfillID, fixedOpening: fixopenText, operableType: opType });                  
                }
                if (glass.InsertWindowSystemType !== null && glass.InsertWindowSystemType !== undefined && glass.InsertWindowSystemType.indexOf('TipTronic') > -1) {
                  this.pickers[pickerIndex].operationType = Object.keys(this.operationType_index).filter(key => this.operationType_index[key] == 'TipTronic')[0];
                } else {
                  this.pickers[pickerIndex].operationType = Object.keys(this.operationType_index).filter(key => this.operationType_index[key] == 'Manual')[0];
                }

                if (os.DoorSystemID > 0) {
                  if (this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems && this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.length > 0) {
                    let dr = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems[glass.OperabilitySystemID - 1];
                    this.pickers[pickerIndex].insideHandle = dr.InsideHandleColor.length > 4 ? dr.InsideHandleArticleDescription + ' - ' + dr.InsideHandleColor.substring(0, dr.InsideHandleColor.length - 10) : dr.InsideHandleArticleDescription + ' - ' + dr.InsideHandleColor;
                    this.insideHandleArticleName = dr ? dr.InsideHandleArticleName : null;
                    setTimeout(() => {
                      this.cpService.setCurrent(this.insideHandleArticleName, PanelsModule.InsideHandle);
                    });
                  }
                } 
                // else {
                //   let op = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[glass.OperabilitySystemID - 1];
                //   this.pickers[os.PickerIndex].insideHandle = op.InsideHandleColor.length > 4 ? op.InsideHandleArticleDescription + ' - ' + op.InsideHandleColor.substring(0, op.InsideHandleColor.length - 10) : op.InsideHandleArticleDescription + ' - ' + op.InsideHandleColor;
                //   this.insideHandleArticleName = op ? op.InsideHandleArticleName : null;
                //   setTimeout(() => {
                //     this.cpService.setCurrent(this.insideHandleArticleName, PanelsModule.InsideHandle);
                //   });
                // }
              }
            });
          });
        if (this.cpService.selectedOperabilityPicker > -1) {
          this.glassAppliedArray[this.cpService.selectedOperabilityPicker].forEach(glassObject => {
            let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].OperabilitySystemID;
            if (this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
              let operabalitySystem = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0];
              this.insulatingSelection = operabalitySystem.InsertInsulationType;
              this.insertInsulationTypeName = operabalitySystem.InsertInsulationTypeName;
              this.gasketSelection = operabalitySystem.InsertUvalueType;
            }
          });
          if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === "parallelOpening" || this.pickers[this.cpService.selectedOperabilityPicker].operableType === "topHung") {
            this.systemAWS114FacadeSelected = this.outwardSystemsToInsert.find(system => system.Name == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id;
            setTimeout(() => {
              // if (this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
              //   this.sendCurrentArticleToFrameCombinationTable.emit({ ArticleID: this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0].VentArticleName, SystemID: this.aws114SystemValue[this.aws114SystemDesc.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)], OperationType: this.pickers[this.cpService.selectedOperabilityPicker].operationType });
              // }
              if (this.cpService.selectedOperabilityPicker > -1) {
                this.systemFacadeSelectedEvent.emit(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
               // console.log('set system call in operability'); this.cpService.setSystem(this.cpService.SystemData("AWS114")[this.systemAWS114FacadeSelected], "OPERABILITY");
              }
            }, 0);
          } else {
          }
        }
      }
      if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem) {
        if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem.includes('90'))
          this.systemType = '90';
        else if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem.includes('75'))
          this.systemType = '75';
        else if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem.includes('114'))
          this.systemType = '114';
      }
    }
    this.defaultOperableTypeSelection();
  }

  private disableApplyButton() {
    if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
      if (this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems && this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.length > 0) {
        let op = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(f => f.DoorSystemID === 1)[0];
        if(op){
          var vOT = []; 
          if(this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.OperabilitySystemID === op.OperabilitySystemID)[0]){
            vOT.push(this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.OperabilitySystemID === op.OperabilitySystemID)[0].InfillID);
            if (vOT.every(elem => this.selectedGlassIDs.indexOf(elem) > -1)) {
              this.isApplyBtnDisable = false;
            } else {
              this.isApplyBtnDisable = true;
            }
          } else {
            this.isApplyBtnDisable = false;
          }
        }
      } else {
        if(this.pickers.filter( (picker,index) => picker.operableType.includes('Door') && this.glassAppliedArray[index].length > 0).length > 0 && this.pickers.filter( (picker,index) => picker.operableType.includes('tiltTurn') && this.glassAppliedArray[index].length > 0).length > 0) {
          this.isApplyBtnDisable = true;
        }
        else{
          this.isApplyBtnDisable = false;}
      }
    } else {
      this.isApplyBtnDisable = false;
    }
  }

  onAddPicker(): void {
    if (!this.pickers.every(picker => picker.status == 'populated')) {
      let firstUnpopulatedIndex: number = this.pickers.findIndex(this.checkUnpopulatedStatus);
      this.pickers[firstUnpopulatedIndex].status = 'populated';
      this.cpService.selectedOperabilityPicker = firstUnpopulatedIndex;
      this.selectedPickerString = this.cpService.selectedOperabilityPicker.toString();
      if((this.pickers[0].operableType === 'tiltTurn-left' || this.pickers[0].operableType === 'tiltTurn-right') && (this.pickers[1].status === 'populated' && this.pickers[1].operableType === '')) {
        this.isDoorOperableDisable = true;
      } else {
        this.isDoorOperableDisable = false;
      }
      // this.insertInsulationTypeName = "Polyamide Anodized After";
      this.insertInsulationTypeName = "PA";
      if (this.permissionService.checkPermission(this.feature.HandleColor)) {
        //  this.handlePositioning = 200;
        this.handleColor = 'Silver grey - RAL 7001';
        this.umService.set_HandleColor(this.handleColor);
        // setTimeout(() => {
        //   this.cpService.setCurrent(this.handleColor, PanelsModule.HandleColor);
        // });
      }
    }
    //this.cpService.setPopout(this.isDoorOperableTypeSelected, PanelsModule.DoorOperableType);
  }


  isTiltTurnLeft = true;
  isSideHungLeft = true;
  isBottomHung = true;
  isSingleDoorLeft = true;
  isDoubleDoorLeft = true;
  onChangeTiltTurn(): void {
    //if (this.umService.current_UnifiedModel.ProblemSetting.ProductType === 'Facade')
    this.isTurnTiltDirectionChanged = true;
    this.isOpDirectionChangeForCameraReset = true;
    this.isTiltTurnLeft = !this.isTiltTurnLeft;
    if (this.isTiltTurnLeft == false) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'tiltTurn-right';
    else if (this.isTiltTurnLeft == true) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'tiltTurn-left';
    this.onOperableTypeChange(this.pickers[this.cpService.selectedOperabilityPicker].operableType);
  }
  onChangeSideHung(): void {
    //if (this.umService.current_UnifiedModel.ProblemSetting.ProductType === 'Facade')
    if (this.permissionService.checkPermission(this.feature.SideHung)) {
      this.isSideHungLeft = !this.isSideHungLeft;
      if (this.isSideHungLeft == false) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'sideHung-right';
      else if (this.isSideHungLeft == true) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'sideHung-left';
      this.onOperableTypeChange(this.pickers[this.cpService.selectedOperabilityPicker].operableType);
    }

  }

  onChangeSingleDoor(): void {
    this.isSingleDoorLeft = !this.isSingleDoorLeft;
    if (this.isSingleDoorLeft == false) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'singleDoor-right';
    else if (this.isSingleDoorLeft == true) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'singleDoor-left';
    this.onOperableTypeChange(this.pickers[this.cpService.selectedOperabilityPicker].operableType);
  }

  onChangeDoubleDoor(): void {
    this.isDoubleDoorLeft = !this.isDoubleDoorLeft;
    if (this.isDoubleDoorLeft == false) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'doubleDoor-right';
    else if (this.isDoubleDoorLeft == true) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'doubleDoor-left';
    this.onOperableTypeChange(this.pickers[this.cpService.selectedOperabilityPicker].operableType);
  }

  onChangeBottomHung(): void {
    //if (this.umService.current_UnifiedModel.ProblemSetting.ProductType === 'Facade')
    //this.isBottomHung = !this.isBottomHung; // No top Hung for either Inward-Window or Inward-Facade
    if (this.isBottomHung == true) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'bottomHung';
    else if (this.isBottomHung == false) this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'topHung';
    this.onOperableTypeChange(this.pickers[this.cpService.selectedOperabilityPicker].operableType);
  }


  defaultOperableTypeSelection(): void {
    if (this.pickers[this.cpService.selectedOperabilityPicker]) {
      if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'tiltTurn-right') this.isTiltTurnLeft = false;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'tiltTurn-left') this.isTiltTurnLeft = true;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'sideHung-right') this.isSideHungLeft = false;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'sideHung-left') this.isSideHungLeft = true;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'bottomHung') this.isBottomHung = true;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung') this.isBottomHung = false;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'singleDoor-right') this.isSingleDoorLeft = false;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'singleDoor-left') this.isSingleDoorLeft = true;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'doubleDoor-right') this.isDoubleDoorLeft = false;
      else if (this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'doubleDoor-left') this.isDoubleDoorLeft = true;
    }
  }

  // updateHandlePosition(value){
  //   if(value && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0].HandlePosition != value){
  //     this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0].HandlePosition = value;
  //     this.configureService.computeClickedSubject.next(false);
  //     //this.handlePositionEvent.emit({value: this.handlePositioning, index: this.cpService.selectedOperabilityPicker});
  //     this.loadJSONService({ Unified3DModel: this.unified3DModel, canBeDrawn: this.canBeDrawnBool });
  //   }
  // }

  // onHandlePositionChange(event) {
  //   if (this.cpService.selectedOperabilityPicker > -1 ) {
  //     if (this.handlePositioning < 200 || this.handlePositioning > this.windowHeight) {
  //       this.validateFormSRS.controls['handlePosition'].setErrors({ 'incorrect': true });
  //     }
  //     if(this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems !== null && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems !== undefined){
  //       this.updateHandlePosition(event);
  //     }
  //   }

  // }

  checkUnpopulatedStatus(picker) {
    return picker.status === 'unpopulated';
  }

  reloadDate = false;
  onSelectPicker(): void {
    this.cpService.closeAllPopouts();
    this.reloadDate = false;
    this.cpService.selectedOperabilityPicker = parseInt(this.selectedPickerString);
    if (this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.find(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))) {
      if (this.umService.isFacade() && this.umService.isAnyInsertedUnit()) {
        if (this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening === "Inward") {
          this.getOuterFrameInputValue();
          this.getVentFrameInputValue();
          this.getUvalueType();         // Insulation level or gasket selection
        }
        this.insulatingSelection = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1)).InsertInsulationType;
        this.insertInsulationTypeName = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1)).InsertInsulationTypeName;
      }
    }
    if (this.cpService.selectedOperabilityPicker > -1) {
      this.systemFacadeSelectedEvent.emit(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);      
    }
    this.defaultOperableTypeSelection();
    if (this.cpService.selectedOperabilityPicker > -1) {
      if (this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening === "Inward") {
        if(this.umService.isWindow()) {
          // this.systemFacadeSelected = this.awsSystemValue.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
          // this.cpService.setSystem(this.cpService.SystemData("AWS")[this.systemFacadeSelected], "OPERABILITY");
        }
        if (this.umService.isFacadeMullionTransom() || this.umService.isFacadeUDC()) {
          if (this.inwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)) {
            let sys = this.inwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
            this.systemFacadeSelected = sys.Id;
            this.cpService.setSystem(this.cpService.SystemData("AWS").find(aws => aws.Description == sys.Name), "OPERABILITY");
          }
          else {
            this.systemFacadeSelected = -1;
          }
        }
      }
      else if (this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening === "Outward") {
        if (this.umService.isWindow()) {
          // this.systemAWS114FacadeSelected = this.aws114SystemDesc.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
          // this.cpService.setSystem(this.cpService.SystemData("AWS114")[this.systemAWS114FacadeSelected], "OPERABILITY");
        }
        if (this.umService.isFacadeMullionTransom() || this.umService.isFacadeUDC()) {
          this.systemAWS114FacadeSelected = this.outwardSystemsToInsert.find(system => system.Name == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id;
          this.cpService.setSystem(this.cpService.SystemData("AWS114")[this.systemAWS114FacadeSelected], "OPERABILITY");
        }
        
      }
      // this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(section => section.OperabilitySystemID == this.cpService.selectedOperabilityPicker).forEach(element => {
      //   element.VentArticleName = 'AWS114VA';
      //   element.InsertOuterFrameArticleName = 'AWS114OF';
      // });
      if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem) {
        if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem.includes('90')) this.systemType = '90';
        else if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem.includes('75')) this.systemType = '75';
        else if (this.pickers[this.cpService.selectedOperabilityPicker].windowSystem.includes('114')) this.systemType = '114';
      }
      if (this.permissionService.checkPermission(this.feature.HandleColor)) {
        // this.handlePositioning = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0].HandlePosition;
        this.handleColor = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] ?
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0].InsideHandleColor : this.pickers[this.cpService.selectedOperabilityPicker].handleColor;
        this.umService.set_HandleColor(this.handleColor);
        // this.cpService.setCurrent('', PanelsModule.HandleColor);
        // setTimeout(() => {
        //   this.cpService.setCurrent(this.handleColor, PanelsModule.HandleColor);
        // });
      }
      if (this.permissionService.checkPermission(this.feature.ADS_75)) {
        if (this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems && this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.length > 0) {
          let ds = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems[this.cpService.selectedOperabilityPicker];
          if (ds != undefined) {
            let HandleList = this.cpService.buildInsideHandleList().filter(handle => handle.ArticleName === ds.InsideHandleArticleName);
            let handleDescription = HandleList[0].Description;
            this.insideHandle = '' + ds.InsideHandleArticleName + ' - ' + ds.InsideHandleColor.split("-")[0].trim();
            this.pickers[this.cpService.selectedOperabilityPicker].insideHandle = ds.InsideHandleColor.length > 4 ? (handleDescription + ' - ' + ds.InsideHandleColor.substring(0, ds.InsideHandleColor.length - 10)) : handleDescription + ' - ' + ds.InsideHandleColor;
          }
        } else {
          if(this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.length >= this.cpService.selectedOperabilityPicker + 1) {
            let os = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[this.cpService.selectedOperabilityPicker];
            if (os != undefined) {
              let WinHandleList = this.cpService.buildWindowHandleList().filter(handle => handle.ArticleName === os.InsideHandleArticleName);
              if (WinHandleList[0] != undefined) {
                let winHandleDescription = WinHandleList[0].Description;
                this.insideHandle = '' + os.InsideHandleArticleName + ' - ' + os.InsideHandleColor.split("-")[0].trim();
                this.pickers[this.cpService.selectedOperabilityPicker].insideHandle = os.InsideHandleColor.length > 4 ? (winHandleDescription + ' - ' + os.InsideHandleColor.substring(0, os.InsideHandleColor.length - 10)) : winHandleDescription + ' - ' + os.InsideHandleColor;          
              }
            }
          }
        }

      }

      if (this.insertInsulationTypeName === "") {
        if (this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening == 'Outward') {
          this.insertInsulationTypeName = "PA";
          this.frameCombinationInputValue = 'Combination 1';
        }
        else if (this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening == 'Inward') {
          // this.insertInsulationTypeName = "Polyamide Anodized After";
          this.insertInsulationTypeName = "PA";
        }
      }

    }
 
    if (this.cpService.selectedOperabilityPicker > 0 || this.pickers.filter(f => f.operableType.includes('Door')).length > 0){
        this.isApplyBtnDisable = true;
      }
    else {
      this.isApplyBtnDisable = false;
    }
  
    this.setDoorOperableTypeDisabled();
  }

  onApply(event = null): void {
    if (this.pickers[this.cpService.selectedOperabilityPicker].operableType.includes('Door')) {
      this.isDoorOperableTypeSelected = true;
      this.isWindowOperableTypeSelected = false;
      this.isWindowArticlesDisplay = false;
      this.isDoorArticlesDisplay = true;
      this.isDoorOperableDisable = false;
      this.isWindowOperableDisable = true;
    } else {
      this.isDoorOperableTypeSelected = false;
      this.isWindowOperableTypeSelected = true;
      this.isWindowArticlesDisplay = true;
      this.isDoorArticlesDisplay = false;
      this.isDoorOperableDisable = true;
      this.isWindowOperableDisable = false;
    }
    if (!this.configureService.getIsFramingCompatibleWithInsetUnitByLocation()) {
      this.modalService.error({
        nzWrapClassName: "vertical-center-modal",
        nzWidth: '410px',
        nzTitle: '',
        nzContent: this.translate.instant(_('notification.incompatible-system-with-current-insert-unit-message')),
        nzOkText: "Ok",
        nzOnOk: () => { }
      });
    }
    else {
      if (!(this.cpService.selectedOperabilityPicker === -1 || !this.pickers[this.cpService.selectedOperabilityPicker].operableType || !this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening)) {

        if(this.umService.isFacade() && this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Inward') {
          // 09-27-2023 Acoustic is not supported by two french systems
          if (!this.systemFacadeSelected) this.systemFacadeSelected = this.inwardSystemsToInsert[0].Id;
          if (!this.umService.isAnyInsertedUnitNotSupportingAcoustic()) this.checkAcousticCompatibility(this.inwardSystemsToInsert.find(s => s.Id == this.systemFacadeSelected).Name);
        }

        this.selectedGlassIDs.forEach((id: number) => {
  
          // check if glassId is already applied
          if (id in this.glassIDsAlreadyApplied) {
            this.glassAppliedArray[this.glassIDsAlreadyApplied[id] - 1] = this.glassAppliedArray[this.glassIDsAlreadyApplied[id] - 1].filter(glass => glass.glassID !== id);  // remove information
          }
          // apply new glass information
          this.glassIDsAlreadyApplied[id] = this.cpService.selectedOperabilityPicker + 1;        
          let opType = '';
          switch (this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType]) {
            case 'Turn-Tilt-Right': opType = 'Turn-Tilt-Right'; break
            case 'Turn-Tilt-Left': opType = 'Turn-Tilt-Left'; break
            case 'Side-Hung-Right': opType = 'Side-Hung-Right'; break
            case 'Side-Hung-Left': opType = 'Side-Hung-Left'; break
            case 'Bottom-Hung': opType = 'Bottom-Hung'; break
            case 'Top-Hung': opType = 'Top-Hung'; break
            case 'Parallel-Opening': opType = 'Parallel-Opening'; break
            case 'Single-Door-Left': opType = 'Single-Door-Left'; break
            case 'Single-Door-Right': opType = 'Single-Door-Right'; break
            case 'Double-Door-Active-Left': opType = 'Double-Door-Active-Left'; break
            case 'Double-Door-Active-Right': opType = 'Double-Door-Active-Right'; break
            default: opType = ''; break
          }
          let fixopenText = '';
          switch (this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening]) {
            case 'Inward': fixopenText = 'Inward'; break
            case 'Outward': fixopenText = 'Outward'; break
            default: fixopenText = 'Inward'; break
          }
          this.glassAppliedArray[this.cpService.selectedOperabilityPicker].push({ glassID: id, fixedOpening: fixopenText, operableType: opType });
  
          let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID === undefined ? -1 : this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID;
          let operabilityExists = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems !== null ? (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find( f => f.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1) === undefined ? false : true) : false;
  
          let doorsystemId = operableId > 0 ? this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].DoorSystemID : -1;
          let hasdoorSystems = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems ? true : false;
          let hasOperability = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems ? true : false;
  
          var os = this.umService.obj_OperabilitySystem(this.cpService.selectedOperabilityPicker);
          let operabilitysystem: OperabilitySystem = {
            VentOpeningDirection: this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening],
            VentOperableType: this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType],
            InsertInsulationType: this.insulatingSelection,
            InsertUvalueType: this.gasketSelection,
            InsertInsulationTypeName: this.insertInsulationTypeName,
            OperabilitySystemID: this.cpService.selectedOperabilityPicker + 1,
            VentArticleName: os?.VentArticleName ? os.VentArticleName:null,
            VentInsideW: os?.VentInsideW ? os.VentInsideW : -1,
            VentOutsideW: os?.VentOutsideW ? os.VentOutsideW : -1,
            VentDistBetweenIsoBars: os?.VentDistBetweenIsoBars ? os.VentDistBetweenIsoBars:-1,
            InsertOuterFrameArticleName: os?.InsertOuterFrameArticleName ? os.InsertOuterFrameArticleName:null,
            InsertOuterFrameInsideW: os?.InsertOuterFrameInsideW ? os.InsertOuterFrameInsideW:-1,
            InsertOuterFrameOutsideW: os?.InsertOuterFrameOutsideW ? os.InsertOuterFrameOutsideW:-1,
            InsertOuterFrameDistBetweenIsoBars: os?.InsertOuterFrameDistBetweenIsoBars ? os.InsertOuterFrameDistBetweenIsoBars:-1,
            InsideHandleArticleName: os?.InsideHandleArticleName ? os.InsideHandleArticleName:null,
            InsideHandleColor: os?.InsideHandleColor ? os.InsideHandleColor:null,
            InsideHandleArticleDescription: os?.InsideHandleArticleDescription ? os.InsideHandleArticleDescription:'',
            DoorSystemID: -1,
            SlidingDoorSystemID: -1,
            JunctionType: -1,
            InsertedWindowType: '',
            RebateGasketArticleName: '',
            CenterGasketInsulationArticleName: '',
            InsertOuterFrameDepth: -1,
            InsertWindowSystem: ''
          };
          let doorsystem: DoorSystem;
          this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID = operabilitysystem.OperabilitySystemID;
  
          if (!this.isDoorOperableTypeSelected) {
            operabilitysystem.DoorSystemID = -1;
            //operabilitysystem.HandlePosition = this.handlePositioning;
            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills[id - 1].HandlePosition = -1; // negative number sets the handle position in center of vent
          }
          else {
            if (this.isDoorOperableTypeSelected) {
              operabilitysystem.VentArticleName = '-1';
              operabilitysystem.VentInsideW = -1;
              operabilitysystem.VentOutsideW = -1;
            }
            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills[id - 1].HandlePosition = 1050; // sets the handle position if it is doorsystem
            var ds = this.umService.obj_Door(1);
            if (ds) {
              doorsystem.DoorLeafArticleName = ds.DoorLeafArticleName;
              doorsystem.DoorLeafInsideW = ds.DoorLeafInsideW;
              doorsystem.DoorLeafOutsideW = ds.DoorLeafOutsideW;
              doorsystem.DoorPassiveJambArticleName = ds.DoorPassiveJambArticleName;
              doorsystem.DoorPassiveJambInsideW = ds.DoorPassiveJambInsideW;
              doorsystem.DoorPassiveJambOutsideW = ds.DoorPassiveJambOutsideW;
              doorsystem.DoorSillArticleName = ds.DoorSillArticleName;
              doorsystem.DoorSillInsideW = ds.DoorSillInsideW;
              doorsystem.DoorSillOutsideW = ds.DoorSillOutsideW;
              doorsystem.DoorSidelightSillArticleName = ds.DoorSidelightSillArticleName;
              doorsystem.HingeCondition = 0;
              doorsystem.OutsideHandleArticleName = ds.OutsideHandleArticleName;
              doorsystem.OutsideHandleColor = ds.OutsideHandleColor;
              doorsystem.OutsideHandleArticleDescription = ds.OutsideHandleArticleDescription;
              doorsystem.InsideHandleArticleName = ds.InsideHandleArticleName;
              doorsystem.InsideHandleColor = ds.InsideHandleColor;
              doorsystem.InsideHandleArticleDescription = ds.InsideHandleArticleDescription;
              doorsystem.HingeArticleName = ds.HingeArticleName;
              doorsystem.HingeColor = ds.HingeColor;
            }
            // doorsystem.HingeArticleDescription = this.HingeTypeFromChild === undefined ? null : this.HingeTypeFromChild.Description;
            //  doorsystem.DoorSystemType = opType.toLowerCase().includes('single') ? 'Single' : 'Double';
            switch (opType) {
              case "Double Door - Right Active": doorsystem.DoorSystemType = this.doubleDoorRightSystemTypeText; break;
              case "Double Door - Left Active": doorsystem.DoorSystemType = this.doubleDoorLeftSystemTypeText; break;
              case "Single Door - Left": doorsystem.DoorSystemType = this.singleDoorLeftSystemTypeText; break;
              case "Single Door - Right": doorsystem.DoorSystemType = this.singleDoorRightSystemTypeText; break;
              default: doorsystem.DoorSystemType = ''; break;
            }
  
            doorsystem.DoorSystemID = this.cpService.selectedOperabilityPicker + 1;
            // doorsystem.InsideHandleArticleName = "1";
            // doorsystem.OutsideHandleArticleName = "1";
            operabilitysystem.DoorSystemID = doorsystem.DoorSystemID;
            if (!hasdoorSystems) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems = [];
              this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.push(doorsystem);
            }
          }
  
           operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID === undefined ? -1 : this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID;
          if (!hasOperability) {
            this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
            this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
          } else {
            if (operabilityExists && operableId > 0) {
              let umOperabilitySystems = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0];
              umOperabilitySystems.VentOpeningDirection = this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening];
              umOperabilitySystems.VentOperableType = this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType];
              umOperabilitySystems.InsertInsulationType = this.insulatingSelection;
              umOperabilitySystems.InsertUvalueType = this.gasketSelection;
              umOperabilitySystems.InsertInsulationTypeName = this.insertInsulationTypeName;
              //umOperabilitySystems.InsideHandleColor = this.handleColor;
              var os = this.umService.obj_OperabilitySystem(0);
              if (os) {
                umOperabilitySystems.VentArticleName = os.VentArticleName;
                umOperabilitySystems.VentInsideW = os.VentInsideW? os.VentInsideW : -1;
                umOperabilitySystems.VentOutsideW = os.VentOutsideW? os.VentOutsideW:-1;
                umOperabilitySystems.VentDistBetweenIsoBars = os.VentDistBetweenIsoBars;
                umOperabilitySystems.InsertOuterFrameArticleName = os.InsertOuterFrameArticleName;
                umOperabilitySystems.InsertOuterFrameInsideW = os.InsertOuterFrameInsideW;
                umOperabilitySystems.InsertOuterFrameOutsideW = os.InsertOuterFrameOutsideW;
                umOperabilitySystems.InsertOuterFrameDistBetweenIsoBars = os.InsertOuterFrameDistBetweenIsoBars;
                umOperabilitySystems.InsideHandleArticleName = os.InsideHandleArticleName;
                umOperabilitySystems.InsideHandleColor = os.InsideHandleColor;
                umOperabilitySystems.InsideHandleArticleDescription = os.InsideHandleArticleDescription;
              }
              if(this.isDoorOperableTypeSelected) {
                umOperabilitySystems.DoorSystemID = 1;
                doorsystemId = 1;
              } else {
                umOperabilitySystems.DoorSystemID = -1;
                  doorsystemId = -1;
                  if(this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(o => o.DoorSystemID === 1).length > 0) {
                    this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.forEach(item => {
                      if(item.DoorSystemID === 1) {
                        item.DoorSystemID = -1;
                      }
                    });
                  }
                  this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems = null;
                  if(this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(o => o.OperabilitySystemID === operableId)[0].VentArticleName === '-1') 
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(o => o.OperabilitySystemID === operableId)[0].VentArticleName = undefined;
              }
              let hasdoorSystems = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems ? true : false;
              if (hasdoorSystems && doorsystemId > 0) {
                let umDoorSystems = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0];
                var ds = this.umService.obj_Door(1);
                if (ds) {
                  umDoorSystems.DoorLeafArticleName = ds.DoorLeafArticleName;
                  umDoorSystems.DoorLeafInsideW = ds.DoorLeafInsideW;
                  umDoorSystems.DoorLeafOutsideW = ds.DoorLeafOutsideW;
                  umDoorSystems.DoorPassiveJambArticleName = ds.DoorPassiveJambArticleName;
                  umDoorSystems.DoorPassiveJambInsideW = ds.DoorPassiveJambInsideW;
                  umDoorSystems.DoorPassiveJambOutsideW = ds.DoorPassiveJambOutsideW;
                  umDoorSystems.DoorSillArticleName = ds.DoorSillArticleName;
                  umDoorSystems.DoorSillInsideW = ds.DoorSillInsideW;
                  umDoorSystems.DoorSillOutsideW = ds.DoorSillOutsideW;
                  umDoorSystems.DoorSidelightSillArticleName = ds.DoorSidelightSillArticleName;
                  umDoorSystems.OutsideHandleArticleName = ds.OutsideHandleArticleName;
                  umDoorSystems.OutsideHandleColor = ds.OutsideHandleColor;
                  umDoorSystems.OutsideHandleArticleDescription = ds.OutsideHandleArticleDescription;
                  umDoorSystems.InsideHandleArticleName = ds.InsideHandleArticleName;
                  umDoorSystems.InsideHandleColor = ds.InsideHandleColor;
                  umDoorSystems.InsideHandleArticleDescription = ds.InsideHandleArticleDescription
                  umDoorSystems.HingeArticleName = ds.HingeArticleName;
                  umDoorSystems.HingeColor = ds.HingeColor;
                  umDoorSystems.HingeArticleDescription = ds.HingeArticleDescription;
                }
                umDoorSystems.HingeCondition = 0;
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].VentArticleName = '-1';
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].VentInsideW = -1;
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].VentOutsideW = -1;
                // umDoorSystems.DoorSystemType = opType.toLowerCase().includes('single') ? 'Single' : 'Double';
                switch (opType) {
                  case "Double Door - Right Active": umDoorSystems.DoorSystemType = this.doubleDoorRightSystemTypeText; break;
                  case "Double Door - Left Active": umDoorSystems.DoorSystemType = this.doubleDoorLeftSystemTypeText; break;
                  case "Single Door - Left": umDoorSystems.DoorSystemType = this.singleDoorLeftSystemTypeText; break;
                  case "Single Door - Right": umDoorSystems.DoorSystemType = this.singleDoorRightSystemTypeText; break;
                  default: umDoorSystems.DoorSystemType = ''; break;
                }
  
              }
            } else {
              if (operabilityExists && (operableId == -1 || operableId == 0)) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.splice(this.cpService.selectedOperabilityPicker, 1);
                // this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] = operabilitysystem;
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.splice(this.cpService.selectedOperabilityPicker, 0, operabilitysystem);
              } else {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
              }
            }
          }
          let umInfills = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0];
          umInfills.InsertWindowSystemType = this.pickers[this.cpService.selectedOperabilityPicker].operationType; //​Window System
          //umInfills.InsertOuterFrameDepth = this.OuterFrameInputArticle === undefined ? null : this.OuterFrameInputArticle.Depth;
          //umInfills.HandlePosition = this.handlePositioning;
          umInfills.OperabilitySystemID = operabilitysystem.OperabilitySystemID;
          let isTopHung: boolean = event === "topHung" || this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] == "Top-Hung";
          let isParallelOpening: boolean = event === "parallelOpening" || this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] === "Parallel-Opening";
          if (isTopHung || isParallelOpening) {
            this.frameCombinationInputValue = 'Combination 1';
            if (this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic) {
              if (isTopHung) this.ngNotificaionShow.next('topHungAcoustic');
              if (isParallelOpening) this.ngNotificaionShow.next('parallelAcoustic');
              this.unified3DModel.ProblemSetting.EnableAcoustic = false;
              this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic = false;
              if (!this.unified3DModel.ProblemSetting.EnableAcoustic) {
                this.unified3DModel.ModelInput.Acoustic = null;
              }
            }
          }
          // let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID ==id)[0].OperabilitySystemID;
          if (this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] === "Parallel-Opening" || this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] === "Top-Hung") {
            if (!hasOperability) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
              operabilitysystem.InsertOuterFrameArticleName = '1';
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
            } else {
              if (operableId > 0) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameArticleName = '1';
              }
              else {
                operabilitysystem.InsertOuterFrameArticleName = '1';
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
              }
  
            }
            this.frameCombinationInputValue = 'Combination 1';
            if (!this.umService.isWindow()) {
              let operabilityExists = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems !== null ? (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] === undefined ? false : true) : false;
              umInfills.InsertWindowSystem = !this.pickers[this.cpService.selectedOperabilityPicker].windowSystem ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem; //​Window System
              if (!hasOperability) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
                operabilitysystem.InsertedWindowType = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
                this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = operabilitysystem.InsertedWindowType;
                this.pickers[this.cpService.selectedOperabilityPicker].operationType = 'Manual';
                operabilitysystem.VentArticleName = 'AWS114VA';
                operabilitysystem.InsertOuterFrameArticleName = 'AWS114OF';
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
              } else {
                if (operabilityExists && operableId > 0) {
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertedWindowType = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].VentArticleName = 'AWS114VA';
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameArticleName = 'AWS114OF';
                } else {
                  operabilitysystem.InsertedWindowType = !this.pickers[this.cpService.selectedOperabilityPicker].windowSystem ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
                  this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = operabilitysystem.InsertedWindowType;
                  operabilitysystem.VentArticleName = 'AWS114VA';
                  operabilitysystem.InsertOuterFrameArticleName = 'AWS114OF';
                  if (operabilityExists && (operableId == -1 || operableId == 0)) {
                    this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.splice(this.cpService.selectedOperabilityPicker, 1);
                    this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.splice(this.cpService.selectedOperabilityPicker, 0, operabilitysystem);
                  } else {
                    this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
                  }
                }
  
              }
  
            }
          }
          else if (!this.umService.isWindow()) {
            let operabilityExists = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems !== null ? (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter( f => f.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] === undefined ? false : true) : false;
            umInfills.InsertWindowSystem = !this.pickers[this.cpService.selectedOperabilityPicker].windowSystem ? this.inwardSystemsToInsert[0].Value : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
            this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = umInfills.InsertWindowSystem;
            if (!hasOperability) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
              operabilitysystem.InsertedWindowType = this.inwardSystemsToInsert.find(system => system.Value === this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)?.Name;
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
            } else {
              if (operabilityExists && operableId > 0) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertedWindowType = this.inwardSystemsToInsert.find(system => system.Value === this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Name;
              } else {
                operabilitysystem.InsertedWindowType = this.inwardSystemsToInsert.find(system => system.Value === this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)?.Name;
                if (operabilityExists && (operableId == -1 || operableId == 0)) {
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.splice(this.cpService.selectedOperabilityPicker, 1);
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.splice(this.cpService.selectedOperabilityPicker, 0, operabilitysystem);
                } else {
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
                }
              }
            }
          }
          this.setDoorOperableTypeDisabled();
        });
        this.selectedGlassIDs = [];
        if (this.pickers[this.cpService.selectedOperabilityPicker].operableType && this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].fixedOpening) {
          if ((this.systemAWS114FacadeSelected == undefined || this.systemAWS114FacadeSelected == null) && this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward' && (this.pickers[this.cpService.selectedOperabilityPicker].operableType == 'parallelOpening' || this.pickers[this.cpService.selectedOperabilityPicker].operableType == 'topHung') || this.applicationType == "SRS") {
            this.onSelectAWSWindowSystem(this.outwardSystemsToInsert[0].Id);
          } else if ((this.systemFacadeSelected == undefined || this.systemFacadeSelected == null) && this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Inward' && this.umService.isFacade() && this.inwardSystemsToInsert && this.inwardSystemsToInsert.length > 0) {
            this.onSelectFWSWindowSystem(this.inwardSystemsToInsert[0].Id);
          }          
        }
        if (this.isDoorOperableTypeSelected) {
          let systemADSSelected = this.adsSystemValue.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
          this.isAddBtnDisable = true;
          this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType = this.cpService.SystemData("ADS")[this.cpService.selectedOperabilityPicker].Description;
          this.cpService.setSystem(this.cpService.SystemData("ADS")[systemADSSelected], "FRAMING");
        }
        else if (this.umService.isWindow()) {
          this.isAddBtnDisable = false;
        } 
        else if(this.umService.isFacade() && this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Inward') {
          this.systemFacadeSelected = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem && this.inwardSystemsToInsert.find(s => s.Value == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem) ? this.inwardSystemsToInsert.find(s => s.Value == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id:this.inwardSystemsToInsert[0].Id;
          let sys = this.inwardSystemsToInsert.find(system => system.Id == this.systemFacadeSelected);
          this.cpService.setSystem(this.cpService.SystemData("AWS").find(aws => aws.Description == sys.Name), "OPERABILITY");
        }
        else if(this.umService.isFacade() && this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward' && (this.pickers[this.cpService.selectedOperabilityPicker].operableType == 'parallelOpening' || this.pickers[this.cpService.selectedOperabilityPicker].operableType == 'topHung')) {
          this.systemAWS114FacadeSelected = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem && this.outwardSystemsToInsert.find(system => system.Name == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem) ? this.outwardSystemsToInsert.find(system => system.Name == this.pickers[this.cpService.selectedOperabilityPicker].windowSystem).Id:0;
          this.cpService.setSystem(this.cpService.SystemData("AWS114")[this.systemAWS114FacadeSelected], "OPERABILITY");
        }
        this.umService.setUnifiedModel(this.umService.current_UnifiedModel);      
        setTimeout(() => {
          this.reloadInputValues();
          setTimeout(() => {
            this.iframeService.setShowLoader(true);
            this.loadJSONService({ Unified3DModel: this.umService.current_UnifiedModel, canBeDrawn: this.canBeDrawnBool });
            this.lc.loadDisplaySetting_ActivePanel();    
          }, 25);
        }, 10);
      }
    }
  }

  private setDoorOperableTypeDisabled() {
    var vOT = [];
    this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.forEach(element => {
      if (element.OperabilitySystemID != -1) {
        let ot = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(f => f.OperabilitySystemID === element.OperabilitySystemID)[0];
        if(ot)
          vOT.push(ot.VentOperableType);
      }
    });
    if (vOT.filter(f => f === 'Turn-Tilt-Left' || f === 'Turn-Tilt-Right').length > 0) {
      this.isDoorOperableDisable = true;
      this.isWindowOperableDisable = false;
    }
    else {
      if(vOT.filter(f => f === 'Single-Door-Left' || f === 'Single-Door-Right' || f === 'Double-Door-Active-Left' || f === 'Double-Door-Active-Right').length > 0) {
        this.isDoorOperableDisable = false;
        this.isWindowOperableDisable = true;
      } else {
        this.isDoorOperableDisable = false;
        this.isWindowOperableDisable = false;
      }       
    }
  }


  updateSelected(event = null): void {
    if (!(this.cpService.selectedOperabilityPicker === -1 || !this.pickers[this.cpService.selectedOperabilityPicker].operableType || !this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening)) {
      if (this.glassAppliedArray[this.cpService.selectedOperabilityPicker].length > 0) {
        this.selectedGlassIDs = [];
        this.glassAppliedArray[this.cpService.selectedOperabilityPicker].forEach(glass => {
          this.selectedGlassIDs.push(glass.glassID);
        });
        this.selectedGlassIDs.forEach((id: number) => {
          if (id in this.glassIDsAlreadyApplied) {  // check if glassId is already applied
            this.glassAppliedArray[this.glassIDsAlreadyApplied[id] - 1] = this.glassAppliedArray[this.glassIDsAlreadyApplied[id] - 1].filter(glass => glass.glassID !== id);  // remove information
          }
          // apply new glass information
          this.glassIDsAlreadyApplied[id] = this.cpService.selectedOperabilityPicker + 1;
          let opType = '';
          switch (this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType]) {
            case 'Turn-Tilt-Right': opType = 'Turn-Tilt-Right'; break
            case 'Turn-Tilt-Left': opType = 'Turn-Tilt-Left'; break
            case 'Side-Hung-Right': opType = 'Side-Hung-Right'; break
            case 'Side-Hung-Left': opType = 'Side-Hung-Left'; break
            case 'Bottom-Hung': opType = 'Bottom-Hung'; break
            case 'Top-Hung': opType = 'Top-Hung'; break
            case 'Parallel-Opening': opType = 'Parallel-Opening'; break
            case 'Single-Door-Left': opType = 'Single-Door-Left'; break
            case 'Single-Door-Right': opType = 'Single-Door-Right'; break
            case 'Double-Door-Active-Left': opType = 'Double-Door-Active-Left'; break
            case 'Double-Door-Active-Right': opType = 'Double-Door-Active-Right'; break
            default: opType = ''; break
          }

          let fixopenText = '';
          switch (this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening]) {
            case 'Inward': fixopenText = 'Inward'; break
            case 'Outward': fixopenText = 'Outward'; break
            default: fixopenText = 'Inward'; break
          }

          if (this.isDoorOperableTypeSelected) {
            //this.glassAppliedArray.splice(this.cpService.selectedOperabilityPicker, 1);
            if(this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0] && this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0].operableType !== opType && this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0].glassID === id) {
              this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0].operableType = opType;
            } else {
              this.glassAppliedArray[this.cpService.selectedOperabilityPicker].push({ glassID: id, fixedOpening: fixopenText, operableType: opType });
            }
            
          } else {
            if(this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0] && this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0].operableType !== opType && this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0].glassID === id) {
              this.glassAppliedArray[this.cpService.selectedOperabilityPicker][0].operableType = opType;
            } else {
              this.glassAppliedArray[this.cpService.selectedOperabilityPicker].push({ glassID: id, fixedOpening: fixopenText, operableType: opType });
            }
            
          }

          let hasOperability = this.umService.isAnyInsertedUnit();
          var os = this.umService.obj_OperabilitySystem(this.cpService.selectedOperabilityPicker);
          let operabilitysystem: OperabilitySystem = {
            VentOpeningDirection: this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening],
            VentOperableType: this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType],
            VentArticleName: os?.VentArticleName ? os.VentArticleName:null,
            VentInsideW: os?.VentInsideW ? os.VentInsideW:-1,
            VentOutsideW: os?.VentOutsideW ? os.VentOutsideW:-1,
            VentDistBetweenIsoBars: os?.VentDistBetweenIsoBars ? os.VentDistBetweenIsoBars:-1,
            InsertOuterFrameArticleName: os?.InsertOuterFrameArticleName ? os.InsertOuterFrameArticleName:null,
            InsertOuterFrameInsideW: os?.InsertOuterFrameInsideW ? os.InsertOuterFrameInsideW:-1,
            InsertOuterFrameOutsideW: os?.InsertOuterFrameOutsideW ? os.InsertOuterFrameOutsideW:-1,
            InsertOuterFrameDistBetweenIsoBars: os?.InsertOuterFrameDistBetweenIsoBars ? os.InsertOuterFrameDistBetweenIsoBars:-1,
            InsideHandleArticleName: os?.InsideHandleArticleName ? os.InsideHandleArticleName:null,
            InsideHandleColor: os?.InsideHandleColor ? os.InsideHandleColor:null,
            InsideHandleArticleDescription: os?.InsideHandleArticleDescription ? os.InsideHandleArticleDescription:'',
            InsertInsulationType: this.insulatingSelection,
            InsertUvalueType: this.gasketSelection,
            InsertInsulationTypeName: this.insertInsulationTypeName,
            OperabilitySystemID: this.cpService.selectedOperabilityPicker + 1,
            DoorSystemID: -1,
            SlidingDoorSystemID: -1,
            JunctionType: 0,
            InsertedWindowType: '',
            RebateGasketArticleName: '',
            CenterGasketInsulationArticleName: '',
            InsertOuterFrameDepth: 0,
            InsertWindowSystem: ''
          };
          let doorsystem: DoorSystem;
          let hasdoorSystems = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems ? true : false;

          if (!this.isDoorOperableTypeSelected) {
            operabilitysystem.DoorSystemID = -1;
            if(!this.isTurnTiltDirectionChanged) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.Infills[id - 1].HandlePosition = -1; // negative number sets the handle position in center of vent
            } else {
              this.isTurnTiltDirectionChanged = false;
            }
           // this.umService.current_UnifiedModel.ModelInput.Geometry.Infills[id - 1].HandlePosition = -1; // negative number sets the handle position in center of vent
          } else {
            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills[id - 1].HandlePosition = 1050; //  sets the handle position to 1050 for the door system
            if (this.isDoorOperableTypeSelected) {
              operabilitysystem.VentArticleName = '-1';
              operabilitysystem.VentInsideW = -1;
              operabilitysystem.VentOutsideW = -1;
            }
            let ds = this.umService.obj_Door(1);
            if (ds) {
              doorsystem.DoorLeafArticleName = ds.DoorLeafArticleName;
              doorsystem.DoorLeafInsideW = ds.DoorLeafInsideW;
              doorsystem.DoorLeafOutsideW = ds.DoorLeafOutsideW;
              doorsystem.DoorPassiveJambArticleName = ds.DoorPassiveJambArticleName;
              doorsystem.DoorPassiveJambInsideW = ds.DoorPassiveJambInsideW;
              doorsystem.DoorPassiveJambOutsideW = ds.DoorPassiveJambOutsideW;
              doorsystem.DoorSillArticleName = ds.DoorSillArticleName;
              doorsystem.DoorSillInsideW = ds.DoorSillInsideW;
              doorsystem.DoorSillOutsideW = ds.DoorSillOutsideW;
              doorsystem.DoorSidelightSillArticleName = ds.DoorSidelightSillArticleName;
              doorsystem.HingeCondition = ds.HingeCondition;
              doorsystem.OutsideHandleArticleName = ds.OutsideHandleArticleName;
              doorsystem.OutsideHandleColor = ds.OutsideHandleColor;
              doorsystem.OutsideHandleArticleDescription = ds.OutsideHandleArticleDescription;
              doorsystem.InsideHandleArticleName = ds.InsideHandleArticleName;
              doorsystem.InsideHandleColor = ds.InsideHandleColor;
              doorsystem.InsideHandleArticleDescription = ds.InsideHandleArticleDescription;
              doorsystem.HingeArticleName = ds.HingeArticleName;
              doorsystem.HingeColor = ds.HingeColor;
              doorsystem.HingeArticleDescription = ds.HingeArticleDescription;
            }
            // doorsystem.DoorSystemType = opType.toLowerCase().includes('single') ? 'Single' : 'Double';
            // doorsystem.DoorSystemType = opType;
            switch (opType) {
              case "Double Door - Right Active": doorsystem.DoorSystemType = this.doubleDoorRightSystemTypeText; break;
              case "Double Door - Left Active": doorsystem.DoorSystemType = this.doubleDoorLeftSystemTypeText; break;
              case "Single Door - Left": doorsystem.DoorSystemType = this.singleDoorLeftSystemTypeText; break;
              case "Single Door - Right": doorsystem.DoorSystemType = this.singleDoorRightSystemTypeText; break;
              default: doorsystem.DoorSystemType = ''; break;
            }
            doorsystem.DoorSystemID = this.cpService.selectedOperabilityPicker + 1;
            //  doorsystem.InsideHandleArticleName = "1";
            // doorsystem.OutsideHandleArticleName = "1";
            operabilitysystem.DoorSystemID = doorsystem.DoorSystemID;

            if (!hasdoorSystems) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems = [];
              this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.push(doorsystem);
            }
          }
          let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID;
          let doorsystemId = operableId > 0 ? this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].DoorSystemID : -1;

          if (!hasOperability) {
            this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
            this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
          } else {
            if (operableId > 0) {
              let umOperabilitySystems = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0];
              umOperabilitySystems.VentOpeningDirection = this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening];
              umOperabilitySystems.VentOperableType = this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType];
              var os = this.umService.obj_OperabilitySystem(0);
              if (os) {
                umOperabilitySystems.VentArticleName = os.VentArticleName;
                umOperabilitySystems.VentInsideW = os.VentInsideW? os.VentInsideW : -1;
                umOperabilitySystems.VentOutsideW = os.VentOutsideW? os.VentOutsideW : -1;
                umOperabilitySystems.VentDistBetweenIsoBars = os.VentDistBetweenIsoBars;
                umOperabilitySystems.InsertOuterFrameArticleName = os.InsertOuterFrameArticleName;
                umOperabilitySystems.InsertOuterFrameInsideW = os.InsertOuterFrameInsideW;
                umOperabilitySystems.InsertOuterFrameOutsideW = os.InsertOuterFrameOutsideW;
                umOperabilitySystems.InsertOuterFrameDistBetweenIsoBars = os.InsertOuterFrameDistBetweenIsoBars;
              }
              
              if (!this.isDoorOperableTypeSelected) {
                umOperabilitySystems.DoorSystemID = -1;
                doorsystemId = -1;
                this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems = null;
                if(this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[0].VentArticleName === '-1')
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[0].VentArticleName = undefined;
              } else {
                umOperabilitySystems.DoorSystemID = 1;
                doorsystemId = 1;
              }
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertInsulationType = this.insulatingSelection;
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertUvalueType = this.gasketSelection;
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertInsulationTypeName = this.insertInsulationTypeName;
              // this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].InsideHandleArticleName = this.InsideHandleFromChild === undefined ? null : this.InsideHandleFromChild.ArticleName;
              // this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].InsideHandleColor = this.InsideHandleFromChild === undefined ? null : this.InsideHandleFromChild.Color;
              let hasdoorSystems = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems ? true : false;
              if (hasdoorSystems && doorsystemId > 0) {
                let umDoorSystem = this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0];
                let ds = this.umService.obj_Door(1);
                if (ds) {
                  umDoorSystem.DoorLeafArticleName = ds.DoorLeafArticleName;
                  umDoorSystem.DoorLeafInsideW = ds.DoorLeafInsideW;
                  umDoorSystem.DoorLeafOutsideW = ds.DoorLeafOutsideW;
                  umDoorSystem.DoorPassiveJambArticleName = ds.DoorPassiveJambArticleName;
                  umDoorSystem.DoorPassiveJambInsideW = ds.DoorPassiveJambInsideW;
                  umDoorSystem.DoorPassiveJambOutsideW = ds.DoorPassiveJambOutsideW;
                  umDoorSystem.DoorSillArticleName = ds.DoorSillArticleName;
                  umDoorSystem.DoorSillInsideW = ds.DoorSillInsideW;
                  umDoorSystem.DoorSillOutsideW = ds.DoorSillOutsideW;
                  umDoorSystem.DoorSidelightSillArticleName = ds.DoorSidelightSillArticleName;
                  umDoorSystem.OutsideHandleArticleName = ds.OutsideHandleArticleName;
                  umDoorSystem.OutsideHandleColor = ds.OutsideHandleColor;
                  umDoorSystem.OutsideHandleArticleDescription = ds.OutsideHandleArticleDescription;
                  umDoorSystem.InsideHandleArticleName = ds.InsideHandleArticleName;
                  umDoorSystem.InsideHandleColor = ds.InsideHandleColor;
                  umDoorSystem.InsideHandleArticleDescription = ds.InsideHandleArticleDescription;
                  umDoorSystem.HingeArticleName = ds.HingeArticleName;
                  umDoorSystem.HingeColor = ds.HingeColor;
                  umDoorSystem.HingeArticleDescription = ds.HingeArticleDescription;
                }
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].VentArticleName = '-1';
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].VentInsideW = -1;
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID === operableId)[0].VentOutsideW = -1;
                // umDoorSystem.DoorSystemType = opType.toLowerCase().includes('single') ? 'Single' : 'Double';
                switch (opType) {
                  case "Double Door - Right Active": this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0].DoorSystemType = this.doubleDoorRightSystemTypeText; break;
                  case "Double Door - Left Active": this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0].DoorSystemType = this.doubleDoorLeftSystemTypeText; break;
                  case "Single Door - Left": this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0].DoorSystemType = this.singleDoorLeftSystemTypeText; break;
                  case "Single Door - Right": this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0].DoorSystemType = this.singleDoorRightSystemTypeText; break;
                  default: this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems.filter(glass => glass.DoorSystemID === doorsystemId)[0].DoorSystemType = ''; break;
                }
              }
            } else {
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
            }
          }

          this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].InsertWindowSystemType = this.pickers[this.cpService.selectedOperabilityPicker].operationType; //​Window System
          //this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].InsertOuterFrameDepth = this.OuterFrameInputArticle === undefined ? null : this.OuterFrameInputArticle.Depth;
          this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID = operabilitysystem.OperabilitySystemID;
          if (event === "parallelOpening" || event === "topHung" || this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] === "Parallel-Opening" || this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] == "Top-Hung") this.frameCombinationInputValue = 'Combination 1';
          if (this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] === "Parallel-Opening" || this.operableType_index[this.pickers[this.cpService.selectedOperabilityPicker].operableType] === "Top-Hung") {
            if (!hasOperability) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
              operabilitysystem.InsertOuterFrameArticleName = '1';
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
            } else {
              if (operableId > 0) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameArticleName = '1';
              } else {
                operabilitysystem.InsertOuterFrameArticleName = '1';
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
              }
            }
            if (!this.umService.isWindow()) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].InsertWindowSystem = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem; //​Window System
              if (!hasOperability) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
                operabilitysystem.InsertedWindowType = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
                operabilitysystem.VentArticleName = 'AWS114VA';
                operabilitysystem.InsertOuterFrameArticleName = 'AWS114OF';
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
              } else {
                if (operableId > 0) {
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertedWindowType = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].VentArticleName = 'AWS114VA';
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameArticleName = 'AWS114OF';
                } else {
                  operabilitysystem.InsertedWindowType = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? 'AWS 114' : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
                  operabilitysystem.VentArticleName = 'AWS114VA';
                  operabilitysystem.InsertOuterFrameArticleName = 'AWS114OF';
                  this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
                }
              }
            }
          } else if (!this.umService.isWindow()) {
            this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].InsertWindowSystem = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? this.inwardSystemsToInsert[0].Value : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
            this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem === '' ? this.inwardSystemsToInsert[0].Value : this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
            if (!hasOperability) {
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = [];
              operabilitysystem.InsertedWindowType = this.inwardSystemsToInsert.find(system => [system.Name, system.Value].includes(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)).Name;
              this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
            } else {
              if (operableId > 0) {
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertedWindowType = this.inwardSystemsToInsert.find(system => [system.Name, system.Value].includes(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)).Name;
              } else {
                operabilitysystem.InsertedWindowType = this.inwardSystemsToInsert.find(system => [system.Name, system.Value].includes(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)).Name;
                this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.push(operabilitysystem);
              }
            }
          }
        });

        //disableresult
        // this.configureService.computeClickedSubject.next(false);
        // this.unified3DModelFromOperabilityEvent.emit(this.unified3DModel);
        this.selectedGlassIDs = [];
        // if (event && this.umService.current_UnifiedModel.ProblemSetting.ProductType == "Facade") {
        if (event) {
          if (event === 'parallelOpening' || event === 'topHung' || this.applicationType == "SRS") {
            let index = this.max(this.outwardSystemsToInsert.find(system => system.Name == this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertedWindowType).Id, 0);
            this.onSelectAWSWindowSystem(index);
          }
          else {
            if (this.umService.isFacade() && this.inwardSystemsToInsert.find(system => system.Name == this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertedWindowType)) {
              let index = this.inwardSystemsToInsert.find(system => system.Name == this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertedWindowType).Id;
              this.onSelectFWSWindowSystem(index);
            }
            else if (this.umService.isFacade() && this.outwardSystemsToInsert.find(system => system.Name == this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertedWindowType)) {
              let index = this.outwardSystemsToInsert.find(system => system.Name == this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertedWindowType).Id;
              this.onSelectAWSWindowSystem(index);
            }
          }          
        }
        if (this.isDoorOperableTypeSelected) {
          let systemADSSelected = this.adsSystemValue.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem);
          this.isAddBtnDisable = true;
          this.umService.current_UnifiedModel.ModelInput.FrameSystem.SystemType = this.cpService.SystemData("ADS")[systemADSSelected].Description;
          this.cpService.setSystem(this.cpService.SystemData("ADS")[systemADSSelected], "FRAMING");
        }
        this.umService.setUnifiedModel(this.umService.current_UnifiedModel);
        setTimeout(() => {
          this.umService.get_InsideHandle();
        }, 1000);  
        setTimeout(() => {
          this.reloadInputValues();
          setTimeout(() => {
            this.iframeService.setShowLoader(true);
            if(this.isOpDirectionChangeForCameraReset){
              this.loadJSONService({ Unified3DModel: this.umService.current_UnifiedModel, canBeDrawn: false });
              this.isOpDirectionChangeForCameraReset = false;
            } else {
              this.loadJSONService({ Unified3DModel: this.umService.current_UnifiedModel, canBeDrawn: this.canBeDrawnBool });
            }           
            this.lc.loadDisplaySetting_ActivePanel();    
          }, 25);
        }, 10);

      }
    }
  }

  onRemoveItem(id: number): void {
    this.glassAppliedArray[this.cpService.selectedOperabilityPicker] = this.glassAppliedArray[this.cpService.selectedOperabilityPicker].filter(glass => glass.glassID !== id);
    delete this.glassIDsAlreadyApplied[id];
    if (this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
      let OperableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID;
      let infills = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.OperabilitySystemID == OperableId);
      if (infills && infills.length > 0) {  // should be always true
        this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].GlazingSystemID = 1;
        this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].OperabilitySystemID = -1;
        this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == id)[0].InsertWindowSystem = null;
        if (this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.OperabilitySystemID == OperableId).length == 0) {
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(os => os.OperabilitySystemID != OperableId);
          if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.length == 0) {
            this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems = null;
          }
        }
        if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.length > 1 && infills.length == 1) {
          let index = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.findIndex(x => x.OperabilitySystemID === OperableId);
          if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[index] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[index].DoorSystemID === 1)
            this.umService.current_UnifiedModel.ModelInput.Geometry.DoorSystems = null;
        }
      }
    }
    if(this.isDoorOperableTypeSelected)
    this.isAddBtnDisable = true;
    else
    this.isAddBtnDisable = false;
    this.loadJSONService({ Unified3DModel: this.umService.current_UnifiedModel, canBeDrawn: this.canBeDrawnBool });
    this.lc.loadDisplaySetting_ActivePanel();
    //disableresult
    this.configureService.computeClickedSubject.next(false);
    this.umService.setUnifiedModel(this.umService.current_UnifiedModel);
    this.selectedGlassIDs = [];
    setTimeout(() => {
      this.setDoorOperableTypeDisabled();
    }, 100);
  }

  isOneGlassApplied(): boolean {
    return Object.keys(this.glassIDsAlreadyApplied).length > 0;
  }

  max(a, b) {
    return Math.max(a, b);
  }

  onFocusGreen(glassId) {
    this.iframeEvent.next(new IFrameEvent('highlightGlassById', { id: glassId }));
  }

  onFocusOutItem() {
    this.iframeEvent.next(new IFrameEvent('clearHighlightedMeshes'));
  }

  onFocusRed(glassId, event) {  // event = true if mouse In, event = false if mouse out
    if (event) {
      let obj = { id: glassId, colorCode: "0xbc0000" };
      this.iframeEvent.next(new IFrameEvent('highlightGlassById', obj));
    }
    else {
      this.onFocusGreen(glassId);
    }
  }
  systemType: string = "";
  onSelectAWSWindowSystem(index: number): void {
    this.systemType = "114";
    if (!this.insertInsulationTypeName || (this.insertInsulationTypeName && this.insertInsulationTypeName != 'PA' && this.insertInsulationTypeName != 'PT')) {
      this.insertInsulationTypeName = 'PA';
    }
    let isValueChanged = false;
    if (this.systemAWS114FacadeSelected != undefined && this.systemAWS114FacadeSelected != null && this.systemAWS114FacadeSelected != index) {
      isValueChanged = true;
      this.isSystemSelectionChanged = true;
      this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = this.isDoorOperableTypeSelected ? this.adsSystemValue[index]:this.outwardSystemsToInsert.find(system => system.Id == index).Name;
      this.configureService.computeClickedSubject.next(false);
      this.glassAppliedArray[this.cpService.selectedOperabilityPicker].forEach(glassObject => {
        let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].OperabilitySystemID;
        if ((this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || this.pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung') && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {

          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].VentArticleName = 'AWS114VA';
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertOuterFrameArticleName = 'AWS114OF';
          this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].InsertWindowSystemType = this.pickers[this.cpService.selectedOperabilityPicker].operationType;
          //this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].VentArticleName = '-1';
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].VentInsideW = -1;
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].VentOutsideW = -1;
          this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].VentDistBetweenIsoBars = -1;
        }
        this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].InsertWindowSystem = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem; //​Window System
        if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertedWindowType = this.pickers[this.cpService.selectedOperabilityPicker].windowSystem;
        //this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].PanelSystemID =  -1; 
        //this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].GlazingSystemID = -1; 
      });

      // if (this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0] && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
      //   this.sendCurrentArticleToFrameCombinationTable.emit({ ArticleID: this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1))[0].VentArticleName, SystemID: this.aws114SystemValue[this.aws114SystemDesc.indexOf(this.pickers[this.cpService.selectedOperabilityPicker].windowSystem)], OperationType: this.pickers[this.cpService.selectedOperabilityPicker].operationType });
      // }
    //  this.unified3DModelFromOperabilityEvent.emit(this.unified3DModel);
      this.umService.setUnifiedModel(this.umService.current_UnifiedModel);
      this.onCloseFramingPopoutsEvent.emit();   // send event framing > left-configure > configure >  iFrame > tables. It closes all framing tables
    }
    this.systemAWS114FacadeSelected = index;
  }

  onSelectFWSWindowSystem(index: number): void {
    if (!this.umService.isAnyInsertedUnitNotSupportingAcoustic()) this.checkAcousticCompatibility(this.inwardSystemsToInsert.find(s => s.Id == index).Name);
    this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = this.inwardSystemsToInsert.find(s => s.Id == index).Value;
    this.systemType = this.inwardSystemsToInsert.find(s => s.Id == index).Value.includes('90') ? '90' : '75';
    if (!this.insertInsulationTypeName) this.insertInsulationTypeName = 'PA';
    else if ((this.systemType == '90' && (this.insertInsulationTypeName != 'PA' && this.insertInsulationTypeName != 'PT')) || (this.systemType == '75' && (this.insertInsulationTypeName == 'PA' || this.insertInsulationTypeName == 'PT'))) this.insertInsulationTypeName = 'PA';
    this.glassAppliedArray[this.cpService.selectedOperabilityPicker].forEach(glassObject => {
      let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.find(glass => glass.InfillID == glassObject.glassID).OperabilitySystemID;
      this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.find(glass => glass.InfillID == glassObject.glassID).InsertWindowSystem = this.inwardSystemsToInsert.find(s => s.Id == index).Value;
      if (operableId > 0 && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == operableId).InsertedWindowType = this.inwardSystemsToInsert.find(system => system.Id == index).Name;
      }
    });
    let sys = this.inwardSystemsToInsert.find(sys => sys.Id == index);
    this.cpService.setSystem(this.cpService.SystemData("AWS").find(aws => aws.Description == sys.Name), "OPERABILITY");
    this.umService.setUnifiedModel(this.umService.current_UnifiedModel);
    this.configureService.computeClickedSubject.next(false);
    this.onCloseFramingPopoutsEvent.emit();
    this.systemFacadeSelected = index;
  }

  onOpenIFramePopout(popup: string): void {
    if (popup === 'FrameCombination') this.cpService.setPopout(true, PanelsModule.FrameCombination);
    else if (popup === 'OuterFrame') this.cpService.setPopout(true, PanelsModule.OuterFrame);
    else if (popup === 'VentFrame') this.cpService.setPopout(true, PanelsModule.VentFrame);
    else if (popup === 'DoorLeafActive') this.cpService.setPopout(true, PanelsModule.DoorLeafActive);
    else if (popup === 'HandleColor') this.cpService.setPopout(true, PanelsModule.HandleColor);
    else if (popup === 'HingeType') this.cpService.setPopout(true, PanelsModule.HingeType);
    else if (popup === 'InsideHandle') this.cpService.setPopout(true, PanelsModule.InsideHandle);
    else if (popup === 'OutsideHandle') this.cpService.setPopout(true, PanelsModule.OutsideHandle);
  }

  doEnableAcoustic() {
    setTimeout(() => {
      if (!this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic) {
        var AWS114Data = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(g =>
          g.InsertWindowSystem !== null &&
          g.InsertWindowSystem !== undefined &&
          g.InsertWindowSystem.indexOf("AWS 114") > -1
        );
          
        var CustomTripleData = this.umService.current_UnifiedModel.ModelInput.Geometry.GlazingSystems.filter(g => (g.Category === "custom" || g.Category === "custom-triple") && g.Plates.length === 3);
            
        if (AWS114Data.length === 0 && CustomTripleData.length === 0) {
          this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic = true;
          this.ngNotificaionShow.next("AcousticEnabled");
        }
      }
    }, 200);
  }

  onOperationTypeChange(event: any) {
    this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(section => section.OperabilitySystemID == (this.cpService.selectedOperabilityPicker + 1)).forEach(section => {
      section.InsertWindowSystemType = this.pickers[this.cpService.selectedOperabilityPicker].operationType;
    });
  }

  onVentOpeningDirectionChange(event: any) {
    switch(event) {
      case 'Outward':
        this.insertInsulationTypeName = "PA";
        let is_operableType_inward = ['tiltTurn-left', 'sideHung-left', 'bottomHung'].indexOf(this.pickers[this.cpService.selectedOperabilityPicker].operableType) > -1;
        if (is_operableType_inward) { // change to the first operable type of outward
          this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].operableType = 'topHung';
          this.onOperableTypeChange('topHung');
        }
        break;
      case 'Inward':
        if (!this.insertInsulationTypeName || (this.insertInsulationTypeName == 'PA' || this.insertInsulationTypeName == 'PT')) {
          if (this.systemFacadeSelected == 0) this.systemType = "75";
          this.insertInsulationTypeName = "PA";
        }
        let is_operableType_outward = ['topHung', 'parallelOpening'].indexOf(this.pickers[this.cpService.selectedOperabilityPicker].operableType) > -1;
        if (is_operableType_outward) { // change to the first operable type of inward
          this.pickers[this.max(this.cpService.selectedOperabilityPicker, 0)].operableType = 'tiltTurn-left';
          this.onOperableTypeChange('tiltTurn-left');
        }
        break;
    }
    if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems != null && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems[0].VentOpeningDirection != this.fixedOpening_index[this.pickers[this.cpService.selectedOperabilityPicker].fixedOpening]) {
      this.onApply(event);
    }
  }

  onOperableTypeChange(event: any, withNotification: boolean = true) {
    if (event.includes('Door')) {
      this.isDoorOperableTypeSelected = true;
      this.isDoorArticlesDisplay = true;
      this.isWindowArticlesDisplay = false;
      this.isWindowOperableTypeSelected = false;
      if(this.selectedGlassIDs.length>1) {
        this.isApplyBtnDisable = true;
      } else {
        this.isApplyBtnDisable = false;
        if (this.pickers.filter((picker,index) => (picker.operableType == 'tiltTurn-left' || picker.operableType == 'tiltTurn-right') && this.glassAppliedArray[index].length > 0).length > 0) {
          this.isApplyBtnDisable = true;
        } else if(this.isTransomGlassExists && this.selectedGlassIDs.length > 0) {
          this.isApplyBtnDisable = true;
          this.ngNotificaionShow.next("Warning_DoorAboveTransom");
        }
      }
    } 
    else {
      this.isApplyBtnDisable = false;
      this.isDoorOperableTypeSelected = false;
      this.isDoorArticlesDisplay = false;
      this.isWindowOperableTypeSelected = true;
      this.isWindowArticlesDisplay = true;      
    }
    if (event === 'parallelOpening' || event === 'topHung') {
      if (this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic) {
        var errorMessage = event == 'parallelOpening' ? this.translate.instant(_('notification.acoustic-solver-does-not-support-Parallel-openings-continue-without-Acoustic')):this.translate.instant(_('notification.acoustic-solver-does-not-support-Top-Hung-openings-continue-without-Acoustic'));
        this.modalService.confirm({
          nzWrapClassName: "vertical-center-modal",
          nzWidth: '490px',
          nzTitle: '',
          nzContent: errorMessage,
          nzCancelText: this.translate.instant(_('notification.no')),
          nzOnCancel: () => this.pickers[this.cpService.selectedOperabilityPicker].operableType = '',
          nzOkText: this.translate.instant(_('notification.yes')),
          nzOnOk: () => {
            this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic = false;
            this.updateSelected(event);
            setTimeout(() => {
              if (event === 'topHung')
                this.ngNotificaionShow.next("topHungAcoustic");
              else
                this.ngNotificaionShow.next("AcousticNoSupportParallelOpenings");
            }, 200);
          },
        });
      }
      else {
        this.frameCombinationInputValue = 'Combination 1';
        if (!this.umService.isWindow()) {
          this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = 'AWS 114';
          this.pickers[this.cpService.selectedOperabilityPicker].operationType = 'Manual';
        }
        this.updateSelected(event);
      }
    } else if (event !== '') {
      this.doEnableAcoustic();
      this.pickers[this.cpService.selectedOperabilityPicker].windowSystem =  this.isDoorOperableTypeSelected ? 'ads__75':
                                                        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1) ? this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertedWindowType:
                                                        this.inwardSystemsToInsert && this.inwardSystemsToInsert[0] ? this.inwardSystemsToInsert[0].Value:
                                                          '';
      this.pickers[this.cpService.selectedOperabilityPicker].operationType = '';
      if(this.isDoorOperableTypeSelected )
      {
        if(!this.isTransomGlassExists) {
          if(this.glassAppliedArray[this.cpService.selectedOperabilityPicker].length > 0) {
          this.isDoorArticlesDisplay = true;
          } else {
            this.isDoorArticlesDisplay = false;
            this.isWindowArticlesDisplay = true;
          }
          this.updateSelected(event);
        
        } else {
          this.isDoorArticlesDisplay = false;
          this.isWindowOperableTypeSelected = true;
          this.isWindowArticlesDisplay = true;   
          this.ngNotificaionShow.next("Warning_DoorAboveTransom");
          this.pickers[this.cpService.selectedOperabilityPicker].windowSystem = this.inwardSystemsToInsert[0].Value;
          if(this.glassAppliedArray[0][0].operableType === 'Turn/Tilt - Left Handed'){
            event = 'tiltTurn-left';
          this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'tiltTurn-left';
          }
          if(this.glassAppliedArray[0][0].operableType === 'Turn/Tilt - Right Handed'){
            event = 'tiltTurn-right';
            this.pickers[this.cpService.selectedOperabilityPicker].operableType = 'tiltTurn-right';
          }               
        }
      } else {
        this.updateSelected(event);
      }
    }
  }
        
  // Functionality moved to framing component. Should be removed
  onSelectGasket(event: any): void { 
    // if (!this.umService.current_UnifiedModel.ModelInput.FrameSystem) { this.umService.current_UnifiedModel.ModelInput.FrameSystem = new FrameSystem(); }
    // this.glassAppliedArray[this.cpService.selectedOperabilityPicker].forEach(glassObject => {
    //   this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.GlassID == glassObject.glassID)[0].InsertInsulationType = this.insulatingSelection;
    //   this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.GlassID == glassObject.glassID)[0].InsertUvalueType = this.gasketSelection;
    // });
    // this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.forEach(glass => {
    //   glass.InsertInsulationType = this.insulatingSelection;
    //   glass.InsertUvalueType = this.gasketSelection;
    // });
    // this.loadJSONService({ Unified3DModel: this.unified3DModel, canBeDrawn: this.canBeDrawnBool, resetCamera: false });
    // this.unified3DModelEvent.emit(this.unified3DModel);
    // //disableresul
    // this.configureService.computeClickedSubject.next(false);
    // this.unified3DModelEvent.emit(this.unified3DModel);
  }

  onSelectInsulating(_event: any): void {
    // if (!this.umService.current_UnifiedModel.ModelInput.FrameSystem) this.umService.current_UnifiedModel.ModelInput.FrameSystem = new FrameSystem();
    //this.insertInsulationTypeName = this.insulatingSelection;
    if (this.insertInsulationTypeName && (this.insertInsulationTypeName.includes('Polythermid') || this.insertInsulationTypeName == 'PT'))
      this.insulatingSelection = 'PT';
    else
      this.insulatingSelection = 'PA';
    let isValueChanged: Boolean = false;
    this.glassAppliedArray[this.cpService.selectedOperabilityPicker].forEach(glassObject => {
      let operableId = this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.filter(glass => glass.InfillID == glassObject.glassID)[0].OperabilitySystemID;
      if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertInsulationType != this.insulatingSelection)
        isValueChanged = true;
      if (this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems) {
        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertInsulationTypeName = this.insertInsulationTypeName;
        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertInsulationType = this.insulatingSelection;
        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.filter(glass => glass.OperabilitySystemID == operableId)[0].InsertUvalueType = this.gasketSelection;

      }
    });
    // this.umService.current_UnifiedModel.ModelInput.Geometry.Infills.forEach(glass => {
    //   glass.InsertInsulationType = this.insulatingSelection;
    //   glass.InsertUvalueType = this.gasketSelection;
    // });
    if (isValueChanged) {
      this.loadJSONService({ Unified3DModel: this.umService.current_UnifiedModel, canBeDrawn: this.canBeDrawnBool });
      //disableresul     
      this.configureService.computeClickedSubject.next(false);
      this.unified3DModelEvent.emit(this.umService.current_UnifiedModel);
    }
  }

  loadJSONService(data: any) {
    this.iframeService.loadJSON(this.iframeEvent, 'loadJSON', data);
  }

  checkAcousticCompatibility(system: string) {
    if (this.umService.isSystemNotSupportingAcoustic(system) && this.umService.current_UnifiedModel.ProblemSetting.EnableAcoustic) this.ngNotificaionShow.next({message1: system, logoToShow: 'AcousticSystemNotSupported'});   
  }

  isFrenchInsertedSystem(): boolean {
    if (this.inwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker]?.windowSystem)) {
      return this.umService.isFrenchSystem(this.inwardSystemsToInsert.find(system => system.Value == this.pickers[this.cpService.selectedOperabilityPicker]?.windowSystem).Name);
    }
    return false;
  }

  isAWS75SIPlusInsertedSystem(): boolean {
    return ['AWS 75.SI+', 'aws__75_si_plus'].includes(this.pickers[this.cpService.selectedOperabilityPicker]?.windowSystem);
  }

  insulationLevelSelection: string;
  onSelectInsertedUValueType(Uvalue: string) {
    if (Uvalue!= undefined && this.umService.isAnyInsertedUnit() && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1)) {
      let isValueChanged: boolean = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType != Uvalue;
      if (isValueChanged) this.configureService.computeClickedSubject.next(false);
      this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType = this.insulationLevelSelection;
      this.unified3DModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType = this.insulationLevelSelection;
   }
  }

  getUvalueType(): void {
    if (this.umService.isAnyInsertedUnit() && this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1)) {
      let UvalueType = this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1)?.InsertUvalueType;
      // Insulation level
      if (this.umService?.current_UnifiedModel?.ProblemSetting?.EnableThermal && this.isFrenchInsertedSystem()) {
        this.insulationLevelSelection = this.framingService.insulationLevels.map(insulation => insulation.Value).includes(UvalueType) ? UvalueType:this.framingService.insulationLevels[0].Value;
        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType = this.insulationLevelSelection;
        this.unified3DModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType = this.insulationLevelSelection;
      }
      // Gaskets
      if (this.umService?.current_UnifiedModel?.ProblemSetting?.EnableThermal && this.isAWS75SIPlusInsertedSystem()) {
        this.insulationLevelSelection = this.framingService.gasketSelections.map(gasket => gasket.Value).includes(UvalueType) ? UvalueType:this.framingService.gasketSelections[0].Value;
        this.umService.current_UnifiedModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType = this.insulationLevelSelection;
        this.unified3DModel.ModelInput.Geometry.OperabilitySystems.find(os => os.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1).InsertUvalueType = this.insulationLevelSelection;
      }
    }
  }

  isInsertWindowDisabled(): boolean {
    return this.glassAppliedArray[this.cpService.selectedOperabilityPicker].length < 1 || this.pickers[this.cpService.selectedOperabilityPicker].operableType === '';
  }
}
