import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NavLayoutService } from '../../services/nav-layout.service';
import { Router, NavigationEnd } from '@angular/router';
//import { BpsProblem } from 'src/app/app-common/models/bps-problem';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { BpsUnifiedProblem } from 'src/app/app-common/models/bps-unified-problem';
import { HomeService } from 'src/app/app-common/services/home.service';
import { Subject, Subscription } from 'rxjs';
import { BpsUnifiedModel } from 'src/app/app-common/models/bps-unified-model';
import { Location } from '@angular/common';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/app-core/services/auth.service';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { AppconstantsService } from 'src/app/app-common/services/appconstants.service';
import { saveAs } from 'file-saver';
import { DownloadService } from '../../services/download.service';
import { CommonService } from 'src/app/app-common/services/common.service';
import { Feature } from 'src/app/app-core/models/feature';
import { TranslateService } from '@ngx-translate/core';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { SystemTableService } from 'src/app/app-structural/services/system-table.service';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';


@Component({
  selector: 'app-nav-layout',
  templateUrl: './nav-layout.component.html',
  styleUrls: ['./nav-layout.component.css']
})
export class NavLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  online$: Observable<boolean>;
  eventURL$: any;
  showDownload: boolean = false;
  isNavBarButtonAndTitleVisible: boolean = true;
  problem: BpsUnifiedProblem = new BpsUnifiedProblem();
  userFullName: string = '';
  isComputeClicked: boolean = false;
  isSystemTableOpened: boolean = false;
  previousRoute: string;
  showCrossButton: boolean = false;
  computeSubjectSubscription: Subscription;
  routerSubscription: Subscription;
  problemSubscription: Subscription;
  navBarSubscription: Subscription;
  deletePreviousArrowSubscription: Subscription;
  feature=Feature;
  computeFormValidationBool: boolean = false;

  private destroy$ = new Subject<void>();
  constructor(private downloads: DownloadService, private location: Location, private appConstantService: AppconstantsService, private commonService: CommonService,
    public configureService: ConfigureService, private localStorageService: LocalStorageService, public navLayoutService: NavLayoutService, private router: Router, private homeService: HomeService, 
    private auth: AuthService, private translate: TranslateService, private cpService: ConfigPanelsService, private stService: SystemTableService,
    private umService: UnifiedModelService) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.networkStatus();

    this.eventURL$ = location.onUrlChange((val) => {
      if (val.split("/")[1] == "orders")
        this.MyOrderSelected = true;
      else this.MyOrderSelected = false;
    })
  }


  public showDownloadButton() {
    if (this.appConstantService.APP_DOMAIN === 'https://localhost:58119/'
      || this.appConstantService.APP_DOMAIN === 'https://apiwebtest.vcldesign.com/'
      || this.appConstantService.APP_DOMAIN === 'https://srsapitest.vcldesign.com/') {
      this.showDownload = true;
    }
  }

  public networkStatus() {
    this.online$.subscribe(value => {
    })
  }
  MyOrderSelected = false;
  OrderPlaced = false;
  applicationType: string = '';
  ngOnInit(): void {
    this.configureService.computeBoolSubject.pipe(takeUntil(this.destroy$)).subscribe((computeFormValidationBool) => {
      this.computeFormValidationBool = computeFormValidationBool;
    });

    this.applicationType = this.commonService.getApplicationType();
    if (this.applicationType === 'BPS') { 
      if(location && location.search && new URLSearchParams(location.search).get('language')){
        var language = new URLSearchParams(location.search).get('language');
        switch(language){
          case "de":
            this.navLayoutService.language = language +"-DE";
            break;
          case "fr":
            this.navLayoutService.language = language +"-FR";
            break;
          case "en":
            this.navLayoutService.language = language +"-US";
            break;
          default:
            if(new URLSearchParams(location.search).get('country')) 
            this.navLayoutService.language = language +"-"+ new URLSearchParams(location.search).get('country');
            break;
        }
      } else{
        this.navLayoutService.language = (this.localStorageService.getValue('culture')) ? this.localStorageService.getValue('culture') : 'de-DE';
      }
      if (!this.localStorageService.hasKey('culture')) {
        this.localStorageService.setValue<string>('culture', this.navLayoutService.language);
      }
      this.navLayoutService.languageIsChangedSubject.next();
      this.translate.use(this.navLayoutService.language);
    }
    

    this.showDownloadButton();
  
    this.userFullName = this.commonService.getUserName();
    this.navLayoutService.isNavBarButtonAndTitleShownSubject.pipe(takeUntil(this.destroy$)).subscribe(isVisible => {
     if (isVisible !== undefined) {
        setTimeout(() => {
         this.isNavBarButtonAndTitleVisible = isVisible;
         if (this.isNavBarButtonAndTitleVisible) {
           this.previousRoute = null;
           this.showCrossButton = false;
         }
        });
     }
   });
    this.navLayoutService.setNavBarButtonAndTitleVisibility();
    this.configureService.isProblemSavedFromNavBar.subscribe((isProblemSaved) => {
      if (isProblemSaved) {
        this.navLayoutService.showBlueSaveButton = false;
      }
    });

    if (location.pathname.includes("orders"))
      this.MyOrderSelected = true;
    else this.MyOrderSelected = false;
  }

  ngAfterViewInit(): void {
    this.umService.obsSaveUnifiedModel.subscribe((res) => {
      if (res) {
        this.onSaveButtonClick()
      }
    });
    
    this.routeEvent(this.router);
   
    // this.navBarSubscription = this.navLayoutService.isNavBarButtonAndTitleShownSubject.subscribe(isVisible => {
     

    //this.problemSubscription = this.configureService.problemSubject.subscribe(problem => {
      this.configureService.problemSubject.pipe(takeUntil(this.destroy$)).subscribe(problem => {
      this.problem = problem;
      this.OrderPlaced = problem.OrderPlaced;
      let url1 = 'problemconfigure/' + this.problem.ProblemGuid;
      this.navLayoutService.isConfigureClicked = this.router.isActive(url1, true);
      let url2 = 'result/' + this.problem.ProblemGuid;
      this.navLayoutService.isResultClicked = this.router.isActive(url2, true);
      let url3 = 'report/' + this.problem.ProblemGuid;
      this.navLayoutService.isReportClicked = this.router.isActive(url3, true);
      let unifiedModel: BpsUnifiedModel = JSON.parse(this.problem.UnifiedModel);
      if (unifiedModel && unifiedModel.AnalysisResult) this.configureService.sendMessage(true);
    });

   // this.deletePreviousArrowSubscription = this.navLayoutService.deletePreviousArrowSubject.subscribe(bool => {
    this.navLayoutService.deletePreviousArrowSubject.pipe(takeUntil(this.destroy$)).subscribe(bool => {
      this.previousRoute = null;
      this.showCrossButton = false;
    });

    this.configureService.computeClickedSubject.pipe(takeUntil(this.destroy$)).subscribe((isClicked) => {
      this.setComputeClickValidation(isClicked);
    });
    this.umService.computeClickedSubject.pipe(takeUntil(this.destroy$)).subscribe((isClicked) => {
      this.setComputeClickValidation(isClicked);
    });
    
    
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.SystemTable) {
          this.isSystemTableOpened = response.isOpened;
        }
      });  
    }

    setComputeClickValidation(isClicked: boolean): void {
      setTimeout(() => {
        this.isComputeClicked = isClicked;
        if (!isClicked && this.umService.current_UnifiedModel) this.umService.current_UnifiedModel.AnalysisResult = null;
      });
    }

  routeEvent(router: Router) {
    // this.routerSubscription = router.events.subscribe(e => {
    router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
      
      if (e instanceof NavigationEnd) {
        setTimeout(() => {
          let url1 = 'problemconfigure/' + this.problem.ProblemGuid;
          this.navLayoutService.isConfigureClicked = this.router.isActive(url1, true);
          let url2 = 'result/' + this.problem.ProblemGuid;
          this.navLayoutService.isResultClicked = this.router.isActive(url2, true);
          let url3 = 'report/' + this.problem.ProblemGuid;
          this.navLayoutService.isReportClicked = this.router.isActive(url3, true);
        });
      }
    });
  }
  
  logOut(): void {
    this.auth.logOut();
  }

  onNavigateToHome(): void {
    if (this.router.url.substr(1, 6) == "proble" || this.router.url.substr(1, 6) == "result" || this.router.url.substr(1, 6) == "report" || this.router.url.substr(1, 6) == "orders") {
      this.previousRoute = this.router.url;
      this.showCrossButton = true;
    }
    this.onCloseSystemTable();
    this.router.navigate(['home']);
    this.navLayoutService.changeNavBarButtonAndTitleVisibility(false);
  }

  onChangePlan(i: number) {
   // this.navLayoutService.plan = i;
  }

  onNavigateToConfigure() {
    this.navLayoutService.changeNavBarButtonAndTitleVisibility(true);
    this.configureService.configureCall = false;
    this.configureService.setProblemShow(this.problem.ProblemGuid);
    let unifiedModel: BpsUnifiedModel = JSON.parse(this.problem.UnifiedModel);
    if (unifiedModel && !unifiedModel.AnalysisResult)
      this.configureService.sendMessage(false);
    this.onCloseSystemTable();
    this.router.navigate(['/problemconfigure/', this.problem.ProblemGuid]);
  }

  onNavigateToResult() {
    this.onCloseSystemTable();
    this.router.navigate(['/result/', this.problem.ProblemGuid]);
  }

  onNavigateToReport() {
    this.onCloseSystemTable();
    this.router.navigate(['/report/', this.problem.ProblemGuid]);
  }
  onNavigateToOrders() {
    this.onSaveButtonClick();
    setTimeout(() => {
      this.router.navigate(['/orders/', this.problem.ProblemGuid]);
    }, 1000);
  }
  onSelectLoginOption(option: string): void {

  }

  ngOnDestroy() {
    // if (this.problemSubscription)
    //   this.problemSubscription.unsubscribe();
    // if (this.computeSubjectSubscription) {
    //   this.computeSubjectSubscription.unsubscribe();
    // }
    // if (this.routerSubscription) {
    //   this.routerSubscription.unsubscribe();
    // }
    // if (this.navBarSubscription)
    //   this.navBarSubscription.unsubscribe();
    // if (this.deletePreviousArrowSubscription)
    //   this.deletePreviousArrowSubscription.unsubscribe();

    if (this.eventURL$)
      this.eventURL$.unsubscribe()

      this.destroy$.next();
      this.destroy$.complete();
  }
  onSaveButtonClick() {
    if (!this.computeFormValidationBool || !this.umService.isProductTypeSelected()) {
      this.configureService.throwUnableToSaveNotificationSubject.next();
    }
    else {
      this.navLayoutService.showBlueSaveButton = true;
      this.configureService.saveProblemSubject.next(true);
      this.stService.saveSystemsSubject.next(true);
    }
  }

  onOpenFeedback() {
    let url = 'https://forms.monday.com/forms/44887157c1b129fb8576775045c141c6?r=use1'
    window.open(url, '_blank');
  }

  onDownloadButtonClick() {
    this.downloads
      .download(this.configureService.currentUnifiedModel.changingThisBreaksApplicationSecurity)
      .subscribe(blob => saveAs(blob, 'download.json'))
  }

  copyToClipboard() {
    const textarea = document.createElement('textarea');
    textarea.value = JSON.stringify(this.umService.current_UnifiedModel);
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  onComePreviousPage() {
    if (this.previousRoute) {
      let problemGuid = this.previousRoute.split(/[/ ]+/).pop();
      if (problemGuid) {
        this.configureService.configureCall = false;
        this.configureService.setProblemShow(problemGuid);
        this.configureService.sendMessage(false);
        this.previousRoute = null;
        this.showCrossButton = false;
        //this.router.navigate(['/problemconfigure/', problemGuid]);
        this.location.back();
      }
      this.previousRoute = null;
      this.showCrossButton = false;
    }
  }

  onChangeLanguage(language: string) {
    this.navLayoutService.language = language;
    this.homeService.setCurrentUserLanguage(this.navLayoutService.language).subscribe((lan) => {
      this.localStorageService.setValue<string>('culture', lan);
      this.translate.use(lan);
      setTimeout(() => {this.navLayoutService.languageIsChangedSubject.next();}, 200);
    });
    
  }

  onOpenCloseSystemTable() {
    if (this.isSystemTableOpened) {
      this.onCloseSystemTable();
    }
    else {
      this.cpService.setPopout(true, PanelsModule.SystemTable);
    }
  }

  onCloseSystemTable() {
    this.stService.onCloseTableFromNavBar();
  }
}
