import { Component, OnInit, Input, EventEmitter, ViewChild, TemplateRef, SimpleChanges, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { FramingService } from 'src/app/app-structural/services/framing.service';
import { CeldType, BpsTableComponent } from 'bps-components-lib';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';

@Component({
  selector: 'app-vent-table',
  templateUrl: './vent-table.component.html',
  styleUrls: ['./vent-table.component.css']
})

export class VentTableComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly articlesToBeHidden_missingThermalData = [ '367250','367260','367270','367290','466550','466560','466570' ];

  unified3DModel: UnifiedModel;

  systemSelected: any;
  adsSystemType: boolean = false;
  aseSystemType: boolean = false;


  //#region bpstable
  isPopoutOpened: boolean = false;
  baseImageSrc = 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/cross-section/';
  private destroy$ = new Subject<void>();
  selectedId: number;
  configurationCustomGrid: any;
  listOfDisplayData = [];
  data = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  searchValue = '';
  arrow_hovered = false;
  @ViewChild('uValueTemplate', { read: TemplateRef, static: true }) uValueTemplate: TemplateRef<{}>;
  @ViewChild('rwTemplate', { read: TemplateRef, static: true }) rwTemplate: TemplateRef<{}>;
  @ViewChild('bpsTable', { read: BpsTableComponent, static: false }) tableComponent: BpsTableComponent<{}>;
  //#endregion

  constructor(
    private translate: TranslateService,
    private fService: FramingService,
    private umService: UnifiedModelService,
    private cpService: ConfigPanelsService,
    private navLayoutService: NavLayoutService,
    private configService: ConfigureService
  ) {}

  ngAfterViewInit() {
    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
        }
      });
    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if ((this.umService.isWindow() && response.systemFor == 'FRAMING') || (this.umService.isFacade() && response.systemFor == 'OPERABILITY')) {
          let changeData: boolean = !this.systemSelected || (response && response.data && (response.data.Images != this.systemSelected.Images));
          this.systemSelected = response.data;
          if (changeData && this.systemSelected) this.collectData(this.systemSelected.Images);
        }
    });
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (this.unified3DModel !== undefined && response.panelsModule === PanelsModule.VentFrame) {
          if (response.isOpened) {
            this.onPopoutOpened();
            this.setConfigGrid();
          }
          this.isPopoutOpened = response.isOpened;
        }
      });
  }
  
  
  ngOnInit(): void {
    this.unified3DModel = this.umService.current_UnifiedModel;
    this.setConfigGrid();
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setConfigGrid();
    });

  }

  //#region data binding for HTML
  displayImg(): boolean {
    return this.listOfDisplayData.find(article => article.id == this.selectedId);
  }

  getImgSRC(): string {
    return this.baseImageSrc + this.listOfDisplayData.find(article => article.id == this.selectedId).description + '.jpg';
  }

  getArticleDescription(): string {
    return this.listOfDisplayData.find(article => article.id == this.selectedId).description;
  }
  //#end region

  //#region bpstable
  setConfigGrid() {
    this.configurationCustomGrid = {
      fields: [
        {
          celdType: CeldType.Default,
          display: this.translate.instant(_('configure.profile-description')),
          property: 'description',
          width: '180px',
          showSort: true
        },
        {
          celdType: CeldType.Default,
          property: 'inside',
          template: {
            ref: this.uValueTemplate,
            context: {}
          },
          width: '65px',
          showSort: true
        },
        {
          celdType: CeldType.Default,
          property: 'outside',
          width: '65px',
          template: {
            ref: this.rwTemplate,
            context: {}
          },
          showSort: true
        }
      ],
      fieldId: 'id'
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  preventDefault($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  onHover(index, value) {
    this.listOfDisplayData[index].outside.context.arrowHovered = value;
  }

  log($event) {
  }

  sort(sort: { sortName: string; sortValue: string }): void {
    this.sortName = sort.sortName;
    this.sortValue = sort.sortValue;
    this.search();
  }

  filter(value: string): void {
    this.searchValue = value;
    this.search();
  }

  search(): void {
    const filterFunc = (item: any) => {
      return item.description.indexOf(this.searchValue) !== -1;
    };
    const data = this.data.filter((item: { description: string; inside: string; outside: string }) => filterFunc(item));
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
      this.listOfDisplayData = this.listOfDisplayData.slice();
      this.getSelectedIndexAfterSearch();
    } else {
      this.listOfDisplayData = data;
      this.getSelectedIndexAfterSearch();
    }
  }
  //#endregion
  
  //#region build data
  onPopoutOpened() {
    if (!this.systemSelected) {
      this.systemSelected = this.cpService.currentSystem;
    }
    if (this.umService.isWindow()) {
      this.collectData(this.systemSelected.Images);
    }
    else if (this.umService.isFacadeMullionTransom()) {
      this.getSelectedIndex();
    }
    // else if (this.unified3DModel.ProblemSetting.ProductType == "Facade") {
    //   if (this.systemSelected.Images.toLowerCase().indexOf('fws') > -1)
    //     this.systemSelected.Images = 'aws__75_si_plus';
    //   this.collectData(this.systemSelected.Images);
    // }
    else if (this.unified3DModel.ProblemSetting.ProductType == "SlidingDoor") {
      this.collectData(this.systemSelected.Images);
    }
  }

  collectData(system: string) {
    if (system.includes("aws")  && (system.includes("75") || system.includes("90"))) {
      this.adsSystemType = false;
      this.aseSystemType = false;
      if (localStorage.getItem('vent_' + system)) {
        this.fillData(localStorage.getItem('vent_' + system));
      } else {
        this.fService.getVentFramesForSystem(system).pipe(takeUntil(this.destroy$)).subscribe(data => {
          localStorage.setItem('vent_' + system, data);
          this.fillData(data);
        });
      }
    }
    else if (system.includes("ads")) {
      this.adsSystemType = true;
      this.aseSystemType = false;
      if (localStorage.getItem('vent_' + system)) {
        this.fillData(localStorage.getItem('vent_' + system));
      } else {
        this.fService.getADSArticlesList(system).pipe(takeUntil(this.destroy$)).subscribe(data => {
          localStorage.setItem('vent_' + system, data);
          this.fillData(data);
        });
      }
    }
    else if (system.includes("ase")) {
      this.adsSystemType = false;
      this.aseSystemType = true;
      if (localStorage.getItem('vent_' + system)) {
        this.fillData(localStorage.getItem('vent_' + system));
      } else {
        this.fService.getASEArticlesList(system).pipe(takeUntil(this.destroy$)).subscribe(data => {
          localStorage.setItem('vent_' + system, data);
          this.fillData(data);
        });
      }
    }
  }

  fillData(dataList: any) {
    let data = JSON.parse(dataList);
    if (this.configService.applicationType == "SRS") {
      if (this.adsSystemType) {
        data = data.filter(x => x.Type == "VentFrame");
      }
      else if (this.aseSystemType){

      } 
      else {
        data = data.filter(x => x.Name == "article__466470"); // filtering out articles meant only for SRS aws.
      }
    }
    
    this.getTableData(data);
    setTimeout(() => {
      if (this.tableComponent && this.listOfDisplayData.find(article => article.id == this.selectedId)) {
        this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == this.selectedId));
        this.tableComponent.inputElement.nativeElement.value = '';
        this.searchValue = '';
        this.search();
      }
    }, 1);
  }

  getTableData(data: any[]) {
    this.data = [];
    let maxDepth = this.fService.getMaxDepth(this.unified3DModel);
    if (this.adsSystemType) {
      data.filter(article => this.isArticleToBeDisplay(article.ArticleName)).forEach((article, index) => {
        this.data.push({
          id: index,
          description: article.ArticleName,
          inside: article.InsideW.toString(),
          outside: article.OutsideW.toString(),
          distBetweenIsoBars: article.DistBetweenIsoBars,
          depth: article.Depth,
          disabled: false,
          bpsTable4CustomRow: false
        });
      });
    } 
    else if (this.aseSystemType) {
      data.filter(article => this.isArticleToBeDisplay(article.ArticleName) && article.System == this.systemSelected.Value && article.SRS == 1 && article.ProfileType == "Vent Frame").forEach((article, index) => {
        this.data.push({
          id: index,
          description: article.ArticleName,
          inside: article.InsideW.toString(),
          outside: article.OutsideW.toString(),
          distBetweenIsoBars: article.DistBetweenIsoBars,
          depth: article.Depth,
          disabled: false,
          bpsTable4CustomRow: false
        });
      });
    }
    else {
      data.filter(article => this.isArticleToBeDisplay(article.Name.substr(9))).forEach((article, index) => {
        this.data.push({
          id: index,
          description: article.Name.substr(9),
          inside: article.InsideDimension.toString(),
          outside: article.OutsideDimension.toString(),
          distBetweenIsoBars: article.DistBetweenIsoBars,
          depth: article.Depth,
          disabled: false,
          bpsTable4CustomRow: false
        });
      });
    }
    this.listOfDisplayData = this.data.slice();
    this.getSelectedIndex();
  }

  getSelectedIndexAfterSearch() {
    let savedData: any;
    if (this.unified3DModel.ProblemSetting.ProductType=='SlidingDoor'){
      if (this.unified3DModel.ModelInput.Geometry.Sections && this.unified3DModel.ModelInput.Geometry.Sections.length > 0) {
        savedData = this.unified3DModel.ModelInput.Geometry.Sections.filter(f => f.SectionID == 43 && f.SectionType == 43)[0];
      }
      if (savedData && this.listOfDisplayData.find(article => article.description == savedData.ArticleName)) {
        this.selectedId = this.listOfDisplayData.find(article => article.description == savedData.ArticleName).id;
        setTimeout(() => {
          if (this.selectedId > -1 && this.tableComponent) this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == this.selectedId));
        }, 100);    
      }
    }
    else {
      if (this.unified3DModel.ModelInput.Geometry.OperabilitySystems) {
        savedData = this.umService.isWindow() ? this.unified3DModel.ModelInput.Geometry.OperabilitySystems[0]:this.unified3DModel.ModelInput.Geometry.OperabilitySystems.filter(f => f.VentArticleName).find(f => f.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1);
      }
      if (savedData && this.listOfDisplayData.find(article => article.description == savedData.VentArticleName)) {
        this.selectedId = this.listOfDisplayData.find(article => article.description == savedData.VentArticleName).id;
        setTimeout(() => {
          if (this.selectedId > -1 && this.tableComponent) this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == this.selectedId));
        }, 100);    
      }
    }
  }

  getSelectedIndex() {
    let savedData: any;
    this.selectedId = 0;
    if (this.unified3DModel.ProblemSetting.ProductType=='SlidingDoor'){
      if (this.unified3DModel.ModelInput.Geometry.Sections && this.unified3DModel.ModelInput.Geometry.Sections.length > 0) {
        savedData = this.unified3DModel.ModelInput.Geometry.Sections.filter(f => f.SectionID == 43 && f.SectionType == 43)[0];
      }
      if (savedData && this.listOfDisplayData.find(article => article.description == savedData.ArticleName)) {
        this.selectedId = this.listOfDisplayData.find(article => article.description == savedData.ArticleName).id;
      }
      else {
        this.selectedId = 0;
      }
    }
    else {
      let setVentFrame: boolean = true;
      if (this.umService.isAnyInsertedUnit()) {
        savedData = this.umService.isWindow() ? this.unified3DModel.ModelInput.Geometry.OperabilitySystems[0]:this.unified3DModel.ModelInput.Geometry.OperabilitySystems.filter(f => f.VentArticleName).find(f => f.OperabilitySystemID == this.cpService.selectedOperabilityPicker + 1);
      }
      if (savedData && this.listOfDisplayData.find(article => article.description == savedData.VentArticleName)) {
        this.selectedId = this.listOfDisplayData.find(article => article.description == savedData.VentArticleName).id;
        setVentFrame = false;
      }
      else {
        this.selectedId = 0;
      }
      if (this.tableComponent) {
        setTimeout(() => {
          this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == this.selectedId));
          this.umService.set_VentFrame(this.listOfDisplayData.find(article => article.id == this.selectedId), this.cpService.selectedOperabilityPicker, setVentFrame);  
        }, 1500);
        if (this.unified3DModel.ProblemSetting.ProductType=='SlidingDoor') {
          this.umService.set_InterlockFrame(this.listOfDisplayData.find(article => article.id == this.selectedId));
        }    
      }  
    }
  }
  //#endregion
  
  //#region events
  selectionChange($event) { }

  onClose(): void {
    if (this.tableComponent) { this.tableComponent.inputElement.nativeElement.value = ''; }
    this.searchValue = '';
    // this.search();
    if (this.isPopoutOpened) this.cpService.setPopout(false, PanelsModule.VentFrame);
  }

  onclickRow(event) { //onSelectVentFrameArticle
    this.selectedId = parseInt(event.id);
    if (this.selectedId < 0) this.selectedId = 0;
  }

  ondblclickRow(event) { //onDblClickRow
    this.tableComponent.selectRow(event);
    this.onConfirm();
  }

  onConfirm() {
    setTimeout(() => {
      this.umService.set_VentFrame(this.listOfDisplayData.find(article => article.id == this.selectedId), this.cpService.selectedOperabilityPicker);  
    }, 200);
   
    if (this.unified3DModel.ProblemSetting.ProductType == 'SlidingDoor') {
      this.umService.set_InterlockFrame(this.listOfDisplayData.find(article => article.id == this.selectedId));
    }
    this.onClose();
  }
  //#endregion

  isArticleToBeDisplay(system: string): boolean {
    return !this.articlesToBeHidden_missingThermalData.includes(system);
  }

}