<div *ngIf="isStructuralTableOpened" class="table">
    <div class="table-container">
        <div class="top-container" [ngClass]="{'top-container-max-height': windLoadSelectedText != 'User Define'}">
            <form bps-form [formGroup]="validateForm" class="form">
                <bps-form-item class="bps-form-item" style="margin-bottom:15px;">
                    <bps-form-control>
                        <div class="sps-str-windload-heading">
                            <div class="heading">
                                <h3 class="white-heading">{{'configure.code-selection' | translate }}</h3>
                                <!-- Code Selection  -->
                                <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" class="cross-box"
                                    (click)="onCloseTable()">
                            </div>
                            <div>
                                <bps-select formControlName="codeSelection" style="width: 235px;"
                                    bpsPlaceHolder="{{'configure.select' | translate }}"
                                    (ngModelChange)="onWindLoadCalculationTypeSelected($event);">
                                    <!-- Select -->
                                    <bps-option bpsValue="DIN EN 1991-1-4" [bpsDisabled]="!configureService.isProjectInGermany()" bpsLabel="DIN EN 1991-1-4"></bps-option>
                                    <bps-option bpsValue="NF EN 1991-1-4" [bpsDisabled]="!configureService.isProjectInFrance()" bpsLabel="NF EN 1991-1-4"></bps-option>
                                    <bps-option bpsValue="SM" [bpsDisabled]="!(configureService.isProjectInFrance() && umService.isFacade())" bpsLabel="{{'configure.structural-table-simplified-method' | translate }}"></bps-option>
                                    <bps-option bpsValue="ASCE 7-16" bpsLabel="ASCE 7-16" bpsDisabled></bps-option>
                                    <bps-option bpsValue="User Define" bpsLabel="{{'configure.user-define' | translate }}"></bps-option>
                                </bps-select>
                            </div>
                        </div>
                    </bps-form-control>
                </bps-form-item>
                <div class="sps-break"></div>
                <div *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4' || windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'">
                    <h3 class="blue-heading" style="margin-bottom: 23px;">{{'configure.site-information' | translate }}</h3>
                    <!-- Site Information -->
                    <div>
                        <div style="margin-bottom: 17px;">
                            <bps-switch [bpsDual]="true" [bpsDualValues]="dualValues" (bpsDualValuesChange)="onChangeSiteInformation($event)"></bps-switch>
                        </div>
                    </div>
                    <div *ngIf="siteInformation === 'zipeCode'" style="margin-top: 16px;">
                        <bps-form-item style="margin-bottom: 19px;">
                            <bps-form-control>
                                <div style="display:flex; justify-content: space-between;">
                                    <h3 class="white-heading">{{'configure.postcode' | translate }}</h3>
                                    <div style="height:22px" bps-popover [bpsPopoverPlacement]="'right'"
                                        [bpsPopoverType]="'variation_2'" [bpsPopoverContent]="postcodeContent">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                    </div>
                                </div>
                                <!-- Postcode -->
                                <input style="width: 100px" type="text" min=0 max=99 bps-input formControlName="postCode"
                                    maxlength="5" [(ngModel)]="postcodeValue"
                                    (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                                    (ngModelChange)="onChangePostCode();">
                            </bps-form-control>
                        </bps-form-item>
                        <div class="grey-rectangle">
                            <div class="row-1">
                                <div class="grey-text-rectangle">{{'configure.location' | translate }}:</div>
                                <!-- Location -->
                                <div class="location-rectangle">{{ locationDescription }}</div>
                            </div>
                            <div class="row-2">
                                <div class="grey-text-rectangle wind-zone">{{'configure.wind-zone' | translate }}: </div>
                                <!-- Wind Zone -->
                                <div class="white-text-rectangle" style="height: 45px;" *ngIf="locationDescription">{{ windZone == '5' ? ('configure.structural-table-guadeloupe' | translate):
                                                                                                                       windZone == '6' ? ('configure.structural-table-guyane' | translate):
                                                                                                                       windZone == '7' ? ('configure.structural-table-martinique' | translate):
                                                                                                                       windZone == '8' ? ('configure.structural-table-mayotte' | translate):
                                                                                                                       windZone == '9' ? ('configure.structural-table-la-reunion' | translate):
                                                                                                                       windZone }}</div>
                            </div>
                            <div class="row-3" style="height: 48px;">
                                <div class="grey-text-rectangle" >{{'configure.basic-wind-speed' | translate }}: </div>
                                <!-- Basic Wind Speed -->
                                <div *ngIf="speedMperS" style="display: flex; flex-wrap: nowrap; justify-content: flex-start; padding-top: 0px;">
                                    <h3 class="white-text-rectangle" style="height: 44px;">{{ speedMperS }}</h3>
                                    <h3 class="grey-text-rectangle">m/s ( </h3>
                                    <h3 class="white-text-rectangle" style="height: 44px;">{{ speedMperH }} </h3>
                                    <h3 class="grey-text-rectangle">mph )</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="siteInformation === 'windZone'" style="margin-top: 16px;">
                        <bps-form-item style="margin-bottom: 19px;">
                            <bps-form-control>
                                <div style="display:flex; justify-content: space-between;">
                                    <h3 class="white-heading">{{'configure.wind-zone' | translate }}</h3>
                                    <!-- Wind zone -->
                                    <div style="height:22px" bps-popover [bpsPopoverPlacement]="'right'"
                                        [bpsPopoverType]="'variation_2'" [bpsPopoverContent]="windZoneContent">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                    </div>
                                </div>
                                <bps-select [ngStyle]="{width: windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM' ? '110px':'80px'}" [(ngModel)]="windZone" formControlName="windZoneControl"
                                    (ngModelChange)="onWindZoneChange($event);"
                                    bpsPlaceHolder="{{'configure.select' | translate }}">
                                    <!-- Select -->
                                    <bps-option bpsValue="1" bpsLabel="{{'configure.wind-zone-label' | translate}} 1"></bps-option>
                                    <bps-option bpsValue="2" bpsLabel="{{'configure.wind-zone-label' | translate}} 2"></bps-option>
                                    <bps-option bpsValue="3" bpsLabel="{{'configure.wind-zone-label' | translate}} 3"></bps-option>
                                    <bps-option bpsValue="4" bpsLabel="{{'configure.wind-zone-label' | translate}} 4"></bps-option>
                                    <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="5" bpsLabel="{{'configure.structural-table-guadeloupe' | translate}}"></bps-option>
                                    <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="6" bpsLabel="{{'configure.structural-table-guyane' | translate}}"></bps-option>
                                    <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="7" bpsLabel="{{'configure.structural-table-martinique' | translate}}"></bps-option>
                                    <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4'" bpsValue="8" bpsLabel="{{'configure.structural-table-mayotte' | translate}}"></bps-option>
                                    <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="9" bpsLabel="{{'configure.structural-table-la-reunion' | translate}}"></bps-option>
                                </bps-select>
                            </bps-form-control>
                        </bps-form-item>
                        <div class="grey-rectangle-2">
                            <div class="row-3">
                                <div class="grey-text-rectangle" style="white-space: normal;">{{'configure.basic-wind-speed' | translate }}: </div>
                                <!-- Basic Wind Speed -->
                                <div style="display: flex; flex-wrap: nowrap; justify-content: flex-start;"
                                    [ngStyle]="{'padding-top': language === 'de-DE' ? '14px':'0px'}">
                                    <h3 class="white-text-rectangle" style="padding-bottom: 4px;">{{windZoneBWSMperS | number: '':language}}<span *ngIf="windZoneBWSMperS && windZoneBWSMpH" class="grey-text-rectangle grey-text-span"> m/s ( </span>{{windZoneBWSMpH | number: '':language}}<span *ngIf="windZoneBWSMperS && windZoneBWSMpH" class="grey-text-rectangle grey-text-span"> mph )</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="terrain-category-container" style="justify-content: space-between;">
                        <h3 class="white-heading">
                            {{'configure.terrain-category' | translate | lowercase}}
                            <!-- terrain category -->
                        </h3>
                        <div style="height:16px;" bpsType="variation-1" bps-popover [bpsPopoverPlacement]="'right'"
                            [bpsPopoverType]="'variation_1'"
                            [bpsPopoverContent]="windLoadSelectedText === 'DIN EN 1991-1-4' ? terrainCategoryContent_DIN:terrainCategoryContent_NF">
                            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                        </div>
                    </div>
                    <bps-form-item class="bps-form-item" style="margin-bottom: 15px;">
                        <bps-form-control>
                            <bps-select style="width: 235px" [(ngModel)]="terrainCategory" formControlName="terrainCategoryControl"
                                (ngModelChange)="calculateWindLoad();"
                                bpsPlaceHolder="{{'configure.select' | translate }}">
                                <!-- Select -->
                                <!-- Terrain category -->
                                <bps-option *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" bpsValue="1" bpsLabel="{{'configure.terrain-category' | translate }} I"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" bpsValue="2" bpsLabel="{{'configure.terrain-category' | translate }} II"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" bpsValue="3" bpsLabel="{{'configure.terrain-category' | translate }} III"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" bpsDisabled bpsValue="4" bpsLabel="{{'configure.terrain-category' | translate }} IV"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" bpsValue="5" bpsLabel="{{'configure.terrain-category-II-III' | translate }}"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" bpsValue="6" bpsLabel="{{'configure.terrain-category-I-II' | translate }}"> </bps-option>
    
                                <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="1" bpsLabel="{{'configure.terrain-category' | translate }} 0"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="2" bpsLabel="{{'configure.terrain-category' | translate }} II"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="3" bpsLabel="{{'configure.terrain-category' | translate }} IIIa"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="4" bpsLabel="{{'configure.terrain-category' | translate }} IIIb"> </bps-option>
                                <bps-option *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'" bpsValue="5" bpsLabel="{{'configure.terrain-category' | translate }} IV"> </bps-option>
                            </bps-select>
                        </bps-form-control>
                    </bps-form-item>
                    <div class="sps-break"></div>
                    <div *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4' || windLoadSelectedText === 'NF EN 1991-1-4'; else buildingHeight_SM">
                        <h3 class="blue-heading" style="margin-bottom: 20px;">
                            {{'configure.building-information' | translate }}
                            <!-- Building Information -->
                        </h3>
                        <bps-form-item>
                            <bps-form-control>
                                <h3 class="white-heading">
                                    {{'configure.building-width' | translate }}<span style="text-transform: lowercase;"> (b)</span>
                                    <!-- Building Width -->
                                </h3>
                                <div class="input-container">
                                    <input style="width: 60px" bps-input type="text" formControlName="buildingWidth"
                                        [(ngModel)]="buildingWidthString"
                                        (ngModelChange)="onBuildingWidthChange();">
                                    <div>(m)</div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
                        <bps-form-item>
                            <bps-form-control>
                                <h3 class="white-heading">
                                    {{'configure.building-depth' | translate }}<span style="text-transform: lowercase;"> (d)</span>
                                    <!-- Building Depth -->
                                    <div style="height:16px; float: right;" bpsType="variation-1" bps-popover
                                        [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                        [bpsPopoverContent]="buildingwidthContent">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                    </div>
                                </h3>
                                <div class="input-container">
                                    <input style="width: 60px" bps-input type="text" formControlName="buildingDepth"
                                        [(ngModel)]="buildingDepthString"
                                        (ngModelChange)="onBuildingDepthChange();">
                                    <div>(m)</div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
                        <bps-form-item>
                            <bps-form-control>
                                <h3 class="white-heading">
                                    {{'configure.building-height' | translate }}<span style="text-transform: lowercase;"> (h)</span>
                                    <!-- Building Height -->
                                    <div style="height:16px; float: right;" bpsType="variation-1" bps-popover *ngIf="windLoadSelectedText === 'NF EN 1991-1-4' || windLoadSelectedText === 'SM'"
                                        [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                        [bpsPopoverContent]="buildingHeightContent_fr">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                    </div>
                                </h3>
                                <div class="input-container">
                                    <input style="width: 60px" bps-input type="text" formControlName="buildingHeight"
                                        [(ngModel)]="buildingHeightString"
                                        (ngModelChange)="onBuildingHeightChange();">
                                    <div>(m)</div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
                        <div class="sps-break"></div>
                        <h3 class="blue-heading" style="margin-bottom: 20px;"
                            *ngIf="umService.isFacade()">
                            {{'configure.facade-information' | translate }}
                            <!-- facade Information -->
                        </h3>
                        <h3 class="blue-heading" style="margin-bottom: 20px;"
                            *ngIf="umService.isWindow()">
                            {{'configure.window-information' | translate }}
                            <!-- Window Information -->
                        </h3>
                        <bps-form-item style="margin-bottom: 24px" *ngIf="umService.isWindow()">
                            <bps-form-control>
                                <!-- <h3 class="white-heading" *ngIf="unified3DModel.ProblemSetting.ProductType === 'Facade'">
                                    {{'configure.facade-elevation' | translate }}
                                    
                                </h3> -->
                                <h3 class="white-heading">
                                    {{'configure.window-elevation' | translate }}
                                    <!-- Window Elevation -->
                                </h3>
                                <div class="input-container">
                                    <input style="width: 60px" bps-input type="text" formControlName="windowElevation"
                                        [(ngModel)]="windowElevationString"
                                        (ngModelChange)="onWindowElevationChange();">
                                    <div>(m)</div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
    
                        <div style="margin-bottom: 10px">
                            <div class="window-zone-container">
                                <h3 class="white-heading">
                                    {{'configure.edge-zone-length' | translate }}
                                    <!--Edge Zone Length-->
                                </h3>
                            </div>
                            <div class="grey-rectangle" style="height:50px;">
                                <div class="row-3">
                                    <div class="grey-text-rectangle" style="white-space: normal;">
                                        a = {{edgeZoneLength}}<span *ngIf="edgeZoneLength">m</span> </div>
                                    <!-- Basic Wind Speed -->
                                    <!-- <div *ngIf="speedMperS"
                                        style="display: flex; flex-wrap: nowrap; justify-content: flex-start;"
                                        [ngStyle]="{'padding-top': language === 'en-US' ? '0px':'14px'}">
                                        <h3 class="white-text-rectangle">{{ speedMperS }}</h3>
                                        <h3 class="grey-text-rectangle">m/s ( </h3>
                                        <h3 class="white-text-rectangle">{{ speedMperH }} </h3>
                                        <h3 class="grey-text-rectangle">mph )</h3>
                                    </div> -->
                                </div>
                            </div>
                        </div>
    
                        <bps-form-item>
                            <bps-form-control>
                                <div class="window-zone-container" style="justify-content: space-between;">
                                    <h3 class="white-heading" *ngIf="umService.isFacade()">
                                        {{'configure.facade-zone' | translate }}
                                        <!-- facade Zone -->
                                    </h3>
                                    <h3 class="white-heading" *ngIf="umService.isWindow()">
                                        {{'configure.window-zone' | translate }}
                                        <!-- Window Zone -->
                                    </h3>
                                    <div style="height:16px;" bpsType="variation-1" bps-popover [bpsPopoverPlacement]="'right'"
                                        [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="windowZoneContent">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                    </div>
                                </div>
                                <bps-select style="width: 235px" formControlName="windowZone" [(ngModel)]="windowZone"
                                    (ngModelChange)="calculateWindLoad();"
                                    bpsPlaceHolder="{{'configure.select' | translate }}">
                                    <!-- Select -->
                                    <bps-option bpsValue="1" bpsLabel="{{'configure.edge-zone-1' | translate }}">
                                    </bps-option>
                                    <!-- Edge zone -->
                                    <bps-option bpsValue="2" bpsLabel="{{'configure.middle-zone-2' | translate }}">
                                    </bps-option>
                                    <!-- Middle Zone -->
                                </bps-select>
                                <div style="margin-bottom: 24px;">
                                    <div class="sps-break"></div>
                                    <div style="display:flex;justify-content: space-between;" class="switch-heading">                
                                        <h3 [ngStyle]="{'color':internalPressure?'#00a2d1':'#ffffff'}">
                                        {{'configure.internal-pressure' | translate | uppercase}}</h3>
                                    <!-- HORIZONTAL LIVE LOAD -->
                                    <bps-switch [(ngModel)]="internalPressure" formControlName="internalPressureControl" (ngModelChange)="onInternalPressureChange()"></bps-switch>
                                    </div>
                                    <div *ngIf="internalPressure" style="margin-top:20px;">
                                    <bps-form-item>
                                        <bps-form-control>
                                            <div class="window-zone-container" style="justify-content: space-between;margin-top:0px;">
                                    
                                                <h3 class="white-heading">
                                                {{'configure.positive' | translate | uppercase}}
                                                </h3>
                                                <div style="height:16px" bps-popover [bpsPopoverPlacement]="'right'"  bpsType="variation-1"
                                                [bpsPopoverType]="'variation_3'" [bpsPopoverContent]="positiveInfo">
                                                <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                                                </div>
                                            </div> 
                                        <div class="input-container">
                                            <input style="width: 60px" bps-input type="text" formControlName="positiveInternalPressureControl"
                                                [(ngModel)]="positiveInternalPressure"
                                                (ngModelChange)="onPositiveInternalPressureChange();">
                                        </div>
                                        </bps-form-control>
                                    </bps-form-item>
                                    <bps-form-item>
                                        <bps-form-control>
                                        <h3 class="white-heading">
                                            {{'configure.negative' | translate | uppercase}}
                                        </h3>
                                        <div class="input-container">
                                            <input style="width: 60px" bps-input type="text" formControlName="negativeInternalPressureControl"
                                                [(ngModel)]="negativeInternalPressure"
                                                (ngModelChange)="onNegativeInternalPressureChange();">
                                        </div>
                                        </bps-form-control>
                                    </bps-form-item>
                                    </div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
    
                    </div>
                </div>
                <div *ngIf="windLoadSelectedText === 'User Define'">
                    <bps-form-item style="margin-bottom: 0px;">
                        <bps-form-control>
                            <h3 class="white-heading">
                                {{'configure.structural-table-peak-velocity-pressure' | translate | uppercase}} 
                                <!-- <span style="text-transform: lowercase;">q<sub>p</sub></span>(z) -->
                                <span style="text-transform: lowercase;">(q<sub>p</sub>)</span>
                                <!-- Building Width -->
                            </h3>
                            <div class="input-container">
                                <input style="width: 60px" bps-input type="text" formControlName="packVelocityPressureControl"
                                    [(ngModel)]="packVelocityPressureString"
                                    (ngModelChange)="onVelocityPressureChange();">
                                <div>(kN/m²)</div>
                            </div>
                        </bps-form-control>
                    </bps-form-item>
                    <div class="sps-break"></div>
                    <h3 class="blue-heading" style="margin-bottom: 20px;">{{'configure.structural-table-pressure-coefficient' | translate}} (<span
                            style="text-transform: lowercase;">c<sub>p</sub></span>)
                        <div style="height:16px; float: right;" bpsType="variation-1" bps-popover
                            [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                            [bpsPopoverContent]="pressureContent">
                            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                        </div>
                    </h3>
                    <bps-form-item>
                      <bps-form-control>
                        <h3 class="white-heading">
                          {{'configure.structural-table-pressure-content-pressure' | translate}}
                          <!-- Building Width -->
                        </h3>
                        <div class="input-container">
                          <input style="width: 60px" bps-input type="text" formControlName="pressureControl"
                                 [(ngModel)]="pressureString" (ngModelChange)="onPressureChange();">
                        </div>
                      </bps-form-control>
                    </bps-form-item>
                    <bps-form-item>
                        <bps-form-control>
                            <h3 class="white-heading">
                                {{'configure.structural-table-pressure-content-suction' | translate}}
                                <!-- Building Width -->
                            </h3>
                            <div class="input-container">
                                <input style="width: 60px" bps-input type="text" formControlName="suctionControl"
                                    [(ngModel)]="suctionString" (ngModelChange)="onSuctionChange();">
                            </div>
                        </bps-form-control>
                    </bps-form-item>
    
                </div>
            </form>
        </div>
        <div class="bottom-container shadow-line-up" *ngIf="windLoadSelectedText">
            <div class="confirm-btn-container">
                <div></div>
                <button style="width: 80px; margin: 19px 0px 18px;" bps-button bpsType="variation-4"
                    [disabled]="isConfirmBtnDisable()" (click)="onConfirm()">
                    {{'configure.confirm' | translate }}
                    <!-- Confirm -->
                </button>
            </div>
        </div>
    </div>
    <div class="overlay-panel-left"></div>
    <div class="overlay-panel-right"></div>
</div>

<ng-template #postcodeContent>
    <div style="width: 195px;" [innerHTML]="">
        {{ windLoadSelectedText === 'DIN EN 1991-1-4' ? ('configure.postCodeInfoDE' | translate):('configure.postCodeInfoFR' | translate) }} <br> {{'configure.postCodeInfoFormat' | translate}}
    </div>
</ng-template>

<ng-template #buildingwidthContent>
    <div style="width: 195px;">
        {{'configure.structural-table-building-width-content' | translate}}
    </div>
</ng-template>

<ng-template #buildingHeightContent_fr>
    <div style="width: 195px;">
        {{'configure.structural-table-building-height-content' | translate}}
    </div>
</ng-template>

<ng-template #windowZoneContent>
    <div style="width: 250px;">
        <img src="/assets/Images/tooltips/Group_6630.png" height="190"
            style="margin:10px; align-items: center;" />
        <!-- <div class="bps-popover-content-subtitle">
            {{'configure.structural-table-window-zone-content-subtitle' | translate}}
        </div> -->
        <div class="bps-popover-content-title" style="margin-top: 10px">
            {{'configure.description' | translate }}</div>
        <div class="bps-popover-content-subtitle" *ngIf="umService.isWindow()">
            {{'configure.window-description' | translate }}
        </div>
        <div class="bps-popover-content-subtitle" *ngIf="umService.isFacade()">
            {{'configure.facade-description' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #pressureContent>
    <div style="width: 220px;">
        {{'configure.wind-pressure' | translate}}: W<sub>e</sub> = q<sub>p</sub>(Z) x c<sub>p</sub>
        <br>
        {{'configure.structural-table-pressure-content-pressure-coefficient' |
        translate}}<sub>p</sub> {{'configure.structural-table-pressure-content-tributary-area' |
        translate}}
    </div>
</ng-template>

<ng-template #terrainCategoryContent_DIN>
    <div style="width: 295px;">
        <div class="bps-popover-content-title">{{'configure.terrain-category' | translate }} I</div>
        <!-- Terrain category -->
        <div class="bps-popover-content-subtitle">{{'configure.terrain-category-I-info' | translate
            }}</div>
        <!-- Open sea; lakes with a free surface of at least 5 km in wind direction; level, flat country without obstacles. -->
        <div class="bps-popover-content-title">{{'configure.terrain-category' | translate }} II
        </div>
        <div class="bps-popover-content-subtitle">{{'configure.terrain-category-II-info' | translate
            }}</div>
        <!-- Terrain with hedges, isolated farm steads, houses or trees, e. g. agricultural areas. -->
        <div class="bps-popover-content-title">{{'configure.terrain-category' | translate }} III
        </div>
        <div class="bps-popover-content-subtitle">{{'configure.terrain-category-III-info' |
            translate }}</div>
        <!-- Suburbs, industrial or commercial areas; forests/woods -->
        <div class="bps-popover-content-title" style="color: #666666">{{'configure.terrain-category'
            | translate }} IV</div>
        <div class="bps-popover-content-subtitle" style="color: #666666">
            {{'configure.terrain-category-IV-info' | translate }}</div>
        <!-- Urban areas in which at least 15% of the surface is covered with buildings of a mean height of more than 15 m. -->
        <div class="bps-popover-content-title">
            <!-- {{'configure.structure-table-urban-areas-popover-content' | translate}}</div> -->
            {{'configure.structural-table-urban-areas-popover-content' | translate}}</div>
        <div class="bps-popover-content-subtitle">
            {{'configure.structural-table-urban-areas-popover-content-subtitle' | translate}}</div>
        <!-- Inland regions, mixed profile of type II and Type III. -->
        <div class="bps-popover-content-title">
            {{'configure.structural-table-inland-regions-popover-content-title' | translate}}</div>
        <div class="bps-popover-content-subtitle">
            {{'configure.structural-table-inland-regions-popover-content-subtitle' | translate}}
        </div>
        <!-- Costal areas, mixed profile of type I and Type II. -->
    </div>
</ng-template>

<ng-template #terrainCategoryContent_NF>
    <div style="width: 380px;">
        <table>
            <tr class="blue-border table-heading-NF">
                <th colspan="2">
                    <span class="table-title">
                        {{'configure.terrain-category' | translate}}
                    </span>
                </th>
            </tr>
            <tr class="table-row-NF">
                <td class="blue-border table-row-NF-td-1">
                    <span class="table-category">0</span>
                </td>
                <td class="blue-border table-row-NF-td-2">
                    <span class="table-text">
                        {{'configure.terrain-category-NF-0-content' | translate}}
                    </span>
                </td>
            </tr>
            <tr class="table-row-NF">
                <td class="blue-border table-row-NF-td-1">
                    <span class="table-category">II</span>
                </td>
                <td class="blue-border table-row-NF-td-2">
                    <span class="table-text">
                        {{'configure.terrain-category-NF-II-content' | translate}}
                    </span>
                </td>
            </tr>
            <tr class="table-row-NF">
                <td class="blue-border table-row-NF-td-1">
                    <span class="table-category">IIIa</span>
                </td>
                <td class="blue-border table-row-NF-td-2">
                    <span class="table-text">
                        {{'configure.terrain-category-NF-IIIa-content' | translate}}
                    </span>
                </td>
            </tr>
            <tr class="table-row-NF">
                <td class="blue-border table-row-NF-td-1">
                    <span class="table-category">IIIb</span>
                </td>
                <td class="blue-border table-row-NF-td-2">
                    <span class="table-text">
                        {{'configure.terrain-category-NF-IIIb-content' | translate}}
                    </span>
                </td>
            </tr>
            <tr class="table-row-NF">
                <td class="blue-border table-row-NF-td-1">
                    <span class="table-category">IV</span>
                </td>
                <td class="blue-border table-row-NF-td-2">
                    <span class="table-text">
                        {{'configure.terrain-category-NF-IV-content' | translate}}
                    </span>
                </td>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #windZoneContent>
    <img *ngIf="windLoadSelectedText === 'DIN EN 1991-1-4'" src="https://cdn.zeplin.io/5c9e5adf57fbe3581e7083b2/assets/7d9c12e1-4e3a-4217-a77a-b977df41bdaf.png" alt="German map" />
    <img *ngIf="windLoadSelectedText != 'DIN EN 1991-1-4'" src="/assets/Images/tooltips/FR_Wind_Map.JPG" class="resize" />
</ng-template>

<ng-template #positiveInfo>
    <div style="width: 212px;" [innerHTML]="">
        {{'configure.positive-info' | translate}}
    </div>
</ng-template>

<ng-template #buildingHeight_SM>
    <form bps-form [formGroup]="validateForm" class="form">
        <h3 class="blue-heading" style="margin-bottom: 20px;">
            {{'configure.building-information' | translate }}
            <!-- Building Information -->
        </h3>
        <bps-form-item>
            <bps-form-control>
                <h3 class="white-heading">
                    {{'configure.building-height' | translate }}<span style="text-transform: lowercase;"> (h)</span>
                    <div style="height:16px; float: right;" bpsType="variation-1" bps-popover
                                        [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                        [bpsPopoverContent]="buildingHeightContent_fr">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                    </div>
                </h3>
                <div class="input-container">
                    <input style="width: 60px" bps-input type="text" formControlName="buildingHeight"
                        [(ngModel)]="buildingHeightString"
                        (ngModelChange)="onBuildingHeightChange();">
                    <div>(m)</div>
                </div>
            </bps-form-control>
        </bps-form-item>
    </form>
</ng-template>