<div class="table-container" [ngClass]="{'hidden': !isUValueCalculationTableOpened}">
    <div class="top-container">
        <div class="selection-container">
            <div class="heading-container">
                <h3 class="heading"> {{'configure.code-selection' | translate }} </h3>
                <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" class="cross-box" (click)="onCloseTable()">
            </div>
            <bps-select style="width: 215px" [(ngModel)]="selectedValue" bpsPlaceHolder="Choose">
                <bps-option bpsValue="NF EN ISO 12631 : 2017" bpsLabel="{{'configure.nf-en-french-norm' | translate }}" [bpsDisabled]="!thermalService.isThermalCodeAllowed('France')"></bps-option> 
                <bps-option bpsValue="EN ISO 10077-1 : 2017" bpsLabel="EN ISO 10077-1 : 2017" [bpsDisabled]="!thermalService.isThermalCodeAllowed('Germany')"></bps-option>
            </bps-select>
        </div>
        <div class="sps-break" [ngClass]="{'hidden':selectedValue && selectedValue != 'NF EN ISO 12631 : 2017'}"></div>
        <form bps-form [formGroup]="validateForm">
            <div class="selection-container" [ngClass]="{'hidden':selectedValue && selectedValue != 'NF EN ISO 12631 : 2017'}">
                <h3 class="heading-blue">
                    {{'configure.thermal-coefficient-alpha' | translate }}
                </h3>
                <bps-form-item class="no-margin-bottom">
                    <bps-form-control>
                        <bps-switch [bpsDual]="true" [bpsDualValues]="dualCoefficientAlphaValues" (bpsDualValuesChange)="onChangeCoefficientAlpha($event)" formControlName="coeffAlphaSwitchControl" style="width: 160px; margin-bottom: 16px;" class="thermal-switch"></bps-switch>
                    </bps-form-control>
                </bps-form-item>
                <h3 class="heading" style="margin-bottom: 7px;">
                    {{'configure.thermal-alpha' | translate }}
                </h3>
                <bps-form-item class="no-margin-bottom">
                    <bps-form-control>
                        <input *ngIf="isCoefficientAlpha_manual_checked" style="width: 60px" bps-input type="number" min="0"  [(ngModel)]="coefficientAlphaInputValue" formControlName="coeffAlphaControl">
                        <input *ngIf="!isCoefficientAlpha_manual_checked" style="width: 60px; border-color: #363636!important;" bps-input disabled [(ngModel)]="coefficientAlphaInputValue" formControlName="coeffAlphaControl">
                    </bps-form-control>
                </bps-form-item>
            </div>
            <div class="sps-break" style="margin-bottom: 12px;" [ngClass]="{'hidden':selectedValue && selectedValue != 'NF EN ISO 12631 : 2017'}"></div>
            <div class="selection-container" [ngClass]="{'hidden':selectedValue && selectedValue != 'NF EN ISO 12631 : 2017'}">
                <div class="heading-container">
                    <h3 class="heading" [ngStyle]="{'color': isDeltaR ? '#00a2d1':'#ffffff'}">
                        {{'configure.thermal-additional-resistance' | translate }}
                    </h3>
                    <bps-form-item style="margin-bottom: -8px;">
                        <bps-form-control>
                            <bps-switch [(ngModel)]="isDeltaR" formControlName="deltaSwitchControl" style="margin-bottom: 7px;"></bps-switch>
                        </bps-form-control>
                    </bps-form-item>
                </div>
                <bps-form-item class="no-margin-bottom">
                    <bps-form-control>
                        <bps-switch [bpsDual]="true" [bpsDualValues]="dualAdditionalResistanceValues" (bpsDualValuesChange)="onChangeAdditionalResistance($event)" formControlName="dualAdditionalResistanceControl" style="width: 160px; margin-bottom: 16px;" [ngClass]="{'hidden': !isDeltaR}" class="thermal-switch"></bps-switch>
                    </bps-form-control>
                </bps-form-item>
                <div class="manual-coefficient-alpha" *ngIf="isAdditionalResistance_manual_checked" [ngClass]="{'hidden': !isDeltaR}">
                    <h3 class="heading" style="margin-bottom: 7px;">
                        {{'configure.thermal-delta-r' | translate }}
                    </h3>
                    <div class="input-and-description-container">
                        <bps-form-item class="no-margin-bottom">
                            <bps-form-control>
                                <input style="width: 60px" bps-input type="number" min="0" [(ngModel)]="deltaRInputValue" formControlName="deltaRInputControl">
                            </bps-form-control>
                        </bps-form-item>
                        <span class="delta-span">(m².K/W)</span>
                    </div>
                </div>
                <div class="list-coefficient-alpha" *ngIf="!isAdditionalResistance_manual_checked" [ngClass]="{'hidden': !isDeltaR}">
                    <h3 class="heading" style="margin-bottom: 7px;">
                        {{'configure.thermal-list-sun-shading' | translate }}
                    </h3>
                    <div class="input-and-arrow-container">
                        <bps-form-item class="no-margin-bottom">
                            <bps-form-control>
                                <input style="width: 170px; color: #fff!important;" bps-input disabled [(ngModel)]="sunShadingListArticle.description" formControlName="deltaRSelectionControl" placeholder="{{'configure.select' | translate}}" >
                            </bps-form-control>
                        </bps-form-item>
                        <button bps-button bpsType="variation-13" [ngClass]="{'active': isListOpened}" style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px" (click)="onOpenListPopout()">
                            <img alt="" style="vertical-align: unset;width:inherit; height: inherit;" src="/assets/Images/sps_round_icon_rightpanelopened_white.svg">
                        </button>
                    </div>
                </div>
            </div>
        </form>        
    </div>
    <div class="bottom-container shadow-line-up">
        <button style="width: 80px" bps-button bpsType="variation-4" (click)="onConfirm();onCloseTable();" [disabled]="!isPanelValid()">{{'configure.confirm' | translate }}</button>
    </div>    
</div>