import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef, ChangeDetectionStrategy, SimpleChanges, ElementRef, Renderer2, OnChanges, OnDestroy } from '@angular/core';
import { FramingService } from 'src/app/app-structural/services/framing.service';
import { CeldType, BpsTableComponent } from 'bps-components-lib';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { Feature } from 'src/app/app-core/models/feature';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
@Component({
  selector: 'app-mullion-table',
  templateUrl: './mullion-table.component.html',
  styleUrls: ['./mullion-table.component.css']
})

export class MullionTableComponent implements OnInit, OnDestroy {

  readonly articlesToBeHidden_missingThermalData = [ '367250','367260','367270','367290','466550','466560','466570' ];

  unified3DModel: UnifiedModel;
  @Output() openNewCustomEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() editCustomArticleEvent: EventEmitter<any> = new EventEmitter<any>();

  systemSelected: any;
  adsSystemType: boolean = false;

  //#region bpstable
  articleTitle: string;

  language: string;
  isMullionPopoutOpened: boolean = false;
  isTransomPopoutOpened: boolean = false;
  initialLength: number;
  selectedId: any;
  baseImageSrc = 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/cross-section/';
  //defaultImageSrc = 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/customArticle.svg';
  private destroy$ = new Subject<void>();
  configurationCustomGrid: any;
  listOfDisplayData = [];
  // listOfDisplayInData = [];
  data = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  searchValue = '';
  arrow_hovered = false;
  @ViewChild('uValueTemplate', { read: TemplateRef, static: true }) uValueTemplate: TemplateRef<{}>;
  @ViewChild('rwTemplate', { read: TemplateRef, static: true }) rwTemplate: TemplateRef<{}>;
  @ViewChild('outsideTemplate', { read: TemplateRef, static: true }) outsideTemplate: TemplateRef<{}>;
  @ViewChild('bpsTable', { read: BpsTableComponent, static: false }) tableComponent: BpsTableComponent<{}>;
  showSection: boolean = false;
  feature = Feature;
  //#endregion

  constructor(
    private umService: UnifiedModelService,
    private configService: ConfigureService,
    private cpService: ConfigPanelsService,
    private framingService: FramingService,
    private translate: TranslateService,
    private navLayoutService: NavLayoutService) {
  }

  ngAfterViewInit() {
    this.setConfigGrid();
    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
        }
      });

    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        let changeData: boolean = !this.systemSelected || (response && response.data && (response.data.Images != this.systemSelected.Images));
        this.systemSelected = response.data;
        if (changeData && this.systemSelected) this.collectData(this.systemSelected.Images);
      });

    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.Mullion) { 
          this.isMullionPopoutOpened = response.isOpened;
          if (response.isOpened) {
            this.onPopoutOpened();
            this.setConfigGrid();
          }
        }
        else if (response.panelsModule === PanelsModule.Transom) {
          this.isTransomPopoutOpened = response.isOpened;
          if (response.isOpened) {
            this.onPopoutOpened();
            this.setConfigGrid();
          }         
        }
      });
  }

  ngOnInit(): void {
    this.language = this.configService.getLanguage();
    this.unified3DModel = this.umService.current_UnifiedModel;
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setConfigGrid();
    });
  }
  //#region bpstable
  setConfigGrid() {
    this.configurationCustomGrid = {
      fields: [
        {
          celdType: CeldType.Default,
          display: this.translate.instant(_('configure.profile-description')),
          property: 'description',
          width: '180px',
          showSort: true
        },
        {
          celdType: CeldType.Default,
          property: 'inside',
          template: {
            ref: this.uValueTemplate,
            context: {}
          },
          width: '65px',
          showSort: true
        },
        {
          celdType: CeldType.TemplateRef,//CeldType.Default,
          property: 'outside',
          width: '65px',
          template: {
            ref: this.rwTemplate,
            context: {}
          },
          showSort: true
        }
      ],
      fieldId: 'id'
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  preventDefault($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  onHover(index, value) {
    if (this.listOfDisplayData.find(article => article.id == index).outside !== undefined) {
      this.listOfDisplayData.find(article => article.id == index).outside.context.arrowHovered = value;
    }
  }

  log($event) {
  }

  sort(sort: { sortName: string; sortValue: string }): void {
    this.sortName = sort.sortName;
    this.sortValue = sort.sortValue;
    this.search();
  }

  filter(value: string): void {
    this.searchValue = value;
    this.search();
  }
  
  displayImg(): boolean {
    return this.listOfDisplayData.find(article => article.id == this.selectedId);
  }

  getImgSRC(): string {
    if (this.listOfDisplayData.find(article => article.id == this.selectedId).bpsTable4CustomRow) {
      return 'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/article-jpeg/customArticle.svg';
    }
    else {
      return this.baseImageSrc + this.listOfDisplayData.find(article => article.id == this.selectedId).description + '.jpg';
    }
  }

  getArticleDescription(): string {
    return this.listOfDisplayData.find(article => article.id == this.selectedId).description;
  }

  search(): void {
    const filterFunc = (item: any) => {
      return item.description.indexOf(this.searchValue) !== -1;
    };
    const data = this.data.filter((item: { description: string; inside: string; outside: string }) => filterFunc(item));
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
      this.listOfDisplayData = this.listOfDisplayData.slice();
    } else {
      this.listOfDisplayData = data;
    }
    this.getSelectedIdAfterSearch();
  }
  //#endregion

  //#region build data
  onPopoutOpened() {
    if (!this.systemSelected) {
      this.systemSelected = this.cpService.currentSystem;
    }
    this.selectedId = 0;
    this.listOfDisplayData = [];
    this.data = [];
    if(this.systemSelected) this.collectData(this.systemSelected.Images);
  }
  collectData(system: string) {
    this.showSection = false;
    if (localStorage.getItem(system)) {
      if (system.includes("ads")) {
        this.adsSystemType = true;
      } else {
        this.adsSystemType = false;
      }
      this.fillData(localStorage.getItem(system));
    } else {
      if (system.includes("aws") && (system.includes("75")|| system.includes("90"))) {
        this.framingService.getMullionFramesForSystem(system).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.adsSystemType = false;
          localStorage.setItem(system, data);
          this.fillData(data);
        });
      } else {
        this.framingService.getADSArticlesList("ADS").pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.adsSystemType = true;
          localStorage.setItem(system, data);
          this.fillData(data);
        });
      }
    }
  }

  fillData(dataList: any) {
    let data = JSON.parse(dataList);
    this.showSection = true;
    if (this.configService.applicationType == "SRS") {
      if (this.adsSystemType) {
        data = data.filter(x => x.Type === 'MullionTransom');
      } else {
        let arr = ["article__368650", "article__368660", "article__382280"]
        data = data.filter(x => arr.includes(x.Name)); // filtering out articles meant only for SRS aws.
      }
    }

    this.getTableData(data);
    setTimeout(() => {
      if (this.tableComponent && this.listOfDisplayData.find(article => article.id == this.selectedId)) {
        this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == this.selectedId));
        this.tableComponent.inputElement.nativeElement.value = '';
        this.searchValue = '';
      }
    }, 1);
  }

  updateCurrentValue_MullionTransom(_article) {
    this.collectData(this.systemSelected.Images);
  }

  getTableData(mdata: any[]) {
    if (this.unified3DModel.ModelInput.Geometry.Sections) {
      var secOne = this.unified3DModel.ModelInput.Geometry.Sections[1];
      var secTwo = this.unified3DModel.ModelInput.Geometry.Sections[2];
      if (secOne && secOne.isCustomProfile) {
        secOne.Name = "Transom " + secOne.ArticleName;
        secOne.Description = secOne.ArticleName;
        secOne.InsideDimension = secOne.InsideW;
        secOne.OutsideDimension = secOne.OutsideW;
        secOne.Depth = secOne.Depth;
       // mdata.push(secOne);
        mdata.push({Name: "Transom " + secOne.ArticleName, Description: secOne.ArticleName, InsideDimension: secOne.InsideW, OutsideDimension: secOne.OutsideW, isCustomProfile: true, sectionElement: {...secOne} });
      }
      if (secTwo && secTwo.isCustomProfile) {
        if (!(secOne.A2 === secTwo.A2 && secOne.Ao === secTwo.Ao && secOne.ArticleName === secTwo.ArticleName && secOne.Au === secTwo.Au && secOne.Cn20 === secTwo.Cn20 && secOne.Cp20 === secTwo.Cp20 &&
          secOne.Cp80 === secTwo.Cp80 && secOne.DistBetweenIsoBars === secTwo.DistBetweenIsoBars && secOne.E === secTwo.E && secOne.InsideW === secTwo.InsideW && secOne.Io === secTwo.Io &&
          secOne.Ioyy === secTwo.Ioyy && secOne.Iu === secTwo.Iu && secOne.Iuyy === secTwo.Iuyy && secOne.LeftRebate === secTwo.LeftRebate && secOne.OutsideW === secTwo.OutsideW && secOne.RSn20 === secTwo.RSn20 &&
          secOne.RSp80 === secTwo.RSp80 && secOne.RTn20 === secTwo.RTn20 && secOne.RTp80 === secTwo.RTp80 && secOne.RightRebate === secTwo.RightRebate &&
          secOne.Weight === secTwo.Weight && secOne.Zol === secTwo.Zol && secOne.Zoo === secTwo.Zoo && secOne.Zor === secTwo.Zor && secOne.Zou === secTwo.Zou && secOne.Zul === secTwo.Zul && secOne.Zuo === secTwo.Zuo &&
          secOne.Zur === secTwo.Zur && secOne.Zuu === secTwo.Zuu && secOne.alpha === secTwo.alpha && secOne.beta === secTwo.beta && secOne.d === secTwo.d)) {
          secTwo.Name = "Transom " + secTwo.ArticleName;
          secTwo.Description = secTwo.ArticleName;
          secTwo.InsideDimension = secTwo.InsideW;
          secTwo.OutsideDimension = secTwo.OutsideW;
          secTwo.Depth = secTwo.Depth;
          //mdata.push(secTwo);
          mdata.push({Name: "Transom " + secTwo.ArticleName, Description: secTwo.ArticleName, InsideDimension: secTwo.InsideW, OutsideDimension: secTwo.OutsideW, isCustomProfile: true, sectionElement: {...secTwo} });
        }
      }
    }
    this.initialLength = mdata.length;
    this.data = [];
    
    if (this.adsSystemType) {
      mdata.filter(article => this.isArticleToBeDisplay(article.isCustomProfile ? article.Description : article.ArticleName) && article.isCustomProfile).forEach((article, index) => {
        this.data.push({
          id: '' + index,
          description: article.isCustomProfile ? article.Description : article.ArticleName,
          inside: article.InsideW ? article.InsideW.toString():'',
          outside: {
            ref: this.outsideTemplate,
            context: {
              value: article.OutsideW ? article.OutsideW.toString():'',
              disabled: false,
              arrowHovered: false,
              showArrow: article.isCustomProfile,
              index: index
            }
          },
          data: article,
          disabled: false,
          bpsTable4CustomRow: article.isCustomProfile
        });
      });
    } else {
      if(mdata && mdata.length > 0) {
        mdata.filter(article => this.isArticleToBeDisplay(article.isCustomProfile ? article.Description ? article.Description: article.ArticleName : article.Description ? article.Description.substr(8): article.ArticleName)).forEach((article, index) => {
          this.data.push({
            id: '' + index,
            description: article.isCustomProfile ? article.Description ? article.Description: article.ArticleName : article.Description ? article.Description.substr(8): article.ArticleName,
            inside: article.InsideDimension ? article.InsideDimension.toString():article.InsideW? article.InsideW.toString(): '0',
            outside: {
              ref: this.outsideTemplate,
              context: {
                value: article.OutsideDimension ? article.OutsideDimension.toString():article.OutsideW? article.OutsideW.toString():'0',
                disabled: false,
                arrowHovered: false,
                showArrow: article.isCustomProfile,
                index: index
              }
            },
            data: {...article},
            disabled: false,
            bpsTable4CustomRow: article.isCustomProfile,
            sectionElement: {...article.sectionElement}
          });
        });
      }      
    }
    this.listOfDisplayData = this.data.slice();
    this.listOfDisplayData.forEach(ele => {
      if(ele.bpsTable4CustomRow === true) {
        let mullioncustomdata = this.unified3DModel.ModelInput.Geometry.Sections.filter(sec => sec.SectionID === 2 && sec.SectionType === 2)[0];
        let transomcustomdata = this.unified3DModel.ModelInput.Geometry.Sections.filter(sec => sec.SectionID === 3 && sec.SectionType === 3)[0];

        if(ele.description.toString() === mullioncustomdata.ArticleName.toString()){
          ele.sectionElement = mullioncustomdata;
        }
        if(ele.description.toString() === transomcustomdata.ArticleName.toString()){
          ele.sectionElement = transomcustomdata;
        }
      }
    });
    if (this.isMullionPopoutOpened || this.isTransomPopoutOpened)
      this.getSelectedId();
    else 
      this.getMullionAndTransomSelectedIdsWithPopoutClosed();
  }

  getSelectedIdAfterSearch() {
    let savedData;
    if (this.isMullionPopoutOpened) {
      savedData = this.umService.obj_Mullion();
      if (savedData) {
        let articleToSelect = this.listOfDisplayData.find(article => {return article.description == savedData.ArticleName });
        this.selectedId = articleToSelect ? articleToSelect.id:-1;
      }
      setTimeout(() => {
        if (this.selectedId > -1 && this.tableComponent) this.tableComponent.selectRow(this.listOfDisplayData.find(article => {return article.id == this.selectedId}));
      }, 100);  
    }
    else if (this.isTransomPopoutOpened) {
      savedData = this.umService.obj_Transom();
      if (savedData) {
        let articleToSelect = this.listOfDisplayData.find(article => {return article.description == savedData.ArticleName });
        this.selectedId = articleToSelect ? articleToSelect.id:-1;
      }
      setTimeout(() => {
        if (this.selectedId > -1 && this.tableComponent) this.tableComponent.selectRow(this.listOfDisplayData.find(article => {return article.id == this.selectedId}));
      }, 100);  
    }
  }


  getSelectedId() {
    let savedData;
    if (this.isMullionPopoutOpened) savedData = this.umService.obj_Mullion();
    if (this.isTransomPopoutOpened) savedData = this.umService.obj_Transom();
    this.selectedId = savedData && this.listOfDisplayData.find(article => article.description == savedData.ArticleName) ? this.listOfDisplayData.find(article => article.description == savedData.ArticleName).id:0;
    let sec = this.unified3DModel.ModelInput.Geometry.Sections;
    if (this.isMullionPopoutOpened && sec && sec.filter(glass => glass.SectionID == 2 && glass.Depth == 0).length > 0) {
      this.onConfirm();
    }
    if (this.isTransomPopoutOpened && sec && sec.filter(glass => glass.SectionID == 3 && glass.Depth == 0).length > 0) {
      this.onConfirm();
    }
  }

  /**
   * Get the (default) selected id of the mullion and transom articles
   * And confirm the selections
   */
  getMullionAndTransomSelectedIdsWithPopoutClosed() {
    let savedData_mullion;
    let savedData_transom;
    savedData_mullion = this.umService.obj_Mullion();
    savedData_transom = this.umService.obj_Transom();
    let selectedId_mullion = 0;
    let selectedId_transom = 0;
    selectedId_mullion = savedData_mullion && this.listOfDisplayData.find(article => article.description == savedData_mullion.ArticleName) ? this.listOfDisplayData.find(article => article.description == savedData_mullion.ArticleName).id:0;
    selectedId_transom = savedData_mullion && this.listOfDisplayData.find(article => article.description == savedData_transom.ArticleName) ? this.listOfDisplayData.find(article => article.description == savedData_transom.ArticleName).id:0;
    let sec = this.unified3DModel.ModelInput.Geometry.Sections;
    if (sec && sec.filter(glass => glass.SectionID == 2).length > 0 && this.listOfDisplayData.length > 0) {
      if (!this.listOfDisplayData.find(article => article.id == selectedId_mullion).bpsTable4CustomRow)
        this.umService.set_Mullion({ article: this.listOfDisplayData.find(article => article.id == selectedId_mullion), isCustomed: false });
      else
        this.umService.set_Mullion({ article: this.listOfDisplayData.find(article => article.id == selectedId_mullion), isCustomed: true });
    }
    if (sec && sec.filter(glass => glass.SectionID == 3).length > 0 && this.listOfDisplayData.length > 0) {
      if (!this.listOfDisplayData.find(article => article.id == selectedId_transom).bpsTable4CustomRow)
        this.umService.set_Transom({ article: this.listOfDisplayData.find(article => article.id == selectedId_transom), isCustomed: false });
      else
        this.umService.set_Transom({ article: this.listOfDisplayData.find(article => article.id == selectedId_transom), isCustomed: true });
    }
  }
  //#endregion

  //#region events
  selectionChange($event) { }

  onClose(): void {
    if (this.tableComponent) { this.tableComponent.inputElement.nativeElement.value = ''; }
    this.searchValue = '';
    this.search();
    if (this.isMullionPopoutOpened) this.cpService.setPopout(false, PanelsModule.Mullion);
    if (this.isTransomPopoutOpened) this.cpService.setPopout(false, PanelsModule.Transom);
  }

  onclickRow(event) { //onSelectOuterFrameArticle
    this.selectedId = parseInt(event.id);
    if (this.selectedId < 0)
      this.selectedId = 0;
  }

  ondblclickRow(event) { //onDblClickRow
    this.tableComponent.selectRow(event);
    this.onConfirm();
    this.onClose();
  }

  onConfirm() {
    if (this.isMullionPopoutOpened && this.listOfDisplayData.length > 0) {
      if (!this.listOfDisplayData.find(article => article.id == this.selectedId).bpsTable4CustomRow)
        this.umService.set_Mullion({ article: this.listOfDisplayData.find(article => article.id == this.selectedId), isCustomed: false });
      else
        this.umService.set_Mullion({ article: this.listOfDisplayData.find(article => article.id == this.selectedId), isCustomed: true });
    }
    else if (this.listOfDisplayData.length > 0) {
      if (!this.listOfDisplayData.find(article => article.id == this.selectedId).bpsTable4CustomRow)
        this.umService.set_Transom({ article: this.listOfDisplayData.find(article => article.id == this.selectedId), isCustomed: false });
      else
        this.umService.set_Transom({ article: this.listOfDisplayData.find(article => article.id == this.selectedId), isCustomed: true });
    }
  }
  //#endregion

  onOpenCustom(): void {
    this.openNewCustomEvent.emit();
  }

  onEditCustom(index: number): void {
    this.editCustomArticleEvent.emit({ article: this.listOfDisplayData.find(article => article.id == index), index: index });
  }

  deleteArticle(articleIndex) {
    this.listOfDisplayData = this.listOfDisplayData.filter(article => article.id != articleIndex);
    this.selectedId = this.listOfDisplayData.find(article => article.id == this.selectedId) ? this.listOfDisplayData.find(article => article.id == this.selectedId).id:Math.max.apply(null, this.listOfDisplayData.map(article => article.id).slice());
    if (this.tableComponent) this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == this.selectedId));
    this.data = this.data.filter(article => article.id != articleIndex);
    this.listOfDisplayData = this.data.slice();
  }

  addMullionTransomArticle(sectionElement) {
    // this.listOfDisplayInData.push(sectionElement);
    this.listOfDisplayData.push(sectionElement);
    let index = this.data.length;
    this.data.push({
      id: '' + index,
      description: sectionElement.ArticleName,
      inside: sectionElement.InsideW,
      outside: {
        ref: this.outsideTemplate,
        context: {
          value: sectionElement.OutsideW,
          disabled: false,
          arrowHovered: false,
          showArrow: sectionElement.isCustomProfile,
          index: index
        }
      },
      data: {...sectionElement},
      disabled: false,
      bpsTable4CustomRow: sectionElement.isCustomProfile,
      sectionElement: {...sectionElement}
    });
    this.listOfDisplayData = this.data.slice();
    setTimeout(() => {
      this.tableComponent.selectRow(this.listOfDisplayData.find(article => article.id == index));
    });
  }

  updateMullionTransomArticle(data) {
    let index = data.index;
    let property = data.sectionElement;
    this.listOfDisplayData.find(article => article.id == index).sectionElement = {...property};
    //this.listOfDisplayData.find(article => article.id == index) = property;
    this.listOfDisplayData.find(article => article.id == index).description = property.ArticleName;
    this.listOfDisplayData.find(article => article.id == index).data.Description = property.ArticleName;
    this.listOfDisplayData.find(article => article.id == index).data.InsideDimension = property.InsideW;
    this.listOfDisplayData.find(article => article.id == index).data.OutsideDimension = property.OutsideW;
  }

  closeMullionTransomArticle(data) {
    let index = data.index;
    let property = data.sectionElement;
    if (this.listOfDisplayData.find(article => article.id == index) != undefined) {
      this.listOfDisplayData.find(article => article.id == index).sectionElement = {...property};
      //this.listOfDisplayData.find(article => article.id == index).description = property.description;
      this.listOfDisplayData.find(article => article.id == index).data.Description = property.ArticleName;
      this.listOfDisplayData.find(article => article.id == index).data.InsideDimension = property.InsideW;
      this.listOfDisplayData.find(article => article.id == index).data.OutsideDimension = property.OutsideW;
    }
  }

  isArticleToBeDisplay(system: string): boolean {
    return !this.articlesToBeHidden_missingThermalData.includes(system);
  }
}
