<div *ngIf="umService && umService.current_UnifiedModel" nzType="flex" nzjustify="center" class="accordion-section-container"
    id="config-accodrdion-container">
    <div id="config-accordion">
        <div class="accordion-section" style="width: 215px;" id="config-operability">
            <div class="column-container" id="sps-operability">
                <div class="dot-picker-operability-container" id="sps-dot-picker-operability-container"
                    style="margin-top: 5px;">
                    <div class="addGlassAndPanel" id="sps-add-button-operability">
                        <button bps-tooltip [bpsTooltipType]="'variation_10'"
                            [disabled]="orderPlaced || isAddBtnDisable"
                            bpsTooltipTitle="{{'configure.add-window-configuration' | translate }}"
                            bpsTooltipPlacement="top" [ngStyle]="{'width': language === 'en-US' ? '60px' : '90px'}"
                            style="padding: 0px;" bps-button bpsType="variation-7"
                            (click)="onAddPicker()">{{'configure.add' | translate | uppercase}}</button>
                    </div>
                    <div class="dot-picker-container" id="sps-dot-picker-doc-container-operability">
                        <div class="dot-picker-dot">
                            <bps-radio-group [(ngModel)]="selectedPickerString" (ngModelChange)="onSelectPicker()">
                                <label bps-radio bpsValue="0" bpsRadioButtonType="variation9"
                                    [bpsDisabled]="pickers[0].status === 'unpopulated'"></label>
                                <label bps-radio bpsValue="1" bpsRadioButtonType="variation9"
                                    [bpsDisabled]="pickers[1].status === 'unpopulated'"></label>
                                <label bps-radio bpsValue="2" bpsRadioButtonType="variation9"
                                    [bpsDisabled]="pickers[2].status === 'unpopulated'"></label>
                                <label bps-radio bpsValue="3" bpsRadioButtonType="variation9"
                                    [bpsDisabled]="pickers[3].status === 'unpopulated'"></label>
                                <!-- <label bps-radio bpsValue="4" bpsRadioButtonType="variation9"
                                    [bpsDisabled]="pickers[4].status === 'unpopulated'" style="width: 16px;"></label> -->
                            </bps-radio-group>
                        </div>
                    </div>
                </div>
                <div class="sps-break"></div>
                <div class="operability-fixed-opening-container">
                    <div class="operability-fixed-opening-column">
                        <ng-container *appCheckPermissions="feature.Window && (feature.SingleDoor || feature.DoubleDoor)">
                            <h3 class="operability-selection-heading" id="operability-fixed-opening-heading"
                            [ngClass]="{'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced }">
                            WINDOW / DOOR TYPE</h3>
                        </ng-container>
                        <ng-container *appCheckPermissions="feature.Window && !(feature.SingleDoor || feature.DoubleDoor)">
                            <h3 class="operability-selection-heading" id="operability-fixed-opening-heading"
                            [ngClass]="{'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced }">
                            {{'configure.fixed-or-opening' | translate
                            }}</h3>
                        <!-- Fixed / opening -->
                        </ng-container>
                        
                        <div class="operability-fixed-opening-boxes" *ngIf="!orderPlaced">
                            <bps-radio-group [(ngModel)]="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening"
                                style="display: flex;margin-left:-0.1px;"
                                (ngModelChange)="onVentOpeningDirectionChange($event)">
                                <label style="width: 102.5px" bps-radio-button bpsValue="Inward"
                                    bpsRadioButtonType="variation3"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1">
                                    {{'configure.inward' | translate }}
                                    <!-- Inward -->
                                </label>
                                <label style="width: 102.5px; margin-right: 0px !important;" bps-radio-button
                                    bpsValue="Outward" bpsRadioButtonType="variation3"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker === -1 || umService.isWindow() || configureService.isProjectInFrance()">
                                    {{'configure.outward' | translate }}
                                    <!-- Outward -->
                                </label>
                            </bps-radio-group>
                        </div>
                        <div class="operability-fixed-opening-boxes" *ngIf="orderPlaced">
                            <bps-radio-group [(ngModel)]="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening"
                                style="display: flex;margin-left:-0.1px;"
                                (ngModelChange)="onVentOpeningDirectionChange($event)">
                                <label style="width: 102.5px;border: 1px solid #666 !important;" bps-radio-button bpsValue="Inward"
                                    bpsRadioButtonType="variation3"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced">
                                    {{'configure.inward' | translate }}
                                    <!-- Inward -->
                                </label>
                                <label style="width: 102.5px; margin-right: 0px !important;" bps-radio-button
                                    bpsValue="Outward" bpsRadioButtonType="variation3"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker === -1 || umService.isWindow()">
                                    {{'configure.outward' | translate }}
                                    <!-- Outward -->
                                </label>
                            </bps-radio-group>
                        </div>
                    </div>
                </div>
                <div class="operable-type-container" style="margin-left:-0.1px;">
                    <div class="operable-type-column">
                        <h3 class="operability-selection-heading" style="margin-top: 12px;"
                            id="operability-operable-type-heading"
                            [ngClass]="{'disabled': cpService.selectedOperabilityPicker === -1 || orderPlaced }">
                            {{'configure.operable-type' | translate }}
                            <span style="height:16px; float: right;" bpsType="variation-1" bps-popover
                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                                [bpsPopoverContent]="windowZoneContent1">
                                <img *ngIf="cpService.selectedOperabilityPicker !== -1 && !orderPlaced else greyImage2" class="iconI"
                                    src="/assets/Images/icons/sps_information_icon_blue.svg">
                                <ng-template #greyImage2>
                                    <img class="iconI" src="/assets/Images/icons/sps_information_icon_grey.svg">
                                </ng-template>
                            </span>
                            <ng-template #windowZoneContent1>
                                <div class="popover-variation3" style="width: 205px;">
                                    {{'configure.operable-type-icons-text' | translate}}
                                    <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                        height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                        <defs>
                                            <style>
                                                .prefix__cls-x1,
                                                .prefix__cls-x4 {
                                                    fill: none
                                                }

                                                .prefix__cls-x1 {
                                                    stroke: #fff
                                                }

                                                .prefix__cls-x3 {
                                                    stroke: none
                                                }
                                            </style>
                                        </defs>
                                        <g id="prefix__sps_rectangle_icon_sidehung_white"
                                            transform="translate(-765 -500)" style="fill: none !important;">
                                            <g id="prefix__Rectangle_1334" class="prefix__cls-x1"
                                                data-name="Rectangle 1334" style="fill: none !important;">
                                                <path d="M0 0H25V30H0z" class="prefix__cls-x3"
                                                    transform="translate(765 500)" style="fill: none !important;" />
                                                <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x4"
                                                    transform="translate(765 500)" style="fill: none !important;" />
                                            </g>
                                            <g id="prefix__Rectangle_1335" class="prefix__cls-x1"
                                                data-name="Rectangle 1335" style="fill: none !important;">
                                                <path d="M0 0H21V26H0z" class="prefix__cls-x3"
                                                    transform="translate(767 502)" style="fill: none !important;" />
                                                <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x4"
                                                    transform="translate(767 502)" style="fill: none !important;" />
                                            </g>
                                            <!-- <path id="prefix__Path_929" d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321" data-name="Path 929" transform="translate(-45 20141.7)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                            <path id="prefix__Path_929"
                                                d="M831.175-19628.764L812.621-19641l18.555-12.312" data-name="Path 929"
                                                transform="translate(-44 20156)"
                                                style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                        </g>
                                    </svg>
                                    {{'configure.direction-opening-text' | translate}}
                                </div>
                            </ng-template>
                        </h3>
                        <div>
                            <bps-radio-group [(ngModel)]="pickers[max(cpService.selectedOperabilityPicker, 0)].operableType"
                                (ngModelChange)="onOperableTypeChange($event)">
                                <ng-container *appCheckPermissions="feature.TiltTurn">
                                    <label *ngIf="isTiltTurnLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                        style="width: 220px" bps-radio-button bpsValue="tiltTurn-right"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" bpsRadioButtonType="variation5">
                                        <span>{{'configure.tilt-or-turn-right-handed' | translate }}</span>
                                        <!-- Tilt/Turn Right-->
                                        <div class="labelSVG">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25" height="30" viewBox="0 0 25 30"
                                                (click)="onChangeTiltTurn()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-t1,
                                                        .prefix__cls-t2,
                                                        .prefix__cls-t3,
                                                        .prefix__cls-t4 {
                                                            fill: none;
                                                            stroke: #fff;
                                                        }
                                    
                                                        .prefix__cls-t2 {
                                                            stroke-width: 1.5px
                                                        }
                                    
                                                        .prefix__cls-t3 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                    <g id="prefix__Rectangle_1324" class="prefix__cls-t1" data-name="Rectangle 1324">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-t3" transform="translate(765 455)" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t4" transform="translate(765 455)" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1325" class="prefix__cls-t1" data-name="Rectangle 1325">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-t3" transform="translate(767 457)" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t4" transform="translate(767 457)" />
                                                    </g>
                                                    <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                        class="prefix__cls-t2" data-name="Path 921" transform="translate(-45 20099)" />
                                                    <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                        class="prefix__cls-t2" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                </g>
                                            </svg>
                                    
                                            <!-- SVG is not in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-t111,
                                                        .prefix__cls-t211,
                                                        .prefix__cls-t311,
                                                        .prefix__cls-t411 {
                                                            fill: none;
                                                            stroke: #00a2d1;
                                                        }
                                    
                                                        .prefix__cls-t2 {
                                                            stroke-width: 1.5px
                                                        }
                                    
                                                        .prefix__cls-t3 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                    <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                    </g>
                                                    <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                        class="prefix__cls-t211" data-name="Path 921" transform="translate(-45 20099)" />
                                                    <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                        class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                </g>
                                            </svg>
                                    
                                            <!-- SVG is in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" viewBox="0 0 25 30"
                                                (click)="onChangeTiltTurn()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-t111,
                                                        .prefix__cls-t211,
                                                        .prefix__cls-t311,
                                                        .prefix__cls-t411 {
                                                            fill: none;
                                                            stroke: #fff;
                                                        }
                                    
                                                        .prefix__cls-t2 {
                                                            stroke-width: 1.5px
                                                        }
                                    
                                                        .prefix__cls-t3 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                    <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                    </g>
                                                    <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                        class="prefix__cls-t211" data-name="Path 921" transform="translate(-45 20099)" />
                                                    <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                        class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                </g>
                                            </svg>
                                    
                                        </div>
                                    </label>
                                    <ng-container *ngIf="isTurnTiltRightSelected">
                                        <label *ngIf="isTiltTurnLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px; border: 1px solid #666 !important" bps-radio-button bpsValue="tiltTurn-right"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>{{'configure.tilt-or-turn-right-handed' | translate }}</span>
                                            <!-- Tilt/Turn Right-->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25" height="30" viewBox="0 0 25 30"
                                                    (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t1,
                                                            .prefix__cls-t2,
                                                            .prefix__cls-t3,
                                                            .prefix__cls-t4 {
                                                                fill: none;
                                                                stroke: #fff;
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t1" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t3" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t4" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t1" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t3" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t4" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                            class="prefix__cls-t2" data-name="Path 921" transform="translate(-45 20099)" />
                                                        <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                            class="prefix__cls-t2" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t311,
                                                            .prefix__cls-t411 {
                                                                fill: none;
                                                                stroke: #00a2d1;
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-45 20099)" />
                                                        <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" viewBox="0 0 25 30"
                                                    (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t311,
                                                            .prefix__cls-t411 {
                                                                fill: none;
                                                                stroke: #fff;
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-45 20099)" />
                                                        <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                            </div>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!isTurnTiltRightSelected">
                                        <label *ngIf="isTiltTurnLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px;" bps-radio-button bpsValue="tiltTurn-right"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>{{'configure.tilt-or-turn-right-handed' | translate }}</span>
                                            <!-- Tilt/Turn Right-->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25" height="30" viewBox="0 0 25 30"
                                                    (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t1,
                                                            .prefix__cls-t2,
                                                            .prefix__cls-t3,
                                                            .prefix__cls-t4 {
                                                                fill: none;
                                                                stroke: #fff;
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t1" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t3" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t4" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t1" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t3" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t4" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                            class="prefix__cls-t2" data-name="Path 921" transform="translate(-45 20099)" />
                                                        <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                            class="prefix__cls-t2" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t311,
                                                            .prefix__cls-t411 {
                                                                fill: none;
                                                                stroke: #00a2d1;
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-45 20099)" />
                                                        <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" viewBox="0 0 25 30"
                                                    (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t311,
                                                            .prefix__cls-t411 {
                                                                fill: none;
                                                                stroke: #fff;
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M 812.621 -19641 L 831.175 -19628.764 l -18.555 12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-45 20099)" />
                                                        <path id="prefix__Path_925" d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                            </div>
                                        </label>
                                    </ng-container>
                                   
                                    <label *ngIf="isTiltTurnLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced "
                                        style="width: 220px" bps-radio-button bpsValue="tiltTurn-left"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" bpsRadioButtonType="variation5">
                                        <span>{{'configure.tilt-or-turn-left-handed' | translate }}</span>
                                        <!-- Tilt/Turn Left-->
                                        <div class="labelSVG">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25" height="30" viewBox="0 0 25 30"
                                                (click)="onChangeTiltTurn()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-t1,
                                                        .prefix__cls-t2,
                                                        .prefix__cls-t4 {
                                                            fill: none
                                                        }
                                    
                                                        .prefix__cls-t1,
                                                        .prefix__cls-t2 {
                                                            stroke: #fff
                                                        }
                                    
                                                        .prefix__cls-t2 {
                                                            stroke-width: 1.5px
                                                        }
                                    
                                                        .prefix__cls-t3 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                    <g id="prefix__Rectangle_1324" class="prefix__cls-t1" data-name="Rectangle 1324">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-t3" transform="translate(765 455)" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t4" transform="translate(765 455)" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1325" class="prefix__cls-t1" data-name="Rectangle 1325">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-t3" transform="translate(767 457)" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t4" transform="translate(767 457)" />
                                                    </g>
                                                    <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312" class="prefix__cls-t2"
                                                        data-name="Path 921" transform="translate(-44 20111)" />
                                                    <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031" class="prefix__cls-t2"
                                                        data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                </g>
                                            </svg>
                                            <!-- SVG is not in disabled state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-t111,
                                                        .prefix__cls-t211,
                                                        .prefix__cls-t411 {
                                                            fill: none
                                                        }
                                    
                                                        .prefix__cls-t111,
                                                        .prefix__cls-t211 {
                                                            stroke: #00a2d1;
                                                        }
                                    
                                                        .prefix__cls-t211 {
                                                            stroke-width: 1.5px
                                                        }
                                    
                                                        .prefix__cls-t311 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                    <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                    </g>
                                                    <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312" class="prefix__cls-t211"
                                                        data-name="Path 921" transform="translate(-44 20111)" />
                                                    <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031" class="prefix__cls-t211"
                                                        data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                </g>
                                            </svg>
                                    
                                            <!-- SVG is in disabled state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-t111,
                                                        .prefix__cls-t211,
                                                        .prefix__cls-t411 {
                                                            fill: none
                                                        }
                                    
                                                        .prefix__cls-t111,
                                                        .prefix__cls-t211 {
                                                            stroke: #fff;
                                                        }
                                    
                                                        .prefix__cls-t211 {
                                                            stroke-width: 1.5px
                                                        }
                                    
                                                        .prefix__cls-t311 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                    <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                    </g>
                                                    <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312" class="prefix__cls-t211"
                                                        data-name="Path 921" transform="translate(-44 20111)" />
                                                    <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031" class="prefix__cls-t211"
                                                        data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                </g>
                                            </svg>
                                    
                                        </div>
                                    </label>
                                    <ng-container *ngIf="isTurnTiltLeftSelected">
                                        <label *ngIf="isTiltTurnLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px; border: 1px solid #666 !important;" bps-radio-button bpsValue="tiltTurn-left"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>{{'configure.tilt-or-turn-left-handed' | translate }}</span>
                                            <!-- Tilt/Turn Left-->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25" height="30" viewBox="0 0 25 30"
                                                    (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t1,
                                                            .prefix__cls-t2,
                                                            .prefix__cls-t4 {
                                                                fill: none
                                                            }
                                    
                                                            .prefix__cls-t1,
                                                            .prefix__cls-t2 {
                                                                stroke: #fff
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t1" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t3" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t4" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t1" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t3" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t4" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                            class="prefix__cls-t2" data-name="Path 921" transform="translate(-44 20111)" />
                                                        <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031" class="prefix__cls-t2"
                                                            data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                                <!-- SVG is not in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t411 {
                                                                fill: none
                                                            }
                                    
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211 {
                                                                stroke: #00a2d1;
                                                            }
                                    
                                                            .prefix__cls-t211 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t311 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-44 20111)" />
                                                        <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                                <!-- SVG is in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t411 {
                                                                fill: none
                                                            }
                                    
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211 {
                                                                stroke: #fff;
                                                            }
                                    
                                                            .prefix__cls-t211 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t311 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-44 20111)" />
                                                        <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                            </div>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!isTurnTiltLeftSelected">
                                        <label *ngIf="isTiltTurnLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px;" bps-radio-button bpsValue="tiltTurn-left"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>{{'configure.tilt-or-turn-left-handed' | translate }}</span>
                                            <!-- Tilt/Turn Left-->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25" height="30" viewBox="0 0 25 30"
                                                    (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t1,
                                                            .prefix__cls-t2,
                                                            .prefix__cls-t4 {
                                                                fill: none
                                                            }
                                    
                                                            .prefix__cls-t1,
                                                            .prefix__cls-t2 {
                                                                stroke: #fff
                                                            }
                                    
                                                            .prefix__cls-t2 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t3 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t1" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t3" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t4" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t1" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t3" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t4" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                            class="prefix__cls-t2" data-name="Path 921" transform="translate(-44 20111)" />
                                                        <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031" class="prefix__cls-t2"
                                                            data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                                <!-- SVG is not in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t411 {
                                                                fill: none
                                                            }
                                    
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211 {
                                                                stroke: #00a2d1;
                                                            }
                                    
                                                            .prefix__cls-t211 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t311 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-44 20111)" />
                                                        <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                                <!-- SVG is in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25" height="30" viewBox="0 0 25 30"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isWindowOperableDisable" (click)="onChangeTiltTurn()">
                                                    <defs>
                                                        <style>
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211,
                                                            .prefix__cls-t411 {
                                                                fill: none
                                                            }
                                    
                                                            .prefix__cls-t111,
                                                            .prefix__cls-t211 {
                                                                stroke: #fff;
                                                            }
                                    
                                                            .prefix__cls-t211 {
                                                                stroke-width: 1.5px
                                                            }
                                    
                                                            .prefix__cls-t311 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="prefix__sps_rectangle_icon_tiltturn_white" transform="translate(-765 -455)">
                                                        <g id="prefix__Rectangle_1324" class="prefix__cls-t111" data-name="Rectangle 1324">
                                                            <path d="M0 0H25V30H0z" class="prefix__cls-t311" transform="translate(765 455)" />
                                                            <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-t411" transform="translate(765 455)" />
                                                        </g>
                                                        <g id="prefix__Rectangle_1325" class="prefix__cls-t111" data-name="Rectangle 1325">
                                                            <path d="M0 0H21V26H0z" class="prefix__cls-t311" transform="translate(767 457)" />
                                                            <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-t411" transform="translate(767 457)" />
                                                        </g>
                                                        <path id="prefix__Path_921" d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                            class="prefix__cls-t211" data-name="Path 921" transform="translate(-44 20111)" />
                                                        <path id="prefix__Path_925" d="M1751.787-19537.582l9.182-23.031 9.193 23.031"
                                                            class="prefix__cls-t211" data-name="Path 925" transform="translate(-983.477 20019.744)" />
                                                    </g>
                                                </svg>
                                    
                                            </div>
                                        </label>
                                    </ng-container>
                                </ng-container>
                                <ng-container *appCheckPermissions="feature.SideHung">
                                    <label
                                    *ngIf="isSideHungLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                    style="width: 220px" bps-radio-button bpsValue="sideHung-right"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced"
                                    bpsRadioButtonType="variation5">
                                    <span>{{'configure.side-hung-right-handed' | translate }}</span>
                                    <!-- Side-Hung -->
                                    <div class="labelSVG">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-x1,
                                                    .prefix__cls-x4 {
                                                        fill: none
                                                    }

                                                    .prefix__cls-x1 {
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-x3 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                transform="translate(-765 -500)" style="fill: none !important;">
                                                <g id="prefix__Rectangle_1334" class="prefix__cls-x1"
                                                    data-name="Rectangle 1334" style="fill: none !important;">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-x3"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x4"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                </g>
                                                <g id="prefix__Rectangle_1335" class="prefix__cls-x1"
                                                    data-name="Rectangle 1335" style="fill: none !important;">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-x3"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x4"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                </g>
                                                <path id="prefix__Path_929"
                                                    d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321"
                                                    data-name="Path 929" transform="translate(-45 20141.7)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                <!-- <path id="prefix__Path_929" d="M 831.175 -19628.764 L 812.621 -19641 l 18.555 -12.312" data-name="Path 929" transform="translate(-44 20156)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-x111,
                                                    .prefix__cls-x411 {
                                                        fill: none
                                                    }

                                                    .prefix__cls-x111 {
                                                        stroke: #00a2d1;
                                                    }

                                                    .prefix__cls-x311 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                transform="translate(-765 -500)" style="fill: none !important;">
                                                <g id="prefix__Rectangle_1334" class="prefix__cls-x111"
                                                    data-name="Rectangle 1334" style="fill: none !important;">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-x311"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x411"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                </g>
                                                <g id="prefix__Rectangle_1335" class="prefix__cls-x111"
                                                    data-name="Rectangle 1335" style="fill: none !important;">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-x311"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x411"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                </g>
                                                <path id="prefix__Path_929"
                                                    d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321"
                                                    class="prefix__cls-x111" data-name="Path 929"
                                                    transform="translate(-45 20141.7)" />
                                                <!-- <path id="prefix__Path_929" d="M 831.175 -19628.764 L 812.621 -19641 l 18.555 -12.312" data-name="Path 929" transform="translate(-44 20156)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                            </g>
                                        </svg>
                                    </div>
                                    </label>
                                    <label
                                    *ngIf="isSideHungLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                    style="width: 220px;border: 1px solid #666 !important;" bps-radio-button bpsValue="sideHung-right"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced"
                                    bpsRadioButtonType="variation5">
                                    <span>{{'configure.side-hung-right-handed' | translate }}</span>
                                    <!-- Side-Hung -->
                                    <div class="labelSVG">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-x1,
                                                    .prefix__cls-x4 {
                                                        fill: none
                                                    }

                                                    .prefix__cls-x1 {
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-x3 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                transform="translate(-765 -500)" style="fill: none !important;">
                                                <g id="prefix__Rectangle_1334" class="prefix__cls-x1"
                                                    data-name="Rectangle 1334" style="fill: none !important;">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-x3"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x4"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                </g>
                                                <g id="prefix__Rectangle_1335" class="prefix__cls-x1"
                                                    data-name="Rectangle 1335" style="fill: none !important;">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-x3"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x4"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                </g>
                                                <path id="prefix__Path_929"
                                                    d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321"
                                                    data-name="Path 929" transform="translate(-45 20141.7)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                <!-- <path id="prefix__Path_929" d="M 831.175 -19628.764 L 812.621 -19641 l 18.555 -12.312" data-name="Path 929" transform="translate(-44 20156)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-x111,
                                                    .prefix__cls-x411 {
                                                        fill: none
                                                    }

                                                    .prefix__cls-x111 {
                                                        stroke: #00a2d1;
                                                    }

                                                    .prefix__cls-x311 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                transform="translate(-765 -500)" style="fill: none !important;">
                                                <g id="prefix__Rectangle_1334" class="prefix__cls-x111"
                                                    data-name="Rectangle 1334" style="fill: none !important;">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-x311"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x411"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                </g>
                                                <g id="prefix__Rectangle_1335" class="prefix__cls-x111"
                                                    data-name="Rectangle 1335" style="fill: none !important;">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-x311"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x411"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                </g>
                                                <path id="prefix__Path_929"
                                                    d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321"
                                                    class="prefix__cls-x111" data-name="Path 929"
                                                    transform="translate(-45 20141.7)" />
                                                <!-- <path id="prefix__Path_929" d="M 831.175 -19628.764 L 812.621 -19641 l 18.555 -12.312" data-name="Path 929" transform="translate(-44 20156)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                            </g>
                                        </svg>
                                    </div>
                                    </label>
                                    <label
                                        *ngIf="isSideHungLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                        style="width: 220px" bps-radio-button bpsValue="sideHung-left"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced"
                                        bpsRadioButtonType="variation5">
                                        <span>{{'configure.side-hung-left-handed' | translate }}</span>
                                        <!-- Side-Hung -->
                                        <div class="labelSVG">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                                height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-x1,
                                                        .prefix__cls-x4 {
                                                            fill: none
                                                        }

                                                        .prefix__cls-x1 {
                                                            stroke: #fff
                                                        }

                                                        .prefix__cls-x3 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                    transform="translate(-765 -500)" style="fill: none !important;">
                                                    <g id="prefix__Rectangle_1334" class="prefix__cls-x1"
                                                        data-name="Rectangle 1334" style="fill: none !important;">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-x3"
                                                            transform="translate(765 500)" style="fill: none !important;" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x4"
                                                            transform="translate(765 500)" style="fill: none !important;" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1335" class="prefix__cls-x1"
                                                        data-name="Rectangle 1335" style="fill: none !important;">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-x3"
                                                            transform="translate(767 502)" style="fill: none !important;" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x4"
                                                            transform="translate(767 502)" style="fill: none !important;" />
                                                    </g>
                                                    <!-- <path id="prefix__Path_929" d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321" data-name="Path 929" transform="translate(-45 20141.7)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                                    <path id="prefix__Path_929"
                                                        d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                        data-name="Path 929" transform="translate(-44 20156)"
                                                        style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25"
                                                height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                                <defs>
                                                    <style>
                                                        .prefix__cls-x111,
                                                        .prefix__cls-x411 {
                                                            fill: none
                                                        }

                                                        .prefix__cls-x111 {
                                                            stroke: #00a2d1
                                                        }

                                                        .prefix__cls-x311 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                    transform="translate(-765 -500)" style="fill: none !important;">
                                                    <g id="prefix__Rectangle_1334" class="prefix__cls-x111"
                                                        data-name="Rectangle 1334" style="fill: none !important;">
                                                        <path d="M0 0H25V30H0z" class="prefix__cls-x311"
                                                            transform="translate(765 500)" style="fill: none !important;" />
                                                        <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x411"
                                                            transform="translate(765 500)" style="fill: none !important;" />
                                                    </g>
                                                    <g id="prefix__Rectangle_1335" class="prefix__cls-x111"
                                                        data-name="Rectangle 1335" style="fill: none !important;">
                                                        <path d="M0 0H21V26H0z" class="prefix__cls-x311"
                                                            transform="translate(767 502)" style="fill: none !important;" />
                                                        <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x411"
                                                            transform="translate(767 502)" style="fill: none !important;" />
                                                    </g>
                                                    <!-- <path id="prefix__Path_929" d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321" data-name="Path 929" transform="translate(-45 20141.7)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                                    <path id="prefix__Path_929"
                                                        d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                        class="prefix__cls-x111" data-name="Path 929"
                                                        transform="translate(-44 20156)" />
                                                </g>
                                            </svg>
                                        </div>
                                    </label>
                                    <label
                                    *ngIf="isSideHungLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                    style="width: 220px;border: 1px solid #666 !important;" bps-radio-button bpsValue="sideHung-left"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced"
                                    bpsRadioButtonType="variation5">
                                    <span>{{'configure.side-hung-left-handed' | translate }}</span>
                                    <!-- Side-Hung -->
                                    <div class="labelSVG">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-x1,
                                                    .prefix__cls-x4 {
                                                        fill: none
                                                    }

                                                    .prefix__cls-x1 {
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-x3 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                transform="translate(-765 -500)" style="fill: none !important;">
                                                <g id="prefix__Rectangle_1334" class="prefix__cls-x1"
                                                    data-name="Rectangle 1334" style="fill: none !important;">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-x3"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x4"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                </g>
                                                <g id="prefix__Rectangle_1335" class="prefix__cls-x1"
                                                    data-name="Rectangle 1335" style="fill: none !important;">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-x3"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x4"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                </g>
                                                <!-- <path id="prefix__Path_929" d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321" data-name="Path 929" transform="translate(-45 20141.7)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                                <path id="prefix__Path_929"
                                                    d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                    data-name="Path 929" transform="translate(-44 20156)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeSideHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-x111,
                                                    .prefix__cls-x411 {
                                                        fill: none
                                                    }

                                                    .prefix__cls-x111 {
                                                        stroke: #00a2d1
                                                    }

                                                    .prefix__cls-x311 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_sidehung_white"
                                                transform="translate(-765 -500)" style="fill: none !important;">
                                                <g id="prefix__Rectangle_1334" class="prefix__cls-x111"
                                                    data-name="Rectangle 1334" style="fill: none !important;">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-x311"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-x411"
                                                        transform="translate(765 500)" style="fill: none !important;" />
                                                </g>
                                                <g id="prefix__Rectangle_1335" class="prefix__cls-x111"
                                                    data-name="Rectangle 1335" style="fill: none !important;">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-x311"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-x411"
                                                        transform="translate(767 502)" style="fill: none !important;" />
                                                </g>
                                                <!-- <path id="prefix__Path_929" d="M 812.621 -19638.764 L 831.175 -19626.764 l -18.555 12.321" data-name="Path 929" transform="translate(-45 20141.7)" style="stroke-width:1.5px;stroke:#fff;fill:none" /> -->
                                                <path id="prefix__Path_929"
                                                    d="M831.175-19628.764L812.621-19641l18.555-12.312"
                                                    class="prefix__cls-x111" data-name="Path 929"
                                                    transform="translate(-44 20156)" />
                                            </g>
                                        </svg>
                                    </div>
                                </label>
                                </ng-container>
                                <ng-container *appCheckPermissions="feature.BottomHung">
                                    <label *ngIf="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward'"
                                    style="width: 220px" bps-radio-button bpsValue="bottomHung"
                                    [bpsDisabled]="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward' || cpService.selectedOperabilityPicker == -1 || orderPlaced"
                                    bpsRadioButtonType="variation5">
                                    <span>{{'configure.bottom-hung' | translate }}</span>
                                    <!-- Double Vent (SH/SH) -->
                                    <div style="margin-top: 4px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeBottomHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-g1,
                                                    .prefix__cls-g4 {
                                                        fill: none;
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-g1 {
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-g3 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_bottomhung_white"
                                                transform="translate(-765 -545)">
                                                <g id="prefix__Rectangle_1336" class="prefix__cls-g1"
                                                    data-name="Rectangle 1336">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-g3"
                                                        transform="translate(765 545)" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-g4"
                                                        transform="translate(765 545)" />
                                                </g>
                                                <g id="prefix__Rectangle_1337" class="prefix__cls-g1"
                                                    data-name="Rectangle 1337">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-g3"
                                                        transform="translate(767 547)" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-g4"
                                                        transform="translate(767 547)" />
                                                </g>
                                                <!-- <path id="prefix__Path_932" d="M 1769.787 -19561.582 l -9.182 23.031 -9.193 -23.031" data-name="Path 932" transform="translate(-983.477 20109.744)" style="stroke-width:1.5px;stroke:#fff;fill:none"/> -->
                                                <path id="prefix__Path_932"
                                                    d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                    data-name="Path 932" transform="translate(-983.477 20109.744)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                            </g>
                                        </svg>
                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeBottomHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-g111,
                                                    .prefix__cls-g411 {
                                                        fill: none;
                                                        stroke: #00a2d1
                                                    }

                                                    .prefix__cls-g111 {
                                                        stroke: #00a2d1
                                                    }

                                                    .prefix__cls-g311 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_bottomhung_white"
                                                transform="translate(-765 -545)">
                                                <g id="prefix__Rectangle_1336" class="prefix__cls-g111"
                                                    data-name="Rectangle 1336">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-g311"
                                                        transform="translate(765 545)" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-g411"
                                                        transform="translate(765 545)" />
                                                </g>
                                                <g id="prefix__Rectangle_1337" class="prefix__cls-g111"
                                                    data-name="Rectangle 1337">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-g311"
                                                        transform="translate(767 547)" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-g411"
                                                        transform="translate(767 547)" />
                                                </g>
                                                <path id="prefix__Path_932" d="M 1769.787 -19561.582 l -9.182 23.031 -9.193 -23.031" data-name="Path 932" transform="translate(-983.477 20109.744)" style="stroke-width:1.5px;stroke:#fff;fill:none"/>
                                                <path id="prefix__Path_932"
                                                    d="M 1751.787 -19537.582 l 9.182 -23.031 9.193 23.031"
                                                    class="prefix__cls-g111" data-name="Path 932"
                                                    transform="translate(-983.477 20109.744)" />
                                            </g>
                                        </svg> -->
                                    </div>
                                    </label>
                                </ng-container>
                                <ng-container *appCheckPermissions="feature.TopHung">
                                    <label *ngIf="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward'"
                                    style="width: 220px" bps-radio-button bpsValue="topHung"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced" bpsRadioButtonType="variation5">
                                    <span>{{'configure.top-hung' | translate }}</span>
                                    <!-- Double Vent (SH/SH) -->
                                    <div style="margin-top: 4px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="showWhiteHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeBottomHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-g1,
                                                    .prefix__cls-g4 {
                                                        fill: none;
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-g1 {
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-g3 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_bottomhung_white"
                                                transform="translate(-765 -545)">
                                                <g id="prefix__Rectangle_1336" class="prefix__cls-g1"
                                                    data-name="Rectangle 1336">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-g3"
                                                        transform="translate(765 545)" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-g4"
                                                        transform="translate(765 545)" />
                                                </g>
                                                <g id="prefix__Rectangle_1337" class="prefix__cls-g1"
                                                    data-name="Rectangle 1337">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-g3"
                                                        transform="translate(767 547)" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-g4"
                                                        transform="translate(767 547)" />
                                                </g>
                                                <path id="prefix__Path_932"
                                                    d="M 1769.787 -19561.582 l -9.182 23.031 -9.193 -23.031"
                                                    data-name="Path 932" transform="translate(-983.477 20109.744)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                            </g>
                                        </svg>
                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="showBlueHover" width="25"
                                            height="30" viewBox="0 0 25 30" (click)="onChangeBottomHung()">
                                            <defs>
                                                <style>
                                                    .prefix__cls-g111,
                                                    .prefix__cls-g411 {
                                                        fill: none;
                                                        stroke: #00a2d1
                                                    }

                                                    .prefix__cls-g111 {
                                                        stroke: #00a2d1
                                                    }

                                                    .prefix__cls-g311 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_bottomhung_white"
                                                transform="translate(-765 -545)">
                                                <g id="prefix__Rectangle_1336" class="prefix__cls-g111"
                                                    data-name="Rectangle 1336">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-g311"
                                                        transform="translate(765 545)" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-g411"
                                                        transform="translate(765 545)" />
                                                </g>
                                                <g id="prefix__Rectangle_1337" class="prefix__cls-g111"
                                                    data-name="Rectangle 1337">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-g311"
                                                        transform="translate(767 547)" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-g411"
                                                        transform="translate(767 547)" />
                                                </g>
                                                <path id="prefix__Path_932"
                                                    d="M 1769.787 -19561.582 l -9.182 23.031 -9.193 -23.031"
                                                    class="prefix__cls-g111" data-name="Path 932"
                                                    transform="translate(-983.477 20109.744)" />
                                            </g>
                                        </svg> -->
                                    </div>
                                    </label>
                                </ng-container>
                                <ng-container *appCheckPermissions="feature.ParallelOpening">
                                    <label
                                    *ngIf="umService.current_UnifiedModel.ProblemSetting.ProductType === 'Facade' && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward'"
                                    style="width: 220px" bps-radio-button bpsValue="parallelOpening"
                                    [bpsDisabled]="cpService.selectedOperabilityPicker == -1" bpsRadioButtonType="variation5">
                                    <span>{{'configure.parallel-opening' | translate }}</span>
                                    <!-- Parallel Opening -->
                                    <div style="margin-top: 4px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30">
                                            <defs>
                                                <style>
                                                    .prefix__cls-g1,
                                                    .prefix__cls-g4 {
                                                        fill: none;
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-g1 {
                                                        stroke: #fff
                                                    }

                                                    .prefix__cls-g3 {
                                                        stroke: none
                                                    }
                                                </style>
                                            </defs>
                                            <g id="prefix__sps_rectangle_icon_bottomhung_white"
                                                transform="translate(-765 -545)">
                                                <g id="prefix__Rectangle_1336" class="prefix__cls-g1"
                                                    data-name="Rectangle 1336">
                                                    <path d="M0 0H25V30H0z" class="prefix__cls-g3"
                                                        transform="translate(765 545)" />
                                                    <path d="M0.5 0.5H24.5V29.5H0.5z" class="prefix__cls-g4"
                                                        transform="translate(765 545)" />
                                                </g>
                                                <g id="prefix__Rectangle_1337" class="prefix__cls-g1"
                                                    data-name="Rectangle 1337">
                                                    <path d="M0 0H21V26H0z" class="prefix__cls-g3"
                                                        transform="translate(767 547)" />
                                                    <path d="M0.5 0.5H20.5V25.5H0.5z" class="prefix__cls-g4"
                                                        transform="translate(767 547)" />
                                                </g>
                                                <path id="prefix__Path_930" d="M 1761 -19562.582 l 0 -12.031"
                                                    data-name="Path 930" transform="translate(-983.477 20127.744)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                <path id="prefix__Path_931" d="M 1771.987 -19559.082 l -10 6.531 -10 -6.531"
                                                    data-name="Path 931" transform="translate(-984.477 20106.744)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                <path id="prefix__Path_932"
                                                    d="M 1771.1 -19520.582 l -10.182 -7.182 -10.031 7.031"
                                                    data-name="Path 932" transform="translate(-983.477 20092.744)"
                                                    style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                            </g>
                                        </svg>
                                    </div>
                                    </label>    
                                </ng-container>
                                <ng-container *appCheckPermissions="feature.SingleDoor">
                                    <label
                                        *ngIf="isSingleDoorLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                        style="width: 220px" bps-radio-button bpsValue="singleDoor-right"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                        bpsRadioButtonType="variation5">
                                        <span>Single Door - Right Handed</span>
                                        <!-- Single Door - Right Handed -->
                                        <div class="labelSVG">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showWhiteHover"
                                                (click)="onChangeSingleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-432765,
                                                        .cls-4-432765 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-432765 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-3-432765 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_singledoor_right_enabled" transform="translate(-1775 -915)">
                                                    <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-432765">
                                                        <path class="cls-3-432765" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                        <path class="cls-4-432765" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                    </g>
                                                    <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-432765">
                                                        <path class="cls-3-432765" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                        <path class="cls-4-432765" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                    </g>
                                                    <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1304.398 -9355.538)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG is not in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                (click)="onChangeSingleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-987654,
                                                        .cls-4-987654 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-987654 {
                                                            stroke: #00a2d1
                                                        }
                                
                                                        .cls-3-987654 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_singledoor_right_hover" transform="translate(-1775 -915)">
                                                    <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-987654">
                                                        <path class="cls-3-987654" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                        <path class="cls-4-987654" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                    </g>
                                                    <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-987654">
                                                        <path class="cls-3-987654" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                        <path class="cls-4-987654" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                    </g>
                                                    <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1304.398 -9355.538)"
                                                        style="stroke-width:1.5px;stroke:#00a2d1;fill:none" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG is in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                (click)="onChangeSingleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-987654,
                                                        .cls-4-987654 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-987654 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-3-987654 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_singledoor_right_hover" transform="translate(-1775 -915)">
                                                    <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-987654">
                                                        <path class="cls-3-987654" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                        <path class="cls-4-987654" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                    </g>
                                                    <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-987654">
                                                        <path class="cls-3-987654" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                        <path class="cls-4-987654" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                    </g>
                                                    <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1304.398 -9355.538)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                
                                        </div>
                                    </label>
                                    <ng-container *ngIf="isSingleDoorRightSelected">
                                        <label
                                            *ngIf="isSingleDoorLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px; border: 1px solid #666 !important;" bps-radio-button bpsValue="singleDoor-right"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Single Door - Right Handed</span>
                                            <!-- Single Door - Right Handed -->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30"
                                                    class="showWhiteHover" (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-432765,
                                                            .cls-4-432765 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-432765 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-432765 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_right_enabled" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-432765">
                                                            <path class="cls-3-432765" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-432765" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-432765">
                                                            <path class="cls-3-432765" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-432765" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1304.398 -9355.538)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-987654,
                                                            .cls-4-987654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-987654 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-3-987654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_right_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-987654" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-987654" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1304.398 -9355.538)"
                                                            style="stroke-width:1.5px;stroke:#00a2d1;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-987654,
                                                            .cls-4-987654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-987654 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-987654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_right_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-987654" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-987654" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1304.398 -9355.538)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                            </div>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!isSingleDoorRightSelected">
                                        <label
                                            *ngIf="isSingleDoorLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px;" bps-radio-button bpsValue="singleDoor-right"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Single Door - Right Handed</span>
                                            <!-- Single Door - Right Handed -->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30"
                                                    class="showWhiteHover" (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-432765,
                                                            .cls-4-432765 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-432765 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-432765 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_right_enabled" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-432765">
                                                            <path class="cls-3-432765" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-432765" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-432765">
                                                            <path class="cls-3-432765" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-432765" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1304.398 -9355.538)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-987654,
                                                            .cls-4-987654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-987654 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-3-987654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_right_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-987654" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-987654" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1304.398 -9355.538)"
                                                            style="stroke-width:1.5px;stroke:#00a2d1;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-987654,
                                                            .cls-4-987654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-987654 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-987654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_right_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-987654" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-987654">
                                                            <path class="cls-3-987654" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-987654" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1304.398 -9355.538)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                            </div>
                                        </label>
                                    </ng-container>
                                    <label *ngIf="isSingleDoorLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                        style="width: 220px" bps-radio-button bpsValue="singleDoor-left"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                        bpsRadioButtonType="variation5">
                                        <span>Single Door - Left Handed</span>
                                        <!-- Single Door - Left Handed -->
                                        <div class="labelSVG">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showWhiteHover"
                                                (click)="onChangeSingleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-759682,
                                                        .cls-4 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-759682 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-3-759682 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_singledoor_left_enabled" transform="translate(-1775 -915)">
                                                    <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-759682">
                                                        <path class="cls-3-759682" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                        <path class="cls-4-759682" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                    </g>
                                                    <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-759682">
                                                        <path class="cls-3-759682" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                        <path class="cls-4-759682" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                    </g>
                                                    <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG is not in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                (click)="onChangeSingleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-546879,
                                                        .cls-4-546879 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-546879 {
                                                            stroke: #00a2d1
                                                        }
                                
                                                        .cls-3-546879 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_singledoor_left_hover" transform="translate(-1775 -915)">
                                                    <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-546879">
                                                        <path class="cls-3-546879" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                        <path class="cls-4-546879" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                    </g>
                                                    <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-546879">
                                                        <path class="cls-3-546879" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                        <path class="cls-4-546879" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                    </g>
                                                    <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#00a2d1;fill:none" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG is in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                (click)="onChangeSingleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-546879,
                                                        .cls-4-546879 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-546879 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-3-546879 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_singledoor_left_hover" transform="translate(-1775 -915)">
                                                    <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-546879">
                                                        <path class="cls-3-546879" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                        <path class="cls-4-546879" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                    </g>
                                                    <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-546879">
                                                        <path class="cls-3-546879" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                        <path class="cls-4-546879" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                    </g>
                                                    <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                        </div>
                                    </label>
                                    <ng-container *ngIf="isSingleDoorLeftSelected">
                                        <label
                                            *ngIf="isSingleDoorLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px; border: 1px solid #666 !important" bps-radio-button bpsValue="singleDoor-left"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Single Door - Left Handed</span>
                                            <!-- Single Door - Left Handed -->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30"
                                                    class="showWhiteHover" (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-759682,
                                                            .cls-4 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-759682 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-759682 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_left_enabled" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-759682">
                                                            <path class="cls-3-759682" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-759682" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-759682">
                                                            <path class="cls-3-759682" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-759682" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-546879,
                                                            .cls-4-546879 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-546879 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-3-546879 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_left_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-546879" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-546879" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#00a2d1;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-546879,
                                                            .cls-4-546879 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-546879 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-546879 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_left_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-546879" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-546879" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!isSingleDoorLeftSelected">
                                        <label
                                            *ngIf="isSingleDoorLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px;" bps-radio-button bpsValue="singleDoor-left"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Single Door - Left Handed</span>
                                            <!-- Single Door - Left Handed -->
                                            <div class="labelSVG">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30"
                                                    class="showWhiteHover" (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-759682,
                                                            .cls-4 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-759682 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-759682 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_left_enabled" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-759682">
                                                            <path class="cls-3-759682" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-759682" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-759682">
                                                            <path class="cls-3-759682" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-759682" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-546879,
                                                            .cls-4-546879 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-546879 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-3-546879 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_left_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-546879" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-546879" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#00a2d1;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeSingleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-546879,
                                                            .cls-4-546879 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-546879 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-3-546879 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_singledoor_left_hover" transform="translate(-1775 -915)">
                                                        <g id="Rectangle_1338" data-name="Rectangle 1338" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h25v30H0z" transform="translate(1775 915)" />
                                                            <path class="cls-4-546879" d="M.5.5h24v29H.5z" transform="translate(1775 915)" />
                                                        </g>
                                                        <g id="Rectangle_1339" data-name="Rectangle 1339" class="cls-1-546879">
                                                            <path class="cls-3-546879" d="M0 0h21v26H0z" transform="translate(1777 917)" />
                                                            <path class="cls-4-546879" d="M.5.5h20v25H.5z" transform="translate(1777 917)" />
                                                        </g>
                                                        <path id="Path_933" data-name="Path 933" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20571)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </label>
                                    </ng-container>
                                </ng-container>
                                <ng-container *appCheckPermissions="feature.DoubleDoor">
                                    <label
                                        *ngIf="isDoubleDoorLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                        style="width: 220px" bps-radio-button bpsValue="doubleDoor-right"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                        bpsRadioButtonType="variation5">
                                        <span>Double Door (R - Active)</span>
                                        <!-- Double Door (R - Active) -->
                                        <div class="labelSVG">
                                            <!-- SVG when it is not in disabled state -->
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable" width="50" height="30"
                                                class="showWhiteHover" (click)="onChangeDoubleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-765432,
                                                        .cls-6-765432 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-765432 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-5-765432 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_left_enabled" transform="translate(-1750 -1005)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-765432">
                                                        <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1775 1005)" />
                                                        <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1775 1005)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-765432">
                                                        <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1750 1005)" />
                                                        <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1750 1005)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-765432">
                                                        <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1777 1007)" />
                                                        <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1777 1007)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" style="stroke:#03ff3f;fill:none">
                                                        <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1752 1007)" />
                                                        <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1752 1007)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20661)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1291.898 -9310.538)"
                                                        style="stroke-width:1.5px;stroke:#03ff3f;fill:none" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG when it is in disabled state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showWhiteHover"
                                                (click)="onChangeDoubleDoor()"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable">
                                                <defs>
                                                    <style>
                                                        .cls-1-765432,
                                                        .cls-6-765432 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-765432 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-5-765432 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_left_enabled" transform="translate(-1750 -1005)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-765432">
                                                        <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1775 1005)" />
                                                        <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1775 1005)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-765432">
                                                        <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1750 1005)" />
                                                        <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1750 1005)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-765432">
                                                        <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1777 1007)" />
                                                        <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1777 1007)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" style="stroke:#ffff;fill:none">
                                                        <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1752 1007)" />
                                                        <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1752 1007)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20661)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1291.898 -9310.538)" style="stroke-width:1.5px;stroke:#ffff;fill:none" />
                                                </g>
                                            </svg>
                                
                                
                                            <!-- SVG in non disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                (click)="onChangeDoubleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-486932,
                                                        .cls-2-486932,
                                                        .cls-4-486932 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-486932,
                                                        .cls-2-486932 {
                                                            stroke: #00a2d1
                                                        }
                                
                                                        .cls-2-486932 {
                                                            stroke-width: 1.5px
                                                        }
                                
                                                        .cls-3-486932 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                        <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                        <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                        <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                        <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" class="cls-2-486932"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                    <path id="Path_937" data-name="Path 937" class="cls-2-486932"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="rotate(180 1291.898 -9333.038)" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                (click)="onChangeDoubleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-486932,
                                                        .cls-2-486932,
                                                        .cls-4-486932 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-486932,
                                                        .cls-2-486932 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-2-486932 {
                                                            stroke-width: 1.5px
                                                        }
                                
                                                        .cls-3-486932 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                        <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                        <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                        <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-486932">
                                                        <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                        <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" class="cls-2-486932"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                    <path id="Path_937" data-name="Path 937" class="cls-2-486932"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="rotate(180 1291.898 -9333.038)" />
                                                </g>
                                            </svg>
                                        </div>
                                    </label>
                                    <ng-container *ngIf="isDoubleDoorRightSelected">
                                        <label
                                            *ngIf="isDoubleDoorLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px; border: 1px solid #666 !important" bps-radio-button bpsValue="doubleDoor-right"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Double Door (R - Active)</span>
                                            <!-- Double Door (R - Active) -->
                                            <div class="labelSVG">
                                                <!-- SVG when it is not in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable" width="50" height="30"
                                                    class="showWhiteHover" (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-765432,
                                                            .cls-6-765432 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-765432 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-765432 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_left_enabled" transform="translate(-1750 -1005)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1775 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1775 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1750 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1750 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1777 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1777 1007)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" style="stroke:#03ff3f;fill:none">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1752 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1752 1007)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20661)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9310.538)"
                                                            style="stroke-width:1.5px;stroke:#03ff3f;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG when it is in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showWhiteHover"
                                                    (click)="onChangeDoubleDoor()"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable">
                                                    <defs>
                                                        <style>
                                                            .cls-1-765432,
                                                            .cls-6-765432 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-765432 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-765432 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_left_enabled" transform="translate(-1750 -1005)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1775 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1775 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1750 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1750 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1777 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1777 1007)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" style="stroke:#ffff;fill:none">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1752 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1752 1007)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20661)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9310.538)"
                                                            style="stroke-width:1.5px;stroke:#ffff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                
                                                <!-- SVG in non disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-486932,
                                                            .cls-2-486932,
                                                            .cls-4-486932 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-486932,
                                                            .cls-2-486932 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-2-486932 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-486932 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable" 
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-486932,
                                                            .cls-2-486932,
                                                            .cls-4-486932 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-486932,
                                                            .cls-2-486932 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-2-486932 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-486932 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!isDoubleDoorRightSelected">
                                        <label
                                            *ngIf="isDoubleDoorLeft == false && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px;" bps-radio-button bpsValue="doubleDoor-right"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Double Door (R - Active)</span>
                                            <!-- Double Door (R - Active) -->
                                            <div class="labelSVG">
                                                <!-- SVG when it is not in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable" width="50" height="30"
                                                    class="showWhiteHover" (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-765432,
                                                            .cls-6-765432 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-765432 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-765432 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_left_enabled" transform="translate(-1750 -1005)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1775 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1775 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1750 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1750 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1777 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1777 1007)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" style="stroke:#03ff3f;fill:none">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1752 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1752 1007)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20661)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9310.538)"
                                                            style="stroke-width:1.5px;stroke:#03ff3f;fill:none" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG when it is in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showWhiteHover"
                                                    (click)="onChangeDoubleDoor()"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable">
                                                    <defs>
                                                        <style>
                                                            .cls-1-765432,
                                                            .cls-6-765432 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-765432 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-765432 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_left_enabled" transform="translate(-1750 -1005)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1775 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1775 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h25v30H0z" transform="translate(1750 1005)" />
                                                            <path class="cls-6-765432" d="M.5.5h24v29H.5z" transform="translate(1750 1005)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-765432">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1777 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1777 1007)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" style="stroke:#ffff;fill:none">
                                                            <path class="cls-5-765432" d="M0 0h21v26H0z" transform="translate(1752 1007)" />
                                                            <path class="cls-6-765432" d="M.5.5h20v25H.5z" transform="translate(1752 1007)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20661)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9310.538)"
                                                            style="stroke-width:1.5px;stroke:#ffff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                
                                                <!-- SVG in non disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-486932,
                                                            .cls-2-486932,
                                                            .cls-4-486932 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-486932,
                                                            .cls-2-486932 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-2-486932 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-486932 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-486932,
                                                            .cls-2-486932,
                                                            .cls-4-486932 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-486932,
                                                            .cls-2-486932 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-2-486932 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-486932 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-486932" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-486932">
                                                            <path class="cls-3-486932" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-486932" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-486932"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </label>
                                    </ng-container>
                                    <label *ngIf="isDoubleDoorLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && !orderPlaced"
                                        style="width: 220px" bps-radio-button bpsValue="doubleDoor-left"
                                        [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                        bpsRadioButtonType="variation5">
                                        <span>Double Door (L - Active)</span>
                                        <!-- Double Door (L - Active) -->
                                        <div class="labelSVG">
                                            <!-- SVG when it is not in disabled state -->
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable" width="50" height="30"
                                                class="showWhiteHover" (click)="onChangeDoubleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-879654,
                                                        .cls-6-879654 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-879654 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-5-879654 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-879654">
                                                        <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                        <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-879654">
                                                        <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                        <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" style="stroke:#03ff3f;fill:none">
                                                        <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                        <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-879654">
                                                        <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                        <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20616)" style="stroke-width:1.5px;stroke:#03ff3f;fill:none" />
                                                    <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1291.898 -9333.038)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                            <!-- SVG when it is in disabled state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showWhiteHover"
                                                (click)="onChangeDoubleDoor()"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable">
                                                <defs>
                                                    <style>
                                                        .cls-1-879654,
                                                        .cls-6-879654 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-879654 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-5-879654 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-879654">
                                                        <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                        <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-879654">
                                                        <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                        <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" style="stroke:#ffff;fill:none">
                                                        <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                        <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-879654">
                                                        <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                        <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="translate(966 20616)" style="stroke-width:1.5px;stroke:#ffff;fill:none" />
                                                    <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                        transform="rotate(180 1291.898 -9333.038)" style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                </g>
                                            </svg>
                                
                                
                                            <!-- SVG is not in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                (click)="onChangeDoubleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-324518,
                                                        .cls-2-324518,
                                                        .cls-4-324518 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-324518,
                                                        .cls-2-324518 {
                                                            stroke: #00a2d1
                                                        }
                                
                                                        .cls-2-324518 {
                                                            stroke-width: 1.5px
                                                        }
                                
                                                        .cls-3-324518 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                        <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                        <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                        <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                        <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" class="cls-2-324518"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                    <path id="Path_937" data-name="Path 937" class="cls-2-324518"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="rotate(180 1291.898 -9333.038)" />
                                                </g>
                                            </svg>
                                
                                            <!-- SVG is in disable state -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                (click)="onChangeDoubleDoor()">
                                                <defs>
                                                    <style>
                                                        .cls-1-324518,
                                                        .cls-2-324518,
                                                        .cls-4-324518 {
                                                            fill: none
                                                        }
                                
                                                        .cls-1-324518,
                                                        .cls-2-324518 {
                                                            stroke: #fff
                                                        }
                                
                                                        .cls-2-324518 {
                                                            stroke-width: 1.5px
                                                        }
                                
                                                        .cls-3-324518 {
                                                            stroke: none
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                    <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                        <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                    </g>
                                                    <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                        <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                    </g>
                                                    <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                        <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                    </g>
                                                    <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-324518">
                                                        <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                        <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                    </g>
                                                    <path id="Path_935" data-name="Path 935" class="cls-2-324518"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                    <path id="Path_937" data-name="Path 937" class="cls-2-324518"
                                                        d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="rotate(180 1291.898 -9333.038)" />
                                                </g>
                                            </svg>
                                
                                        </div>
                                    </label>
                                    <ng-container *ngIf="isDoubleDoorLeftSelected">
                                        <label
                                            *ngIf="isDoubleDoorLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px; border: 1px solid #666 !important;" bps-radio-button bpsValue="doubleDoor-left"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Double Door (L - Active)</span>
                                            <!-- Double Door (L - Active) -->
                                            <div class="labelSVG">
                                                <!-- SVG when it is not in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable" width="50" height="30"
                                                    class="showWhiteHover" (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-879654,
                                                            .cls-6-879654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-879654 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-879654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" style="stroke:#03ff3f;fill:none">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20616)" style="stroke-width:1.5px;stroke:#03ff3f;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                                <!-- SVG when it is in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showWhiteHover"
                                                    (click)="onChangeDoubleDoor()"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable">
                                                    <defs>
                                                        <style>
                                                            .cls-1-879654,
                                                            .cls-6-879654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-879654 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-879654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" style="stroke:#ffff;fill:none">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20616)" style="stroke-width:1.5px;stroke:#ffff;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-324518,
                                                            .cls-2-324518,
                                                            .cls-4-324518 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-324518,
                                                            .cls-2-324518 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-2-324518 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-324518 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-324518,
                                                            .cls-2-324518,
                                                            .cls-4-324518 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-324518,
                                                            .cls-2-324518 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-2-324518 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-324518 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                
                                            </div>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!isDoubleDoorLeftSelected">
                                        <label
                                            *ngIf="isDoubleDoorLeft == true && pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward' && orderPlaced"
                                            style="width: 220px;" bps-radio-button bpsValue="doubleDoor-left"
                                            [bpsDisabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                            bpsRadioButtonType="variation5">
                                            <span>Double Door (L - Active)</span>
                                            <!-- Double Door (L - Active) -->
                                            <div class="labelSVG">
                                                <!-- SVG when it is not in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable" width="50" height="30"
                                                    class="showWhiteHover" (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-879654,
                                                            .cls-6-879654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-879654 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-879654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" style="stroke:#03ff3f;fill:none">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20616)" style="stroke-width:1.5px;stroke:#03ff3f;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                                <!-- SVG when it is in disabled state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showWhiteHover"
                                                    (click)="onChangeDoubleDoor()"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable">
                                                    <defs>
                                                        <style>
                                                            .cls-1-879654,
                                                            .cls-6-879654 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-879654 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-5-879654 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-6-879654" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" style="stroke:#ffff;fill:none">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-879654">
                                                            <path class="cls-5-879654" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-6-879654" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="translate(966 20616)" style="stroke-width:1.5px;stroke:#ffff;fill:none" />
                                                        <path id="Path_937" data-name="Path 937" d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)"
                                                            style="stroke-width:1.5px;stroke:#fff;fill:none" />
                                                    </g>
                                                </svg>
                                
                                
                                                <!-- SVG is not in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker > -1 && !orderPlaced && !isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-324518,
                                                            .cls-2-324518,
                                                            .cls-4-324518 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-324518,
                                                            .cls-2-324518 {
                                                                stroke: #00a2d1
                                                            }
                                
                                                            .cls-2-324518 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-324518 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                
                                                <!-- SVG is in disable state -->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" class="showBlueHover"
                                                    *ngIf="cpService.selectedOperabilityPicker == -1 || orderPlaced || isDoorOperableDisable"
                                                    (click)="onChangeDoubleDoor()">
                                                    <defs>
                                                        <style>
                                                            .cls-1-324518,
                                                            .cls-2-324518,
                                                            .cls-4-324518 {
                                                                fill: none
                                                            }
                                
                                                            .cls-1-324518,
                                                            .cls-2-324518 {
                                                                stroke: #fff
                                                            }
                                
                                                            .cls-2-324518 {
                                                                stroke-width: 1.5px
                                                            }
                                
                                                            .cls-3-324518 {
                                                                stroke: none
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g id="sps_rectangle_icon_doubledoor_right_enabled" transform="translate(-1750 -960)">
                                                        <g id="Rectangle_1340" data-name="Rectangle 1340" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1775 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1775 960)" />
                                                        </g>
                                                        <g id="Rectangle_1342" data-name="Rectangle 1342" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h25v30H0z" transform="translate(1750 960)" />
                                                            <path class="cls-4-324518" d="M.5.5h24v29H.5z" transform="translate(1750 960)" />
                                                        </g>
                                                        <g id="Rectangle_1341" data-name="Rectangle 1341" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1777 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1777 962)" />
                                                        </g>
                                                        <g id="Rectangle_1343" data-name="Rectangle 1343" class="cls-1-324518">
                                                            <path class="cls-3-324518" d="M0 0h21v26H0z" transform="translate(1752 962)" />
                                                            <path class="cls-4-324518" d="M.5.5h20v25H.5z" transform="translate(1752 962)" />
                                                        </g>
                                                        <path id="Path_935" data-name="Path 935" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312" transform="translate(966 20616)" />
                                                        <path id="Path_937" data-name="Path 937" class="cls-2-324518"
                                                            d="M831.175-19628.764 812.621-19641l18.555-12.312"
                                                            transform="rotate(180 1291.898 -9333.038)" />
                                                    </g>
                                                </svg>
                                
                                            </div>
                                        </label>
                                    </ng-container>
                                </ng-container>
                            </bps-radio-group>
                            <!-- nz-tooltip [nzTooltipTitle]="titleTemplate" nzTooltipPlacement="right" [nzVisible]="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening !== '' && pickers[max(cpService.selectedOperabilityPicker, 0)].operableType === ''" nzTooltipTrigger='true'
                            <ng-template #titleTemplate style="margin-left: 10px;"><span>OPERABLE TYPE<br>Please, select another option.</span></ng-template> -->
                        </div>
                    </div>
                </div>

                <div class="sps-break"></div>
                <div class="apply-btn-container" id="operability-apply-btn-container">
                    <button
                        [ngStyle]="language ==='de-DE' ? {'transform': 'translate(74%, 0%)'} : {'margin-left':'74px','width':'80px'}"
                        bps-button bpsType="variation-4"
                        [disabled]="cpService.selectedOperabilityPicker === -1 || !selectedGlassIDs || selectedGlassIDs.length === 0 || !pickers[cpService.selectedOperabilityPicker].operableType || !pickers[cpService.selectedOperabilityPicker].fixedOpening || orderPlaced || isApplyBtnDisable"
                        [ngClass]="{'active': !(cpService.selectedOperabilityPicker === -1 || !selectedGlassIDs || selectedGlassIDs.length === 0 || !pickers[cpService.selectedOperabilityPicker].operableType || !pickers[cpService.selectedOperabilityPicker].fixedOpening || orderPlaced || isApplyBtnDisable)}"
                        (click)="onApply()">{{'configure.apply' | translate }}</button>
                    <!-- Apply -->
                    <span style="margin-top: 4px; float: right;" bpsType="variation-1" bps-popover
                        [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                        [bpsPopoverContent]="applyContent9">
                        <img *ngIf="cpService.selectedOperabilityPicker !== -1 && !orderPlaced else greyImage2" class="iconI" 
                            src="/assets/Images/icons/sps_information_icon_blue.svg">
                        <ng-template #greyImage2>
                            <img class="iconI" src="/assets/Images/icons/sps_information_icon_grey.svg">
                        </ng-template>
                    </span>
                    <ng-template #applyContent9>
                        <div class="popover-variation3" style="width: 400px; height: 180px;">
                            <div style="float: left; width: 45%; margin:10px;border-right: #383838 2px solid;">
                                <div class="roundCount"> 1 </div> {{'configure.operability-select-class-text' |
                                translate}}
                                {{'configure.3d-model' | translate}}
                                <br>
                                <img src="/assets/Images/tooltips/APPLY_OPERABLE.png" height="115"
                                    style="margin:10px; align-items: center;" />
                            </div>
                            <div style="float: right; width: 45%; margin:10px;" *ngIf="language === 'en-US'">
                                <div class="roundCount"> 2 </div>{{'configure.click-text' | translate}}&nbsp;
                                "<a>{{'configure.apply-text' | translate}}</a>"&nbsp;
                                {{'configure.button-text' | translate}}
                                <br>
                                <button bps-button bpsType="variation-4" style="margin: 50px">{{'configure.apply' |
                                    translate }}</button>
                            </div>
                            <div style="float: right; width: 45%; margin:10px;" *ngIf="language ==='de-DE'">
                                <div class="roundCount"> 2 </div>{{'configure.left-configure-framing-click-text' |
                                translate}} <br>
                                "<a>{{'configure.lft-configure-framing-apply-text' | translate}}</a>"
                                <br>
                                <button bps-button bpsType="variation-4" style="margin: 50px">{{'configure.apply' |
                                    translate
                                    }}</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <bps-list bpsBordered bpsListType="variation6" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!orderPlaced">
                    <bps-list-item *ngFor="let glass of glassAppliedArray[cpService.selectedOperabilityPicker]"
                        style="font-weight: 0!important;" bpsDelete (ondelete)="onRemoveItem(glass.glassID)"
                        (mouseenter)="onFocusGreen(glass.glassID)" (mouseleave)="onFocusOutItem()"
                        (ondeletehover)="onFocusRed(glass.glassID, $event)">
                        {{ glass.fixedOpening == 'Inward' ? ('configure.inward' | translate):('configure.outward' | translate) }} - {{glass.operableType == 'Turn-Tilt-Right' ? ('configure.tilt-or-turn-right-handed' | translate):
                                                                                                                                        glass.operableType == 'Turn-Tilt-Left' ? ('configure.tilt-or-turn-left-handed' | translate):
                                                                                                                                        glass.operableType == 'Side-Hung-Right' ? ('configure.side-hung-right-handed' | translate):
                                                                                                                                        glass.operableType == 'Side-Hung-Left' ? ('configure.side-hung-left-handed' | translate):
                                                                                                                                        glass.operableType == 'Bottom-Hung' ? ('configure.bottom-hung' | translate):
                                                                                                                                        glass.operableType == 'Top-Hung' ? ('configure.top-hung' | translate):
                                                                                                                                        glass.operableType == 'Parallel-Opening' ? ('configure.parallel-opening' | translate): glass.operableType }}
                        {{ glass.glassID }}_{{'configure.added' | translate | lowercase}}
                        <!-- compare values to fixedOpening_index & operableType_index -->
                    </bps-list-item>
                </bps-list>
                <bps-list bpsBordered bpsListType="variation6" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="orderPlaced">
                    <bps-list-item *ngFor="let glass of glassAppliedArray[cpService.selectedOperabilityPicker]"
                        style="font-weight: 0!important; background-color: #363636!important; color: #666!important">
                        {{ glass.fixedOpening == 'Inward' ? ('configure.inward' | translate):('configure.outward' | translate) }} - {{glass.operableType == 'Turn-Tilt-Right' ? ('configure.tilt-or-turn-right-handed' | translate):
                                                                                                                                        glass.operableType == 'Turn-Tilt-Left' ? ('configure.tilt-or-turn-left-handed' | translate):
                                                                                                                                        glass.operableType == 'Side-Hung-Right' ? ('configure.side-hung-right-handed' | translate):
                                                                                                                                        glass.operableType == 'Side-Hung-Left' ? ('configure.side-hung-left-handed' | translate):
                                                                                                                                        glass.operableType == 'Bottom-Hung' ? ('configure.bottom-hung' | translate):
                                                                                                                                        glass.operableType == 'Top-Hung' ? ('configure.top-hung' | translate):
                                                                                                                                        glass.operableType == 'Parallel-Opening' ? ('configure.parallel-opening' | translate): glass.operableType }}
                        {{ glass.glassID }}_{{'configure.added' | translate | lowercase}}
                        <!-- compare values to fixedOpening_index & operableType_index -->
                    </bps-list-item>
                </bps-list>
                <ng-container *ngIf=" glassAppliedArray[cpService.selectedOperabilityPicker] && glassAppliedArray[cpService.selectedOperabilityPicker].length > 0">
                    <div> 
                        <ng-container *appCheckPermissions="feature.InsideHandle || feature.ADS_75">
                            <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container" 
                            style="margin-top: 0px;" *ngIf="isDoorArticlesDisplay || isWindowArticlesDisplay">
                            <div class="sps-break" style=" margin-top: 0px;"></div>
                            <h3 class="operability-fittings-heading"
                                [ngClass]="{'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced }">
                                FITTINGS</h3>
                        </div>
                        <ng-container *appCheckPermissions="feature.ADS_75">
                            <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container" style="margin-top: 22px;" *ngIf="isDoorArticlesDisplay">
                                <h3 class="outer-frame-heading"
                                    [ngClass]="{'blue-heading': isOutsideHandleOPened,'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced }">
                                    OUTSIDE HANDLE</h3>
                                <!-- Outside Handle -->
                                <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
                                    style="margin: 7px 0px 15px 0px;">
                                    <div class="sps-operability-vent-frame-button-container">
                                        <div class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
                                            <input [ngClass]="{'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced == true }" class="left-selection"
                                                centered disabled bpsDisabled bps-input [(ngModel)]="outsideHandle">
                                        </div>
                                        <button bps-button bpsType="variation-13" [disabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced == true"
                                            [ngClass]="{'active': isOutsideHandleOpened}"
                                            style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
                                            (click)="onOpenIFramePopout('OutsideHandle')">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956" viewBox="0 0 12.967 11.956">
                                                <defs>
                                                    <style>
                                                        .cls-1 {
                                                            fill: none;
                                                            stroke: #fff;
                                                            stroke-linecap: round;
                                                            stroke-width: 2px
                                                        }
                                                    </style>
                                                </defs>
                                                <g id="sps_square_icon_changeprofile_enabled_new" transform="translate(-2533.021 -328.325)">
                                                    <g id="sps_round_icon_rightpanelopened_white" transform="translate(2540.011 329.74)">
                                                        <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0" class="cls-1" data-name="Path 287"
                                                            transform="rotate(180 2.282 4.564)" />
                                                    </g>
                                                    <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1" data-name="Path 1295"
                                                        transform="translate(11867.02 22323.951)" />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *appCheckPermissions="feature.InsideHandle">                    
                        <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container"
                            style="margin-top: 22px;" *ngIf="isDoorArticlesDisplay || isWindowArticlesDisplay">
                            <h3 class="outer-frame-heading"
                                [ngClass]="{'blue-heading': isInsideHandleOpened,'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced }">
                                INSIDE HANDLE</h3>
                            <!-- Inside Handle -->
                            <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
                                style="margin: 7px 0px 15px 0px;">
                                <div class="sps-operability-vent-frame-button-container">
                                    <div class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
                                        <input [ngClass]="{'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced == true }"
                                            class="left-selection" centered disabled bpsDisabled bps-input
                                            [(ngModel)]="insideHandle">
                                    </div>
                                    <button bps-button bpsType="variation-13"
                                        [disabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced == true"
                                        [ngClass]="{'active': isInsideHandleOpened}"
                                        style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
                                        (click)="onOpenIFramePopout('InsideHandle')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956"
                                            viewBox="0 0 12.967 11.956">
                                            <defs>
                                                <style>
                                                    .cls-1 {
                                                        fill: none;
                                                        stroke: #fff;
                                                        stroke-linecap: round;
                                                        stroke-width: 2px
                                                    }
                                                </style>
                                            </defs>
                                            <g id="sps_square_icon_changeprofile_enabled_new"
                                                transform="translate(-2533.021 -328.325)">
                                                <g id="sps_round_icon_rightpanelopened_white"
                                                    transform="translate(2540.011 329.74)">
                                                    <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0" class="cls-1"
                                                        data-name="Path 287" transform="rotate(180 2.282 4.564)" />
                                                </g>
                                                <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1"
                                                    data-name="Path 1295" transform="translate(11867.02 22323.951)" />
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    </ng-container>
                    </div>
                    <div *appCheckPermissions="feature.ADS_75">
                        <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container"
                            style="margin-top: 0;" *ngIf="isDoorArticlesDisplay">
                            <ng-container *appCheckPermissions="feature.ADS_75">
                                <div >
                                    <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container"
                                    style="margin-top: 7px;">
                                        <h3 class="outer-frame-heading"
                                            [ngClass]="{'blue-heading': isHingeTypeOpened,'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced }">
                                            HINGE TYPE</h3>
                                        <!-- HINGE TYPE -->
                                        <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
                                            style="margin: 7px 0px 15px 0px;">
                                            <div class="sps-operability-vent-frame-button-container">
                                                <div
                                                    class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
                                                    <input
                                                        [ngClass]="{'disabled': cpService.selectedOperabilityPicker == -1 || orderPlaced == true }"
                                                        class="left-selection" centered disabled bpsDisabled bps-input
                                                        [(ngModel)]="hingeType">
                                                </div>
                                                <button bps-button bpsType="variation-13"
                                                    [disabled]="cpService.selectedOperabilityPicker == -1 || orderPlaced == true"
                                                    [ngClass]="{'active': isHingeTypeOpened}"
                                                    style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
                                                    (click)="onOpenIFramePopout('HingeType')">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956"
                                                        viewBox="0 0 12.967 11.956">
                                                        <defs>
                                                            <style>
                                                                .cls-1 {
                                                                    fill: none;
                                                                    stroke: #fff;
                                                                    stroke-linecap: round;
                                                                    stroke-width: 2px
                                                                }
                                                            </style>
                                                        </defs>
                                                        <g id="sps_square_icon_changeprofile_enabled_new"
                                                            transform="translate(-2533.021 -328.325)">
                                                            <g id="sps_round_icon_rightpanelopened_white"
                                                                transform="translate(2540.011 329.74)">
                                                                <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0"
                                                                    class="cls-1" data-name="Path 287"
                                                                    transform="rotate(180 2.282 4.564)" />
                                                            </g>
                                                            <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1"
                                                                data-name="Path 1295"
                                                                transform="translate(11867.02 22323.951)" />
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
        
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="sps-break" style="margin: 0px;margin-left: -15px;"></div>

                <!-- WINDOW SYSTEMS info only for Facade-->
                <div class="column-container"
                    *ngIf="cpService.selectedOperabilityPicker > -1 && umService && umService.current_UnifiedModel && umService.current_UnifiedModel.ProblemSetting && umService.isFacade()">

                    <h3 class="operability-selection-heading" style="margin-top: 15px;"
                        [ngClass]="{'disabled': isInsertWindowDisabled()}"
                        [ngClass]="{'blue-heading': isOuterOpened, 'disabled': isInsertWindowDisabled()}">
                        {{'configure.window-systems' | translate}}</h3>
                    <div class="sps-accordion-ui-section-content-container">

                        <div id="system-cross-section-img-container" class="sps-accordion-ui-section-content"
                            *ngIf="isInsertWindowDisabled()">
                            <img id="system-cross-section-img"
                                [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/windowsystem.png'"
                                alt="aws_picture">
                        </div>
                        <div id="system-cross-section-img-container" class="sps-accordion-ui-section-content"
                            *ngIf="glassAppliedArray[cpService.selectedOperabilityPicker].length > 0 && pickers[this.cpService.selectedOperabilityPicker].operableType !== ''">
                            <img id="system-cross-section-img"
                                *ngIf="aws114SystemValue[systemAWS114FacadeSelected] !== undefined && (pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung') && (pickers[max(cpService.selectedOperabilityPicker, 0)].operationType === 'Manual' || pickers[max(cpService.selectedOperabilityPicker, 0)].operationType === 'TipTronic')"
                                [ngClass]="{'blue-heading': isOuterOpened, 'disabled': glassAppliedArray[cpService.selectedOperabilityPicker].length < 1}"
                                [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/aws_114.png'"
                                alt="aws_picture">
                            <!-- <img id="system-cross-section-img" *ngIf="aws114SystemValue[systemAWS114FacadeSelected] !== undefined && pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' && pickers[max(cpService.selectedOperabilityPicker, 0)].operationType === 'TipTronic'" 
                            [ngClass]="{'blue-heading': isOuterOpened, 'disabled': isInsertWindowDisabled()}"
                            [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/'+aws114SystemValue[systemAWS114FacadeSelected]+'_tiptronic.png'" alt="aws_picture"> -->

                            <img id="system-cross-section-img"
                                *ngIf="isInwardSystemSelected() && pickers[this.cpService.selectedOperabilityPicker].operableType !== 'parallelOpening' && pickers[this.cpService.selectedOperabilityPicker].operableType !== 'topHung'"
                                [ngClass]="{'blue-heading': isOuterOpened, 'disabled': isInsertWindowDisabled()}"
                                [src]="getSystemCrossSectionSRC()"
                                alt="aws_picture">
                            <img id="system-cross-section-img"
                                *ngIf="!isInwardSystemSelected() && aws114SystemValue[systemAWS114FacadeSelected] === undefined"
                                [ngClass]="{'disabled': true }"
                                [attr.src]="'https://vcl-design-com.s3.amazonaws.com/StaticFiles/Images/system-jpeg/aws__75_si_plus.jpg'"
                                alt="aws_picture">
                        </div>
                    </div>
                    <div *ngIf="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward'" id="system-pill-container"
                        class="sps-accordion-ui-section-content" style="margin-bottom: 0;">
                        <div id="sps-system-names" class="sps-pill-container" *ngIf="glassAppliedArray[cpService.selectedOperabilityPicker].length < 1 || pickers[this.cpService.selectedOperabilityPicker].operableType == ''">
                            <div *ngFor="let outwardSystem of outwardSystemsToInsert" class="sps-pill sps-pill-disable">
                                <p>{{outwardSystem.Name}}</p>
                            </div>
                        </div>
                        <div id="sps-system-names" class="sps-pill-container"
                            *ngIf="glassAppliedArray[cpService.selectedOperabilityPicker].length > 0 && pickers[this.cpService.selectedOperabilityPicker].operableType !== undefined  && pickers[this.cpService.selectedOperabilityPicker].operableType !== null && pickers[this.cpService.selectedOperabilityPicker].operableType !== ''">
                            <div *ngFor="let outwardSystem of outwardSystemsToInsert" class="sps-pill" [ngClass]="{'selected': systemAWS114FacadeSelected === outwardSystem.Id}"
                                (click)="onSelectAWSWindowSystem(outwardSystem.Id)">
                                <p>{{outwardSystem.Name}}</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening != 'Outward'" id="system-pill-container"
                        class="sps-accordion-ui-section-content" style="margin-bottom: 0;">
                        <div id="sps-system-names" class="sps-pill-container" *ngIf="isInsertWindowDisabled()">
                            <div *ngFor="let inwardSystem of inwardSystemsToInsert" class="sps-pill sps-pill-disable">
                                <p>{{inwardSystem.Name}}</p>
                            </div>
                        </div>
                        <div id="sps-system-names" class="sps-pill-container" *ngIf="glassAppliedArray[cpService.selectedOperabilityPicker].length > 0 && pickers[this.cpService.selectedOperabilityPicker].operableType !== undefined  && pickers[this.cpService.selectedOperabilityPicker].operableType !== null && pickers[this.cpService.selectedOperabilityPicker].operableType !== ''">
                            <div *ngFor="let inwardSystem of inwardSystemsToInsert" class="sps-pill" [ngClass]="{'selected': systemFacadeSelected === inwardSystem.Id}" (click)="onSelectFWSWindowSystem(inwardSystem.Id)">
                                <p>{{inwardSystem.Name}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="sps-break" style="margin-bottom: 5;"
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung'">
                    </div>
                    <div class="operability-fixed-opening-container"
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung'">
                        <div class="operability-fixed-opening-column">
                            <h3 class="operability-selection-heading" id="operability-fixed-opening-heading"
                                [ngClass]="{'disabled': isInsertWindowDisabled() }">
                                {{'configure.operation-type' | translate }}</h3>
                            <!-- OPERATION TYPE -->
                            <div class="operability-fixed-opening-boxes">
                                <bps-radio-group [(ngModel)]="pickers[max(cpService.selectedOperabilityPicker, 0)].operationType"
                                    style="display: flex;margin-left:-0.1px;"
                                    (ngModelChange)="onOperationTypeChange($event)">
                                    <label style="width: 102.5px" bps-radio-button bpsValue="Manual"
                                        bpsRadioButtonType="variation3"
                                        [bpsDisabled]="isInsertWindowDisabled()">
                                        {{'configure.manual' | translate }}
                                        <!-- Manual -->
                                    </label>
                                    <label style="width: 102.5px; margin-right: 0px !important;" bps-radio-button
                                        bpsValue="TipTronic" bpsRadioButtonType="variation3"
                                        [bpsDisabled]="isInsertWindowDisabled()">
                                        {{'configure.tiptronic' | translate }}
                                        <!-- TipTronic -->
                                    </label>
                                </bps-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="sps-break"
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung'">
                    </div>
                    <h3 class="operability-selection-heading"
                        [ngClass]="{'blue-heading': isFrameCombinationOpened, 'disabled': isInsertWindowDisabled() }"
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung'">
                        {{'configure.frame-combination' | translate }}</h3>

                    <div id="sps-operablility-vent-frame-dropdown-container" class="accordion-ui-section-content"
                        style="margin: 7px 0px 15px 0px;"
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' || pickers[this.cpService.selectedOperabilityPicker].operableType === 'topHung'">
                        <div class="sps-operability-vent-frame-button-container">
                            <div>
                                <input class="left-selection"
                                    [ngClass]="{'disabled': isInsertWindowDisabled()}"
                                    centered disabled bpsDisabled bps-input [(ngModel)]="frameCombinationInputValue">
                            </div>
                            <button bps-button bpsType="variation-13" style="margin-left: 22px"
                                [disabled]="isInsertWindowDisabled()"
                                [ngClass]="{'active': isFrameCombinationOpened}"
                                (click)="onOpenIFramePopout('FrameCombination')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956"
                                    viewBox="0 0 12.967 11.956">
                                    <defs>
                                        <style>
                                            .cls-1 {
                                                fill: none;
                                                stroke: #fff;
                                                stroke-linecap: round;
                                                stroke-width: 2px
                                            }
                                        </style>
                                    </defs>
                                    <g id="sps_square_icon_changeprofile_enabled_new"
                                        transform="translate(-2533.021 -328.325)">
                                        <g id="sps_round_icon_rightpanelopened_white"
                                            transform="translate(2540.011 329.74)">
                                            <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0" class="cls-1"
                                                data-name="Path 287" transform="rotate(180 2.282 4.564)" />
                                        </g>
                                        <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1"
                                            data-name="Path 1295" transform="translate(11867.02 22323.951)" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="sps-break" style="margin-bottom: 5;"
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType !== 'parallelOpening' && pickers[this.cpService.selectedOperabilityPicker].operableType !== 'topHung'">
                    </div>
                    <div
                        *ngIf="pickers[this.cpService.selectedOperabilityPicker].operableType !== 'parallelOpening' && pickers[this.cpService.selectedOperabilityPicker].operableType !== 'topHung'">
                        <div id="sps-sightline-input-container" class="sps-accordion-ui-section-content-container"
                            style="margin-top: 0;">

                            <h3 class="outer-frame-heading"
                                [ngClass]="{'blue-heading': isOuterOpened, 'disabled': isInsertWindowDisabled()}">
                                {{'configure.outer-frame' | translate }}</h3>
                            <!-- Outer Frame -->
                            <div id="sps-sightline-dropdown-container" class="sps-accordion-ui-section-content"
                                style="margin: 7px 0px 15px 0px;">
                                <div class="sps-operability-vent-frame-button-container">
                                    <div class="size operability-vent-fr-value-10 sps-framing-sightline-input-margin">
                                        <input class="left-selection"
                                            [ngClass]="{'disabled': glassAppliedArray[cpService.selectedOperabilityPicker].length < 1 || pickers[cpService.selectedOperabilityPicker].operableType == ''}"
                                            centered disabled bpsDisabled bps-input [(ngModel)]="outerFrameInputValue">
                                    </div>
                                    <button bps-button bpsType="variation-13"
                                        [disabled]="isInsertWindowDisabled()"
                                        [ngClass]="{'active': isOuterOpened}"
                                        style="background-color: #262626; outline: none; cursor: pointer; border: 0px; margin-left: 22px"
                                        (click)="onOpenIFramePopout('OuterFrame')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956"
                                            viewBox="0 0 12.967 11.956">
                                            <defs>
                                                <style>
                                                    .cls-1 {
                                                        fill: none;
                                                        stroke: #fff;
                                                        stroke-linecap: round;
                                                        stroke-width: 2px
                                                    }
                                                </style>
                                            </defs>
                                            <g id="sps_square_icon_changeprofile_enabled_new"
                                                transform="translate(-2533.021 -328.325)">
                                                <g id="sps_round_icon_rightpanelopened_white"
                                                    transform="translate(2540.011 329.74)">
                                                    <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0" class="cls-1"
                                                        data-name="Path 287" transform="rotate(180 2.282 4.564)" />
                                                </g>
                                                <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1"
                                                    data-name="Path 1295" transform="translate(11867.02 22323.951)" />
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="sps-operability-vent-frame-container" class="sps-operability-vent-frame-container">
                            <h3 id="operablility-vent-frame-heading-width" class="vent-frame-heading"
                                [ngClass]="{'blue-heading': isVentOpened, 'disabled': isInsertWindowDisabled()}">
                                {{'configure.vent-frame' | translate }}
                            </h3>
                            <!-- Vent Frame -->
                            <div id="sps-operablility-vent-frame-dropdown-container"
                                class="accordion-ui-section-content" style="margin: 7px 0px 15px 0px;">
                                <div class="sps-operability-vent-frame-button-container">
                                    <div>
                                        <input class="left-selection"
                                            [ngClass]="{'disabled': isInsertWindowDisabled()}"
                                            centered disabled bpsDisabled bps-input [(ngModel)]="ventFrameInputValue">
                                    </div>
                                    <button bps-button bpsType="variation-13" style="margin-left: 22px"
                                        [disabled]="isInsertWindowDisabled()"
                                        [ngClass]="{'active': isVentOpened}"
                                        (click)="onOpenIFramePopout('VentFrame')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="11.956"
                                            viewBox="0 0 12.967 11.956">
                                            <defs>
                                                <style>
                                                    .cls-1 {
                                                        fill: none;
                                                        stroke: #fff;
                                                        stroke-linecap: round;
                                                        stroke-width: 2px
                                                    }
                                                </style>
                                            </defs>
                                            <g id="sps_square_icon_changeprofile_enabled_new"
                                                transform="translate(-2533.021 -328.325)">
                                                <g id="sps_round_icon_rightpanelopened_white"
                                                    transform="translate(2540.011 329.74)">
                                                    <path id="Path_287" d="M4.564 9.128L0 4.564 4.564 0" class="cls-1"
                                                        data-name="Path 287" transform="rotate(180 2.282 4.564)" />
                                                </g>
                                                <path id="Path_1295" d="M-9323.126-21989.648H-9333" class="cls-1"
                                                    data-name="Path 1295" transform="translate(11867.02 22323.951)" />
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sps-break" style="margin-top: 0px; margin-bottom: 0px"></div>
                    <form bps-form [formGroup]="validateForm"
                        *ngIf="pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Outward' && (pickers[this.cpService.selectedOperabilityPicker].operableType === 'parallelOpening' ||  pickers[this.cpService.selectedOperabilityPicker].operableType == 'topHung')">
                        <bps-form-item style="margin-top: 15px !important;">
                            <bps-form-control>
                                <div class="insulating-bar-container"
                                    *ngIf="umService.current_UnifiedModel.ProblemSetting.EnableStructural || umService.current_UnifiedModel.ProblemSetting.EnableThermal">
                                    <h3 class="vent-frame-heading" style="color: #00a2d1;"
                                        [ngClass]="{'disabled': isInsertWindowDisabled()}">
                                        {{'configure.insulating-bar-material' | translate }}
                                    </h3>
                                    <!-- Insulating Bar -->
                                    <div style="margin-top: 10px;">
                                        <bps-select style="width: 222px" formControlName="Insulating"
                                            [(ngModel)]="insertInsulationTypeName" name="insertInsulationTypeName"
                                            [bpsDisabled]="isInsertWindowDisabled()"
                                            (ngModelChange)="onSelectInsulating($event)"
                                            bpsPlaceHolder="{{'configure.select' | translate }}">
                                            <!-- Select -->
                                            <bps-option bpsValue="PA"  bpsLabel="Polyamide (PA)">
                                            </bps-option>
                                            <bps-option *ngIf="umService.isPolythermideEnabled()" bpsValue="PT"  bpsLabel="Polythermide (PT)">
                                            </bps-option>
                                        </bps-select>
                                    </div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
                    </form>
                    <form bps-form [formGroup]="validateFormForFacade"
                        *ngIf="(umService.current_UnifiedModel.ProblemSetting.EnableStructural || umService.current_UnifiedModel.ProblemSetting.EnableThermal) &&
                         (pickers[max(cpService.selectedOperabilityPicker, 0)].fixedOpening == 'Inward' && (pickers[this.cpService.selectedOperabilityPicker].operableType !== 'parallelOpening' && pickers[this.cpService.selectedOperabilityPicker].operableType !== 'topHung'))">
                        <bps-form-item style="margin-top: 15px !important; margin-bottom: 15px !important;">
                            <bps-form-control>
                                <div class="insulating-bar-container"
                                    *ngIf="umService.current_UnifiedModel.ProblemSetting.EnableStructural || umService.current_UnifiedModel.ProblemSetting.EnableThermal">
                                    <h3 class="vent-frame-heading" style="color: #00a2d1;"
                                        [ngClass]="{'disabled': isInsertWindowDisabled()}">
                                        {{'configure.insulating-bar' | translate }}
                                    </h3>
                                    <div style="margin-top:10px">
                                        <h3 class="vent-frame-heading" [ngClass]="{'disabled': isInsertWindowDisabled()}">{{'configure.material' | translate }}</h3>
                                    </div>
                                    <!-- Insulating Bar -->
                                    <div style="margin-top: 7px;">
                                        <bps-select style="width: 214px" formControlName="Insulating"
                                            [(ngModel)]="insertInsulationTypeName" name="insertInsulationTypeName"
                                            [bpsDisabled]="isInsertWindowDisabled()"
                                            (ngModelChange)="onSelectInsulating($event)"
                                            bpsPlaceHolder="{{'configure.select' | translate }}">
                                            <!-- Select -->
                                            <bps-option bpsValue="PA"  bpsLabel="Polyamide (PA)">
                                            </bps-option>
                                            <bps-option *ngIf="umService.isPolythermideEnabled()" bpsValue="PT"  bpsLabel="Polythermide (PT)">
                                            </bps-option>
                                        </bps-select>
                                    </div>
                                </div>
                            </bps-form-control>
                        </bps-form-item>
                        
                        <bps-form-item style="margin-top: 10px !important; margin: 0 0 15px !important;"  *ngIf="(umService.current_UnifiedModel.ProblemSetting.EnableStructural || umService.current_UnifiedModel.ProblemSetting.EnableThermal) && isAWS75SIPlusInsertedSystem()">
                            <bps-form-control>
                                <div class="glazing-gasket-container">
                                    <h3 class="vent-frame-heading"
                                        [ngClass]="{'disabled': isInsertWindowDisabled()}">
                                        {{'configure.glazing-gasket-combination' | translate }}</h3>
                                    <div
                                        style="margin-top: 7px; display: flex; justify-content: space-between; width: 214px;">
                                        <bps-select style="width: 170px;" formControlName="Glazing"
                                            [bpsDisabled]="isInsertWindowDisabled()"
                                            [(ngModel)]="gasketSelection" name="gasketSelection"
                                            bpsPlaceHolder="{{'configure.select' | translate }}"
                                            (ngModelChange)="onSelectInsertedUValueType($event)">
                                            <bps-option *ngFor="let gasket of framingService.gasketSelections" bpsValue="{{gasket.Value}}" bpsLabel="{{'configure.option' | translate }} {{gasket.Option}}"></bps-option>
                                        </bps-select>
                                        <div>
                                            <img *ngIf="isInsertWindowDisabled()" class="iconI"
                                                src="/assets/Images/icons/sps_information_icon_grey.svg">
                                            <img *ngIf="!isInsertWindowDisabled()" class="iconI"
                                                src="/assets/Images/icons/sps_information_icon_blue.svg" bps-popover
                                                [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                                [bpsPopoverContent]="contentTemplate">
                                        </div>
                                    </div>
                                </div>
                                <ng-template #contentTemplate>
                                    <div style="text-align: left; padding-left: 15px;">
                                        <div>
                                            <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 1</h3>
                                            <p>{{'configure.glazing-combination-option-1-content' | translate }}</p>
                                        </div>
                                        <div>
                                            <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 2</h3>
                                            <p>{{'configure.glazing-combination-option-2-content' | translate }}</p>
                                        </div>
                                        <div>
                                            <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 3</h3>
                                            <p>{{'configure.glazing-combination-option-3-content' | translate }}</p>
                                        </div>
                                        <div>
                                            <h3 style="color: #00a2d1;">{{'configure.option' | translate }} 4</h3>
                                            <p>{{'configure.glazing-combination-option-4-content' | translate }}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </bps-form-control>
                        </bps-form-item>
                        <bps-form-item *ngIf="umService?.current_UnifiedModel?.ProblemSetting?.EnableThermal && isFrenchInsertedSystem()">
                            <bps-form-control>
                                <div>
                                    <h3 class="vent-frame-heading" [ngClass]="{'disabled': isInsertWindowDisabled()}">{{'configure.insulation-level' | translate }}</h3>
                                    <!-- Insulation Level -->
                                    <div style="margin-top: 7px; display: flex; justify-content: space-between; width: 214px;">
                                      <bps-select style="width: 170px;" formControlName="InsulationControl" [(ngModel)]="insulationLevelSelection" bpsPlaceHolder="{{'configure.select' | translate }}" (ngModelChange)="onSelectInsertedUValueType($event)" [bpsDisabled]="isInsertWindowDisabled()">
                                        <bps-option *ngFor="let insulation of framingService.insulationLevels" bpsValue="{{insulation.Value}}" bpsLabel="{{'configure.level' | translate }} {{insulation.Level}}"></bps-option>
                                      </bps-select>
                                      <div>
                                        <img *ngIf="isInsertWindowDisabled()" class="iconI" src="/assets/Images/icons/sps_information_icon_grey.svg">
                                        <img *ngIf="!isInsertWindowDisabled()" class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg" bps-popover
                                          [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                          [bpsPopoverContent]="contentTemplate_AWS_75_II">
                                      </div>
                                    </div>
                                  </div>
                            </bps-form-control>
                        </bps-form-item>
                    </form>

                      <div class="sps-last-break"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentTemplate_AWS_75_II>
  <div style="text-align: left; padding-left: 15px;">
    <div>
      <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 1</h3>
      <!-- Option -->
      <p>{{'configure.insulation-level-1-content' | translate }}.</p>
    </div>
    <div>
      <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 2</h3>
      <p>{{'configure.insulation-level-2-content' | translate }}.</p>
    </div>
    <div>
      <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 3</h3>
      <p>{{'configure.insulation-level-3-content' | translate }}.</p>
    </div>
    <!-- <div>
      <h3 style="color: #00a2d1;">{{'configure.level' | translate }} 4</h3>
      <p>{{'configure.insulation-level-4-content' | translate }}.</p>
    </div> -->
  </div>
</ng-template>