import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { ThermalService } from 'src/app/app-structural/services/thermal.service';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';

@Component({
  selector: 'app-thermal',
  templateUrl: './thermal.component.html',
  styleUrls: ['./thermal.component.css']
})
export class ThermalComponent implements OnInit, OnChanges, AfterViewInit {

  @Output() thermalChildEvent: EventEmitter<any> = new EventEmitter();
  dewPointChecked: boolean = false;
  realtiveHumidityString: string;
  realtiveHumidityNumber: number;
  unified3DModel: UnifiedModel;
  private destroy$ = new Subject<void>();

  isCalculationOpened: boolean = false;
  calculationInputValue: string;

  // isRHvalid: boolean = true; 
  isValid: boolean = true;
  validateForm: FormGroup;
  language: string;
  patternLanguage: string;

  constructor(private fb: FormBuilder,
    public umService: UnifiedModelService,
    private cpService: ConfigPanelsService,
    private navLayoutService: NavLayoutService,
    private thermalService: ThermalService,
    private configureService: ConfigureService,
    private configService: ConfigureService, private translate: TranslateService) {
    this.language = this.configService.getLanguage();
    this.patternLanguage = this.configService.getNumberPattern();
    this.validateForm = this.fb.group({ realtiveHumidity: ["", [Validators.required, Validators.pattern(this.patternLanguage)],], });
  }

  ngOnInit(): void {
    this.unified3DModel = this.umService.current_UnifiedModel;
    this.loadThermal();
  }

  ngAfterViewInit(): void {
    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
          this.loadThermal(); 
        }
      }
    );

    this.cpService.obsSystem.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response?.systemFor == 'FRAMING') this.SetCodeType();
      }
    );

    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.UValueCalculation) this.isCalculationOpened = response.isOpened;
      }
    );

    this.cpService.obsConfirm.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.UValueCalculation) this.onTableConfirmation(response.data);
      }
    );
    
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.language = this.configureService.getLanguage();
      this.SetCodeType();
    });  

  }

  SetCodeType() {
    if (!this.unified3DModel.ModelInput.Thermal) this.unified3DModel.ModelInput.Thermal = { RelativeHumidity: null, InsulationZone: null, SunShadingArticleId: null, DeltaR: null, Codetype: null };
    if (!this.umService.current_UnifiedModel.ModelInput.Thermal) this.umService.current_UnifiedModel.ModelInput.Thermal = { RelativeHumidity: null, InsulationZone: null, SunShadingArticleId: null, DeltaR: null, Codetype: null };
    let thermalCode = this.umService?.current_UnifiedModel?.ModelInput?.Thermal?.Codetype;
    let isGermanAllowed: Boolean = this.thermalService.isThermalCodeAllowed('Germany');
    let isFrenchAllowed: Boolean = this.thermalService.isThermalCodeAllowed('France');
    
    if (!thermalCode) {
      if (isGermanAllowed) {
        thermalCode = 1;
      } else if (isFrenchAllowed) {
        thermalCode = 2;
      }
    } else if (thermalCode === 1 && !isGermanAllowed && isFrenchAllowed) {
      thermalCode = 2;
    } else if (thermalCode === 2 && isGermanAllowed && !isFrenchAllowed) {
      thermalCode = 1;
    }
    
    this.umService.current_UnifiedModel.ModelInput.Thermal.Codetype = this.unified3DModel.ModelInput.Thermal.Codetype = thermalCode;
    
    switch (thermalCode) {
      case 2:
        this.calculationInputValue = this.translate.instant(_('configure.nf-en-french-norm'));
        break;
      case 1:
      default:
        this.calculationInputValue = 'EN ISO 10077-1 : 2017';
        break;
    }
  }

  loadThermal() {
    this.SetCodeType();
    if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Thermal && this.unified3DModel.ModelInput.Thermal.RelativeHumidity > 0) {
      this.dewPointChecked = true;
      this.validateForm.enable();
      this.realtiveHumidityNumber = this.unified3DModel.ModelInput.Thermal.RelativeHumidity * 100;
      if (this.realtiveHumidityNumber < 1 || this.realtiveHumidityNumber > 100) {
        this.validateForm.controls["realtiveHumidity"].setErrors({ incorrect: true });
      }
    } else {
      this.dewPointChecked = false;
      this.validateForm.disable();
      this.realtiveHumidityNumber = null;
      this.realtiveHumidityString = null;
    }
    if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.FrameSystem && !this.unified3DModel.ModelInput.FrameSystem.AbsorptionCoefficient) this.unified3DModel.ModelInput.FrameSystem.AbsorptionCoefficient = this.thermalService.AbsorptionCoefficient_defaultValue; 
    if (this.umService.current_UnifiedModel && this.umService.current_UnifiedModel.ModelInput && this.umService.current_UnifiedModel.ModelInput.FrameSystem && !this.umService.current_UnifiedModel.ModelInput.FrameSystem.AbsorptionCoefficient) this.umService.current_UnifiedModel.ModelInput.FrameSystem.AbsorptionCoefficient = this.thermalService.AbsorptionCoefficient_defaultValue; 
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    if (this.realtiveHumidityNumber) {
      this.realtiveHumidityString = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.realtiveHumidityNumber.toString());
    }
  }

  ngOnChanges(Changes: SimpleChanges) {
    if (Changes) {
      if (Changes.unified3DModel && Changes.unified3DModel.currentValue) {
        this.unified3DModel = Changes.unified3DModel.currentValue;
        if (Changes.unified3DModel.previousValue) {
          if (Changes.unified3DModel.currentValue.ProblemSetting.ProblemGuid !== Changes.unified3DModel.previousValue.ProblemSetting.ProblemGuid) {
            this.dewPointChecked = false;
            this.validateForm.disable();
            this.realtiveHumidityString = '';
            this.realtiveHumidityNumber = null;
          }
          this.loadThermal();
        }
      }
    }
  }

  onTableConfirmation(data) {
    this.unified3DModel.ModelInput.FrameSystem.AbsorptionCoefficient = data.AbsorptionCoefficient;
    // if (this.unified3DModel.ModelInput.Thermal == null) this.unified3DModel.ModelInput.Thermal = new Thermal();
    this.unified3DModel.ModelInput.Thermal.DeltaR = data.SunShading.DeltaR;
    this.unified3DModel.ModelInput.Thermal.SunShadingArticleId = data.SunShading.SunShadingArticleId;
  
  }

  onDewPointSelected() {
    let isValueChanged = false;
    if (!this.dewPointChecked && this.realtiveHumidityNumber)
      isValueChanged = true;
    else if (this.dewPointChecked && !this.realtiveHumidityNumber)
      isValueChanged = true;
    if (isValueChanged) this.configService.computeClickedSubject.next(false);
    if (this.dewPointChecked) {
      this.validateForm.enable();
      this.onRealativeHumidityChange();
    }
    else {
      this.validateForm.disable();
      this.isValid = true;
      let relativeHumidity = 0;
      // thermal.RelativeHumidity = null;
      this.unified3DModel.ModelInput.Thermal.RelativeHumidity = relativeHumidity;
      this.thermalChildEvent.emit(this.unified3DModel.ModelInput);
      this.umService.set_RelativeHumidity(relativeHumidity);
    }
  }
  onRealativeHumidityChange() {
    if (this.realtiveHumidityString) {
      this.realtiveHumidityNumber = parseFloat(this.realtiveHumidityString.replace(',', '.'));
      if (this.realtiveHumidityNumber < 1 || this.realtiveHumidityNumber > 100) {
        this.validateForm.controls["realtiveHumidity"].setErrors({ incorrect: true });
      }
    }
    else {
      this.realtiveHumidityNumber = 0;
    }
    let isValueChanged = false;
    if ((!this.unified3DModel.ModelInput.Thermal && this.realtiveHumidityNumber)
      || (!this.realtiveHumidityNumber && this.unified3DModel.ModelInput.Thermal && this.unified3DModel.ModelInput.Thermal.RelativeHumidity > 0)
      || (this.unified3DModel.ModelInput.Thermal && this.realtiveHumidityNumber && (this.realtiveHumidityNumber / 100) != this.unified3DModel.ModelInput.Thermal.RelativeHumidity))
      isValueChanged = true;
    //let isValueChanged = this.realtiveHumidityNumber != this.unified3DModel.ModelInput.Thermal.RelativeHumidity;
    if (isValueChanged) this.configService.computeClickedSubject.next(false);
    this.isValid = this.isValidNumber(this.realtiveHumidityNumber);
    if (!this.isValid) {
      this.validateForm.controls['realtiveHumidity'].setErrors({ 'incorrect': true });
    }
    let relativeHumidity: number;
    if (this.realtiveHumidityNumber) {
      relativeHumidity = this.realtiveHumidityNumber / 100;
    }
    else {
      relativeHumidity = 0;
    }
    if (this.dewPointChecked) {
      this.unified3DModel.ModelInput.Thermal.RelativeHumidity = relativeHumidity;
      this.thermalChildEvent.emit(this.unified3DModel.ModelInput);
    }
    else {
      // if (this.unified3DModel.ModelInput.Thermal == null) this.unified3DModel.ModelInput.Thermal = new Thermal();
      this.unified3DModel.ModelInput.Thermal.RelativeHumidity = 0;
      this.thermalChildEvent.emit(this.unified3DModel.ModelInput);
    }
    this.umService.set_RelativeHumidity(relativeHumidity);
  }

  isValidNumber(value: any): boolean {
    if (!value)
      return false;
    if (isNaN(value))
      return false;
    if (value <= 0)
      return false;
    return true;
  }

  isFormValid(): boolean {
    if (!this.dewPointChecked) return true;
    return this.validateForm.valid;
  }

  onOpenCalculationPopout() {
    this.cpService.setPopout(true, PanelsModule.UValueCalculation);
  }
}
