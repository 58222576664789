<div style="height: 100%;">
  <div id="sps-solver-button-container">

    <div id="sps-solver-button-content" class="sps-solver-button-content sps-result-tab-physics-type-spacing">
      <!-- <h3 id="sps-physics-type-heading" class="sps-physics-type-heading fontUniversForSchueco-530">{{'result.physics-type' | translate }}</h3> -->
      <h3 id="sps-physics-type-heading" class="sps-physics-type-heading fontUniversForSchueco-530">
        {{ physicsType == 'A' ? ('result.acoustic-result' | translate):
           physicsType == 'B' ? ('result.structural-result' | translate):
           physicsType == 'C' ? ('result.thermal-result' | translate):
           physicsType == 'D' ? ('result.sustainability.sustainability-result' | translate):
           '' }}
      </h3>
      <!-- Physics Type -->
      <div style="display:flex;">
        <div id="sps-solver-configuration-row-1" style="margin-top: 7.3px;"
          class="sps-solver-configuration-row sps-result-tab-display-center sps-result-tab-display-text-align">
          <div>            
            <bps-radio-group [ngClass]="{'four-physics-type': configureService.getPhysicsTypeNumber() == 4}" [(ngModel)]="physicsType" (ngModelChange)="sendPhysicsTypeToParent()">
              <label *appCheckPermissions="feature.Acoustic" bps-radio-button bpsValue="A" [bpsDisabled]="isAcousticDisabled" class="physics-type-btn"
                (mouseenter)="!isAcousticDisabled? isAcousticSelected = true: isAcousticSelected = false;"
                (mouseleave)="isAcousticSelected = false;" bpsRadioButtonType="variation11">
                  <ng-container *ngIf="(physicsType === 'A' || isAcousticSelected) && !isAcousticDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_acoustic_active.svg" />
                  </ng-container>
                  <ng-container *ngIf="physicsType !== 'A' && !isAcousticSelected && !isAcousticDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_acoustic_enabled.svg" />
                  </ng-container>
                  <ng-container *ngIf="isAcousticDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_acoustic_disabled.svg" />
                  </ng-container>
              </label>

              <label *appCheckPermissions="feature.Structural" bps-radio-button bpsValue="B" [bpsDisabled]="isStructuralDisabled"
                (mouseenter)="!isStructuralDisabled? isStructuralSelected = true:isStructuralSelected = false;"
                (mouseleave)="isStructuralSelected = false;" class="physics-type-btn" bpsRadioButtonType="variation11">
                  <ng-container *ngIf="(physicsType === 'B' || isStructuralSelected) && !isStructuralDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_structural_active.svg" />
                  </ng-container>
                  <ng-container *ngIf="physicsType !== 'B' && !isStructuralSelected && !isStructuralDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_structural_enabled.svg" />
                  </ng-container>
                  <ng-container *ngIf="isStructuralDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_structural_disabled.svg" />
                  </ng-container>
              </label>

              <label *appCheckPermissions="feature.Thermal" bps-radio-button bpsValue="C" [bpsDisabled]="isThermalDisabled" class="physics-type-btn"
                (mouseenter)="!isThermalDisabled? isThermalSelected = true: isThermalSelected = false;"
                (mouseleave)="isThermalSelected = false;" bpsRadioButtonType="variation11">
                  <ng-container *ngIf="(physicsType === 'C' || isThermalSelected) && !isThermalDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_thermal_active.svg" />
                  </ng-container>
                  <ng-container *ngIf="physicsType !== 'C' && !isThermalSelected && !isThermalDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_thermal_enabled.svg" />
                  </ng-container>
                  <ng-container *ngIf="isThermalDisabled">
                    <img src="/assets/bps-icons/sps_rectangle_icon_thermal_disabled.svg" />
                  </ng-container>
              </label>

              <label *appCheckPermissions="feature.Sustainability" bps-radio-button bpsValue="D" [bpsDisabled]="isSustainabilityDisabled" class="physics-type-btn"
                (mouseenter)="!isSustainabilityDisabled? isSustainabilitySelected = true: isSustainabilitySelected = false;"
                (mouseleave)="isSustainabilitySelected = false;" bpsRadioButtonType="variation11">
                  <ng-container *ngIf="(physicsType === 'D' || isSustainabilitySelected) && !isSustainabilityDisabled">
                    <img src="/assets/Images/icons/bps_sustainability_icon_s_active.svg" />
                  </ng-container>
                  <ng-container *ngIf="physicsType !== 'D' && !isSustainabilitySelected && !isSustainabilityDisabled">
                    <img src="/assets/Images/icons/bps_sustainability_icon_s_enabled.svg" />
                  </ng-container>
                  <ng-container *ngIf="isSustainabilityDisabled">
                    <img src="/assets/Images/icons/bps_sustainability_icon_s_disabled.svg" />
                  </ng-container>
              </label>
            </bps-radio-group>
          </div>
        </div>
        <div id="sps-result-tab-grey-spacer" class="sps-result-tab-spacer-grey"></div>
        <div id="sps-result-tab-blue-circle" style="margin-top: 7.3px;" class="sps-result-tab-blue-circle">
          <div *ngIf="!isProductInfoVisible; else blueIcon" bps-tooltip [bpsTooltipType]="'variation_10'"
            bpsTooltipTitle="{{'result.product-info' | translate }}"
            bpsTooltipPlacement="top">
            <img style="cursor:pointer;" id="sps-result-product-info-icon"
              src="assets/Images/icons/sps_round_icon_productinfo_grey.svg" (click)="showProductInfoModal()">
          </div>
          <ng-template #blueIcon>
            <div>
              <img style="cursor:pointer;" id="sps-result-product-info-icon"
                src="assets/Images/icons/sps_round_icon_productinfo_blue.svg">
            </div>
          </ng-template>
          <bps-modal [(bpsVisible)]="isProductInfoVisible" [bpsFooter]="null" [bpsWidth]="610"
            (bpsOnCancel)="handleCancel()" bpsTitle="{{'result.product-info' | translate }}">
            <div class="parent">
              <span class="imgDiv">
                <!-- Product Info -->
                <div class="product-image-dimension"
                  style="display: flex; justify-content: center; align-items: center;height:295px">
                  <img #selectedItemImage (error)="setDefaultImage()" src='{{path}}'
                    style="height: 190px; width: auto; object-fit: cover;" alt="">
                </div>
              </span>
              <span class="infoDiv">
                <div>
                  <span class="product-field">{{'result.product-type' | translate | uppercase }}</span>
                  <!-- PRODUCT TYPE -->
                  <span class="product-description">{{productType}}</span>

                  <span class="product-field">{{'result.system-name' | translate | uppercase }}</span>
                  <!-- SYSTEM NAME -->
                  <span class="product-description">{{systemType}}</span>

                  <span class="product-field">{{'result.dimension' | translate | uppercase }}</span>
                  <!-- DIMENSION -->
                  <span class="product-description">{{dimensions}}</span>
                </div>

                <div *ngFor="let glass of GeometryGlassList; let i = index">
                  <div class="divider"></div>
                  <span class="product-field" style="color:#00a2d1 !important">{{'result.leftresult-insertunit' |
                    translate}} {{i+1}}</span>
                  <!-- PRODUCT TYPE -->
                  <span class="product-description">{{glass.VentOperableType}}</span>

                  <span class="product-field">{{'configure.window-systems' | translate | uppercase }}</span>
                  <!-- SYSTEM NAME -->
                  <span class="product-description">{{glass.InsertedWindowType}}</span>

                  <span class="product-field">DIRECTION</span>
                  <!-- DIMENSION -->
                  <span class="product-description">{{glass.VentOpeningDirection}}</span>
                </div>

              </span>
            </div>
          </bps-modal>
        </div>
      </div>

    </div>
    <div class="sps-solver-configuration-tab-container">
      <!-- <div id="sps-solver-configuration-tab" class="hoverPointer hidden">
              
          </div> -->
    </div>
  </div>
  <div *ngIf="unified3DModel" style="height: calc(100vh - 172px); overflow-y: auto;">
    <app-left-acoustic-panel [ngClass]="{'hidden': physicsType != 'A'}" [unified3DModel]="unified3DModel">
    </app-left-acoustic-panel>
    <app-left-structural-panel [ngClass]="{'hidden': physicsType != 'B'}"
      (sendSelectedIntermediate)="onReceiveSelectedIntermediateGeneral($event)"
      (sendIntermediateRedColor)="onReceiveIntermediateRedColor($event)" [unified3DModel]="unified3DModel"
      [selectedStructuralIntermediate]="selectedStructuralIntermediate"
      [selectedStructuralIntermediateFacade]="selectedStructuralIntermediateFacade"></app-left-structural-panel>
    <app-left-thermal-panel [ngClass]="{'hidden': physicsType != 'C'}" [unified3DModel]="unified3DModel"
      [selectedThermalResultLabel]="selectedThermalResultLabel"></app-left-thermal-panel>
    <app-left-sustainability-panel [ngClass]="{'hidden': physicsType != 'D'}"></app-left-sustainability-panel>
  </div>

</div>




<!---->
<!-- <div>
  <app-left-physics-type></app-left-physics-type>
  <app-left-acoustic-panel></app-left-acoustic-panel>
  <app-left-structural-panel></app-left-structural-panel>
  <app-left-thermal-panel></app-left-thermal-panel>
</div> -->