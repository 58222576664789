import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AppconstantsService } from 'src/app/app-common/services/appconstants.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FramingService {
  constructor(private localStorageService: LocalStorageService, private appConstantService: AppconstantsService, private http: HttpClient) { }
  language = (this.localStorageService.getValue('culture')) ? this.localStorageService.getValue('culture') : 'en-US';
  reinforcementData: any = [];
  private sujDisableReinforcement: Subject<Boolean> = new Subject<Boolean>();
  obsDisableReinforcement = this.sujDisableReinforcement.asObservable();
  transomreinforcementData: any = [];
  private sujDisableTransomReinforcement: Subject<Boolean> = new Subject<Boolean>();
  obsDisableTransomReinforcement = this.sujDisableTransomReinforcement.asObservable();

  readonly gasketSelections: any = [
    {Value:'AIF', Option: 1},
    {Value:'AGF', Option: 2},
    {Value:'B', Option: 3},
    {Value:'C', Option: 4}
  ];
  readonly insulationLevels: any = [
    {Value: 'THi1', Level: 1},
    {Value: 'THi2', Level: 2},
    {Value: 'THi3', Level: 3},
  ];

  //#region API Calls
  getOuterFramesForSystem(systemName: string): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetOuterFramesForSystem/" + systemName;
    return this.http.get<string>(url);
  }
  getVentFramesForSystem(systemName: string): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetVentFramesForSystem/" + systemName;
    return this.http.get<string>(url);
  }
  getMullionFramesForSystem(systemName: string): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetMullionTransomForSystem/" + systemName;
    return this.http.get<string>(url);
  }
  getFacadeInsertUnit(systemName: string): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetFacadeInsertUnit";
    return this.http.get<string>(url);
  }
  getFacadeProfile(): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetFacadeProfile";
    return this.http.get<string>(url);
  }
  getFacadeSpacer(): Observable<string> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetFacadeSpacer";
    return this.http.get<string>(url);
  }
  getADSArticlesList(systemName: string): Observable<any> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemName;
    return this.http.get<any>(url);
  }
  getASEArticlesList(systemName: string): Observable<string> { 
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemName;   // sliding data not in the db
    return this.http.get<string>(url);
  }
  GetDoorHandleArticles(): Observable<any> {
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetDoorHandleHingeForSystem/";
    return this.http.get<any>(url);
  }

  getSlidingDoorArticles(): Observable<any> {
    let slidingDoorArticlesList: any = [];
    slidingDoorArticlesList.push({'ArticleSetID': 1, 'ArticleName':'269400', 'Color': 'Silver Grey - RAL 7001',   'ColorCode':'RAL 7001', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Handle Recess'});
    slidingDoorArticlesList.push({'ArticleSetID': 2, 'ArticleName':'269401', 'Color': 'Jet Black - RAL 9005',     'ColorCode':'RAL 9005', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Handle Recess'});
    slidingDoorArticlesList.push({'ArticleSetID': 3, 'ArticleName':'269402', 'Color': 'Pure White - RAL 9010',    'ColorCode':'RAL 9010', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Handle Recess'});
    slidingDoorArticlesList.push({'ArticleSetID': 4, 'ArticleName':'269403', 'Color': 'Traffic White - RAL 9016', 'ColorCode':'RAL 9016', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Handle Recess'});
    slidingDoorArticlesList.push({'ArticleSetID': 5, 'ArticleName':'269404', 'Color': 'INOX',                     'ColorCode':'INOX',     'Type': 'Lift-and-Slide Fittings', 'Description': 'Handle Recess'}); 
    slidingDoorArticlesList.push({'ArticleSetID': 6, 'ArticleName':'269411', 'Color': 'Silver Grey - RAL 7001',   'ColorCode':'RAL 7001', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Crank Handle'});
    slidingDoorArticlesList.push({'ArticleSetID': 7, 'ArticleName':'269412', 'Color': 'Jet Black - RAL 9005',     'ColorCode':'RAL 9005', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Crank Handle'});
    slidingDoorArticlesList.push({'ArticleSetID': 8, 'ArticleName':'269413', 'Color': 'Pure White - RAL 9010',    'ColorCode':'RAL 9010', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Crank Handle'});
    slidingDoorArticlesList.push({'ArticleSetID': 9, 'ArticleName':'269414', 'Color': 'Traffic White - RAL 9016', 'ColorCode':'RAL 9016', 'Type': 'Lift-and-Slide Fittings', 'Description': 'Crank Handle'});
    slidingDoorArticlesList.push({'ArticleSetID': 10, 'ArticleName':'269415', 'Color': 'INOX',                     'ColorCode':'INOX',     'Type': 'Lift-and-Slide Fittings', 'Description': 'Crank Handle'});    
       
        return of(JSON.stringify(slidingDoorArticlesList));
  }
  //#endregion
  
  //#region Static Data
  getMaxDepth(unified3DModel: any): number {
    if (unified3DModel.ProblemSetting.ProductType === "Window" && unified3DModel.ModelInput.Geometry.Sections.length > 0) {
      var oDepth = unified3DModel.ModelInput.Geometry.Sections[0].Depth;
      var mDepth = unified3DModel.ModelInput.Geometry.Sections[1].Depth;
      var tDepth = unified3DModel.ModelInput.Geometry.Sections[2].Depth;
      oDepth = oDepth == undefined ? 0 : oDepth;
      mDepth = mDepth == undefined ? 0 : mDepth;
      tDepth = tDepth == undefined ? 0 : tDepth;
      return [oDepth, mDepth, tDepth].sort(function (a, b) { return b - a })[0];
    }
    else if (unified3DModel.ProblemSetting.ProductType === "Facade" && unified3DModel.ModelInput.Geometry.FacadeSections.length > 0) {
      var oDepth = unified3DModel.ModelInput.Geometry.FacadeSections[0].Depth;
      var mDepth = unified3DModel.ModelInput.Geometry.FacadeSections[1].Depth;
      var tDepth = unified3DModel.ModelInput.Geometry.FacadeSections[2].Depth;
      oDepth = oDepth == undefined ? 0 : oDepth;
      mDepth = mDepth == undefined ? 0 : mDepth;
      tDepth = tDepth == undefined ? 0 : tDepth;
      return [oDepth, mDepth, tDepth].sort(function (a, b) { return b - a })[0];
    }
  }

  FilterLogicOnTransom(system: string, articleList: any, mullionId: string,hasIntermediateMullion: boolean, mullionDepth: number): any{
    if(hasIntermediateMullion){
      var lowestDepthAllowed = 90;
      var largestDepthAllowed = 155;
      switch(system){
        case "FWS 35":
        case "FWS 35 PD":
          largestDepthAllowed = 155;
          break;
        case "FWS 50":
          largestDepthAllowed = 205;
          break;
        case "FWS 60":
          largestDepthAllowed = 155;
          break;
      }
      return articleList.filter(article => article.Mullion == mullionId 
                                        && article.Transom_Depth <= largestDepthAllowed && article.Transom_Depth >= lowestDepthAllowed
                                        && article.Transom_Depth <= mullionDepth + 5)
                        .map(article => new FacadeTransom(article.Transom, article.Transom_Depth));
    } else{
      return articleList.filter(article => article.Mullion == mullionId 
                                        && article.Transom_Depth <= mullionDepth + 5)
                        .map(article => new FacadeTransom(article.Transom, article.Transom_Depth));
    }
    

  }
  FilterLogicOnInetermediateMullionDepth(articleList: any, mullionId: string, transomDepth: number){
      //intermediate mullion depth should be always less than or equal to (transom depth -6)
      //i.e. intermediate mullion depth < = (transom depth -6)
    return articleList.filter(article => article.Mullion == mullionId && article.Transom_Depth <= (transomDepth - 6))
                      .map(article => new FacadeTransom(article.Transom, article.Transom_Depth));
  }

  removeDuplicates(arr) {
    if (arr == undefined) return arr;
    const uniqueArray = [];
    const seenObjects = new Set();  
    for (const item of arr) {
      const itemString = JSON.stringify(item);
      
      if (!seenObjects.has(itemString)) {
        seenObjects.add(itemString);
        uniqueArray.push(item);
      }
    }  
    return uniqueArray;
  }

  getSystemShortName(system: string): string {
    return system.split(' ').join('').substring(0, 5);
  }

  getFacade_MullionList(system: string): Observable<any> {
    let systemShort: string = this.getSystemShortName(system);
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemShort;
    return this.http.get<any>(url).pipe(map((response) => {
      let articleList: any = JSON.parse(response);
      let resp: FacadeMullion[] = [];
      resp = articleList.map(article => new FacadeMullion(article.Mullion, article.Mullion_Depth));
      resp = this.removeDuplicates(resp);
      return resp;
    }));
  }

  getFacade_TransomList(system: string, mullionId: string, hasIntermediateMullion: boolean, mullionDepth: number = undefined, applicationType = 'BPS'): Observable<any> {
    let systemShort: string = this.getSystemShortName(system);
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemShort;
    return this.http.get<any>(url).pipe(map((response) => {
      let articleList: any = JSON.parse(response);
      let resp: FacadeTransom[] = [];
      resp = this.FilterLogicOnTransom(system, articleList, mullionId, hasIntermediateMullion, mullionDepth)
      //resp = articleList.filter(article => article.Mullion == mullionId).map(article => new FacadeTransom(article.Transom, article.Transom_Depth));
      resp = this.removeDuplicates(resp);
      return resp;
    }));
  }

  getFacade_Level2_Intermediate_MullionList(system: string, mullionId: string): Observable<any> {
    let systemShort: string = this.getSystemShortName(system);
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemShort;
    return this.http.get<any>(url).pipe(map((response) => {
      let articleList: any = JSON.parse(response);
      let resp: FacadeTransom[] = [];
      resp = articleList.filter(article => article.Mullion == mullionId).map(article => new FacadeTransom(article.Transom, article.Transom_Depth));
      resp = this.removeDuplicates(resp);
      return resp;
    }));
    // let facadeTransomList: FacadeTransom[] = [];
    // system = this.getFWSSystemType(system);
    // switch (system) {
    //   case "FWS 35":
    //     switch (mullionId) {
    //       case "477750": //65
    //         return of(facadeTransomList);
    //       case "477760": //85
    //         facadeTransomList.push(new FacadeTransom(434200, 84));
    //         return of(facadeTransomList);
    //       case "477770": //105
    //         facadeTransomList.push(new FacadeTransom(434200, 84));
    //         facadeTransomList.push(new FacadeTransom(434180, 104));
    //         return of(facadeTransomList);
    //       case "477780": //125
    //         facadeTransomList.push(new FacadeTransom(434200, 84));
    //         facadeTransomList.push(new FacadeTransom(434180, 104));//NEW
    //         facadeTransomList.push(new FacadeTransom(434210, 124));
    //         return of(facadeTransomList);
    //       case "477790": //150
    //         facadeTransomList.push(new FacadeTransom(434200, 84));
    //         facadeTransomList.push(new FacadeTransom(434180, 104));//NEW
    //         facadeTransomList.push(new FacadeTransom(434210, 124)); //NEW
    //         facadeTransomList.push(new FacadeTransom(434220, 149));
    //         return of(facadeTransomList);
    //     }
    //   case "FWS 50":
    //     switch (mullionId) {
    //       case "322250": // OLD
    //       case "536800":
    //         return of(facadeTransomList);
    //       case "322260": // OLD
    //       case "536810":
    //         return of(facadeTransomList);
    //       case "322270": // OLD
    //       case "536820":
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         return of(facadeTransomList);
    //       case "322280": // OLD
    //       case "536830":
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         return of(facadeTransomList);
    //       case "536720":
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));//NEW
    //         return of(facadeTransomList);
    //       case "322290": // OLD
    //       case "536840":
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));//NEW
    //         facadeTransomList.push(new FacadeTransom(322350, 124));
    //         return of(facadeTransomList);
    //       case "322300": // OLD
    //       case "536850":
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));//NEW
    //         facadeTransomList.push(new FacadeTransom(322350, 124));
    //         facadeTransomList.push(new FacadeTransom(322360, 149));
    //         return of(facadeTransomList);
    //       case "322310": // OLD
    //       case "536270":
    //       case "536320": // NEW
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));//NEW
    //         facadeTransomList.push(new FacadeTransom(322350, 124));
    //         facadeTransomList.push(new FacadeTransom(322360, 149));
    //         facadeTransomList.push(new FacadeTransom(493110, 174));
    //         return of(facadeTransomList);
    //       // ------------- Obsolete -------------------------------
    //       // case "326050":
    //       //   facadeTransomList.push(new FacadeTransom(322330, 84));
    //       //   facadeTransomList.push(new FacadeTransom(322340, 104));
    //       //   facadeTransomList.push(new FacadeTransom(536790, 114));
    //       //   facadeTransomList.push(new FacadeTransom(322350, 124));
    //       //   facadeTransomList.push(new FacadeTransom(322360, 149));
    //       //   facadeTransomList.push(new FacadeTransom(493110, 174));
    //       //   return of(facadeTransomList);
    //       case "326030": // OLD
    //       case "536380":
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));
    //         facadeTransomList.push(new FacadeTransom(322350, 124));
    //         facadeTransomList.push(new FacadeTransom(322360, 149));
    //         facadeTransomList.push(new FacadeTransom(493110, 174));
    //         facadeTransomList.push(new FacadeTransom(493120, 199));
    //         return of(facadeTransomList);
    //       case "336230": // OLD
    //       case "536470": // NEW
    //       case "536480": // REMOVE
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));
    //         facadeTransomList.push(new FacadeTransom(322350, 124));
    //         facadeTransomList.push(new FacadeTransom(322360, 149));
    //         facadeTransomList.push(new FacadeTransom(493110, 174));
    //         facadeTransomList.push(new FacadeTransom(493120, 199));
    //         return of(facadeTransomList);
    //       case "336240": // OLD
    //       case "536180": // NEW
    //       case "536510": // REMOVE
    //         facadeTransomList.push(new FacadeTransom(322330, 84));
    //         facadeTransomList.push(new FacadeTransom(322340, 104));
    //         facadeTransomList.push(new FacadeTransom(536790, 114));
    //         facadeTransomList.push(new FacadeTransom(322350, 124));
    //         facadeTransomList.push(new FacadeTransom(322360, 149));
    //         facadeTransomList.push(new FacadeTransom(493110, 174));
    //         facadeTransomList.push(new FacadeTransom(493120, 199));
    //         return of(facadeTransomList);
    //     }
    //   case "FWS 60":
    //     switch (mullionId) {
    //       case "324010": case "543000":
    //         return of(facadeTransomList);
    //       case "324020": case "543010":
    //         return of(facadeTransomList);
    //       case "324030": case "543020":
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         return of(facadeTransomList);
    //       case "324040": case "543030":
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         return of(facadeTransomList);
    //       case "324050": case "543040":
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         facadeTransomList.push(new FacadeTransom(324530, 124));
    //         return of(facadeTransomList);
    //       case "324060": case "543050":
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         facadeTransomList.push(new FacadeTransom(324530, 124));
    //         facadeTransomList.push(new FacadeTransom(324540, 149));
    //         return of(facadeTransomList);
    //       case "324070": case "536540":
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         facadeTransomList.push(new FacadeTransom(324530, 124));
    //         facadeTransomList.push(new FacadeTransom(324540, 149));
    //         return of(facadeTransomList);
    //       case "324080": case "536580":
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         facadeTransomList.push(new FacadeTransom(324530, 124));
    //         facadeTransomList.push(new FacadeTransom(324540, 149));
    //         return of(facadeTransomList);
    //       case "324090": // OLD
    //       case "536620": // NEW
    //       case "536630": // REMOVE
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         facadeTransomList.push(new FacadeTransom(324530, 124));
    //         facadeTransomList.push(new FacadeTransom(324540, 149));
    //         return of(facadeTransomList);
    //       case "336270": // OLD
    //       case "536660": // NEW
    //       case "536670": // REMOVE
    //         facadeTransomList.push(new FacadeTransom(324510, 84));
    //         facadeTransomList.push(new FacadeTransom(324520, 104));
    //         facadeTransomList.push(new FacadeTransom(324530, 124));
    //         facadeTransomList.push(new FacadeTransom(324540, 149));
    //         return of(facadeTransomList);
    //     }
    //   default:
    //     facadeTransomList.push(new FacadeTransom(477750, 65));
    //     facadeTransomList.push(new FacadeTransom(477760, 85));
    //     facadeTransomList.push(new FacadeTransom(477770, 105));
    //     facadeTransomList.push(new FacadeTransom(477780, 125));
    //     facadeTransomList.push(new FacadeTransom(477790, 150));
    //     return of(facadeTransomList);
    // }
  }

  getFacade_Intermediate_MullionList(system: string, mullionId: string, transomDepth: number): Observable<any> {
    let systemShort: string = this.getSystemShortName(system);
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemShort;
    return this.http.get<any>(url).pipe(map((response) => {
      let articleList: any = JSON.parse(response);
      let resp: FacadeTransom[] = [];
      if(transomDepth)
        resp = this.FilterLogicOnInetermediateMullionDepth(articleList, mullionId, transomDepth);
      else
        resp = articleList.filter(article => article.Mullion == mullionId).map(article => new FacadeTransom(article.Transom, article.Transom_Depth));
      resp = this.removeDuplicates(resp);
      return resp;
    }));
    // let facacdeMullionList: FacadeTransom[] = [];
    // system = this.getFWSSystemType(system);
    // switch (system) {
    //   case "FWS 35":
    //     switch (mullionId) {
    //       case "477750":
    //         //facacdeMullionList.push(new FacadeTransom(477800, 70));
    //         return of(facacdeMullionList);
    //       case "477760":
    //         facacdeMullionList.push(new FacadeTransom(477800, 70));
    //         facacdeMullionList.push(new FacadeTransom(477810, 90));
    //         return of(facacdeMullionList);
    //       case "477770":
    //         facacdeMullionList.push(new FacadeTransom(477800, 70));
    //         facacdeMullionList.push(new FacadeTransom(477810, 90));
    //         facacdeMullionList.push(new FacadeTransom(477820, 110));
    //         return of(facacdeMullionList);
    //       case "477780":
    //         facacdeMullionList.push(new FacadeTransom(477800, 70));
    //         facacdeMullionList.push(new FacadeTransom(477810, 90));
    //         facacdeMullionList.push(new FacadeTransom(477820, 110));
    //         facacdeMullionList.push(new FacadeTransom(477830, 130));
    //         return of(facacdeMullionList);
    //       case "477790":
    //         facacdeMullionList.push(new FacadeTransom(477800, 70));
    //         facacdeMullionList.push(new FacadeTransom(477810, 90));
    //         facacdeMullionList.push(new FacadeTransom(477820, 110));
    //         facacdeMullionList.push(new FacadeTransom(477830, 130));
    //         facacdeMullionList.push(new FacadeTransom(477840, 155));
    //         return of(facacdeMullionList);
    //     }
    //   case "FWS 50":
    //     switch (mullionId) {
    //       case "322250": case "536800":
    //         // facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         // facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         // facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         // facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         //facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         return of(facacdeMullionList);
    //       case "322260": case "536810":
    //         // facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         // facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         // facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         // facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         // facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         //facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         return of(facacdeMullionList);
    //       case "322270": case "536820":
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         return of(facacdeMullionList);
    //       case "322280": case "536830":
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         return of(facacdeMullionList);
    //       case "536720": //new data..........
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //       case "322290": case "536840":
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //         facacdeMullionList.push(new FacadeTransom(322430, 130));
    //         return of(facacdeMullionList);
    //       case "322300": case "536850":
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //         facacdeMullionList.push(new FacadeTransom(322430, 130));
    //         facacdeMullionList.push(new FacadeTransom(322440, 155));
    //         return of(facacdeMullionList);
    //       case "322310": // OLD
    //       case "536270": // NEW
    //       case "536320": // NEW
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //         facacdeMullionList.push(new FacadeTransom(322430, 130));
    //         facacdeMullionList.push(new FacadeTransom(322440, 155));
    //         facacdeMullionList.push(new FacadeTransom(322450, 180));
    //         return of(facacdeMullionList);
    //       // ------------- Obsolete -------------------------------
    //       // case "326050":
    //       //   facacdeMullionList.push(new FacadeTransom(322370, 6));
    //       //   facacdeMullionList.push(new FacadeTransom(322380, 21));
    //       //   facacdeMullionList.push(new FacadeTransom(322460, 27));
    //       //   facacdeMullionList.push(new FacadeTransom(323840, 45));
    //       //   facacdeMullionList.push(new FacadeTransom(322390, 55));
    //       //   facacdeMullionList.push(new FacadeTransom(322400, 70));
    //       //   facacdeMullionList.push(new FacadeTransom(322410, 90));
    //       //   facacdeMullionList.push(new FacadeTransom(322420, 110));
    //       //   facacdeMullionList.push(new FacadeTransom(536750, 120));
    //       //   facacdeMullionList.push(new FacadeTransom(322430, 130));
    //       //   facacdeMullionList.push(new FacadeTransom(322440, 155));
    //       //   facacdeMullionList.push(new FacadeTransom(322450, 180));
    //       //   return of(facacdeMullionList);
    //       case "326030": case "536380":
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //         facacdeMullionList.push(new FacadeTransom(322430, 130));
    //         facacdeMullionList.push(new FacadeTransom(322440, 155));
    //         facacdeMullionList.push(new FacadeTransom(322450, 180));
    //         facacdeMullionList.push(new FacadeTransom(449590, 205));
    //         return of(facacdeMullionList);
    //       case "336230": // OLD
    //       case "536470": // NEW
    //       case "536480": // REMOVE //225 depth
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //         facacdeMullionList.push(new FacadeTransom(322430, 130));
    //         facacdeMullionList.push(new FacadeTransom(322440, 155));
    //         facacdeMullionList.push(new FacadeTransom(322450, 180));
    //         facacdeMullionList.push(new FacadeTransom(449590, 205));
    //         // facacdeMullionList.push(new FacadeTransom(449600, 230));
    //         // facacdeMullionList.push(new FacadeTransom(505020, 230));
    //         return of(facacdeMullionList);
    //       case "336240": // OLD
    //       case "536180": // NEW
    //       case "536510": // REMOVE //250 depth
    //         facacdeMullionList.push(new FacadeTransom(322370, 6));
    //         facacdeMullionList.push(new FacadeTransom(322380, 21));
    //         facacdeMullionList.push(new FacadeTransom(322460, 27));
    //         facacdeMullionList.push(new FacadeTransom(323840, 45));
    //         facacdeMullionList.push(new FacadeTransom(322390, 55));
    //         facacdeMullionList.push(new FacadeTransom(322400, 70));
    //         facacdeMullionList.push(new FacadeTransom(322410, 90));
    //         facacdeMullionList.push(new FacadeTransom(322420, 110));
    //         facacdeMullionList.push(new FacadeTransom(536750, 120));
    //         facacdeMullionList.push(new FacadeTransom(322430, 130));
    //         facacdeMullionList.push(new FacadeTransom(322440, 155));
    //         facacdeMullionList.push(new FacadeTransom(322450, 180));
    //         facacdeMullionList.push(new FacadeTransom(449590, 205));
    //         // facacdeMullionList.push(new FacadeTransom(449600, 230));
    //         // facacdeMullionList.push(new FacadeTransom(505020, 230));
    //         // facacdeMullionList.push(new FacadeTransom(449610, 255));
    //         // facacdeMullionList.push(new FacadeTransom(505030, 255));
    //         return of(facacdeMullionList);
    //     }
    //   case "FWS 60":
    //     switch (mullionId) {
    //       case "324010": case "543000": //50 depth
    //         // facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         // facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         // facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         // facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         //facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         return of(facacdeMullionList);
    //       case "324020": case "543010": //65 depth
    //         // facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         // facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         // facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         // facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         // facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         //facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         return of(facacdeMullionList);
    //       case "324030": case "543020":
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         return of(facacdeMullionList);
    //       case "324040": case "543030":
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         return of(facacdeMullionList);
    //       case "324050": case "543040":
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         facacdeMullionList.push(new FacadeTransom(324480, 130));
    //         return of(facacdeMullionList);
    //       case "324060": case "543050":
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         facacdeMullionList.push(new FacadeTransom(324480, 130));
    //         facacdeMullionList.push(new FacadeTransom(324490, 155));
    //         return of(facacdeMullionList);
    //       case "324070": case "536540":
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         facacdeMullionList.push(new FacadeTransom(324480, 130));
    //         facacdeMullionList.push(new FacadeTransom(324490, 155));
    //         //facacdeMullionList.push(new FacadeTransom(324500, 180));
    //         return of(facacdeMullionList);
    //       case "324080": case "536580":
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         facacdeMullionList.push(new FacadeTransom(324480, 130));
    //         facacdeMullionList.push(new FacadeTransom(324490, 155));
    //         //------facacdeMullionList.push(new FacadeTransom(324500, 180));
    //         //facacdeMullionList.push(new FacadeTransom(326940, 205));
    //         return of(facacdeMullionList);
    //       case "324090": // OLD
    //       case "536620": // NEW
    //       case "536630": // REMOVE
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         facacdeMullionList.push(new FacadeTransom(324480, 130));
    //         facacdeMullionList.push(new FacadeTransom(324490, 155));
    //         //------facacdeMullionList.push(new FacadeTransom(324500, 180));
    //         //------facacdeMullionList.push(new FacadeTransom(326940, 205));
    //         //facacdeMullionList.push(new FacadeTransom(493680, 230));
    //         return of(facacdeMullionList);
    //       case "336270": // OLD
    //       case "536660": // NEW
    //       case "536670": // REMOVE
    //         facacdeMullionList.push(new FacadeTransom(324400, 6));
    //         facacdeMullionList.push(new FacadeTransom(324410, 21));
    //         facacdeMullionList.push(new FacadeTransom(324420, 27));
    //         facacdeMullionList.push(new FacadeTransom(324430, 45));
    //         facacdeMullionList.push(new FacadeTransom(324440, 55));
    //         facacdeMullionList.push(new FacadeTransom(324450, 70));
    //         facacdeMullionList.push(new FacadeTransom(324460, 90));
    //         facacdeMullionList.push(new FacadeTransom(324470, 110));
    //         facacdeMullionList.push(new FacadeTransom(324480, 130));
    //         facacdeMullionList.push(new FacadeTransom(324490, 155));
    //         //------ facacdeMullionList.push(new FacadeTransom(324500, 180));
    //         //------ facacdeMullionList.push(new FacadeTransom(326940, 205));
    //         //------ facacdeMullionList.push(new FacadeTransom(493680, 230));
    //         //facacdeMullionList.push(new FacadeTransom(493690, 255));
    //         return of(facacdeMullionList);
    //     }
    //   default:
    //     facacdeMullionList.push(new FacadeTransom(477750, 65));
    //     facacdeMullionList.push(new FacadeTransom(477760, 85));
    //     facacdeMullionList.push(new FacadeTransom(477770, 105));
    //     facacdeMullionList.push(new FacadeTransom(477780, 125));
    //     facacdeMullionList.push(new FacadeTransom(477790, 150));
    //     return of(facacdeMullionList.filter(f => f.transomArticleId < parseInt(mullionId)));
    // }
  }

  getFacade_Mullion_ReinforcementList(system, mullionId: string): Observable<any> {
    let systemShort: string = this.getSystemShortName(system);
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemShort;
    return this.http.get<any>(url).pipe(map((response) => {
      let articleList: any = JSON.parse(response);
      let resp: FacadeTransom[] = [];
      resp = articleList.filter(article => article.Mullion == mullionId).reduce((acc, article) => {
        if (article.Mullion_Reinforcement_Type1 && article.Mullion_Reinforcement_Type1_Material) {
          acc.push(new FacadeMullionReinforcement(article.Mullion_Reinforcement_Type1, article.Mullion_Reinforcement_Type1_Material));
        }
        if (article.Mullion_Reinforcement_Type2 && article.Mullion_Reinforcement_Type2_Material) {
          acc.push(new FacadeMullionReinforcement(article.Mullion_Reinforcement_Type2, article.Mullion_Reinforcement_Type2_Material));
        }
        return acc;
      }, []);
      resp = this.removeDuplicates(resp);
      return resp;
    }));
    // let reinforcementList: FacadeMullionReinforcement[] = [];
    // switch (mullionId) {
    //   // FWS 35
    //   case "477770": reinforcementList.push(new FacadeMullionReinforcement(477870, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201310, "Steel")); return of(reinforcementList);
    //   case "477780": reinforcementList.push(new FacadeMullionReinforcement(477880, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201311, "Steel")); return of(reinforcementList);
    //   case "477790": reinforcementList.push(new FacadeMullionReinforcement(477890, "Aluminium")); return of(reinforcementList);

    //   //Facade New FWS 50 article data
    //   case "536800": return of(reinforcementList);
    //   case "536810": return of(reinforcementList);
    //   case "536820": reinforcementList.push(new FacadeMullionReinforcement(536890, "Aluminium")); return of(reinforcementList);
    //   case "536830": reinforcementList.push(new FacadeMullionReinforcement(536900, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201216, "Steel")); return of(reinforcementList);
    //   case "536720": reinforcementList.push(new FacadeMullionReinforcement(536740, "Aluminium")); return of(reinforcementList);
    //   case "536840": reinforcementList.push(new FacadeMullionReinforcement(536910, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201217, "Steel")); return of(reinforcementList);
    //   case "536850": reinforcementList.push(new FacadeMullionReinforcement(536920, "Aluminium")); return of(reinforcementList);
    //   case "536270": reinforcementList.push(new FacadeMullionReinforcement(536290, "Aluminium")); return of(reinforcementList);
    //   case "536320": reinforcementList.push(new FacadeMullionReinforcement(536370, "Aluminium")); return of(reinforcementList);
    //   case "536380": reinforcementList.push(new FacadeMullionReinforcement(536460, "Aluminium")); return of(reinforcementList);
    //   case "536470": reinforcementList.push(new FacadeMullionReinforcement(536500, "Aluminium")); return of(reinforcementList);
    //   case "536480": reinforcementList.push(new FacadeMullionReinforcement(536500, "Aluminium")); return of(reinforcementList);
    //   case "536180":
    //   case "536510": reinforcementList.push(new FacadeMullionReinforcement(536530, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(484010, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(484010, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(484010, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(484010, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(484010, "Aluminium")); return of(reinforcementList);
    //   // OLD FWS 50 --------------------------------
    //   case "322270": reinforcementList.push(new FacadeMullionReinforcement(322720, "Aluminium")); return of(reinforcementList);
    //   case "322280": reinforcementList.push(new FacadeMullionReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201216, "Steel")); return of(reinforcementList);
    //   case "322290": reinforcementList.push(new FacadeMullionReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201217, "Steel")); return of(reinforcementList);
    //   case "322300": reinforcementList.push(new FacadeMullionReinforcement(322750, "Aluminium")); return of(reinforcementList);
    //   case "322310": reinforcementList.push(new FacadeMullionReinforcement(322760, "Aluminium")); return of(reinforcementList);
    //   case "326250": reinforcementList.push(new FacadeMullionReinforcement(322760, "Aluminium")); return of(reinforcementList);
    //   case "326030": reinforcementList.push(new FacadeMullionReinforcement(326050, "Aluminium")); return of(reinforcementList);
    //   case "336230": reinforcementList.push(new FacadeMullionReinforcement(336250, "Aluminium")); return of(reinforcementList);
    //   case "336240": reinforcementList.push(new FacadeMullionReinforcement(336260, "Aluminium")); return of(reinforcementList);

    //   //Facade New FWS 60 article data
    //   case "543000": return of(reinforcementList);
    //   case "543010": return of(reinforcementList);
    //   case "543020": reinforcementList.push(new FacadeMullionReinforcement(543060, "Aluminium")); return of(reinforcementList);
    //   case "543030": reinforcementList.push(new FacadeMullionReinforcement(543070, "Aluminium")); return of(reinforcementList);
    //   case "543040": reinforcementList.push(new FacadeMullionReinforcement(543080, "Aluminium")); return of(reinforcementList);
    //   case "543050": reinforcementList.push(new FacadeMullionReinforcement(543090, "Aluminium")); return of(reinforcementList);
    //   case "536540": reinforcementList.push(new FacadeMullionReinforcement(536570, "Aluminium")); return of(reinforcementList);
    //   case "536580": reinforcementList.push(new FacadeMullionReinforcement(536610, "Aluminium")); return of(reinforcementList);
    //   case "536620": reinforcementList.push(new FacadeMullionReinforcement(536650, "Aluminium")); return of(reinforcementList);
    //   case "536630": reinforcementList.push(new FacadeMullionReinforcement(536650, "Aluminium")); return of(reinforcementList);
    //   case "536660": reinforcementList.push(new FacadeMullionReinforcement(536690, "Aluminium"));reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); return of(reinforcementList);
    //   case "536670": reinforcementList.push(new FacadeMullionReinforcement(536690, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); return of(reinforcementList);
    //   // OLD FWS 60 --------------------------------
    //   case "324030": reinforcementList.push(new FacadeMullionReinforcement(324300, "Aluminium")); return of(reinforcementList)
    //   case "324040": reinforcementList.push(new FacadeMullionReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(201216, "Steel")); return of(reinforcementList)
    //   case "324050": reinforcementList.push(new FacadeMullionReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeMullionReinforcement(301217, "Steel")); return of(reinforcementList)
    //   case "324060": reinforcementList.push(new FacadeMullionReinforcement(324330, "Aluminium")); return of(reinforcementList)
    //   case "324070": reinforcementList.push(new FacadeMullionReinforcement(324340, "Aluminium")); return of(reinforcementList)
    //   case "324080": reinforcementList.push(new FacadeMullionReinforcement(324350, "Aluminium")); return of(reinforcementList)
    //   case "324090": reinforcementList.push(new FacadeMullionReinforcement(324360, "Aluminium")); return of(reinforcementList)
    //   case "336270": reinforcementList.push(new FacadeMullionReinforcement(477570, "Aluminium")); return of(reinforcementList)
    //   default: return of(reinforcementList)
    // }
  }

  setReinforcementData(data) {
    this.reinforcementData = data;
    this.sujDisableReinforcement.next(this.reinforcementData.length == 0);
  }

  setTransomReinforcementData(data) {
    this.transomreinforcementData = data;
    this.sujDisableTransomReinforcement.next(this.transomreinforcementData.length == 0);
  }

  getFacade_Transom_ReinforcementList(system: string, transomId: string): Observable<any> {
    let systemShort: string = this.getSystemShortName(system);
    let url: string = this.appConstantService.APP_DOMAIN + "api/Article/GetArticlesForSystem/" + systemShort;
    return this.http.get<any>(url).pipe(map((response) => {
      let articleList: any = JSON.parse(response);
      let resp: FacadeTransom[] = [];
      resp = articleList.filter(article => article.Transom == transomId).reduce((acc, article) => {
        if (article.Transom_Reinforcement && article.Transom_Reinforcement_Material) {
          acc.push(new FacadeTransomReinforcement(article.Transom_Reinforcement, article.Transom_Reinforcement_Material));
        }
        return acc;
      }, []);
      resp = this.removeDuplicates(resp);
      return resp;
    }));
    // let reinforcementList: FacadeTransomReinforcement[] = [];
    // switch (transomId) {
    //   //FWS 50 Level1 Transoms
    //   case "322370": return of(reinforcementList);
    //   case "322380": return of(reinforcementList);
    //   case "322460": return of(reinforcementList);
    //   case "323840": return of(reinforcementList);
    //   case "322390": return of(reinforcementList);
    //   case "322400": return of(reinforcementList);
    //  // case "322410": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); return of(reinforcementList);
    //   case "322410": return of(reinforcementList);
    //   // case "322420": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); return of(reinforcementList);
    //   case "322420": return of(reinforcementList);
    //   // case "536750": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); return of(reinforcementList);
    //   case "536750": return of(reinforcementList);
    //   // case "322430": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); return of(reinforcementList);
    //   case "322430": reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); return of(reinforcementList);
    //   // case "322440": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322750, "Aluminium")); return of(reinforcementList);
    //   case "322440":  reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium"));  return of(reinforcementList);
    //   // case "322450": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322750, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322760, "Aluminium")); return of(reinforcementList);
    //   case "322450": reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); return of(reinforcementList);
    //   // case "449590": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322750, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322760, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(326050, "Aluminium")); return of(reinforcementList);
    //   case "449590": reinforcementList.push(new FacadeTransomReinforcement(351980, "Aluminium")); return of(reinforcementList);
    //   case "449600": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); return of(reinforcementList);
    //   case "449610": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); return of(reinforcementList);
      
    //   //FWS 50 Level2 Transoms
    //   case "322330": return of(reinforcementList);
    //   case "322340": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); return of(reinforcementList);
    //   case "536790": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); return of(reinforcementList);
    //   case "322350": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); return of(reinforcementList);
    //   case "322360": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); return of(reinforcementList);
    //   case "493110": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322750, "Aluminium")); return of(reinforcementList);
    //   case "493120": reinforcementList.push(new FacadeTransomReinforcement(322720, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322730, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201216, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322740, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(201217, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(322750, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(322760, "Aluminium")); return of(reinforcementList);

    //   // FWS 35 Level1 Transoms
    //   case "477800": return of(reinforcementList);
    //   case "477810": return of(reinforcementList);
    //   // case "477820": reinforcementList.push(new FacadeTransomReinforcement(477870, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201310, "Steel"));reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);
    //   // case "477830": reinforcementList.push(new FacadeTransomReinforcement(477870, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201310, "Steel"));reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(477880, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201311, "Steel")); return of(reinforcementList);
    //   // case "477840": reinforcementList.push(new FacadeTransomReinforcement(477870, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201310, "Steel"));reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(477880, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201311, "Steel")); reinforcementList.push(new FacadeTransomReinforcement(477890, "Aluminium")); return of(reinforcementList);
    //   case "477820": reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);
    //   case "477830": reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);
    //   case "477840": reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);
    //    //FWS 35 Level2 Transoms
    //   case "434200": return of(reinforcementList);
    //   case "434180": return of(reinforcementList);
    //   // case "434210": reinforcementList.push(new FacadeTransomReinforcement(477870, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201310, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);
    //   // case "434220": reinforcementList.push(new FacadeTransomReinforcement(477870, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201310, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(477880, "Aluminium"));reinforcementList.push(new FacadeTransomReinforcement(201311, "Steel")); return of(reinforcementList);
    //   case "434210": reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);
    //   case "434220": reinforcementList.push(new FacadeTransomReinforcement(477900, "Aluminium")); return of(reinforcementList);

      

    //   //FWS 60 Level1 Transoms
    //   case "324400": return of(reinforcementList);
    //   case "324410": return of(reinforcementList);
    //   case "324420": return of(reinforcementList);
    //   case "324430": return of(reinforcementList);
    //   case "324440": return of(reinforcementList);
    //   case "324450": return of(reinforcementList);
    //   // case "324460": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); return of(reinforcementList);
    //   case "324460": return of(reinforcementList);
    //   // case "324470": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); return of(reinforcementList);
    //   case "324470": return of(reinforcementList);
    //   // case "324480": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324320, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   // case "324490": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324320, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324330, "Aluminium")); return of(reinforcementList);
    //   // case "324500": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324320, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324330, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324340, "Aluminium")); return of(reinforcementList);
    //   // case "326940": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324320, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324330, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324340, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324350, "Aluminium")); return of(reinforcementList);
    //   // case "493680": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324320, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324330, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324340, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324350, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324360, "Aluminium")); return of(reinforcementList);
    //   case "324480": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   case "324490": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   case "324500": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   case "326940": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   case "493680": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   case "493690": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   //FWS 60 Level2 Transoms
    //   case "324510": return of(reinforcementList);
    //   case "324520": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); return of(reinforcementList);
    //   case "324530": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); return of(reinforcementList);
    //   // case "324540": reinforcementList.push(new FacadeTransomReinforcement(324300, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324310, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(324320, "Aluminium")); reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   case "324540": reinforcementList.push(new FacadeTransomReinforcement(336090, "Aluminium")); return of(reinforcementList);
    //   default: return of(reinforcementList)
    // }
  }

  // getFacade_Transom_ReinforcementList(system: string): Observable<any> {
  //   let reinforcementList: FacadeMullionReinforcement[] = [];
  //   system = this.getFWSSystemType(system);
  //   switch (system) {
  //     case "FWS 35": reinforcementList.push(new FacadeMullionReinforcement(477900, "Aluminium")); return of(reinforcementList);
  //     case "FWS 50": reinforcementList.push(new FacadeMullionReinforcement(351980, "Aluminium")); return of(reinforcementList);
  //     case "FWS 60": reinforcementList.push(new FacadeMullionReinforcement(336090, "Aluminium")); return of(reinforcementList);
  //   }
  // }

  getBottomFrameList(framingId: string): FacadeUDC {
    switch (framingId) {
      case "505200": return new FacadeUDC(505300, 0, 130);
      case "505210": return new FacadeUDC(505310, 0, 155);
      case "520720": return new FacadeUDC(520820, 0, 180);
      // case "505220": return new FacadeUDC(520820, 0, 180);
      case "505230": return new FacadeUDC(505330, 0, 205);
      //case "527230": return new FacadeUDC(527330, 0, 205);
      case "505240": return new FacadeUDC(505340, 0, 230);
      //case "527240": return new FacadeUDC(527340, 0, 230);
      case "505250": return new FacadeUDC(505350, 0, 255);
      //case "527250": return new FacadeUDC(527350, 0, 255);
      default:
        return null;
    }
  }

  getUDCFramingList(system: string, mullionId: string, type: string): Observable<any> {
    let facadeUDC80List: FacadeUDC[] = [];
    facadeUDC80List.push(new FacadeUDC(505200, 35, 130));
    facadeUDC80List.push(new FacadeUDC(505210, 35, 155));
    facadeUDC80List.push(new FacadeUDC(520720, 35, 180));
    facadeUDC80List.push(new FacadeUDC(505220, 35, 180));
    facadeUDC80List.push(new FacadeUDC(505230, 35, 205));
    facadeUDC80List.push(new FacadeUDC(505240, 35, 230));
    facadeUDC80List.push(new FacadeUDC(505250, 35, 255));

    let facadeUDCList: FacadeUDC[] = [];
    system = this.getUDCSystemType(system);
    switch (system) {
      case 'UDC 80':
        if (type === 'Framing') {
          facadeUDCList = facadeUDC80List;
        }
        else if (type === 'IntermediateMullion' || type === 'IntermediateTransom') {
          let selectedFrame = facadeUDC80List.filter(f => f.transomArticleId.toString() === mullionId);
          facadeUDCList.push(new FacadeUDC(505500, 60, 54, this.disabledTransomOrMullion(selectedFrame, 54)));
          facadeUDCList.push(new FacadeUDC(505510, 60, 79, this.disabledTransomOrMullion(selectedFrame, 79)));
          facadeUDCList.push(new FacadeUDC(505520, 60, 129, this.disabledTransomOrMullion(selectedFrame, 129)));
          facadeUDCList.push(new FacadeUDC(505530, 60, 154, this.disabledTransomOrMullion(selectedFrame, 154)));
          facadeUDCList.push(new FacadeUDC(505540, 60, 179, this.disabledTransomOrMullion(selectedFrame, 179)));
          facadeUDCList.push(new FacadeUDC(505550, 60, 204, this.disabledTransomOrMullion(selectedFrame, 204)));
          //facadeUDCList.push(new FacadeUDC(527550, 60, 204));
          facadeUDCList.push(new FacadeUDC(505560, 60, 229, this.disabledTransomOrMullion(selectedFrame, 229)));
          //facadeUDCList.push(new FacadeUDC(527560, 60));
          facadeUDCList.push(new FacadeUDC(505570, 60, 254, this.disabledTransomOrMullion(selectedFrame, 254)));
          //facadeUDCList.push(new FacadeUDC(527570, 60));

          facadeUDCList.push(new FacadeUDC(505400, 80, 54, this.disabledTransomOrMullion(selectedFrame, 54)));
          facadeUDCList.push(new FacadeUDC(505410, 80, 79, this.disabledTransomOrMullion(selectedFrame, 79)));
          facadeUDCList.push(new FacadeUDC(505420, 80, 129, this.disabledTransomOrMullion(selectedFrame, 129)));
          facadeUDCList.push(new FacadeUDC(505430, 80, 154, this.disabledTransomOrMullion(selectedFrame, 154)));
          facadeUDCList.push(new FacadeUDC(505440, 80, 179, this.disabledTransomOrMullion(selectedFrame, 179)));
          facadeUDCList.push(new FacadeUDC(505450, 80, 204, this.disabledTransomOrMullion(selectedFrame, 204)));
          //facadeUDCList.push(new FacadeUDC(527450, 80, 204));
          facadeUDCList.push(new FacadeUDC(505460, 80, 229, this.disabledTransomOrMullion(selectedFrame, 229)));
          //facadeUDCList.push(new FacadeUDC(527460, 80, 229));
          facadeUDCList.push(new FacadeUDC(505470, 80, 254, this.disabledTransomOrMullion(selectedFrame, 254)));
          //facadeUDCList.push(new FacadeUDC(527470, 80, 254));
        }
    }
    // if (mullionId !== null && type === 'IntermediateMullion') {
    //   let depth = facadeUDC80List.filter(f => f.transomArticleId.toString() === mullionId);
    //   if (depth.length > 0)
    //     facadeUDCList = facadeUDCList.filter(f => f.transomDepth <= depth[0].transomDepth);
    // }
    // else if (mullionId !== null && type === 'IntermediateTransom') {
    //   let depth = facadeUDCList.filter(f => f.transomArticleId.toString() === mullionId);
    //   if (depth.length > 0)
    //     facadeUDCList = facadeUDCList.filter(f => f.transomDepth <= depth[0].transomDepth);
    // }
    return of(facadeUDCList);
  }

  disabledTransomOrMullion(selectedFrame, depthGlazingBar): boolean {
    let isDisabled: boolean = false;
    if (selectedFrame && selectedFrame.length > 0 && depthGlazingBar > selectedFrame[0].transomDepth) {
      return true;
    }
    return isDisabled;
  }

  oldToNewFraming(oldArticle: number) {
    if (oldArticle == 322250) return 536800;
    else if (oldArticle == 322260) return 536810;
    else if (oldArticle == 322270) return 536820;
    else if (oldArticle == 322280) return 536830;
    else if (oldArticle == 322280) return 536720;
    else if (oldArticle == 322290) return 536840;
    else if (oldArticle == 322300) return 536850;
    else if (oldArticle == 322310) return 536270;
    else if (oldArticle == 322310) return 536320;
    else if (oldArticle == 326030) return 536380;
    else if (oldArticle == 336230) return 536470;
    else if (oldArticle == 336240) return 536180;
    else if (oldArticle == 324010) return 543000;
    else if (oldArticle == 324020) return 543010;
    else if (oldArticle == 324030) return 543020;
    else if (oldArticle == 324040) return 543030;
    else if (oldArticle == 324050) return 543040;
    else if (oldArticle == 324060) return 543050;
    else if (oldArticle == 324070) return 536540;
    else if (oldArticle == 324080) return 536580;
    else if (oldArticle == 324090) return 536620;
    else if (oldArticle == 336270) return 536660;
    //Facade Mullion Reinforcement
    else if (oldArticle == 336280) return 536690;
    else if (oldArticle == 324360) return 536650;
    else if (oldArticle == 324350) return 536610;
    else if (oldArticle == 324340) return 536570;

    else return oldArticle;
  }

  getFWSSystemType(systemType): string {
    var fwsSystemDesc = ['FWS 35', 'FWS 35', 'FWS 50', 'FWS 50', 'FWS 50', 'FWS 50', 'FWS 60', 'FWS 60', 'FWS 60', 'FWS 60'];
    var fwsSystemDesc2 = ['FWS 35 PD', 'FWS 35 PD', 'FWS 50', 'FWS 50', 'FWS 50', 'FWS 50', 'FWS 60', 'FWS 60', 'FWS 60', 'FWS 60'];
    var systemIndex = fwsSystemDesc2.indexOf(systemType);
    if (systemIndex != -1)
      return fwsSystemDesc[systemIndex];
    else
      return systemType;
  }

  getUDCSystemType(systemType): string {
    var systemData_FacadeUDC = ['UDC 80', 'UDC 80', 'UDC 80', 'UDC 80'];
    var systemDataUpper_FacadeUDC = ['UDC 80', 'UDC 80', 'UDC 80', 'UDC 80'];
    var systemIndex = systemDataUpper_FacadeUDC.indexOf(systemType);
    if (systemIndex != -1)
      return systemData_FacadeUDC[systemIndex];
    else
      return systemType;
  }
  
  getADSDoorLeafList(system: string): Observable<any> {
    let doorleafArticle= [{ "ArticleId":0,
    "ArticleGuid":null,
      "Name":"ads__476230",
      "Unit":"mm",
      "ArticleTypeId":1,
      "CrossSectionUrl":null,
      "Description":"Door Leaf 476230",
      "InsideDimension":73.0,
      "OutsideDimension":73.0,
      "Dimension":-1.0,
      "LeftRebate":-1.0,
      "RightRebate":-1.0,
      "DistBetweenIsoBars":-1.0,
      "Depth":-1.0}]
      return of(doorleafArticle);
    }
    //#endregion
}
//#region Article Models
class DoorLeafArticle {
  ArticleId: number;
  ArticleGuid: string;
  Name: string;
  Unit: string;
  ArticleTypeId: number;
  CrossSectionUrl: string;
  Description: string;
  InsideDimension: number;
  OutsideDimension: number;
  Dimension: number;
  LeftRebate: number;
  RightRebate: number;
  DistBetweenIsoBars: number;
  Depth: number;
  // "ArticleId":12,
  // "ArticleGuid":"0eb57216-c19c-46f4-934e-b986f7c56f45",
  // "Name":"article__382200",
  // "Unit":"mm",
  // "ArticleTypeId":1,
  // "CrossSectionUrl":null,
  // "Description":"Outer Frame 382200",
  // "InsideDimension":125.0,
  // "OutsideDimension":150.0,
  // "Dimension":-1.0,
  // "LeftRebate":-1.0,
  // "RightRebate":-1.0,
  // "DistBetweenIsoBars":119.8,
  // "Depth":-75.0
}
class FacadeMullion {
  mullionArticleId: number;
  mullionDepth: number;
  moreInfo: string = '';
  constructor(articleId_mullion = null, depth_mullion = null, moreInfo = '') {
    this.mullionArticleId = articleId_mullion;
    this.mullionDepth = depth_mullion;
    this.moreInfo = moreInfo;
  }
}
class FacadeTransom {
  transomArticleId: number;
  transomDepth: number;
  moreInfo: string = '';
  constructor(articleId_transom = null, depth_transom = null, moreInfo = '') {
    this.transomArticleId = articleId_transom;
    this.transomDepth = depth_transom;
    this.moreInfo = moreInfo;
  }
}
class FacadeMullionReinforcement {
  value: number;
  material: string;
  moreInfo: string = '';
  constructor(val = null, mat = null, moreInfo = '') {
    this.value = val;
    this.material = mat;
    this.moreInfo = moreInfo;
  }
}

class FacadeTransomReinforcement {
  value: number;
  material: string;
  moreInfo: string = '';
  constructor(val = null, mat = null, moreInfo = '') {
    this.value = val;
    this.material = mat;
    this.moreInfo = moreInfo;
  }
}
class FacadeUDC {
  transomArticleId: number;
  transomDepth: number;
  transomWidth: number;
  moreInfo: string = '';
  disabled: boolean
  constructor(articleId_transom = null, width_transom = null, depth_transom = null, disabled = false, moreInfo = '') {
    this.transomArticleId = articleId_transom;
    this.transomWidth = width_transom;
    this.transomDepth = depth_transom;
    this.disabled = disabled;
    this.moreInfo = moreInfo;
  }
}
//#endregion
