import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { IframeService } from 'src/app/app-structural/services/iframe.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { Acoustic } from 'src/app/app-common/models/unified-model/Acoustic';

@Component({
  selector: 'app-acoustic',
  templateUrl: './acoustic.component.html',
  styleUrls: ['./acoustic.component.css']
})
export class AcousticComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() wallType: string;
  height: number;
  width: number;
  roomArea: number;
  heightString: string;
  widthString: string;
  roomAreaString: string;
  @Input() unified3DModel: UnifiedModel;
  @Input() iframeEvent: any;
  // @Input() displayContentAcoustic: boolean;
  @Input() canBeDrawnBool: boolean;
  @Output() unified3DModelFromAcousticEvent: EventEmitter<UnifiedModel> = new EventEmitter<UnifiedModel>();
  wallPerimeterEffectChecked: boolean = false;
  validateForm: FormGroup;
  language: string;
  patternLanguage: string;
  wallTypeNumberClicks: number = 0;
  private destroy$ = new Subject<void>();
  isClassificationOpened: boolean = false
  classificationString: string;
  classificationValue: number;

  constructor(
    private umService: UnifiedModelService,
    private cpService: ConfigPanelsService,
    private fb: FormBuilder,
    private configureService: ConfigureService,
    private iframeService: IframeService, private translate: TranslateService) {
    this.language = this.configureService.getLanguage()
    this.patternLanguage = this.configureService.getNumberPattern();
    this.validateForm = this.fb.group({
      wallType: ['', [Validators.required]],
      height: ['', [Validators.required, Validators.pattern(this.patternLanguage)]],
      width: ['', [Validators.required, Validators.pattern(this.patternLanguage)]],
      roomArea: ['', [Validators.required, Validators.pattern(this.patternLanguage)]]
    });
  }

  ngOnInit(): void {
    if (!this.umService.isFacadeUDC()) this.loadAcoustic();
  }

  ngAfterViewInit(): void {
    this.umService.obsLoadSidePanel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response && response.panelsModule > -1 && response.finishedLoading && !this.umService.isFacadeUDC()) {
          if (response.panelsModule === PanelsModule.ClassificationTable) this.getClassificationValueNString();
        }
      }
    );
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.panelsModule === PanelsModule.ClassificationTable) this.isClassificationOpened = response.isOpened;
      }
    );
  }

  onOpenIFramePopout(popup: string): void {
    if (popup === 'Calculation') this.cpService.setPopout(true, PanelsModule.ClassificationTable);
  }

  getClassificationValueNString() {
    this.classificationValue = this.umService.getAcousticClassificationValue();
    switch (this.classificationValue) {
      case 1:
        this.classificationString = this.translate.instant(_('configure.calculation-method-US-Standard'));
        break;
      case 2:
        this.classificationString = this.translate.instant(_('configure.calculation-method-European-Standard'));
        break;
    }
  }

  loadAcoustic() {
    this.validateForm.disable();
    if (this.unified3DModel && this.unified3DModel.ModelInput && this.unified3DModel.ModelInput.Acoustic && this.unified3DModel.ModelInput.Acoustic.WallType) {
       this.validateForm.enable();
      for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
      this.wallPerimeterEffectChecked = true
      this.height = this.unified3DModel.ModelInput.Acoustic.Height;
      this.roomArea = this.unified3DModel.ModelInput.Acoustic.RoomArea;
      this.wallType = this.unified3DModel.ModelInput.Acoustic.WallType ?
        this.unified3DModel.ModelInput.Acoustic.WallType.toString() : null;
      if (this.wallType) {
        this.wallTypeNumberClicks = 2;
      }
      this.width = this.unified3DModel.ModelInput.Acoustic.Width;

    } else {
      this.wallPerimeterEffectChecked = false;
      this.height = null;
      this.roomArea = null;
      this.wallType = null;
      this.wallTypeNumberClicks = 0;
      this.width = null;
    }
    this.heightString = this.height ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.height.toString()):null;
    this.widthString = this.width ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.width.toString()):null;
    this.roomAreaString = this.roomArea ? this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(this.roomArea.toString()):null;
    this.getClassificationValueNString();
  }

  ngOnChanges(Changes: SimpleChanges) {
    if (!this.umService.isFacadeUDC()) {
      if (Changes) {
        if (Changes.unified3DModel && Changes.unified3DModel.currentValue) {
          this.unified3DModel = Changes.unified3DModel.currentValue;
          if (Changes.unified3DModel.previousValue && Changes.unified3DModel.currentValue.ProblemSetting.ProblemGuid !== Changes.unified3DModel.previousValue.ProblemSetting.ProblemGuid) {
            this.loadAcoustic();
          }
        }
      }
    }
  }

  onWallPerimeterChecked() {
    this.configureService.computeClickedSubject.next(false);
    if (this.wallPerimeterEffectChecked) {
      this.validateForm.enable();
      this.writeNsendUnified3DModel();
    } else {
      this.validateForm.disable();
      delete this.unified3DModel.ModelInput.Acoustic.Height;
      delete this.unified3DModel.ModelInput.Acoustic.RoomArea;
      delete this.unified3DModel.ModelInput.Acoustic.Width;
      delete this.unified3DModel.ModelInput.Acoustic.WallType;
      this.loadJSONService({ Unified3DModel: this.unified3DModel, canBeDrawn: this.canBeDrawnBool });
      //disableresult
      this.unified3DModelFromAcousticEvent.emit(this.unified3DModel);
    }
    if (this.wallTypeNumberClicks < 2) {
      this.validateForm.controls.wallType.markAsUntouched();
    }
  }

  onWallTypeChange() {
    this.wallTypeNumberClicks++;
    this.writeNsendUnified3DModel();
  }

  writeNsendUnified3DModel() {
    if (this.heightString) {
      this.height = parseFloat(this.heightString.replace(',', '.'));
      if (this.height < 1) {
        this.validateForm.controls['height'].setErrors({ 'incorrect': true });
      }
    }
    else {
      this.height = null;
    }
    if (this.widthString) {
      this.width = parseFloat(this.widthString.replace(',', '.'));
      if (this.width < 1) {
        this.validateForm.controls['width'].setErrors({ 'incorrect': true });
      }
    }
    else {
      this.width = null;
    }
    if (this.roomAreaString) {
      this.roomArea = parseFloat(this.roomAreaString.replace(',', '.'));
    }
    else {
      this.roomArea = null;
    }
    if (this.unified3DModel.ModelInput.Acoustic && ((!this.unified3DModel.ModelInput.Acoustic.WallType && this.wallType)
      || (this.unified3DModel.ModelInput.Acoustic.WallType && !this.wallType)
      || this.unified3DModel.ModelInput.Acoustic.WallType && this.wallType && (this.unified3DModel.ModelInput.Acoustic.WallType != parseInt(this.wallType))
      || this.unified3DModel.ModelInput.Acoustic.Height != this.height
      || this.unified3DModel.ModelInput.Acoustic.Width != this.width
      || this.unified3DModel.ModelInput.Acoustic.RoomArea != this.roomArea)) {
      this.configureService.computeClickedSubject.next(false);
    }
    if (this.wallPerimeterEffectChecked) {
      this.unified3DModel.ModelInput.Acoustic = {
        WallType: parseInt(this.wallType),
        Height: this.height,
        Width: this.width,
        RoomArea: this.roomArea,
        Classification: this.classificationValue
      };
      this.getClassificationValueNString();
      if (!(this.unified3DModel.ProblemSetting.ProductType == '')) {
        this.loadJSONService({ Unified3DModel: this.unified3DModel, canBeDrawn: this.canBeDrawnBool });
        //this.configureService.computeClickedSubject.next(false);
      }
      if (this.roomArea == 0) {
        this.validateForm.controls['roomArea'].setErrors({ 'incorrect': true });
      }
    }
    this.unified3DModelFromAcousticEvent.emit(this.unified3DModel);
  }

  isFormValid() {
    if (!this.wallPerimeterEffectChecked) { return true; }
    else
      return this.validateForm.valid;
  }

  loadJSONService(data: any) {
    this.iframeService.loadJSON(this.iframeEvent, 'loadJSON', data);
  }

  isAnyAWS75IISystem(): boolean {
    if (this.umService.isWindow()) {
      return this.umService.isSystemSelected('AWS 75.II');
    }
    else if (this.umService.isFacade()) {
      return this.umService.isSystemInserted('AWS 75.II');
    }
    return false;
  }
}