<div>
    <div nz-row nzType="flex" nzJustify="start" nzAlign="left">
        <div nz-col>
            <app-left-panel [country]="country" (updateListEvent)="refreshHome()" (showFeaturePopUpEvent)="showFeaturePopup()"></app-left-panel>
        </div>
        <div nz-col nzSpan="19" style="float:right; flex-grow:1;width:65%;">
            <app-middle-panel *ngIf="projects" [projects]="projects" [isReload]="updateProjectList"></app-middle-panel>
        </div>

    </div>
    <div nz-row id="poweredByVCL" class="top-grey-border-intro-page">
        <div></div>
        <div style="width: 100%; text-align: center">
            <p>
                Powered by
                <span>THE VIRTUAL CONSTRUCTION LAB OF SCHÜCO</span>
            </p>
        </div>
        <div style="position: absolute; right: 25px;">
            <img src="/assets/Images/sps/sps-schuco-white-logo.svg">
        </div>
    </div>
</div>
<div id="loaderContainer" [ngClass]="{'show': !isOperating, 'hide': isOperating}"
    style="position: absolute; top: 0; z-index: 1000;">
    <div style="position: relative; width: 100vw; height: 100vh; background-color: black; opacity: .6;"></div>
    <div style="position: absolute; top: 23vh; width: 100%; text-align: center;">
        <img style="width:auto !important; height: auto !important;" src="/assets/Images/loaders/loading_2_GIF.gif" />
    </div>
</div>
<bps-modal [(bpsVisible)]="isFeaturePopupVisible"
           [bpsFooter]="null"
           [bpsWidth]="700"
           (bpsOnCancel)="hideFeaturePopup()"
           bpsTitle="4.2 Release - New Features" *ngIf="language === 'en-US'" >
    <div>
        
    <!-- <span class="example-field" style="font:16px; font-weight: bold;">UX/UI Improvements and Features:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square; font-size:12px !important;">
            <li>Architectural change to scale up and acquire new countries in BPS in the near future.</li>
            <li>New login/out overflow menu with language and plan selections available.</li>
            <li>Home page-left panel expanded to accommodate longer names.</li>
            <li>Configuration Panel expanded to accommodate longer names.</li>
            <li>New panel to manage systems selections, ability to add systems available in other countries to your project, and run calculations.</li>
            <li>New Library panel for Glass and Panel selections, the panel is designed to accommodate more information on the available options.</li>
            <li>The side panel for Custom Glass & Panel has been improved.</li>
            <li>Improved user flow to accommodate additional codes from different countries.</li>
            <li>Codes filtered out based on project location, your project will have only the available codes for the specific country chosen for your project. </li>
            <li>Improved Acoustic, Thermal, and Structural panels to include French Systems.</li>
            <li>Ability to download reports in all the available languages supported by the system.</li>
            <li>New pop-up panel to download reports.</li>
            <li>New notifications to enhance the experience.</li>
        </ul>
    </span> -->
    <span class="example-field" style="font:16px; font-weight: bold;">Thermal Engineering and Features:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>U-value calculation for AWS 75.II, AWS 75 BD, AWS 75 BS.HI+(FR) French System.</li>
            <li>Solar Transmittance and Light Transmittance calculation for FWS systems and French AWS systems.</li>
            <li>Thermal engineering reports in French language for all systems</li>
        </ul>
    </span>
    <span class="example-field" style="font:16px; font-weight: bold;">Acoustic Engineering and Features:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>Acoustic calculation for AWS 75.II French System</li>
            <li>Acoustic engineering reports in French language for all systems</li>
        </ul>
    </span>

    <!-- <span class="example-field" style="font:16px; font-weight: bold;">Structural Engineering and Features:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>NF EN 1991-1-4 wind load design for AWS located in France.</li> 
            <li>Both NF EN 1991-1-4 and Eurocode 1.4 Simplified (FE 45 SNFA) wind load design for FWS and UDC located in France.</li>
            <li>French deflection criteria for AWS which accommodates applied wind load and/or horizontal live load. </li>
            <li>French engineering reports (Acoustic, Thermal, and summary/full reports for structural).</li>
        </ul>
    </span> -->
    </div>
    <div style="text-align: center; margin-top: 25px">
      <button style="width: 165px" bps-button bpsType="variation-1" (click)="hideFeaturePopup()">OK</button>
    </div>
</bps-modal> 

<bps-modal [(bpsVisible)]="isFeaturePopupVisible"
           [bpsFooter]="null"
           [bpsWidth]="700"
           (bpsOnCancel)="hideFeaturePopup()"
           bpsTitle="4.2 Version - Nouvelles fonctionnalités" *ngIf="language === 'fr-FR'" >
    <div>
        
    <!-- <span class="example-field" style="font:16px; font-weight: bold;">Améliorations et fonctionnalités UX/UI:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square; font-size:12px !important;">
            <li>Modification de l'architecture du logiciel en vue de l’ouverture prochaine de BPS à de nouveaux pays.</li>
            <li>Nouveau menu de connexion/déconnexion avec sélection de la langue et du statut d’utilisateur.</li>
            <li>Page d'accueil - menu de gauche élargi pour accueillir des noms plus longs.</li>
            <li>Le panneau de configuration a été élargi pour accueillir des noms plus longs.</li>
            <li>Nouveau panneau pour gérer les sélections de systèmes, la possibilité d'ajouter des systèmes disponibles dans d'autres pays à votre projet et d'effectuer des calculs.</li>
            <li>Nouveau menu Bibliothèque pour les sélections de vitrages et de panneaux, le menu est conçu pour contenir plus d'informations sur les options disponibles.</li>
            <li>Le menu latéral pour le verre et les panneaux personnalisés a été amélioré.</li>
            <li>Amélioration de la gestion d'utilisateurs pour prendre en compte les normes supplémentaires provenant de différents pays.</li>
            <li>Les normes sont filtrées en fonction de l'emplacement du projet, votre projet n'aura que les normes disponibles pour le pays spécifique choisi pour votre projet. </li>
            <li>Amélioration des menus acoustiques, thermiques et statiques pour inclure les systèmes français.</li>
            <li>Possibilité de télécharger les rapports dans toutes les langues supportées par le système.</li>
            <li>Nouveau menu pop-up pour télécharger les rapports.</li>
            <li>Nouvelles notifications pour améliorer l'expérience utilisateur.</li>
        </ul>
    </span> -->
    <span class="example-field" style="font:16px; font-weight: bold;">Calcul thermique:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>Calcul de Uw pour le système français AWS 75.II, AWS 75 BD, AWS 75 BS.HI+(FR)</li>
            <li>Calcul du facteur solaire et de la transmission lumineuse pour les systèmes français FWS et AWS.</li>
            <li>Rapports d’études thermiques en français </li>
        </ul>
    </span>
    <span class="example-field" style="font:16px; font-weight: bold;">Calcul acoustique:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>Calcul acoustique pour le système français AWS 75.II</li>
            <li>Rapports de calculs acoustiques en français pour tous les systèmes.</li>
        </ul>
    </span>

    <!-- <span class="example-field" style="font:16px; font-weight: bold;">Calcul statique:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>NF EN 1991-1-4 charges de vent pour tous les projets situés en France.</li> 
            <li>FT 45 SNFA : méthode simplifiée de détermination des charges de vent pour les les projets de façades situés en France.</li>
            <li>Critères de flèche spécifiques pour les systèmes AWS (vent et/ou charge d’exploitation horizontale). </li>
            <li>Rapports statiques (résumés / détaillés) en français.</li>
        </ul>
    </span> -->
    </div>
    <div style="text-align: center; margin-top: 25px">
      <button style="width: 165px" bps-button bpsType="variation-1" (click)="hideFeaturePopup()">OK</button>
    </div>
</bps-modal> 

<bps-modal [(bpsVisible)]="isFeaturePopupVisible"
           [bpsFooter]="null"
           [bpsWidth]="700"
           (bpsOnCancel)="hideFeaturePopup()"
           bpsTitle="4.2 Version - Neue Funktionen" *ngIf="language === 'de-DE'" >
    <div>
        
    <!-- <span class="example-field" style="font:16px; font-weight: bold;">UX/UI-Verbesserungen und Funktionen:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square; font-size:12px !important;">
            <li>Strukturelle Änderung um in naher Zukunft neue Länder im BPS zu integrieren und aufzunehmen.</li>
            <li>Neues An-/Abmelde Bedienfeld mit zusätzlicher Sprachauswahl und Softwareinformationen.</li>
            <li>Der linke Bereich der Startseite wurde erweitert, um es zu ermöglichen längere Projektnamen einzugeben.</li>
            <li>Der Konfigurationsbereich wurde erweitert, um längere Bezeichnungen aufzunehmen.</li>
            <li>Neues Panel zur Verwaltung der Systemauswahl, Möglichkeit um Systeme zu einem Projekt hinzuzufügen und zu berechnen, gilt auch für Systeme die in anderen Ländern verfügbar sind.</li>
            <li>Neues Bibliotheksfenster für die Glas- und Panelauswahl. Die Bibliothek soll mehr Informationen zu den verfügbaren Optionen enthalten.</li>
            <li>Der Bereich für beutzerdefinierte Gläser und Glaspaneele wurde verbessert.</li>
            <li>Verbesserte Benutzerführung um zusätzliche Normen aus verschiedenen Ländern zu implementieren.</li>
            <li>Die gemäß für den Projektstandort zugehörigen Länder Normen werden automatisch zugeordnet. </li>
            <li>Verbesserter Akustik-, U-Werte und Statikbereich unter Berücksichtigung der französischen Systeme.</li>
            <li>Möglichkeit zum Herunterladen von Projekt Berichten in allen in der Software verfügbaren Sprachen.</li>
            <li>Neuer Bereich zum Herunterladen von Projekt Berichten.</li>
            <li>Neues Notifikationsfeld mit wichtigen Hinweisen und Informationen über durchgeführte Vorgänge.</li>
        </ul>
    </span> -->
    <span class="example-field" style="font:16px; font-weight: bold;">U-Werte, Berechnungen und Funktionen:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>U-Wert Berechnung für das Französische Fenstersystem  AWS 75.II, AWS 75 BD, AWS 75 BS.HI+(FR)</li>
            <li>Berechnung der Sonnen- und Lichtdurchlässigkeit für FWS- und französische AWS-Systeme.</li>
            <li>Projektberichte für die U-Werte Berechnung in französischer Sprache für die im BPS verfügbaren Systeme.</li>
        </ul>
    </span>
    <span class="example-field" style="font:16px; font-weight: bold;">Akustik Berechnungen und Funktionen:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>Akustische Berechnung für das französische System AWS 75.II</li>
            <li>Projektberichte für die Akustik Berechnung in französicher Sprache für die im BPS verfügbaren Systeme.</li>
        </ul>
    </span>

    <!-- <span class="example-field" style="font:16px; font-weight: bold;">Statik Berechnungen und Funktionen:</span><br/>
    <span class="example-field">
        <ul class="a" style="list-style-type: square;font-size:12px !important;">
            <li>Windlastermittlung für AWS-Fenstersysteme in Frankreich (gem. NF EN 1991-1-4).</li> 
            <li>Windlastermittlung für Pfosten-Riegel-Fassaden und Elementfassaden in Frankreich (gem. NF EN 1991-1-4 als auch Eurocode 1.4 (FE 45 SNFA) ).</li>
            <li>Nationale Durchbiegungskriterien (Frankreich) für Fenstersysteme, die bei Windlasten und/oder der horizontalen Nutzlast angewendet werden. </li>
            <li>Ergebnisberichte in französischer Sprache (Kurzbericht oder ausführlicher Bericht).</li>
        </ul>
    </span> -->
    </div>
    <div style="text-align: center; margin-top: 25px">
      <button style="width: 165px" bps-button bpsType="variation-1" (click)="hideFeaturePopup()">OK</button>
    </div>
</bps-modal> 