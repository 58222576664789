import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BpsTableComponent, CeldType } from 'bps-components-lib';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ThermalService } from 'src/app/app-structural/services/thermal.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';


@Component({
  selector: 'app-sun-shading-list-table',
  templateUrl: './sun-shading-list-table.component.html',
  styleUrls: ['./sun-shading-list-table.component.css']
})
export class SunShadingListTableComponent implements OnInit, OnDestroy {

  @Output() closeSunShadingTableOutput = new EventEmitter<void>();
  @Output() confirmArticleOutput = new EventEmitter<any>();
  @Output() letUValueTableReadDataFromUMOutput = new EventEmitter<any>();
  @ViewChild('col2Heading', { read: TemplateRef, static: true }) col2HeadingTemplate: TemplateRef<{}>;
  @ViewChild('table', { read: BpsTableComponent, static: true }) tableComponent: BpsTableComponent;

  data: any;
  listOfDisplayData: any = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  searchValue: string = '';
  selectedArticle: any;
  private destroy$ = new Subject<void>();

  configurationCustomGrid: any;

  constructor(private navLayoutService: NavLayoutService,private translate: TranslateService, private thermalService: ThermalService, private cpService: ConfigPanelsService) { }

  displayTable: boolean = false;

  ngOnInit(): void {
    this.setConfigurationCustomGrid();
    this.thermalService.loadDataCallSubject.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.loadData();
      }
    );
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {        
        if (response.panelsModule === PanelsModule.SunShadingTable) {
          this.displayTable = response.isOpened; // for on close all tables call only
        }
      }
    );
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setConfigurationCustomGrid();
      this.translateArticles();
    });
  }

  setConfigurationCustomGrid() {
    this.configurationCustomGrid = {
      fields: [
        {
          celdType: CeldType.Default,
          display: this.translate.instant(_('configure.thermal-closing')),
          property: 'description',
          width: '240px',
          showSort: true
        },
        {
          celdType: CeldType.Default,
          property: 'delta',
          template: {
            ref: this.col2HeadingTemplate,
            context: {}
          },
          width: '60px',
          showSort: true
        },
      ],
      fieldId: 'id'
    };
  }

  openTable(currentArticleId) {
    this.displayTable = true;
    this.loadData(currentArticleId);
  }

  onCloseTable() {
    this.displayTable = false;
    this.closeSunShadingTableOutput.next();
  }

  sort(sort: { sortName: string; sortValue: string }): void {
    this.sortName = sort.sortName;
    this.sortValue = sort.sortValue;
    this.search();
  }

  search(): void {
    const filterFunc = (item: any) => {
      return item.description.indexOf(this.searchValue) !== -1;
    };
    const data = this.data.filter((item: { description: string; uValue: string; rw: string }) => filterFunc(item));
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
      this.listOfDisplayData = this.listOfDisplayData.slice();
    } else {
      this.listOfDisplayData = data;
    }
  }

  onConfirm() {
    this.confirmArticleOutput.next(this.selectedArticle);
  }

  loadData(currentArticleId = null): void {
    if (this.thermalService.sunShadingArticleList.length == 0) {
      this.thermalService.getSunShadingList().pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.thermalService.sunShadingArticleList = data;
        this.data = [];
        data.forEach((article) => {
          this.data.push({
            id: '' + article.id,
            description: this.translate.instant(_(article.descriptionKey)),
            descriptionKey: article.descriptionKey,
            delta: article.delta,
            disabled: false
          });
        });
        this.listOfDisplayData = this.data.slice();
        // this.letUValueTableReadDataFromUMOutput.next();
        if (currentArticleId) setTimeout(() => {this.selectCurrentOrDefaultRow(currentArticleId);});
      });
    }
    else {
      this.data = [];
      this.thermalService.sunShadingArticleList.forEach((article) => {
        this.data.push({
          id: '' + article.id,
          description: this.translate.instant(_(article.descriptionKey)),
          descriptionKey: article.descriptionKey,
          delta: article.delta,
          disabled: false
        });
      });
      this.listOfDisplayData = this.data.slice();
      if (currentArticleId) setTimeout(() => {this.selectCurrentOrDefaultRow(currentArticleId);});
    }
  }

  translateArticles() {
    if (this.data) {
      this.data.forEach(article => {
        article.description = this.translate.instant(_(article.descriptionKey));
      });
    }
  }

  selectCurrentOrDefaultRow(currentArticleId) {
    if (this.tableComponent) {
      if (currentArticleId == null){
        this.selectedArticle = this.listOfDisplayData.find(article => article.id == '0');
      }
      else {
        this.selectedArticle = this.listOfDisplayData.find(article => article.id == currentArticleId);
      }
      this.tableComponent.selectRow(this.selectedArticle);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickRow(event) {
    this.selectedArticle = event;
  }

  ondblclickRow(event) {
    this.onClickRow(event);
    this.onConfirm();
    this.onCloseTable();
  }
}
