import { Component, OnInit } from '@angular/core';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';

@Component({
  selector: 'app-sustainability-panel',
  templateUrl: './sustainability-panel.component.html',
  styleUrls: ['./sustainability-panel.component.css']
})
export class SustainabilityPanelComponent implements OnInit {

  constructor(public umService: UnifiedModelService) { }

  ngOnInit(): void {
  }

}
