

    <div *appCheckPermissions="feature.GlassPanelSideTableFull" [ngStyle]="{'display': isPopoutOpened ? 'flex':'none' }"
    class="sps-left-popout">
    <div class="lp-panel">
        <div class="lp-panel-top">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text">{{'configure.library' | translate | uppercase}}</h3>
                </div>
                <div class="buttons-container">
                    <button [ngStyle]="{'padding-left': language === 'de-DE' ? '1px' : ''}" style="width: 100%; margin-bottom: 10px;" bps-button
                        bpsType="variation-2" [ngClass]="{'selected': selectedType == 'double'}"
                        [disabled]="isDoubleBtnDisabled" (click)="onNewTypeSelection('double')">
                        {{'configure.double-glass' | translate }}
                        <!-- Double -->
                    </button>
                    <button [ngStyle]="{'padding-left': language === 'de-DE' ? '1.5px' : ''}"
                        style="margin-right: 0px !important; width: 100%; margin-bottom: 10px;" bps-button bpsType="variation-2"
                        [ngClass]="{'selected': selectedType == 'triple'}" [disabled]="isTripleBtnDisabled"
                        (click)="onNewTypeSelection('triple')">
                        {{'configure.triple-glass' | translate }}
                        <!-- Triple -->
                    </button>
                    <button style="width: 100%;" bps-button bpsType="variation-2"
                        [ngClass]="{'selected': selectedType == 'panel'}" [disabled]="isPanelBtnDisabled"
                        (click)="onNewTypeSelection('panel')">
                        {{'configure.panel' | translate }}
                        <!-- Panel -->
                    </button>
                </div>
                <div class="sps-break"></div>
            </div>
        </div>
        <div class="lp-panel-custom">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text">{{'configure.custom' | translate | uppercase}}</h3>
                </div>
                <div class="buttons-container">
                    <button style="background-color: #253d47!important; width: 100%; margin-right: 0px !important; margin-bottom: 10px;"
                        bps-button bpsType="variation-2" [ngClass]="{'selected': selectedType == 'newGlassAndPanel'}"
                        [disabled]="isNewBtnDisabled" (click)="onNewTypeSelection('newGlassAndPanel')">
                        {{'configure.new-glass-and-panel' | translate }}
                        <!-- New -->
                    </button>
                    <button style="background-color: #253d47!important; width: 100%; margin-right: 0px !important;"
                        bps-button bpsType="variation-2" [ngClass]="{'selected': selectedType == 'customLibrary'}"
                        [disabled]="isNewBtnDisabled" (click)="onNewTypeSelection('customLibrary')">
                        {{'configure.custom-library' | translate }}
                        <!-- New -->
                    </button>
                </div>
                <div class="sps-break"></div>
            </div>
        </div>
    </div>
    <div class="lp-panel-extand" [ngStyle]="{width: selectedType!='newGlassAndPanel' && !isEditCustom ? '775px':'595px'}">
        <div class="lp-top-section-extanded" *ngIf="selectedType!='newGlassAndPanel' && !isEditCustom">
            <div class="lp-header">
                <h3 class="lp-header-text">{{'configure.glass' | translate | uppercase}} {{'configure.library' | translate | uppercase}}</h3>
                <img style="cursor: pointer;" src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse"
                    alt="close icon" (click)="onClose()">
            </div>
        </div>
        <div class="glass-viewer-container" id="sps-glass-viewer-container" *ngIf="selectedType!='newGlassAndPanel' && !isEditCustom">
            <div class="glass-viewer-row">
                <div class="glass-viewer">
                    <app-viewer-infill [isSidePanel]="isSidePanel" *ngIf="isPopoutOpened"
                        [confirmedGlassPanelArticle]="selectedArticle">
                    </app-viewer-infill>
                </div>
                <div class="glass-viewer-dimensions-of-glass" id="sps-glass-viewer-dimensions-of-glass" *ngIf="selectedArticle!=undefined">
                    <div class="glass-and-panel-dimensions-container" id="glass-and-panel-dimensions-container">
                        <div class="lp-header">
                            <h3 class="lp-header-text" style="margin: 10px 77px 11px 15px;">{{'configure.glass-thickness' | translate | uppercase}}</h3>
                        </div>
                        <ng-container *ngIf="selectedArticle.Glass_SystemType === 'EN' || selectedArticle.category === 'custom' || selectedArticle.category === 'panel'">
                            <div class="glass-and-panel-dimensions-row" *ngFor="let elementSize of selectedArticle.composition.split('-'); let i = index">
                                <h3 *ngIf="this.applicationType=='BPS'" class="glass-and-panel-dimensions-text" style="margin: 0 0 0 15px !important;">{{ elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('(')) : elementSize  }}</h3>
                                <h3 class="glass-and-panel-dimensions-unit" style="margin: 0 0 0 15px !important;">{{ selectedArticle.totalThicknessUnit }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='glass'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.glass' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='argon'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.argon' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='air'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.air' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='insulation'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.structural-library-custom-panel-layer-insulation-option' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='aluminium'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.structural-library-custom-panel-layer-aluminium-option' | translate }}</h3>
                                <h3 *ngIf="elementSize.includes('(') && this.applicationType=='BPS' else selectContactTemp" class="glass-and-panel-dimensions-text" style="margin: 0 -25px 0 15px; width:182px !important;"> {{elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('('))/2 : elementSize/2 | number}}{{'|'}}{{elementSize.substring(2,6)}}{{' PVB|'}}{{elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('('))/2 : elementSize/2 | number}}</h3>
                                <!-- <h3 *ngIf="pvbValueFull[selectedPicker][i] && applicationType=='SRS'"  style="width:115px;" class="glass-and-panel-dimensions-unit">({{ pvbValueFull[selectedPicker][i] }})</h3>
                                <h3 *ngIf="pvbValueFull[selectedPicker][i] && applicationType == 'BPS'" style="width:115px;" class="glass-and-panel-dimensions-unit">{{elementSize.includes('[')? elementSize.substring(0, elementSize.indexOf('['))/2 : elementSize/2 | number}}{{'|'}}{{pvbValueFull[selectedPicker][i].substring(0, pvbValueFull[selectedPicker][i].indexOf('m'))}}{{' PVB|'}}{{elementSize.includes('[')? elementSize.substring(0, elementSize.indexOf('['))/2 : elementSize/2 | number}}</h3> -->
                            </div>
                        </ng-container>
                        <ng-container *ngIf="selectedArticle.Glass_SystemType === 'FR'">
                            <div class="glass-and-panel-dimensions-row" *ngFor="let elementSize of selectedArticle.description.split('-'); let i = index">
                                <h3 *ngIf="this.applicationType=='BPS'" class="glass-and-panel-dimensions-text" style="margin: 0 0 0 15px !important;">{{ elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('(')) : elementSize  }}</h3>
                                <h3 class="glass-and-panel-dimensions-unit" style="margin: 0 0 0 15px !important;">{{ selectedArticle.totalThicknessUnit }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='glass'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.glass' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='argon'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.argon' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='air'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.air' | translate }}</h3>
                                <h3 *ngIf="selectedArticle.type.split('-')[i].toLowerCase ()==='lamipvb'" [ngStyle]="{'width':'150px'}" class="glass-and-panel-dimensions-type" style="margin: 0 0 0 10px !important;">{{ 'configure.glass' | translate }}</h3>
                                <h3 *ngIf="elementSize.includes('(')  && this.applicationType=='BPS' else selectContactTemp" class="glass-and-panel-dimensions-text" style="margin: 0 -25px 0 15px; width:182px !important;"> {{elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('('))/2 : elementSize/2 | number}}{{'|'}}{{elementSize.substring(2,6)}}{{' PVB|'}}{{elementSize.includes('(')? elementSize.substring(0, elementSize.indexOf('('))/2 : elementSize/2 | number}}</h3>
                                <!-- <h3 *ngIf="pvbValueFull[selectedPicker][i] && applicationType=='SRS'"  style="width:115px;" class="glass-and-panel-dimensions-unit">({{ pvbValueFull[selectedPicker][i] }})</h3>
                                <h3 *ngIf="pvbValueFull[selectedPicker][i] && applicationType == 'BPS'" style="width:115px;" class="glass-and-panel-dimensions-unit">{{elementSize.includes('[')? elementSize.substring(0, elementSize.indexOf('['))/2 : elementSize/2 | number}}{{'|'}}{{pvbValueFull[selectedPicker][i].substring(0, pvbValueFull[selectedPicker][i].indexOf('m'))}}{{' PVB|'}}{{elementSize.includes('[')? elementSize.substring(0, elementSize.indexOf('['))/2 : elementSize/2 | number}}</h3> -->
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="glass-measurements-container" id="sps-glass-measurements-container">
                    <div class="glass-and-panel-measurement-container" *ngIf="selectedArticle">
                        <div class="lp-header">
                            <h3 class="lp-header-text"  style="margin: 10px 60px 11px 12px;">{{'configure.additional-info' | translate | uppercase}}</h3>
                            <div style="padding: 10px;" bpsType="variation-1" bps-popover
                            [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_3'"
                            [bpsPopoverContent]="additionalInfoContent">
                            <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg">
                        <ng-template #additionalInfoContent>
                            <div style="text-align: left; padding-left: 15px;">
                                <div>
                                  <h3 style="color: #00a2d1;">{{'configure.sg-winter-1' | translate }}</h3>
                                  <p>{{'configure.sg-winter-1-popover-content' | translate }}.</p>
                                </div>
                                <div>
                                  <h3 style="color: #00a2d1;">{{'configure.sg-winter-2' | translate }}</h3>
                                  <p>{{'configure.sg-winter-2-popover-content' | translate }}.</p>
                                </div>
                                <div>
                                  <h3 style="color: #00a2d1;">{{'configure.sg-summer-1' | translate }}</h3>
                                  <p>{{'configure.sg-summer-1-popover-content' | translate }}.</p>
                                </div>
                                <div>
                                  <h3 style="color: #00a2d1;">{{'configure.sg-summer-2' | translate }}</h3>
                                  <p>{{'configure.sg-summer-2-popover-content' | translate }}.</p>
                                </div>
                              </div>
                        </ng-template>
                    </div>
                        </div>
                        <div class="glass-and-panel-measurement-row1">
                            <h3 class="glass-and-panel-measurement-unit1" id="glass-and-panel-UValue-heading" style="margin: 0 0 10px 15px;">{{'configure.sg-winter-1' | translate }}</h3>
                            <!-- U-Value -->
                            <h3 *ngIf="selectedArticle.sg1h && (selectedArticle.sg1h !== 0 && selectedArticle.sg1h !== null); else NoDataUValue"
                                class="glass-and-panel-measurement-unit2" id="glass-and-panel-UValue">{{selectedArticle.sg1h}}</h3>
                            <ng-template #NoDataUValue>
                                <h3 class="glass-and-panel-measurement-unit2" id="glass-and-panel-UValue">-</h3>
                            </ng-template>

                        </div>
                        <div class="glass-and-panel-measurement-row1">
                            <h3 class="glass-and-panel-measurement-unit1" id="glass-and-panel-rw-value-heading" style="margin: 0 0 10px 15px;">{{'configure.sg-winter-2' | translate }}
                            </h3>
                            <h3 *ngIf="selectedArticle.sg2h  && (selectedArticle.sg2h !== 0 && selectedArticle.sg2h !== null); else NoDataRW"
                                class="glass-and-panel-measurement-unit2" id="glass-and-panel-rw-value">{{selectedArticle.sg2h}}</h3>
                            <ng-template #NoDataRW>
                                <h3 class="glass-and-panel-measurement-unit2" id="glass-and-panel-rw-value">-</h3>
                            </ng-template>
                        </div>
                        <div class="glass-and-panel-measurement-row1">
                            <h3 class="glass-and-panel-measurement-unit1" id="glass-and-panel-UValue-heading" style="margin: 0 0 10px 15px;">{{'configure.sg-summer-1' | translate }}</h3>
                            <!-- U-Value -->
                            <h3 *ngIf="selectedArticle.sg1e && (selectedArticle.sg1e !== 0 && selectedArticle.sg1e !== null); else NoDataUValue"
                                class="glass-and-panel-measurement-unit2" id="glass-and-panel-UValue">{{selectedArticle.sg1e}}</h3>
                            <ng-template #NoDataUValue>
                                <h3 class="glass-and-panel-measurement-unit2" id="glass-and-panel-UValue">-</h3>
                            </ng-template>

                        </div>
                        <div class="glass-and-panel-measurement-row1">
                            <h3 class="glass-and-panel-measurement-unit1" id="glass-and-panel-UValue-heading" style="margin: 0 0 10px 15px;">{{'configure.sg-summer-2' | translate }}</h3>
                            <!-- U-Value -->
                            <h3 *ngIf="selectedArticle.sg2e && (selectedArticle.sg2e !== 0 && selectedArticle.sg2e !== null); else NoDataUValue"
                                class="glass-and-panel-measurement-unit2" id="glass-and-panel-UValue">{{selectedArticle.sg2e}}</h3>
                            <ng-template #NoDataUValue>
                                <h3 class="glass-and-panel-measurement-unit2" id="glass-and-panel-UValue">-</h3>
                            </ng-template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lp-library-panel" *ngIf="selectedType!=='newGlassAndPanel' && !isEditCustom">
            <!-- Table Content -->
            <div class="bpsTableContent">
                <div id="predefined" *ngIf="selectedType !== 'customLibrary'">
                    <bps-table #bpsTable [data]="listOfDisplayData" [scroll]="{ y: 'calc(100vh - 470px)' }"
                    [showPagination]="false" [frontPagination]="false" (onclickRow)="onclickRow($event)"
                    (ondblclickRow)="ondblclickRow($event)" (selectionChange)="selectionChange($event)"
                    [tableType]="'glass_profile'" class="bps-table-section" [filterPlaceholder]="('configure.search-by-dimension' | translate)"
                    (searchValueChange)="filter($event)" (sortChange)="sort($event)"
                    [(config)]="configurationCustomGrid" [ngClass]="{'custom': selectedType == 'customLibrary'}">
                </bps-table>
                </div>
                <div id="custom"  *ngIf="selectedType == 'customLibrary'">
                    <bps-table #bpsTable [data]="listOfDisplayData" [scroll]="{ y: 'calc(100vh - 470px)' }"
                    [showPagination]="false" [frontPagination]="false" (onclickRow)="onclickRow($event)"
                    (ondblclickRow)="ondblclickRow($event)" (selectionChange)="selectionChange($event)"
                    [tableType]="'glass_profile'" class="bps-table-section" [filterPlaceholder]="('configure.search-by-dimension' | translate)"
                    (searchValueChange)="filter($event)" (sortChange)="sort($event)"
                    [(config)]="configurationCustomGrid">
                </bps-table>
                </div>
                
            </div>
            <div class="lp-panel-bottom shadow-line-up">
                <!-- delete -->
                <!-- Confirm -->
                <button class="lp-delete-btn" *ngIf="selectedType == 'customLibrary'" bps-button bpsType="variation-4" (click)="onDelete()">{{'configure.delete' | translate }}</button>
                <div *ngIf="selectedType != 'customLibrary'"></div>
                <button class="lp-confirm-btn" bps-button bpsType="variation-4" (click)="onConfirm()">{{'configure.confirm' | translate }}</button>
            </div>
        </div>
        <app-library-custom #customlibrary
            (updateLibraryCustomArticleEvent)="updateLibraryCustomArticle($event)"
            (addLibraryCustomArticleEvent)="addLibraryCustomArticle($event)"
            (deleteLibraryCustomArticleEvent)="deleteLibraryCustomArticle($event)"
            (closePanelEvent)="onClose($event)"
            style="width: 100%; height: 100%;"></app-library-custom>
    </div>
     <!-- <div *ngIf="isDisplay" id="sps-left-popout-container-level2-overlay-left"
        class="sps-left-popout-container-level2-overlay-left"></div> -->
    <div *ngIf="isDisplay" id="sps-left-popout-container-level2-overlay-right"
        class="sps-left-popout-container-level2-overlay-right"></div>

</div>
<ng-template #idTemplate>
    <div>#</div>
</ng-template>
<ng-template #outsideTemplate let-value="value" let-validData="validData" let-disabled="disabled"
    let-arrowHovered="arrowHovered" let-index="index">
    <div>
        <span>{{value}}</span>
        <span (mouseenter)="onHover(index, true)" *ngIf="selectedType === 'customLibrary'"
            style="position: relative; float: right; top: -1px; margin-right: -125px" (mouseleave)="onHover(index, false)"
            (click)="preventDefault($event, index)">
            <img *ngIf="!validData" src="/assets/Images/bps-icons/sps_icon_arrowright_orange.svg" />
            <img *ngIf="validData && (!arrowHovered || disabled)"
                src="/assets/bps-icons/sps_icon_arrowright_grey.svg" />
            <img *ngIf="validData && arrowHovered && !disabled" src="/assets/bps-icons/sps_icon_arrowright_blue.svg" />
        </span>
    </div>
</ng-template>
<ng-template #uValueTemplate>
    <div>{{'configure.u-value' | translate }}</div>
    <!-- U-Value -->
    <!-- <div style="color: #999999">(W/m²K)</div> -->
</ng-template>
<ng-template #rwTemplate>
    <div>Rw</div>
    <!-- <div class="subtitle-disabled">(dB)</div> -->
</ng-template>
<ng-template #thicknessTemplate>
    <div>{{'configure.thickness' | translate }}</div>
</ng-template>
<ng-template #shgcTemplate>
    <div>SHGC</div>
</ng-template>
<ng-template #oitcTemplate>
    <div>OITC</div>
</ng-template>
<ng-template #vtTemplate>
    <div>VT</div>
</ng-template>
<ng-template #stcTemplate>
    <div>STC</div>
</ng-template>
<div *appCheckPermissions="feature.GlassPanelSideTableShort" [ngStyle]="{'display': isPopoutOpened ? 'flex':'none' }"
    class="sps-left-popout">
    <div class="lp-panel">
        <div class="lp-panel-top">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text">{{'configure.glass' | translate | uppercase}} {{'configure.library' |
                        translate | uppercase}}</h3>
                    <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse"
                        alt="close icon" (click)="onClose()">
                </div>
            </div>
            <div class="canvas-rectangle">
                <app-viewer-infill [isSidePanel]="isSidePanel" *ngIf="isPopoutOpened"
                    [confirmedGlassPanelArticle]="selectedArticle"></app-viewer-infill>
            </div>
            <div class="buttons-container">
                <button [ngStyle]="{'padding-left': language === 'de-DE' ? '1px' : ''}" style="width : 160px;"
                    bps-button bpsType="variation-2" [ngClass]="{'selected': selectedType == 'double'}"
                    [disabled]="isDoubleBtnDisabled" (click)="onNewTypeSelection('double')">
                    {{'configure.double' | translate }}
                    <!-- Double -->
                </button>
                <button [ngStyle]="{'padding-left': language === 'de-DE' ? '1.5px' : ''}"
                    style="margin-right: 0px !important;width:160px;" bps-button bpsType="variation-2"
                    [ngClass]="{'selected': selectedType == 'triple'}" [disabled]="isTripleBtnDisabled"
                    (click)="onNewTypeSelection('triple')">
                    {{'configure.triple' | translate }}
                    <!-- Triple -->
                </button>
            </div>
            <!-- Table Content -->
            <div class="bpsTableContent">
                <div class="custom-panel-wrapper">
                    <div class="bps-cmacs-custom-scrollbar custom-panel" id="custom-panel">
                        <nz-collapse nzGhost style="border: none;" [nzShowArrow]="false">
                            <nz-collapse-panel *ngFor="let node of nodes" [nzHeader]="contentTemplate" [nzActive]="true"
                                [nzDisabled]="false" (nzActiveChange)="activeChange($event)">
                                <div nz-row class="child" *ngFor="let childnode of node.children"
                                    [ngClass]="{'selected': childnode.key === selectedChildNodeKey && !headerSelected}"
                                    (dblclick)="onDblClickSRS(childnode,1)" (click)="Click(childnode)">
                                    <div class="sub-child" nz-col nzSpan="23" [innerHTML]="childnode.title"
                                        (dblclick)="onDblClickSRS(childnode,1)" (click)="Click(childnode)">
                                    </div>
                                    <div class="sub-child" nz-col nzSpan="1"
                                        *ngIf="(selectedType === 'double' && (childnode.key === 3 || childnode.key === 4 || childnode.key === 5)) || (selectedType === 'triple' && childnode.key === 1)">
                                        <img *ngIf="selectedType === 'double' && childnode && childnode.key === 3" class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg"
                                            bps-popover [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                            [bpsPopoverContent]="tooltipTemplate1">
                                        <img *ngIf="selectedType === 'double' && childnode && childnode.key === 4" class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg"
                                            bps-popover [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                            [bpsPopoverContent]="tooltipTemplate2">
                                        <img *ngIf="selectedType === 'double' && childnode && childnode.key === 5" class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg"
                                            bps-popover [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                            [bpsPopoverContent]="tooltipTemplate3">
                                        <img *ngIf="selectedType === 'triple' && childnode && childnode.key === 1" class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg"
                                            bps-popover [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                            [bpsPopoverContent]="tooltipTemplate4">
                                    </div>
                                </div>
                            </nz-collapse-panel>
                        </nz-collapse>

                    </div>
                </div>
            </div>
        </div>
        <!-- Confirm -->
        <div class="lp-confirm-btn-container">
            <button class="lp-confirm-btn" bps-button bpsType="variation-4" (click)="onConfirm()">{{'configure.confirm'
                | translate }}</button>
        </div>
    </div>
</div>
<ng-template #tooltipTemplate1>
    <div [innerHTML]="'1/4 Clear (Solarban 70 #2) + 1/2 AIR + 1/4 Clear'">
    </div>
</ng-template>
<ng-template #tooltipTemplate2>
    <div [innerHTML]="'1/4 Grey (Solarban 70 #2) + 1/2 AIR + 1/4 Clear'">
    </div>
</ng-template>
<ng-template #tooltipTemplate3>
    <div [innerHTML]="'1/4 Bronze (Solarban 70 #2) + 1/2 AIR + 1/4 Clear'">
    </div>
</ng-template>
<ng-template #tooltipTemplate4>
    <div [innerHTML]="'-Ext lite: 1/4&quot; Optigray Glass (SB70 coating face #2).<br>\
                    -1/2&quot; Argon Space <br>\
                    -Mid. Lite: 1/4&quot; Starphire Glass (SB70 coating face #4). <br>\
                    -1/2&quot; Argon Space <br>\
                    -Int. Lite: 1/4&quot; Clear Glass (Energy Advantage face #4).'">
    </div>
</ng-template>

<ng-template #contentTemplate>
    <div style="width:100%">
        <img src="/assets/Images/spacer-type/vitroarchitecturalglass_enabled.svg">
    </div>
</ng-template>


<ng-template #selectContactTemp>
    <div class="emptyDiv" style="width: 182px !important;">
        
    </div>
</ng-template>


