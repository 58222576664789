<div *ngIf="isPopoutOpened" [ngStyle]="{'display': isPopoutOpened ? 'flex':'none' }" class="sps-left-popout">
    <div class="lp-panel">
        <div class="lp-panel-top">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text">{{'configure.spacer-library' | translate }}</h3>
                    <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse"
                        alt="close icon" (click)="onClose()" style="cursor: pointer;">
                </div>
            </div>
            <!-- Table Content -->
            <!-- <div class="bpsTableContent">
                <div class="sps-left-panel-wrapper">
                    <div class="bps-cmacs-custom-scrollbar sps-left-panel">
                        <bps-tree #bpsTreeComponent [bpsData]="nodes" bpsBlockNode [bpsExpandedKeys]="defaultExpandedKeys"
                            [bpsSelectedKeys]="defaultSelectedKeys" (bpsClick)="bpsClick($event)" (bpsDblClick)="onDblClickRow($event)"
                            (bpsCheckBoxChange)="bpsCheckBoxChange($event)" (bpsExpandChange)="bpsExpandChange($event)" [bpsTreeTemplateChild]="bpsChildNodeTemplate">
                        </bps-tree>
                    </div>
                </div>
            </div> -->
            <div class="bpsTableContent">
                <div class="custom-panel-wrapper">
                    <div class="bps-cmacs-custom-scrollbar custom-panel" id="custom-panel">
                        <nz-collapse nzGhost style="border: none;" [nzShowArrow]="false" nzAccordion>
                            <nz-collapse-panel *ngFor="let node of nodes" [nzHeader]="contentTemplate_DE_US" [nzActive]="node.active"
                                [nzDisabled]="false" (nzActiveChange)="activeChange($event)" [nzShowArrow]="false">
                                <div nz-row class="child" *ngFor="let childnode of node.children"
                                    [ngClass]="{'selected': childnode.key === selectedChildNodeKey.toString() && !headerSelected}"
                                    (dblclick)="onDblClickSRS(childnode,1)" (click)="Click(childnode)">
                                    <div class="sub-child" nz-col nzSpan="23" [innerHTML]="childnode.title"
                                        (dblclick)="onDblClickSRS(childnode,1)" (click)="Click(childnode)">
                                    </div>
                                    <div *ngIf="!isFrenchSystem()" class="sub-child" nz-col nzSpan="1">
                                        <img class="iconI" src="/assets/Images/icons/sps_information_icon_blue.svg"
                                            bps-popover [bpsPopoverPlacement]="'right'" [bpsPopoverType]="'variation_5'"
                                            [bpsPopoverContent]="tooltipTemplate_notFR">
                                    </div>
                                    <ng-template #tooltipTemplate_notFR>
                                        <div [innerHTML]="('configure.spacer-psi-value-double-glazing' | translate) + '   ' + childnode.dPSIValue">
                                        </div>
                                        <div [innerHTML]="('configure.spacer-psi-value-triple-glazing' | translate) + '   ' + childnode.tPSIValue">
                                        </div>
                                    </ng-template>
                                    <!-- <ng-template #tooltipTemplate_FR>
                                        <div [innerHTML]="('configure.spacer-psi-value-fixed' | translate) + '   ' + getPSIValue_Fixed(childnode)">
                                        </div>
                                        <div [innerHTML]="('configure.spacer-psi-value-inward-opening' | translate) + '   ' + getPSIValue_Inward(childnode)">
                                        </div>
                                    </ng-template> -->
                                </div>

                                

                                <ng-template #header>
                                </ng-template>
                        
                                <ng-template #contentTemplate_DE_US>
                                    <ng-container [ngSwitch]="node.key">
                                        <ng-container *ngSwitchCase="'100'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img *ngIf="language != 'fr-FR' && language != 'de-DE'" src="/assets/Images/spacer-type/generalspacers_enabled.svg">
                                                <img *ngIf="language == 'fr-FR'" src="/assets/Images/spacer-type/generalspacers_fr_enabled.svg">
                                                <img *ngIf="language == 'de-DE'" src="/assets/Images/spacer-type/generalspacers_de_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'200'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/allmetal_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'300'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/edgetech_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'400'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/ensinger_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'500'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/fenzi_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'600'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/helima_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'700'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/igk_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'800'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/kommerling_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'900'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/rolltech_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'10000'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/swisspacer_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'11000'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/technoform_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'12000'">
                                            <div style="width:100%" class="flex-center-center">
                                                <img src="/assets/Images/spacer-type/thermoseal_enabled.svg">
                                            </div>
                                        </ng-container>
                                        <!-- <ng-container *ngSwitchDefault>
                                            This is the null case
                                        </ng-container> -->
                                    </ng-container>
                                </ng-template>
                            </nz-collapse-panel>
                        </nz-collapse>

                    </div>
                </div>
            </div>
        </div>
        <!-- Confirm -->
        <div class="lp-confirm-btn-container shadow-line-up">
            <button class="lp-confirm-btn" bps-button bpsType="variation-4" (click)="onConfirm()"[disabled]="disableConfirmBtn">{{'configure.confirm' | translate }}</button>
        </div>
    </div>
</div>