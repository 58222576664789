<div *ngIf="showSection" [ngStyle]="{'display': isMullionPopoutOpened || isTransomPopoutOpened ? 'flex':'none' }"
    class="sps-left-popout">
    <div class="lp-panel">
        <div class="lp-panel-top">
            <!-- Header Title -->
            <div class="lp-top-section">
                <div class="lp-header">
                    <h3 class="lp-header-text" *ngIf="isMullionPopoutOpened">{{'configure.mullion-library' | translate }}</h3>
                    <h3 class="lp-header-text" *ngIf="isTransomPopoutOpened">{{'configure.transom-library' | translate }}</h3>
                    <img src="/assets/Images/sps/sps_delete_icon_x_darkgrey.svg" id="fas fa-times fa-inverse" alt="close icon" (click)="onClose()" style="cursor: pointer;">
                </div>
            </div>
            <!-- Selected Info -->
            <div class="lp-container lp-container-custom">
                <div class="lp-container-displaybox">
                    <img *ngIf="displayImg()" alt="article" class="articleImg"
                        [attr.src]="getImgSRC()">  
                </div>
                <div class="lp-container-text">
                    <div class="sps-pill" *ngIf="displayImg()">
                        <p>{{ getArticleDescription() }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="navLayoutService.plan == 2">
                <ng-container *appCheckPermissions="feature.CustomFraming">
                    <div class="bpsCustomButtonContainer"
                        *ngIf="(isMullionPopoutOpened || isTransomPopoutOpened) && unified3DModel && unified3DModel.ProblemSetting && unified3DModel.ProblemSetting.EnableStructural">
                        <button bps-button bpsType="variation-2" class="btnCustom" (click)="onOpenCustom()">{{'configure.custom-profile'
                            | translate }}</button>
                    </div>
                </ng-container>
            </div>
            <!-- Table Content -->
            <div class="bpsTableContent">
                <bps-table #bpsTable [data]="listOfDisplayData" [scroll]="{ y: navLayoutService.plan == 2 && feature.CustomFraming ? 'calc(100vh - 455px)':'calc(100vh - 407px)' }"
                    [showPagination]="false" [frontPagination]="false" (onclickRow)="onclickRow($event)"
                    (ondblclickRow)="ondblclickRow($event)" (selectionChange)="selectionChange($event)"
                    [tableType]="'glass_profile'" class="bps-table-section"
                    [filterPlaceholder]="('configure.mullion-depth-search-by-article' | translate)" (searchValueChange)="filter($event)"
                    (sortChange)="sort($event)" [(config)]="configurationCustomGrid">
                </bps-table>
            </div>
        </div>
        <!-- Confirm -->
        <div class="lp-confirm-btn-container shadow-line-up">
            <button class="lp-confirm-btn" bps-button bpsType="variation-4" (click)="onConfirm();onClose();">{{'configure.confirm' | translate }}</button>
        </div>
    </div>
</div>



<ng-template #uValueTemplate>
    <div>{{'configure.inside' | translate }}</div>
    <div class="headerColor">(mm)</div>
</ng-template>

<ng-template #rwTemplate>
    <div>{{'configure.outside' | translate }}</div>
    <div class="headerColor">(mm)</div>
</ng-template>

<ng-template #outsideTemplate let-value="value" let-showArrow="showArrow" let-disabled="disabled" let-arrowHovered="arrowHovered" let-index="index">
    <div (mouseleave)="onHover(index, false)" (mouseenter)="onHover(index, true)">
        <span>{{value}}</span>
        <span *ngIf="showArrow" class="bpsArrow" (click)="onEditCustom(index)">
            <img *ngIf="!arrowHovered || disabled" alt=""
                src="/assets/bps-icons/sps_icon_arrowright_grey.svg" />
            <img *ngIf="arrowHovered && !disabled" alt=""
                src="/assets/bps-icons/sps_icon_arrowright_blue.svg" />
        </span>
    </div>
</ng-template>