import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PanelsModule } from 'src/app/app-structural/models/panels/panels.module';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { ThermalService } from 'src/app/app-structural/services/thermal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavLayoutService } from 'src/app/app-layout/services/nav-layout.service';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';


@Component({
  selector: 'app-uvalue-calculation-table',
  templateUrl: './uvalue-calculation-table.component.html',
  styleUrls: ['./uvalue-calculation-table.component.css']
})
export class UValueCalculationTableComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  isUValueCalculationTableOpened: boolean = false;
  selectedValue: string;
  dualCoefficientAlphaValues = [
    { title: this.translate.instant(_('configure.auto')), checked: true },
    { title: this.translate.instant(_('configure.manual')), checked: false }
  ];
  coefficientAlphaInputValue: number;
  dualAdditionalResistanceValues = [
    { title: this.translate.instant(_('configure.manual')), checked: true },
   { title: this.translate.instant(_('configure.list')), checked: false }
  ];
  deltaRInputValue: number;
  isAdditionalResistance_manual_checked: boolean;
  isCoefficientAlpha_manual_checked: boolean = false;
  isListOpened: boolean = false;
  isDeltaR: boolean = false;
  sunShadingListArticle: any = {description: null, id: null, delta: null};

  validateForm: FormGroup;


  @Output() openSunShadingTableOutput = new EventEmitter<any>();
  


  constructor(private navLayoutService: NavLayoutService, private cpService: ConfigPanelsService, private translate: TranslateService, public umService: UnifiedModelService, public thermalService: ThermalService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.coefficientAlphaInputValue = this.thermalService.AbsorptionCoefficient_defaultValue;
    this.cpService.obsPopout.pipe(takeUntil(this.destroy$)).subscribe(
      response => {        
        if (response.panelsModule === PanelsModule.UValueCalculation) {
          this.isUValueCalculationTableOpened = response.isOpened;
          if (this.isUValueCalculationTableOpened) this.onOpenTable();
          else this.onCloseListPopout();
        }
      }
    );
    this.navLayoutService.languageIsChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dualCoefficientAlphaValues[0].title = this.translate.instant(_('configure.auto'));
      this.dualCoefficientAlphaValues[1].title = this.translate.instant(_('configure.manual'));
      this.dualAdditionalResistanceValues[0].title = this.translate.instant(_('configure.zip-code'));
      this.dualAdditionalResistanceValues[1].title = this.translate.instant(_('configure.list'));
    });

    this.validateForm = this.fb.group({
      coeffAlphaSwitchControl: [false],
      coeffAlphaControl: [this.coefficientAlphaInputValue, [Validators.required]],
      dualAdditionalResistanceControl: [''],
      deltaSwitchControl: [false],
      deltaRInputControl: [this.deltaRInputValue, [Validators.required]],
      deltaRSelectionControl: [this.sunShadingListArticle.description, [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOpenTable() {
    this.readDataFromUM(this.umService.current_UnifiedModel);
    this.validateForm.controls['coeffAlphaControl'].markAsTouched();
   if(this.umService.current_UnifiedModel.ModelInput.Thermal && this.umService.current_UnifiedModel.ModelInput.Thermal.Codetype){
    switch(this.umService.current_UnifiedModel.ModelInput.Thermal.Codetype){
      case 2:
        this.selectedValue = 'NF EN ISO 12631 : 2017';
        break;
      case 1:
      default:
        this.selectedValue = 'EN ISO 10077-1 : 2017';
        break;
    }
   }

    if (!this.thermalService.isThermalCodeAllowed('France')) {
      this.isDeltaR = false;
    }
  }

  readDataFromUM(unified3DModel: UnifiedModel) {
    this.isCoefficientAlpha_manual_checked = unified3DModel.ModelInput.FrameSystem.AbsorptionCoefficient != this.thermalService.AbsorptionCoefficient_defaultValue;
    this.coefficientAlphaInputValue = unified3DModel.ModelInput.FrameSystem.AbsorptionCoefficient;
    if (this.isCoefficientAlpha_manual_checked) {
      this.dualCoefficientAlphaValues = [ { title: this.translate.instant(_('configure.auto')), checked: false }, { title: this.translate.instant(_('configure.manual')), checked: true } ];
    }
    else {
      this.dualCoefficientAlphaValues = [ { title: this.translate.instant(_('configure.auto')), checked: true }, { title: this.translate.instant(_('configure.manual')), checked: false } ];
    }

    this.isDeltaR = unified3DModel.ModelInput.Thermal && (unified3DModel.ModelInput.Thermal.SunShadingArticleId != null || unified3DModel.ModelInput.Thermal.DeltaR != null);

    this.isAdditionalResistance_manual_checked = !unified3DModel.ModelInput.Thermal || unified3DModel.ModelInput.Thermal.SunShadingArticleId == null;
    if (this.isAdditionalResistance_manual_checked) {
      this.dualAdditionalResistanceValues = [ { title: this.translate.instant(_('configure.manual')), checked: true }, { title: this.translate.instant(_('configure.list')), checked: false } ];
    }
    else {
      this.dualAdditionalResistanceValues = [ { title: this.translate.instant(_('configure.manual')), checked: false }, { title: this.translate.instant(_('configure.list')), checked: true } ];
    }
    this.deltaRInputValue = this.isAdditionalResistance_manual_checked && unified3DModel.ModelInput.Thermal ?  unified3DModel.ModelInput.Thermal.DeltaR : null;
    this.sunShadingListArticle.id = unified3DModel.ModelInput.Thermal ? unified3DModel.ModelInput.Thermal.SunShadingArticleId : null;
    this.sunShadingListArticle.delta = unified3DModel.ModelInput.Thermal ? unified3DModel.ModelInput.Thermal.DeltaR : null;
    this.sunShadingListArticle.description = unified3DModel.ModelInput.Thermal && this.isDeltaR && !this.isAdditionalResistance_manual_checked ? this.thermalService.getSunShadingArticleStringById(this.sunShadingListArticle.id) : null;
  }

  onChangeAdditionalResistance(event) {
    this.isAdditionalResistance_manual_checked = event.find(x => x.title == this.translate.instant(_('configure.manual'))).checked;
  }

  onChangeCoefficientAlpha(event) {
    this.isCoefficientAlpha_manual_checked = event.find(x => x.title == this.translate.instant(_('configure.manual'))).checked;
    if (!this.isCoefficientAlpha_manual_checked) { // Auto
      this.coefficientAlphaInputValue = this.thermalService.AbsorptionCoefficient_defaultValue;
    }
  }

  onCloseTable() {
    this.cpService.setPopout(false, PanelsModule.UValueCalculation);
  }

  onConfirm() {
    let data: any = {};
    switch(this.selectedValue){
      case 'NF EN ISO 12631 : 2017':
        data.Codetype = 2;
        break;
      case 'EN ISO 10077-1 : 2017':
      default:
        data.Codetype = 1;
        break;
    }
    data.AbsorptionCoefficient = this.thermalService.isThermalCodeAllowed('France') ? this.coefficientAlphaInputValue : null;
    data.SunShading = {};
    data.SunShading.DeltaR = this.isDeltaR ? this.isAdditionalResistance_manual_checked ? this.deltaRInputValue : this.sunShadingListArticle.delta : null;
    data.SunShading.SunShadingArticleId = this.isDeltaR ? this.isAdditionalResistance_manual_checked ? null : this.sunShadingListArticle.id : null;
    this.umService.onConfirmUValueCalculation(data);
    this.cpService.setConfirm(data, PanelsModule.UValueCalculation);
  }

  onCloseListPopout() {
    this.isListOpened = false;
  }

  onOpenListPopout() {
    this.openSunShadingTableOutput.next(this.sunShadingListArticle.id);
    this.isListOpened = true;
  }

  onConfirmationFromSunShidingTable(event) {
    this.sunShadingListArticle = event;
  }

  isPanelValid(): boolean {
    let valid: boolean = true;
    if (this.thermalService.isThermalCodeAllowed('France')) {
      valid = valid && this.validateForm.controls['coeffAlphaControl'].status == 'VALID';
    }
    if (this.isDeltaR) {
      switch (this.dualAdditionalResistanceValues.find(article => article.checked).title) {
        case this.translate.instant(_('configure.manual')):
          valid = valid && this.validateForm.controls['deltaRInputControl'].status == 'VALID';
          break;
        case this.translate.instant(_('configure.list')):
          valid = valid && this.validateForm.controls['deltaRSelectionControl'].status == 'VALID';
          break;
      };
    }
    return valid;
  }

}
