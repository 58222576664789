import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/app-core/services/local-storage.service';
import { ConfigPanelsService } from 'src/app/app-structural/services/config-panels.service';
import { ConfigureService } from 'src/app/app-structural/services/configure.service';
import { UnifiedModelService } from 'src/app/app-structural/services/unified-model.service';
import { NotificationCustomComponent } from '../../../../../app-common/components/notification-custom/notification-custom.component';
import { Feature } from 'src/app/app-core/models/feature';
import { UnifiedModel } from 'src/app/app-common/models/unified-model/UnifiedModel';
import { CustomGlass } from 'src/app/app-common/models/unified-model/CustomGlass';

interface PanelLayer  {
  type: string;
  size: string;
}
@Component({
  selector: 'app-library-custom',
  templateUrl: './library-custom.component.html',
  styleUrls: ['./library-custom.component.css']
})
export class LibraryCustomComponent implements OnInit {
  @ViewChild(NotificationCustomComponent) notifCustomTemplate: NotificationCustomComponent;
  private destroy$ = new Subject<void>();
  @Output() deleteLibraryCustomArticleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() addLibraryCustomArticleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateLibraryCustomArticleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closePanelEvent: EventEmitter<any> = new EventEmitter<any>();
  unified3DModel: UnifiedModel;
  glazingSystem: any;
  isDisplay: boolean = false;
  isAddButtonDisplay: boolean;
  isAddButtonActive: boolean;
  libraryArticleIndex: number;
  validateForm: FormGroup;
  libraryElements: any;
  defaultLibraryElements: any;
  dxf: string;
  language: string;
  patternLanguage: string;

  disableUpdate: boolean = true;
  applicationType: string;

  isSidePanel: boolean = true;
  isPopoutOpened: boolean = true;
  selectedArticle: any;

  selectedArticleIsConfigure: boolean = false;
  isPanelBtnDisabled: boolean = false;
  isTripleDisabled: boolean = false;
  isEditCustom: boolean;
  selectedType: string;
  isFrenchSystem: boolean = false;
  //panelLayers: any = [{type: 'air', size: '12'}];
  panelLayerForm: PanelLayer[] = [
    { type: 'Aluminium', size: '2' },
    { type: 'Insulation', size: '20' },
    { type: 'Aluminium', size: '2' },
  ];
  isAddDisable: boolean = false;
  feature = Feature;
 

  constructor(private umService: UnifiedModelService, private fb: FormBuilder, private configureService: ConfigureService, private localStorageService: LocalStorageService,
              private cpService: ConfigPanelsService) {
    this.applicationType = this.configureService.applicationType;
    this.language = this.configureService.getLanguage();
    this.patternLanguage = '^[0-9]+[.]?[0-9]*$';
  }
  ngAfterViewInit() {
    this.cpService.obscustomLibraryrow.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.selectedType = response.selectedType;
        this.isEditCustom = response.isEditCustom;
         this.openNewCustom(response.article);
      });

  }

  ngOnInit(article: CustomGlass = null): void {
    this.applicationType = this.configureService.applicationType;
    this.unified3DModel = this.umService.current_UnifiedModel;
    if(this.umService.isWindow() || this.unified3DModel.ProblemSetting.EnableAcoustic) {
      this.isPanelBtnDisabled = true;
    } else {
      this.isPanelBtnDisabled = false;
    }
    if(this.unified3DModel.ProblemSetting.EnableAcoustic) {
      this.isTripleDisabled = true;
    }else {
      this.isTripleDisabled = false;
    }
    this.umService.obsUnifiedModel.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.unified3DModel = response;
          let insulationZone = this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone && this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone != null ? '.'+this.umService.current_UnifiedModel.ModelInput.FrameSystem.InsulationZone: "";
          this.isFrenchSystem = this.umService.isFrenchSystem(this.unified3DModel.ModelInput.FrameSystem.SystemType+insulationZone);
        }
      });

    this.isAddButtonActive = this.isFormValid();
    if (article !== null) {
      this.isAddButtonDisplay = false;
      this.libraryElements = article;
      this.libraryElements.element_size_1 = article.element_size_1.includes('[') ? article.element_size_1.substring(0, article.element_size_1.indexOf('[')) : article.element_size_1;
      this.libraryElements.element_size_2 = article.element_size_2.includes('[') ? article.element_size_2.substring(0, article.element_size_2.indexOf('[')) : article.element_size_2;
      this.libraryElements.element_size_3 = article.element_size_3.includes('[') ? article.element_size_3.substring(0, article.element_size_3.indexOf('[')) : article.element_size_3;

      this.libraryElements.element_interlayer_1 = article.element_interlayer_1 == "0" ? null : article.element_interlayer_1;
      this.libraryElements.element_interlayer_2 = article.element_interlayer_2 == "0" ? null : article.element_interlayer_2;
      this.libraryElements.element_interlayer_3 = article.element_interlayer_3 == "0" ? null : article.element_interlayer_3;
      this.libraryElements.name = '';
      this.validateForm = this.fb.group({
        selectedType: [article.selectedType, [Validators.required]],
        name: ['custom', [Validators.required]],
        element_xx_1: [article.element_xx_1, [Validators.required]],
        element_type_1: [article.element_type_1, [Validators.required]],
        // element_size_1: [article.element_size_1, [Validators.required]],
        element_size_1: [article.element_size_1.includes('[') ? article.element_size_1.substring(0, article.element_size_1.indexOf('[')) : article.element_size_1, [Validators.required]],
        element_interlayer_1: [article.element_interlayer_1, [Validators.required, Validators.pattern(this.patternLanguage)]],
        element_ins_type_1: [article.element_ins_type_1, [Validators.required]],
        element_ins_size_1: [article.element_ins_size_1, [Validators.required]],
        element_xx_2: [article.element_xx_2, [Validators.required]],
        element_type_2: [article.element_type_2, [Validators.required]],
        //element_size_2: [article.element_size_2, [Validators.required]],
        element_size_2: [article.element_size_2.includes('[') ? article.element_size_2.substring(0, article.element_size_2.indexOf('[')) : article.element_size_2, [Validators.required]],
        element_interlayer_2: [article.element_interlayer_2, [Validators.required, Validators.pattern(this.patternLanguage)]],
        element_ins_type_2: [article.element_ins_type_2, [Validators.required]],
        element_ins_size_2: [article.element_ins_size_2, [Validators.required]],
        element_xx_3: [article.element_xx_3, [Validators.required]],
        element_type_3: [article.element_type_3, [Validators.required]],
        //element_size_3: [article.element_size_3, [Validators.required]],
        element_size_3: [article.element_size_3.includes('[') ? article.element_size_3.substring(0, article.element_size_3.indexOf('[')) : article.element_size_3, [Validators.required]],
        element_interlayer_3: [article.element_interlayer_3, [Validators.required, Validators.pattern(this.patternLanguage)]],
        uValue: [article.uValue, [Validators.required, Validators.pattern(this.patternLanguage)]],
        glassrw: [article.glassrw, [Validators.pattern(this.patternLanguage)]],
        sgWinter1: [article.sgWinter1, [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        sgWinter2: [article.sgWinter2, [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        sgSummer1: [article.sgSummer1, [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        sgSummer2: [article.sgSummer2, [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        tlg: [article.tlg, [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        stc: [article.stc === '-1' ? '': article.stc, [Validators.pattern(this.patternLanguage)]],
        oitc: [article.oitc === '-1' ? '' : article.oitc, [Validators.pattern(this.patternLanguage)]],
        up: [article.up, [Validators.required, Validators.pattern(this.patternLanguage)]],
        panelLayers: this.fb.array(article.panelLayers.map(layer => this.createPanelLayerControl(layer))),
        shgc: [article.shgc === '-1' ? '' : article.shgc, [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        absCoeffControl: [article.AbsorptionCoefficient, [Validators.required, Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]]

        // sgWinter1: [article.sgWinter1, [Validators.required, Validators.pattern(this.patternLanguage)]],
        // sgWinter2: [article.sgWinter2, [Validators.required, Validators.pattern(this.patternLanguage)]],
        // sgSummer1: [article.sgSummer1, [Validators.required, Validators.pattern(this.patternLanguage)]],
        // sgSummer2: [article.sgSummer2, [Validators.required, Validators.pattern(this.patternLanguage)]],
        // tlg: [article.tlg, [Validators.required, Validators.pattern(this.patternLanguage)]]
      }); 
    } else {
      this.isAddButtonDisplay = true;
      this.libraryElements = {
        customGlassID: 0,
        selectedType: 'custom-double',
        name: '',
        element_xx_1: 'HS',
        element_type_1: 'Glass',
        element_size_1: this.applicationType === 'BPS' ? '2' : '4',
        element_interlayer_1: null,

        element_ins_type_1: 'Air',
        element_ins_size_1: '12',

        element_xx_2: 'HS',
        element_type_2: 'Glass',
        element_size_2: this.applicationType === 'BPS' ? '2' : '4',
        element_interlayer_2: null,

        element_ins_type_2: 'Air',
        element_ins_size_2: '12',

        element_xx_3: 'HS',
        element_type_3: 'Glass',
        element_size_3: this.applicationType === 'BPS' ? '2' : '4',
        element_interlayer_3: null,

        uValue: '',
        glassrw: '',
        sgWinter1: '',
        sgWinter2: '',
        sgSummer1: '',
        sgSummer2: '',
        tlg: '',
        stc: '',
        oitc: '',
        up:'',
        shgc: '',
        AbsorptionCoefficient: '0.6',

        panelLayers: []
      }
      this.validateForm = this.fb.group({
        selectedType: ['custom-double', [Validators.required]],
        name: ['Custom', [Validators.required]],
        element_xx_1: ['HS', [Validators.required]],
        element_type_1: ['Glass', [Validators.required]],
        element_size_1: ['2', [Validators.required]],
        element_interlayer_1: [null, [Validators.required, Validators.pattern(this.patternLanguage)]],
        element_ins_type_1: ['Air', [Validators.required]],
        element_ins_size_1: ['12', [Validators.required]],
        element_xx_2: ['HS', [Validators.required]],
        element_type_2: ['Glass', [Validators.required]],
        element_size_2: ['2', [Validators.required]],
        element_interlayer_2: [null, [Validators.required, Validators.pattern(this.patternLanguage)]],
        element_ins_type_2: ['Air', [Validators.required]],
        element_ins_size_2: ['12', [Validators.required]],
        element_xx_3: ['HS', [Validators.required]],
        element_type_3: ['Glass', [Validators.required]],
        element_size_3: ['2', [Validators.required]],
        element_interlayer_3: [null, [Validators.required, Validators.pattern(this.patternLanguage)]],
        uValue: ['', [Validators.required, Validators.pattern(this.patternLanguage)]],
        glassrw: ['', [Validators.pattern(this.patternLanguage)]],
        sgWinter1: ['', [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        sgWinter2: ['', [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        sgSummer1: ['', [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        sgSummer2: ['', [Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        tlg: ['', [ Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        stc: ['', [Validators.pattern(this.patternLanguage)]],
        oitc: ['', [Validators.pattern(this.patternLanguage)]],
        up: ['', [Validators.required, Validators.pattern(this.patternLanguage)]],
        // add the standard value for the custom panel in the standardLayer function
        panelLayers: this.fb.array(this.standardLayer()),
        shgc: ['',[ Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]],
        absCoeffControl: ['0.6',[Validators.required, Validators.pattern('^0\.[0-9]|[0-9]\.[0-9]$')]]
      });
    }
    if (!this.libraryElements.element_interlayer_1) this.validateForm.controls["element_interlayer_1"].disable();
    if (!this.libraryElements.element_interlayer_2) this.validateForm.controls["element_interlayer_2"].disable();
    if (!this.libraryElements.element_interlayer_3) this.validateForm.controls["element_interlayer_3"].disable();
    this.defaultLibraryElements = this.libraryElements;
    // Object.keys(this.libraryElements).forEach(key => {
    //   this.libraryElements[key] = '';
    // });
    this.fillSelectedArticle();

    if(this.libraryElements.selectedType === 'custom-panel') {
      this.validateForm.controls.up.enable();
      this.validateForm.controls.panelLayers.enable();
    } else {
      this.validateForm.controls.up.disable();
      this.validateForm.controls.panelLayers.disable();
    }
   
  }

  createPanelLayerControl(layer: PanelLayer) {
    return this.fb.group({
      type: [layer.type, Validators.required],
      size: [layer.size, Validators.required],
    });
  }

  glazingSystemsId = undefined;
  openNewCustom(article: any) {
    if (article && article.customGlassID === undefined) {
      this.glazingSystemsId = article.idArticle + 1;
      article = null;
    }
    this.ngOnInit(article);
   // this.disableUpdate = true;
    this.isDisplay = true;
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls[key].value) {
        this.validateForm.controls[key].markAsDirty();
        this.validateForm.controls[key].markAsUntouched();
      }
    }
    this.isAddButtonActive = this.isFormValid();
  }

  onSelectTypeChange(selectType: string) {
    this.disableUpdate = false;
    if (selectType === 'custom-triple') {
      this.libraryElements.selectedType = 'custom-triple';
      // this.libraryElements.element_ins_type_2 = 'Air';
      // this.libraryElements.element_ins_size_2 = '12';
      // this.libraryElements.element_xx_3 = 'HS';
      // this.libraryElements.element_type_3 = 'Glass';
      // this.libraryElements.element_size_3 = '4';
      // this.libraryElements.element_interlayer_3 = '1.52';
    } else if (selectType === 'custom-double') {
      this.libraryElements.selectedType = 'custom-double';
      // this.libraryElements.element_ins_type_2 = undefined;
      // this.libraryElements.element_ins_size_2 = undefined;
      // this.libraryElements.element_xx_3 = undefined;
      // this.libraryElements.element_type_3 = undefined;
      // this.libraryElements.element_size_3 = undefined;
      // this.libraryElements.element_interlayer_3 = undefined;
    }
    else if (selectType === 'custom-panel') {
      this.libraryElements.selectedType = 'custom-panel';
      // this.libraryElements.element_ins_type_2 = undefined;
      // this.libraryElements.element_ins_size_2 = undefined;
      // this.libraryElements.element_xx_3 = undefined;
      // this.libraryElements.element_type_3 = undefined;
      // this.libraryElements.element_size_3 = undefined;
      // this.libraryElements.element_interlayer_3 = undefined;
    }
    this.fillSelectedArticle();
    this.formEnableValues()
  }

  formEnableValues() {
    if (this.libraryElements.selectedType === 'custom-panel') {
      if(this.unified3DModel.ProblemSetting.EnableThermal) {
        this.validateForm.controls.up.enable();
        this.validateForm.controls.panelLayers.enable();
      } else {
        this.validateForm.controls.up.disable();
        this.validateForm.controls.panelLayers.enable();
      }
      this.validateForm.controls.uValue.disable();
      this.validateForm.controls.glassrw.disable();
      this.validateForm.controls.sgWinter1.disable();
      this.validateForm.controls.sgWinter2.disable();
      this.validateForm.controls.sgSummer1.disable();
      this.validateForm.controls.sgSummer2.disable();
      this.validateForm.controls.tlg.disable();
      this.validateForm.controls.stc.disable();
      this.validateForm.controls.oitc.disable();
    }
    else {
      this.validateForm.controls.up.disable();
      this.validateForm.controls.panelLayers.disable();

      this.validateForm.controls.uValue.enable();
      this.validateForm.controls.glassrw.enable();
      this.validateForm.controls.sgWinter1.enable();
      this.validateForm.controls.sgWinter2.enable();
      this.validateForm.controls.sgSummer1.enable();
      this.validateForm.controls.sgSummer2.enable();
      this.validateForm.controls.tlg.enable();
      this.validateForm.controls.stc.enable();
      this.validateForm.controls.oitc.enable();
      this.validateForm.controls.shgc.enable();
    }
  }

  onCloseButton(): void {
    this.isDisplay = false;
  }

  onDelete(): void {
    this.deleteLibraryCustomArticleEvent.emit(this.libraryArticleIndex);
    this.isDisplay = false;
  }

  onAdd(): void {
    const pLayers = [];
    for (let panel of this.panelLayers.controls){
      pLayers.push(panel.value);
    }
    if(this.validateForm.controls.panelLayers.enabled){
      if(pLayers[0].type.toLowerCase() === 'air' || pLayers[pLayers.length - 1].type.toLowerCase() === 'insulation') {
        this.validateForm.controls['panelLayers'].markAsTouched();
        this.validateForm.controls['panelLayers'].setErrors({'incorrect': true})
        this.umService.setNotificaionShow("Warning_incorrectLayer");
      } else {
        this.buildModel(true);
      }
    } else {
      this.buildModel(true);
    }
   
  }

  onUpdate(): void {
    this.buildModel(false);
  }

  buildModel(isNew: boolean = true): void {
    this.defaultLibraryElements.shgc = '' + ((parseFloat(this.defaultLibraryElements.AbsorptionCoefficient.replace(",",".")) * parseFloat(this.defaultLibraryElements.up.replace(",","."))) / 25).toFixed(3);
    this.libraryElements = this.defaultLibraryElements;

    this.libraryElements.panelLayers = [];
    for (let panel of this.panelLayers.controls){
      this.libraryElements.panelLayers.push(panel.value);
    }

    this.unified3DModel.ModelInput.Geometry.GlazingSystems = this.unified3DModel.ModelInput.Geometry.GlazingSystems.filter(element => element.GlazingSystemID !== this.glazingSystemsId);
    if (!this.unified3DModel.ProblemSetting.EnableThermal) {
      this.libraryElements.uValue = "";
      this.libraryElements.sgWinter1 = "";
      this.libraryElements.sgWinter2 = "";
      this.libraryElements.sgSummer1 = "";
      this.libraryElements.sgSummer2 = "";
      this.libraryElements.tlg = "";
      this.libraryElements.stc = "";
      this.libraryElements.oitc = "";
      this.libraryElements.shgc = "";
    } 
    if (!this.unified3DModel.ProblemSetting.EnableAcoustic) this.libraryElements.glassrw = "";
    let sectionElement = this.libraryElements;

   // sectionElement.uValue ? null : sectionElement.uValue = "-1";
   // sectionElement.glassrw ? null : sectionElement.glassrw = "-1";
   // sectionElement.stc ? null || '' : sectionElement.stc = "-1";
   // sectionElement.oitc ? null || '' : sectionElement.oitc = "-1";
  // sectionElement.shgc ? null || '' : sectionElement.shgc = "-1";
    

    if (sectionElement.element_type_1 === 'lamiAcousticPVB') sectionElement.element_size_1 += '[' + sectionElement.element_interlayer_1 + ']';
    if (sectionElement.element_type_2 === 'lamiAcousticPVB') sectionElement.element_size_2 += '[' + sectionElement.element_interlayer_2 + ']';
    if (sectionElement.element_type_3 === 'lamiAcousticPVB') sectionElement.element_size_3 += '[' + sectionElement.element_interlayer_3 + ']';
    let es1 = sectionElement.element_size_1; let es2 = sectionElement.element_size_2; let es3 = sectionElement.element_size_3;
    const element_type_1 = sectionElement.element_type_1 === 'lamiAcousticPVB' ? 'Glass' : sectionElement.element_type_1;
    const element_type_2 = sectionElement.element_type_2 === 'lamiAcousticPVB' ? 'Glass' : sectionElement.element_type_2;
    const element_type_3 = sectionElement.element_type_3 === 'lamiAcousticPVB' ? 'Glass' : sectionElement.element_type_3;
    var language = this.configureService.getLanguage();
    let thickness = parseInt(sectionElement.element_size_1) + parseInt(sectionElement.element_ins_size_1) + parseInt(sectionElement.element_size_2);
    let composition = es1 + "-" + sectionElement.element_ins_size_1 + "-" + es2;
    let type = element_type_1 + "-" + sectionElement.element_ins_type_1 + "-" + element_type_2;
    let description = sectionElement.element_size_1 + "/" + sectionElement.element_ins_size_1 + "/" + sectionElement.element_size_2;

    if (sectionElement.element_type_1 === 'Glass') sectionElement.element_interlayer_1 = 0;
    if (sectionElement.element_type_2 === 'Glass') sectionElement.element_interlayer_2 = 0;
    if (sectionElement.element_type_3 === 'Glass') sectionElement.element_interlayer_3 = 0;

    if (sectionElement.selectedType == 'custom-triple') {
      thickness = thickness + parseInt(sectionElement.element_ins_size_2) + parseInt(sectionElement.element_size_3);
      composition += "-" + sectionElement.element_ins_size_2 + "-" + es3;
      type += "-" + sectionElement.element_ins_type_2 + "-" + element_type_3;
      description += "/" + sectionElement.element_ins_size_2 + "/" + sectionElement.element_size_3
    }
    if (!this.unified3DModel.ModelInput.Geometry.CustomGlass)
      this.unified3DModel.ModelInput.Geometry.CustomGlass = [];
    if (isNew) {
      sectionElement.customGlassID = this.unified3DModel.ModelInput.Geometry.CustomGlass.length;
      this.unified3DModel.ModelInput.Geometry.CustomGlass.push(sectionElement);
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].uValue === null || ''? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].uValue;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].glassrw === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].glassrw;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].shgc === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].shgc;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgSummer1 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgSummer1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgSummer2 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgSummer2;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgWinter1 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgWinter1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgWinter2 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sgWinter2;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].stc === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].stc;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].oitc === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].oitc;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].tlg === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].tlg;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg1h === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg2h === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter2;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg1e === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg2e === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer2;
    } else {
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID] = sectionElement;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].uValue === null || ''? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].uValue;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].glassrw === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].glassrw;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].shgc === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].shgc;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer1 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer2 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer2;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter1 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter2 === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter2;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].stc === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].stc;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].oitc === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].oitc;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].tlg === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].tlg;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg1h === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg2h === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgWinter2;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg1e === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer1;
      this.unified3DModel.ModelInput.Geometry.CustomGlass[sectionElement.customGlassID].sg2e === null || '' ? -1 : this.unified3DModel.ModelInput.Geometry.CustomGlass[this.libraryElements.customGlassID].sgSummer2;
    }
    var newCustom = {
      composition: composition,
      type: type,
      totalThickness: thickness,
      totalThicknessUnit: "mm",
      uvalue: sectionElement.selectedType=='custom-panel'? sectionElement.up ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.up)) : "--" : sectionElement.uValue ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.uValue)) : "--",
      rw: sectionElement.glassrw ? parseFloat(sectionElement.glassrw) : "--",
      spacer: sectionElement.selectedType=='custom-panel' ? [""] : ["1"],
      category: sectionElement.selectedType,
      
      sgWinter1: sectionElement.sgWinter1 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgWinter1)) : "--",
      sgWinter2: sectionElement.sgWinter2 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgWinter2)) : "--",
      sgSummer1: sectionElement.sgSummer1 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgSummer1)) : "--",
      sgSummer2: sectionElement.sgSummer2 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgSummer2)) : "--",
      tlg: sectionElement.tlg ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.tlg)) : "--",
      stc: sectionElement.stc ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.stc)) : "--",
      oitc: sectionElement.oitc ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.oitc)) : "--",
      up: sectionElement.up ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.up)) : "--",
      AbsorptionCoefficient: sectionElement.AbsorptionCoefficient ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.AbsorptionCoefficient)) : "--",
      shgc: sectionElement.shgc ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.shgc)) : "--",
      panelLayers: sectionElement.panelLayers,
      sg1h: sectionElement.sgWinter1 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgWinter1)) : "--",
      sg2h: sectionElement.sgWinter2 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgWinter2)) : "--",
      sg1e: sectionElement.sgSummer1 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgSummer1)) : "--",
      sg2e: sectionElement.sgSummer2 ? parseFloat(this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement.sgSummer2)) : "--",

    };

    description += " (" + newCustom.totalThickness + " " + newCustom.totalThicknessUnit + ")";// + newCustom.category;
    if (sectionElement.selectedType == 'custom-triple') {
      this.glazingSystem = {
        "GlazingSystemID": this.glazingSystemsId,
        "Rw": newCustom.rw,
        "UValue": newCustom.uvalue,
        "Sg1h": newCustom.sgWinter1 === '--'? -1 : newCustom.sgWinter1,
        "Sg2h": newCustom.sgWinter2 === '--' ? -1 : newCustom.sgWinter2,
        "Sg1e": newCustom.sgSummer1 === '--' ? -1 : newCustom.sgSummer1,
        "Sg2e": newCustom.sgSummer2 === '--' ? -1 : newCustom.sgSummer2,
        "VT": newCustom.tlg === '--' ? -1 : newCustom.tlg,
        "STC": newCustom.stc === '--' ? -1 : newCustom.stc,
        "OITC": newCustom.oitc === '--' ? -1 : newCustom.oitc,
        "SHGC": newCustom.shgc === '--' ? -1 : newCustom.shgc,
        "SpacerType": 1,
        "Description": description,
        "Plates": [{
          "UDF1": sectionElement.element_xx_1,
          "Material": sectionElement.element_type_1,
          "H": sectionElement.element_size_1,
          "InterH": sectionElement.element_interlayer_1
        }, {
          "UDF1": sectionElement.element_xx_2,
          "Material": sectionElement.element_type_2,
          "H": sectionElement.element_size_2,
          "InterH": sectionElement.element_interlayer_2
        }, {
          "UDF1": sectionElement.element_xx_3,
          "Material": sectionElement.element_type_3,
          "H": sectionElement.element_size_3,
          "InterH": sectionElement.element_interlayer_3
        }],
        "Cavities": [{
          "CavityType": sectionElement.element_ins_type_1,
          "Lz": sectionElement.element_ins_size_1
        }, {
          "CavityType": sectionElement.element_ins_type_2,
          "Lz": sectionElement.element_ins_size_2
        }],
        "Category": sectionElement.selectedType,
      };
    } else {
      this.glazingSystem = {
        "GlazingSystemID": this.glazingSystemsId,
        "Rw": newCustom.rw === "--" ? -1: newCustom.rw,
        "UValue": newCustom.uvalue === "--" ? -1: newCustom.uvalue,
        "Sg1h": newCustom.sgWinter1 === '--'? -1 : newCustom.sgWinter1,
        "Sg2h": newCustom.sgWinter2 === '--' ? -1 : newCustom.sgWinter2,
        "Sg1e": newCustom.sgSummer1 === '--' ? -1 : newCustom.sgSummer1,
        "Sg2e": newCustom.sgSummer2 === '--' ? -1 : newCustom.sgSummer2,
        "VT": newCustom.tlg === '--' ? -1 : newCustom.tlg,
        "STC": newCustom.stc === '--' ? -1 : newCustom.stc,
        "OITC": newCustom.oitc === '--' ? -1 : newCustom.oitc,
        "SHGC": newCustom.shgc === '--' ? -1 : newCustom.shgc,
        "SpacerType": 1,
        "Description": description,
        "Plates": [{
          "UDF1": sectionElement.element_xx_1,
          "Material": sectionElement.element_type_1,
          "H": sectionElement.element_size_1,
          "InterH": sectionElement.element_interlayer_1
        }, {
          "UDF1": sectionElement.element_xx_2,
          "Material": sectionElement.element_type_2,
          "H": sectionElement.element_size_2,
          "InterH": sectionElement.element_interlayer_2
        }],
        "Cavities": [{
          "CavityType": sectionElement.element_ins_type_1,
          "Lz": sectionElement.element_ins_size_1
        }],
        "Category": sectionElement.selectedType,
      };
    }
    this.unified3DModel.ModelInput.Geometry.GlazingSystems.push(this.glazingSystem);
    if (isNew) {
      this.addLibraryCustomArticleEvent.emit(newCustom);
    } else {
      this.updateLibraryCustomArticleEvent.emit(newCustom);
    }
    this.isDisplay = false;
  }

  hasDuplicateGlass = false;
  checkForDuplicateCustomGlass() {
    if (this.libraryElements) {
      this.hasDuplicateGlass = false;
      var customGlassList = this.unified3DModel.ModelInput.Geometry.CustomGlass;
      if (!this.isAddButtonDisplay){
        if(this.unified3DModel.ModelInput.Geometry.CustomGlass !== null)
        customGlassList = this.unified3DModel.ModelInput.Geometry.CustomGlass.filter(f => f.customGlassID !== this.libraryElements.customGlassID);
      }
        
      if (customGlassList && customGlassList !== null) {
        customGlassList.forEach(customGlass => {
          if (customGlass.element_ins_size_1 === this.libraryElements.element_ins_size_1 &&
            customGlass.element_ins_size_2 === this.libraryElements.element_ins_size_2 &&
            customGlass.element_ins_type_1 === this.libraryElements.element_ins_type_1 &&
            customGlass.element_ins_type_2 === this.libraryElements.element_ins_type_2 &&

            (customGlass.element_interlayer_1 === this.libraryElements.element_interlayer_1 || (customGlass.element_interlayer_1 === "0" && this.libraryElements.element_interlayer_1 == null)) &&
            (customGlass.element_interlayer_2 === this.libraryElements.element_interlayer_2 || (customGlass.element_interlayer_2 === "0" && this.libraryElements.element_interlayer_2 == null)) &&
            (customGlass.element_interlayer_3 === this.libraryElements.element_interlayer_3 || (customGlass.element_interlayer_3 === "0" && this.libraryElements.element_interlayer_3 == null)) &&

            customGlass.element_size_1 === this.libraryElements.element_size_1 &&
            customGlass.element_size_2 === this.libraryElements.element_size_2 &&
            customGlass.element_size_3 === this.libraryElements.element_size_3 &&
            customGlass.element_type_1 === this.libraryElements.element_type_1 &&
            customGlass.element_type_2 === this.libraryElements.element_type_2 &&
            customGlass.element_type_3 === this.libraryElements.element_type_3 &&
            customGlass.element_xx_1 === this.libraryElements.element_xx_1 &&
            customGlass.element_xx_2 === this.libraryElements.element_xx_2 &&
            customGlass.element_xx_3 === this.libraryElements.element_xx_3 &&
            customGlass.glassrw === this.libraryElements.glassrw &&
            //customGlass.name === this.libraryElements.name &&
            customGlass.selectedType === this.libraryElements.selectedType &&
            customGlass.uValue === this.libraryElements.uValue) {
            this.hasDuplicateGlass = true;
          }
        });
      }
    }
  }
  isFormValid() {
    this.checkForDuplicateCustomGlass();
    if (this.validateForm) {
      if (this.validateForm.touched)
        this.disableUpdate = false;
      if (this.unified3DModel.ProblemSetting.EnableAcoustic && this.libraryElements.selectedType !== 'custom-panel') {
        this.validateForm.controls.glassrw.enable();
        if (this.libraryElements.uValue === undefined || this.libraryElements.uValue === "--" || this.libraryElements.uValue === "0") {
          this.disableUpdate = false;
        }
        if (this.isAddButtonDisplay === false) {
          for (const key in this.validateForm.controls) {
            this.validateForm.controls[key].markAsDirty();
            this.validateForm.controls[key].updateValueAndValidity();
          }
        }
      }
      else
        this.validateForm.controls.glassrw.disable();

      if (this.unified3DModel.ProblemSetting.EnableThermal && this.libraryElements.selectedType !== 'custom-panel') {
        this.validateForm.controls.uValue.enable();
        if (this.libraryElements.glassrw === undefined || this.libraryElements.glassrw.toString() == "--" || this.libraryElements.glassrw === 0) {
          this.disableUpdate = false;
        }
        if (this.isAddButtonDisplay === false) {
          for (const key in this.validateForm.controls) {
            this.validateForm.controls[key].markAsDirty();
            this.validateForm.controls[key].updateValueAndValidity();
          }
        }
      }
      else
        this.validateForm.controls.uValue.disable();
      if (this.validateForm.pristine && !(this.validateForm.dirty || this.validateForm.touched)) {
        return this.validateForm.valid;
      }
      if (!this.validateForm.pristine) {
        return this.validateForm.valid;
      }
    }

    return false;
  }

  editCustomArticle(data) {
    let sectionElement = data.article;
    let index = data.index;
    this.isDisplay = true;
    this.isAddButtonDisplay = false;
    this.libraryElements = JSON.parse(JSON.stringify(sectionElement));
    this.libraryArticleIndex = index;
    Object.keys(this.libraryElements).forEach(key => {
      this.libraryElements[key] = this.configureService.getStringFormat_withCorrectDecimalSeparator_byCulture(sectionElement[key].toString());
    });
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  changeElement_type_1($event: any) {
    if (this.defaultLibraryElements.element_type_1 !== $event) {
      this.defaultLibraryElements.element_type_1 = $event;
      if (this.libraryElements.element_type_1 === 'Glass') {
        this.libraryElements.element_size_1 = this.applicationType === 'BPS' ? '2' : '4';
        this.libraryElements.element_interlayer_1 = null;
        this.defaultLibraryElements.element_interlayer_1 = this.libraryElements.element_interlayer_1;
        this.defaultLibraryElements.element_size_1 = this.libraryElements.element_size_1;
        this.validateForm.controls["element_interlayer_1"].disable();
      } else {
        this.validateForm.controls["element_interlayer_1"].enable();
        setTimeout(() => {
          // this.validateForm.controls["element_interlayer_1"].markAsUntouched();
          // this.validateForm.controls["element_interlayer_1"].markAsPristine();
          this.validateForm.get('element_interlayer_1').markAsUntouched();
          this.validateForm.get('element_interlayer_1').markAsPristine();
        }, 50);
        this.libraryElements.element_size_1 = this.applicationType === 'BPS' ? '4' : '3';
        this.defaultLibraryElements.element_size_1 = this.libraryElements.element_size_1;
      }
    }
    this.fillSelectedArticle();
  }

  changeElement_type_2($event: any) {
    if (this.defaultLibraryElements.element_type_2 !== $event) {
      this.defaultLibraryElements.element_type_2 = $event;
      if (this.libraryElements.element_type_2 === 'Glass') {
        this.libraryElements.element_size_2 = this.applicationType === 'BPS' ? '2' : '4';
        this.libraryElements.element_interlayer_2 = null;
        this.defaultLibraryElements.element_size_2 = this.libraryElements.element_size_2;
        this.defaultLibraryElements.element_interlayer_2 = this.libraryElements.element_interlayer_2;
        this.validateForm.controls["element_interlayer_2"].disable();
      } else {
        this.validateForm.controls["element_interlayer_2"].enable();
        setTimeout(() => {
          // this.validateForm.controls["element_interlayer_2"].markAsUntouched();
          // this.validateForm.controls["element_interlayer_2"].markAsPristine();
          this.validateForm.get('element_interlayer_2').markAsUntouched();
          this.validateForm.get('element_interlayer_2').markAsPristine();
        }, 50);
        this.libraryElements.element_size_2 = this.applicationType === 'BPS' ? '4' : '3';
        this.defaultLibraryElements.element_size_2 = this.libraryElements.element_size_2;
      }
    }
    this.fillSelectedArticle();
  }

  changeElement_type_3($event: any) {
    if (this.defaultLibraryElements.element_type_3 !== $event) {
      this.defaultLibraryElements.element_type_3 = $event;
      if (this.libraryElements.element_type_3 === 'Glass') {
        this.libraryElements.element_size_3 = this.applicationType === 'BPS' ? '2' : '4';
        this.libraryElements.element_interlayer_3 = null;
        this.defaultLibraryElements.element_size_3 = this.libraryElements.element_size_3;
        this.defaultLibraryElements.element_interlayer_3 = this.libraryElements.element_interlayer_3;
        this.validateForm.controls["element_interlayer_3"].disable();
      } else {
        // this.validateForm.controls["element_interlayer_3"].enable();
        // this.validateForm.controls["element_interlayer_3"].markAsUntouched();
        setTimeout(() => {
          this.validateForm.get('element_interlayer_3').markAsUntouched();
          this.validateForm.get('element_interlayer_3').markAsPristine();
        }, 50);
        this.libraryElements.element_size_3 = this.applicationType === 'BPS' ? '4' : '3';
        this.defaultLibraryElements.element_size_3 = this.libraryElements.element_size_3;
      }
    }
    this.fillSelectedArticle();
  }

  changeElement_xx_1($event: any) { this.defaultLibraryElements.element_xx_1 = $event; if(this.defaultLibraryElements.element_xx_1 !== null) this.fillSelectedArticle(); }
  changeElement_xx_2($event: any) { this.defaultLibraryElements.element_xx_2 = $event; if(this.defaultLibraryElements.element_xx_2 !== null) this.fillSelectedArticle(); }
  changeElement_xx_3($event: any) { this.defaultLibraryElements.element_xx_3 = $event; if(this.defaultLibraryElements.element_xx_3 !== null) this.fillSelectedArticle(); }

  changeElement_ins_type_1($event: any) { this.defaultLibraryElements.element_ins_type_1 = $event; if(this.defaultLibraryElements.element_ins_type_1 !== null) this.fillSelectedArticle(); }
  changeElement_ins_type_2($event: any) { this.defaultLibraryElements.element_ins_type_2 = $event; if(this.defaultLibraryElements.element_ins_type_2 !== null) this.fillSelectedArticle();}

  changeElement_ins_size_1($event: any) { this.defaultLibraryElements.element_ins_size_1 = $event; if(this.defaultLibraryElements.element_ins_size_1 !== null) this.fillSelectedArticle(); }
  changeElement_ins_size_2($event: any) { this.defaultLibraryElements.element_ins_size_2 = $event; if(this.defaultLibraryElements.element_ins_size_2 !== null) this.fillSelectedArticle(); }

  changeElement_interlayer_1($event: any) { this.defaultLibraryElements.element_interlayer_1 = $event; if(this.defaultLibraryElements.element_interlayer_1 !== null) this.fillSelectedArticle(); }
  changeElement_interlayer_2($event: any) { this.defaultLibraryElements.element_interlayer_2 = $event; if(this.defaultLibraryElements.element_interlayer_2 !== null) this.fillSelectedArticle(); }
  changeElement_interlayer_3($event: any) { this.defaultLibraryElements.element_interlayer_3 = $event; if(this.defaultLibraryElements.element_interlayer_3 !== null) this.fillSelectedArticle(); }

  changeElement_size_1($event: any) { this.defaultLibraryElements.element_size_1 = $event; if(this.defaultLibraryElements.element_size_1 !== null) this.fillSelectedArticle(); }
  changeElement_size_2($event: any) { this.defaultLibraryElements.element_size_2 = $event; if(this.defaultLibraryElements.element_size_2 !== null) this.fillSelectedArticle(); }
  changeElement_size_3($event: any) { this.defaultLibraryElements.element_size_3 = $event; if(this.defaultLibraryElements.element_size_3 !== null) this.fillSelectedArticle(); }

  changeElement_uValue($event: any) {
    if (this.defaultLibraryElements.uValue != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.uValue = $event;

    this.selectedArticle.uvalue = this.libraryElements.uValue;
  }
  changeElement_glassrw($event: any) {
    if (this.defaultLibraryElements.glassrw != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.glassrw = $event;

    this.selectedArticle.rw = this.libraryElements.glassrw;
  }
  changeElement_sgWinter1($event: any) {
    if (this.defaultLibraryElements.sgWinter1 != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.sgWinter1 = $event;
  }
  changeElement_sgWinter2($event: any) {
    if (this.defaultLibraryElements.sgWinter2 != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.sgWinter2 = $event;
  }
  changeElement_sgSummer1($event: any) {
    if (this.defaultLibraryElements.sgSummer1 != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.sgSummer1 = $event;
  }
  changeElement_sgSummer2($event: any) {
    if (this.defaultLibraryElements.sgSummer2 != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.sgSummer2 = $event;
  }
  changeElement_tlg($event: any) {
    if (this.defaultLibraryElements.tlg != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.tlg = $event;
  }
  changeElement_stc($event: any) {
    if (this.defaultLibraryElements.stc != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.stc = $event;
  }
  changeElement_oitc($event: any) {
    if (this.defaultLibraryElements.oitc != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.oitc = $event;
  }
  changeElement_shgc($event: any) {
    if (this.defaultLibraryElements.shgc != $event)
    this.disableUpdate = false;
  this.defaultLibraryElements.shgc = $event;
  }
  changeElement_up($event: any) {
    if (this.defaultLibraryElements.up != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.up = $event;
  }
  changeElement_absCoeff($event: any) {
    if (this.defaultLibraryElements.AbsorptionCoefficient != $event)
      this.disableUpdate = false;
    this.defaultLibraryElements.AbsorptionCoefficient = $event;
  }
  changeElement_panelLayers($event: any) {
    // this.defaultLibraryElements.panelLayers = []
    // for (let panel in this.panelLayers.controls){
    //   this.defaultLibraryElements.panelLayers.push(panel);
    // }
    this.fillSelectedArticle();
  }
  get panelLayers() {
    return this.validateForm.controls["panelLayers"] as FormArray;
  }
  standardLayer() {
    let standardPanelLayers = [];
    // to add a new row copy the code below and change the string values. (check the new value exist in the html option code)
    const layerForm = this.fb.group({
      type: ['Aluminium', Validators.required],
      size: ['2', Validators.required]
    });
    standardPanelLayers.push(layerForm);
    const layerForm1 = this.fb.group({
      type: ['Insulation', Validators.required],
      size: ['20', Validators.required]
    });
    standardPanelLayers.push(layerForm1);
    const layerForm2 = this.fb.group({
      type: ['Aluminium', Validators.required],
      size: ['2', Validators.required]
    });
    standardPanelLayers.push(layerForm2);
    //end of code for one row

    return standardPanelLayers;
  }
  addLayer() {
    const layerForm = this.fb.group({
        type: ['Aluminium', Validators.required],
        size: ['2', Validators.required]
    });
    if(this.panelLayers && (this.panelLayers.length === 10 || this.panelLayers.length > 10)) {
      this.ngNotificaionShow('Warning_layerLimit');
      this.isAddDisable = true;
    } else {
      this.isAddDisable = false;
      this.panelLayers.push(layerForm);
    }
    
    this.fillSelectedArticle();  
  }
  deleteLayer(layerIndex: number) {
    this.panelLayers.removeAt(layerIndex);
    this.fillSelectedArticle();
    if(this.panelLayers && (this.panelLayers.length === 10 || this.panelLayers.length > 10)) {
      this.ngNotificaionShow('Warning_layerLimit');
      this.isAddDisable = true;
    } else {
      this.isAddDisable = false;
    }
  }

  ngNotificaionShow(event: string) {
    this.notifCustomTemplate.notificationShow(event);
  }
  onReset() {
    this.ngOnInit();
  }
  onClose() {
    this.closePanelEvent.emit();
    // this.validateForm.reset();
  }
  fillSelectedArticle() {
    this.selectedArticleIsConfigure = false;
    this.selectedArticle = {};
    this.selectedArticle.spacer = [''];
    this.selectedArticle.category = this.libraryElements.selectedType.split("-")[1];
    if (this.selectedArticle.category != "panel") {
      this.selectedArticle.uvalue = this.libraryElements.uValue;
      this.selectedArticle.rw = this.libraryElements.glassrw;
      
      var aelement_type_1 = this.libraryElements.element_type_1 !== null ? this.libraryElements.element_type_1.includes('lami') ? 'Glass' : this.libraryElements.element_type_1 : '';
      var aelement_type_2 = this.libraryElements.element_type_2 !== null ? this.libraryElements.element_type_2.includes('lami') ? 'Glass' : this.libraryElements.element_type_2 : '';
      var aelement_type_3 = this.libraryElements.element_type_3 !== null ? this.libraryElements.element_type_3.includes('lami') ? 'Glass' : this.libraryElements.element_type_3 : '';
      var aelement_size_1 = this.libraryElements.element_interlayer_1 === null ? this.libraryElements.element_size_1 : this.libraryElements.element_size_1 + '(' + this.libraryElements.element_interlayer_1 + 'mm Acoustic PVB)';
      var aelement_size_2 = this.libraryElements.element_interlayer_2 === null ? this.libraryElements.element_size_2 : this.libraryElements.element_size_2 + '(' + this.libraryElements.element_interlayer_2 + 'mm Acoustic PVB)';
      var aelement_size_3 = this.libraryElements.element_interlayer_3 === null ? this.libraryElements.element_size_3 : this.libraryElements.element_size_3 + '(' + this.libraryElements.element_interlayer_3 + 'mm Acoustic PVB)';

    
      this.selectedArticle.composition =   aelement_size_1  + "-" + this.libraryElements.element_ins_size_1 + "-" + aelement_size_2;   
      this.selectedArticle.type = aelement_type_1 + "-" + this.libraryElements.element_ins_type_1 + "-" + aelement_type_2;
     
     if (this.selectedArticle.category == "triple") {
        this.selectedArticle.composition += "-" + this.libraryElements.element_ins_size_2 + "-" + aelement_size_3;
        this.selectedArticle.type += "-" + this.libraryElements.element_ins_type_2 + "-" + aelement_type_3;
      }
    }
    else {
      this.selectedArticle.composition = "";
      this.selectedArticle.type = "";
      for (let layer of this.panelLayers.controls) {
        this.selectedArticle.composition += layer.value.size + "-";
        this.selectedArticle.type += layer.value.type + "-";
      }
      this.selectedArticle.composition = this.selectedArticle.composition.substring(0, this.selectedArticle.composition.length - 1);
      this.selectedArticle.type = this.selectedArticle.type.substring(0, this.selectedArticle.type.length - 1);
    }
    this.selectedArticle.totalThickness = this.calculTotalThickness();
    this.selectedArticle.totalThicknessUnit = "mm";
    this.selectedArticleIsConfigure = true;
  }
  calculTotalThickness() {
    let totalThickness = 0;
    const composition = this.selectedArticle.composition.split("-");
    composition.forEach(size => {
      totalThickness += parseInt(size);
    }); 
    return totalThickness;
  }
}
